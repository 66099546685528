import * as React from 'react';
import _ from 'lodash-es';
import cx from 'classnames';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Loader from 'components/Loader/Loader';
import SvgAddIcon from '../../../static/icons/svgAddIcon';
import AddDomainPopup from './AddDomainPopup';
import ConfirmationPopup from 'components/Popup/Confirmation';

const styles = require('./styles.pcss');

import { Company, ReduxState } from '../../../reducers/types';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { update } from '../../../actions/Companies/Update';
import { resetSaveCompanyStatus } from '../../../actions/Companies/Add';

type Props = {
  company: Company;
  update: any;
  saveCompanyStatus: any;
  hasPermission: boolean;
};

type State = {
  openAddPopup: boolean;
  openDeleteConfirmationPopup: boolean;
  saveError: string;
  domainToDelete: any;
};

class AdditionalDomains extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      openDeleteConfirmationPopup: false,
      openAddPopup: false,
      saveError: '',
      domainToDelete: null,
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    if (_.get(nextProps, 'saveCompanyStatus.isLoading')) {
      return;
    }

    if (
      _.get(nextProps, 'saveCompanyStatus.errors.additionalDomains', '') !== ''
    ) {
      this.setState({
        saveError: nextProps.saveCompanyStatus.errors.additionalDomains,
      });
    } else {
      this.setState({
        saveError: '',
        openAddPopup: false,
      });
    }
  }

  openAddPopup = () => {
    this.setState({
      openAddPopup: true,
    });
  };

  closeAddPopup = () => {
    this.setState({
      openAddPopup: false,
    });
  };

  closeDeleteConfirmation = () => {
    this.setState({
      openDeleteConfirmationPopup: false,
    });
  };

  openDeleteConfirmation = (domain: string) => () => {
    this.setState({
      openDeleteConfirmationPopup: true,
      domainToDelete: domain,
    });
  };

  onSuccess = () => {
    this.setState({
      openAddPopup: false,
    });
  };

  addAdditionalDomain = (domain: any) => {
    let company = JSON.parse(JSON.stringify(this.props.company));

    if (!company.additionalDomains) {
      company.additionalDomains = [];
    }

    company.additionalDomains.push(domain);

    company = {
      ...company,
      ...company.address,
    };
    this.props.update(company);
  };

  removeAdditionalDomain = () => {
    let company = JSON.parse(JSON.stringify(this.props.company));
    company = {
      ...company,
      ...company.address,
      additionalDomains: company.additionalDomains.filter(
        (d: string) => d !== this.state.domainToDelete,
      ),
    };

    this.props.update(company);

    this.setState({
      openDeleteConfirmationPopup: false,
      domainToDelete: null,
    });
  };

  renderLoading() {
    if (_.get(this.props.saveCompanyStatus, 'isLoading', false) === true) {
      return (
        <div className={styles.infoLoader}>
          <Loader visible={true} />
        </div>
      );
    }

    return null;
  }

  render() {
    let paperStyle = styles.paper;

    if (_.get(this.props.saveCompanyStatus, 'isLoading', false) === true) {
      paperStyle = cx(styles.paper, styles.isLoading);
    }

    const additionalDomains = _.get(
      this.props,
      'company.additionalDomains',
      [],
    );

    return (
      <>
        <Paper className={paperStyle}>
          <div className={styles.sectionTitle}>
            <h2>Additional Domains</h2>
            <div className={styles.description}>
              Other domains associated to the company.
            </div>
          </div>
          <Table>
            <TableBody>
              {additionalDomains &&
                additionalDomains.map((domain: any, i: number) => (
                  <TableRow className={cx(styles.tableRow)}>
                    <TableCell
                      key={i}
                      className={cx(styles.tableCell)}
                    >
                      {domain}
                    </TableCell>
                    <TableCell
                      key={'rem'}
                      className={cx(styles.tableCell, styles.removeCell)}
                    >
                      <Button
                        className={styles.buttonRemove}
                        variant="contained"
                        size="small"
                        onClick={this.openDeleteConfirmation(domain)}
                      >
                        Remove
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {this.props.hasPermission && (
            <div
              className={styles.addDomain}
              onClick={this.openAddPopup}
            >
              <div className={styles.addIcon}>
                <SvgAddIcon
                  width={18}
                  height={18}
                />
              </div>
              <div>Add Domain</div>
            </div>
          )}
          {this.renderLoading()}
        </Paper>
        <AddDomainPopup
          error={this.state.saveError}
          showPopup={this.state.openAddPopup}
          closePopup={this.closeAddPopup}
          handleSubmit={this.addAdditionalDomain}
          additionalDomains={additionalDomains}
        />
        <ConfirmationPopup
          open={this.state.openDeleteConfirmationPopup}
          onClose={this.closeDeleteConfirmation}
          onSubmit={this.removeAdditionalDomain}
          title="Are you sure?"
          bodyText={`Are you sure you want to delete "${this.state.domainToDelete}" Additional Domain?`}
          submitButtonText="Delete"
          closeButtonText="Cancel"
        />
      </>
    );
  }
}

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {
    saveCompanyStatus: state.saveCompanyStatus,
  };
}

// Wire up redux dispatch functions
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      update,
      resetSaveCompanyStatus,
    },
    dispatch,
  );
}

// Connect it to Redux
export default connect(mapStateToProps, mapDispatchToProps)(AdditionalDomains);

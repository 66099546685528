import * as React from 'react';
import cx from 'classnames';
import TextFieldWithClear from 'components/TextFieldWithIcon/TextFieldWithIcon';
import DropdownAutoSuggestDynamic from 'components/Inputs/Dropdown/DropdownAutoSuggestDynamic';
import Button from '@material-ui/core/Button';
import _ from 'lodash-es';

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ReduxState } from '../../../reducers/types';

import { get as getUsers, emptyResults as emptyUsers } from 'actions/Users/Get';
import { getSingle as getUser } from 'actions/Users/GetSingle';

const styles = require('./styles.pcss');

class TravelersRecordForm extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      userId: null,
      userDefaultValue: '',
    };
  }

  componentDidMount = () => {
    this.onSubmitForm('initial');
  };

  componentWillReceiveProps = (nextProps: any) => {
    if (
      nextProps.user.isLoading == false &&
      nextProps.user.success == true &&
      Object.keys(nextProps.user.user).length != 0
    ) {
      this.setState({
        userDefaultValue: `${_.get(
          nextProps.user.user,
          'firstName',
          '',
        )} ${_.get(nextProps.user.user, 'lastName', '')}`,
      });
    }
  };

  onChangeFirstName = (event: any) => {
    this.setState({
      firstName: event.target.value,
    });
  };

  onChangeLastName = (event: any) => {
    this.setState({
      lastName: event.target.value,
    });
  };

  onChangeEmail = (event: any) => {
    this.setState({
      email: event.target.value,
    });
  };

  clearFirstName = () => {
    this.setState({
      firstName: '',
    });
  };

  clearLastName = () => {
    this.setState({
      lastName: '',
    });
  };

  clearEmail = () => {
    this.setState({
      email: '',
    });
  };

  onSubmitForm = (initial?: any) => {
    let req: any = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      orderBy: 'lastName',
      order: 'asc',
    };

    if (this.state.userId !== null) {
      req.userId = this.state.userId;
    }

    if (initial == 'initial') {
      req.orderBy = 'created';
      req.order = 'desc';

      const urlParams = new URLSearchParams(window.location.search);

      if (urlParams.get('firstName')) {
        req.firstName = urlParams.get('firstName');
      }

      if (urlParams.get('lastName')) {
        req.lastName = urlParams.get('lastName');
      }

      if (urlParams.get('email')) {
        req.email = urlParams.get('email');
      }

      if (urlParams.get('userId')) {
        req.userId = urlParams.get('userId');

        this.setState({
          userId: req.userId,
        });

        this.props.getUser(req.userId);
      }

      if (urlParams.get('sort')) {
        req.orderBy = urlParams.get('sort');
        req.order = 'asc';
      }

      this.setState({
        firstName: req.firstName,
        lastName: req.lastName,
        email: req.email,
      });
    } else {
      let state = { type: 'traveler' };
      let url = 'lookup?type=traveler';

      if (this.state.firstName) {
        url += `&firstName=${encodeURIComponent(this.state.firstName)}`;
      }

      if (this.state.lastName) {
        url += `&lastName=${encodeURIComponent(this.state.lastName)}`;
      }

      if (this.state.email) {
        url += `&email=${encodeURIComponent(this.state.email)}`;
      }

      if (this.state.userId) {
        url += `&userId=${encodeURIComponent(this.state.userId)}`;
      }

      url += `&sort=${encodeURIComponent('lastName')}`;

      window.history.pushState(state, '', url);
    }

    this.props.onSubmit(req);
  };

  _getSearchButtonText = () => {
    return 'Search';
  };

  onKeyPressed = (e: any) => {
    if (e.key === 'Enter') {
      this.onSubmitForm();
    }
  };

  onChangeUser = (userId: any) => {
    this.setState({
      userId,
    });
  };

  getUsers = (value: string) => {
    this.props.getUsers({
      firstName: value,
      lastName: value,
      email: value,
      type: 'or',
      withReset: true,
    });
  };

  parseUsers = () => {
    return this.props.users.map((u: any) => {
      let name = `${u.firstName} ${u.lastName}`;

      if (name.trim() == '') {
        return {
          id: u.id,
          name: `${u.email}`,
        };
      }

      return {
        id: u.id,
        name: `${u.firstName} ${u.lastName}`,
      };
    });
  };

  emptyUsers = () => {
    this.props.emptyUsers();
  };

  render() {
    return (
      <div
        className={styles.form}
        onKeyDown={(e) => this.onKeyPressed(e)}
      >
        <div className={cx(styles.inputWrapper, styles.small)}>
          <TextFieldWithClear
            onChange={this.onChangeFirstName}
            label="First Name"
            variant="outlined"
            margin="normal"
            value={this.state.firstName}
            onClear={this.clearFirstName}
            fullWidth={true}
          />
        </div>

        <div className={cx(styles.inputWrapper, styles.small)}>
          <TextFieldWithClear
            onChange={this.onChangeLastName}
            label="Last Name"
            variant="outlined"
            margin="normal"
            value={this.state.lastName}
            onClear={this.clearLastName}
            fullWidth={true}
          />
        </div>

        <div className={cx(styles.inputWrapper, styles.small)}>
          <TextFieldWithClear
            onChange={this.onChangeEmail}
            label="Email"
            variant="outlined"
            margin="normal"
            value={this.state.email}
            onClear={this.clearEmail}
            fullWidth={true}
          />
        </div>

        <div className={cx(styles.inputWrapper, styles.small)}>
          <DropdownAutoSuggestDynamic
            className={styles.dropdownContainer}
            onChange={this.onChangeUser}
            margin="normal"
            label="User"
            list={this.parseUsers()}
            getList={this.getUsers}
            emptyResults={this.emptyUsers}
            isLoading={this.props.isLoadingUsers || this.props.user.isLoading}
            value={this.state.userDefaultValue}
          />
        </div>

        <div className={styles.inputWrapper}>
          <Button
            fullWidth
            className={styles.button}
            variant="contained"
            color="primary"
            onClick={this.onSubmitForm}
          >
            {this._getSearchButtonText()}
          </Button>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getUsers,
      emptyUsers,
      getUser,
    },
    dispatch,
  );
}

function mapStateToProps(state: ReduxState) {
  return {
    users: state.users.users,
    user: state.user,
    isLoadingUsers: state.users.isLoading,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TravelersRecordForm);

import * as React from 'react';
import _ from 'lodash-es';
import Paper from '@material-ui/core/Paper';
import Loader from 'components/Loader/Loader';
const styles = require('./styles.pcss');
import { Agency, ReduxState } from '../../reducers/types';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { putSingle } from 'actions/Agencies/PutSingle';
import ChangeColorRow from './ChangeColorRow';

type Props = {
  agency: Agency;
  disabled: boolean;
  putSingle: Function;
  isLoading: boolean;
};

type State = {
  primaryColor: string;
  successColor: string;
  errorColor: string;
  secondaryColor: string;
  tertiaryColor: string;
  validationPrimaryColor: string;
  validationSuccessColor: string;
  validationErrorColor: string;
  validationSecondaryColor: string;
  validationTertiaryColor: string;
};

class AppAppearance extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      primaryColor: _.get(this.props, 'agency.uiSettings.primaryColor', ''),
      successColor: _.get(this.props, 'agency.uiSettings.successColor', ''),
      errorColor: _.get(this.props, 'agency.uiSettings.errorColor', ''),
      secondaryColor: _.get(this.props, 'agency.uiSettings.secondaryColor', ''),
      tertiaryColor: _.get(this.props, 'agency.uiSettings.tertiaryColor', ''),
      validationPrimaryColor: '',
      validationSuccessColor: '',
      validationErrorColor: '',
      validationSecondaryColor: '',
      validationTertiaryColor: '',
    };
  }

  validHexValue = (value: any) => {
    return /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i.test(value);
  };

  primaryColorChange = (e: any) => {
    this.setState({
      primaryColor: e.color,
    });
  };

  successColorChange = (e: any) => {
    this.setState({
      successColor: e.color,
    });
  };

  errorColorChange = (e: any) => {
    this.setState({
      errorColor: e.color,
    });
  };

  secondaryColorChange = (e: any) => {
    this.setState({
      secondaryColor: e.color,
    });
  };

  tertiaryColorChange = (e: any) => {
    this.setState({
      tertiaryColor: e.color,
    });
  };

  primaryColorClose = (e: any) => {
    if (
      this.validHexValue(e.color) &&
      e.color != '' &&
      e.color != _.get(this.props, 'agency.uiSettings.primaryColor', '')
    ) {
      this.props.putSingle(
        _.get(this.props, 'agency.id', null),
        {
          id: _.get(this.props, 'agency.id', null),
          'uiSettings.primaryColor': e.color,
        },
        'color',
      );
    }
  };

  successColorClose = (e: any) => {
    if (
      this.validHexValue(e.color) &&
      e.color != '' &&
      e.color != _.get(this.props, 'agency.uiSettings.successColor', '')
    ) {
      this.props.putSingle(
        _.get(this.props, 'agency.id', null),
        {
          id: _.get(this.props, 'agency.id', null),
          'uiSettings.successColor': e.color,
        },
        'color',
      );
    }
  };

  errorColorClose = (e: any) => {
    if (
      this.validHexValue(e.color) &&
      e.color != '' &&
      e.color != _.get(this.props, 'agency.uiSettings.errorColor', '')
    ) {
      this.props.putSingle(
        _.get(this.props, 'agency.id', null),
        {
          id: _.get(this.props, 'agency.id', null),
          'uiSettings.errorColor': e.color,
        },
        'color',
      );
    }
  };

  secondaryColorClose = (e: any) => {
    if (
      this.validHexValue(e.color) &&
      e.color != '' &&
      e.color != _.get(this.props, 'agency.uiSettings.secondaryColor', '')
    ) {
      this.props.putSingle(
        _.get(this.props, 'agency.id', null),
        {
          id: _.get(this.props, 'agency.id', null),
          'uiSettings.secondaryColor': e.color,
        },
        'color',
      );
    }
  };

  tertiaryColorClose = (e: any) => {
    if (
      this.validHexValue(e.color) &&
      e.color != '' &&
      e.color != _.get(this.props, 'agency.uiSettings.tertiaryColor', '')
    ) {
      this.props.putSingle(
        _.get(this.props, 'agency.id', null),
        {
          id: _.get(this.props, 'agency.id', null),
          'uiSettings.tertiaryColor': e.color,
        },
        'color',
      );
    }
  };

  onChangeTextPrimaryColor = (e: any) => {
    if (!this.validHexValue(e.target.value)) {
      this.setState({
        validationPrimaryColor: 'Invalid hex value',
        primaryColor: e.target.value,
      });
    } else {
      this.setState({
        validationPrimaryColor: '',
        primaryColor: e.target.value,
      });

      if (
        e.target.value !=
        _.get(this.props, 'agency.uiSettings.primaryColor', '')
      ) {
        this.props.putSingle(
          _.get(this.props, 'agency.id', null),
          {
            id: _.get(this.props, 'agency.id', null),
            'uiSettings.primaryColor': e.target.value,
          },
          'color',
        );
      }
    }
  };

  onChangeTextSuccessColor = (e: any) => {
    if (!this.validHexValue(e.target.value)) {
      this.setState({
        validationSuccessColor: 'Invalid hex value',
        successColor: e.target.value,
      });
    } else {
      this.setState({
        validationSuccessColor: '',
        successColor: e.target.value,
      });
      if (
        e.target.value !=
        _.get(this.props, 'agency.uiSettings.successColor', '')
      ) {
        this.props.putSingle(
          _.get(this.props, 'agency.id', null),
          {
            id: _.get(this.props, 'agency.id', null),
            'uiSettings.successColor': e.target.value,
          },
          'color',
        );
      }
    }
  };

  onChangeTextErrorColor = (e: any) => {
    if (!this.validHexValue(e.target.value)) {
      this.setState({
        validationErrorColor: 'Invalid hex value',
        errorColor: e.target.value,
      });
    } else {
      this.setState({
        validationErrorColor: '',
        errorColor: e.target.value,
      });

      if (
        e.target.value != _.get(this.props, 'agency.uiSettings.errorColor', '')
      ) {
        this.props.putSingle(
          _.get(this.props, 'agency.id', null),
          {
            id: _.get(this.props, 'agency.id', null),
            'uiSettings.errorColor': e.target.value,
          },
          'color',
        );
      }
    }
  };

  onChangeTextSecondaryColor = (e: any) => {
    if (!this.validHexValue(e.target.value)) {
      this.setState({
        validationSecondaryColor: 'Invalid hex value',
        secondaryColor: e.target.value,
      });
    } else {
      this.setState({
        validationSecondaryColor: '',
        secondaryColor: e.target.value,
      });

      if (
        e.target.value !=
        _.get(this.props, 'agency.uiSettings.secondaryColor', '')
      ) {
        this.props.putSingle(
          _.get(this.props, 'agency.id', null),
          {
            id: _.get(this.props, 'agency.id', null),
            'uiSettings.secondaryColor': e.target.value,
          },
          'color',
        );
      }
    }
  };

  onChangeTextTertiaryColor = (e: any) => {
    if (!this.validHexValue(e.target.value)) {
      this.setState({
        validationTertiaryColor: 'Invalid hex value',
        tertiaryColor: e.target.value,
      });
    } else {
      this.setState({
        validationTertiaryColor: '',
        tertiaryColor: e.target.value,
      });

      if (
        e.target.value !=
        _.get(this.props, 'agency.uiSettings.tertiaryColor', '')
      ) {
        this.props.putSingle(
          _.get(this.props, 'agency.id', null),
          {
            id: _.get(this.props, 'agency.id', null),
            'uiSettings.tertiaryColor': e.target.value,
          },
          'color',
        );
      }
    }
  };

  render() {
    return (
      <Paper className={styles.paper}>
        {this.props.isLoading && (
          <div className={styles.loaderContainer}>
            <Loader visible={this.props.isLoading} />
          </div>
        )}

        <h2>App Appeareance</h2>

        <ChangeColorRow
          description="The main color used throughout the app."
          text="Primary Color"
          color={this.state.primaryColor}
          onChange={this.primaryColorChange}
          onClose={this.primaryColorClose}
          onChangeText={this.onChangeTextPrimaryColor}
          validationError={this.state.validationPrimaryColor}
        />

        <ChangeColorRow
          description="Color used for hover states."
          text="Secondary Color"
          color={this.state.secondaryColor}
          onChange={this.secondaryColorChange}
          onClose={this.secondaryColorClose}
          onChangeText={this.onChangeTextSecondaryColor}
          validationError={this.state.validationSecondaryColor}
        />

        <ChangeColorRow
          description="Color used for pressed states."
          text="Tertiary Color"
          color={this.state.tertiaryColor}
          onChange={this.tertiaryColorChange}
          onClose={this.tertiaryColorClose}
          onChangeText={this.onChangeTextTertiaryColor}
          validationError={this.state.validationTertiaryColor}
        />

        <ChangeColorRow
          description="Color used to highlight success."
          text="Success Color"
          color={this.state.successColor}
          onChange={this.successColorChange}
          onClose={this.successColorClose}
          onChangeText={this.onChangeTextSuccessColor}
          validationError={this.state.validationSuccessColor}
        />

        <ChangeColorRow
          description="Color used to highlight errors."
          text="Error Color"
          color={this.state.errorColor}
          onChange={this.errorColorChange}
          onClose={this.errorColorClose}
          onChangeText={this.onChangeTextErrorColor}
          validationError={this.state.validationErrorColor}
        />
      </Paper>
    );
  }
}

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {};
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      putSingle,
    },
    dispatch,
  );
}

// Connect it to Redux
export default connect(mapStateToProps, mapDispatchToProps)(AppAppearance);

import React from 'react';

type Props = {
  width: number;
  height: number;
};

const SvgIconDiscover = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Discover</title>
      <desc>Created with Sketch.</desc>
      <defs>
        <path
          d="M0,16 C0,24.838 7.163,32 16,32 C24.839,32 32,24.837 32,16 C32,7.161 24.837,0 16,0 C7.161,0 0,7.163 0,16 Z"
          id="path-1"
        ></path>
        <path
          d="M10,16 C10,19.314 12.686,22 16,22 C19.314,22 22,19.314 22,16 C22,12.686 19.314,10 16,10 C12.686,10 10,12.686 10,16 Z"
          id="path-3"
        ></path>
      </defs>
      <g
        id="Assets"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Discover">
          <mask
            id="mask-2"
            fill="white"
          >
            <use xlinkHref="#path-1"></use>
          </mask>
          <use
            id="Clip-2"
            fill="#F8F8F9"
            xlinkHref="#path-1"
          ></use>
          <path
            d="M16.283,32.206 C23.811,32.206 30.106,27.002 31.811,20 C27.275,22.557 19.239,26.509 8,29.876 C10.418,31.345 13.247,32.206 16.283,32.206"
            id="Fill-4"
            fill="#E67730"
          ></path>
          <mask
            id="mask-4"
            fill="white"
          >
            <use xlinkHref="#path-3"></use>
          </mask>
          <use
            id="Clip-7"
            fill="#E67730"
            xlinkHref="#path-3"
          ></use>
        </g>
      </g>
    </svg>
  );
};

export default SvgIconDiscover;

import * as React from 'react';
import _ from 'lodash-es';
import cx from 'classnames';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import ApproversTableRows from './ApproversTableRows';
import TableRow from '@material-ui/core/TableRow';
import Loader from 'components/Loader/Loader';

const styles = require('./styles.pcss');

import { ReduxState } from 'reducers/types';

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

type Props = {
  approversList: any;
  loading: any;
};

class Approvers extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  renderEmptyApprovers = () => {
    if (this.props.approversList.length < 1) {
      return <div className={styles.emptyData}>No Approvers</div>;
    }

    return null;
  };

  render() {
    return (
      <Paper
        className={
          this.props.loading
            ? cx(styles.paper, styles.approversPaper, styles.loading)
            : cx(styles.paper, styles.approversPaper)
        }
      >
        <div className={styles.policyRules}>
          <h2>Approvers</h2>
          <div>
            <Loader visible={this.props.loading} />
          </div>
          <Table>
            <TableBody>
              {this.props.approversList.map((user: any) => {
                return <ApproversTableRows user={user} />;
              })}
            </TableBody>
          </Table>
          {this.renderEmptyApprovers()}
        </div>
      </Paper>
    );
  }
}

export default Approvers;

import React from 'react';

type Props = {
  width: number;
  height: number;
};

const SvgRadioChecked = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Group</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Master"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Travel---Hotel-Search-Results---Review-Filter"
          transform="translate(-495.000000, -328.000000)"
        >
          <g
            id="reviews---filter"
            transform="translate(471.000000, 243.000000)"
          >
            <g
              id="Group"
              transform="translate(24.000000, 85.000000)"
            >
              <rect
                id="Rectangle-13"
                fill="#318AFB"
                x="0"
                y="0"
                width="16"
                height="16"
                rx="8"
              ></rect>
              <rect
                id="Rectangle-13"
                fill="#FFFFFF"
                x="5"
                y="5"
                width="6"
                height="6"
                rx="3"
              ></rect>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

SvgRadioChecked.defaultProps = {
  width: 16,
  height: 16,
};

export default SvgRadioChecked;

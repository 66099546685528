/**
 * PUT Credit card departments
 */
import _ from 'lodash-es';
import { Dispatch } from 'redux';

import {
  ACTION_PUT_CREDITCARD,
  ACTION_PUT_CREDITCARD_SUCCESS,
  ACTION_PUT_CREDITCARD_FAILURE,
  ACTION_RESET_EDIT_CREDIT_CARD_SUCCESS,
  API_CALL,
} from '../../configs/ActionTypes';

import { track } from '../../analytics/analytics';
import { enqueueSnackbarHandler } from '../Status';

export function putCreditCard(data: any, type: any) {
  return (dispatch: Dispatch, getState: Function) => {
    dispatch(requestStarted());

    //Dispatch the api call request.
    dispatch({
      type: API_CALL,
      payload: {
        call: 'creditCards',
        method: 'put',
        data: JSON.stringify(data),
        success: (response: object) => {
          dispatch(requestSuccess(response));
          handleTrack(type);
          enqueueSnackbarHandler(
            dispatch,
            { editCreditCardSuccess: true },
            'success',
          );
        },
        fail: (error: any) => {
          dispatch(requestFailed(error));
          enqueueSnackbarHandler(dispatch, error.payload, 'error');
        },
      },
    });

    return null;
  };
}

export function resetEditCredCardStatus() {
  return (dispatch: Dispatch, getState: Function) => {
    dispatch({
      type: ACTION_RESET_EDIT_CREDIT_CARD_SUCCESS,
      payload: null,
    });
  };
}

export function requestStarted() {
  return {
    type: ACTION_PUT_CREDITCARD,
    payload: null,
  };
}

function requestSuccess(responeJSON: object) {
  return {
    type: ACTION_PUT_CREDITCARD_SUCCESS,
    payload: responeJSON,
  };
}

function requestFailed(responeJSON: object) {
  return {
    type: ACTION_PUT_CREDITCARD_FAILURE,
    payload: responeJSON,
  };
}

function handleTrack(type: any) {
  let properties = {
    Type: type,
  };

  track('BB_CreditCardUpdated', properties);
}

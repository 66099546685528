import _ from 'lodash-es';

const requiredPostalCountries = require('./requiredPostalCountries.js');
const requiredRegionCountries = require('./requiredRegionCountries.js');

export function missingBillingAddress(card: any) {
  if (
    _.get(card, 'billingAddress.country', null) === null ||
    _.get(card, 'billingAddress.country', '').trim() == ''
  ) {
    return true;
  }

  let zipCodeRequired =
    requiredPostalCountries.requiredPostalCountriesCode.includes(
      _.get(card, 'billingAddress.country', ''),
    );
  let stateRequired =
    requiredRegionCountries.requiredRegionCountriesCode.includes(
      _.get(card, 'billingAddress.country', ''),
    );

  if (
    (_.get(card, 'billingAddress.street', null) === null ||
      _.get(card, 'billingAddress.street', '').trim()) == '' ||
    _.get(card, 'billingAddress.city', null) === null ||
    _.get(card, 'billingAddress.city', '').trim() == '' ||
    ((_.get(card, 'billingAddress.state', null) === null ||
      _.get(card, 'billingAddress.state', '').trim()) == '' &&
      stateRequired) ||
    ((_.get(card, 'billingAddress.postalCode', null) === null ||
      _.get(card, 'billingAddress.postalCode', '').trim()) == '' &&
      zipCodeRequired)
  ) {
    return true;
  }

  return false;
}

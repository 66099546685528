import * as React from 'react';
import cx from 'classnames';
import _ from 'lodash-es';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import SvgAirplane from '../../../static/icons/svgAirplane';
import SvgArrowDown from '../../../static/icons/svgArrowDown';
import FlightDetails from 'components/Popup/FlightDetails';
import { AIRLINE_NAME_MAP } from '../../../helpers/airlinemap';

import Link from '@material-ui/core/Link';

const styles = require('./styles.pcss');

type Props = {
  segment: any;
  last: any;
  confirmationNumbers: Array<any>;
  locations: Array<any>;
  passengers: Array<any>;
  seatAssignments: Array<any>;
};

class Segment extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);

    this.state = {
      openFlightPopup: false,
      detailsPopupOpen: false,
    };
  }

  openPopup = () => {
    this.setState({
      detailsPopupOpen: true,
    });
  };

  onCloseDetailsPopup = () => {
    this.setState({
      detailsPopupOpen: false,
    });
  };

  renderAirlineLogo = (airline: string) => {
    try {
      return (
        <div className={styles.logo}>
          <img src={`/static/images/airlines/${airline}.png`} />
        </div>
      );
    } catch (err) {
      return null;
    }
  };

  render() {
    const dep = _.get(this.props, 'segment.segment[0].departure', '');
    let departureTime = moment(dep);
    let confirmationNumber = _.get(
      this.props.confirmationNumbers,
      _.get(this.props.segment, 'segment[0].airline', ''),
      '',
    );
    let length = _.get(this.props, 'segment.segment', []).length;
    let arrivalDate = moment(
      _.get(this.props.segment.segment[length - 1], 'arrival', ''),
    );
    let difference = arrivalDate.diff(departureTime);
    let time =
      moment
        .utc(moment.duration(difference, 'milliseconds').asMilliseconds())
        .format('HH') +
      'h ' +
      moment
        .utc(moment.duration(difference, 'milliseconds').asMilliseconds())
        .format('mm') +
      'm';
    let airline = _.get(this.props, 'segment.segment[0].airline', '');

    return (
      <Paper className={styles.segmentDetail}>
        <div className={styles.container}>
          <div className={styles.destinations}>
            <span className="destinationFrom">
              {_.get(this.props, 'segment.from', '')}
            </span>
            <SvgAirplane
              width={20}
              height={16}
            />
            <span className="destinationTo">
              {_.get(this.props, 'segment.to', '')}
            </span>
          </div>

          <div>
            <p className={styles.labels}>Departure</p>
            <p className={cx(styles.details, 'detailsTime')}>
              {' '}
              {moment(dep).utcOffset(dep).format('LT')}{' '}
            </p>
            <div className={styles.logoContainer}>
              {this.renderAirlineLogo(airline)}
              <p className={cx(styles.airline, 'airline')}>
                {' '}
                {_.get(AIRLINE_NAME_MAP, airline, '')}{' '}
                {_.get(this.props, 'segment.segment[0].flightNumber', '')}
              </p>
            </div>
          </div>

          <div>
            <p className={styles.labels}>Time</p>
            <p className={cx(styles.details, 'time')}> {time} </p>
          </div>

          <div className={styles.confirmationNumber}>
            <p className={styles.labels}>Confirmation Number</p>
            <p className={cx(styles.confNumb, 'confNr')}>
              {' '}
              {confirmationNumber}{' '}
            </p>
          </div>
        </div>

        <div
          className={
            !this.props.last
              ? cx(styles.withBorder, styles.flightDetailsPopupButton)
              : cx(styles.flightDetailsPopupButton)
          }
        >
          <span
            onClick={this.openPopup}
            className={styles.detailsLink}
          >
            Flight details
            <div className={styles.arrowLink}>
              <SvgArrowDown
                width={9}
                height={6}
              />
            </div>
          </span>
        </div>
        <FlightDetails
          open={this.state.detailsPopupOpen}
          onClose={this.onCloseDetailsPopup}
          segments={_.get(this.props.segment, 'segment', [])}
          locations={_.get(this.props, 'locations', [])}
          passengers={_.get(this.props, 'passengers', [])}
          key={_.get(this.props.segment, 'segment.segmentKey', 1)}
          seatAssignments={_.get(this.props, 'seatAssignments', [])}
        />
      </Paper>
    );
  }
}

export default Segment;

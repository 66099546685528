import { combineReducers } from 'redux';

import UserLogin from './UserLogin';
import Travelers from './Travelers';
import Traveler from './Traveler';
import Users from './Users';
import User from './User';
import Companies from './Companies';
import Company from './Company';
import Status from './Status';
import SaveCompany from './SaveCompany';
import Places from './Places';
import Hotels from './Hotels';
import ImportEmployees from './ImportEmployees';
import MyProfile from './MyProfile';
import ResetPassword from './ResetPassword';
import Bookings from './Bookings';
import FrequentFlyers from './FrequentFlyers';
import HotelRooms from './HotelRooms';
import AddCreditCard from './AddCreditCard';
import EditCreditCard from './EditCreditCard';
import DeleteCreditCard from './DeleteCreditCard';
import PostBooking from './PostBooking';
import CreditCards from './CreditCards';
import UserTravelPolicyRules from './UserTravelPolicyRules';
import UserBudgetPolicyRules from './UserBudgetPolicyRules';
import BetaFeatures from './BetaFeatures';
import OrganizationProductsSettings from './OrganizationProductsSettings';
import OrganizationBasicEconomyFeature from './OrganizationBasicEconomyFeature';
import CorporateRateCodes from './CorporateRateCodes';
import UpdateUserStatus from './UpdateUserStatus';
import GetCreditCards from './GetCreditCards';
import Booking from './Booking';
import Approvers from './Approvers';
import OrganizationTrustedCompanyFeature from './OrganizationTrustedCompanyFeature';
import NegotiatedHotels from './NegotiatedHotels';
import ExclusiveHotels from './ExclusiveHotels';
import Features from './Features';
import UserFraudRule from './UserFraudRule';
import OrganizationFraudRule from './OrganizationFraudRule';
import BookingCancelInfo from './BookingCancelInfo';
import CancelBooking from './CancelBooking';
import BookingNewPayment from './BookingNewPayment';
import BookingRefund from './BookingRefund';
import ReimbursementSettings from './ReimbursementSettings';
import AddEmployee from './AddEmployee';
import UpdateEmployee from './UpdateEmployee';
import OrganizationActiveExpenseIntegration from './OrganizationActiveExpenseIntegration';
import Agencies from './Agencies';
import Agency from './Agency';
import AgencySetting from './AgencySetting';
import OrganizationActiveExpenseManagement from './OrganizationActiveExpenseManagement';
import BBUsers from './BBUsers';
import AgencyPermissions from './AgencyPermissions';
import ExpensePolicy from './ExpensePolicy';
import CarLoyaltyPrograms from './CarLoyaltyPrograms';
import SpecialRateCodes from './SpecialRateCodes';
import OrganizationBankAccount from './OrganizationBankAccount';
import Roles from './Roles';
import UserBankAccount from './UserBankAccount';
import OrganizationReimbursementFee from './OrganizationReimbursementFee';
import AddBBUser from './AddBBUser';
import CompanyEmployees from './CompanyEmployees';
import UserAnalyticsAccess from './UserAnalyticsAccess';
import FlightTourCodes from './FlightTourCodes';
import UpdateBBUser from './UpdateBBUser';
import CompanyEmployeesSimple from './CompanyEmployeesSimple';
import UserExpenseReports from './UserExpenseReports';
import AddDepartment from './AddDepartment';
import GetManagers from './GetManagers';
import OrganizationFlightBookingWindowFeature from './OrganizationFlightBookingWindowFeature';
import OrganizationVerifiedForTravelFeature from './OrganizationVerifiedForTravelFeature';
import GetDepartments from './GetDepartments';
import ExpenseSplitSetting from './ExpenseSplitSetting';
import LicensePricing from './LicensePricing';
import CarbonEmissionProducts from './CarbonEmissionProducts';
import OrganizationSettings from './OrganizationSettings';
import OrganizationPaidPlanSetting from './OrganizationPaidPlanSetting';

// Define the root reducer which combines all the separate reducers.
const AppReducer = combineReducers({
  userLogin: UserLogin,
  travelers: Travelers,
  traveler: Traveler,
  users: Users,
  user: User,
  companies: Companies,
  company: Company,
  saveCompanyStatus: SaveCompany,
  notifications: Status,
  places: Places,
  hotels: Hotels,
  importEmployees: ImportEmployees,
  myProfile: MyProfile,
  resetPassword: ResetPassword,
  bookings: Bookings,
  frequentFlyers: FrequentFlyers,
  hotelRooms: HotelRooms,
  addCreditCard: AddCreditCard,
  editCreditCardStatus: EditCreditCard,
  deleteCreditCardStatus: DeleteCreditCard,
  postBooking: PostBooking,
  creditCards: CreditCards,
  userTravelPolicyRules: UserTravelPolicyRules,
  betaFeatures: BetaFeatures,
  organizationProductsSettings: OrganizationProductsSettings,
  corporateRateCodes: CorporateRateCodes,
  updateUserStatus: UpdateUserStatus,
  getCreditCards: GetCreditCards,
  booking: Booking,
  approvers: Approvers,
  organizationTrustedCompanyFeature: OrganizationTrustedCompanyFeature,
  negotiatedHotels: NegotiatedHotels,
  exclusiveHotels: ExclusiveHotels,
  features: Features,
  userFraudRule: UserFraudRule,
  organizationFraudRule: OrganizationFraudRule,
  updateEmployee: UpdateEmployee,
  bookingCancelInfo: BookingCancelInfo,
  cancelBooking: CancelBooking,
  bookingNewPayment: BookingNewPayment,
  bookingRefund: BookingRefund,
  reimbursementSettings: ReimbursementSettings,
  organizationBasicEconomyFeature: OrganizationBasicEconomyFeature,
  addEmployee: AddEmployee,
  organizationActiveExpenseIntegration: OrganizationActiveExpenseIntegration,
  agencies: Agencies,
  agency: Agency,
  agencySetting: AgencySetting,
  organizationSettings: OrganizationSettings,
  organizationActiveExpenseManagement: OrganizationActiveExpenseManagement,
  bbusers: BBUsers,
  agencyPermissions: AgencyPermissions,
  expensePolicy: ExpensePolicy,
  carLoyaltyPrograms: CarLoyaltyPrograms,
  organizationBankAccount: OrganizationBankAccount,
  roles: Roles,
  userBankAccount: UserBankAccount,
  organizationReimbursementFee: OrganizationReimbursementFee,
  addBBUser: AddBBUser,
  updateBBUser: UpdateBBUser,
  employees: CompanyEmployees,
  employeesSimple: CompanyEmployeesSimple,
  userAnalyticsAccess: UserAnalyticsAccess,
  flightTourCodes: FlightTourCodes,
  userBudgetPolicyRules: UserBudgetPolicyRules,
  userExpenseReports: UserExpenseReports,
  addDepartment: AddDepartment,
  getManagers: GetManagers,
  organizationFlightBookingWindowFeature:
    OrganizationFlightBookingWindowFeature,
  getDepartments: GetDepartments,
  expenseSplitSetting: ExpenseSplitSetting,
  verifiedForTravelFeature: OrganizationVerifiedForTravelFeature,
  licensePricingSetting: LicensePricing,
  specialRateCodes: SpecialRateCodes,
  carbonEmissionOffset: CarbonEmissionProducts,
  organizationPaidPlanSetting: OrganizationPaidPlanSetting,
});

export const RootReducer = (state: any, action: any) => {
  if (
    action.type === 'LOGOUT_SUCCESS' ||
    action.type === 'LOGIN' ||
    action.type === 'GET_LOGIN_FAILURE'
  ) {
    state = undefined;
  }

  return AppReducer(state, action);
};

export default RootReducer;

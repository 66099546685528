import * as React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = require('./styles.pcss');

type Props = {
  visible: boolean;
  id?: any;
};

const Loader = (props: Props) => {
  if (props.visible === false) {
    return null;
  }

  return (
    <div className={styles.loaderLayout}>
      <CircularProgress
        size={32}
        className={styles.sCircularProgress}
        id={props.id ? props.id : ''}
      />
    </div>
  );
};

export default Loader;

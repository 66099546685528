import {
  ACTION_GET_ORG_SETTINGS,
  ACTION_GET_ORG_SETTINGS_SUCCESS,
  ACTION_GET_ORG_SETTINGS_FAILURE,
} from '../configs/ActionTypes';
import { OrgSettings } from './types';

export interface OrganizationSettingsState {
  settings: OrgSettings;
  isLoading: boolean;
  success: boolean | null;
}

const initialState: OrganizationSettingsState = {
  settings: {},
  isLoading: false,
  success: null,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_GET_ORG_SETTINGS: {
      return {
        ...state,
        isLoading: true,
        success: null,
      };
    }

    case ACTION_GET_ORG_SETTINGS_SUCCESS: {
      const settings = action.payload.payload;

      return {
        ...state,
        settings,
        isLoading: false,
        success: true,
      };
    }

    case ACTION_GET_ORG_SETTINGS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        success: false,
      };
    }

    default: {
      return state;
    }
  }
}

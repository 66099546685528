import * as React from 'react';
import _ from 'lodash-es';
import cx from 'classnames';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import CloseIcon from '@material-ui/icons/Close';
import { KeyboardArrowDown } from '@material-ui/icons';
import { FormHelperText } from '@material-ui/core';

const styles = require('./styles.pcss');

type Props = {
  value: Array<any>;
  label?: string;
  labelWidth?: number;
  selections: Array<any>;
  onChange: any;
  error?: any;
  disabled?: any;
};

class MultiSelect extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  onOpen = (e: any) => {
    this.setState({
      open: true,
    });
  };

  onClose = (e: any) => {
    this.setState({
      open: false,
    });
  };

  onChange = (event: any, child: any) => {
    let value = _.cloneDeep(this.props.value);

    if (!_.includes(this.props.value, child.key)) {
      value.push(child.key);
    }

    this.props.onChange(value);
  };

  handleDelete = (key: any) => () => {
    let value = _.cloneDeep(this.props.value);

    _.remove(value, (v) => v === key);

    this.props.onChange(value);
  };

  render() {
    let labelWidth =
      this.props.value.length > 0 ? this.props.labelWidth || 100 : 0;
    let shrink = this.props.value.length > 0 ? true : false;
    return (
      <FormControl
        error={this.props.error}
        className={styles.formControl}
        margin="normal"
        variant="outlined"
        disabled={this.props.disabled}
      >
        <InputLabel
          htmlFor="select-multiple"
          shrink={shrink}
        >
          {this.props.label}
        </InputLabel>
        <Select
          className={this.state.open ? styles.selectedM : styles.selectM}
          multiple
          value={this.props.value}
          onChange={this.onChange}
          onOpen={this.onOpen}
          onClose={this.onClose}
          open={this.state.open}
          IconComponent={() => (
            <KeyboardArrowDown className={styles.iconStyle} />
          )}
          input={
            <OutlinedInput
              error={this.props.error}
              inputProps={{ className: styles.selectInput }}
              fullWidth
              labelWidth={labelWidth}
              name="select-multiple"
            />
          }
          renderValue={(selected: Array<any>) => (
            <div className={styles.chips}>
              {selected.map((item) => {
                const selection = _.find(
                  this.props.selections,
                  (s) => s.key === item,
                );

                return (
                  <Chip
                    key={selection.key}
                    label={selection.value}
                    className={styles.chip}
                    onDelete={this.handleDelete(selection.key)}
                    deleteIcon={<CloseIcon className={styles.deleteChipIcon} />}
                  />
                );
              })}
            </div>
          )}
          MenuProps={{
            style: { marginTop: 4, boxShadow: 'none' },
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
          }}
        >
          {this.props.selections.map((selection) => {
            let className = styles.menuItem;
            if (_.includes(this.props.value, selection.key)) {
              className = cx(styles.menuItem, styles.menuItemSelected);
            }

            return (
              <MenuItem
                key={selection.key}
                value={selection.value}
                className={className}
              >
                {selection.value}
              </MenuItem>
            );
          })}
        </Select>
        {this.props.error && (
          <FormHelperText error={true}>{this.props.error}</FormHelperText>
        )}
      </FormControl>
    );
  }
}

export default MultiSelect;

import React from 'react';
import Switch from '@material-ui/core/Switch';
const styles = require('./styles.pcss');
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
type Props = {
  enabled: boolean;
  handleClick?: any;
  id?: any;
  hasPermission: boolean;
};

class CustomizedSwitches extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);
  }

  handleChange = (event: any, value: any) => {
    this.props.handleClick(event);
  };

  render() {
    return (
      <FormControlLabel
        control={
          <Switch
            disabled={this.props.hasPermission}
            color={'primary'}
            classes={{
              switchBase: styles.iOSSwitchBase,
              bar: this.props.enabled ? styles.iOSBarON : styles.iOSBar,
              icon: styles.iOSIcon,
              iconChecked: styles.iOSIconChecked,
              checked: styles.iOSChecked,
              colorPrimary: styles.colorPrimary,
              root: styles.root,
              disabled: styles.disabled,
            }}
            disableRipple
            checked={this.props.enabled}
            onChange={this.handleChange}
            value="checked"
            id={this.props.id}
          />
        }
        label=""
      />
    );
  }
}

export default CustomizedSwitches;

import React from 'react';

type Props = {
  width: number;
  height: number;
};

const SvgIconSearchSmall = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 13 13"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Master"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Organization-Macro---Employees---Add---Department-Dropdown"
          transform="translate(-152.000000, -41.000000)"
          fill="#6D717A"
        >
          <g
            id="Group-12"
            transform="translate(32.000000, 14.000000)"
          >
            <g id="Dropdown">
              <g id="Search">
                <g transform="translate(16.000000, 16.000000)">
                  <g
                    id="Group-18"
                    transform="translate(104.710963, 7.727494)"
                  >
                    <path
                      d="M2.76275624,11.5680697 C1.40276421,10.230258 1.40276421,8.05459873 2.76275624,6.71678696 C3.44275226,6.04788108 4.33546497,5.71428571 5.22904948,5.71428571 C6.1217622,5.71428571 7.01447491,6.04788108 7.69447093,6.71678696 C9.05359117,8.05459873 9.05359117,10.230258 7.69447093,11.5680697 C6.3344789,12.9058815 4.12274827,12.9058815 2.76275624,11.5680697 M11.9388515,14.5047766 L9.6848854,12.2869057 C11.1658596,10.2363386 10.9859698,7.37377849 9.11849961,5.5369399 C7.03551789,3.48812469 3.64521801,3.48724876 1.56223629,5.5369399 C-0.52074543,7.58663103 -0.52074543,10.9204449 1.56223629,12.9692601 C2.60328188,13.9941057 3.97204755,14.5056525 5.33992268,14.5056525 C6.42549545,14.5056525 7.50305333,14.1701689 8.42476606,13.5254797 L10.6796227,15.7433506 C10.8532787,15.9141582 11.0812579,16 11.3092371,16 C11.5372163,16 11.7643049,15.9141582 11.9388515,15.7433506 C12.2861636,15.4008595 12.2861636,14.8472677 11.9388515,14.5047766"
                      id="Fill-1"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

SvgIconSearchSmall.defaultProps = {
  width: 15,
  height: 15,
};

export default SvgIconSearchSmall;

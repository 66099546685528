/**
 * POST User Action
 */
import _ from 'lodash-es';
import { Dispatch } from 'redux';

import {
  ACTION_RESET_PASSWORD_SUCCESS,
  ACTION_RESET_PASSWORD_FAILURE,
  ACTION_RESET_PASSWORD_RESET_STATES,
  ACTION_AFTER_RESET_PASSWORD_SUCCESS,
  API_CALL,
} from '../../configs/ActionTypes';

import { enqueueSnackbarHandler } from '../Status';

/**
 * POST Users Request Action
 */
export function resetPassword(
  currentPassword: string,
  newPassword: string,
  confirmNewPassword: string,
) {
  return (dispatch: Dispatch, getState: Function) => {
    var dataToSend: any = {
      currentPassword: currentPassword,
      newPassword: newPassword,
      confirmNewPassword: confirmNewPassword,
    };

    //Dispatch the api call request.
    dispatch({
      type: API_CALL,
      payload: {
        call: 'profile/resetpw',
        method: 'put',
        data: JSON.stringify(dataToSend),
        success: (response: any) => {
          if (response.type === 'error') {
            dispatch(requestFailed(response));
          } else {
            enqueueSnackbarHandler(
              dispatch,
              { resetPasswordSuccess: true },
              'success',
            );
            dispatch(requestSuccessChangeForceResetPassword());
            dispatch(requestSuccess(response));
          }
        },
        fail: (error: any) => {
          dispatch(requestFailed(error));
        },
      },
    });

    return null;
  };
}

function requestSuccess(responeJSON: object) {
  return {
    type: ACTION_RESET_PASSWORD_SUCCESS,
    payload: responeJSON,
  };
}

function requestSuccessChangeForceResetPassword() {
  return {
    type: ACTION_AFTER_RESET_PASSWORD_SUCCESS,
    payload: null,
  };
}

function requestFailed(responeJSON: object) {
  return {
    type: ACTION_RESET_PASSWORD_FAILURE,
    payload: responeJSON,
  };
}

export function resetStates() {
  return (dispatch: Dispatch, getState: Function) => {
    // Dispatch the api call request.
    dispatch({
      type: ACTION_RESET_PASSWORD_RESET_STATES,
      payload: null,
    });
  };
}

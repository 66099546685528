import React from 'react';

type Props = {
  width: number;
  height: number;
};

const SvgIconHotel = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 64 64"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Master"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Manage-TB"
          transform="translate(-320.000000, -186.000000)"
        >
          <g
            id="Hotels"
            transform="translate(280.000000, 143.000000)"
          >
            <g
              id="Hotel-Icon"
              transform="translate(40.000000, 43.000000)"
            >
              <rect
                id="Rectangle"
                fill="#1B2432"
                x="0"
                y="0"
                width="64"
                height="64"
                rx="32"
              ></rect>
              <path
                d="M47,44 L17,44 L17,26.839154 L23.6149068,26.839154 L23.6149068,23.4069848 L26.9689441,23.4069848 L26.9689441,20 L37.0310559,20 L37.0310559,23.4069848 L40.3850932,23.4069848 L40.3850932,26.839154 L47,26.839154 L47,44 Z M28.0869565,38.279718 L28.0869565,42.8559436 L31.4409938,42.8559436 L31.4409938,38.279718 L28.0869565,38.279718 Z M32.5590062,38.279718 L32.5590062,42.8559436 L35.9264596,42.8559436 L35.9264596,38.279718 L32.5590062,38.279718 Z M23.6149068,38.279718 L21.378882,38.279718 L21.378882,40.5678308 L23.6149068,40.5678308 L23.6149068,38.279718 Z M42.621118,38.279718 L40.3850932,38.279718 L40.3850932,40.5678308 L42.621118,40.5678308 L42.621118,38.279718 Z M30.3229814,33.7034924 L28.0869565,33.7034924 L28.0869565,35.9916052 L30.3229814,35.9916052 L30.3229814,33.7034924 Z M35.9264596,33.7034924 L33.6904348,33.7034924 L33.6904348,35.9916052 L35.9264596,35.9916052 L35.9264596,33.7034924 Z M23.6149068,33.7034924 L21.378882,33.7034924 L21.378882,35.9916052 L23.6149068,35.9916052 L23.6149068,33.7034924 Z M42.621118,33.7034924 L40.3850932,33.7034924 L40.3850932,35.9916052 L42.621118,35.9916052 L42.621118,33.7034924 Z M30.3229814,29.1272668 L28.0869565,29.1272668 L28.0869565,31.4153796 L30.3229814,31.4153796 L30.3229814,29.1272668 Z M35.9264596,29.1272668 L33.6904348,29.1272668 L33.6904348,31.4153796 L35.9264596,31.4153796 L35.9264596,29.1272668 Z M23.6149068,29.1272668 L21.378882,29.1272668 L21.378882,31.4153796 L23.6149068,31.4153796 L23.6149068,29.1272668 Z M42.621118,29.1272668 L40.3850932,29.1272668 L40.3850932,31.4153796 L42.621118,31.4153796 L42.621118,29.1272668 Z M30.3229814,24.5510412 L28.0869565,24.5510412 L28.0869565,26.839154 L30.3229814,26.839154 L30.3229814,24.5510412 Z M35.9264596,24.5510412 L33.6904348,24.5510412 L33.6904348,26.839154 L35.9264596,26.839154 L35.9264596,24.5510412 Z"
                id="Shape"
                fill="#FFFFFF"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgIconHotel;

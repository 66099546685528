import * as React from 'react';
import LayoutDashboard from 'components/Layout/Dashboard';
import SvgIconFlag from '../../static/icons/svgIconFlag';
import SvgIconHotel from '../../static/icons/svgIconHotel';
import Box from 'components/ManageTB/Box/Box';

const styles = require('./styles.pcss');

class ManageTB extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <LayoutDashboard full>
        <div className={styles.paper}>
          <div className={styles.header}>
            <h1 className={styles.h1}>Manage TravelBank</h1>
          </div>
          <div className={styles.body}>
            <Box
              text="Hotels"
              icon={
                <SvgIconHotel
                  width={64}
                  height={64}
                />
              }
              link="manage-tb/hotels"
            />
            <Box
              text="Feature Flags"
              icon={
                <SvgIconFlag
                  width={64}
                  height={64}
                />
              }
              link="manage-tb/feature-flags"
            />
          </div>
        </div>
      </LayoutDashboard>
    );
  }
}

export default ManageTB;

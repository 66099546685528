import * as React from 'react';
import classNames from 'classnames';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import SvgMoreVertical from '../../static/icons/svgMoreVertical';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import SvgAlertIcon from '../../static/icons/svgAlertIcon';
import { CreditCard } from 'reducers/types';

const styles = require('./styles.pcss');

type Props = {
  showRemove?: boolean;
  clickRemove?: any;
  clickMarkAsCorporate?: any;
  clickUpdateBillingAddress?: any;
  showMarkAsCorporate?: boolean;
  missingBillingAddress?: boolean;
  missingBillingAddressExists?: boolean;
  type: any;
  name: any;
  expiration: any;
  card: CreditCard;
  corporate: string;
};

const CreditCardTableRow = (props: Props) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const options = [
    'Update Billing Address',
    'Marks as Corporate Card',
    'Delete',
  ];

  function handleToggle() {
    setOpen((prevOpen) => !prevOpen);
  }

  function handleMenuItemClick(event: any, index: any) {
    switch (index) {
      case 0:
        props.clickUpdateBillingAddress(props.card);

        break;
      case 1:
        props.clickMarkAsCorporate();
        break;
      case 2:
        props.clickRemove();
        break;
    }

    setOpen(false);
  }

  function handleClose(event: React.ChangeEvent<HTMLInputElement>) {
    setOpen(false);
  }

  const displayRemove = (props: Props) => {
    if (props.showRemove && props.showMarkAsCorporate) {
      return (
        <TableCell
          key={'rem'}
          className={classNames(styles.tableCell, styles.removeCell)}
        >
          <Button
            className={classNames(styles.button_more)}
            onClick={handleToggle}
            id="edit-creditCard"
          >
            <SvgMoreVertical
              width={24}
              height={24}
            />
          </Button>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            transition
            disablePortal
            style={{
              zIndex: 1,
              position: 'absolute',
              justifyContent: 'center',
              alignItems: 'center',
              transform: 'scale(1, 1) translateY(-13px) translateX(-133px)',
              minWidth: '233px',
            }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: 'left bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList className={classNames(styles.menuList)}>
                      {options.map((option, index) => (
                        <MenuItem
                          key={option}
                          onClick={(event) => handleMenuItemClick(event, index)}
                        >
                          {index == 0 && props.missingBillingAddress ? (
                            <>
                              {' '}
                              <SvgAlertIcon
                                width={16}
                                height={16}
                              />{' '}
                              {option}
                            </>
                          ) : (
                            <>{option}</>
                          )}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </TableCell>
      );
    }

    if (props.showRemove) {
      return (
        <TableCell
          key={'rem'}
          className={classNames(styles.tableCell, styles.removeCell)}
        >
          <Button
            color="primary"
            onClick={props.clickRemove}
          >
            Remove
          </Button>
        </TableCell>
      );
    }

    if (props.showRemove) {
      return (
        <TableCell
          key={'rem'}
          className={classNames(styles.tableCell, styles.removeCell)}
        >
          <Button
            color="primary"
            onClick={props.clickMarkAsCorporate}
          >
            Marks as Corporate Card
          </Button>
        </TableCell>
      );
    }

    return (
      <TableCell
        key={'rem'}
        className={classNames(styles.tableCell)}
      ></TableCell>
    );
  };

  let classN = props.missingBillingAddressExists
    ? classNames(
        styles.tableCell,
        styles.extraMargin,
        styles.tableCellType,
        styles.tableCellPayment,
      )
    : classNames(
        styles.tableCell,
        styles.tableCellType,
        styles.tableCellPayment,
      );

  return (
    <TableRow className={classNames(styles.tableRow, styles.paymentTableRow)}>
      {props.missingBillingAddress ? (
        <TableCell
          key={'type'}
          className={classNames(
            styles.tableCell,
            styles.tableCellPayment,
            styles.tableCellType,
          )}
        >
          {
            <div className={styles.alertMessageCont}>
              {' '}
              <div className={styles.alertMessageContInside}>
                <SvgAlertIcon
                  width={16}
                  height={16}
                />
                {props.type}
              </div>{' '}
              <span className={styles.alertMessage}>
                Missing Billing Address
              </span>
            </div>
          }
        </TableCell>
      ) : (
        <TableCell
          key={'type'}
          className={classN}
        >
          {props.type}
        </TableCell>
      )}
      <TableCell
        key={'corporate'}
        className={classNames(styles.tableCell, styles.tableCellPayment)}
      >
        {props.corporate}
      </TableCell>
      <TableCell
        key={'name'}
        className={classNames(styles.tableCell, styles.tableCellPayment)}
      >
        {props.name}
      </TableCell>
      <TableCell
        key={'expiration'}
        className={classNames(styles.tableCell, styles.tableCellPayment)}
      >
        {props.expiration}
      </TableCell>

      {displayRemove(props)}
    </TableRow>
  );
};

export default CreditCardTableRow;

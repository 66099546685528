import React from 'react';

type Props = {
  width: number;
  height: number;
};

const SvgIconLock = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 10 13"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Master"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="My-Account"
          transform="translate(-314.000000, -353.000000)"
          fill="#318AFB"
          fillRule="nonzero"
        >
          <g
            id="personal-details"
            transform="translate(280.000000, 143.000000)"
          >
            <g
              id="change-password"
              transform="translate(34.000000, 209.000000)"
            >
              <g
                id="lock"
                transform="translate(0.000000, 1.000000)"
              >
                <path
                  d="M4.69870714,0 C2.6014615,0 0.898819562,1.70264193 0.898819562,3.79988758 L0.898819562,5.97020798 C0.898819562,5.99213041 0.869589657,6.03597527 0.847667229,6.04328274 C0.643057898,6.10905003 0.474985947,6.17481731 0.321528949,6.23327712 C0.138842046,6.30635188 0,6.50365374 0,6.70095559 L0,11.6919618 C0,11.8892636 0.138842046,12.093873 0.314221473,12.1596402 C1.71725689,12.7150084 3.19336706,13 4.69870714,13 C6.20404722,13 7.68015739,12.7150084 9.0831928,12.1596402 C9.26587971,12.0865655 9.39741428,11.8819562 9.39741428,11.6919618 L9.39741428,6.70826307 C9.39741428,6.51096121 9.25857223,6.30635188 9.07588533,6.2405846 C8.92242833,6.18212479 8.75435638,6.12366498 8.54974705,6.05059022 C8.52782462,6.04328274 8.49859472,5.99943789 8.49859472,5.97751546 L8.49859472,3.79988758 C8.49859472,1.70264193 6.78864531,0 4.69870714,0 Z M3.66104553,8.2282181 C3.66104553,7.65823496 4.12141653,7.19055649 4.69870714,7.19055649 C5.26869028,7.19055649 5.73636875,7.65092749 5.73636875,8.2282181 C5.73636875,8.60820686 5.52445194,8.92242833 5.21753794,9.10511523 L5.21753794,10.8150646 C5.21753794,11.1000562 4.98369871,11.3338954 4.69870714,11.3338954 C4.41371557,11.3338954 4.17987634,11.1000562 4.17987634,10.8150646 L4.17987634,9.10511523 C3.87296234,8.92242833 3.66104553,8.60820686 3.66104553,8.2282181 Z M7.19055649,3.79988758 L7.19055649,5.67060146 C6.37211917,5.49522203 5.53175942,5.40753232 4.69870714,5.40753232 C3.85834739,5.40753232 3.02529511,5.49522203 2.19955031,5.67060146 L2.19955031,3.79988758 C2.19955031,2.42608207 3.31759415,1.30803822 4.69139966,1.30803822 C6.07251265,1.30803822 7.19055649,2.42608207 7.19055649,3.79988758 Z"
                  id="Shape"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgIconLock;

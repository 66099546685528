import * as React from 'react';
import cx from 'classnames';
import * as _ from 'lodash-es';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import SvgIconStarColorProp from '../../../static/icons/svgIconStarColorProp';
import SvgLocation from '../../../static/icons/svgLocation';

const styles = require('./styles.pcss');

type Props = {
  booking: any;
};

class HotelBookingDetails extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);
  }

  getRatingText(userRating: any) {
    if (userRating >= 4.5) {
      return '4.5/5 - Wonderful';
    }
    if (userRating >= 4 && userRating <= 4.4) {
      return '4/4.4 - Very good';
    }
    if (userRating >= 3.5 && userRating <= 3.9) {
      return '3.5/3.9 - Good';
    }

    return userRating;
  }

  renderRating = () => {
    const rating = _.get(this.props.booking, 'hotel.userRating', 0);

    return (
      <div className={styles.userRating}>
        <p className={styles.ratingText}>{this.getRatingText(rating)}</p>
        <p className={styles.ratingCount}>
          {'('}
          {_.get(this.props.booking, 'hotel.userRatingCount', 0)} {'Reviews)'}
        </p>
      </div>
    );
  };

  render() {
    let checkOutDate = moment(
      _.get(this.props.booking, 'departureDate', ''),
    ).isValid()
      ? moment(_.get(this.props.booking, 'departureDate', '')).format(
          'ddd, MMM D',
        )
      : '';
    let checkInDate = moment(
      _.get(this.props.booking, 'arrivalDate', ''),
    ).isValid()
      ? moment(_.get(this.props.booking, 'arrivalDate', '')).format(
          'ddd, MMM D',
        )
      : '';
    let checkIn =
      _.get(this.props.booking, 'checkInTime', '') + ' ' + checkInDate;
    let checkOut =
      _.get(this.props.booking, 'checkOutTime', '') + ' ' + checkOutDate;

    return (
      <Paper className={styles.hotelBookingDetails}>
        <div className={styles.header}>
          <div className={styles.hotelName}>
            <p className={styles.hotelName}>
              {' '}
              {_.get(this.props.booking, 'hotel.name', '')}
            </p>
            <p className={styles.title}>
              {_.get(this.props.booking, 'hotel.address', '')},{' '}
              {_.get(this.props.booking, 'hotel.city', '')},{' '}
              {_.get(this.props.booking, 'hotel.stateProvinceCode', '')},{' '}
              {_.get(this.props.booking, 'hotel.postalCode', '')}{' '}
            </p>
          </div>
          <div>
            <p className={styles.confNumb}>Confirmation Number</p>
            <p className={styles.description}>
              {' '}
              {_.get(this.props.booking, 'confirmationNumber', '')}{' '}
            </p>
          </div>
        </div>
        <div className={styles.hotelInfo}>
          <div>
            <p className={styles.title}>Check-in</p>
            <p className={styles.description}> {checkIn} </p>
          </div>
          <div>
            <p className={styles.title}>Check-out</p>
            <p className={styles.description}> {checkOut} </p>
          </div>
        </div>
        <div className={styles.hotelInfo}>
          <div>
            <p className={styles.title}>Room Type</p>
            <p className={styles.description}>
              {' '}
              {_.get(
                this.props.booking,
                'hotel.roomDetails[0].roomDescription',
                '',
              )}{' '}
            </p>
          </div>
        </div>

        <div className={styles.placeInfo}>
          <div className={styles.star}>
            <p className={styles.description}>
              <SvgIconStarColorProp
                width={13}
                height={13}
                color={'#6D717A'}
              />{' '}
              {_.get(this.props.booking, 'hotel.starRating', '')} Star
            </p>
            <p className={styles.description}>
              {' '}
              <SvgLocation
                width={10}
                height={14}
                color={'#6D717A'}
              />{' '}
              {_.get(this.props.booking, 'hotel.locationDescription', '')}{' '}
            </p>
          </div>
          <div className={styles.rating}>{this.renderRating()}</div>
        </div>
      </Paper>
    );
  }
}

export default HotelBookingDetails;

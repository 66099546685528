import * as React from 'react';
import classNames from 'classnames';
import * as _ from 'lodash-es';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Avatar from '@material-ui/core/Avatar';
import SvgIconAvatar from '../../../static/icons/svgIconAvatar';

const styles = require('./styles.pcss');

type Props = {
  user?: any;
};

const ApproversTableRows = (props: Props) => {
  const renderAvatar = () => {
    if (
      _.get(props.user, 'avatar', '').length > 1 &&
      _.get(props.user, 'avatar', '') != null
    ) {
      return (
        <Avatar
          alt="Avatar"
          src={_.get(props.user, 'avatar', '')}
          className={styles.avatar}
        />
      );
    } else {
      let firstName =
        _.get(props, 'user.firstName', null) !== null
          ? _.get(props, 'user.firstName', null)
          : '';
      let lastName =
        _.get(props, 'user.lastName', null) !== null
          ? _.get(props, 'user.lastName', null)
          : '';
      let name = `${firstName} ${lastName}`;

      if (name.trim() === '') {
        return (
          <SvgIconAvatar
            width={50}
            height={50}
          />
        );
      }

      return (
        <Avatar
          alt="avatar"
          className={styles.avatarLetter}
        >
          {firstName.charAt(0)}
          {lastName.charAt(0)}
        </Avatar>
      );
    }
  };

  const tableColumn = () => (
    <TableCell
      key={_.get(props, 'user.id', '1')}
      className={classNames(styles.tableCell)}
    >
      <div className={styles.avatarAndName}>
        <div className={styles.avatarContainer}>{renderAvatar()}</div>
        <div>
          <div className={styles.name}>
            {_.get(props, 'user.firstName', '')}{' '}
            {_.get(props, 'user.lastName', '')}
          </div>
          <div className={styles.email}>{_.get(props, 'user.email', '')}</div>
        </div>
      </div>
    </TableCell>
  );

  return (
    <TableRow className={classNames(styles.tableRowApprovers)}>
      {tableColumn()}
    </TableRow>
  );
};

export default ApproversTableRows;

import React, { Component } from 'react';
import classNames from 'classnames';

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import _ from 'lodash-es';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Select from 'components/Inputs/Select/Select';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextFieldWithClear from 'components/TextFieldWithIcon/TextFieldWithIcon';
import cx from 'classnames';
import { updateBooking } from 'actions/Bookings/UpdateBooking';

const styles = require('./styles.pcss');

import { ReduxState } from '../../reducers/types';

type Props = {
  updateBooking: Function;
  open: boolean;
  onClose: any;
  onSubmit: any;
  type: string;
  id: any;
  confirmationNumber: string;
  status: string;
  identifier: any;
  additionalEmails: string;
  isLoading: any;
  userEmail: string;
  notes?: string;
};

type State = {
  errorAdditionalEmails: any;
  status: string;
  confirmationNumber: string;
  bookingUserEmail: string;
  waitingResponse: boolean;
};

class ModifyBookingDetailsPopup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      errorAdditionalEmails: false,
      waitingResponse: false,
      bookingUserEmail: this.props.userEmail,
      status: this.props.status,
      confirmationNumber: this.props.confirmationNumber,
    };
  }

  componentDidMount() {
    this.setState(this.getInitialState());
  }

  shouldComponentUpdate(nextProps: any) {
    if (this.props.isLoading == true && !_.get(nextProps, 'isLoading', true)) {
      this.onClose();
    }

    return true;
  }

  handleChangeStatus = (event: any) => {
    this.setState({
      status: event.target.value,
    });
  };

  handleChangeUserEmail = (event: any) => {
    this.setState({
      bookingUserEmail: event.target.value,
    });
  };

  handleChangeConfNumber = (event: any) => {
    this.setState({
      confirmationNumber: event.target.value,
    });
  };

  onClearStatus = (event: any) => {
    this.setState({
      status: '',
    });
  };

  onClearUserEmail = (event: any) => {
    this.setState({
      bookingUserEmail: '',
    });
  };

  onClearConfNumber = (event: any) => {
    this.setState({
      confirmationNumber: '',
    });
  };

  handleSubmit = () => {
    this.props.updateBooking(
      this.props.id,
      this.state.status,
      this.state.confirmationNumber,
      this.state.bookingUserEmail,
      this.props.notes,
    );
    this.props.onSubmit();
    this.setState({
      waitingResponse: true,
    });
  };

  onClose = () => {
    this.setState(this.getInitialState());
    this.props.onClose();
  };

  render() {
    return (
      <Dialog
        fullWidth={true}
        maxWidth="md"
        className={cx(styles.dialogBox, styles.sendEmailConfirmation)}
        open={this.props.open}
        onClose={this.onClose}
      >
        <div className={classNames(styles.title)}>
          <div className={styles.titleStyle}>Modify Booking Details</div>
          <IconButton
            color="inherit"
            onClick={this.onClose}
            className={classNames(styles.closeIcon)}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className={classNames(styles.content)}>
          <div className={classNames(styles.sendEmailText)}>
            Modify one or more fields on the booking below
          </div>
          <Select
            fullWidth
            id="updatedStatus"
            className={styles.flightCreditModalStatus}
            label="Status"
            variant="outlined"
            margin="normal"
            value={this.state.status}
            onChange={this.handleChangeStatus}
            menuItems={[
              { key: 'completed', value: 'completed', label: 'Completed' },
              { key: 'canceled', value: 'canceled', label: 'Canceled' },
              { key: 'pending', value: 'pending', label: 'Pending' },
              { key: 'errored', value: 'errored', label: 'Errored' },
            ]}
          />
          <TextFieldWithClear
            fullWidth
            id="bookingUserEmail"
            className={classNames(styles.textField)}
            label="User Email"
            variant="outlined"
            margin="normal"
            value={this.state.bookingUserEmail}
            onChange={this.handleChangeUserEmail}
            onClear={this.onClearUserEmail}
          />
          <TextFieldWithClear
            fullWidth
            id="updatedConfirmationNumber"
            className={classNames(styles.textField)}
            label="Confirmation Number"
            variant="outlined"
            margin="normal"
            value={this.state.confirmationNumber}
            onChange={this.handleChangeConfNumber}
            onClear={this.onClearConfNumber}
          />

          <div className={classNames(styles.actions, styles.actionsRight)}>
            <Button
              className={styles.button}
              variant="contained"
              color="primary"
              onClick={this.handleSubmit}
            >
              Save
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {
    isLoading: state.bookings.isLoading,
  };
}

// Wire up redux dispatch functions
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      updateBooking,
    },
    dispatch,
  );
}

// Connect it to Redux
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModifyBookingDetailsPopup);

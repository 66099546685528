import React, { Component } from 'react';
import cx from 'classnames';
import _ from 'lodash-es';

import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Loader from 'components/Loader/Loader';
import MultiSelect from '../Inputs/MultiSelect/MultiSelect';

const styles = require('./styles.pcss');

type Props = {
  open: boolean;
  in_process?: any;
  onCancel: any;
  submit: any;
  possibleTags: any;
  defValue: any;
};

type State = {
  errorCategories: string;
  categories: any;
};

class EditCustomCategoriesBookingPopup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      categories: props.defValue || [],
      errorCategories: '',
    };
  }

  handleChangeCategories = (categories: any) => {
    this.setState({
      categories,
    });
  };

  componentWillReceiveProps = (nextProps: any) => {
    if (nextProps.defValue !== this.props.defValue) {
      this.setState({
        categories: nextProps.defValue,
      });
    }
  };

  handleSubmit = () => {
    this.props.submit(this.state.categories);
  };

  renderLoader = () => {
    if (!this.props.in_process) {
      return null;
    }
    return null;
    return (
      <div className={styles.loaderContainer}>
        <Loader visible={true} />
      </div>
    );
  };

  render() {
    return (
      <Dialog
        maxWidth={'md'}
        fullWidth={false}
        className={cx(styles.dialogBox, styles.ccPopup)}
        open={this.props.open}
        onClose={this.props.onCancel}
      >
        <div className={styles.title}>
          <div className={styles.titleStyle}>Edit Custom Categories</div>
          <IconButton
            color="inherit"
            onClick={this.props.onCancel}
            className={cx(styles.closeIconCreditCard)}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        </div>
        {this.renderLoader()}
        <div
          className={cx(styles.body, styles.addProgram, styles.categoriesPopup)}
        >
          <MultiSelect
            error={this.state.errorCategories}
            onChange={this.handleChangeCategories}
            value={this.state.categories}
            selections={this.props.possibleTags}
            labelWidth={125}
            label="Custom Categories"
          />

          <div
            className={cx(
              styles.popupButtonsContainer,
              styles.addNewTableRowProgram,
            )}
          >
            <Button
              size="small"
              className={cx(styles.cancelButton, styles.wide)}
              color="primary"
              onClick={this.props.onCancel}
            >
              Cancel
            </Button>

            <Button
              size="small"
              className={cx(styles.button, styles.wide)}
              variant="contained"
              color="primary"
              onClick={this.handleSubmit}
            >
              Save
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default EditCustomCategoriesBookingPopup;

import React, { Component } from 'react';
import _ from 'lodash-es';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextFieldWithClear from 'components/TextFieldWithIcon/TextFieldWithIcon';

const styles = require('../styles.pcss');

type Props = {
  open: boolean;
  onClose: any;
  onSubmit: any;
  agency: any;
};

type State = {
  generalSupportEmail: string;
  travelSupportEmail: string;
  mainSupportPhoneNumber: string;
  alternativeSupportPhoneNumber: string;
};

class ContactPopup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      generalSupportEmail: _.get(
        this.props,
        'agency.uiSettings.supportSettings.generalSupportEmail',
        '',
      ),
      travelSupportEmail: _.get(
        this.props,
        'agency.uiSettings.supportSettings.travelSupportEmail',
        '',
      ),
      mainSupportPhoneNumber: _.get(
        this.props,
        'agency.uiSettings.supportSettings.mainSupportPhoneNumber',
        '',
      ),
      alternativeSupportPhoneNumber: _.get(
        this.props,
        'agency.uiSettings.supportSettings.alternativeSupportPhoneNumber',
        '',
      ),
    };
  }

  onClose = () => {
    this.setState({
      generalSupportEmail: _.get(
        this.props,
        'agency.uiSettings.supportSettings.generalSupportEmail',
        '',
      ),
      travelSupportEmail: _.get(
        this.props,
        'agency.uiSettings.supportSettings.travelSupportEmail',
        '',
      ),
      mainSupportPhoneNumber: _.get(
        this.props,
        'agency.uiSettings.supportSettings.mainSupportPhoneNumber',
        '',
      ),
      alternativeSupportPhoneNumber: _.get(
        this.props,
        'agency.uiSettings.supportSettings.alternativeSupportPhoneNumber',
        '',
      ),
    });

    this.props.onClose();
  };

  onDeleteGeneralSupportEmail = () => {
    this.setState({
      generalSupportEmail: '',
    });
  };

  onDeleteTravelSupportEmail = () => {
    this.setState({
      travelSupportEmail: '',
    });
  };

  onDeleteMainSupportPhoneNumber = () => {
    this.setState({
      mainSupportPhoneNumber: '',
    });
  };

  onDeleteAlternativeSupportPhoneNumber = () => {
    this.setState({
      alternativeSupportPhoneNumber: '',
    });
  };

  onChangeGeneralSupportEmail = (event: any) => {
    this.setState({
      generalSupportEmail: event.target.value,
    });
  };

  onChangeTravelSupportEmail = (event: any) => {
    this.setState({
      travelSupportEmail: event.target.value,
    });
  };

  onChangeMainSupportPhoneNumber = (event: any) => {
    this.setState({
      mainSupportPhoneNumber: event.target.value,
    });
  };

  onChangeAlternativeSupportPhoneNumber = (event: any) => {
    this.setState({
      alternativeSupportPhoneNumber: event.target.value,
    });
  };

  onSubmit = () => {
    let uiSettings = _.get(this.props, 'agency.uiSettings', {});

    uiSettings.supportSettings = {
      ...uiSettings.supportSettings,
      generalSupportEmail: this.state.generalSupportEmail,
      travelSupportEmail: this.state.travelSupportEmail,
      mainSupportPhoneNumber: this.state.mainSupportPhoneNumber,
      alternativeSupportPhoneNumber: this.state.alternativeSupportPhoneNumber,
    };

    this.props.onSubmit({
      ...this.props.agency,
      uiSettings,
    });
  };

  render() {
    return (
      <Dialog
        maxWidth={'md'}
        fullWidth={true}
        className={styles.dialogBox}
        open={this.props.open}
        onClose={this.onClose}
      >
        <div className={styles.title}>
          Edit Support Contact Details
          <IconButton
            color="inherit"
            onClick={this.onClose}
            className={styles.closeIcon}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className={styles.companyInfoDescription}>
          Update contact details for users to receive support.
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.inputWrapper}>
            <TextFieldWithClear
              fullWidth
              label="Support Email"
              variant="outlined"
              margin="normal"
              value={this.state.generalSupportEmail}
              onChange={this.onChangeGeneralSupportEmail}
              onClear={this.onDeleteGeneralSupportEmail}
            />
          </div>

          <div className={styles.inputWrapper}>
            <TextFieldWithClear
              fullWidth
              label="Travel Support Email"
              variant="outlined"
              margin="normal"
              value={this.state.travelSupportEmail}
              onChange={this.onChangeTravelSupportEmail}
              onClear={this.onDeleteTravelSupportEmail}
            />
          </div>
        </div>

        <div className={styles.inputContainer}>
          <div className={styles.inputWrapper}>
            <TextFieldWithClear
              fullWidth
              label="Phone Number"
              variant="outlined"
              margin="normal"
              value={this.state.mainSupportPhoneNumber}
              onChange={this.onChangeMainSupportPhoneNumber}
              onClear={this.onDeleteMainSupportPhoneNumber}
            />
          </div>

          <div className={styles.inputWrapper}>
            <TextFieldWithClear
              fullWidth
              label="Alternate Phone Number"
              variant="outlined"
              margin="normal"
              value={this.state.alternativeSupportPhoneNumber}
              onChange={this.onChangeAlternativeSupportPhoneNumber}
              onClear={this.onDeleteAlternativeSupportPhoneNumber}
            />
          </div>
        </div>

        <div className={styles.popupButtonsContainer}>
          <Button
            className={styles.compPopupButton}
            variant="contained"
            color="primary"
            onClick={this.onSubmit}
          >
            Save
          </Button>
        </div>
      </Dialog>
    );
  }
}

export default ContactPopup;

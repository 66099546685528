import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
};

const SvgIconSearch = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 14 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="menu"
          transform="translate(-17.000000, -64.000000)"
          fill={props.fill}
        >
          <g id="Fill-1">
            <path d="M20.1705487,72.8294147 C18.6098171,71.2686343 18.6098171,68.7303652 20.1705487,67.1695848 C20.9509145,66.3891946 21.9753947,66 23.0008754,66 C24.0253556,66 25.0498359,66.3891946 25.8302017,67.1695848 C27.3899328,68.7303652 27.3899328,71.2686343 25.8302017,72.8294147 C24.2694701,74.3901951 21.7312803,74.3901951 20.1705487,72.8294147 M30.7010676,76.2555727 L28.1144082,73.6680566 C29.8139794,71.2757284 29.6075372,67.9360749 27.4644219,65.7930965 C25.0739849,63.4028121 21.1832649,63.4017902 18.7928278,65.7930965 C16.4023907,68.1844029 16.4023907,72.0738524 18.7928278,74.4641368 C19.9875354,75.65979 21.5583356,76.2565946 23.1281139,76.2565946 C24.373921,76.2565946 25.6105302,75.865197 26.6682909,75.1130597 L29.2559723,77.7005757 C29.4552605,77.8998513 29.7168902,78 29.9785199,78 C30.2401496,78 30.5007574,77.8998513 30.7010676,77.7005757 C31.0996441,77.3010028 31.0996441,76.6551457 30.7010676,76.2555727"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

SvgIconSearch.defaultProps = {
  width: 15,
  height: 15,
  fill: '#FFFFFF',
};

export default SvgIconSearch;

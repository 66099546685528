import React from 'react';
import classNames from 'classnames';

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import _ from 'lodash-es';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = require('./styles.pcss');

import { ReduxState } from '../../reducers/types';

type Props = {
  content?: any;
  popupOpen: boolean;
  onClosePopup: Function;
};

class ImportEmployeesPopup extends React.Component<Props> {
  render() {
    return (
      <Dialog
        fullWidth={true}
        maxWidth={false}
        scroll="paper"
        className={classNames(styles.dialogBox)}
        open={this.props.popupOpen}
      >
        <div className={classNames(styles.title)}>
          Import Employees
          <IconButton
            color="inherit"
            onClick={() => {
              this.props.onClosePopup();
            }}
            className={classNames(styles.closeIcon)}
            aria-label="Close"
          >
            <CloseIcon className={styles.svg} />
          </IconButton>
        </div>

        <DialogContent>
          <div className={styles.popupSubtitle}>
            Import via a CSV file to add or modify several employees at once.
          </div>
          {this.props.content}
        </DialogContent>
      </Dialog>
    );
  }
}

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {
    saveCompanyStatus: state.saveCompanyStatus,
  };
}

// Wire up redux dispatch functions
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({}, dispatch);
}

// Connect it to Redux
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ImportEmployeesPopup);

import {
  ACTION_PUT_REIMBURSEMENT_SETTINGS_FEATURE,
  ACTION_PUT_REIMBURSEMENT_SETTINGS_FEATURE_SUCCESS,
  ACTION_PUT_REIMBURSEMENT_SETTINGS_FEATURE_FAILURE,
  API_CALL,
} from '../../configs/ActionTypes';

import _ from 'lodash-es';

import { Dispatch } from 'redux';

import { enqueueSnackbarHandler } from '../Status';

export type datatype = {
  organizationId?: string;
  frequency?: string;
  autoPay?: string;
  enabled?: any;
};

export function putReimbursementSettings(data: datatype) {
  return (dispatch: Dispatch, getState: Function) => {
    dispatch(requestStarted());
    //Dispatch the api call request.
    dispatch({
      type: API_CALL,
      payload: {
        call: 'organizations/feature/reimbursementSettings',
        method: 'PUT',
        data: JSON.stringify(data),
        success: (response: any) => {
          if (response.type === 'error') {
            enqueueSnackbarHandler(dispatch, response.payload.payload, 'error');
            dispatch(requestFailed(response));
          } else {
            enqueueSnackbarHandler(
              dispatch,
              { updateFeatureSuccess: true },
              'success',
            );
            dispatch(requestSuccess(response));
          }
        },
        fail: (error: any) => {
          _.get(error.payload, 'userPermissionError', false)
            ? enqueueSnackbarHandler(dispatch, error.payload, 'error')
            : enqueueSnackbarHandler(
                dispatch,
                { updateFeatureError: true },
                'error',
              );
          dispatch(requestFailed(error));
        },
      },
    });
    return null;
  };
}

export function requestStarted() {
  return {
    type: ACTION_PUT_REIMBURSEMENT_SETTINGS_FEATURE,
    payload: null,
  };
}

export function requestSuccess(responseJSON: object) {
  return {
    type: ACTION_PUT_REIMBURSEMENT_SETTINGS_FEATURE_SUCCESS,
    payload: responseJSON,
  };
}

export function requestFailed(responseJSON: object) {
  return {
    type: ACTION_PUT_REIMBURSEMENT_SETTINGS_FEATURE_FAILURE,
    payload: responseJSON,
  };
}

import React from 'react';

type Props = {
  width: number;
  height: number;
};

const SvgIconRewardsProgram = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox="0 0 32 33"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 16.7998C0 7.99065 7.162 0.848389 16 0.848389C24.836 0.848389 32 7.98865 32 16.7998C32 25.609 24.838 32.7513 16 32.7513C7.164 32.7513 0 25.611 0 16.7998Z"
        fill="#1B2432"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.625 18.6951C17.8701 19.1873 16.9684 19.4734 16 19.4734C15.0316 19.4734 14.1299 19.1873 13.375 18.6951V22.6869L16 21.9334L18.625 22.6869V18.6951ZM12.5 17.964C11.6863 17.1021 11.1875 15.9397 11.1875 14.6609C11.1875 12.003 13.3421 9.84839 16 9.84839C18.6579 9.84839 20.8125 12.003 20.8125 14.6609C20.8125 15.9397 20.3137 17.1021 19.5 17.964V23.8484L16 22.8438L12.5 23.8484V17.964ZM16.7935 10.8034C18.5878 11.1705 19.9375 12.7581 19.9375 14.6609C19.9375 16.8355 18.1746 18.5984 16 18.5984C13.8254 18.5984 12.0625 16.8355 12.0625 14.6609C12.0625 12.4863 13.8254 10.7234 16 10.7234C16.2718 10.7234 16.5372 10.7509 16.7935 10.8034Z"
        fill="white"
      />
    </svg>
  );
};

export default SvgIconRewardsProgram;

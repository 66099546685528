import * as React from 'react';
import SvgIconVisa from '../../static/icons/svgIconVisa';
import SvgIconMasterCard from '../../static/icons/svgIconMasterCard';
import SvgIconAmex from '../../static/icons/svgIconAMEX';
import SvgIconDiscover from '../../static/icons/svgIconDiscover';
import SvgIconOtherCardType from '../../static/icons/svgIconOtherCardType';
import SvgIconDinersClub from '../../static/icons/svgIconDinersClub';

type Props = {
  type: string;
  width: any;
  height: any;
};

const RenderIcon = ({ type, width, height }: Props) => {
  switch (type) {
    case 'Visa':
      return (
        <SvgIconVisa
          width={width}
          height={height}
        />
      );
    case 'MasterCard':
      return (
        <SvgIconMasterCard
          width={width}
          height={height}
        />
      );
    case 'AMEX':
      return (
        <SvgIconAmex
          width={width}
          height={height}
        />
      );
    case 'Discover':
      return (
        <SvgIconDiscover
          width={width}
          height={height}
        />
      );
    case 'Diners Club':
      return (
        <SvgIconDinersClub
          width={width}
          height={height}
        />
      );
    default:
      return (
        <SvgIconOtherCardType
          width={width}
          height={height}
        />
      );
  }
};

export default RenderIcon;

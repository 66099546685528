import * as React from 'react';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import _ from 'lodash-es';

const styles = require('./styles.pcss');

type Props = {
  provider: any;
  expenseManagement: any;
};

const ActiveExpenseIntegration = (props: Props) => {
  let expenseManagement = _.keys(props.expenseManagement)
    ? _.keys(props.expenseManagement)
    : [];

  const renderExpenseManagement = (name: any) => {
    return (
      <div className={styles.imgAndTextContainer}>
        <div className={styles.legdeImgContainer}>
          <img src={`/static/images/expensemanagement/${name}.jpg`} />
        </div>
        <div className={styles.expenseTextContainer}>{name}</div>
      </div>
    );
  };

  const renderProviders = () => {
    let img = '';
    let text = '';

    switch (props.provider) {
      case 'BillCom':
        text = 'Bill.com';
        img = 'billcom';
        break;
      case 'NetSuite':
        text = 'NetSuite';
        img = 'netsuite';
        break;
      case 'QBE':
        text = 'QuickBooks Enterprise';
        img = 'quickbooks';
        break;
      case 'QBO':
        text = 'QuickBooks Online';
        img = 'quickbooks';
        break;
      case 'Xero':
        text = 'Xero';
        img = 'xero';
        break;
    }

    return (
      <div className={styles.imgAndTextContainer}>
        <div className={styles.legdeImgContainer}>
          <img src={`/static/images/providers/${img}.png`} />
        </div>
        <div className={styles.legdeTextContainer}>{text}</div>
      </div>
    );
  };

  let styleExpenseManagement = classNames(styles.expenseManagement);

  if (props.provider && expenseManagement.length > 0) {
    styleExpenseManagement = classNames(
      styles.expenseManagement,
      styles.expenseManagementBorder,
    );
  }

  if (props.provider || expenseManagement.length > 0) {
    return (
      <Paper className={styles.activeExpenseIntegrationPaper}>
        {props.provider && (
          <div className={styles.titleStyle}>General Ledger</div>
        )}
        {props.provider && renderProviders()}
        {expenseManagement.length > 0 && (
          <div className={styleExpenseManagement}>
            <div className={styles.titleStyle}>Expense Management</div>
            <div className={styles.listExpenseManagement}>
              {_.map(expenseManagement, renderExpenseManagement)}
            </div>
          </div>
        )}
      </Paper>
    );
  } else {
    return null;
  }
};

export default ActiveExpenseIntegration;

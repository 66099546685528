import React, { Component } from 'react';
import cx from 'classnames';

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import _ from 'lodash-es';
import moment from 'moment';

import { navigate, redirectTo } from '@reach/router';
import InputLabel from '@material-ui/core/InputLabel';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import { TextMaskExpiry } from '../TravelerDetails/MaskedFields/MaskedFields';
import OutlinedInputWithDelete from 'components/TextFieldWithIcon/OutlinedInputWithDelete';
import FormHelperText from '@material-ui/core/FormHelperText';

import TextFieldWithClear from 'components/TextFieldWithIcon/TextFieldWithIcon';
import AutoSuggest from 'components/Inputs/AutoSuggest/AutoSuggest';
import {
  putCreditCard,
  resetEditCredCardStatus,
} from '../../actions/CreditCards/EditCreditCard';
import { hasPermission } from '../../helpers/Permission';
import { ReduxState } from '../../reducers/types';
import SvgAlertIcon from '../../static/icons/svgAlertIcon';
import { missingBillingAddress } from '../../helpers/MissingBillingAddress';
const requiredPostalCountries = require('../../helpers/requiredPostalCountries.js');
const requiredRegionCountries = require('../../helpers/requiredRegionCountries.js');
const states = require('../../helpers/States.js');
const provinces = require('../../helpers/Provinces.js');
const countries = require('../../helpers/Countries.js');

const styles = require('./styles.pcss');

type Props = {
  showEditCreditCardPopup: boolean;
  closeEditCreditCardPopup: any;
  putCreditCard: any;
  resetEditCredCardStatus: any;
  creditCard: any;
  onSuccess: any;
  companyDetails: any;
};

type State = {
  editCreditCardStatus: any;
  openConfirmation: boolean;
  userId: string;
  country: any;
  postalCode: any;
  countryError: any;
  postalCodeError: any;
  zipCodeRequired: Boolean;
  user: any;
  employees: string;
  regionError: any;
  region: string;
  city: string;
  cityError: any;
  streetAddress: string;
  streetAddressError: any;
  streetAddress2: string;
  name: any;
  expiry: any;
  nameError: any;
  expiryError: any;
};

class EditCreditCardPopup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = this.getInitialState();
    this.validFields();
  }

  getInitialState() {
    return {
      editCreditCardStatus: false,
      openConfirmation: false,
      employees:
        (_.get(this.props.creditCard, 'departmentIds', []) || []).length === 0
          ? 'all'
          : 'specific',
      userId: this.props.creditCard.userId,
      name: _.get(this.props.creditCard, 'name', ''),
      cvc: _.get(this.props.creditCard, 'cvv', ''),
      country: _.get(this.props.creditCard.billingAddress, 'country', ''),
      postalCode: _.get(this.props.creditCard.billingAddress, 'postalCode', ''),
      nameError: '',
      expiryError: '',
      expiry: moment(_.get(this.props.creditCard, 'expiry', '')).format(
        'MM/YY',
      ),
      countryError:
        _.get(this.props.creditCard.billingAddress, 'country', '').trim() === ''
          ? 'Required'
          : '',
      postalCodeError:
        _.get(this.props.creditCard.billingAddress, 'postalCode', null) ===
          null ||
        (_.get(
          this.props.creditCard.billingAddress,
          'postalCode',
          '',
        ).trim() === '' &&
          requiredPostalCountries.requiredPostalCountriesCode.includes(
            _.get(this.props.creditCard.billingAddress, 'country', '').trim(),
          ))
          ? 'Required'
          : '',
      zipCodeRequired: false,
      user: '',
      region: _.get(this.props.creditCard.billingAddress, 'state', ''),
      regionError:
        (_.get(this.props.creditCard.billingAddress, 'state', null) === null ||
          _.get(this.props.creditCard.billingAddress, 'state', '').trim() ===
            '') &&
        requiredRegionCountries.requiredRegionCountriesCode.indexOf(
          _.get(this.props.creditCard.billingAddress, 'country', '').trim(),
        ) !== -1
          ? 'Required'
          : '',
      city: _.get(this.props.creditCard.billingAddress, 'city', ''),
      cityError:
        _.get(this.props.creditCard.billingAddress, 'city', null) === null ||
        _.get(this.props.creditCard.billingAddress, 'city', '').trim() === ''
          ? 'Required'
          : '',
      streetAddress: _.get(this.props.creditCard.billingAddress, 'street', ''),
      streetAddressError:
        _.get(this.props.creditCard.billingAddress, 'street', null) === null ||
        _.get(this.props.creditCard.billingAddress, 'street', '').trim() === ''
          ? 'Required'
          : '',
      streetAddress2: _.get(
        this.props.creditCard.billingAddress,
        'street2',
        '',
      ),
    };
  }

  shouldComponentUpdate(nextProps: any) {
    if (
      _.get(nextProps, 'editCreditCardStatus.editCreditCard', false) === true
    ) {
      if (_.get(nextProps, 'editCreditCardStatus.success', null) === true) {
        this.props.closeEditCreditCardPopup();
        this.props.resetEditCredCardStatus();
        this.setState(this.getInitialState());
        this.props.onSuccess();
        return false;
      }
    }

    return true;
  }

  handleSubmit = () => {
    if (!this.validFields()) {
      return;
    }

    this.props.putCreditCard(
      {
        id: this.props.creditCard.id,
        userId: this.state.userId,
        city: this.state.city,
        country: this.state.country,
        postalCode: this.state.postalCode,
        state: _.get(this.state, 'region.value', this.state.region),
        street: this.state.streetAddress,
        street2: this.state.streetAddress2,
        name: this.state.name,
        expiry: moment(this.state.expiry, 'MM/YYYY').format('YYYY-MM'),
      },
      'personal',
    );
  };

  onChangeCountryAuto = (country: any) => {
    let zipCodeRequired =
      requiredPostalCountries.requiredPostalCountries.includes(country);

    this.setState({
      region: '',
      country: country,
      countryError: '',
      zipCodeRequired,
    });
  };

  onChangeRegion = (region: any) => {
    this.setState({
      region: region,
      regionError: '',
    });
  };

  onChangeRegionText = (event: any) => {
    this.setState({
      region: event.target.value,
      regionError: '',
    });
  };

  onChangePostalCode = (event: any) => {
    this.setState({
      postalCode: event.target.value,
      postalCodeError: '',
    });
  };

  onChangeStreetAddress = (event: any) => {
    this.setState({
      streetAddress: event.target.value,
      streetAddressError: '',
    });
  };

  onChangeStreetAddress2 = (event: any) => {
    this.setState({
      streetAddress2: event.target.value,
    });
  };

  onChangeCity = (event: any) => {
    this.setState({
      city: event.target.value,
      cityError: '',
    });
  };

  onDeleteStreetAddress = () => {
    this.setState({
      streetAddress: '',
      streetAddressError: '',
    });
  };

  onDeleteStreetAddress2 = () => {
    this.setState({
      streetAddress2: '',
    });
  };

  onDeleteCity = () => {
    this.setState({
      city: '',
      cityError: '',
    });
  };

  onDeleteName = () => {
    this.setState({
      name: '',
      nameError: '',
    });
  };

  onDeletePostalCode = () => {
    this.setState({
      postalCode: '',
      postalCodeError: '',
    });
  };

  onDeleteExpiry = () => {
    this.setState({
      expiry: '',
      expiryError: '',
    });
  };

  onDeleteRegion = () => {
    this.setState({
      regionError: '',
      region: '',
    });
  };

  onChangeName = (event: any) => {
    this.setState({
      name: event.target.value,
      nameError: '',
    });
  };

  onChangeExpiry = (event: any) => {
    this.setState({
      expiry: event.target.value,
      expiryError: '',
    });
  };

  renderExpiryError = () => {
    if (Boolean(this.state.expiryError)) {
      return <FormHelperText error>{this.state.expiryError}</FormHelperText>;
    }

    return null;
  };

  validFields = () => {
    let isValid = true;
    let countryError = '';
    let postalCodeError = '';
    let regionError = '';
    let cityError = '';
    let streetAddressError = '';
    let nameError = '';
    let expiryError = '';

    if (
      (_.get(this.state, 'region.label', this.state.region) === null ||
        _.get(this.state, 'region.label', this.state.region).trim() === '') &&
      (requiredRegionCountries.requiredRegionCountries.indexOf(
        this.state.country.trim(),
      ) !== -1 ||
        requiredRegionCountries.requiredRegionCountriesCode.indexOf(
          this.state.country.trim(),
        ) !== -1)
    ) {
      regionError = 'Required';
      isValid = false;
    }

    if (this.state.name.trim() === '') {
      nameError = 'Required';
      isValid = false;
    }

    if (this.state.expiry === null || this.state.expiry.trim() === '') {
      expiryError = 'Required';
      isValid = false;
    } else if (!moment(this.state.expiry, 'MM/YYYY').isValid()) {
      expiryError = 'Invalid credit card expiry';
      isValid = false;
    }

    if (this.state.city === null || this.state.city.trim() === '') {
      cityError = 'Required';
      isValid = false;
    }

    if (
      this.state.streetAddress === null ||
      this.state.streetAddress.trim() === ''
    ) {
      streetAddressError = 'Required';
      isValid = false;
    }

    if (this.state.country === null || this.state.country.trim() === '') {
      countryError = 'Required';
      isValid = false;
    }

    if (
      (requiredPostalCountries.requiredPostalCountries.includes(
        this.state.country,
      ) ||
        requiredPostalCountries.requiredPostalCountriesCode.includes(
          this.state.country,
        )) &&
      this.state.postalCode.trim() === ''
    ) {
      postalCodeError = 'Required';
      isValid = false;
    }

    this.setState({
      countryError,
      postalCodeError,
      regionError,
      cityError,
      streetAddressError,
      nameError,
      expiryError,
    });

    return isValid;
  };

  renderRegion = () => {
    if (this.state.country == 'United States' || this.state.country == 'US') {
      return (
        <div className={styles.inputWrapper}>
          <AutoSuggest
            error={this.state.regionError}
            className={styles.dropdownContainer}
            onChange={(value: any) => {
              this.onChangeRegion(value);
            }}
            label="State"
            value={_.get(this.state, 'region.label', this.state.region)}
            dataSet={states.states}
            returnAll={true}
            id="province"
          />
        </div>
      );
    }
    if (this.state.country == 'Canada' || this.state.country == 'CA') {
      return (
        <div className={styles.inputWrapper}>
          <AutoSuggest
            error={this.state.regionError}
            className={styles.dropdownContainer}
            onChange={(value: any) => {
              this.onChangeRegion(value);
            }}
            label="Province"
            value={_.get(this.state, 'region.label', this.state.region)}
            dataSet={provinces.provinces}
            returnAll={true}
          />
        </div>
      );
    }
    return (
      <div className={styles.inputWrapper}>
        <TextFieldWithClear
          error={Boolean(this.state.regionError)}
          helperText={this.state.regionError}
          onChange={this.onChangeRegionText}
          fullWidth
          label="State/Province/Region"
          variant="outlined"
          margin="normal"
          value={this.state.region}
          onClear={this.onDeleteRegion}
        />
      </div>
    );
  };

  render() {
    return (
      <Dialog
        fullWidth={true}
        maxWidth="xl"
        className={cx(styles.dialogBox, styles.ccPopup)}
        open={this.props.showEditCreditCardPopup}
        onClose={this.props.closeEditCreditCardPopup}
      >
        <div className={cx(styles.title)}>
          Add Billing for Credit Card Ending{' '}
          {_.get(this.props, 'creditCard.last4', '')}
          <IconButton
            color="inherit"
            onClick={this.props.closeEditCreditCardPopup}
            className={cx(styles.closeIconUpdatePopup)}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        </div>

        <div className={styles.updatePopup}>
          <div className={styles.inputContainer}></div>

          <div className={cx(styles.informationTitle, styles.addMarginTop)}>
            <h2>1. Credit Card Information</h2>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.inputWrapper}>
              <TextFieldWithClear
                error={Boolean(this.state.nameError)}
                helperText={this.state.nameError}
                onChange={this.onChangeName}
                fullWidth
                label="Name on Card"
                variant="outlined"
                margin="normal"
                value={this.state.name}
                onClear={this.onDeleteName}
                id="name"
              />
            </div>

            <div className={styles.inputWrapper}>
              <FormControl
                margin="normal"
                variant="outlined"
                className={styles.selectWrapper}
              >
                <InputLabel
                  error={Boolean(this.state.expiryError)}
                  htmlFor="input-expiry"
                >
                  Expiration Date
                </InputLabel>
                <OutlinedInputWithDelete
                  error={Boolean(this.state.expiryError)}
                  fullWidth
                  id="input-expiry"
                  name="expiry"
                  labelWidth={102}
                  value={this.state.expiry}
                  onChange={this.onChangeExpiry}
                  inputComponent={TextMaskExpiry}
                  onClear={this.onDeleteExpiry}
                />
                {this.renderExpiryError()}
              </FormControl>
            </div>
          </div>

          <div className={styles.billingAddressContainer}>
            {missingBillingAddress(this.props.creditCard) ? (
              <h2 className={styles.billingInformationTitle}>
                <SvgAlertIcon
                  width={16}
                  height={16}
                />{' '}
                Billing Information
              </h2>
            ) : (
              <h2 className={styles.billingInformationTitle}>
                {' '}
                Billing Information
              </h2>
            )}
          </div>
          <div className={cx(styles.inputContainer, styles.borderBottom)}>
            <div className={styles.inputWrapper}>
              <TextFieldWithClear
                error={Boolean(this.state.streetAddressError)}
                helperText={this.state.streetAddressError}
                onChange={this.onChangeStreetAddress}
                fullWidth
                label="Street Address"
                variant="outlined"
                margin="normal"
                value={this.state.streetAddress}
                onClear={this.onDeleteStreetAddress}
                id="street-address"
              />
            </div>
            <div className={styles.inputWrapper}>
              <TextFieldWithClear
                onChange={this.onChangeStreetAddress2}
                fullWidth
                label="Street Address 2"
                variant="outlined"
                margin="normal"
                value={this.state.streetAddress2}
                onClear={this.onDeleteStreetAddress2}
              />
            </div>
            <div className={styles.inputWrapper}>
              <TextFieldWithClear
                error={Boolean(this.state.cityError)}
                helperText={this.state.cityError}
                onChange={this.onChangeCity}
                fullWidth
                label="City"
                variant="outlined"
                margin="normal"
                value={this.state.city}
                onClear={this.onDeleteCity}
                id="city"
              />
            </div>

            {this.renderRegion()}

            <div className={styles.inputWrapper}>
              <AutoSuggest
                error={this.state.countryError}
                className={styles.dropdownContainer}
                onChange={(value: any) => {
                  this.onChangeCountryAuto(value);
                }}
                label="Country"
                value={this.state.country}
                dataSet={countries.countries}
                id="country"
              />
            </div>

            {(requiredPostalCountries.requiredPostalCountries.includes(
              this.state.country,
            ) ||
              requiredPostalCountries.requiredPostalCountriesCode.includes(
                this.state.country,
              )) && (
              <div className={styles.inputWrapper}>
                <TextFieldWithClear
                  error={Boolean(this.state.postalCodeError)}
                  helperText={this.state.postalCodeError}
                  onChange={this.onChangePostalCode}
                  fullWidth
                  label="Zip Code"
                  variant="outlined"
                  margin="normal"
                  value={this.state.postalCode}
                  onClear={this.onDeletePostalCode}
                  id="zipcode"
                />
              </div>
            )}
          </div>

          <div
            className={cx(
              styles.actions,
              styles.actionUpdatePopup,
              styles.bigButtonSize,
            )}
          >
            <Button
              className={styles.button}
              variant="outlined"
              color="primary"
              onClick={this.props.closeEditCreditCardPopup}
            >
              Cancel
            </Button>
            <Button
              className={styles.button}
              variant="contained"
              color="primary"
              onClick={this.handleSubmit}
              id="submit-update"
            >
              Add
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {
    editCreditCardStatus: state.editCreditCardStatus,
    companyDetails: state.company.company,
  };
}

// Wire up redux dispatch functions
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      putCreditCard,
      resetEditCredCardStatus,
    },
    dispatch,
  );
}

// Connect it to Redux
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditCreditCardPopup);

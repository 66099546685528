import {
  ACTION_GET_COMPANY_EMPLOYEES_SIMPLE,
  ACTION_GET_COMPANY_EMPLOYEES_SIMPLE_SUCCESS,
  ACTION_GET_COMPANY_EMPLOYEES_SIMPLE_FAILURE,
  ACTION_EMPTY_COMPANY_EMPLOYEES_SIMPLE,
} from '../configs/ActionTypes';

import { Company } from './types';

import _ from 'lodash-es';

const initialState = {
  employees: [],
  isLoading: false,
  success: null,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_GET_COMPANY_EMPLOYEES_SIMPLE: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ACTION_GET_COMPANY_EMPLOYEES_SIMPLE_SUCCESS: {
      const employees = _.get(action.payload.payload, 'employees', []);

      return {
        ...state,
        employees: employees,
        success: true,
        isLoading: false,
      };
    }

    case ACTION_EMPTY_COMPANY_EMPLOYEES_SIMPLE: {
      return initialState;
    }

    case ACTION_GET_COMPANY_EMPLOYEES_SIMPLE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        success: false,
      };
    }

    default: {
      return state;
    }
  }
}

import * as React from 'react';
import LayoutDashboard from 'components/Layout/Dashboard';

const styles = require('./styles.pcss');

export default class RefundChargeGoogleForm extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <LayoutDashboard full>
        <div className={styles.header}>
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSeeREwXTMjfh7D43-UB0W5NVy7HVYk0YHD8Qq-bsTSNUq68sA/viewform?embedded=true"
            width="636"
            height="2320"
            frameBorder={0}
            marginHeight={0}
            marginWidth={0}
          >
            Loading...
          </iframe>
        </div>
      </LayoutDashboard>
    );
  }
}

import {
  ACTION_BOOKINGS_SYNC_DUFFEL_BOOKING,
  ACTION_BOOKINGS_SYNC_DUFFEL_BOOKING_SUCCESS,
  ACTION_BOOKINGS_SYNC_DUFFEL_BOOKING_FAILURE,
  API_CALL,
} from '../../configs/ActionTypes';

import { enqueueSnackbarHandler } from '../Status';
import { Dispatch } from 'redux';
import _ from 'lodash-es';

export function syncDuffelBooking(userId: string, orderId: string) {
  return (dispatch: Dispatch, getState: Function) => {
    dispatch(requestStarted());
    //Dispatch the api call request.
    dispatch({
      type: API_CALL,
      payload: {
        call: `user/${userId}/sync-duffel-booking`,
        method: 'post',
        data: JSON.stringify({
          orderId,
        }),
        success: (response: any) => {
          if (response.type === 'error') {
            dispatch(requestFailed(response));
            _.get(response.payload.payload, 'userPermissionError', false)
              ? enqueueSnackbarHandler(
                  dispatch,
                  response.payload.payload,
                  'error',
                )
              : enqueueSnackbarHandler(
                  dispatch,
                  { duffelSyncFailed: true },
                  'error',
                );
          } else {
            dispatch(requestSuccess(response));
            enqueueSnackbarHandler(
              dispatch,
              { duffelSyncSucceded: true },
              'success',
            );
          }
        },
        fail: (error: any) => {
          _.get(error.payload, 'userPermissionError', false)
            ? enqueueSnackbarHandler(dispatch, error.payload, 'error')
            : enqueueSnackbarHandler(
                dispatch,
                { duffelSyncFailed: true },
                'error',
              );
          dispatch(requestFailed(error));
        },
      },
    });

    return null;
  };
}

export function requestStarted() {
  return {
    type: ACTION_BOOKINGS_SYNC_DUFFEL_BOOKING,
    payload: null,
  };
}

export function requestSuccess(responseJSON: any) {
  return {
    type: ACTION_BOOKINGS_SYNC_DUFFEL_BOOKING_SUCCESS,
    payload: responseJSON,
  };
}

export function requestFailed(responseJSON: any) {
  return {
    type: ACTION_BOOKINGS_SYNC_DUFFEL_BOOKING_FAILURE,
    payload: responseJSON,
  };
}

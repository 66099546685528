import _ from 'lodash-es';

import {
  ACTION_PUT_CREDITCARD,
  ACTION_PUT_CREDITCARD_SUCCESS,
  ACTION_PUT_CREDITCARD_FAILURE,
  ACTION_RESET_EDIT_CREDIT_CARD_SUCCESS,
} from '../configs/ActionTypes';

const initialState = {
  editCreditCard: false,
  success: null,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_PUT_CREDITCARD_SUCCESS: {
      return {
        ...state,
        editCreditCard: true,
        success: true,
      };

      break;
    }

    case ACTION_PUT_CREDITCARD_FAILURE: {
      return {
        ...state,
        editCreditCard: true,
        success: false,
      };

      break;
    }
    case ACTION_PUT_CREDITCARD: {
      return {
        ...state,
        editCreditCard: true,
      };

      break;
    }
    case ACTION_RESET_EDIT_CREDIT_CARD_SUCCESS: {
      return {
        ...state,
        editCreditCard: false,
        success: null,
      };

      break;
    }

    default: {
      return state;
    }
  }
}

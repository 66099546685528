import * as React from 'react';
import LayoutDashboard from 'components/Layout/Dashboard';
import SvgUserIcon from '../../static/icons/svgUserIcon';
import SvgIconHotel from '../../static/icons/svgIconHotel';
import Box from 'components/ManageTB/Box/Box';
const styles = require('./styles.pcss');

class ManageBlackBox extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <LayoutDashboard full>
        <div className={styles.paper}>
          <div className={styles.header}>
            <h1 className={styles.h1}>Manage BlackBox</h1>
          </div>
          <div className={styles.body}>
            <Box
              text="Roles/Permissions"
              description="Manage user’s permissions to BlackBox."
              icon={
                <SvgIconHotel
                  width={64}
                  height={64}
                />
              }
              link="manage-bb/roles-permissions"
            />
            <Box
              text="Users"
              description="List of users with access to BlackBox."
              icon={
                <SvgUserIcon
                  width={64}
                  height={64}
                />
              }
              link="manage-bb/users"
            />
          </div>
        </div>
      </LayoutDashboard>
    );
  }
}

export default ManageBlackBox;

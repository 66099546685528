import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TravelerTableRow from './TravelerTableRow';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { bindActionCreators, Dispatch } from 'redux';
import { ReduxState } from '../../reducers/types';
import moment from 'moment';
import * as _ from 'lodash-es';
import UpdateFlightCreditPopup from 'components/Popup/UpdateFlightCreditPopup';
import { updateUserFlightCredit } from 'actions/User/UpdateUserFlightCredit';

const styles = require('./styles.pcss');
import * as React from 'react';

type Props = {
  hasPermission: any;
  isLoading: any;
  userLoading: any;
  flightCredits: any;
  updateUserFlightCredit: any;
};

type State = {
  flightCredits: any;
  ticketNumber: string;
  status: string;
  updatePopup: boolean;
  id: string;
};

class FlightCredits extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      updatePopup: false,
      ticketNumber: '',
      status: '',
      id: '',
      flightCredits: this.props.flightCredits,
    };
  }

  handleUpdateStatus = (flightCreditId: any) => {
    const changedFlightCreditIndex = this.state.flightCredits.findIndex(
      (cred: any) => cred.id === flightCreditId,
    );
    const flightCredits = [...this.state.flightCredits];
    const flightCredit = { ...flightCredits[changedFlightCreditIndex] };
    flightCredit.status = this.state.status;
    flightCredits[changedFlightCreditIndex] = flightCredit;
    this.setState({ flightCredits });
    this.props.updateUserFlightCredit(flightCreditId, this.state.status);
  };

  handleStatusSelect = (status: string) => {
    this.setState({ status });
  };

  onClickEdit = (ticketNumber: any, status: any, id: any) => {
    this.setState({
      id,
      ticketNumber,
      status,
      updatePopup: true,
    });
  };

  onClosePopup = () => {
    this.setState({
      updatePopup: false,
    });
  };

  render() {
    return (
      <Paper className={styles.paper}>
        <div className={styles.iconAndInfoContainer}>
          <div className={styles.iconContainer}></div>
          <div>
            <div className={styles.titleStyle}>Flight Credits</div>
            <div className={styles.subtitleStyle}>
              An overview of the traveler’s flight credits. Flight credits may
              take up to 24 hours to update.
            </div>
            <Table className={styles.loyaltyProgramTable}>
              <TableHead>
                <TableRow>
                  <TableCell className={styles.flightCreditsHead}>
                    Airline
                  </TableCell>
                  <TableCell className={styles.flightCreditsHead}>
                    Traveler Name
                  </TableCell>
                  <TableCell className={styles.flightCreditsHead}>
                    Ticket Number
                  </TableCell>
                  <TableCell className={styles.flightCreditsHead}>
                    Value
                  </TableCell>
                  <TableCell className={styles.flightCreditsHead}>
                    Valid Until
                  </TableCell>
                  <TableCell className={styles.flightCreditsHead}>
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.flightCredits.map((flightCredit: any) => {
                  return (
                    <TravelerTableRow
                      firstItemToLeft={true}
                      key={flightCredit.ticketNumber}
                      rowColumns={[
                        flightCredit.airline,
                        flightCredit.travelerName,
                        flightCredit.ticketNumber,
                        '$' + flightCredit.totalValue,
                        moment(flightCredit.expiryDate).format('MMM DD, YYYY'),
                        flightCredit.status,
                      ]}
                      clickEdit={this.onClickEdit.bind(
                        this,
                        flightCredit.ticketNumber,
                        flightCredit.status,
                        flightCredit.id,
                      )}
                      showEdit={this.props.hasPermission}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </div>
        <UpdateFlightCreditPopup
          handleStatusSelect={this.handleStatusSelect}
          open={this.state.updatePopup}
          onClose={this.onClosePopup}
          update={this.handleUpdateStatus}
          ticketNumber={this.state.ticketNumber}
          status={this.state.status}
          id={this.state.id}
        />
      </Paper>
    );
  }
}

function mapStateToProps(state: ReduxState) {
  return {
    isLoading: state.traveler.isLoading,
    userLoading: state.user.isLoading,
  };
}

// Wire up redux dispatch functions
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      updateUserFlightCredit,
    },
    dispatch,
  );
}

// Connect it to Redux
export default connect(mapStateToProps, mapDispatchToProps)(FlightCredits);

import {
  ACTION_GET_NEGOTIATED_HOTELS,
  ACTION_GET_NEGOTIATED_HOTELS_SUCCESS,
  ACTION_GET_NEGOTIATED_HOTELS_FAILURE,
  ACTION_PUT_NEGOTIATED_HOTELS,
  ACTION_PUT_NEGOTIATED_HOTELS_SUCCESS,
  ACTION_PUT_NEGOTIATED_HOTELS_FAILURE,
  ACTION_DELETE_NEGOTIATED_HOTELS,
  ACTION_DELETE_NEGOTIATED_HOTELS_SUCCESS,
  ACTION_DELETE_NEGOTIATED_HOTELS_FAILURE,
} from '../configs/ActionTypes';

import _ from 'lodash-es';

const initialState = {
  negotiatedHotels: [],
  isLoading: false,
  success: null,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_DELETE_NEGOTIATED_HOTELS:
    case ACTION_GET_NEGOTIATED_HOTELS:
    case ACTION_PUT_NEGOTIATED_HOTELS: {
      return {
        ...state,
        isLoading: true,
        success: null,
      };
    }

    case ACTION_GET_NEGOTIATED_HOTELS_SUCCESS:
    case ACTION_PUT_NEGOTIATED_HOTELS_SUCCESS:
    case ACTION_DELETE_NEGOTIATED_HOTELS_SUCCESS: {
      return {
        ...state,
        negotiatedHotels: action.payload.payload,
        isLoading: false,
        success: null,
      };
    }

    case ACTION_GET_NEGOTIATED_HOTELS_FAILURE:
    case ACTION_PUT_NEGOTIATED_HOTELS_FAILURE:
    case ACTION_DELETE_NEGOTIATED_HOTELS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        success: false,
      };
    }

    default: {
      return state;
    }
  }
}

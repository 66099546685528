import * as React from 'react';
import _ from 'lodash-es';
import { Checkbox } from '@material-ui/core';

import LayoutDashboard from 'components/Layout/Dashboard';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/Breadcrumbs/Breadcrumb';

import Table from 'components/Permissions/Table/Table';
import TableRow from 'components/Permissions/Table/TableRow';
import TableColumn from 'components/Permissions/Table/TableColumn';
import CustomSelect from 'components/Inputs/Select/Select';
import Loader from 'components/Loader/Loader';

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { getAgencyPermissions } from '../../actions/ManageBB/Permissions/GetAgencyPermissions';
import { get as getAgencies, emptyResults } from 'actions/Agencies/Get';
import { get as getRoles, emptyResults as emptyRoles } from 'actions/Roles/Get';

import { ReduxState } from '../../reducers/types';

const styles = require('./styles.pcss');

class ManageBlackBoxPermissions extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      agency: '',
    };
  }

  componentDidMount() {
    const loginData = localStorage.getItem('login_data') as string;
    const parsedLoginData = JSON.parse(loginData);
    this.props.getAgencies();

    if (parsedLoginData.agencyId) {
      this.props.getRoles(parsedLoginData.agencyId);
      this.props.getAgencyPermissions(parsedLoginData.agencyId);
    }
  }

  componentWillUnmount() {
    this.props.emptyResults();
  }

  onChangeAgency = (event: any) => {
    this.props.emptyRoles();
    this.props.getRoles(event.target.value);

    this.setState({
      agency: event.target.value,
    });

    this.props.getAgencyPermissions(event.target.value);
  };

  _buildTableData = () => {
    const orderedPermissions = _.orderBy(
      this.props.agencyPermissions,
      'resourceFriendlyName',
      'asc',
    );

    return orderedPermissions.reduce(
      (acc, item) => ({
        ...acc,
        [item.resourceFriendlyName]: {
          ...acc[item.resourceFriendlyName],
          [item.role]: item,
        },
      }),
      {},
    );
  };

  renderPermissions = (roles: any) => {
    if (this.props.isLoadingPermissions) {
      return (
        <div>
          <Loader visible={this.props.isLoading} />
        </div>
      );
    }

    const permissions = this._buildTableData();

    return _.map(permissions, (value, key) => (
      <>
        <TableRow header={true}>
          <TableColumn style={{ justifyContent: 'flex-start' }}>
            {_.upperCase(key)}
          </TableColumn>
          {_.map(roles, (role: any) => {
            return <TableColumn></TableColumn>;
          })}
        </TableRow>
        {this.renderPermission(key, value, 'create', roles)}
        {this.renderPermission(key, value, 'read', roles)}
        {this.renderPermission(key, value, 'update', roles)}
        {this.renderPermission(key, value, 'delete', roles)}
        {key == 'Booking' && this.renderPermission(key, value, 'charge', roles)}
        {key == 'Booking' && this.renderPermission(key, value, 'refund', roles)}
      </>
    ));
  };

  renderPermission = (
    resource: any,
    permission: any,
    type: any,
    roles: any,
  ) => {
    return (
      <TableRow>
        <TableColumn style={{ justifyContent: 'flex-start' }}>
          {type === 'update' ? 'Edit' : _.capitalize(type)} {resource}
        </TableColumn>

        {_.map(roles, (role: any) => {
          return (
            <TableColumn>
              <Checkbox
                checked={_.get(permission, `${role.name}.${type}`, false)}
              />
            </TableColumn>
          );
        })}
      </TableRow>
    );
  };

  renderAgency = () => {
    const loginData = localStorage.getItem('login_data') as string;
    const parsedLoginData = JSON.parse(loginData);

    if (_.get(parsedLoginData, 'agencyId', false)) {
      const agency = _.find(
        this.props.agencies,
        (agency) => agency.id == parsedLoginData.agencyId,
      );
      return <>{_.get(agency, 'friendlyName', _.get(agency, 'name', ''))}</>;
    } else {
      return this.renderAgencySelection();
    }
  };

  renderAgencySelection = () => {
    return (
      <CustomSelect
        margin={'normal'}
        variant={'outlined'}
        className={styles.selectWrapperPermissions}
        htmlFor={'input-agency'}
        label={'Select Agency'}
        id={'input-agency'}
        value={this.state.agency}
        onChange={this.onChangeAgency}
        fullWidth={true}
        labelWidth={100}
        inputName={'agency'}
        MenuProps={{
          style: { marginTop: 4, boxShadow: 'none' },
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        menuItems={this.props.agencies.map((agency: any) => ({
          key: agency.id,
          value: agency.id,
          label: agency.friendlyName,
        }))}
      />
    );
  };

  render() {
    let roles = _.map(this.props.roles.roles, (role: any) => {
      if (!role.friendly_name) {
        role.friendly_name = role.name;
      }
      return role;
    });

    roles = _.sortBy(roles, [(user) => user.friendly_name.toLowerCase()]);

    if (this.props.roles.isLoading) {
      return (
        <div>
          <Loader visible={true} />
        </div>
      );
    }

    return (
      <LayoutDashboard>
        <Breadcrumbs className={styles.breadcrumbs}>
          <Breadcrumb
            name="BB Settings"
            arrow
          />
          <Breadcrumb name="Roles & Permissions" />
        </Breadcrumbs>
        <div className={styles.header}>
          <h1 className={styles.h1}>Roles & Permissions</h1>
        </div>
        <div className={styles.permissionsBody}>
          <Table>
            <TableRow>
              <TableColumn>{this.renderAgency()}</TableColumn>
              {_.map(roles, (role: any) => {
                return (
                  <TableColumn style={{ justifyContent: 'flex-start' }}>
                    {_.get(role, 'friendly_name', '')}
                  </TableColumn>
                );
              })}
            </TableRow>

            {this.renderPermissions(roles)}
          </Table>
        </div>
      </LayoutDashboard>
    );
  }
}

// Wire up redux dispatch functions
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getAgencyPermissions,
      getAgencies,
      getRoles,
      emptyRoles,
      emptyResults,
    },
    dispatch,
  );
}

function mapStateToProps(state: ReduxState) {
  return {
    agencyPermissions: state.agencyPermissions.agencyPermissions,
    isLoadingPermissions: state.agencyPermissions.isLoading,
    agencies: state.agencies.agencies,
    roles: state.roles,
  };
}

// Connect it to Redux
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ManageBlackBoxPermissions);

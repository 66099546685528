import {
  ACTION_GET_COMPANY_EMPLOYEES,
  ACTION_GET_COMPANY_EMPLOYEES_SUCCESS,
  ACTION_GET_COMPANY_EMPLOYEES_FAILURE,
  ACTION_EMPTY_COMPANY_EMPLOYEES,
} from '../configs/ActionTypes';

import { Company } from './types';

import _ from 'lodash-es';

const initialState = {
  employees: [],
  totalPages: 0,
  isLoading: false,
  success: null,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_GET_COMPANY_EMPLOYEES: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ACTION_GET_COMPANY_EMPLOYEES_SUCCESS: {
      const employees = _.get(action.payload.payload, 'employees', []);
      const totalPages = _.get(action.payload.payload, 'totalPages', 0);

      return {
        ...state,
        employees: employees,
        totalPages: totalPages,
        success: true,
        isLoading: false,
      };
    }

    case ACTION_EMPTY_COMPANY_EMPLOYEES: {
      return initialState;
    }

    case ACTION_GET_COMPANY_EMPLOYEES_FAILURE: {
      return {
        ...state,
        isLoading: false,
        success: false,
      };
    }

    default: {
      return state;
    }
  }
}

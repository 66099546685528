import React, { Component } from 'react';
import classNames from 'classnames';

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import _ from 'lodash-es';

import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextFieldWithClear from 'components/TextFieldWithIcon/TextFieldWithIcon';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconRadioChecked from '../../../static/icons/svgRadioChecked';
import IconRadio from '../../../static/icons/svgRadio';
import CancelIcon from '@material-ui/icons/Cancel';
import FeatureFlagPopupComponent from './FeatureFlagPopupComponent';
import CustomSelect from 'components/Inputs/Select/Select';
import MultiSelectWithSearchDynamic from '../../Inputs/MultiSelect/MultiSelectWithSearchDynamic';
import FormHelperText from '@material-ui/core/FormHelperText';
import Loader from 'components/Loader/Loader';
import { hasPermission } from '../../../helpers/Permission';

const styles = require('../styles.pcss');

import {
  postFeature,
  resetFeatureExceptList,
} from '../../../actions/ManageTB/Features/Post';
import { putFeature } from '../../../actions/ManageTB/Features/Put';
import { deleteFeature } from '../../../actions/ManageTB/Features/Delete';
import {
  get as getAgencies,
  emptyResults as emptyAgencies,
} from 'actions/Agencies/Get';
import { get as getUsers, emptyResults as emptyUsers } from 'actions/Users/Get';
import {
  get as getCompanies,
  emptyResults as emptyCompanies,
} from 'actions/Companies/Get';
import { ReduxState } from '../../../reducers/types';

type Props = {
  openPopup: boolean;
  closePopup: any;
  postFeature: Function;
  addNewFeatureFlag: boolean;
  getCompanies: Function;
  getAgencies: Function;
  getUsers: Function;
  companies: any;
  isLoadingCompanies: boolean;
  emptyCompanies: Function;
  emptyAgencies: Function;
  emptyUsers: Function;
  isLoadingFeatures: Boolean;
  features: any;
  flag: Array<any>;
  putFeature: Function;
  deleteFeature: Function;
  resetFeatureExceptList: Function;
  isLoadingAgencies: boolean;
  isLoadingUsers: boolean;
};

type State = {
  name: string;
  friendlyName: string;
  errorName: any;
  errorPlatform: any;
  errorFriendlyName: any;
  waitingResponse: boolean;
  saveCompanyStatus: boolean;
  confidence: string;
  radioButtonState: any;
  errorOrganizaition: any;
  organizations: Array<any>;
  errorAgency: any;
  agencies: Array<any>;
  errorUser: any;
  users: Array<any>;
  errorConfidence: any;
  companyId: any;
  companies: any;
  value: any;
  selectedOrgs: any;
  selectedAgencies: any;
  selectedUsers: any;
  checkedItems: Array<any>;
  flagVersions: any;
  init: Boolean;
  defCompany: any;
  deleteFlag: any;
  agencyMultiSelectValue: any;
  userMultiSelectValue: any;
};

class FeatureFlagPopup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      name: _.get(this.props.flag, '[0].name', ''),
      friendlyName: _.get(this.props.flag, '[0].friendlyName', ''),
      errorName: '',
      errorFriendlyName: '',
      errorPlatform: '',
      errorOrganizaition: '',
      errorAgency: '',
      errorUser: '',
      waitingResponse: false,
      saveCompanyStatus: false,
      confidence: _.get(this.props.flag, '[0].confidence', 'preview'),
      radioButtonState: this.getRadioButtonState(
        _.get(this.props.flag[0], 'scope', {}),
      ),
      organizations: [],
      agencies: [],
      users: [],
      errorConfidence: '',
      companyId: '',
      companies: '',
      deleteFlag: false,
      value: '',
      selectedOrgs: [],
      selectedAgencies: [],
      selectedUsers: [],
      checkedItems: this.props.flag.map((flag: any) =>
        _.get(flag, 'platform', ''),
      ),
      flagVersions: {
        api: this.getVersion('api', this.props.flag),
        web: this.getVersion('web', this.props.flag),
        android: this.getVersion('android', this.props.flag),
        ios: this.getVersion('ios', this.props.flag),
      },
      init: true,
      defCompany: [],
      agencyMultiSelectValue: '',
      userMultiSelectValue: '',
    };
  }

  getRadioButtonState = (scope: any) => {
    if (_.isEmpty(scope)) {
      return 'all';
    }

    return scope?.type;
  };

  getVersion = (platform: any, flags: any) => {
    return _.result(
      _.find(flags, (p) => p.platform == platform),
      'version',
    );
  };

  getFlagId = (platform: any, flags: any) => {
    return _.result(
      _.find(flags, (p) => p.platform == platform),
      'id',
    );
  };

  getAgenciesWithFriendlyName = (agencies: any) => {
    agencies.map((agency: any, i: number) => {
      if (
        _.get(agency, 'friendlyName', false) &&
        agency.friendlyName
          .toLowerCase()
          .includes(this.state.agencyMultiSelectValue.toLowerCase())
      ) {
        agency.name = agency.friendlyName;
      }
    });

    agencies = agencies.sort(
      (a: any, b: any) => a?.name.localeCompare(b?.name),
    );

    return agencies;
  };

  componentWillReceiveProps = (nextProps: any) => {
    if (
      _.get(nextProps.features, 'isLoading', null) === false &&
      _.get(nextProps.features, 'success', null) !== false &&
      _.get(nextProps, 'flag', null) !== _.get(this.props, 'flag', null)
    ) {
      this.setState({
        name: _.get(nextProps.flag, '[0].name', ''),
        friendlyName: _.get(nextProps.flag, '[0].friendlyName', ''),
        confidence: _.get(nextProps.flag, '[0].confidence', 'preview'),
        checkedItems: nextProps.flag.map((flag: any) =>
          _.get(flag, 'platform', ''),
        ),
        flagVersions: {
          api: this.getVersion('api', nextProps.flag),
          web: this.getVersion('web', nextProps.flag),
          android: this.getVersion('android', nextProps.flag),
          ios: this.getVersion('ios', nextProps.flag),
        },
        ...(this.getRadioButtonState(_.get(nextProps, 'flag[0].scope', {})) !==
        this.getRadioButtonState(_.get(this.props, 'flag[0].scope', {}))
          ? {
              radioButtonState: this.getRadioButtonState(
                _.get(nextProps, 'flag[0].scope', {}),
              ),
            }
          : { radioButtonState: this.state.radioButtonState }),
      });
    }

    if (
      this.state.init ||
      _.get(nextProps, 'flag', null) !== _.get(this.props, 'flag', null)
    ) {
      const newOrgIds = _.get(nextProps.flag, '[0]scope.organizations', []);
      const newAgencyIds = _.get(nextProps.flag, '[0]scope.agencies', []);
      const newUserIds = _.get(nextProps.flag, '[0]scope.users', []);

      if (newOrgIds.length > 0) {
        this.props.getCompanies({ organizationIds: newOrgIds });
      }

      if (newAgencyIds.length > 0) {
        this.props.getAgencies({ agencyIds: newAgencyIds });
      }

      if (newUserIds.length > 0) {
        this.props.getUsers({ userIds: newUserIds });
      }

      this.setState({
        init: false,
        ...(this.getRadioButtonState(_.get(nextProps, 'flag[0].scope', {})) !==
        this.getRadioButtonState(_.get(this.props, 'flag[0].scope', {}))
          ? {
              radioButtonState: this.getRadioButtonState(
                _.get(nextProps, 'flag[0].scope', {}),
              ),
            }
          : { radioButtonState: this.state.radioButtonState }),
      });
    }

    if (
      nextProps.companies.length !== 0 &&
      this.state.selectedOrgs.length < 1 &&
      _.get(nextProps.flag, '[0]scope.organizations', []).length > 0
    ) {
      this.setState({
        selectedOrgs: nextProps.companies.map((data: any) => ({
          value: data.id,
          label: data.name,
        })),
      });
    }

    if (
      nextProps.agencies.length !== 0 &&
      this.state.selectedAgencies.length === 0 &&
      _.get(nextProps.flag, '[0]scope.agencies', []).length > 0
    ) {
      this.setState({
        selectedAgencies: this.getAgenciesWithFriendlyName(
          nextProps.agencies,
        ).map((data: any) => ({
          value: data.id,
          label: data.name,
        })),
      });
    }

    if (
      nextProps.users.length !== 0 &&
      this.state.selectedUsers.length === 0 &&
      _.get(nextProps.flag, '[0]scope.users', []).length > 0
    ) {
      this.setState({
        selectedUsers: nextProps.users.map((data: any) => ({
          value: data.id,
          label: `${data.firstName} ${data.lastName} - ${data.email}`,
        })),
      });
    }

    if (nextProps.companies.length !== 0) {
      nextProps.companies.sort((a: any, b: any) =>
        a.name.localeCompare(b.name),
      );
      this.setState({
        companies: nextProps.companies,
      });
    }

    if (nextProps.agencies.length !== 0) {
      this.setState({
        agencies: this.getAgenciesWithFriendlyName(nextProps.agencies),
      });
    }

    if (nextProps.users.length !== 0) {
      nextProps.users.sort((a: any, b: any) => a.email.localeCompare(b.email));
      this.setState({
        users: nextProps.users,
      });
    }

    if (_.get(nextProps.features, 'success', null) === false) {
      this.setState({
        errorName: _.get(nextProps.features, 'errors.name', ''),
        errorPlatform: _.get(nextProps.features, 'errors.platform', ''),
        errorConfidence: _.get(nextProps.features, 'errors.confidence', ''),
      });
    } else if (_.get(nextProps.features, 'success', null) === true) {
      this.closePopup();
    }
  };

  componentWillUnmount() {
    this.setState({
      init: true,
      defCompany: [],
    });
  }

  handleChangeName = (event: any) => {
    this.setState({
      name: event.target.value,
      errorName: false,
    });
  };

  handleChangeFriendlyName = (event: any) => {
    this.setState({
      friendlyName: event.target.value,
      errorFriendlyName: false,
    });
  };

  onChangeConfidence = (event: any) => {
    this.setState({
      confidence: event.target.value,
      errorConfidence: false,
    });
  };

  onDeleteName = () => {
    this.setState({
      name: '',
      errorName: false,
    });
  };

  onDeleteFriendlyName = () => {
    this.setState({
      friendlyName: '',
      errorFriendlyName: false,
    });
  };

  handleSubmit = () => {
    let errorName = '';
    let errorPlatform = '';
    let errorConfidence = '';
    let errorOrganizaition = '';
    let errorAgency = '';
    let errorUser = '';
    let isValid = true;

    if (this.state.name.trim() == '') {
      errorName = 'Required';
      isValid = false;
    }

    if (this.state.checkedItems.length < 1) {
      errorPlatform = 'Required';
      isValid = false;
    }

    if (this.state.confidence.trim() == '') {
      errorConfidence = 'Required';
      isValid = false;
    }

    if (
      this.state.radioButtonState.trim() == 'organization' &&
      this.state.selectedOrgs.length < 1
    ) {
      errorOrganizaition = 'Required';
      isValid = false;
    }

    if (
      this.state.radioButtonState.trim() == 'agency' &&
      this.state.selectedAgencies.length < 1
    ) {
      errorAgency = 'Required';
      isValid = false;
    }

    if (
      this.state.radioButtonState.trim() == 'user' &&
      this.state.selectedUsers.length < 1
    ) {
      errorUser = 'Required';
      isValid = false;
    }

    this.setState({
      errorName,
      errorPlatform,
      errorConfidence,
      errorOrganizaition,
      errorAgency,
      errorUser,
    });

    if (isValid) {
      let orgIds: Array<any> = [];
      let platform: Array<any> = [];
      let agencyIds: Array<any> = [];
      let userIds: Array<any> = [];
      let newScope: any = null;

      if (this.state.radioButtonState === 'organization') {
        orgIds = this.state.selectedOrgs.map((orgs: any) => orgs.value);
        newScope = {
          type: 'organization',
          organizations: orgIds,
        };
      }

      if (this.state.radioButtonState === 'agency') {
        agencyIds = this.state.selectedAgencies.map(
          (agency: any) => agency.value,
        );
        newScope = {
          type: 'agency',
          agencies: agencyIds,
        };
      }

      if (this.state.radioButtonState === 'user') {
        userIds = this.state.selectedUsers.map((user: any) => user.value);
        newScope = {
          type: 'user',
          users: userIds,
        };
      }

      let flagVersions = _.clone(this.state.flagVersions);

      if (this.props.addNewFeatureFlag) {
        const checkedlist = _.uniq(this.state.checkedItems);
        checkedlist.forEach((item: any) => {
          platform.push({ platform: item, version: flagVersions[item] });
        });

        this.props.postFeature({
          name: this.state.name,
          friendlyName: this.state.friendlyName,
          confidence: this.state.confidence,
          scope: newScope,
          platform,
        });
      } else {
        const checkedlist = _.uniq(this.state.checkedItems);
        checkedlist.forEach((item: any) => {
          platform.push({
            platform: item,
            version: flagVersions[item],
            id: this.getFlagId(item, this.props.flag),
          });
        });

        let toDelete: Array<any> = [];

        this.props.flag.forEach((item: any) => {
          if (this.state.checkedItems.indexOf(item.platform) == -1) {
            toDelete.push(item.id);
          }
        });

        const confidenceUpdated =
          _.get(this.props.flag, '[0].confidence', 'preview') !=
          this.state.confidence;
        const scopeUpdated =
          this.getRadioButtonState(_.get(this.props.flag[0], 'scope', {})) !=
          this.state.radioButtonState;

        this.props.putFeature(
          {
            name: this.state.name,
            friendlyName: this.state.friendlyName,
            confidence: this.state.confidence,
            scope: newScope,
            platform,
            toDelete,
            nameUpdate: this.state.name !== this.props.flag[0].name,
          },
          _.get(this.props.flag, '[0].name', ''),
          confidenceUpdated,
          scopeUpdated,
        );
      }

      this.setState({
        waitingResponse: true,
      });
    }
  };

  onChange = (val: any) => (event: any, checked: any) => {
    let values = _.clone(this.state.checkedItems);

    if (checked) {
      values.push(val);
    } else {
      _.remove(values, (v) => val === v);
    }

    this.setState({
      checkedItems: values,
    });
  };

  onChangeRadioButton = (event: any, value: any) => {
    this.setState({
      radioButtonState: value,
      errorOrganizaition: '',
      errorAgency: '',
      errorUser: '',
      selectedOrgs: [],
      selectedAgencies: [],
      selectedUsers: [],
    });
  };

  handleChangeOrganizations = (organizations: any) => {
    this.setState({
      organizations,
      errorOrganizaition: '',
    });
  };

  handleChangeAgencies = (agencies: any) => {
    this.setState({
      agencies,
      errorAgency: '',
    });
  };

  handleChangeUsers = (users: any) => {
    this.setState({
      users,
      errorUser: '',
    });
  };

  onChangecompanyAuto = (companyId: any) => {
    this.setState({
      companyId,
    });
  };

  getCompanies = (value: string) => {
    this.emptyCompanies();

    this.props.getCompanies({
      name: value,
    });
  };

  emptyCompanies = () => {
    this.props.emptyCompanies();
  };

  getAgencies = (value: string) => {
    this.emptyAgencies();

    this.props.getAgencies({
      name: value,
      friendlyName: value,
      type: 'or',
    });

    this.setState({
      agencyMultiSelectValue: value,
    });
  };

  emptyAgencies = () => {
    this.props.emptyAgencies();
  };

  getUsers = (value: string) => {
    this.emptyUsers();

    this.props.getUsers({
      email: value,
      type: 'or',
    });

    this.setState({
      userMultiSelectValue: value,
    });
  };

  emptyUsers = () => {
    this.props.emptyUsers();
  };

  selectedOrgChange = (value: any) => {
    this.setState({
      selectedOrgs: value,
      errorOrganizaition: '',
    });
  };

  selectedAgenciesChange = (value: any) => {
    this.setState({
      selectedAgencies: value,
      errorAgency: '',
    });
  };

  selectedUsersChange = (value: any) => {
    this.setState({
      selectedUsers: value,
      errorUser: '',
    });
  };

  renderOrganizationsInput = () => {
    if (this.state.radioButtonState === 'all') {
      return null;
    }

    if (this.state.radioButtonState === 'organization') {
      return (
        <MultiSelectWithSearchDynamic
          error={this.state.errorOrganizaition}
          className={styles.dropdownContainer}
          onChange={(value: any) => {
            this.getCompanies(value);
          }}
          label={'Feature Flags Available To'}
          returnAll={true}
          multiValue={this.state.selectedOrgs}
          dataSet={this.state.companies || []}
          isLoading={this.props.isLoadingCompanies}
          changeSelectedValue={this.selectedOrgChange}
          disabled={this.props.isLoadingFeatures}
          id="features-available-to"
        />
      );
    }

    if (this.state.radioButtonState === 'user') {
      return (
        <MultiSelectWithSearchDynamic
          error={this.state.errorUser}
          className={styles.dropdownContainer}
          onChange={(value: any) => {
            this.getUsers(value);
          }}
          label={'Users Available To'}
          returnAll={true}
          multiValue={this.state.selectedUsers}
          dataSet={this.state.users || []}
          isLoading={this.props.isLoadingUsers}
          changeSelectedValue={this.selectedUsersChange}
          disabled={this.props.isLoadingFeatures}
          id="features-available-to-user"
          type="user"
        />
      );
    }

    return (
      <MultiSelectWithSearchDynamic
        error={this.state.errorAgency}
        className={styles.dropdownContainer}
        onChange={(value: any) => {
          this.getAgencies(value);
        }}
        label={'Agencies Available To'}
        returnAll={true}
        multiValue={this.state.selectedAgencies}
        dataSet={this.getAgenciesWithFriendlyName(this.state.agencies) || []}
        isLoading={this.props.isLoadingAgencies}
        changeSelectedValue={this.selectedAgenciesChange}
        disabled={this.props.isLoadingFeatures}
        id="features-available-to-agency"
      />
    );
  };

  renderPlatformError = () => {
    if (Boolean(this.state.errorPlatform)) {
      return <FormHelperText error>{this.state.errorPlatform}</FormHelperText>;
    }

    return null;
  };

  renderCompanyError = () => {
    if (Boolean(this.state.errorOrganizaition)) {
      return (
        <FormHelperText error>{this.state.errorOrganizaition}</FormHelperText>
      );
    }

    return null;
  };

  renderAgencyError = () => {
    if (Boolean(this.state.errorAgency)) {
      return <FormHelperText error>{this.state.errorAgency}</FormHelperText>;
    }

    return null;
  };

  renderUserError = () => {
    if (Boolean(this.state.errorUser)) {
      return <FormHelperText error>{this.state.errorUser}</FormHelperText>;
    }

    return null;
  };

  isChecked = (value: string) => {
    return this.state.checkedItems.indexOf(value) > -1;
  };

  onChangeFlagVersion = (type: string, event: any) => {
    let flagVersions = _.clone(this.state.flagVersions);
    flagVersions[type] = event.target.value;

    this.setState({
      flagVersions: flagVersions,
    });
  };

  handleClearFlagVersion = (type: any, resp: any) => {
    let flagVersions = _.clone(this.state.flagVersions);
    flagVersions[type] = '';

    this.setState({
      flagVersions: flagVersions,
    });
  };

  renderConfidenceError = () => {
    if (Boolean(this.state.errorConfidence)) {
      return (
        <FormHelperText error>{this.state.errorConfidence}</FormHelperText>
      );
    }

    return null;
  };
  renderDeleteFlag = () => {
    if (!this.props.addNewFeatureFlag && hasPermission('features', 'delete')) {
      if (!this.state.deleteFlag) {
        return (
          <div className={styles.deleteButtonContainer}>
            <Button
              size="small"
              className={styles.buttonDeleteProfile}
              variant="contained"
              onClick={this.onClickDeleteFlag}
            >
              Delete Feature Flag
            </Button>
          </div>
        );
      } else {
        return (
          <div className={styles.deleteFlagContainer}>
            <div className={styles.deleteFlagTextContainer}>
              <div className={styles.deleteFlagText}>Delete Feature Flag</div>
              <div className={styles.deleteFlagDescription}>
                Please confirm you want to delete {this.state.name} flag.
              </div>
            </div>
            <div className={styles.deleteButtonContainer}>
              <Button
                size="small"
                className={styles.buttonDeleteProfile}
                variant="contained"
                onClick={this.onClickCancelDeleteFlag}
              >
                Cancel
              </Button>
              <Button
                size="small"
                className={styles.buttonDeleteProfile}
                variant="contained"
                onClick={this.onClickConfirmDeleteFlag}
              >
                Delete
              </Button>
            </div>
          </div>
        );
      }
    }
    return null;
  };

  onClickDeleteFlag = () => {
    this.setState({
      deleteFlag: true,
    });
  };

  onClickCancelDeleteFlag = () => {
    this.setState({
      deleteFlag: false,
    });
  };

  onClickConfirmDeleteFlag = () => {
    let toDelete: Array<any> = [];

    this.props.flag.forEach((item: any) => {
      toDelete.push(item.id);
    });

    this.props.deleteFeature(toDelete);
  };

  renderLoader = () => {
    if (!this.props.isLoadingFeatures) {
      return null;
    }

    return (
      <div className={styles.loaderContainer}>
        <Loader visible={true} />
      </div>
    );
  };

  getFlagOrgIds = (flag: any) => {
    return _.get(flag, '[0]scope.organizations', []);
  };

  closePopup = () => {
    this.setState({
      name: '',
      agencyMultiSelectValue: '',
      userMultiSelectValue: '',
      friendlyName: '',
      errorName: '',
      errorFriendlyName: '',
      errorPlatform: '',
      errorOrganizaition: '',
      errorAgency: '',
      errorUser: '',
      agencies: [],
      selectedAgencies: [],
      users: [],
      selectedUsers: [],
      waitingResponse: false,
      saveCompanyStatus: false,
      confidence: '',
      radioButtonState: 'all',
      organizations: [],
      errorConfidence: '',
      companyId: '',
      companies: '',
      value: '',
      selectedOrgs: [],
      checkedItems: [],
      flagVersions: {
        api: '',
        web: '',
        android: '',
        ios: '',
      },
      init: true,
      deleteFlag: false,
      defCompany: [],
    });

    this.props.closePopup();
    this.props.emptyCompanies();
    this.props.emptyAgencies();
    this.props.emptyUsers();
    this.props.resetFeatureExceptList();
  };

  render() {
    let title = this.props.addNewFeatureFlag
      ? 'Add Feature Flag'
      : 'Update Feature Flag';
    return (
      <Dialog
        fullWidth={true}
        maxWidth="md"
        className={classNames(styles.dialogBox, styles.ccPopup)}
        open={this.props.openPopup}
        onClose={this.closePopup}
      >
        <div className={classNames(styles.title, styles.featureFlagPopup)}>
          <div className={styles.titleStyle}>{title}</div>
          <IconButton
            color="inherit"
            onClick={this.closePopup}
            className={classNames(styles.closeIcon)}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        </div>
        {this.renderLoader()}

        <div className={classNames(styles.updatePopup, styles.editTraveler)}>
          <div
            className={classNames(styles.informationTitle, styles.addMarginTop)}
          >
            <h2>Flag Name</h2>
          </div>

          <div
            className={classNames(
              styles.inputContainer,
              styles.inputContainerCreditCard,
            )}
          >
            <div className={styles.inputWrapper}>
              <TextFieldWithClear
                helperText={this.state.errorName}
                fullWidth
                id="name"
                className={classNames(styles.textField)}
                label="Name"
                variant="outlined"
                margin="normal"
                value={this.state.name}
                onChange={this.handleChangeName}
                error={this.state.errorName}
                onClear={this.onDeleteName}
              />
            </div>

            <div className={styles.inputWrapper}>
              <TextFieldWithClear
                fullWidth
                id="frname"
                className={classNames(styles.textField)}
                label="Friendly Name"
                variant="outlined"
                margin="normal"
                value={this.state.friendlyName}
                onChange={this.handleChangeFriendlyName}
                error={this.state.errorFriendlyName}
                onClear={this.onDeleteFriendlyName}
              />
            </div>
          </div>
          <div className={classNames(styles.informationTitle)}>
            <h2>Platforms</h2>
            {this.renderPlatformError()}
          </div>

          <div className={styles.flagPlatformRow}>
            <FeatureFlagPopupComponent
              value={this.state.flagVersions.android}
              onChange={this.onChange('android')}
              error={this.state.errorFriendlyName}
              onClear={this.handleClearFlagVersion.bind(this, 'android')}
              label={'Android'}
              id={'android'}
              checked={this.isChecked('android')}
              onChangeVersion={this.onChangeFlagVersion}
              disabled={this.props.isLoadingFeatures}
            />
          </div>

          <div className={styles.flagPlatformRow}>
            <FeatureFlagPopupComponent
              value={this.state.flagVersions.api}
              onChange={this.onChange('api')}
              error={this.state.errorFriendlyName}
              onClear={this.handleClearFlagVersion.bind(this, 'api')}
              id={'api'}
              checked={this.isChecked('api')}
              label={'API'}
              onChangeVersion={this.onChangeFlagVersion}
              disabled={this.props.isLoadingFeatures}
            />
          </div>

          <div className={styles.flagPlatformRow}>
            <FeatureFlagPopupComponent
              value={this.state.flagVersions.ios}
              onChange={this.onChange('ios')}
              error={this.state.errorFriendlyName}
              onClear={this.handleClearFlagVersion.bind(this, 'ios')}
              id={'ios'}
              checked={this.isChecked('ios')}
              onChangeVersion={this.onChangeFlagVersion}
              disabled={this.props.isLoadingFeatures}
              label={'iOS'}
            />
          </div>
          <div className={styles.flagPlatformRow}>
            <FeatureFlagPopupComponent
              value={this.state.flagVersions.web}
              onChange={this.onChange('web')}
              error={this.state.errorFriendlyName}
              onClear={this.handleClearFlagVersion.bind(this, 'web')}
              id={'web'}
              onChangeVersion={this.onChangeFlagVersion}
              checked={this.isChecked('web')}
              disabled={this.props.isLoadingFeatures}
              label={'Web'}
            />
          </div>

          <div
            className={classNames(styles.informationTitle, styles.addMarginTop)}
          >
            <h2>Scope</h2>
          </div>

          <div className={styles.row}>
            <div className={styles.inputWrapper}>
              <CustomSelect
                margin={'normal'}
                variant={'outlined'}
                className={styles.selectWrapper}
                error={this.state.errorConfidence}
                htmlFor={'input-confidence'}
                label={'Confidence'}
                id={'input-Confidence'}
                value={this.state.confidence}
                onChange={this.onChangeConfidence}
                renderError={this.renderConfidenceError()}
                fullWidth={true}
                labelWidth={80}
                inputName={'confidence'}
                MenuProps={{
                  style: { marginTop: 4, boxShadow: 'none' },
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
                menuItems={[
                  {
                    key: 'production',
                    value: 'production',
                    label: 'Production',
                  },
                  {
                    key: 'staging',
                    value: 'staging',
                    label: 'Staging',
                  },
                  ,
                  {
                    key: 'preview',
                    value: 'preview',
                    label: 'Preview',
                  },
                  {
                    key: 'development',
                    value: 'development',
                    label: 'Development',
                  },
                  {
                    key: 'test',
                    value: 'test',
                    label: 'Test',
                  },
                ]}
              />
            </div>
          </div>

          <div className={styles.radiogroupFeature}>
            <RadioGroup
              aria-label=""
              name="organ"
              className={styles.radioGroupUpdatePopup}
              value={this.state.radioButtonState}
              onChange={this.onChangeRadioButton}
              id="radio-group-specific"
            >
              <FormControlLabel
                value={'all'}
                control={
                  <Radio
                    icon={
                      <IconRadio
                        width={16}
                        height={16}
                      />
                    }
                    checkedIcon={
                      <IconRadioChecked
                        width={16}
                        height={16}
                      />
                    }
                    className={styles.checkboxReview}
                  />
                }
                label={
                  <span className={styles.radioGroupLabel}>
                    Available to Everyone
                  </span>
                }
              />
              <FormControlLabel
                value={'agency'}
                control={
                  <Radio
                    icon={
                      <IconRadio
                        width={16}
                        height={16}
                      />
                    }
                    checkedIcon={
                      <IconRadioChecked
                        width={16}
                        height={16}
                      />
                    }
                    className={styles.checkboxReview}
                  />
                }
                label={
                  <span className={styles.radioGroupLabel}>
                    Restrict to Specific Agencies
                  </span>
                }
              />
              <FormControlLabel
                value={'organization'}
                control={
                  <Radio
                    icon={
                      <IconRadio
                        width={16}
                        height={16}
                      />
                    }
                    checkedIcon={
                      <IconRadioChecked
                        width={16}
                        height={16}
                      />
                    }
                    className={styles.checkboxReview}
                  />
                }
                label={
                  <span className={styles.radioGroupLabel}>
                    Restrict to Specific Organizations
                  </span>
                }
              />
              <FormControlLabel
                value={'user'}
                control={
                  <Radio
                    icon={
                      <IconRadio
                        width={16}
                        height={16}
                      />
                    }
                    checkedIcon={
                      <IconRadioChecked
                        width={16}
                        height={16}
                      />
                    }
                    className={styles.checkboxReview}
                  />
                }
                label={
                  <span className={styles.radioGroupLabel}>
                    Restrict to Specific Users
                  </span>
                }
              />
            </RadioGroup>

            {this.renderOrganizationsInput()}
            {this.renderCompanyError()}
            {this.renderAgencyError()}
            {this.renderUserError()}
          </div>
          {this.renderDeleteFlag()}

          <div className={classNames(styles.actions, styles.actionsRight)}>
            <div className={styles.popupButtonsContainer}>
              <Button
                size="small"
                className={styles.cancelButton}
                color="primary"
                onClick={this.closePopup}
              >
                Cancel
              </Button>

              <Button
                size="small"
                className={styles.button}
                variant="contained"
                color="primary"
                onClick={this.handleSubmit}
                id="submit-feature-button"
              >
                {this.props.addNewFeatureFlag ? 'Add' : 'Save'}
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {
    saveCompanyStatus: state.saveCompanyStatus,
    companies: state.companies.companies,
    isLoadingCompanies: state.companies.isLoading,
    isLoadingFeatures: state.features.isLoading,
    isLoadingAgencies: state.agencies.isLoading,
    isLoadingUsers: state.users.isLoading,
    agencies: state.agencies.agencies,
    users: state.users.users,
    features: state.features,
  };
}

// Wire up redux dispatch functions
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      deleteFeature,
      postFeature,
      getCompanies,
      emptyCompanies,
      getAgencies,
      emptyAgencies,
      getUsers,
      emptyUsers,
      putFeature,
      resetFeatureExceptList,
    },
    dispatch,
  );
}

// Connect it to Redux
export default connect(mapStateToProps, mapDispatchToProps)(FeatureFlagPopup);

/**
 * ADD Travelers Action
 */
import _ from 'lodash-es';
import { navigate } from '@reach/router';

import {
  ACTION_ADD_TRAVELER,
  ACTION_ADD_TRAVELER_FAILURE,
  ACTION_ADD_TRAVELER_SUCCESS,
  API_CALL,
} from '../../configs/ActionTypes';

import { Dispatch } from 'redux';

import { enqueueSnackbarHandler } from '../Status';

/**
 * POST Users Request Action
 */
export function addTraveler(traveler: any) {
  return (dispatch: Dispatch, getState: Function) => {
    dispatch(requestStarted());

    dispatch({
      type: API_CALL,
      payload: {
        call: `traveler`,
        method: 'POST',
        data: JSON.stringify(traveler),
        success: (response: any) => {
          if (response.type === 'error') {
            dispatch(requestFailed(response));
          } else {
            enqueueSnackbarHandler(
              dispatch,
              { addTravelerSuccess: true },
              'success',
            );
            dispatch(requestSuccess(response));
          }
        },
        fail: (error: any) => {
          dispatch(requestFailed(error));
        },
      },
    });

    return null;
  };
}

export function requestStarted() {
  return {
    type: ACTION_ADD_TRAVELER,
    payload: null,
  };
}

export function requestFailed(responseJSON: object) {
  return {
    type: ACTION_ADD_TRAVELER_FAILURE,
    payload: responseJSON,
  };
}

export function requestSuccess(responseJSON: object) {
  return {
    type: ACTION_ADD_TRAVELER_SUCCESS,
    payload: responseJSON,
  };
}

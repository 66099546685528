import {
  ACTION_GET_CARBON_EMISSION_PRODUCTS,
  ACTION_GET_CARBON_EMISSION_PRODUCTS_FAILURE,
  ACTION_GET_CARBON_EMISSION_PRODUCTS_SUCCESS,
  ACTION_UPDATE_CARBON_EMISSION_PRODUCT,
  ACTION_UPDATE_CARBON_EMISSION_PRODUCT_FAILURE,
  ACTION_UPDATE_CARBON_EMISSION_PRODUCT_SUCCESS,
  ACTION_GET_CARBON_EMISSION_ORG_PRODUCT,
  ACTION_GET_CARBON_EMISSION_ORG_PRODUCT_FAILURE,
  ACTION_GET_CARBON_EMISSION_ORG_PRODUCT_SUCCESS,
} from '../configs/ActionTypes';
import { CarbonOffsetProduct } from 'components/CreateUpdateCarbonEmissionProduct/types';

export interface CarbonOffsetReduxState {
  productId: string;
  isLoading: boolean;
  success: boolean | null;
  carbonOffsetProducts?: CarbonOffsetProduct[];
}

const initialState = {
  productId: '',
  carbonOffsetProducts: [],
  isLoading: false,
  success: null,
};

export default function (
  state: CarbonOffsetReduxState = initialState,
  action: any,
) {
  switch (action.type) {
    case ACTION_UPDATE_CARBON_EMISSION_PRODUCT:
    case ACTION_GET_CARBON_EMISSION_ORG_PRODUCT:
    case ACTION_GET_CARBON_EMISSION_PRODUCTS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ACTION_UPDATE_CARBON_EMISSION_PRODUCT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        success: true,
      };
    }
    case ACTION_GET_CARBON_EMISSION_ORG_PRODUCT_SUCCESS: {
      const { payload: { payload: carbonOffsetInfo = {} } = {} } = action || {};

      const { value: { id: productId = '' } = {} } = carbonOffsetInfo;

      return {
        ...state,
        productId,
        isLoading: false,
        success: true,
      };
    }
    case ACTION_GET_CARBON_EMISSION_PRODUCTS_SUCCESS: {
      const { payload: { payload: carbonOffsetProducts = [] } = {} } =
        action || {};

      return {
        ...state,
        carbonOffsetProducts,
        isLoading: false,
        success: true,
      };
    }
    case ACTION_UPDATE_CARBON_EMISSION_PRODUCT_FAILURE:
    case ACTION_GET_CARBON_EMISSION_ORG_PRODUCT_FAILURE:
    case ACTION_GET_CARBON_EMISSION_PRODUCTS_FAILURE: {
      return {
        ...state,
        carbonOffsetProducts: [],
        isLoading: false,
        success: false,
      };
    }

    default: {
      return state;
    }
  }
}

import { render } from 'react-dom';
import * as React from 'react';
import 'react-dates/initialize';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import AppRouter from './Router';
import { MuiBlackBoxTheme } from './global-styles/MuiBlackBoxTheme';
import Notifier from './components/Snackbar/SnackBar';
import SnackBarWrapper from './components/Snackbar/SnackBarWrapper';

import { Provider } from 'react-redux';
import reduxStore from './configs/ReduxStore';

import './global-styles/global.pcss';
import './analytics.js';

const theme = createMuiTheme(MuiBlackBoxTheme as any);

window.onload = () => {
  // Render the output
  render(
    <Provider store={reduxStore}>
      <MuiThemeProvider theme={theme}>
        <SnackBarWrapper>
          <Notifier />
        </SnackBarWrapper>
        <AppRouter />
      </MuiThemeProvider>
    </Provider>,
    document.getElementById('root'),
  );
};

import React from 'react';

type Props = {
  width: number;
  height: number;
};

const SvgRadio = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Rectangle 13</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Master"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Travel---Hotel-Search-Results---Review-Filter"
          transform="translate(-495.000000, -360.000000)"
          stroke="#C6C8CC"
        >
          <g
            id="reviews---filter"
            transform="translate(471.000000, 243.000000)"
          >
            <rect
              id="Rectangle-13"
              x="24.5"
              y="117.5"
              width="15"
              height="15"
              rx="7.5"
            ></rect>
          </g>
        </g>
      </g>
    </svg>
  );
};

SvgRadio.defaultProps = {
  width: 16,
  height: 16,
};

export default SvgRadio;

import * as React from 'react';
import classNames from 'classnames';
import { navigate } from '@reach/router';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { Agency } from '../../reducers/types';

const styles = require('./styles.pcss');

type Props = {
  agency: Agency;
};

const AgencyTableRow = ({ agency }: Props) => (
  <TableRow
    className={classNames(styles.clickable, styles.tableRow)}
    key={agency.id}
    onClick={() => navigate(`/agency/${agency.id}`)}
  >
    <TableCell
      className={classNames(styles.tableRowCell)}
      scope="row"
    >
      {agency.friendlyName}
    </TableCell>
    <TableCell className={classNames(styles.tableRowCell)}>
      {agency.appHost}
    </TableCell>
    <TableCell className={classNames(styles.tableRowCell)}></TableCell>
  </TableRow>
);

export default AgencyTableRow;

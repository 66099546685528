import React from 'react';
import cx from 'classnames';
import moment from 'moment';
moment.updateLocale('en', { weekdaysMin: 'S_M_T_W_T_F_S'.split('_') });
import TextField from '@material-ui/core/TextField';
import Modal from '@material-ui/core/Modal';
import Popover from '@material-ui/core/Popover';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import CancelIcon from '@material-ui/icons/Cancel';
import BackIcon from '@material-ui/icons/ArrowBackIos';
import ForwardIcon from '@material-ui/icons/ArrowForwardIos';
import { DayPickerSingleDateController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

const styles = require('./styles.pcss');

type Props = {
  onChange: Function;
  label: string;
  className?: any;
  error?: string;
  date: any;
  id: any;
  maxYear?: string;
  disabled?: boolean;
};

type State = {
  modalAnchor: any;
  date: any;
  focused: boolean;
};

class DatePicker extends React.Component<Props, State> {
  containerRef: any;
  inputRef: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      modalAnchor: null,
      date: props.date,
      focused: false,
    };

    this.containerRef = React.createRef();
    this.inputRef = React.createRef();
  }

  componentWillReceiveProps = (nextProps: Props) => {
    this.setState({
      date: nextProps.date,
    });
  };

  openModal = (event: any) => {
    this.setState({
      modalAnchor: this.containerRef,
      focused: true,
    });
  };

  closeModal = () => {
    this.setState({
      modalAnchor: null,
      focused: false,
    });

    this.blurInput();
  };

  blurInput = () => {
    setTimeout(() => {
      if (document.activeElement) {
        (document.activeElement as HTMLElement).blur();
      }
    }, 100);
  };

  onDateChange = (date: any) => {
    this.setState({
      date,
    });

    this.props.onChange(date);
  };

  clearDate = () => {
    this.setState({
      date: null,
      modalAnchor: null,
    });

    this.props.onChange(null);
  };

  onPickerFocusChange = (focused: any) => {
    let newStates = {
      focused: focused.focused,
      modalAnchor: this.state.modalAnchor,
    };

    if (!focused.focused) {
      newStates.modalAnchor = null;
      this.blurInput();
    }

    this.setState(newStates);
  };

  getTextValue = () => {
    let value = '';

    if (this.state.date !== null) {
      value += moment(this.state.date).utc().format('MM/DD/YYYY');
    }

    return value;
  };

  renderPicker = () => {
    return (
      <Popover
        PaperProps={{
          style: {
            boxShadow: 'none',
            border: '1px solid #C6C8CC',
          },
        }}
        open={Boolean(this.state.modalAnchor)}
        onClose={this.closeModal}
        anchorEl={this.state.modalAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className="singleDatePicker">
          <DayPickerSingleDateController
            renderDayContents={(d) => (
              <div className="dayPicker">{d.date()}</div>
            )}
            hideKeyboardShortcutsPanel={true}
            numberOfMonths={1}
            date={moment(this.state.date)}
            onDateChange={this.onDateChange}
            focused={this.state.focused}
            onFocusChange={this.onPickerFocusChange}
            navPrev={<BackIcon />}
            navNext={<ForwardIcon />}
            renderMonthElement={this.renderMonthElement}
          />
        </div>
      </Popover>
    );
  };

  returnYears = () => {
    let years = [];

    let year = moment().year();

    if (this.props.maxYear) {
      year = moment(this.props.maxYear).year();
    }

    for (let i = year - 100; i <= year; i++) {
      years.push(
        <MenuItem
          key={i}
          className={styles.option}
          value={i}
        >
          {i}
        </MenuItem>,
      );
    }

    return years;
  };

  renderMonthElement = ({ month, onMonthSelect, onYearSelect }: any) => (
    <div className={styles.yearMonth}>
      <FormControl className={styles.formControl}>
        <Select
          className={styles.select}
          value={month.month()}
          onChange={(e) => onMonthSelect(month, e.target.value)}
        >
          {moment.months().map((label: any, value: any) => (
            <MenuItem
              key={value}
              className={styles.option}
              value={value}
            >
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={styles.formControl}>
        <Select
          className={styles.select}
          value={month.year()}
          onChange={(e) => onYearSelect(month, e.target.value)}
        >
          {this.returnYears()}
        </Select>
      </FormControl>
    </div>
  );

  render() {
    let className = styles.datePicker;

    if (this.props.className) {
      className = cx(styles.datePicker, this.props.className);
    }

    return (
      <div
        className={className}
        ref={(r: any) => (this.containerRef = r)}
      >
        <TextField
          disabled={this.props.disabled}
          margin="normal"
          error={Boolean(this.props.error)}
          helperText={this.props.error}
          onFocus={this.openModal}
          label={this.props.label}
          variant="outlined"
          fullWidth={true}
          value={this.getTextValue()}
          id={this.props.id ? this.props.id : ''}
          InputProps={{
            readOnly: true,
          }}
        />
        {this.renderPicker()}
      </div>
    );
  }
}

export default DatePicker;

import {
  ACTION_GET_USER_BUDGET_POLICY_RULES,
  ACTION_GET_USER_BUDGET_POLICY_RULES_SUCCESS,
  ACTION_GET_USER_BUDGET_POLICY_RULES_FAILURE,
  ACTION_EMPTY_USER_BUDGET_POLICY_RULES,
  API_CALL,
} from '../../configs/ActionTypes';

import { Dispatch } from 'redux';

/**
 * GET Travel Policy Rules of a user
 */
export function getBudgetPolicyRules(policyId: string) {
  return (dispatch: Dispatch, getState: Function) => {
    dispatch(requestStarted());

    //Dispatch the api call request.
    dispatch({
      type: API_CALL,
      payload: {
        call: `user/budgetPolicyRule/${policyId}`,
        method: 'get',
        data: {},
        success: (response: any) => {
          if (response.type === 'error') {
            dispatch(requestFailed(response));
          } else {
            dispatch(requestSuccess(response));
          }
        },
        fail: (error: any) => {
          dispatch(requestFailed(error));
        },
      },
    });

    return null;
  };
}

export function emptyResults() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ACTION_EMPTY_USER_BUDGET_POLICY_RULES,
      payload: null,
    });
  };
}

export function requestStarted() {
  return {
    type: ACTION_GET_USER_BUDGET_POLICY_RULES,
    payload: null,
  };
}

export function requestSuccess(responseJSON: object) {
  return {
    type: ACTION_GET_USER_BUDGET_POLICY_RULES_SUCCESS,
    payload: responseJSON,
  };
}

export function requestFailed(responseJSON: object) {
  return {
    type: ACTION_GET_USER_BUDGET_POLICY_RULES_FAILURE,
    payload: responseJSON,
  };
}

import React from 'react';

type Props = {
  width: number;
  height: number;
};

const SvgAirplane = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs></defs>
      <g
        id="(WIP)-Refund-Card"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Booking-Macro---Flight"
          transform="translate(-386.000000, -272.000000)"
          fill="#1B2432"
        >
          <g
            id="reservation"
            transform="translate(280.000000, 214.000000)"
          >
            <g
              id="flight-1"
              transform="translate(32.000000, 32.000000)"
            >
              <g
                id="route"
                transform="translate(0.000000, 14.000000)"
              >
                <path
                  d="M90.9279996,19.2671734 C92.4648325,19.2671734 92.4619777,21.6518812 90.9279996,21.6518812 L86.6733992,21.6518812 L80.0788162,28 L78.0309746,28 L82.233237,21.6518812 L77.4381282,21.6518812 L75.4131249,23.8034472 L74,23.8034472 L75.9269885,20.3967217 L74,16.9899963 L75.4131249,16.9899963 L77.5723037,19.2671734 L90.9279996,19.2671734 Z M78.4494879,12 L80.5315871,12 L87.2651037,18.4204404 L82.7354911,18.4204404 L78.4494879,12 Z"
                  id="Airplane"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgAirplane;

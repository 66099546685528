import React, { Component } from 'react';
import cx from 'classnames';
import _ from 'lodash-es';

import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SvgLocation from '../../static/icons/svgLocation';
import SvgTriangle from '../../static/icons/svgTriangle';
import SvgPowerIcon from '../../static/icons/svgPowerIcon';
import SvgTvIcon from '../../static/icons/svgTvIcon';
import SvgWifiIcon from '../../static/icons/svgWifiIcon';
import SvgIconStar from '../../static/icons/svgIconStar';
import SvgIconStarColorProp from '../../static/icons/svgIconStarColorProp';
import { AIRLINE_NAME_MAP } from '../../helpers/airlinemap';

import moment from 'moment';

const styles = require('./styles.pcss');

type Props = {
  open: boolean;
  onClose: any;
  segments: any;
  locations: Array<any>;
  passengers: Array<any>;
  seatAssignments: Array<any>;
};

const returnSeatCode = (seatAssignments: any, segmentKey: any) => {
  let seatCode = _.result(
    _.find(seatAssignments, (p) => p.segmentKey == segmentKey),
    'seatCode',
  );

  return seatCode ? seatCode : '';
};

const renderSegments = (segment: any, props: Props) => {
  let departureTime = '';
  let time =
    moment
      .utc(
        moment.duration(_.get(segment, 'time', ''), 'minute').asMilliseconds(),
      )
      .format('HH') +
    'h ' +
    moment
      .utc(
        moment.duration(_.get(segment, 'time', ''), 'minute').asMilliseconds(),
      )
      .format('mm') +
    'm';
  let arrival = moment(_.get(segment, 'arrival', '')).utcOffset(
    _.get(segment, 'arrival', ''),
  );
  let departure = moment(_.get(segment, 'departure', '')).utcOffset(
    _.get(segment, 'departure', ''),
  );
  let locationFrom = _.find(
    props.locations,
    (location) => location.code === _.get(segment, 'from', ''),
  );
  let locationTo = _.find(
    props.locations,
    (location) => location.code === _.get(segment, 'to', ''),
  );
  let numIncludedBaggage = parseInt(_.get(segment, 'numIncludedBaggage', 0));
  let airline = _.get(segment, 'airline', '');
  let seatpitch = '';

  if (_.get(segment, '_amenities.seat.exists', false)) {
    seatpitch = _.get(segment, '_amenities.seat.description', '');
    seatpitch = seatpitch.replace('"', ' inch');
  }

  return (
    <div className={styles.container}>
      <div className={styles.destinations}>
        <p> {_.get(segment, 'from', '')} </p>
        <p>
          {' '}
          <SvgTriangle
            width={8}
            height={13}
          />{' '}
        </p>
        <p className={styles.line}> </p>
        <p className={styles.svgLoc}>
          {' '}
          <SvgLocation
            width={12}
            height={15}
          />{' '}
        </p>
        <p> {_.get(segment, 'to', '')} </p>
      </div>

      <div className={styles.destinationDetails}>
        <div className={styles.locationFrom}>
          {renderLocation(locationFrom)}
          <p> {departure.isValid() == true && departure.format('LT')} </p>
          <p> {departure.isValid() == true && departure.format('MMM DD')}</p>
        </div>
        <div className={styles.time}>
          <p>{time}</p>
        </div>
        <div className={styles.locationTo}>
          {renderLocation(locationTo)}
          <p> {arrival.isValid() == true && arrival.format('LT')} </p>
          <p
            className={!departure.isSame(arrival, 'day') ? styles.redColor : ''}
          >
            {' '}
            {arrival.isValid() == true && arrival.format('MMM DD')}
          </p>
        </div>
      </div>

      <div className={styles.seatDetails}>
        {renderAirlineIcon(airline)}

        <div>
          <p className={styles.subTitle}>
            {' '}
            {_.get(AIRLINE_NAME_MAP, airline, '')}
          </p>
          <p className={styles.labels}>
            {' '}
            {_.get(segment, 'airline', '')} {_.get(segment, 'flightNumber', '')}
          </p>
        </div>

        {_.get(segment, '_amenities.seat.rating', false) !== false && (
          <div>
            <p className={styles.subTitle}>Main</p>
            {renderStars(_.get(segment, '_amenities.seat.rating', 0))}
          </div>
        )}
        {_.get(segment, '_amenities.seat.exists', false) == true && (
          <div>
            <p className={styles.subTitle}>Main</p>
            <p className={styles.labels}> {seatpitch}</p>
          </div>
        )}
      </div>

      <div className={styles.travelerDetailsListContainer}>
        {props.passengers.map((passenger: any, i: any) => {
          return (
            <div className={styles.travelerDetails}>
              <p className={styles.travelerName}>
                {' '}
                {_.get(passenger, 'firstName', '')}{' '}
                {_.get(passenger, 'lastName', '')}
              </p>
              <p className={styles.airline}>
                {' '}
                {returnSeatCode(
                  _.get(props, 'seatAssignments', []),
                  _.get(segment, 'segmentKey', null),
                )}{' '}
              </p>
            </div>
          );
        })}
      </div>

      <div className={styles.amenities}>
        <div className={styles.amenities}>
          <p>Amenities</p>
          <div className={styles.amenitiesIcon}>
            {_.get(segment, '_amenities.wifi.exists', false) == true && (
              <SvgWifiIcon
                width={16}
                height={12}
              />
            )}

            {_.get(segment, '_amenities.power.exists', false) == true && (
              <SvgPowerIcon
                width={8}
                height={12}
              />
            )}

            {_.get(segment, '_amenities.entertainment.exists', false) ==
              true && (
              <SvgTvIcon
                width={16}
                height={14}
              />
            )}
          </div>
        </div>

        <div className={styles.baggage}>
          <p>Baggage</p>

          {numIncludedBaggage > 0 ? (
            <p> {numIncludedBaggage} free checked baggages</p>
          ) : (
            <p>No free checked baggage</p>
          )}
        </div>
      </div>
    </div>
  );
};

const renderLocation = (location: any) => {
  let stateCode = _.get(location, 'stateCode', null);

  if (stateCode === null) {
    stateCode = _.get(location, 'state', null);
  }
  return (
    <p>
      {' '}
      {_.get(location, 'city', '')}, {stateCode}
    </p>
  );
};

const renderAirlineIcon = (airline: any) => {
  try {
    return (
      <div className={styles.logo}>
        <img src={`/static/images/airlines/${airline}.png`} />
      </div>
    );
  } catch (err) {
    return null;
  }
};

const renderStars = (rating: any) => {
  const starItems = [];

  for (let i = 0; i < 6; i++) {
    if (rating > i) {
      starItems.push(
        <SvgIconStar
          width={13}
          height={13}
        />,
      );
    } else {
      starItems.push(
        <SvgIconStarColorProp
          width={13}
          height={13}
        />,
      );
    }
  }

  return <>{starItems}</>;
};

const FlightDetailsPopup = (props: Props) => {
  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      className={cx(styles.dialogBox)}
      open={props.open}
      onClose={props.onClose}
    >
      <div className={cx(styles.title, styles.flightDetails)}>
        Trip Details
        <IconButton
          color="inherit"
          onClick={props.onClose}
          className={cx(styles.closeIcon)}
          aria-label="Close"
        >
          <CloseIcon />
        </IconButton>
      </div>

      <div className={cx(styles.body)}>
        {props.segments.map((segment: any, i: any) => {
          return renderSegments(segment, props);
        })}
      </div>
    </Dialog>
  );
};

export default FlightDetailsPopup;

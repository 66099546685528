import * as React from 'react';
import _ from 'lodash-es';

import moment from 'moment';

import SimpleTable from 'components/SimpleTable';
import Loader from 'components/Loader/Loader';
import cx from 'classnames';
import { QUERY_LIMIT_25 } from '../../configs/General';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import BookingsTableHead from './BookingsTableHead';
import BookingsTableRow from './BookingsTableRow';

const styles = require('./styles.pcss');

const appWindow = window;

type Props = {
  sort: any;
  travelerId?: string;
  userId?: string;
  isLoading: boolean;
  cars: Array<any>;
  get: any;
  loadedAllCars: any;
  searchParams?: Array<object>;
  noBorder?: boolean;
  order?: any;
};

class Cars extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentWillUnmount() {
    appWindow.removeEventListener('scroll', this.handleScroll);
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.sort && nextProps.sort !== this.props.sort) {
      let params: any = {
        type: 'car',
        limit: QUERY_LIMIT_25,
        skip: 0,
      };

      if (this.props.travelerId) {
        params.travelerId = this.props.travelerId;
      }

      if (this.props.userId) {
        params.userId = this.props.userId;
      }

      if (nextProps.order) {
        params.order = nextProps.order;
      }

      params.sort = nextProps.sort;
      this.props.get(params);
    }
  }

  componentDidMount() {
    appWindow.addEventListener('scroll', this.handleScroll);

    if (this.props.cars.length == 0) {
      let params: any = {
        type: 'car',
        limit: QUERY_LIMIT_25,
        skip: 0,
      };

      if (this.props.travelerId) {
        params.travelerId = this.props.travelerId;
      }

      if (this.props.userId) {
        params.userId = this.props.userId;
      }

      if (this.props.sort) {
        params.sort = this.props.sort;
      }

      if (this.props.order) {
        params.order = this.props.order;
      }

      if (this.props.searchParams) {
        params = {
          ...params,
          ...this.props.searchParams,
        };
      }

      this.props.get(params);
    }
  }

  handleScroll() {
    const body = document.body;
    const html = document.documentElement;

    if (!body || !html) {
      return;
    }

    const windowHeight =
      'innerHeight' in appWindow ? appWindow.innerHeight : html.offsetHeight;

    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight,
    );
    const windowBottom = windowHeight + window.pageYOffset;

    if (
      windowBottom >= docHeight &&
      this.props.isLoading === false &&
      this.props.loadedAllCars === false
    ) {
      let params: any = {
        type: 'car',
        limit: QUERY_LIMIT_25,
        sort: this.props.sort,
        skip: this.props.cars.length,
      };

      if (this.props.userId) {
        params.userId = this.props.userId;
      }

      if (this.props.sort) {
        params.sort = this.props.sort;
      }

      if (this.props.order) {
        params.order = this.props.order;
      }

      if (this.props.searchParams) {
        params = {
          ...params,
          ...this.props.searchParams,
        };
      }

      this.props.get(params);
    }
  }

  renderTableRow = (cells: any, i: number) => {
    return (
      <BookingsTableRow
        key={i}
        id={this.props.cars[i].id}
        cells={cells}
      />
    );
  };

  render() {
    let sorted: any;
    let columns: any = [
      'PNR',
      'Conf #',
      'Traveler',
      'Pick Up',
      'Drop Off',
      'Class',
      'Vendor',
      'Provider',
      'Status',
    ];

    let rows: any = [];

    _.each(this.props.cars, (car: any) => {
      const pickup = `<p>${_.get(car, 'pickupLocation', '')}</p> <p>${moment(
        car.pickupDateTime,
      )
        .utc()
        .format('MMM D, YYYY')}</p>`;
      const dropoff = `<p>${_.get(car, 'dropoffLocation', '')}</p> <p>${moment(
        car.returnDateTime,
      )
        .utc()
        .format('MMM D, YYYY')}</p>`;

      let rowItem = [
        car.pnr,
        car.confirmationNumber,
        `${_.get(car.traveler, 'firstName')} ${_.get(
          car.traveler,
          'lastName',
        )}`,
        <div dangerouslySetInnerHTML={{ __html: pickup }} />,
        <div dangerouslySetInnerHTML={{ __html: dropoff }} />,
        _.get(car, 'car.class', ''),
        _.get(car, 'car.vendorName', ''),
        _.get(car, 'provider', 'Unknown'),
        car.status,
      ];

      rows.push(rowItem);
    });

    if (
      rows.length == 0 &&
      this.props.travelerId &&
      this.props.isLoading == false
    ) {
      return <div className={styles.noData}>Empty car bookings.</div>;
    }

    let classNames = cx(
      styles.paper,
      styles.wideTable,
      this.props.noBorder ? styles.noBorder : styles.withBorder,
    );

    return (
      <div>
        <Paper className={classNames}>
          <Table>
            <BookingsTableHead columns={columns} />
            <TableBody>{rows.map(this.renderTableRow)}</TableBody>
          </Table>
        </Paper>
        <Loader visible={this.props.isLoading} />
      </div>
    );
  }
}

export default Cars;

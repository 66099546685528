export const list = [
  'Marriott Bonvoy',
  'IHG Rewards Club',
  'World of Hyatt',
  'Radisson Rewards',
  'Le Club AccorHotels',
  'Best Western Rewards',
  'Hilton Honors',
  'Choice Privileges Rewards',
  'Wyndham Rewards',
  'M life Rewards',
];

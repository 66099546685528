import * as React from 'react';
import _ from 'lodash-es';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Loader from 'components/Loader/Loader';
const styles = require('./styles.pcss');
import { ReduxState } from '../../reducers/types';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { putSingle } from 'actions/Agencies/PutSingle';
import ContactPopup from './AgencySupportContact/ContactPopup';
import SvgIconQuestion from '../../static/icons/svgIconQuestion';
import cx from 'classnames';

type Props = {
  agency: any;
  disabled: boolean;
  putSingle: Function;
  isLoading: boolean;
};

type State = {
  openPopup: boolean;
};

class AgencySupportContact extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      openPopup: false,
    };
  }

  onSubmit = (data: any) => {
    this.props.putSingle(_.get(this.props, 'agency.id', null), data);
  };

  openPopup = () => {
    this.setState({
      openPopup: true,
    });
  };

  closePopup = () => {
    this.setState({
      openPopup: false,
    });
  };

  renderLoading = () => {
    if (_.get(this.props, 'isLoading', false) === true) {
      return (
        <div className={styles.infoLoader}>
          <Loader visible={true} />
        </div>
      );
    }

    return null;
  };

  render() {
    return (
      <Paper className={styles.paper}>
        <div className={styles.companyInfoHeader}>
          <div className={styles.companyInfoHeaderIconContainer}>
            <SvgIconQuestion
              width={10}
              height={16}
            />
          </div>
          <div className={styles.companyInfoHeaderText}>
            <h2>Support Contact</h2>
            <div className={styles.description}>
              Contact details for users to receive support.
            </div>
          </div>
        </div>
        <div
          className={cx(styles.companyInfoContainer, styles.contactContainer)}
        >
          <div className={styles.contactInfoData}>
            <div className={styles.companyInfoColumn}>
              <div className={styles.descr}>
                Support Email:{' '}
                <span>
                  {_.get(
                    this.props,
                    'agency.uiSettings.supportSettings.generalSupportEmail',
                    '',
                  )}
                </span>
              </div>
              <div className={styles.descr}>
                Travel Support Email:{' '}
                <span>
                  {_.get(
                    this.props,
                    'agency.uiSettings.supportSettings.travelSupportEmail',
                    '',
                  )}
                </span>
              </div>
            </div>
            <div className={styles.companyInfoColumn}>
              <div className={styles.descr}>
                Phone Number:{' '}
                <span>
                  {_.get(
                    this.props,
                    'agency.uiSettings.supportSettings.mainSupportPhoneNumber',
                    '',
                  )}
                </span>
              </div>
              <div className={styles.descr}>
                Alternate Phone Number:{' '}
                <span>
                  {_.get(
                    this.props,
                    'agency.uiSettings.supportSettings.alternativeSupportPhoneNumber',
                    '',
                  )}
                </span>
              </div>
            </div>
          </div>

          <div className={styles.contactButtonWrapper}>
            {!this.props.disabled && (
              <Button
                size="small"
                className={cx(styles.button, styles.buttonEdit)}
                variant="outlined"
                color="primary"
                onClick={this.openPopup}
              >
                Edit
              </Button>
            )}
          </div>
        </div>

        <ContactPopup
          open={this.state.openPopup}
          onClose={this.closePopup}
          onSubmit={this.onSubmit}
          agency={this.props.agency}
        />

        {this.renderLoading()}
      </Paper>
    );
  }
}

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {
    isLoading: state.agency.isLoading,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      putSingle,
    },
    dispatch,
  );
}

// Connect it to Redux
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AgencySupportContact);

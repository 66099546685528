import React from 'react';

type Props = {
  width: number;
  height: number;
};

const SvgAlertIcon = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Icon_Alert</title>
      <g
        id="Master"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Organization-Macro---Profile"
          transform="translate(-322.000000, -652.000000)"
          fill="#FC3B60"
        >
          <g
            id="payment-methods"
            transform="translate(280.000000, 515.000000)"
          >
            <path
              d="M51.0666667,146.066667 C51.0666667,146.655733 50.5890667,147.133333 50,147.133333 C49.4109333,147.133333 48.9333333,146.655733 48.9333333,146.066667 L48.9333333,140.733333 C48.9333333,140.144267 49.4109333,139.666667 50,139.666667 C50.5890667,139.666667 51.0666667,140.144267 51.0666667,140.733333 L51.0666667,146.066667 Z M50,150.333333 C49.4109333,150.333333 48.9333333,149.855733 48.9333333,149.266667 C48.9333333,148.6776 49.4109333,148.2 50,148.2 C50.5890667,148.2 51.0666667,148.6776 51.0666667,149.266667 C51.0666667,149.855733 50.5890667,150.333333 50,150.333333 L50,150.333333 Z M50,137 C45.5818667,137 42,140.581867 42,145 C42,149.418133 45.5818667,153 50,153 C54.4181333,153 58,149.418133 58,145 C58,140.581867 54.4181333,137 50,137 L50,137 Z"
              id="Icon_Alert"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

SvgAlertIcon.defaultProps = {
  width: 16,
  height: 16,
};

export default SvgAlertIcon;

import React from 'react';
import cx from 'classnames';
import TextField from '@material-ui/core/TextField';
import Modal from '@material-ui/core/Modal';
import Popover from '@material-ui/core/Popover';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import _ from 'lodash-es';
import Button from '@material-ui/core/Button';
import { Checkbox } from '@material-ui/core';
import SvgIconSearchSmall from '../../../static/icons/svgIconSearchSmall';
import { KeyboardArrowDown } from '@material-ui/icons';

const styles = require('./styles.pcss');

type Props = {
  onChange: Function;
  label: string;
  className?: any;
  error?: string;
  value: any;
  id: any;
  disabled?: boolean;
  endText?: any;
  searchable?: boolean;
  onClickClear?: any;
  isMultiple?: boolean;
  selections: Array<any>;
};

type State = {
  modalAnchor: any;
  value: any;
  focused: boolean;
  searchValue: any;
  selections: Array<any>;
};

class SelectWithCheckBox extends React.Component<Props, State> {
  containerRef: any;
  inputRef: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      modalAnchor: null,
      value: props.value,
      focused: false,
      searchValue: '',
      selections: props.selections,
    };

    this.containerRef = React.createRef();
    this.inputRef = React.createRef();
  }

  componentWillReceiveProps = (nextProps: Props) => {
    this.setState({
      value: nextProps.value,
      selections: nextProps.selections,
    });
  };

  openModal = (event: any) => {
    this.setState({
      modalAnchor: this.containerRef,
      focused: true,
    });
  };

  closeModal = () => {
    this.setState({
      modalAnchor: null,
      focused: false,
      value: this.props.value,
    });
    this.blurInput();
  };

  blurInput = () => {
    setTimeout(() => {
      if (document.activeElement) {
        (document.activeElement as HTMLElement).blur();
      }
    }, 100);
  };

  getTextValue = () => {
    let values = this.state.value;
    let list = _.filter(this.props.selections, function (f) {
      return values.includes(f.key);
    });

    return list.map((l) => _.get(l, 'value', '')).join(', ');
  };

  save = () => {
    this.props.onChange(this.state.value);
    this.setState({
      value: [],
    });
    this.closeModal();
  };

  onClickClear = () => {
    this.setState({
      value: [],
    });
    this.props.onClickClear();
  };

  handleCheck = (e: any) => {
    let value = _.cloneDeep(this.state.value);

    if (!_.includes(this.state.value, e.currentTarget.dataset.id)) {
      this.props.isMultiple
        ? value.push(e.currentTarget.dataset.id)
        : (value[0] = e.currentTarget.dataset.id);
    } else {
      _.pull(value, e.currentTarget.dataset.id);
    }

    this.setState({
      value,
    });
  };

  onChangeSearch = (event: any) => {
    const e = event.target.value.toLowerCase();
    const newItems: any = _.filter(this.props.selections, function (el: any) {
      return _.get(el, 'value', '').toLowerCase().includes(e);
    });

    this.setState({
      searchValue: event.target.value,
      selections: newItems,
    });
  };

  renderContainer = () => {
    let classNameD = this.props.isMultiple
      ? cx(styles.dropdown, styles.dropdownMultiple)
      : styles.dropdown;
    let searchBoxClass =
      this.state.searchValue.length > 0
        ? cx(styles.searchBox, styles.hidden)
        : styles.searchBox;
    return (
      <Popover
        PaperProps={{
          style: {
            boxShadow: 'none',
            border: '1px solid #C6C8CC',
          },
        }}
        id={this.props.id + 'Container'}
        open={Boolean(this.state.modalAnchor)}
        onClose={this.closeModal}
        anchorEl={this.state.modalAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className={classNameD}>
          {this.props.searchable && (
            <div className={styles.searchContainer}>
              <div className={searchBoxClass}>
                <span>
                  <SvgIconSearchSmall
                    width={13}
                    height={13}
                  />
                </span>
                <input
                  className={styles.searchBar}
                  placeholder="Search"
                  onChange={this.onChangeSearch}
                  type="text"
                ></input>
              </div>
            </div>
          )}
          <ul>
            {this.state.selections.map((selection: any) => {
              let className = styles.menuItemCheckbox;
              if (_.includes(this.state.value, selection.key)) {
                className = cx(
                  styles.menuItemCheckbox,
                  styles.menuItemSelected,
                );
              }

              return (
                <li
                  key={selection.key}
                  data-id={selection.key}
                  value={selection.value}
                  className={className}
                  onClick={this.handleCheck.bind(this)}
                >
                  <Checkbox
                    checked={this.state.value.indexOf(selection.key) > -1}
                    color={
                      this.state.value.indexOf(selection.key) > -1
                        ? 'primary'
                        : 'default'
                    }
                  />
                  <div className={styles.menuItemText}>
                    <p className={styles.text}>{selection.value}</p>
                    {Boolean(selection.description) && (
                      <p className={styles.description}>
                        {selection.description}
                      </p>
                    )}
                    {Boolean(selection.description2) && (
                      <p className={styles.description2}>
                        {selection.description2}
                      </p>
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
          <div className={styles.buttons}>
            {this.props.searchable ? (
              <Button
                size="small"
                className={styles.buttonClear}
                color="primary"
                onClick={this.props.onClickClear}
                id={this.props.id + 'Clear'}
              >
                Clear
              </Button>
            ) : (
              <div></div>
            )}

            <Button
              size="small"
              className={styles.button}
              variant="contained"
              color="primary"
              onClick={this.save}
              id={this.props.id + 'Save'}
            >
              Save
            </Button>
          </div>
        </div>
      </Popover>
    );
  };

  render() {
    let className = '';
    if (this.props.className) {
      className = cx(styles.dropdownCont, this.props.className);
    }

    className = Boolean(this.state.modalAnchor)
      ? cx(className, styles.selected)
      : className;

    return (
      <div
        className={className}
        ref={(r: any) => (this.containerRef = r)}
      >
        <TextField
          disabled={this.props.disabled}
          margin="normal"
          error={Boolean(this.props.error)}
          helperText={this.props.error}
          onFocus={this.openModal}
          label={this.props.label}
          variant="outlined"
          fullWidth={true}
          id={this.props.id}
          value={this.getTextValue()}
          InputProps={{
            readOnly: true,
            endAdornment: Boolean(this.props.endText) && (
              <InputAdornment
                className={styles.endText}
                position="end"
                disablePointerEvents={true}
              >
                {this.props.endText}{' '}
                <KeyboardArrowDown className={styles.icon} />
              </InputAdornment>
            ),
          }}
        />
        {this.renderContainer()}
      </div>
    );
  }
}

export default SelectWithCheckBox;

import * as React from 'react';
import { connect } from 'react-redux';
import { ReduxState } from '../../reducers/types';
import { putAgencySetting } from 'actions/Companies/Settings/PutAgencySetting';
import { putSpecialRateCodes } from 'actions/Companies/Settings/PutSpecialRateCodes';
import { bindActionCreators, Dispatch } from 'redux';
import AgencyInventorySuppliers from 'components/AgencyDetails/AgencyInventorySuppliers';
import Paper from '@material-ui/core/Paper';
import cx from 'classnames';
const styles = require('./styles.pcss');

type Props = {
  isLoading: boolean;
  agencyDetails?: any;
  specialRateCodes?: any;
  putAgencySetting: Function;
  putSpecialRateCodes: Function;
  organizationId: any;
  agency?: any;
};

class OrganizationAgencySetting extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);
  }

  putAgencySetting = (organizationId: any, setting: any) => {
    this.props.putAgencySetting(organizationId, setting);
  };
  putSpecialRateCodes = (organizationId: any, setting: any) => {
    this.props.putSpecialRateCodes(organizationId, setting);
  };

  render() {
    return (
      <Paper
        className={
          this.props.isLoading ? cx(styles.paper, styles.loading) : styles.paper
        }
      >
        <div className={styles.agencySetting}>
          <AgencyInventorySuppliers
            isLoading={this.props.isLoading}
            agencyDetails={this.props.agencyDetails}
            specialRateCodes={this.props.specialRateCodes}
            putAgency={this.putAgencySetting}
            putSpecialRateCodes={this.putSpecialRateCodes}
            organizationId={this.props.organizationId}
            agency={this.props.agency}
          />
        </div>
      </Paper>
    );
  }
}

function mapStateToProps(state: ReduxState) {
  return {
    isLoading: state.agencySetting.isLoading,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      putAgencySetting,
      putSpecialRateCodes,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrganizationAgencySetting);

const AIRLINE_NAME_MAP = {
  SU: 'Aeroflot',
  AK: 'AirAsia',
  CX: 'Cathay Pacific',
  KE: 'Korean Air',
  MH: 'Malaysia Airlines',
  SQ: 'Singapore Airlines',
  TG: 'THAI airways',
  CZ: 'China Southern Airlines',
  CA: 'Air China',
  NH: 'All Nippon Airways',
  JT: 'Lion Air',
  EK: 'Emirates Airlines',
  JL: 'Japan Airlines',
  HU: 'Hainan Airlines',
  GA: 'Garuda Indonesia',
  QR: 'Qatar Airways',
  '6E': 'IndiGo',
  EY: 'Etihad Airways',
  MF: 'Xiamen Airlines',
  ZH: 'Shenzhen Airlines',
  '5J': 'Cebu Pacific',
  OZ: 'Asiana Airlines',
  AI: 'Air India',
  '3U': 'Sichuan Airlines',
  VN: 'Vietnam Airlines',
  SV: 'Saudia',
  CI: 'China Airlines',
  SC: 'Shandong Airlines',
  PR: 'Philippine Airlines',
  '9C': 'Spring Airlines',
  BR: 'EVA Airways',
  GF: 'Gulf Air',
  TR: 'Tigerair',
  G9: 'Air Arabia',
  W5: 'Mahan Air',
  PK: 'Pakistan International Airways',
  WY: 'Oman Air',
  PG: 'Bangkok Airways',
  IR: 'Iran Air',
  UL: 'SriLankan Airlines',
  LY: 'EL AL',
  D7: 'AirAsia X',
  KC: 'Air Astana',
  EP: 'Iran Aseman Airlines',
  HO: 'Juneyao Airlines',
  RJ: 'Royal Jordanian Airlines',
  HY: 'Uzbekistan Airways',
  KU: 'Kuwait Airways',
  ME: 'Middle East Airlines',
  I3: 'Ata Airlines',
  T5: 'Turkmenistan Airlines',
  NX: 'Air Macau',
  Y9: 'Kish Air',
  BG: 'Biman Bangladesh Airlines',
  OD: 'Malindo',
  DD: 'NokAir',
  WE: 'THAI Smile',
  FY: 'Firefly',
  A3: 'Aegean Airlines',
  EI: 'Aer Lingus',
  AM: 'Aeromexico',
  AB: 'Air Berlin',
  AC: 'Air Canada',
  AF: 'Air France',
  MK: 'Air Mauritius',
  NZ: 'Air New Zealand',
  YW: 'Air Nostrum',
  HM: 'Air Seychelles',
  TS: 'Air Transat',
  AS: 'Alaska Airlines',
  AZ: 'Alitalia',
  OS: 'Austrian Airlines AG',
  AV: 'Avianca',
  J2: 'Azerbaijan Airlines',
  AD: 'Azul Airlines',
  BA: 'British Airways',
  SN: 'Brussels Airlines',
  MU: 'China Eastern',
  CM: 'Copa Airlines',
  DL: 'Delta Air Lines',
  KA: 'Cathay Dragon',
  ET: 'Ethiopian Airlines',
  EW: 'Eurowings',
  FJ: 'Fiji Airways',
  AY: 'Finnair',
  HA: 'Hawaiian Airlines',
  HX: 'Hong Kong Airlines',
  IB: 'Iberia',
  FI: 'Icelandair',
  B6: 'jetBlue Airways',
  JQ: 'Jetstar Airways',
  KQ: 'Kenya Airways',
  KL: 'KLM',
  MN: 'Kulula',
  LA: 'LAN Airlines',
  LO: 'LOT Polish',
  LH: 'Lufthansa',
  MM: 'Peach',
  PD: 'Porter Airlines',
  QF: 'Qantas',
  BI: 'Royal Brunei Airlines',
  SK: 'SAS Scandinavian',
  TZ: 'Scoot',
  MI: 'SilkAir',
  SA: 'South African Airways',
  WN: 'Southwest Airlines',
  SG: 'SpiceJet',
  LX: 'SWISS',
  DT: 'TAAG Angola',
  TP: 'TAP Portugal Transportes Aéreos Portugueses S.A.',
  BY: 'Thomson Airways',
  GS: 'Tianjin Airlines',
  TK: 'Turkish Airlines',
  UA: 'United Airlines',
  VX: 'Virgin America',
  VS: 'Virgin Atlantic',
  VA: 'Virgin Australia',
  WS: 'WestJet',
  G8: 'GoAir',
  '2T': 'Trujet',
  MS: 'Egyptair',
  FR: 'Ryanair',
  OA: 'Olympic Air',
  VY: 'Vueling',
  KM: 'Air Malta',
  FZ: 'Flydubai',
  DE: 'Condor',
  X3: 'TUIfly',
  BM: 'BMI regional',
  Y4: 'Volaris',
  JJ: 'LATAM Airlines Brasil',
  JU: 'Air Serbia',
  UO: 'HK Express',
  OK: 'CSA Czech Airlines',
  FC: 'VivaColombia',
  ZL: 'Regional Express',
  JP: 'Adria',
  '0B': 'BlueAir',
  VB: 'Viva Aerobus',
  GR: 'Aurigny',
  EN: 'AirDolomiti',
  WP: 'Island Air',
  S4: 'SATA Internacional - Azores Airlines',
  FB: 'Bulgaria Air',
  NK: 'Spirit',
  ID: 'Batik Air',
  LB: 'Air Costa',
  AA: 'American Airlines',
  SS: 'Corsair',
  TB: 'Jetairfly',
  KK: 'Atlasglobal',
  VW: 'Aeromar',
  RO: 'Tarom',
  AT: 'Royal Air Maroc',
  XQ: 'SunExpress Güneş Ekspress Havacılık A.Ş',
  WG: 'Sunwing',
  PC: 'Pegasus Airlines',
  F9: 'Frontier Airlines',
  PS: 'UIA',
  FD: 'Thai AirAsia',
  G3: 'GOL Linhas A_reas Inteligentes',
  UX: 'Air Europa',
  FM: 'Shanghai Airlines',
  S7: 'S7',
  '4U': 'Germanwings',
  '3K': 'Jetstar Asia',
  YV: 'Mesa Airlines',
  XY: 'Flynas',
  SY: 'Sun Country Airlines',
  '3M': 'Silver Airways',
  BT: 'airBaltic',
  CL: 'Lufthansa CityLine',
  SL: 'Thai Lion Air',
  B2: 'Belavia',
  W6: 'Wizz Air',
  H1: 'Hahn Air',
  '4O': 'Interjet',
  UT: 'Utair',
  '8Q': 'Onur Air',
  WX: 'Cityjet',
  HG: 'Niki',
  GK: 'Jetstar Japan',
  OU: 'Croatia Airlines',
  AR: 'Aerolineas Argentinas',
  BW: 'Caribbean Airlines',
  U6: 'Ural Airlines',
  C5: 'CommutAir',
  CT: 'Alitalia CityLiner',
  A9: 'Georgian Airways',
  XJ: 'Thai AirAsia X',
  TU: 'Tunisair',
  IX: 'Air India Express',
  QS: 'SmartWings',
  QV: 'Lao Airlines',
  FL: 'Air Leap',
  '2L': 'Helvetic Airways',
  VJ: 'Vietjet',
  LG: 'Luxair',
  G4: 'Allegiant Air',
  UK: 'Vistara',
  NQ: 'Air Japan',
  BL: 'Jetstar Pacific Airlines',
  J9: 'Jazeera Airways',
  EQ: 'TAME',
  CJ: 'BA Cityflyer',
  BB: 'Seaborne Airlines',
  I2: 'Iberia Express',
  LJ: 'Jin Air',
  FV: 'Rossiya',
  QZ: 'Indonesia AirAsia',
  '9K': 'Cape Air',
  ZB: 'Monarch Airlines',
  AH: 'Air Algerie',
  '9B': 'AccesRail',
  '8M': 'Myanmar Airways International',
  '2P': 'PAL Express',
  BX: 'Air Busan',
  JW: 'Vanilla Air',
  W3: 'Arik Air',
  XL: 'LATAM Airlines Ecuador',
  MJ: 'Mihin Lanka',
  '9U': 'Air Moldova',
  IT: 'Tigerair Taiwan',
  F7: 'Etihad Regional',
  UP: 'Bahamasair',
  TT: 'Tigerair Australia',
  ZE: 'Eastar Jet',
  LI: 'Liat',
  WB: 'RwandAir',
  GE: 'Transasia Airways',
  K6: 'Cambodia Angkor Air',
  WK: 'Edelweiss Air',
  AE: 'Mandarin Airlines',
  '7G': 'StarFlyer',
  KP: 'ASKY Airlines',
  TN: 'Air Tahiti Nui',
  XW: 'NokScoot',
  MW: 'Mokulele Airlines',
  IZ: 'Arkia',
  WW: 'WOW Air',
  PZ: 'LATAM Airlines Paraguay',
  EH: 'ANA Wings',
  H2: 'Sky Airline',
  PW: 'Precision Air',
  '7C': 'Jeju Air',
  RA: 'Nepal Airlines',
  Z2: 'AirAsia Zest',
  LR: 'Avianca Costa Rica',
  '5W': '5W-Rail&Shuttle',
  WF: 'Wideroe',
  WI: 'White Airways',
  MT: 'Thomas Cook Airlines',
  OO: 'SkyWest Airlines',
  '2A': 'Deutsche Bahn',
  BV: 'Blue Panorama Airlines',
  KX: 'Cayman Airways',
  '4Q': 'Safi Airways',
  YM: 'Montenegro Airlines',
  GH: 'Globus Airlines',
  NU: 'Japan Transocean Air',
  YX: 'Republic Airlines',
  LS: 'Jet2.com',
  OX: 'Orient Thai Airlines',
  QG: 'Citilink Airlines',
  '2C': 'SNCF',
  NT: 'Binter Canarias',
  CU: 'Cubana',
  '4M': 'LATAM Airlines Argentina',
  V7: 'Volotea',
  EC: 'OpenSkies',
  B7: 'Uni Air',
  '2Z': 'Passaredo Linhas A_reas',
  I5: 'AirAsia India',
  AU: 'Austral Lineas Aereas',
  '9I': 'Air India Regional',
  XT: 'Indonesia AirAsia X',
  R3: 'Yakutia Airlines',
  SW: 'Air Namibia',
  OM: 'MIAT Mongolian Airlines',
  '5H': 'Fly540',
  TM: 'LAM Mozambique Airlines',
  NN: 'VIM Airlines',
  JY: 'InterCaribbean Airways',
  '6H': 'Israir Airlines',
  P5: 'Copa Airlines Colombia',
  D9: 'Donavia',
  OB: 'BOA Boliviana de Aviaci_n',
  PY: 'Surinam Airways',
  KN: 'China United Airlines',
  '7R': 'RusLine',
  '4D': 'Air Sinai',
  Z6: 'Dniproavia',
  S3: 'SBA Airlines',
  SJ: 'Sriwijaya Air',
  QA: 'Cimber',
  EL: 'Ellinair',
  RG: 'Rotana Jet',
  SE: 'XL Airways France',
  JE: 'Mango',
  ZI: 'Aigle Azur',
  BC: 'Skymark Airlines',
  S5: 'Shuttle America',
  UU: 'Air Austral',
  HD: 'Air Do',
  ZK: 'Great Lakes Airlines',
  MD: 'Air Madagascar',
  R7: 'Aserca Airlines',
  '2J': 'Air Burkina',
  '4B': 'Boutique Air',
  '5O': 'ASL Airlines France',
  Z8: 'Amaszonas',
  '4Z': 'Airlink',
  '5N': 'Nordavia',
  PX: 'Air Niugini',
  P9: 'Peruvian Airlines',
  WM: 'Winair',
  IY: 'Yemenia',
  HF: "Air C_te d'Ivoire",
  TW: "T'way Airlines",
  XK: 'Air Corsica',
  CP: 'Compass Airlines',
  RQ: 'Kam Air',
  N7: 'Norra',
  '9V': 'Avior Airlines',
  DP: 'Pobeda',
  G7: 'GoJet Airlines',
  AP: 'AlbaStar',
  '8U': 'Afriqiyah Airways',
  DV: 'SCAT Airlines',
  BP: 'Air Botswana',
  '3S': 'Air Antilles Express',
  VT: 'Air Tahiti',
  YC: 'Yamal',
  B5: 'Fly-SAX',
  K5: 'SeaPort Airlines',
  ZV: 'V Air',
  '9E': 'Delta Connection',
  '3O': 'Air Arabia Maroc',
  SM: 'Air Cairo',
  '9F': 'Eurostar',
  XZ: 'South African Express',
  V0: 'Conviasa',
  XC: 'Corendon Airlines',
  '6J': 'Solaseed Air',
  HZ: 'Aurora',
  BJ: 'Nouvelair',
  SX: 'SkyWork Airlines',
  TX: 'Air Caraibes',
  W2: 'FlexFlight',
  FW: 'Ibex Airlines',
  UG: 'Tunisair Express',
  QX: 'Horizon Air',
  UM: 'Air Zimbabwe',
  AX: 'Trans States Airlines',
  FA: 'Safair',
  FN: 'fastjet',
  VR: 'TACV Cabo Verde Airlines',
  YE: 'YANAIR',
  B0: 'La Compagnie',
  T3: 'Eastern Airways',
  '2I': 'Star Peru',
  P0: 'Proflight Zambia',
  IW: 'Wings Air',
  YQ: 'TAR Aerolineas',
  TL: 'Airnorth',
  KS: 'Penair',
  NP: 'Nile Air',
  OR: 'TUIfly Netherlands',
  Y7: 'NordStar',
  LM: 'Loganair',
  DG: 'Cebgo',
  SB: 'Aircalin',
  LN: 'Libyan Airlines',
  O8: 'Siam Air',
  '7H': 'Ravn Alaska',
  L6: 'Mauritania Airlines International',
  E5: 'Air Arabia Egypt',
  JV: 'Bearskin Airlines',
  W9: 'Air Bagan',
  YO: 'Heli Air Monaco',
  '8L': 'Lucky Air',
  OJ: 'Fly Jamaica Airways',
  Z4: 'Zagrosjet',
  '2N': 'Nextjet',
  EB: 'Wamos Air',
  SP: 'Sociedade Açoreana de Transportes Aéreos',
  W4: 'LCPeru',
  HQ: 'Thomas Cook Airlines Belgium',
  LC: 'ECAir',
  JF: 'Jet Asia',
  IN: 'NAM Air',
  '5Z': 'CemAir',
  '9R': 'SATENA',
  SZ: 'Somon Air',
  T7: 'Twin Jet',
  '9M': 'Central Mountain Air',
  WZ: 'Red Wings',
  NF: 'Air Vanuatu',
  LT: 'LongJiang Airlines',
  U8: 'Tus Airways',
  RL: 'Royal Flight airlines',
  '2K': 'Avianca Ecuador',
  UH: 'AtlasGlobal Ukraine',
  '8R': 'SOL Lineas Aereas',
  ZN: 'NAYSA',
  NO: 'Neos',
  C3: 'Trade Air',
  '2B': 'Albawings',
  TJ: 'Tradewind Aviation',
  GQ: 'Sky Express',
  QU: 'Azur Air Ukraine',
  VL: 'Med-View Airline',
  VE: 'EasyFly',
  EZ: 'Sun-Air',
  CG: 'PNG Air',
  XM: 'Zimex',
  D6: 'Interair South Africa',
  '3E': 'Air Choice One',
  CN: 'Grand China Air',
  YK: 'Avia Traffic Company',
  DX: 'Danish Air Transport',
  '2D': 'Dynamic International Airways',
  '9H': 'Air Changan',
  '7M': 'MAYAir',
  V3: 'Carpatair',
  J8: 'Berjaya Air',
  TD: 'Atlantis European Airways',
  ZM: 'Air Manas',
  AW: 'Africa World Airlines',
  UB: 'Myanmar National Airlines',
  VQ: 'Novoair',
  B8: 'Eritrean Airlines',
  WA: 'KLM Cityhopper',
  RC: 'Atlantic Airways',
  JD: 'Capital Airlines',
  GL: 'Air Greenland',
  '9N': 'Tropic Air',
  KY: 'Kunming Airlines',
  IE: 'flysolomons',
  '7F': 'First Air',
  PF: 'Primera Air',
  JC: 'Japan Air Commuter',
  SD: 'Sudan Airways',
  '4J': 'FlyDamas',
  '5T': 'Canadian North',
  DJ: 'AirAsia Japan',
  X9: 'Avion Express',
  N4: 'Nordwind Airlines',
  B4: 'ZanAir',
  EF: 'Fly CamInter',
  NY: 'Air Iceland',
  A2: 'Astra Airlines',
  UJ: 'ALMasria Universal Airlines',
  '5M': 'FlyMontserrat',
  G5: 'China Express Airlines',
  '6R': 'ALROSA',
  '3P': 'Tiara Air Aruba',
  S9: 'Starbow',
  M9: 'Motor Sich Airlines',
  MQ: 'Envoy Air',
  CE: 'Chalair Aviation',
  C2: 'CEIBA Intercontinental',
  AG: 'Aruba Airlines',
  P1: 'Public Charters',
  QC: 'Camair-Co',
  YJ: 'Asian Wings Airways',
  '7W': 'Windrose Airlines',
  PN: 'China West Air',
  '9P': 'Air Arabia Jordan',
  EU: 'Chengdu Airlines',
  '8P': 'Pacific Coastal Airlines',
  UI: 'Auric Air',
  K8: 'Kan Air',
  E9: 'Evelop!',
  '7J': 'Tajik Air',
  MR: 'Hunnu Air',
  CD: 'Corendon Dutch Airlines',
  '5I': 'ALSA',
  KD: 'Kalstar Aviation',
  OY: 'Andes Lineas Aereas',
  IK: 'Pegas Fly',
  TI: 'Tailwind Airlines',
  CC: 'CM Airlines',
  R6: 'DOT LT',
  IO: 'IrAero',
  '5K': 'Hi Fly',
  M4: 'Mistral Air',
  KV: 'Sky Regional Airlines',
  RS: 'Air Seoul',
  QH: 'Air Kyrgyzstan',
  K7: 'Air KBZ',
  QL: 'LASER Airlines',
  RT: 'Rainbow Airlines',
  '6Y': 'SmartLynx Airlines',
  '1X': 'Branson AirExpress',
  Z9: 'Bek Air',
  '7Q': 'Elite Airways',
  TV: 'Tibet Airlines',
  DZ: 'Donghai Airlines',
  ZG: 'Groznyy Avia',
  IQ: 'Qazaq Air',
  BU: 'flyCAA',
  VZ: 'Thai Vietjet Air',
  '9X': 'Southern Airways Express',
  BK: 'Okay Airways',
  Y5: 'Golden Myanmar Airlines',
  ZT: 'Titan Airways',
  KO: 'Komiaviatrans',
  '3H': 'Air Inuit',
  PB: 'PAL Airlines',
  RB: 'SyrianAir',
  ED: 'AirExplore',
  MZ: 'Amakusa Airlines',
  GJ: 'Loong Air',
  FH: 'Freebird Airlines',
  '4A': 'Aerodynamics',
  S8: 'Sounds Air',
  KB: 'Drukair',
  NS: 'Hebei Airlines',
  '7N': 'PAWA Dominicana',
  EG: 'Enerjet',
  Q5: '40-Mile Air',
  RV: 'Air Canada Rouge',
  AQ: '9 Air',
  '9G': '9G Rail',
  Y6: 'AB Aviation',
  GB: 'ABX Air',
  '7T': 'Trenitalia',
  M0: 'Aero Mongolia',
  WV: 'Aero VIP',
  NG: 'aero',
  N3: 'Aerolineas MAS',
  A8: 'AeroLink',
  '5D': 'Aeromexico Connect',
  VH: 'Aeropostal',
  V5: 'DAP',
  '4C': 'LATAM Airlines Colombia',
  L8: 'Afric Aviation',
  XU: 'African Express Airways',
  J7: 'Afrijet Business Service',
  '6I': 'Air Alsie',
  TY: 'Air Caledonie',
  '2S': 'Air Carnival',
  Y2: 'Air Century',
  F4: 'Air Flamenco',
  YN: 'Air Creebec',
  DQ: 'Air Direct Connect',
  X4: 'Air Excursions',
  EO: 'Air Go Egypt',
  GT: 'Air Guilin',
  BN: 'Budgetlines',
  JS: 'Air Koryo',
  AL: 'Air Leisure',
  '4N': 'Air North',
  '7P': 'Air Panama',
  OP: 'Air Pegasus',
  GZ: 'Air Rarotonga',
  ZJ: 'Air Routing International',
  PJ: 'Air Saint Pierre',
  TC: 'Air Tanzania',
  '6C': 'Air Timor',
  '8T': 'Air Tindi',
  '8C': 'Air Transport International Inc.',
  '3N': 'Airline Urga',
  VK: 'AirVallee',
  ZW: 'Air Wisconsin',
  PQ: 'AirAsia Philippines',
  RM: 'Armenia Aircompany',
  P2: 'AirKenya',
  '6L': 'AKLAK AIR',
  NR: 'Al-Naser Airlines',
  YZ: 'Alas Uruguay',
  J5: 'Alaska Seaplanes',
  QQ: 'Alliance Airlines',
  '4W': "Warbelow's Air",
  '5A': 'Alpine Aviation',
  K4: 'Kalitta Air',
  PE: "People's Viennaline",
  HP: 'Amapola Flyg',
  ZP: 'Amaszonas Paraguay',
  '2V': 'Amtrak',
  Q3: 'Anguilla Air Services',
  SO: 'APEX Airlines',
  GP: 'APG Airlines',
  FG: 'Ariana Afghan Airlines',
  HB: 'Asia Atlantic Airlines',
  DM: 'Asian Air',
  '8V': 'Wright Air Service',
  L5: 'Atlas Atlantique Airlines',
  '5Y': 'Atlas Air',
  '6V': 'Auto Res Avanza Group',
  T9: 'Aviator',
  '3R': 'Avior Regional',
  AJ: 'Aztec Airways',
  ZF: 'AZUR Air',
  J4: 'Badr Airlines',
  '5B': 'Bassaka Air',
  '8E': 'Bering Air Inc.',
  '8H': 'BH Air',
  BZ: 'Blue Bird Airways',
  YB: 'BoraJet',
  L9: 'Bristow',
  U4: 'Buddha Air',
  UZ: 'Buraq Air',
  '9Q': 'Caicos Express Airways',
  OE: 'Cairo Aviation',
  MO: 'Calm Air',
  BD: 'Cambodia Bayon Airlines',
  PM: 'Canaryfly',
  C8: 'Cronos Airlines',
  '6Q': 'Cham Wings Airlines',
  CQ: 'Coastal Aviation',
  H8: 'Chilejet',
  OQ: 'Chongqing Airlines',
  V6: 'VI Airlink',
  CO: 'Cobalt',
  GY: 'Colorful Guizhou Airlines',
  LF: 'Corporate Flight Management',
  D3: 'Daallo Airlines',
  JX: 'DAC Aviation',
  '9J': 'Dana Air',
  D4: 'DART Ukrainian Airlines',
  G6: 'Denim Air',
  '1I': 'NetJets Aviation',
  DW: 'Dominican Wings',
  DO: 'Dornier Aviation Nigeria AIEP',
  '9A': 'Eaglexpress',
  '5E': 'East Coast Flight Services',
  EA: 'Eastern',
  EM: 'Empire Airlines',
  E4: 'Estelar',
  E7: 'Equaflight',
  YU: 'EuroAtlantic Airways',
  WL: 'European Coastal Airlines',
  ZD: 'EWA Air',
  X7: 'Exec Air Naples',
  MB: 'Execaire',
  '6G': 'Go2Sky',
  F3: 'FAI rent-a-jet',
  FE: 'FAT Far Eastern Air Transport',
  '7V': 'Federal Airlines',
  '8D': 'FitsAir',
  F8: 'Flair Airlines',
  '4Y': 'Yute Air',
  '8W': 'Fly All Ways',
  IF: 'Fly Baghdad',
  '7B': 'Fly Blue Crane',
  '5F': 'Fly One',
  FT: 'FlyEgypt',
  ND: 'FMI Air',
  BF: 'French Blue',
  JH: 'FDA Fuji Dream Airlines',
  FU: 'Fuzhou Airlines',
  '4G': 'Gazpromavia',
  '5S': 'Global Aviation Services Group',
  YR: 'Scenic Airlines',
  GV: 'Grant Aviation',
  GX: 'GX Airlines',
  HS: 'Heli Securite Helicopter Airline',
  UD: "Hex'Air",
  RX: 'Regent Airways',
  H9: 'Himalaya Airlines',
  OI: 'Hinterland Aviation',
  II: 'IBC Air',
  '4I': 'Izair',
  V8: 'Iliamna Air Taxi',
  I7: "Int'air iles",
  B9: 'Iran Airtour',
  NV: 'Iranian Naft Airlines',
  IA: 'Iraqi Airways',
  Q2: 'Maldivian',
  T6: 'AirSWIFT',
  I8: 'Izhavia',
  J6: 'Jet Ops',
  JG: 'Jetgo Australia',
  RY: 'Jiangxi Air',
  R5: 'Jordan Aviation',
  JR: 'Joy Air',
  '6Z': 'Euro-Asia Air',
  '3J': 'Jubba Airways',
  U5: 'Karinou Airlines',
  M5: 'Kenmore Air',
  '4K': 'Kenn Borek Air',
  KG: 'Key Lime Air',
  KI: 'KrasAvia',
  WJ: 'Air Labrador',
  LP: 'LATAM Airlines Peru',
  LK: 'Lao Skyway',
  N5: 'Nolinor Aviation',
  YL: 'Libyan Wings',
  '8J': 'EcoJet',
  I4: 'Island Air Express',
  PA: 'airblue',
  A0: 'Macair Jet',
  M8: 'Skyjet Airlines',
  '3W': 'Malawian Airlines',
  '7Y': 'Mann Yadanarpon Airlines',
  VM: 'MaxAir',
  LV: 'MEGA Maldives Airlines',
  JI: 'Meraj Air',
  '7D': 'Maluti Sky',
  M2: 'MHS Aviation GmbH',
  LL: 'Miami Air International',
  '8G': 'Fly Mid Africa',
  QM: 'Monacair',
  N8: 'National Airlines',
  '5C': 'Nature Air',
  ON: 'Nauru Airlines',
  '6N': 'Niger Airlines',
  N6: 'Bay Air',
  M3: 'North Flying',
  HW: 'North-Wright Airways',
  J3: 'Northwestern Air',
  N9: 'Novair',
  O9: 'NOVA Airways',
  J1: 'OneJet',
  '2G': 'Angara Airlines',
  O3: 'Orange Air',
  '6O': 'Orbest',
  OC: 'Oriental Air Bridge',
  '3F': 'Pacific Airways',
  P6: 'Pascan Aviation',
  FP: 'FlyPelican',
  YP: 'Perimeter Aviation',
  PU: 'Plus Ultra Lineas Aereas',
  Z3: 'Promech Air',
  PI: 'Polar Airlines',
  OL: 'Polynesian Airlines',
  '0J': 'Premium Jet AG',
  '6F': 'Primea Air',
  PV: 'St Barth Commuter',
  '8F': 'STP Airways',
  '8B': 'TransNusa',
  XN: 'Xpress Air',
  IL: 'Trigana Air Service',
  QB: 'Qeshm Air',
  QW: 'Qingdao Airlines',
  '2O': 'Island Air Service',
  '8N': 'Regional Air',
  DR: 'Ruili Airlines',
  '5R': 'Rutaca Airlines',
  E3: 'New Gen Airways',
  K3: 'Taquan Air',
  F2: 'Safarilink',
  C7: 'Cinnamon Air',
  '6W': 'Saratov Airlines',
  '6S': 'SaudiGulf',
  RZ: 'SANSA',
  D2: 'Severstal',
  NL: 'Shaheen Air',
  SH: 'Sharp Airlines',
  HK: 'Skippers',
  ZA: 'Sky Angkor Airlines',
  TE: 'SkyTaxi',
  Q7: 'SkyBahamas',
  NB: 'Skypower Express Airways',
  RK: 'R Airlines',
  OW: 'Skyward Express',
  P7: 'Small Planet Airlines',
  '6K': 'Songbird Airways',
  XO: 'SEAIR',
  PL: 'Southern Air Charter',
  IJ: 'Spring Airlines Japan',
  P8: 'SprintAir',
  '2U': "Sun d'Or",
  S6: 'Sunrise Airways',
  YH: 'Yangon Airways',
  LZ: 'Swiss Global',
  '7E': 'Sylt Air',
  HH: 'Taban Airlines',
  TQ: 'Tandem Aero',
  '3T': 'Tarco Air',
  H7: 'Taron Avia',
  SF: 'Tassili Airlines',
  XP: 'XTRA Airways',
  '2H': 'Thalys',
  DK: 'Thomas Cook Scandinavia',
  K1: 'Topas',
  Q8: 'TAC Trans Air Congo',
  LU: 'LATAM Airlines Chile',
  '5U': 'TAG Transportes Aereos Guatemaltecos',
  '6B': 'TUIfly Nordiñ',
  UE: 'Ultimate Air Shuttle',
  '4H': 'United Airways Bangladesh',
  UQ: 'Urumqi Air',
  BS: 'US-Bangla Airlines',
  V9: 'Van Air Europe',
  VC: 'ViaAir',
  '0V': 'VASCO',
  VP: 'Flyme',
  VU: 'VECA Airlines',
  IV: 'VVB Aviation Malta',
  W7: 'Wings of Lebanon',
  Y8: 'Yangtze River Airlines',
  A6: 'Hongtu',
  YI: 'Yingan Airlines',
};

export { AIRLINE_NAME_MAP };

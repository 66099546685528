import * as React from 'react';
import Paper from '@material-ui/core/Paper';
const styles = require('./styles.pcss');
import cx from 'classnames';
import moment from 'moment';

type Props = {
  syncError: any;
};

class BookingSyncError extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);
  }

  renderSyncErrorInformation = () => {
    if (this.props.syncError != null && 'message' in this.props.syncError) {
      const errorDate = moment
        .utc(this.props.syncError.created)
        .format('LT ddd, MMM D');

      return (
        <div className={styles.syncError__information}>
          <div className={styles.syncError__property}>
            <h2 className={styles.syncError__property__title}>Message</h2>
            <p className={styles.syncError__property__value}>
              {this.props.syncError.message}
            </p>
          </div>
          <hr className={styles.syncError__divider} />
          <div className={styles.syncError__property}>
            <h2 className={styles.syncError__property__title}>Exception</h2>
            <p className={styles.syncError__property__value}>
              {this.props.syncError.exception}
            </p>
          </div>
          <hr className={styles.syncError__divider} />
          <div className={styles.syncError__property}>
            <h2 className={styles.syncError__property__title}>Is Synced</h2>
            <p className={styles.syncError__property__value}>
              {this.props.syncError.isSynced ? 'Yes' : 'No'}
            </p>
          </div>
          <hr className={styles.syncError__divider} />
          <div className={styles.syncError__property}>
            <h2 className={styles.syncError__property__title}>Date</h2>
            <p className={styles.syncError__property__value}>{errorDate}</p>
          </div>
        </div>
      );
    }
    return (
      <div className={styles.syncError__information}>
        <div className={styles.syncError__description}>
          This booking has not encountered any sync errors yet.
        </div>
      </div>
    );
  };

  renderTitle = () => {
    if (this.props.syncError != null && 'message' in this.props.syncError) {
      return 'Last Known Sync Error';
    } else {
      return 'No Sync Errors Found';
    }
  };

  render() {
    return (
      <Paper className={cx(styles.syncError, styles.bookingDetails)}>
        <div className={styles.header}>
          <h1 className={styles.syncError__title}>{this.renderTitle()}</h1>
        </div>
        <div className={styles.syncError__container}>
          {this.renderSyncErrorInformation()}
        </div>
      </Paper>
    );
  }
}

export default BookingSyncError;

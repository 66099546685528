import React, { Component } from 'react';
import classNames from 'classnames';
import _ from 'lodash-es';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { navigate, redirectTo } from '@reach/router';
import { ReduxState } from '../../reducers/types';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextFieldWithClear from 'components/TextFieldWithIcon/TextFieldWithIcon';
import CustomSelect from 'components/Inputs/Select/Select';
import Loader from 'components/Loader/Loader';
import Visibility from '@material-ui/icons/Visibility';
import FormHelperText from '@material-ui/core/FormHelperText';
import { add, resetAddUser } from '../../actions/ManageBB/Users/Add';
import {
  update,
  resetUpdateUser,
  updateStatus,
} from '../../actions/ManageBB/Users/Update';
import {
  get as getAgencies,
  emptyResults as emptyAgencies,
} from 'actions/Agencies/Get';
import { get as getRoles, emptyResults as emptyRoles } from 'actions/Roles/Get';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { hasPermission } from '../../helpers/Permission';

const styles = require('./styles.pcss');

type Props = {
  showAddUser: boolean;
  closeAddUser: any;
  add: Function;
  addBBUser: any;
  updateBBUser: any;
  resetAddUser: Function;
  resetUpdateUser: Function;
  getAgencies: Function;
  getRoles: Function;
  emptyRoles: Function;
  emptyAgencies: Function;
  agencies: any;
  roles: any;
  onSuccess: Function;
  update: Function;
  updateUser?: any;
  updateStatus: Function;
};

type State = {
  id: string;
  name: string;
  email: string;
  password: string;
  confpassword: string;
  agency: string;
  role: string;
  errorName: string;
  errorEmail: string;
  errorPassword: string;
  errorConfPassword: string;
  errorRole: string;
  showNewPassword: boolean;
  showConfPassword: boolean;
  changePasswordCheck: any;
  openTerminateProfile: any;
};

class AddUserPopup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.props.getAgencies();
    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      id: '',
      name: _.get(this.props, 'updateUser.name', ''),
      email: _.get(this.props, 'updateUser.email', ''),
      password: '',
      confpassword: '',
      agency: _.get(this.props, 'updateUser.agency_id', ''),
      role: _.get(this.props, 'updateUser.roleName'),
      errorName: '',
      errorEmail: '',
      errorPassword: '',
      errorConfPassword: '',
      errorRole: '',
      showNewPassword: false,
      showConfPassword: false,
      changePasswordCheck: '',
      openTerminateProfile: false,
    };
  }

  componentWillUnmount() {
    this.props.emptyAgencies();
  }

  componentWillReceiveProps = (nextProps: any) => {
    if (
      _.get(nextProps, 'showAddUser', null) == true &&
      _.get(this.props, 'showAddUser', null) === false
    ) {
      let agency_id = _.get(nextProps, 'updateUser.agency_id', '');

      if (!nextProps.updateUser.agency_id) {
        agency_id = 'null';
      }

      if (_.get(nextProps, 'updateUser', '')) {
        this.setState({
          name: _.get(nextProps, 'updateUser.name', ''),
          email: _.get(nextProps, 'updateUser.email', ''),
          role: _.get(nextProps, 'updateUser.roleName', ''),
          agency: agency_id,
          id: _.get(nextProps, 'updateUser.id', ''),
        });

        if (_.get(nextProps, 'updateUser.agency_id', '')) {
          this.props.getRoles(_.get(nextProps, 'updateUser.agency_id', ''));
        } else {
          this.props.getRoles();
        }
      } else {
        this.setState({
          id: '',
          name: '',
          email: '',
          password: '',
          confpassword: '',
          agency: '',
          role: '',
          errorName: '',
          errorEmail: '',
          errorPassword: '',
          errorConfPassword: '',
          errorRole: '',
          showNewPassword: false,
          showConfPassword: false,
          openTerminateProfile: false,
        });

        this.props.getRoles(this.getUserAgencyId());
      }
    }

    if (
      _.get(nextProps.addBBUser, 'success', null) === false ||
      _.get(nextProps.updateBBUser, 'success', null) === false
    ) {
      let errors = _.get(nextProps.addBBUser, 'error', {});

      if (_.isEmpty(errors)) {
        errors = _.get(nextProps.updateBBUser, 'error', {});
      }

      this.setState({ ...errors });
    } else if (_.get(nextProps, 'addBBUser.success', null) === true) {
      this.props.resetAddUser();
      this.closeAddUser();
      this.props.onSuccess();
    } else if (_.get(nextProps, 'updateBBUser.success', null) === true) {
      this.props.resetUpdateUser();
      this.closeAddUser();
    }
  };

  handleChangeName = (event: any) => {
    this.setState({
      name: event.target.value,
      errorName: '',
    });
  };

  handleChangeEmail = (event: any) => {
    this.setState({
      email: event.target.value,
      errorEmail: '',
    });
  };

  handleChangePassword = (event: any) => {
    this.setState({
      password: event.target.value,
      errorPassword: '',
    });
  };

  handleChangeConfPassword = (event: any) => {
    this.setState({
      confpassword: event.target.value,
      errorConfPassword: '',
    });
  };

  handleClickshowConfPassword = () => {
    this.setState({
      showConfPassword: !this.state.showConfPassword,
    });
  };

  handleClickshowNewPassword = () => {
    this.setState({
      showNewPassword: !this.state.showNewPassword,
    });
  };

  onKeyUp = (e: any) => {
    if (e.keyCode === 13) {
      this.handleSubmit();
    }
  };

  onDeleteName = () => {
    this.setState({
      name: '',
      errorName: '',
    });
  };

  onChangeAgency = (event: any) => {
    this.props.emptyRoles();

    if (event.target.value == 'null') {
      this.props.getRoles();
    } else {
      this.props.getRoles(event.target.value);
    }

    this.setState({
      agency: event.target.value,
      role: '',
      errorRole: '',
    });
  };

  onChangeRole = (event: any) => {
    this.setState({
      role: event.target.value,
      errorRole: '',
    });
  };

  onDeleteEmail = () => {
    this.setState({
      email: '',
      errorEmail: '',
    });
  };

  handleSubmit = () => {
    if (!_.get(this.props, 'updateUser.active', true)) {
      this.props.updateStatus({
        bbUserId: this.state.id,
        active: !this.props.updateUser.active,
      });
    } else {
      let agencyId = this.state.agency;

      if (this.getUserAgencyId()) {
        agencyId = this.getUserAgencyId();
      }

      if (this.validFields()) {
        let data: any = {
          name: this.state.name,
          email: this.state.email,
          role: this.state.role,
        };

        if (agencyId != 'null' && agencyId) {
          data['agencyId'] = agencyId;
        }

        if (!_.get(this.props, 'updateUser', '')) {
          data['password'] = this.state.password;
          this.props.add(data);
        } else {
          if (this.state.changePasswordCheck) {
            data['password'] = this.state.password;
          }

          data['bbUserId'] = this.state.id;
          this.props.update(data);
        }
      }
    }
  };

  onClickTerminateProfile = () => {
    this.setState({
      openTerminateProfile: true,
    });
  };

  onClickTerminateProfileSubmit = () => {
    this.props.updateStatus({
      bbUserId: this.state.id,
      active: !this.props.updateUser.active,
    });
  };

  onClickTerminateProfileCancel = () => {
    this.setState({
      openTerminateProfile: false,
    });
  };

  renderStatusUpdate = () => {
    if (
      _.get(this.props, 'updateUser', '') &&
      _.get(this.props, 'updateUser.active', false) &&
      hasPermission('blackBox', 'update')
    ) {
      if (!this.state.openTerminateProfile) {
        return (
          <div className={styles.deleteButtonContainer}>
            <Button
              size="small"
              className={styles.buttonTerminateProfile}
              variant="contained"
              onClick={this.onClickTerminateProfile}
            >
              Deactivate
            </Button>
          </div>
        );
      } else {
        return (
          <div className={styles.deleteProfileContainer}>
            <div>
              <div className={styles.deleteTextStyle}>Deactivate</div>
              <div className={styles.deleteTextDescStyle}>
                Please confirm that you want to deactivate this employee’s
                account.
              </div>
            </div>
            <div
              className={classNames(styles.buttonCont, styles.profileDelete)}
            >
              <Button
                size="small"
                className={styles.cancelButton}
                variant="outlined"
                color="primary"
                onClick={this.onClickTerminateProfileCancel}
              >
                Cancel
              </Button>

              <Button
                className={styles.deleteButtonStyle}
                variant="contained"
                color="secondary"
                size="small"
                onClick={this.onClickTerminateProfileSubmit}
                id="submit-deactivate"
              >
                Deactivate
              </Button>
            </div>
          </div>
        );
      }
    }

    return null;
  };

  validFields = () => {
    let isValid = true;
    let errorConfPassword = '';
    let errorPassword = '';
    let errorName = '';
    let errorEmail = '';
    let errorRole = '';

    if (!this.state.name || this.state.name.trim() === '') {
      errorName = 'Required';
      isValid = false;
    }

    if (!this.state.email || this.state.email.trim() === '') {
      errorEmail = 'Required';
      isValid = false;
    }

    if (
      (this.getUserAgencyId() || this.state.agency) &&
      (!this.state.role || this.state.role.trim() === '')
    ) {
      errorRole = 'Required';
      isValid = false;
    }

    if (
      !_.get(this.props, 'updateUser', '') ||
      this.state.changePasswordCheck
    ) {
      if (this.state.confpassword.trim() === '') {
        errorConfPassword = 'Required';
        isValid = false;
      }

      if (this.state.confpassword !== this.state.password) {
        errorConfPassword = 'The Password and Confirm Password does not match';
        isValid = false;
      }

      if (this.state.password.trim() === '') {
        errorPassword = 'Required';
        isValid = false;
      }
    }

    this.setState({
      errorConfPassword,
      errorPassword,
      errorName,
      errorEmail,
      errorRole,
    });

    return isValid;
  };

  renderLoading = () => {
    if (_.get(this.props, 'addBBUser.isLoading', false) == true) {
      return (
        <div className={styles.infoLoader}>
          <Loader visible={true} />
        </div>
      );
    }

    return null;
  };

  getUserAgencyId = () => {
    const loginData = localStorage.getItem('login_data') as string;
    const parsedLoginData = JSON.parse(loginData);

    return _.get(parsedLoginData, 'agencyId', '');
  };

  renderAgencySelection = () => {
    if (this.getUserAgencyId()) {
      return null;
    }

    let agencies = this.props.agencies.map((agency: any) => ({
      key: agency.id,
      value: agency.id,
      label: agency.friendlyName,
    }));

    agencies.unshift({ key: 'null', value: 'null', label: 'All' });

    return (
      <div className={styles.inputWrapper}>
        <CustomSelect
          margin={'normal'}
          variant={'outlined'}
          className={styles.selectWrapper}
          htmlFor={'input-agency'}
          label={'Agency'}
          id={'input-agency'}
          value={this.state.agency}
          onChange={this.onChangeAgency}
          fullWidth={true}
          labelWidth={60}
          inputName={'agency'}
          disabled={!_.get(this.props, 'updateUser.active', true)}
          endText={this.state.agency != '' ? '' : 'Optional'}
          MenuProps={{
            style: { marginTop: 4, boxShadow: 'none' },
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
          }}
          menuItems={agencies}
        />
      </div>
    );
  };

  onChangePasswordCheckBox = () => {
    this.setState({
      changePasswordCheck: !this.state.changePasswordCheck,
    });
  };

  renderCheckBox = () => {
    if (_.get(this.props, 'updateUser', '')) {
      return (
        <div className={classNames(styles.inputWrapper, styles.editUserCheck)}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  className={styles.checkboxRating}
                  checked={this.state.changePasswordCheck}
                  onChange={this.onChangePasswordCheckBox}
                  value={this.state.changePasswordCheck}
                  disabled={!_.get(this.props, 'updateUser.active', true)}
                />
              }
              label={'Change Password'}
            />
          </FormGroup>
        </div>
      );
    }

    return null;
  };

  renderPassword = () => {
    if (
      _.get(this.props, 'updateUser', '') &&
      !this.state.changePasswordCheck
    ) {
      return null;
    }

    return (
      <>
        <div className={styles.inputWrapper}>
          <TextField
            disabled={!_.get(this.props, 'updateUser.active', true)}
            fullWidth
            id="currentPassword"
            className={styles.selectWrapper}
            label="Password"
            variant="outlined"
            margin="normal"
            value={this.state.password}
            onChange={this.handleChangePassword}
            error={Boolean(this.state.errorPassword)}
            helperText={this.state.errorPassword}
            onKeyUp={this.onKeyUp}
            type={this.state.showNewPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: this.state.password !== '' && (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={this.handleClickshowNewPassword}
                  >
                    {this.state.showNewPassword ? (
                      <Visibility
                        className={classNames(styles.icon, styles.eyeVisible)}
                      />
                    ) : (
                      <Visibility className={styles.icon} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className={styles.inputWrapper}>
          <TextField
            disabled={!_.get(this.props, 'updateUser.active', true)}
            fullWidth
            id="confirmPassword"
            className={styles.selectWrapper}
            label="Confirm Password"
            variant="outlined"
            margin="normal"
            value={this.state.confpassword}
            onChange={this.handleChangeConfPassword}
            error={Boolean(this.state.errorConfPassword)}
            helperText={this.state.errorConfPassword}
            onKeyUp={this.onKeyUp}
            type={this.state.showConfPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: this.state.confpassword !== '' && (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={this.handleClickshowConfPassword}
                  >
                    {this.state.showConfPassword ? (
                      <Visibility
                        className={classNames(styles.icon, styles.eyeVisible)}
                      />
                    ) : (
                      <Visibility className={styles.icon} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </>
    );
  };

  renderRoleError = () => {
    if (Boolean(this.state.errorRole)) {
      return <FormHelperText error>{this.state.errorRole}</FormHelperText>;
    }

    return null;
  };

  renderRoles = () => {
    if (!this.getUserAgencyId() && !this.state.agency) {
      return null;
    }

    let roles = _.map(this.props.roles.roles, (role: any) => {
      if (!role.friendly_name) {
        role.friendly_name = role.name;
      }
      return role;
    });

    roles = _.sortBy(roles, [(role) => role.friendly_name.toLowerCase()]);

    return (
      <CustomSelect
        disabled={!_.get(this.props, 'updateUser.active', true)}
        margin={'normal'}
        variant={'outlined'}
        className={styles.selectWrapper}
        htmlFor={'input-role'}
        label={'Role'}
        id={'role'}
        value={this.state.role}
        onChange={this.onChangeRole}
        fullWidth={true}
        labelWidth={45}
        inputName={'role'}
        renderError={this.renderRoleError()}
        error={Boolean(this.state.errorRole)}
        MenuProps={{
          style: { marginTop: 4, boxShadow: 'none' },
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        menuItems={roles.map((role: any) => ({
          key: role.name,
          value: role.name,
          label: role.friendly_name,
        }))}
      />
    );
  };

  closeAddUser = () => {
    this.props.resetAddUser();
    this.props.resetUpdateUser();
    this.setState(this.getInitialState());
    this.props.closeAddUser();
  };

  render() {
    return (
      <Dialog
        fullWidth={true}
        maxWidth="md"
        className={classNames(styles.dialogBox, styles.adduser)}
        open={this.props.showAddUser}
        onClose={this.closeAddUser}
      >
        <div className={classNames(styles.title)}>
          {this.props.updateUser ? 'Update User' : 'Add User'}
          <IconButton
            color="inherit"
            onClick={this.closeAddUser}
            className={classNames(styles.closeIcon)}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        </div>
        {this.renderLoading()}
        <div
          className={classNames(
            styles.content,
            styles.inputContainerAddCompany,
          )}
        >
          <div className={styles.inputWrapper}>
            <TextFieldWithClear
              disabled={!_.get(this.props, 'updateUser.active', true)}
              fullWidth
              id="name"
              className={styles.textField}
              label="Name"
              variant="outlined"
              margin="normal"
              value={this.state.name}
              onChange={this.handleChangeName}
              error={this.state.errorName}
              helperText={this.state.errorName}
              onKeyUp={this.onKeyUp}
              onClear={this.onDeleteName}
            />
          </div>
          <div className={styles.inputWrapper}>
            <TextFieldWithClear
              disabled={!_.get(this.props, 'updateUser.active', true)}
              fullWidth
              id="email"
              className={styles.textField}
              label="Email"
              variant="outlined"
              margin="normal"
              value={this.state.email}
              onChange={this.handleChangeEmail}
              error={Boolean(this.state.errorEmail)}
              helperText={this.state.errorEmail}
              onKeyUp={this.onKeyUp}
              onClear={this.onDeleteEmail}
            />
          </div>

          {this.renderCheckBox()}
          {this.renderPassword()}
          {this.renderAgencySelection()}

          <div className={styles.inputWrapper}>{this.renderRoles()}</div>

          <div className={styles.statusUpdate}>{this.renderStatusUpdate()}</div>

          <div className={classNames(styles.actions, styles.actionsRight)}>
            <Button
              className={styles.button}
              variant="contained"
              color="primary"
              onClick={this.handleSubmit}
              disabled={
                this.props.addBBUser.isLoading ||
                this.state.openTerminateProfile
              }
              id="submit-bbuser-popup"
            >
              {!_.get(this.props, 'updateUser.active', true)
                ? 'Reactivate'
                : 'Save'}
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {
    addBBUser: state.addBBUser,
    updateBBUser: state.updateBBUser,
    agencies: state.agencies.agencies,
    roles: state.roles,
  };
}

// Wire up redux dispatch functions
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      add,
      update,
      resetAddUser,
      resetUpdateUser,
      getAgencies,
      getRoles,
      emptyRoles,
      emptyAgencies,
      updateStatus,
    },
    dispatch,
  );
}

// Connect it to Redux
export default connect(mapStateToProps, mapDispatchToProps)(AddUserPopup);

import React, { Component } from 'react';
import cx from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextFieldWithClear from 'components/TextFieldWithIcon/TextFieldWithIcon';
import CustomSelect from 'components/Inputs/Select/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import {
  putBankAccountSetting,
  resetBankAccountError,
} from '../../actions/Companies/Settings/PutBankAccountSetting';
import _ from 'lodash-es';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ReduxState } from '../../reducers/types';
import Loader from 'components/Loader/Loader';

const styles = require('./styles.pcss');

type Props = {
  open: boolean;
  onClose: any;
  companyName: any;
  bankAccountId: any;
  bankAccount: any;
  putBankAccountSetting: Function;
  resetBankAccountError: Function;
  organizationId: any;
};

type State = {
  bankName: string;
  account: any;
  accountType: string;
  routingNumber: any;
  confAccountNumber: any;
  errorBankName: string;
  errorRoutingNumber: string;
  errorAccountNumber: string;
  errorConfAccountNumber: string;
  errorAccountType: string;
  accountNumber: any;
};

class BankAccountPopup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      bankName: '',
      account: '',
      accountType: '',
      routingNumber: '',
      accountNumber: '',
      confAccountNumber: '',
      errorBankName: '',
      errorRoutingNumber: '',
      errorAccountNumber: '',
      errorConfAccountNumber: '',
      errorAccountType: '',
    };
  }

  componentWillReceiveProps = (nextProps: any) => {
    if (
      _.get(this.props.bankAccount, 'isLoading', null) &&
      _.get(nextProps.bankAccount, 'success', null) === false
    ) {
      const errors = _.get(nextProps.bankAccount, 'errors', {});

      this.setState({ ...errors });
    } else if (
      _.get(this.props.bankAccount, 'isLoading', null) &&
      _.get(nextProps.bankAccount, 'success', null) === true
    ) {
      this.onClose();
    }
  };

  handleChangeName = (event: any) => {
    this.setState({
      bankName: event.target.value,
      errorBankName: '',
    });
  };

  handleChangeRoutingNumber = (event: any) => {
    this.setState({
      routingNumber: event.target.value,
      errorRoutingNumber: '',
    });
  };

  handleChangeAccountNumber = (event: any) => {
    this.setState({
      accountNumber: event.target.value,
      errorAccountNumber: '',
    });
  };

  handleChangeconfAccountNumber = (event: any) => {
    this.setState({
      confAccountNumber: event.target.value,
      errorConfAccountNumber: '',
    });
  };

  onChangeAccountType = (event: any) => {
    this.setState({
      accountType: event.target.value,
      errorAccountType: '',
    });
  };

  onDeleteName = () => {
    this.setState({
      bankName: '',
      errorBankName: '',
    });
  };

  onDeleteRoutingNumber = () => {
    this.setState({
      routingNumber: '',
      errorRoutingNumber: '',
    });
  };

  onDeleteAccountNumber = () => {
    this.setState({
      accountNumber: '',
      errorAccountNumber: '',
    });
  };

  onDeleteConfAccountNumber = () => {
    this.setState({
      confAccountNumber: '',
      errorConfAccountNumber: '',
    });
  };

  renderAccountTypeError = () => {
    if (Boolean(this.state.errorAccountType)) {
      return (
        <FormHelperText error>{this.state.errorAccountType}</FormHelperText>
      );
    }

    return null;
  };

  validFields = () => {
    let isValid = true;
    let check = {
      errorBankName: '',
      errorRoutingNumber: '',
      errorAccountNumber: '',
      errorConfAccountNumber: '',
      errorAccountType: '',
    };

    if (this.state.bankName.trim() == '') {
      check.errorBankName = 'Required';
      isValid = false;
    }

    if (this.state.routingNumber.trim() == '') {
      check.errorRoutingNumber = 'Required';
      isValid = false;
    }

    if (this.state.accountNumber.trim() == '') {
      check.errorAccountNumber = 'Required';
      isValid = false;
    }

    if (this.state.accountNumber.trim() == '') {
      check.errorAccountNumber = 'Required';
      isValid = false;
    }

    if (this.state.confAccountNumber.trim() == '') {
      check.errorConfAccountNumber = 'Required';
      isValid = false;
    }

    if (
      !check.errorAccountNumber &&
      !check.errorConfAccountNumber &&
      this.state.confAccountNumber.trim() != this.state.accountNumber.trim()
    ) {
      check.errorConfAccountNumber = 'Does not match with Account Number';
      isValid = false;
    }

    if (this.state.accountType.trim() == '') {
      check.errorAccountType = 'Required';
      isValid = false;
    }

    this.setState({ ...check });

    return isValid;
  };

  onSubmit = () => {
    if (this.validFields()) {
      this.props.putBankAccountSetting(this.props.organizationId, {
        bank: this.state.bankName,
        accountName: this.props.companyName,
        accountNumber: this.state.accountNumber,
        confAccountNumber: this.state.confAccountNumber,
        accountType: this.state.accountType,
        routingNumber: this.state.routingNumber,
        bankAccountId: this.props.bankAccountId,
      });
    }
  };

  onClose = () => {
    this.setState({ ...this.getInitialState() });
    this.props.resetBankAccountError();
    this.props.onClose();
  };

  renderLoader = () => {
    if (_.get(this.props, 'bankAccount.isLoading', false) == true) {
      return (
        <div className={styles.infoLoader}>
          <Loader visible={true} />
        </div>
      );
    }

    return null;
  };

  render() {
    return (
      <Dialog
        maxWidth={'md'}
        fullWidth={true}
        className={cx(styles.dialogBox, styles.confirmationDialog)}
        open={this.props.open}
        onClose={this.onClose}
      >
        <div className={cx(styles.title, styles.titleBankAccount)}>
          Add Bank Account
          <IconButton
            color="inherit"
            onClick={this.onClose}
            className={styles.closeIcon}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        </div>

        {this.renderLoader()}

        <div className={styles.bankAccountHeader}>
          Add {this.props.companyName}’s bank account information to reimburse
          employees.
        </div>
        <div
          className={cx(
            styles.bankAccountContent,
            styles.inputContainerAddCompany,
          )}
        >
          <div className={styles.inputWrapper}>
            <TextFieldWithClear
              fullWidth
              id="bankNameInst"
              className={cx(styles.textField)}
              label="Bank Institute Name"
              variant="outlined"
              margin="normal"
              value={this.state.bankName}
              onChange={this.handleChangeName}
              onClear={this.onDeleteName}
              error={Boolean(this.state.errorBankName)}
              helperText={this.state.errorBankName}
            />
          </div>
          <div className={styles.inputWrapper}>
            <TextFieldWithClear
              fullWidth
              id="routingNumber"
              className={cx(styles.textField)}
              label="Routing Number"
              variant="outlined"
              margin="normal"
              value={this.state.routingNumber}
              onChange={this.handleChangeRoutingNumber}
              onClear={this.onDeleteRoutingNumber}
              error={Boolean(this.state.errorRoutingNumber)}
              helperText={this.state.errorRoutingNumber}
            />
          </div>
          <div className={styles.inputWrapper}>
            <TextFieldWithClear
              fullWidth
              id="accountNumber"
              className={cx(styles.textField)}
              label="Account Number"
              variant="outlined"
              margin="normal"
              value={this.state.accountNumber}
              onChange={this.handleChangeAccountNumber}
              onClear={this.onDeleteAccountNumber}
              error={Boolean(this.state.errorAccountNumber)}
              helperText={this.state.errorAccountNumber}
            />
          </div>
          <div className={styles.inputWrapper}>
            <TextFieldWithClear
              fullWidth
              id="routingNumber"
              className={cx(styles.textField)}
              label="Confirm Account Number"
              variant="outlined"
              margin="normal"
              value={this.state.confAccountNumber}
              onChange={this.handleChangeconfAccountNumber}
              onClear={this.onDeleteConfAccountNumber}
              error={Boolean(this.state.errorConfAccountNumber)}
              helperText={this.state.errorConfAccountNumber}
            />
          </div>

          <div className={styles.inputWrapper}>
            <CustomSelect
              margin={'normal'}
              variant={'outlined'}
              className={styles.selectWrapper}
              htmlFor={'input-type'}
              label={'Account Type'}
              id={'input-type'}
              value={this.state.accountType}
              onChange={this.onChangeAccountType}
              fullWidth={true}
              labelWidth={95}
              inputName={'account-type'}
              inputError={Boolean(this.state.errorAccountType)}
              renderError={this.renderAccountTypeError()}
              MenuProps={{
                style: { marginTop: 4, boxShadow: 'none' },
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
              }}
              menuItems={[
                { key: 'checking', value: 'checking', label: 'Checking' },
                { key: 'savings', value: 'savings', label: 'Savings' },
              ]}
            />
          </div>
        </div>

        <div className={styles.bankAccountPopupButtons}>
          <Button
            className={styles.cancelButton}
            color="primary"
            onClick={this.onClose}
          >
            {`Cancel`}
          </Button>

          <Button
            className={styles.button}
            variant="contained"
            color="primary"
            onClick={this.onSubmit}
          >
            {`Add`}
          </Button>
        </div>
      </Dialog>
    );
  }
}

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {
    bankAccount: state.organizationBankAccount,
  };
}

// Wire up redux dispatch functions
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      putBankAccountSetting,
      resetBankAccountError,
    },
    dispatch,
  );
}

// Connect it to Redux
export default connect(mapStateToProps, mapDispatchToProps)(BankAccountPopup);

import React from 'react';
import cx from 'classnames';
import { debounce } from 'lodash-es';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import CancelIcon from '@material-ui/icons/Cancel';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = require('./styles.pcss');

type Props = {
  onChange: Function;
  label: string;
  className: any;
  places: any;
  getList: any;
  emptyResults: any;
  error: string;
  value: string;
  menuClass?: any;
  list: any;
};

type State = {
  value: any;
};

class DropdownAutosuggestDynamic extends React.Component<any, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      value: props.value ? props.value : '',
    };

    this.handleSuggestionsFetchRequested = debounce(
      this.handleSuggestionsFetchRequested,
      250,
    );
  }

  componentWillReceiveProps = (nextProps: any) => {
    if (nextProps.value != this.props.value) {
      this.setState({
        value: nextProps.value,
      });
    }
  };

  callGetList = (value: string) => {
    this.props.getList(value);
  };

  handleSuggestionsFetchRequested = ({ value }: any) => {
    if (value.length > 2) {
      this.callGetList(value);
    }
  };

  handleSuggestionsClearRequested = () => {
    this.props.emptyResults();
  };

  handleSelect = (event: any, { newValue, method }: any) => {
    this.setState({
      value: newValue.name,
    });

    this.props.onChange(newValue.id);
  };

  /**
   * 
    The `newValue.value` is the original object which was selected/passed on the dropdown. For ex.: the original employee object.
    This is optional, if your onChangeXXX handler has a second parameter it will get the original object. 
    Don't forget to include the original object on your parseXXX function. Check `parseManagers()` for example.
    The standard format for the selected dropdown value is intact: {id: string, name: string}
   */

  handleChange = (event: any, { newValue, method }: any) => {
    let strValue = newValue;
    if (
      (method === 'click' ||
        method === 'down' ||
        method === 'up' ||
        method === 'enter') &&
      newValue.hasOwnProperty('name')
    ) {
      strValue = newValue.name;

      this.props.onChange(newValue.id, newValue.value);
    }

    this.setState({
      value: strValue,
    });
  };

  renderSuggestion = (suggestion: any, { query, isHighlighted }: any) => {
    const matches = match(suggestion.name, query);
    const parts = parse(suggestion.name, matches);

    return (
      <MenuItem
        classes={{ root: styles.listItem }}
        className={styles.paper}
        selected={isHighlighted}
      >
        <div className={styles.menuItem}>
          <div className={styles.location}>
            {parts.map((part, index) => {
              return part.highlight ? (
                <span
                  key={String(index)}
                  style={{ fontWeight: 500 }}
                >
                  {part.text}
                </span>
              ) : (
                <strong
                  key={String(index)}
                  style={{ fontWeight: 400 }}
                >
                  {part.text}
                </strong>
              );
            })}
          </div>
        </div>
      </MenuItem>
    );
  };

  clearValue = () => {
    this.setState({
      value: '',
    });
    this.props.onChange(null);
  };

  renderInputAdornment() {
    if (this.props.isLoading) {
      return (
        <InputAdornment
          className={styles.close}
          position="end"
          onClick={this.clearValue}
        >
          <CircularProgress
            size={24}
            className={styles.sCircularProgress}
          />
        </InputAdornment>
      );
    }

    if (this.state.value.trim() !== '') {
      return (
        <InputAdornment
          className={styles.close}
          position="end"
          onClick={this.clearValue}
        >
          <CancelIcon className={styles.icon} />
        </InputAdornment>
      );
    }

    return null;
  }

  renderInputComponent = (inputProps: any) => {
    const { inputRef = () => {}, ref, ...other } = inputProps;

    return (
      <TextField
        error={Boolean(this.props.error)}
        helperText={this.props.error}
        label={this.props.label}
        variant="outlined"
        margin={this.props.margin}
        fullWidth
        InputProps={{
          inputRef: (node) => {
            ref(node);
            inputRef(node);
          },
          endAdornment: this.renderInputAdornment(),
        }}
        {...other}
      />
    );
  };

  render() {
    const autosuggestProps = {
      renderInputComponent: this.renderInputComponent,
      suggestions: this.props.list,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue: (suggestion: any) => suggestion,
      renderSuggestion: this.renderSuggestion,
    };

    return (
      <div className={cx(this.props.className, styles.root)}>
        <Autosuggest
          {...autosuggestProps}
          inputProps={{
            value: this.state.value,
            onChange: this.handleChange,
          }}
          renderSuggestionsContainer={(options) => (
            <Paper
              {...options.containerProps}
              square
            >
              {options.children}
            </Paper>
          )}
        />
      </div>
    );
  }
}

export default DropdownAutosuggestDynamic;

// function mapStateToProps(state: ReduxState) {
//   return {
//     places: state.places.places,
//   }
// }
//
// function mapDispatchToProps(dispatch: Dispatch) {
//   return bindActionCreators({
//     get,
//     emptyResults
//   }, dispatch);
// }
//
// export default connect(mapStateToProps, mapDispatchToProps)(DropdownAutosuggestDynamic);

import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import _ from 'lodash-es';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MultiSelectWithSearchDynamic from '../Inputs/MultiSelect/MultiSelectWithSearchDynamic';
import Loader from 'components/Loader/Loader';
const styles = require('./styles.pcss');
import {
  putUserOrgAnalytics,
  resetResult,
} from '../../actions/Users/PutUserOrgAnalytics';
import {
  get as getCompanies,
  emptyResults as emptyCompanies,
} from 'actions/Companies/Get';
import { ReduxState } from '../../reducers/types';

type Props = {
  openPopup: boolean;
  closePopup: any;
  getCompanies: Function;
  companies: any;
  isLoadingCompanies: boolean;
  emptyCompanies: Function;
  user: any;
  userAnalyticsAccess: any;
  putUserOrgAnalytics: Function;
  resetResult: Function;
};

type State = {
  companies: any;
  selectedOrgs: any;
  init: any;
};

class UserAnalyticsPopup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = this.getInitialState();

    const newOrgIds = _.get(props.user, 'multiOrganizationAnalytics', []);

    if (newOrgIds.length > 0) {
      this.setState({
        init: false,
      });
      this.props.getCompanies({ organizationIds: newOrgIds });
    }
  }

  getInitialState() {
    return {
      companies: '',
      selectedOrgs: [],
      init: true,
    };
  }

  componentWillReceiveProps = (nextProps: any) => {
    if (
      this.state.init ||
      _.get(nextProps, 'user', null) !== _.get(this.props, 'user', null)
    ) {
      const newOrgIds = _.get(nextProps.user, 'multiOrganizationAnalytics', []);

      if (newOrgIds.length > 0) {
        this.props.getCompanies({ organizationIds: newOrgIds });
      }

      this.setState({
        init: false,
      });
    }

    if (
      nextProps.companies.length !== 0 &&
      this.state.selectedOrgs.length < 1 &&
      _.get(nextProps.user, 'multiOrganizationAnalytics', []).length > 0
    ) {
      const newOrgIds = nextProps.companies.map((data: any) => data.id);

      if (
        _.isEmpty(
          _.xor(
            newOrgIds,
            _.get(nextProps.user, 'multiOrganizationAnalytics', []),
          ),
        )
      ) {
        this.setState({
          selectedOrgs: nextProps.companies.map((data: any) => ({
            value: data.id,
            label: data.name,
          })),
        });
      }
    }

    if (nextProps.companies.length !== 0) {
      nextProps.companies.sort((a: any, b: any) =>
        a.name.localeCompare(b.name),
      );
      this.setState({
        companies: nextProps.companies,
      });
    }

    if (_.get(nextProps.userAnalyticsAccess, 'success', null) === true) {
      this.closePopup();
    }
  };

  componentWillUnmount() {
    this.setState({
      init: true,
    });
  }

  handleSubmit = () => {
    let orgIds: any = [];

    orgIds = this.state.selectedOrgs.map((orgs: any) => orgs.value);
    orgIds = orgIds.join();

    this.props.putUserOrgAnalytics(this.props.user.id, { orgIds: orgIds });
  };

  getCompanies = (value: string) => {
    this.emptyCompanies();

    this.props.getCompanies({
      name: value,
    });
  };

  emptyCompanies = () => {
    this.props.emptyCompanies();
  };

  selectedOrgChange = (value: any) => {
    this.setState({
      selectedOrgs: value,
    });
  };

  renderOrganizationsInput = () => {
    return (
      <MultiSelectWithSearchDynamic
        className={styles.dropdownContainer}
        onChange={(value: any) => {
          this.getCompanies(value);
        }}
        label={'Access to Companies'}
        returnAll={true}
        multiValue={this.state.selectedOrgs}
        dataSet={this.state.companies || []}
        isLoading={this.props.isLoadingCompanies}
        changeSelectedValue={this.selectedOrgChange}
        disabled={this.props.userAnalyticsAccess.isLoading}
        maxMenuHeight={100}
      />
    );
  };

  renderLoader = () => {
    if (!this.props.userAnalyticsAccess.isLoading) {
      return null;
    }

    return (
      <div className={styles.loaderContainer}>
        <Loader visible={true} />
      </div>
    );
  };

  closePopup = () => {
    this.setState({
      companies: '',
      selectedOrgs: [],
      init: true,
    });

    this.props.closePopup();
    this.props.resetResult();
    this.props.emptyCompanies();
  };

  render() {
    return (
      <Dialog
        fullWidth={true}
        maxWidth="md"
        className={classNames(styles.dialogBox, styles.ccPopup)}
        open={this.props.openPopup}
        onClose={this.closePopup}
      >
        <div
          className={classNames(styles.title, styles.editUserAnalyticsPopup)}
        >
          <div className={styles.titleStyle}>{'Update Analytics Access'}</div>
          <IconButton
            color="inherit"
            onClick={this.closePopup}
            className={classNames(styles.closeIcon)}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        </div>
        {this.renderLoader()}
        <div
          className={classNames(styles.updatePopup, styles.editUserAnalytics)}
        >
          <div
            className={classNames(
              styles.userAnalyticsDescription,
              styles.addMarginTop,
            )}
          >
            List of companies whose data will be made available to the user in
            Analytics.
          </div>
          {this.renderOrganizationsInput()}
        </div>
        <div className={classNames(styles.actions, styles.actionsRight)}>
          <div
            className={classNames(
              styles.popupButtonsContainer,
              styles.userAnalyticsButtonCont,
            )}
          >
            <Button
              size="small"
              className={styles.cancelButton}
              color="primary"
              onClick={this.closePopup}
            >
              Cancel
            </Button>
            <Button
              size="small"
              className={styles.button}
              variant="contained"
              color="primary"
              onClick={this.handleSubmit}
            >
              Update
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {
    companies: state.companies.companies,
    isLoadingCompanies: state.companies.isLoading,
    userAnalyticsAccess: state.userAnalyticsAccess,
  };
}

// Wire up redux dispatch functions
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getCompanies,
      emptyCompanies,
      putUserOrgAnalytics,
      resetResult,
    },
    dispatch,
  );
}

// Connect it to Redux
export default connect(mapStateToProps, mapDispatchToProps)(UserAnalyticsPopup);

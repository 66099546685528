import * as React from 'react';
import cx from 'classnames';
import TextFieldWithClear from 'components/TextFieldWithIcon/TextFieldWithIcon';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { get, emptyResults } from 'actions/Agencies/Get';
import { getSingle } from 'actions/Agencies/GetSingle';
import { bindActionCreators, Dispatch } from 'redux';
import { ReduxState } from '../../../reducers/types';
import DropdownAutoSuggestDynamic from 'components/Inputs/Dropdown/DropdownAutoSuggestDynamic';
import _ from 'lodash-es';

const styles = require('./styles.pcss');

class CompaniesRecordForm extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      name: '',
      domain: '',
      agencyId: '',
    };
  }

  componentDidMount = () => {
    this.onSubmitForm('initial');
  };

  onChangeName = (event: any) => {
    this.setState({
      name: event.target.value,
    });
  };

  onChangeDomain = (event: any) => {
    this.setState({
      domain: event.target.value,
    });
  };

  onChangeAgency = (event: any) => {
    this.props.emptyResults();

    this.setState({
      agencyId: event,
    });
  };

  getAgencies = (value: string) => {
    this.props.emptyResults();

    this.props.get({
      friendlyName: value,
      name: value,
      appHost: value,
      order: 'friendlyName ASC',
      type: 'or',
    });
  };

  clearAgency = () => {
    this.props.emptyResults();
  };

  clearName = () => {
    this.setState({
      name: '',
    });
  };

  clearDomain = () => {
    this.setState({
      domain: '',
    });
  };

  onSubmitForm = (initial?: any) => {
    let req: any = {
      name: this.state.name,
      domain: this.state.domain,
      agencyId: this.state.agencyId,
    };

    if (initial == 'initial') {
      const urlParams = new URLSearchParams(window.location.search);

      if (urlParams.get('name')) {
        req.name = urlParams.get('name');
      }

      if (urlParams.get('domain')) {
        req.domain = urlParams.get('domain');
      }

      if (urlParams.get('agencyId')) {
        req.agencyId = urlParams.get('agencyId');

        if (!this.getUserAgencyId()) {
          this.props.getSingle(req.agencyId);
        }
      }

      if (urlParams.get('sort')) {
        req.order = decodeURIComponent(urlParams.get('sort') || 'created DESC');
      } else {
        req.order = 'created DESC';
      }

      this.setState({
        name: req.name,
        domain: req.domain,
        agencyId: req.agencyId,
      });
    } else {
      req.order = 'name ASC';

      let state = { type: 'company' };
      let url = 'lookup?type=company';

      if (this.state.name) {
        url += `&name=${encodeURIComponent(this.state.name)}`;
      }

      if (this.state.domain) {
        url += `&domain=${encodeURIComponent(this.state.domain)}`;
      }

      if (this.state.agencyId) {
        url += `&agencyId=${encodeURIComponent(this.state.agencyId)}`;
      }

      url += `&sort=${encodeURIComponent('name ASC')}`;

      window.history.pushState(state, '', url);
    }

    this.props.onSubmit(req);
  };

  _getSearchButtonText = () => {
    return 'Search';
  };

  onKeyPressed = (e: any) => {
    if (e.key === 'Enter') {
      this.onSubmitForm();
    }
  };

  parseAgencies = () => {
    if (!_.isArray(this.props.agencies)) {
      return [];
    }

    return this.props.agencies.map((agency: any) => {
      return {
        id: _.get(agency, 'id', ''),
        name: _.get(agency, 'friendlyName', ''),
      };
    });
  };

  getUserAgencyId = () => {
    const loginData = localStorage.getItem('login_data') as string;
    const parsedLoginData = JSON.parse(loginData);

    return _.get(parsedLoginData, 'agencyId', '');
  };

  render() {
    return (
      <div
        className={styles.form}
        onKeyDown={(e) => this.onKeyPressed(e)}
      >
        <div className={styles.inputWrapper}>
          <TextFieldWithClear
            onChange={this.onChangeName}
            label="Name"
            variant="outlined"
            margin="normal"
            value={this.state.name}
            onClear={this.clearName}
            fullWidth={true}
          />
        </div>

        <div className={styles.inputWrapper}>
          <TextFieldWithClear
            onChange={this.onChangeDomain}
            label="Domain"
            variant="outlined"
            margin="normal"
            value={this.state.domain}
            onClear={this.clearDomain}
            fullWidth={true}
          />
        </div>

        {!this.getUserAgencyId() && (
          <div className={styles.inputWrapper}>
            <DropdownAutoSuggestDynamic
              className={styles.dropdownContainer}
              onChange={this.onChangeAgency}
              margin="normal"
              label="Agency"
              list={this.parseAgencies()}
              getList={this.getAgencies}
              emptyResults={this.clearAgency}
              isLoading={this.props.isLoading || this.props.isLoadingAgency}
              value={_.get(this.props.agency.agency, 'friendlyName', '')}
            />
          </div>
        )}

        <div className={styles.inputWrapper}>
          <Button
            fullWidth
            className={styles.button}
            variant="contained"
            color="primary"
            onClick={this.onSubmitForm}
          >
            {this._getSearchButtonText()}
          </Button>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      get,
      emptyResults,
      getSingle,
    },
    dispatch,
  );
}

function mapStateToProps(state: ReduxState) {
  return {
    agency: state.agency,
    isLoadingAgency: state.agency.isLoading,
    agencies: state.agencies.agencies,
    isLoading: state.agencies.isLoading,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompaniesRecordForm);

import * as React from 'react';
import _ from 'lodash-es';
import classNames from 'classnames';

const styles = require('./styles.pcss');

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { navigate } from '@reach/router';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import CircularProgress from '@material-ui/core/CircularProgress';

import { ReduxState } from '../../reducers/types';

import { login } from '../../actions/User/Login';

type Props = any;

type State = {
  email: string;
  password: string;
  errorEmail: boolean;
  errorPassword: boolean;
  waitingResponse: boolean;
  showPassword: boolean;
  loading: boolean;
};

class Login extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      errorEmail: false,
      errorPassword: false,
      waitingResponse: false,
      showPassword: false,
      loading: false,
    };

    if (_.get(this.props.userLogin, 'isLoggedIn', false) === true) {
      if (_.get(this.props.userLogin, 'forceResetPassword', false) === true) {
        navigate(`/change-password/`);
      } else {
        navigate(`/lookup/`);
      }
    }
  }

  componentWillReceiveProps(nextProps: any) {
    this.setState({
      loading: false,
    });

    if (_.get(nextProps.userLogin, 'isLoggedIn', false) === true) {
      if (_.get(nextProps.userLogin, 'forceResetPassword', false) === true) {
        navigate(`/change-password/`);
      } else {
        navigate(`/lookup/`);
      }
    }
  }

  handleChangeEmail = (event: any) => {
    this.setState({
      email: event.target.value,
      errorEmail: false,
    });
  };

  handleChangePassword = (event: any) => {
    this.setState({
      password: event.target.value,
      errorPassword: false,
    });
  };

  handleSubmit = () => {
    if (this.state.loading === true) {
      return;
    }

    let errorEmail = false;
    let errorPassword = false;

    if (this.state.email.trim() == '') {
      errorEmail = true;
    }
    if (this.state.password.trim() == '') {
      errorPassword = true;
    }

    this.setState({
      errorEmail: errorEmail,
      errorPassword: errorPassword,
    });

    if (errorPassword == false && errorEmail == false) {
      this.setState({
        loading: true,
      });

      this.props.login(this.state.email, this.state.password);
    }
  };

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  };

  /**
   * Handle form submit on enter
   */
  onKeyUp = (e: any) => {
    if (e.keyCode === 13) {
      this.handleSubmit();
    }
  };

  render() {
    return (
      <div className={styles.pageLogin}>
        <Card className={styles.card}>
          <CardContent className={styles.cardContent}>
            <h1 className={styles.title}>BlackBox</h1>
            <TextField
              fullWidth
              id="email"
              className={styles.textField}
              label="Email"
              variant="outlined"
              margin="normal"
              value={this.state.email}
              onChange={this.handleChangeEmail}
              error={this.state.errorEmail}
              onKeyUp={this.onKeyUp}
            />

            <TextField
              fullWidth
              id="password"
              className={styles.textField}
              label="Password"
              type={this.state.showPassword ? 'text' : 'password'}
              margin="normal"
              variant="outlined"
              autoComplete="current-password"
              value={this.state.password}
              onChange={this.handleChangePassword}
              error={this.state.errorPassword}
              onKeyUp={this.onKeyUp}
              InputProps={{
                endAdornment: this.state.password !== '' && (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={this.handleClickShowPassword}
                    >
                      {this.state.showPassword ? (
                        <Visibility
                          className={classNames(styles.icon, styles.eyeVisible)}
                        />
                      ) : (
                        <Visibility className={styles.icon} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Button
              id="button-login"
              fullWidth
              className={styles.button}
              variant="contained"
              color="primary"
              disabled={this.state.loading}
              onClick={this.handleSubmit}
              disableRipple={true}
            >
              Log In
            </Button>

            {this.state.loading && (
              <CircularProgress
                size={24}
                style={{
                  position: 'absolute',
                  left: '50%',
                  marginTop: 28,
                  marginLeft: -12,
                }}
              />
            )}
          </CardContent>
        </Card>
      </div>
    );
  }
}

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {
    userLogin: state.userLogin,
  };
}

// Wire up redux dispatch functions
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      login,
    },
    dispatch,
  );
}

// Connect it to Redux
export default connect(mapStateToProps, mapDispatchToProps)(Login);

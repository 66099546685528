import React from 'react';

type Props = {
  width: number;
  height: number;
  color: any;
};

const SvgRightArrowIcon = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 13 13"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <filter
          x="-9.7%"
          y="-37.5%"
          width="119.4%"
          height="178.1%"
          filterUnits="objectBoundingBox"
          id="filter-1"
        >
          <feOffset
            dx="0"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            stdDeviation="2"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0.42745098   0 0 0 0 0.443137255   0 0 0 0 0.478431373  0 0 0 0.15 0"
            type="matrix"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          ></feColorMatrix>
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
        </filter>
      </defs>
      <g
        id="Master"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Organization-Macro---Employees"
          transform="translate(-884.000000, -813.000000)"
          fill={props.color}
        >
          <g
            id="Pagination"
            filter="url(#filter-1)"
            transform="translate(781.000000, 803.000000)"
          >
            <path
              d="M108.475875,17.480375 L109.219875,18.220375 L109.499875,18.500375 L109.780875,18.220375 L110.524875,17.480375 L113.288875,14.730375 C113.570875,14.449375 113.570875,13.990375 113.288875,13.710375 C113.006875,13.429375 112.545875,13.429375 112.263875,13.710375 L109.499875,16.460375 L106.735875,13.710375 C106.454875,13.429375 105.993875,13.429375 105.711875,13.710375 C105.429875,13.990375 105.429875,14.449375 105.711875,14.730375 L108.475875,17.480375 Z"
              id="RightArrow"
              transform="translate(109.500375, 16.000000) rotate(-90.000000) translate(-109.500375, -16.000000) "
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

SvgRightArrowIcon.defaultProps = {
  width: 16,
  height: 16,
  color: '#E0E2E6',
};

export default SvgRightArrowIcon;

/**
 * ADD Travelers Action
 */
import _ from 'lodash-es';

import { ACTION_RESET_TRAVELER_SAVE_SUCCEEDED } from '../../configs/ActionTypes';

import { Dispatch } from 'redux';

/**
 * POST Users Request Action
 */
export function resetSaveSuccess() {
  return (dispatch: Dispatch, getState: Function) => {
    dispatch({
      type: ACTION_RESET_TRAVELER_SAVE_SUCCEEDED,
      payload: null,
    });
  };
}

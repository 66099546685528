import {
  ACTION_GET_PLACES,
  ACTION_GET_PLACES_SUCCESS,
  ACTION_GET_PLACES_FAILURE,
  ACTION_EMPTY_PLACES,
} from '../configs/ActionTypes';

import { QUERY_LIMIT } from '../configs/General';

import { Place } from './types';

import _ from 'lodash-es';

const initialState = {
  places: [],
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_GET_PLACES_SUCCESS: {
      let places: Array<Place> = action.payload.payload;

      return {
        ...state,
        places,
      };
    }

    case ACTION_EMPTY_PLACES: {
      return {
        ...state,
        places: [],
      };
    }

    default: {
      return state;
    }
  }
}

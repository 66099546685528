import * as React from 'react';
import _ from 'lodash-es';
import cx from 'classnames';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import CreditCards from './CreditCards';
import AddCreditCard from './AddCreditCard';
import EditCreditCardPopup from 'components/Popup/EditTravelerCreditCardPopup';
import AddCreditCardPopup from 'components/Popup/AddCreditCardPopup';

import SvgCreditCard from '../../static/icons/svgCreditCard';
import SvgCreditCardSilver from '../../static/icons/svgCreditCardSilver';

import Button from '@material-ui/core/Button';

const styles = require('./styles.pcss');
import { hasPermission } from '../../helpers/Permission';

import { CreditCard } from 'reducers/types';

type Props = {
  creditCards: Array<CreditCard>;
  userId?: any;
  onCreditCardsChanged?: any;
  onSaveCreditCard?: any;
};

type State = {
  tabValue: string;
  editCreditCardPopupOpen: boolean;
  addCreditCardPopupOpen: boolean;
  creditCard: any;
};

class PaymentMethods extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      tabValue: 'list',
      editCreditCardPopupOpen: false,
      addCreditCardPopupOpen: false,
      creditCard: [],
    };
  }

  handleTabChange = (event: any, value: any) => {
    this.setState({
      tabValue: value,
    });
  };

  _onNewCardsChanged = () => {
    if (_.get(this.props, 'onCreditCardsChanged', null) !== null) {
      this.props.onCreditCardsChanged();
    }

    this.setState({
      tabValue: 'list',
      addCreditCardPopupOpen: false,
    });
  };

  onClickEditCreditcardPopupOpen = (param: any) => {
    this.setState({
      creditCard: param,
      editCreditCardPopupOpen: true,
    });
  };

  onClickEditCreditCardPopup = () => {
    this.setState({
      editCreditCardPopupOpen: false,
    });
  };

  onClickEditCreditCardPopupClose = () => {
    this.setState({
      editCreditCardPopupOpen: false,
    });
  };

  onClickAddCreditCardPopupClose = () => {
    this.setState({
      addCreditCardPopupOpen: false,
    });
  };

  onClickAddCreditCardPopupOpen = () => {
    this.setState({
      addCreditCardPopupOpen: true,
    });
  };

  renderEditCreditCardPopup() {
    if (this.state.editCreditCardPopupOpen === false) {
      return null;
    }
    return (
      <EditCreditCardPopup
        showEditCreditCardPopup={this.state.editCreditCardPopupOpen}
        closeEditCreditCardPopup={this.onClickEditCreditCardPopupClose}
        creditCard={this.state.creditCard}
        onSuccess={this.handleCrediCardEdit}
      />
    );
  }

  handleCrediCardEdit = () => {
    this.props.onSaveCreditCard();
  };

  renderAddCreditCardPopup() {
    if (this.state.addCreditCardPopupOpen == false) {
      return null;
    }

    return (
      <AddCreditCardPopup
        open={this.state.addCreditCardPopupOpen}
        onClose={this.onClickAddCreditCardPopupClose}
        userId={this.props.userId}
        onSuccess={this._onNewCardsChanged}
      />
    );
  }

  render() {
    const description =
      this.props.creditCards.length < 1
        ? 'Add your payment method to start booking all travel within TravelBank.'
        : 'An overview of your payment methods.';

    return (
      <Paper className={cx(styles.paper)}>
        <div className={styles.avatarAndNameActionButton}>
          <div className={styles.iconAndInfoContainer}>
            <div className={styles.iconContainer}>
              {this.props.creditCards.length < 1 ? (
                <SvgCreditCardSilver
                  width={32}
                  height={32}
                />
              ) : (
                <SvgCreditCard
                  width={32}
                  height={32}
                />
              )}
            </div>
            <div>
              <div className={styles.titleStyle}>Payment Methods</div>
              <div className={styles.subtitleStyle}>
                <span className={styles.boldText}>{description}</span>
              </div>
            </div>
          </div>
          <div>
            {this.props.creditCards.length < 1 && (
              <div className={styles.buttonContainer}>
                <Button
                  size="small"
                  className={styles.editButton}
                  variant="outlined"
                  color="primary"
                  onClick={this.onClickAddCreditCardPopupOpen}
                  id="add-creditCard"
                >
                  Add
                </Button>
              </div>
            )}
          </div>
        </div>

        <CreditCards
          creditCards={this.props.creditCards}
          onCreditCardDeleted={this._onNewCardsChanged}
          deletePermission={hasPermission('creditCards', 'delete')}
          updatePermission={hasPermission('creditCards', 'update')}
          onClickEditCreditcardPopupOpen={this.onClickEditCreditcardPopupOpen}
          onClickEditCreditCardPopupClose={this.onClickEditCreditCardPopup}
        />

        {hasPermission('creditCards', 'create') &&
          this.props.creditCards.length > 0 && (
            <div
              className={cx(
                styles.buttonContainer,
                styles.addPaymentButtonContainer,
              )}
            >
              <Button
                size="small"
                className={cx(styles.button, styles.addPaymentButton)}
                variant="contained"
                color="primary"
                onClick={this.onClickAddCreditCardPopupOpen}
                id="add-creditCard"
              >
                Add
              </Button>
            </div>
          )}
        {this.renderEditCreditCardPopup()}
        {this.renderAddCreditCardPopup()}
      </Paper>
    );
  }
}

export default PaymentMethods;

import {
  ACTION_GET_CREDITCARDS,
  ACTION_GET_CREDITCARDS_SUCCESS,
  ACTION_GET_CREDITCARDS_FAILURE,
  ACTION_RESET_GET_CREDITCARDS,
} from '../configs/ActionTypes';

const initialState = {
  creditCards: [],
  isLoading: false,
  loaded: false,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_GET_CREDITCARDS: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ACTION_RESET_GET_CREDITCARDS: {
      return initialState;
    }

    case ACTION_GET_CREDITCARDS_SUCCESS: {
      return {
        ...state,
        creditCards: action.payload.payload,
        isLoading: false,
        loaded: true,
      };
    }

    case ACTION_GET_CREDITCARDS_FAILURE: {
      return {
        ...state,
        creditCards: [],
        isLoading: false,
        loaded: false,
      };
    }

    default: {
      return state;
    }
  }
}

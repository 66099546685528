/**
 * POST User Action
 */
import _ from 'lodash-es';
import { Dispatch } from 'redux';
import { track, identifyPublic } from '../../analytics/analytics';

import {
  ACTION_POST_LOGIN_SUCCESS,
  ACTION_POST_LOGIN_FAILURE,
  ACTION_LOGOUT_SUCCESS,
  RESET_NO_ACCESS,
  API_CALL,
} from '../../configs/ActionTypes';

import { enqueueSnackbarHandler } from '../Status';

/**
 * POST Users Request Action
 */
export function login(email: string, password: string) {
  return (dispatch: Dispatch, getState: Function) => {
    var dataToSend: any = {
      email: email,
      password: password,
    };

    //Dispatch the api call request.
    dispatch({
      type: API_CALL,
      payload: {
        call: 'login',
        method: 'post',
        data: JSON.stringify(dataToSend),
        success: (response: any) => {
          if (response.type === 'error') {
            enqueueSnackbarHandler(dispatch, response.payload.payload, 'error');
            dispatch(requestFailed(response));
          } else {
            dispatch(requestSuccess(response));
          }
        },
        fail: (error: any) => {
          enqueueSnackbarHandler(dispatch, error.payload, 'error');
          dispatch(requestFailed(error));
        },
      },
    });

    return null;
  };
}

export function logout() {
  return (dispatch: Dispatch, getState: Function) => {
    dispatch({
      type: ACTION_LOGOUT_SUCCESS,
    });
  };
}

export function resetNoAccess() {
  return (dispatch: Dispatch, getState: Function) => {
    dispatch({
      type: RESET_NO_ACCESS,
    });
  };
}

function requestSuccess(responeJSON: object) {
  identifyPublic(
    _.get(responeJSON, 'payload.user.id', null),
    _.get(responeJSON, 'payload.userRole', null),
  );
  track('BB_AccountLogin', { Status: 'success' });

  return {
    type: ACTION_POST_LOGIN_SUCCESS,
    payload: responeJSON,
  };
}

function requestFailed(responeJSON: object) {
  track('BB_AccountLogin', { Status: 'failed' });

  return {
    type: ACTION_POST_LOGIN_FAILURE,
    payload: responeJSON,
  };
}

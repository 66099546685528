import * as React from 'react';
import * as _ from 'lodash-es';
import moment from 'moment';
import classNames from 'classnames';
import { navigate } from '@reach/router';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';

const styles = require('./styles.pcss');

type Props = {
  report: any;
  sync?: any;
  clickSync?: any;
};

const displaySync = (clickSync: any) => {
  return (
    <Button
      color="primary"
      onClick={clickSync}
      className={styles.syncButton}
    >
      Sync
    </Button>
  );
};

const ExpenseReportsTableRow = ({ report, sync, clickSync }: Props) => {
  return (
    <TableRow
      className={classNames(styles.clickable, styles.tableRow)}
      key={report.id}
    >
      <TableCell
        className={classNames(styles.tableRowCell)}
        scope="row"
      >
        {_.get(report, 'title', '')}
      </TableCell>
      <TableCell
        className={classNames(
          styles.tableRowCell,
          styles.tableRowCellBirthDate,
        )}
      >
        {moment(
          _.get(report.scheduledToReimburseDate, report.userId, ''),
        ).isValid() &&
          moment(
            _.get(report.scheduledToReimburseDate, report.userId, ''),
          ).format('MMM DD, YYYY')}
      </TableCell>
      <TableCell className={classNames(styles.tableRowCell)}>
        ${_.get(report, 'amounts.totalSpent', 0).toFixed(2)}
      </TableCell>
      <TableCell className={classNames(styles.tableRowCell)}>
        {_.get(report, 'status', '')}
      </TableCell>
      <TableCell className={classNames(styles.tableRowCell)}>
        {_.get(report, 'syncToGlStatus', '')}
      </TableCell>
      <TableCell
        key={'rem'}
        className={classNames(styles.tableCell)}
      >
        {sync && displaySync(clickSync)}
      </TableCell>
    </TableRow>
  );
};

export default ExpenseReportsTableRow;

import React from 'react';

type Props = {
  width: number;
  height: number;
};

const SvgIconInfo = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Master"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Travel---Hotel-Search-Results"
          transform="translate(-1207.000000, -275.000000)"
        >
          <g
            id="info"
            transform="translate(1207.000000, 275.000000)"
          >
            <circle
              id="Oval-7"
              stroke="#318AFB"
              strokeWidth="1.57894737"
              cx="10"
              cy="10"
              r="9.21052632"
            ></circle>
            <path
              d="M10,10 L10,15.2631579"
              id="Line-2"
              stroke="#318AFB"
              strokeWidth="2.10526316"
              strokeLinecap="round"
            ></path>
            <circle
              id="Oval-2"
              fill="#318AFB"
              cx="10"
              cy="6.31578947"
              r="1.05263158"
            ></circle>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgIconInfo;

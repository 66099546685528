/**
 * GET Travelers Action
 */
import _ from 'lodash-es';
import { track } from '../../analytics/analytics';

import {
  ACTION_UPDATE_TRAVELER,
  ACTION_UPDATE_TRAVELER_SUCCESS,
  ACTION_UPDATE_TRAVELER_FAILURE,
  API_CALL,
} from '../../configs/ActionTypes';

import { Dispatch } from 'redux';

import { enqueueSnackbarHandler } from '../Status';

/**
 * POST Users Request Action
 */
export function update(traveler: any) {
  return (dispatch: Dispatch, getState: Function) => {
    const { travelerId, ...travelerData } = traveler;

    dispatch(requestStarted());

    dispatch({
      type: API_CALL,
      payload: {
        call: `traveler/${travelerId}`,
        method: 'put',
        data: JSON.stringify(travelerData),
        success: (response: any) => {
          if (response.type === 'error') {
            dispatch(requestFailed(response));
          } else {
            enqueueSnackbarHandler(
              dispatch,
              { updateTravelerSuccess: true },
              'success',
            );
            dispatch(requestSuccess(response));
          }
        },
        fail: (error: any) => {
          dispatch(requestFailed(error));
        },
      },
    });

    return null;
  };
}

export function softDeleteTraveler(travelerId: any) {
  return (dispatch: Dispatch, getState: Function) => {
    dispatch(requestStarted());

    dispatch({
      type: API_CALL,
      payload: {
        call: `traveler/${travelerId}`,
        method: 'delete',
        data: {},
        success: (response: any) => {
          if (response.type === 'error') {
            dispatch(requestFailed(response));
          } else {
            enqueueSnackbarHandler(
              dispatch,
              { deleteTravelerSuccess: true },
              'success',
            );
            dispatch(requestSuccess(response));
          }
        },
        fail: (error: any) => {
          dispatch(requestFailed(error));
        },
      },
    });

    return null;
  };
}
export function requestStarted() {
  return {
    type: ACTION_UPDATE_TRAVELER,
    payload: null,
  };
}

export function requestSuccess(responseJSON: object) {
  track('BB_TravelerUpdated', {});
  return {
    type: ACTION_UPDATE_TRAVELER_SUCCESS,
    payload: responseJSON,
  };
}

export function requestFailed(responseJSON: object) {
  return {
    type: ACTION_UPDATE_TRAVELER_FAILURE,
    payload: responseJSON,
  };
}

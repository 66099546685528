import React from 'react';

type Props = {
  width: number;
  height: number;
};

const SvgCheckboxChecked = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Group</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Master"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Travel---Hotel-Search-Results---Rating-Filter"
          transform="translate(-382.000000, -328.000000)"
        >
          <g
            id="rating---filter"
            transform="translate(358.000000, 243.000000)"
          >
            <g
              id="Group-2"
              transform="translate(24.000000, 84.000000)"
            >
              <g
                id="Group"
                transform="translate(0.000000, 1.000000)"
              >
                <rect
                  id="Rectangle-13"
                  fill="#318AFB"
                  x="0"
                  y="0"
                  width="16"
                  height="16"
                ></rect>
                <polyline
                  id="Path-2"
                  stroke="#FFFFFF"
                  strokeWidth="2"
                  stroke-linecap="round"
                  points="4 9.12902425 6.85654807 12 13 5"
                ></polyline>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

SvgCheckboxChecked.defaultProps = {
  width: 16,
  height: 16,
};

export default SvgCheckboxChecked;

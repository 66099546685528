import * as React from 'react';
import _ from 'lodash-es';
import cx from 'classnames';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Loader from 'components/Loader/Loader';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import HotelTableRow from './HotelTableRow';
import SvgAddIcon from '../../../static/icons/svgAddIcon';
import ConfirmationPopup from 'components/Popup/Confirmation';
import SvgIconHotel from '../../../static/icons/svgIconHotel';
import AddExclusiveHotelPopup from 'components/Popup/AddExclusiveHotelPopup';
import { hasPermission } from '../../../helpers/Permission';
import { deleteHotel } from 'actions/ManageTB/Hotels/Delete';
import { bindActionCreators, Dispatch } from 'redux';

import { connect } from 'react-redux';

const styles = require('./styles.pcss');

import { ReduxState } from 'reducers/types';

type Props = {
  exclusiveHotels: any;
  deleteHotel: Function;
};

type State = {
  addNewOpen: boolean;
  openConfirmation: boolean;
  hotelToDelete: any;
};

class Hotels extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      addNewOpen: false,
      openConfirmation: false,
      hotelToDelete: false,
    };
  }

  onClickAddHotel = () => {
    this.setState({
      addNewOpen: true,
    });
  };

  onClickCancelAddHotel = () => {
    this.setState({
      addNewOpen: false,
    });
  };

  onClickRemove = (id: any) => {
    this.setState({
      hotelToDelete: id,
      openConfirmation: true,
    });
  };

  closeConfirmation = () => {
    this.setState({
      openConfirmation: false,
    });
  };

  renderLoader = () => {
    if (!_.get(this.props, 'exclusiveHotels.isLoading', false)) {
      return null;
    }

    return (
      <div className={styles.loaderContainer}>
        <Loader visible={true} />
      </div>
    );
  };

  renderTabContent = () => {
    let sortHotelList = _.get(
      this.props.exclusiveHotels,
      'exclusiveHotels',
      [],
    );
    sortHotelList = sortHotelList
      ? sortHotelList.sort((a: any, b: any) => a.name.localeCompare(b.name))
      : [];

    return (
      <>
        <Table className={styles.hotelTable}>
          <TableBody>
            {sortHotelList.map((hotel: any) => {
              return (
                <HotelTableRow
                  clickRemove={this.onClickRemove.bind(this, hotel.id)}
                  showRemove={
                    !this.state.addNewOpen &&
                    hasPermission('inventory', 'delete')
                  }
                  key={hotel.id}
                  rowColumns={[hotel.name, hotel.identifier]}
                />
              );
            })}
          </TableBody>
        </Table>
      </>
    );
  };

  renderNoHotels = () => {
    if (_.get(this.props.exclusiveHotels, 'exclusiveHotels', []).length < 1) {
      return <div className={styles.emptyData}>No Exclusive Hotels</div>;
    }

    return null;
  };

  onSubmitDelete = () => {
    this.props.deleteHotel(this.state.hotelToDelete);

    this.setState({
      openConfirmation: false,
    });
  };

  render() {
    return (
      <Paper className={styles.paper}>
        <div className={styles.iconAndInfoContainer}>
          <div className={styles.Item1}>
            <div className={styles.iconContainer}>
              <SvgIconHotel
                width={32}
                height={32}
              />
            </div>
            <div>
              <div className={styles.titleStyle}>
                TravelBank Exclusive Hotels
              </div>
              <div className={styles.subtitleStyle}>
                List of hotels to be tagged as Exclusive for TravelBank users.
              </div>
            </div>
          </div>
          {hasPermission('inventory', 'create') && (
            <div className={styles.Item2}>
              <div
                className={styles.addHotel}
                onClick={this.onClickAddHotel}
              >
                <div className={styles.addIcon}>
                  <SvgAddIcon
                    width={18}
                    height={18}
                  />
                </div>
                <div className={styles.addHotelButtonLabel}>Add Hotel</div>
              </div>
            </div>
          )}
        </div>
        {this.renderLoader()}
        {this.renderTabContent()}
        {this.renderNoHotels()}

        <AddExclusiveHotelPopup
          open={this.state.addNewOpen}
          onCancel={this.onClickCancelAddHotel}
        />
        <ConfirmationPopup
          open={this.state.openConfirmation}
          onClose={this.closeConfirmation}
          onSubmit={this.onSubmitDelete}
          title="Remove Hotel?"
          bodyText="Please confirm that you want to remove this hotel."
          submitButtonText="Delete"
          closeButtonText="Cancel"
          disableX={true}
        />
      </Paper>
    );
  }
}

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {
    exclusiveHotels: state.exclusiveHotels,
  };
}

// Wire up redux dispatch functions
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      deleteHotel,
    },
    dispatch,
  );
}

// Connect it to Redux
export default connect(mapStateToProps, mapDispatchToProps)(Hotels);

import {
  ACTION_PUT_USER_EXPENSE_REPORTS,
  ACTION_PUT_USER_EXPENSE_REPORTS_SUCCESS,
  ACTION_PUT_USER_EXPENSE_REPORTS_FAILURE,
  API_CALL,
} from '../../configs/ActionTypes';

import { Dispatch } from 'redux';
import _ from 'lodash-es';
import { enqueueSnackbarHandler } from '../Status';

export function syncExpenseReport(reportId: string) {
  return (dispatch: Dispatch, getState: Function) => {
    //Dispatch the api call request.
    dispatch(requestStarted());

    dispatch({
      type: API_CALL,
      payload: {
        call: `user/sync-expense-report/${reportId}`,
        method: 'post',
        success: (response: any) => {
          if (response.type === 'error') {
            _.get(response.payload, 'payload.userPermissionError', false)
              ? enqueueSnackbarHandler(
                  dispatch,
                  response.payload.payload,
                  'error',
                )
              : enqueueSnackbarHandler(
                  dispatch,
                  { syncExpenseReportFailed: true },
                  'error',
                );

            dispatch(requestFailed(response));
          } else {
            enqueueSnackbarHandler(
              dispatch,
              { syncExpenseReportSuccess: true },
              'success',
            );
            dispatch(requestSuccess(response));
          }
        },
        fail: (error: any) => {
          if (error.payload) {
            enqueueSnackbarHandler(dispatch, error.payload, 'error');
          } else {
            enqueueSnackbarHandler(
              dispatch,
              { syncExpenseReportFailed: true },
              'error',
            );
          }
          dispatch(requestFailed(error));
        },
      },
    });

    return null;
  };
}

export function requestStarted() {
  return {
    type: ACTION_PUT_USER_EXPENSE_REPORTS,
    payload: null,
  };
}

export function requestSuccess(responseJSON: object) {
  return {
    type: ACTION_PUT_USER_EXPENSE_REPORTS_SUCCESS,
    payload: responseJSON,
  };
}

export function requestFailed(responseJSON: object) {
  return {
    type: ACTION_PUT_USER_EXPENSE_REPORTS_FAILURE,
    payload: responseJSON,
  };
}

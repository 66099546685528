export const MuiBlackBoxTheme = {
  palette: {
    primary: { main: '#318afb' },
    secondary: { main: '#fff' },
  },

  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },

  overrides: {
    MuiFormControl: {
      root: {
        borderBottomColor: '#E0E2E6',
        borderTopColor: '#E0E2E6',
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: 15,
        '&$focused': {
          color: '#6D717A',
        },
      },
      '&$disabled': {
        color: '#6D717A !important',
      },
    },
    MuiInputBase: {
      root: {
        fontSize: 15,
        height: 52,
      },
      input: {
        display: 'flex',
      },
    },
    MuiInput: {
      // Name of the styleSheet

      underline: {
        height: 'auto',
        '&:before': {
          borderBottom: 'none',
        },
        '&:after': {
          borderBottom: 'none',
        },
        '&:focus': {
          borderBottom: 'none',
        },
        '&:hover:not($&$disabled):not($focused):not($error):before': {
          borderBottom: 'none',
          background: 'transparent',
        },
        '&:hover:not($&$disabled):not($focused):not($error):after': {
          borderBottom: 'none',
          background: 'transparent',
        },
      },
    },
    MuiSelect: {
      root: {
        fontSize: 17,
        fontWeight: 500,
      },
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
          background: 'transparent',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderColor: '#C6CBCC',
        },
        '&$focused $notchedOutline': {
          borderWidth: 1,
        },
        '&:hover:not($&$disabled):not($focused):not($error) $notchedOutline': {
          border: '1px solid',
          borderColor: '#6D717A',
        },
      },
      input: {
        padding: 16,
      },
      '&$disabled': {
        color: '#6D717A',
        backgroundColor: '#F6F6F7',
      },
    },
    MuiInputLabel: {
      outlined: {
        'z-index': 'unset',
        transform: 'translate(16px, 19px) scale(1)',
      },
    },
    MuiButton: {
      root: {
        fontSize: 15,
        borderRadius: 4,
        disableRipple: true,
        fontWeight: 500,
        padding: 16,
        height: 52,
        textTransform: 'initial',
        lineHeight: 'normal',
      },

      sizeSmall: {
        height: 32,
      },

      flatPrimary: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
        disableRipple: true,
      },

      containedPrimary: {
        fontWeight: 400,
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: '#1261C6',
        },
        '&:active': {
          boxShadow: 'none',
          backgroundColor: '#1351A2',
        },
      },
      containedSecondary: {
        fontWeight: 400,
        boxShadow: 'none',
      },
    },
    MuiCardHeader: {
      root: {
        paddingLeft: '16px !important',
        paddingRight: '16px !important',
        backgroundColor: '#1351A2',
      },
      title: {
        fontSize: 17,
        color: '#fff',
      },
      action: {
        marginRight: '0px !important',
        marginLeft: 0,
        marginTop: 0,
        marginBottom: 0,
      },
    },
    MuiCardContent: {
      root: {
        paddingLeft: '16px !important',
        paddingRight: '16px !important',
        fontSize: 13,
        color: '#5F6670',
      },
    },
    MuiCardActions: {
      root: {
        padding: '0 0 16px !important',
      },
    },
    MuiTabs: {
      root: {
        fontSize: 15,
      },
    },
    MuiTab: {
      root: {
        color: '#A4A7AD !important',
        fontSize: '15px !important',
        fontWeight: 400,
        '&:hover': {
          color: '#C6C8CC !important',
        },
      },

      selected: {
        color: '#1B2432 !important',
        fontWeight: 500,
        '&:hover': {
          color: '#1B2432 !important',
        },
      },
    },
    MuiFormHelperText: {
      error: {
        color: '#f44336',
        marginRight: '0 !important',
        marginTop: '4px !important',
      },
      root: {
        textAlign: 'right',
      },
    },
    MuiTableCell: {
      root: {
        padding: '4px 24px',
        minWidth: 120,
      },
    },
    MuiCheckbox: {
      root: {
        color: '#C6C8CC !important',
      },
      checked: {
        color: '#318afb !important',
      },
    },
    MuiRadio: {
      checked: {
        color: '#318afb !important',
      },
    },
    MuiListItem: {
      root: {
        color: '#6D717A !important',
        fontSize: 15,
        paddingTop: 6,
        paddingBottom: 6,
        '&:hover': {
          backgroundColor: 'rgba(95, 102, 112, 0.4) !important',
        },
      },
      button: {
        '&:hover': {
          backgroundColor: '#f6f6f7 !important',
        },
      },
      selected: {
        backgroundColor: 'transparent !important',
      },
    },
    MuiPopover: {
      paper: {
        border: '1px solid #DDDEE0',
        boxShadow: '0 2px 4px 0 rgba(221, 222, 224, 0.5)',
      },
    },
    MuiPaper: {
      elevation2: {
        border: '1px solid #DDDEE0',
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.05)',
      },
    },
    MuiFormControlLabel: {
      label: {
        fontWeight: 500,
      },
    },
    MuiChip: {
      deleteIcon: {
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 4,
        marginRight: 0,
        width: 20,
        padding: 4,
      },
    },
  },
};

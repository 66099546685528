import {
  ACTION_GET_VERIFIED_FOR_TRAVEL_SETTINGS,
  ACTION_GET_VERIFIED_FOR_TRAVEL_SETTINGS_SUCCESS,
  ACTION_GET_VERIFIED_FOR_TRAVEL_SETTINGS_FAILURE,
  ACTION_PUT_VERIFIED_FOR_TRAVEL_SETTINGS,
  ACTION_PUT_VERIFIED_FOR_TRAVEL_SETTINGS_SUCCESS,
  ACTION_PUT_VERIFIED_FOR_TRAVEL_SETTINGS_FAILURE,
  ACTION_DELETE_VERIFIED_FOR_TRAVEL_SETTINGS,
  ACTION_DELETE_VERIFIED_FOR_TRAVEL_SETTINGS_SUCCESS,
  ACTION_DELETE_VERIFIED_FOR_TRAVEL_SETTINGS_FAILURE,
} from '../configs/ActionTypes';

import _ from 'lodash-es';

const initialState = {
  feature: [],
  isLoading: false,
  success: null,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_DELETE_VERIFIED_FOR_TRAVEL_SETTINGS:
    case ACTION_GET_VERIFIED_FOR_TRAVEL_SETTINGS:
    case ACTION_PUT_VERIFIED_FOR_TRAVEL_SETTINGS: {
      return {
        ...state,
        isLoading: true,
        success: null,
      };
    }

    case ACTION_GET_VERIFIED_FOR_TRAVEL_SETTINGS_SUCCESS:
    case ACTION_PUT_VERIFIED_FOR_TRAVEL_SETTINGS_SUCCESS: {
      return {
        ...state,
        feature: action.payload.payload,
        isLoading: false,
        success: true,
      };
    }

    case ACTION_DELETE_VERIFIED_FOR_TRAVEL_SETTINGS_SUCCESS: {
      return {
        ...state,
        feature: [],
        isLoading: false,
        success: true,
      };
    }

    case ACTION_GET_VERIFIED_FOR_TRAVEL_SETTINGS_FAILURE:
    case ACTION_PUT_VERIFIED_FOR_TRAVEL_SETTINGS_FAILURE:
    case ACTION_DELETE_VERIFIED_FOR_TRAVEL_SETTINGS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        success: false,
      };
    }

    default: {
      return state;
    }
  }
}

/**
 * POST BB_USER
 */
import _ from 'lodash-es';
import { Dispatch } from 'redux';

import {
  ACTION_UPDATE_BB_USER,
  ACTION_UPDATE_BB_USER_SUCCESS,
  ACTION_UPDATE_BB_USER_FAILURE,
  ACTION_RESET_UPDATE_BB_USER,
  API_CALL,
} from '../../../configs/ActionTypes';

import { enqueueSnackbarHandler } from '../../Status';

/**
 * POST Users Request Action
 */
export function update(dataToSend: any) {
  return (dispatch: Dispatch, getState: Function) => {
    dispatch({
      type: ACTION_UPDATE_BB_USER,
      payload: null,
    });

    //Dispatch the api call request.
    dispatch({
      type: API_CALL,
      payload: {
        call: 'bb-user',
        method: 'put',
        data: JSON.stringify(dataToSend),
        success: (response: object) => {
          dispatch(requestSuccess(response));
          enqueueSnackbarHandler(
            dispatch,
            { updateBBuserSuccess: true },
            'success',
          );
        },
        fail: (error: any) => {
          _.get(error.payload, 'userPermissionError', false)
            ? enqueueSnackbarHandler(dispatch, error.payload, 'error')
            : enqueueSnackbarHandler(
                dispatch,
                { updateBBuserFailed: true },
                'error',
              );
          dispatch(requestFailed(error));
        },
      },
    });

    return null;
  };
}

export function updateStatus(dataToSend: any) {
  return (dispatch: Dispatch, getState: Function) => {
    dispatch({
      type: ACTION_UPDATE_BB_USER,
      payload: null,
    });

    //Dispatch the api call request.
    dispatch({
      type: API_CALL,
      payload: {
        call: 'bb-user-status',
        method: 'put',
        data: JSON.stringify(dataToSend),
        success: (response: object) => {
          dispatch(requestSuccess(response));
          enqueueSnackbarHandler(
            dispatch,
            { updateBBuserSuccess: true },
            'success',
          );
        },
        fail: (error: any) => {
          enqueueSnackbarHandler(
            dispatch,
            { updateBBuserFailed: true },
            'error',
          );
          dispatch(requestFailed(error));
        },
      },
    });

    return null;
  };
}

export function resetUpdateUser() {
  return (dispatch: Dispatch, getState: Function) => {
    // Dispatch the api call request.
    dispatch({
      type: ACTION_RESET_UPDATE_BB_USER,
      payload: null,
    });
  };
}

function requestSuccess(responeJSON: object) {
  return {
    type: ACTION_UPDATE_BB_USER_SUCCESS,
    payload: responeJSON,
  };
}

function requestFailed(responeJSON: object) {
  return {
    type: ACTION_UPDATE_BB_USER_FAILURE,
    payload: responeJSON,
  };
}

import React from 'react';

type Props = {
  width: number;
  height: number;
};

const SvgIconRewards = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      version="1.0"
      viewBox="0 0 50 50"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
    >
      <defs>
        <filter
          x="-33.3%"
          y="-23.5%"
          width="166.7%"
          height="147.1%"
          filterUnits="objectBoundingBox"
          id="filter-1"
        >
          <feOffset
            dx="0"
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            stdDeviation="1.5"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 1   0 0 0 0 0.724915286   0 0 0 0 0.398285061  0 0 0 0.650404283 0"
            type="matrix"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          ></feColorMatrix>
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
        </filter>
        <rect
          id="path-2"
          x="0"
          y="-1.57898386e-13"
          width="4"
          height="8"
        ></rect>
      </defs>
      <g
        id="Master"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="User-Macro---Account"
          transform="translate(-1078.000000, -320.000000)"
        >
          <g
            id="Rewards"
            transform="translate(1046.000000, 244.000000)"
          >
            <g
              id="Points"
              transform="translate(32.000000, 76.000000)"
            >
              <g id="RewardsIcon">
                <circle
                  id="Oval"
                  fill="#FFEFDA"
                  cx="25"
                  cy="25"
                  r="25"
                ></circle>
                <g
                  id="award"
                  filter="url(#filter-1)"
                  transform="translate(13.333333, 10.750000)"
                >
                  <path
                    d="M21.6440364,8 L19.3803072,8 L17.7732619,9.47228471 L21.6440364,9.47228471 C22.0773947,9.47228471 22.4293576,9.80220683 22.4293576,10.2084271 L22.4293576,11.3298939 C22.4293576,12.8136809 21.88495,14.2687123 20.8955272,15.4275613 C20.0511024,16.4175194 18.9269069,17.1521282 17.6446246,17.5525973 L16.537199,17.8984308 C16.1261325,18.027064 15.9036248,18.4440197 16.040238,18.830111 C16.1498557,19.1385623 16.4566218,19.3333333 16.7856796,19.3333333 C16.8676884,19.3333333 16.9513333,19.3210643 17.0341601,19.2959511 L18.1415857,18.9493507 C19.7114101,18.4591643 21.0879717,17.5591152 22.1225915,16.3477393 C23.332886,14.9287482 24,13.1464786 24,11.3298939 L24,10.2084271 C24,8.99072492 22.9430886,8 21.6440364,8 Z"
                    id="Path"
                    fill="#FF7816"
                    fillRule="nonzero"
                  ></path>
                  <path
                    d="M7.46280096,17.8984308 L6.35537536,17.5533641 C5.07309313,17.1521282 3.94889762,16.4175194 3.10447284,15.4275613 C2.11504997,14.2687123 1.57064239,12.8136809 1.57064239,11.3298939 L1.57064239,10.2084271 C1.57064239,9.80220683 1.92260533,9.47228471 2.35596358,9.47228471 L6.41427445,9.47228471 L4.6196928,8 L2.35596358,8 C1.05691144,8 0,8.99072492 0,10.2084271 L0,11.3298939 C0,13.1464786 0.667114018,14.9287482 1.87740848,16.3477393 C2.91202826,17.5591152 4.28858992,18.4591643 5.85841432,18.9501175 L6.96583987,19.2959511 C7.0486667,19.3210643 7.13231162,19.3333333 7.21432041,19.3333333 C7.54337816,19.3333333 7.85014425,19.1385623 7.959762,18.830111 C8.09637517,18.4440197 7.8738675,18.027064 7.46280096,17.8984308 Z"
                    id="Path"
                    fill="#FF9F00"
                    fillRule="nonzero"
                  ></path>
                  <path
                    d="M14.1930768,24 C14.1930768,24.4265873 14.5076239,24.7619048 14.9082105,24.7619048 C16.0954443,24.7619048 17.0536118,25.7827381 17.0536118,27.047619 L17.0536118,28.5714286 C17.0536118,28.9980159 16.7390647,29.3333333 16.338478,29.3333333 L7.66152198,29.3333333 C7.26093533,29.3333333 6.94638824,28.9980159 6.94638824,28.5714286 L6.94638824,27.047619 C6.94638824,25.7827381 7.9045557,24.7619048 9.09178946,24.7619048 C9.49237611,24.7619048 9.8069232,24.4265873 9.8069232,24 L9.8069232,22.8724206 C7.14658845,21.9732143 5.20157367,19.4742064 4.88684031,16.5027778 L4,8 L20,8 L19.1131597,16.5027778 C18.7984263,19.4742064 16.8534115,21.9732143 14.1930768,22.8724206 L14.1930768,24 Z"
                    id="Path"
                    fill="#FFD400"
                    fillRule="nonzero"
                  ></path>
                  <path
                    d="M16.7000179,29.3333333 L12,29.3333333 L12,8 L20.6666667,8 L19.705923,16.5027778 C19.3649619,19.4742064 17.2578625,21.9732143 14.3758332,22.8724206 L14.3758332,24 C14.3758332,24.4265873 14.7165926,24.7619048 15.1505614,24.7619048 C16.4367313,24.7619048 17.4747461,25.7827381 17.4747461,27.047619 L17.4747461,28.5714286 C17.4747461,28.9980159 17.1339867,29.3333333 16.7000179,29.3333333 Z"
                    id="Path"
                    fill="#FF9F00"
                    fillRule="nonzero"
                  ></path>
                  <path
                    d="M18,28.0894714 L18,32.6666667 L5.33333333,32.6666667 L5.33333333,28.0894714 C5.33333333,27.6659159 5.64057712,27.3333333 6.03186275,27.3333333 L17.3014706,27.3333333 C17.6927562,27.3333333 18,27.6659159 18,28.0894714 Z"
                    id="Path"
                    fill="#EC5569"
                    fillRule="nonzero"
                  ></path>
                  <g
                    id="Group-15"
                    transform="translate(8.000000, 11.333333)"
                  >
                    <path
                      d="M5.75265235,7.59705295 L4.00686106,6.62567812 L4.00686106,6.62567812 L2.2618139,7.59663891 C2.06877043,7.70405013 1.82520378,7.63463147 1.71779257,7.441588 C1.67379695,7.36251743 1.65791071,7.27086597 1.67272822,7.18160114 L2.01715266,5.10669078 L2.01715266,5.10669078 L0.548008985,3.61052849 C0.39322901,3.452902 0.395536567,3.19964653 0.553163057,3.04486655 C0.613144693,2.9859681 0.690158078,2.94746428 0.773266576,2.93482301 L2.75814751,2.63291139 L2.75814751,2.63291139 L3.63097829,0.772100007 C3.72479234,0.572095318 3.96297949,0.486010795 4.16298418,0.579824843 C4.24756777,0.619499558 4.3155955,0.687530192 4.3552666,0.772115478 L5.22799314,2.63291139 L5.22799314,2.63291139 L7.22582141,2.93527304 C7.44424791,2.96833084 7.59451893,3.17219913 7.56146114,3.39062563 C7.54886398,3.47386023 7.51032761,3.55099878 7.45133919,3.61105755 L5.99656947,5.09222423 L5.99656947,5.09222423 L6.34179056,7.18233238 C6.37779093,7.40029321 6.23028284,7.60616945 6.01232201,7.64216982 C5.92315407,7.6568976 5.83162657,7.64099495 5.75265235,7.59705295 Z"
                      id="Path"
                      fill="#FF9F00"
                    ></path>
                    <g
                      id="Group-14"
                      transform="translate(4.000000, 0.000000)"
                    >
                      <mask
                        id="mask-3"
                        fill="white"
                      >
                        <use xlinkHref="#path-2"></use>
                      </mask>
                      <g id="Rectangle"></g>
                      <path
                        d="M1.75265235,7.59705295 L0.00686106346,6.62567812 L0.00686106346,6.62567812 L-1.7381861,7.59663891 C-1.93122957,7.70405013 -2.17479622,7.63463147 -2.28220743,7.441588 C-2.32620305,7.36251743 -2.34208929,7.27086597 -2.32727178,7.18160114 L-1.98284734,5.10669078 L-1.98284734,5.10669078 L-3.45199101,3.61052849 C-3.60677099,3.452902 -3.60446343,3.19964653 -3.44683694,3.04486655 C-3.38685531,2.9859681 -3.30984192,2.94746428 -3.22673342,2.93482301 L-1.24185249,2.63291139 L-1.24185249,2.63291139 L-0.369021708,0.772100007 C-0.27520766,0.572095318 -0.0370205125,0.486010795 0.162984176,0.579824843 C0.247567767,0.619499558 0.315595495,0.687530192 0.355266597,0.772115478 L1.22799314,2.63291139 L1.22799314,2.63291139 L3.22582141,2.93527304 C3.44424791,2.96833084 3.59451893,3.17219913 3.56146114,3.39062563 C3.54886398,3.47386023 3.51032761,3.55099878 3.45133919,3.61105755 L1.99656947,5.09222423 L1.99656947,5.09222423 L2.34179056,7.18233238 C2.37779093,7.40029321 2.23028284,7.60616945 2.01232201,7.64216982 C1.92315407,7.6568976 1.83162657,7.64099495 1.75265235,7.59705295 Z"
                        id="Path"
                        fill="#FF7816"
                        mask="url(#mask-3)"
                      ></path>
                    </g>
                  </g>
                  <path
                    d="M18,28.0894714 L18,32.6666667 L12,32.6666667 L12,27.3333333 L17.3382353,27.3333333 C17.7089269,27.3333333 18,27.6659159 18,28.0894714 Z"
                    id="Path"
                    fill="#CC2E43"
                    fillRule="nonzero"
                  ></path>
                  <path
                    d="M20,33 C20,33.186632 19.6820407,33.3333333 19.2771084,33.3333333 L4.72289157,33.3333333 C4.31795933,33.3333333 4,33.186632 4,33 C4,32.8132812 4.31795933,32.6666667 4.72289157,32.6666667 L19.2771084,32.6666667 C19.6820407,32.6666667 20,32.8132812 20,33 Z"
                    id="Path"
                    fill="#697C86"
                    fillRule="nonzero"
                  ></path>
                  <path
                    d="M20,33 C20,33.186632 19.6820407,33.3333333 19.2771084,33.3333333 L12,33.3333333 L12,32.6666667 L19.2771084,32.6666667 C19.6820407,32.6666667 20,32.8132812 20,33 Z"
                    id="Path"
                    fill="#465A61"
                    fillRule="nonzero"
                  ></path>
                  <rect
                    id="Rectangle"
                    fill="#FFE27D"
                    x="11.3333333"
                    y="-5.70531277e-14"
                    width="1.33333333"
                    height="5.33333333"
                    rx="0.666666667"
                  ></rect>
                  <path
                    d="M20.0735305,1.5105057 C20.4251045,1.51097448 20.7099949,1.79586425 20.7104644,2.14743824 L20.7155506,5.95610989 C20.7160185,6.30647331 20.4323721,6.59087827 20.0820087,6.59134615 C20.0814443,6.59134691 20.08088,6.59134691 20.0803156,6.59134615 C19.7287416,6.59087737 19.4438513,6.3059876 19.4433818,5.95441362 L19.4382956,2.14574196 C19.4378277,1.79537855 19.7214741,1.51097359 20.0718375,1.5105057 C20.0724018,1.51050495 20.0729662,1.51050495 20.0735305,1.5105057 Z"
                    id="Rectangle"
                    fill="#F4B44D"
                    transform="translate(20.076923, 4.050926) rotate(60.000000) translate(-20.076923, -4.050926) "
                  ></path>
                  <path
                    d="M3.45814591,1.5105057 C3.80971989,1.51097448 4.09461026,1.79586425 4.09507976,2.14743824 L4.10016597,5.95610989 C4.10063385,6.30647331 3.81698748,6.59087827 3.46662407,6.59134615 C3.46605972,6.59134691 3.46549536,6.59134691 3.46493101,6.59134615 C3.11335703,6.59087737 2.82846667,6.3059876 2.82799716,5.95441362 L2.82291096,2.14574196 C2.82244307,1.79537855 3.10608944,1.51097359 3.45645285,1.5105057 C3.45701721,1.51050495 3.45758156,1.51050495 3.45814591,1.5105057 Z"
                    id="Rectangle"
                    fill="#FFD89A"
                    transform="translate(3.461538, 4.050926) scale(-1, 1) rotate(60.000000) translate(-3.461538, -4.050926) "
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgIconRewards;

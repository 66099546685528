import {
  ACTION_PUT_CANCEL_BOOKING,
  ACTION_PUT_CANCEL_BOOKING_SUCCESS,
  ACTION_PUT_CANCEL_BOOKING_FAILURE,
  ACTION_EMPTY_PUT_CANCEL_BOOKING,
  API_CALL,
} from '../../configs/ActionTypes';

import { Dispatch } from 'redux';
import { enqueueSnackbarHandler } from '../Status';

export function cancelFlightBooking(bookingId: any) {
  return (dispatch: Dispatch, getState: Function) => {
    dispatch(requestStarted());

    //Dispatch the api call request.
    dispatch({
      type: API_CALL,
      payload: {
        call: `bookings/flight/cancel`,
        method: 'put',
        data: JSON.stringify(bookingId) || {},
        success: (response: any) => {
          if (response.type === 'error') {
            dispatch(requestFailed(response));
            enqueueSnackbarHandler(dispatch, { cancelFailed: true }, 'error');
          } else {
            dispatch(requestSuccess(response));
            enqueueSnackbarHandler(
              dispatch,
              { cancelBooking: true },
              'success',
            );
          }
        },
        fail: (error: any) => {
          dispatch(requestFailed(error));
          enqueueSnackbarHandler(dispatch, { cancelFailed: true }, 'error');
        },
      },
    });

    return null;
  };
}

export function requestStarted() {
  return {
    type: ACTION_PUT_CANCEL_BOOKING,
    payload: null,
  };
}

export function requestSuccess(responseJSON: any) {
  return {
    type: ACTION_PUT_CANCEL_BOOKING_SUCCESS,
    payload: responseJSON,
  };
}

export function requestFailed(responseJSON: any) {
  return {
    type: ACTION_PUT_CANCEL_BOOKING_FAILURE,
    payload: responseJSON,
  };
}

export function emptyCancelResults() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ACTION_EMPTY_PUT_CANCEL_BOOKING,
      payload: null,
    });
  };
}

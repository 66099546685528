import * as React from 'react';
import cx from 'classnames';
import CancelIcon from '@material-ui/icons/Cancel';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

const styles = require('./styles.pcss');

type Props = {
  label: string;
  onChange: any;
  variant: any;
  margin: any;
  value: any;
  onClear: any;
  disabled?: any;
  error?: any;
  helperText?: any;
  fullWidth?: any;
  className?: any;
  id?: any;
  onKeyUp?: any;
  startAdornment?: any;
  icon?: any;
  onFocus?: any;
  onBlur?: any;
  endText?: string;
  type?: any;
  placeHolder?: string;
};

const renderStartAdornment = (props: Props) => {
  if (props.startAdornment && props.icon) {
    return <InputAdornment position="start">{props.icon}</InputAdornment>;
  } else if (props.startAdornment) {
    return (
      <InputAdornment
        className={styles.startAdornment}
        position="start"
      >
        {props.startAdornment}
      </InputAdornment>
    );
  }

  return null;
};

const TextFieldWithClear = (props: Props) => {
  let className = styles.textFieldClear;

  if (props.className) {
    className = cx(styles.textFieldClear, props.className);
  }

  return (
    <TextField
      disabled={props.disabled}
      type={props.type ? props.type : 'text'}
      onChange={props.onChange}
      error={props.error}
      helperText={props.helperText}
      label={props.label}
      variant={props.variant}
      margin={props.margin}
      value={props.value}
      fullWidth={props.fullWidth}
      className={className}
      onKeyUp={props.onKeyUp}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      placeholder={props.placeHolder}
      id={props.id}
      InputProps={{
        endAdornment:
          props.value &&
          props.value.toString().trim() !== '' &&
          !props.disabled ? (
            <InputAdornment
              className={styles.close}
              position="end"
              onClick={props.onClear}
              disablePointerEvents={props.disabled}
            >
              <CancelIcon className={styles.icon} />
            </InputAdornment>
          ) : (
            Boolean(props.endText) && (
              <InputAdornment
                className={styles.endText}
                position="end"
              >
                {props.endText}
              </InputAdornment>
            )
          ),
        startAdornment: renderStartAdornment(props),
      }}
    />
  );
};

export default TextFieldWithClear;

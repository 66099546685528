import * as React from 'react';
import _ from 'lodash-es';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import SvgIconVisa from '../../../static/icons/svgIconVisa';
import SvgIconMasterCard from '../../../static/icons/svgIconMasterCard';
import SvgIconAmex from '../../../static/icons/svgIconAMEX';
import SvgIconDiscover from '../../../static/icons/svgIconDiscover';
import SvgIconApproved from '../../../static/icons/svgIconApproved';
import SvgIconOtherCardType from '../../../static/icons/svgIconOtherCardType';
import SvgIconDinersClub from '../../../static/icons/svgIconDinersClub';
import SvgIconBrex from '../../../static/icons/svgIconBrex';
import { cardType } from '../../../helpers/creditCardType';

import { connect } from 'react-redux';
import { CreditCard, ReduxState } from '../../../reducers/types';
import Loader from 'components/Loader/Loader';

const styles = require('./styles.pcss');

type Props = {
  booking: any;
};

class PaymentInfo extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);
  }

  renderCorporateCard = () => {
    if (
      (_.get(this.props.booking, 'paymentInfo.organizationId', null) !== null &&
        _.get(this.props.booking, 'paymentInfo.organizationId', '') !== '') ||
      (_.isArray(
        _.get(this.props.booking, 'paymentInfo.departmentIds', false),
      ) &&
        _.get(this.props.booking, 'paymentInfo.departmentIds', []).length > 0)
    ) {
      return (
        <>
          <span className={styles.dot}></span> <span> Corporate Card </span>
        </>
      );
    }

    return '';
  };

  renderSelectedCardInfo = () => {
    return `${_.get(
      cardType,
      _.get(this.props.booking, 'paymentInfo.type', ''),
      '',
    )} ending in ${_.get(this.props.booking, 'paymentInfo.last4', '')}`;
  };

  renderBrex = () => {
    const brexTransaction = _.find(
      _.get(this.props.booking, 'transactions', []),
      (transaction) => transaction.type === 'brexPoints',
    );
    let amount = _.get(brexTransaction, 'amount', 0) / 100;

    return `Brex Points - ${amount
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  };

  renderIcon = () => {
    if (
      _.get(this.props.booking, 'alternativePaymentType', false) == 'brexPoints'
    ) {
      return (
        <SvgIconBrex
          width={32}
          height={32}
        />
      );
    }

    switch (
      _.get(cardType, _.get(this.props.booking, 'paymentInfo.type', ''), '')
    ) {
      case 'Visa':
        return (
          <SvgIconVisa
            width={32}
            height={32}
          />
        );
      case 'MasterCard':
        return (
          <SvgIconMasterCard
            width={32}
            height={32}
          />
        );
      case 'American Express':
        return (
          <SvgIconAmex
            width={32}
            height={32}
          />
        );
      case 'Discover':
        return (
          <SvgIconDiscover
            width={32}
            height={32}
          />
        );
      case 'Diners Club':
        return (
          <SvgIconDinersClub
            width={32}
            height={32}
          />
        );
      default:
        return (
          <SvgIconOtherCardType
            width={32}
            height={32}
          />
        );
    }
  };

  render() {
    return (
      <Paper className={styles.paymentInfo}>
        <div className={styles.infoHeader}>
          <div className={styles.avatar}>{this.renderIcon()}</div>
          <div className={styles.title}>
            <div>
              Payment Method
              <br />
              {_.get(this.props.booking, 'alternativePaymentType', false) ==
              'brexPoints' ? (
                <span>{this.renderBrex()}</span>
              ) : (
                <span>
                  {this.renderSelectedCardInfo()} {this.renderCorporateCard()}
                </span>
              )}
            </div>
          </div>
        </div>
      </Paper>
    );
  }
}

function mapStateToProps(state: ReduxState) {
  return {
    isLoading: state.booking.isLoading,
  };
}

export default connect(mapStateToProps, null)(PaymentInfo);

import { applyMiddleware, createStore } from 'redux';

// Load the root reducer for Redux
import RootReducer from '../reducers/RootReducer';

// Load Redux middlewares
import ReduxThunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import apiMiddleware from '../middleware/Api';

/**
 * Setup the Redux middlewares that will be attached to Redux actions
 */
const getMiddlewares = () => {
  // !!! ORDER OF APPLYING MIDDLEWARES IS VERY IMPORTANT !!!
  if (process.env.NODE_ENV === 'production') {
    return applyMiddleware(apiMiddleware, ReduxThunk);
  }

  // Not a production environment, apply development middlewares too.
  // NOTE: createLogger() MUST BE THE LAST MIDDLEWARE IN THE CHAIN SO IT WON'T LOG OTHER MIDDLEWARE ACTIVITY.

  const logger = createLogger({
    duration: true,
  });
  return applyMiddleware(apiMiddleware, ReduxThunk, logger as any);
};

// Create a Redux store and apply the middlewares
const reduxStore = createStore(RootReducer, getMiddlewares());

export default reduxStore;

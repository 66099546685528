import React from 'react';

type Props = {
  width: number;
  height: number;
};

const SvgCreditCard = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      version="1.0"
      viewBox="0 0 32 32"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
    >
      <g
        id="Master"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="User-Macro---Payment"
          transform="translate(-312.000000, -282.000000)"
        >
          <g
            id="payment-method"
            transform="translate(280.000000, 244.000000)"
          >
            <g
              id="Avatar"
              transform="translate(32.000000, 38.000000)"
            >
              <g id="Group-6">
                <path
                  d="M0,16 C0,7.164 7.162,0 16,0 C24.836,0 32,7.162 32,16 C32,24.836 24.838,32 16,32 C7.164,32 0,24.838 0,16"
                  id="Fill-1"
                  fill="#1B2432"
                ></path>
                <g
                  id="Group-4"
                  transform="translate(9.000000, 11.000000)"
                  fill="#FFFFFF"
                >
                  <path
                    d="M14,4 L14,8.5 C14,9.32842712 13.3284271,10 12.5,10 L1.5,10 C0.671572875,10 1.01453063e-16,9.32842712 0,8.5 L0,4 L14,4 Z M0,1.5 C-1.01453063e-16,0.671572875 0.671572875,1.52179594e-16 1.5,0 L12.5,0 C13.3284271,-1.52179594e-16 14,0.671572875 14,1.5 L14,3 L0,3 L0,1.5 Z"
                    id="Combined-Shape"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgCreditCard;

import React, { Component } from 'react';
import cx from 'classnames';
import _ from 'lodash-es';

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';

const styles = require('./styles.pcss');

import {
  resetPassword as resetPw,
  resetStates,
} from '../../actions/User/ResetPassword';

import { ReduxState } from '../../reducers/types';

type Props = {
  resetPw: Function;
  resetStates: Function;
  showChangePassword: boolean;
  closeChangePassword: any;
};

type State = {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
  errorCurrentPassword: any;
  errorNewPassword: any;
  errorConfirmNewPassword: any;
  showCurrentPassword: boolean;
  showNewPassword: boolean;
  showConfirmNewPassword: boolean;
};

class ChangePasswordPopup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      errorCurrentPassword: '',
      errorNewPassword: '',
      errorConfirmNewPassword: '',
      showCurrentPassword: false,
      showNewPassword: false,
      showConfirmNewPassword: false,
    };
  }

  componentWillReceiveProps = (nextProps: any) => {
    if (_.get(nextProps, 'resetPassword.success', false) === true) {
      this.props.closeChangePassword();
      this.props.resetStates();

      this.setState({
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
      });

      return;
    }

    this.setState({
      errorCurrentPassword: _.get(
        nextProps,
        'resetPassword.currentPasswordError',
        '',
      ),
      errorNewPassword: _.get(nextProps, 'resetPassword.newPasswordError', ''),
      errorConfirmNewPassword: _.get(
        nextProps,
        'resetPassword.confirmNewPasswordError',
        '',
      ),
    });
  };

  handleChangeCurrentPassword = (event: any) => {
    this.setState({
      currentPassword: event.target.value,
      errorCurrentPassword: false,
    });
  };

  handleChangeNewPassword = (event: any) => {
    this.setState({
      newPassword: event.target.value,
      errorNewPassword: false,
    });
  };

  handleChangeConfirmNewPassword = (event: any) => {
    this.setState({
      confirmNewPassword: event.target.value,
      errorConfirmNewPassword: false,
    });
  };

  validFields = () => {
    let isValid = true;
    let errorCurrentPassword = '';
    let errorNewPassword = '';
    let errorConfirmNewPassword = '';

    if (this.state.currentPassword.trim() === '') {
      errorCurrentPassword = 'Required';
      isValid = false;
    }

    if (this.state.newPassword.trim() === '') {
      errorNewPassword = 'Required';
      isValid = false;
    }

    if (this.state.confirmNewPassword !== this.state.newPassword) {
      errorConfirmNewPassword =
        'New password and confirm new password does not match';
      isValid = false;
    }

    if (this.state.confirmNewPassword.trim() === '') {
      errorConfirmNewPassword = 'Required';
      isValid = false;
    }

    this.setState({
      errorCurrentPassword,
      errorNewPassword,
      errorConfirmNewPassword,
    });

    return isValid;
  };

  handleSubmit = () => {
    if (this.validFields()) {
      this.props.resetPw(
        this.state.currentPassword,
        this.state.newPassword,
        this.state.confirmNewPassword,
      );
    }
  };

  /**
   * Handle form submit on enter
   */
  onKeyUp = (e: any) => {
    if (e.keyCode === 13) {
      this.handleSubmit();
    }
  };

  handleClickShowCurrentPassword = () => {
    this.setState({
      showCurrentPassword: !this.state.showCurrentPassword,
    });
  };

  handleClickShowNewPassword = () => {
    this.setState({
      showNewPassword: !this.state.showNewPassword,
    });
  };

  handleClickShowConfirmNewPassword = () => {
    this.setState({
      showConfirmNewPassword: !this.state.showConfirmNewPassword,
    });
  };

  render() {
    return (
      <Dialog
        fullWidth={true}
        maxWidth={false}
        className={cx(styles.dialogBox, styles.changePasswordBox)}
        open={this.props.showChangePassword}
        onClose={this.props.closeChangePassword}
      >
        <div className={cx(styles.title)}>
          Change Password
          <IconButton
            color="inherit"
            onClick={this.props.closeChangePassword}
            className={cx(styles.closeIcon)}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className={cx(styles.content)}>
          <TextField
            fullWidth
            id="currentPassword"
            className={cx(styles.textField)}
            label="Current Password"
            variant="outlined"
            margin="normal"
            value={this.state.currentPassword}
            onChange={this.handleChangeCurrentPassword}
            error={Boolean(this.state.errorCurrentPassword)}
            helperText={this.state.errorCurrentPassword}
            onKeyUp={this.onKeyUp}
            type={this.state.showCurrentPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: this.state.currentPassword !== '' && (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={this.handleClickShowCurrentPassword}
                  >
                    {this.state.showCurrentPassword ? (
                      <Visibility
                        className={cx(styles.icon, styles.eyeVisible)}
                      />
                    ) : (
                      <Visibility className={styles.icon} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            fullWidth
            id="newPassword"
            className={cx(styles.textField)}
            label="New Password"
            variant="outlined"
            margin="normal"
            value={this.state.newPassword}
            onChange={this.handleChangeNewPassword}
            error={Boolean(this.state.errorNewPassword)}
            helperText={this.state.errorNewPassword}
            onKeyUp={this.onKeyUp}
            type={this.state.showNewPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: this.state.newPassword !== '' && (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={this.handleClickShowNewPassword}
                  >
                    {this.state.showNewPassword ? (
                      <Visibility
                        className={cx(styles.icon, styles.eyeVisible)}
                      />
                    ) : (
                      <Visibility className={styles.icon} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            fullWidth
            id="confirmNewPassword"
            className={cx(styles.textField)}
            label="Confirm New Password"
            variant="outlined"
            margin="normal"
            value={this.state.confirmNewPassword}
            onChange={this.handleChangeConfirmNewPassword}
            error={Boolean(this.state.errorConfirmNewPassword)}
            helperText={this.state.errorConfirmNewPassword}
            onKeyUp={this.onKeyUp}
            type={this.state.showConfirmNewPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: this.state.confirmNewPassword !== '' && (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={this.handleClickShowConfirmNewPassword}
                  >
                    {this.state.showConfirmNewPassword ? (
                      <Visibility
                        className={cx(styles.icon, styles.eyeVisible)}
                      />
                    ) : (
                      <Visibility className={styles.icon} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Button
            className={styles.button}
            variant="contained"
            color="primary"
            onClick={this.handleSubmit}
          >
            Submit
          </Button>
        </div>
      </Dialog>
    );
  }
}

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {
    resetPassword: state.resetPassword,
  };
}

// // Wire up redux dispatch functions
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      resetPw,
      resetStates,
    },
    dispatch,
  );
}

// Connect it to Redux
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChangePasswordPopup);

import * as React from 'react';
import * as _ from 'lodash-es';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { ReduxState } from 'reducers/types';
import ExpenseReportsTableRow from 'components/TravelerDetails/ExpenseReportsTableRow';
import ExpenseReportsTableHeader from 'components/TravelerDetails/ExpenseReportsTableHeader';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { hasPermission } from '../../helpers/Permission';
import Loader from 'components/Loader/Loader';
import { syncExpenseReport } from 'actions/Users/PutExpenseReports';
import { getExpenseReports } from 'actions/Users/GetExpenseReports';

const styles = require('./styles.pcss');

type Props = {
  expenseReports: any;
  isLoading: any;
  syncExpenseReport: any;
  success: any;
  getExpenseReports: any;
  userId: any;
};

type State = {
  activeTabIndex: number;
};

class ExpenseReportsTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      activeTabIndex: 0,
    };

    this.onTabChange = this.onTabChange.bind(this);
  }

  componentWillReceiveProps = (nextProps: any) => {
    if (_.get(nextProps, 'success', null) == true) {
      this.props.getExpenseReports(this.props.userId);
    }
  };

  onTabChange(e: any, idx: number) {
    this.setState({
      activeTabIndex: idx,
    });
  }

  onClickSync = (id: any) => {
    this.props.syncExpenseReport(id);
  };

  renderTableRow = (report: any, i: number) => {
    return (
      <ExpenseReportsTableRow
        key={i}
        report={report}
        clickSync={this.onClickSync.bind(this, report.id)}
        sync={
          report.syncToGlStatus == 'failed' &&
          hasPermission('expenseReport', 'update')
        }
      />
    );
  };

  renderTable() {
    let reports: any = [];

    return (
      <Table className={styles.expenseReports}>
        <ExpenseReportsTableHeader />
        <TableBody>
          {this.state.activeTabIndex ? (
            <>
              {_.filter(_.get(this.props, 'expenseReports', []), function (o) {
                return o.status == 'paid';
              }).map(this.renderTableRow)}
            </>
          ) : (
            <>
              {_.filter(_.get(this.props, 'expenseReports', []), function (o) {
                return o.status != 'paid';
              }).map(this.renderTableRow)}
            </>
          )}
        </TableBody>
      </Table>
    );
  }

  render() {
    return (
      <div className="pageWrapper">
        <Loader visible={this.props.isLoading} />

        <Tabs
          className={styles.tabbs}
          value={this.state.activeTabIndex}
          indicatorColor="primary"
          textColor="primary"
          onChange={this.onTabChange}
        >
          <Tab
            className={styles.tabb}
            label="Open"
          />
          <Tab
            className={styles.tabb}
            label="Paid"
          />
        </Tabs>

        {this.renderTable()}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      syncExpenseReport,
      getExpenseReports,
    },
    dispatch,
  );
}

function mapStateToProps(state: ReduxState) {
  return {
    isLoading: state.userExpenseReports.isLoading,
    success: state.userExpenseReports.success,
    expenseReports: state.userExpenseReports.expenseReports,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExpenseReportsTable);

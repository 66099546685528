import * as React from 'react';
import cx from 'classnames';
import _ from 'lodash-es';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import SvgArrowDown from '../../../static/icons/svgArrowDown';
import SvgIconInfo from '../../../static/icons/svgIconInfo';
import InfoPopup from 'components/Popup/InfoPopup';
import { convertToNumber } from '../../../helpers/ConvertToNumber';
import { getProviderDisplayName } from '../../../helpers/getProviderDisplayName';
const styles = require('./styles.pcss');

type Props = {
  details: any;
};

type State = {
  openTaxes: boolean;
  openInfo: boolean;
};

class HotelDetails extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);

    this.state = {
      openTaxes: false,
      openInfo: false,
    };
  }

  closeInfoModal = () => {
    this.setState({
      openInfo: false,
    });
  };

  renderTaxesAndFees = (taxes: any) => {
    let tax = _.get(taxes, 'value', '')
      ? convertToNumber(_.get(taxes, 'value', '').substring(3)).toFixed(2)
      : ' - - ';

    return (
      <div className={cx(styles.row, styles.withPrice, styles.dropdown)}>
        <div className={cx(styles.title, styles.fees)}>{taxes.type}</div>
        <div className={styles.price}>+${tax}</div>
      </div>
    );
  };

  onClickTaxesAndFees = () => {
    this.setState((currentState: any) => ({
      openTaxes: !currentState.openTaxes,
    }));
  };

  onCLickCancelationInfo = () => {
    this.setState({
      openInfo: true,
    });
  };

  calculatePromo = () => {
    let promo = _.get(this.props.details, 'promo', false);
    let discountAmount = 0;

    if (promo && promo.type == 'purchase' && promo.unit == 'percentage') {
      discountAmount = Math.min(
        (promo.value / 100) *
          convertToNumber(
            _.get(this.props.details, 'hotel.priceDetails.base', 0),
          ),
        _.get(promo, 'maxCashValue', 0)
          ? convertToNumber(promo.maxCashValue)
          : 0,
      );
    }
    return discountAmount ? discountAmount.toFixed(2) : ' - - ';
  };

  renderTaxesAndFeesIcon = () => {
    if (
      _.get(this.props.details, 'hotel.priceDetails.taxDetails', []).length > 0
    ) {
      return (
        <div
          className={styles.title}
          onClick={this.onClickTaxesAndFees}
        >
          Taxes & Fees{' '}
          <SvgArrowDown
            width={9}
            height={6}
            color={'#6D717A'}
          />
        </div>
      );
    }

    return <div className={styles.title}>Taxes & Fees</div>;
  };

  render() {
    let nr_passenger = _.get(this.props, 'passenger', 0);
    let refundable = 'Non-refundable';

    if (_.get(this.props.details, 'hotel.refundable', false) === true) {
      refundable = 'Refundable';
    }

    const provider = _.get(
      this.props.details,
      'provider',
      _.get(this.props.details, 'hotel.roomDetails.0._meta.provider', ''),
    );
    const providerCode = _.get(
      this.props.details,
      'providerCode',
      'TP providerCode unknown',
    );
    let pricedetails = _.get(this.props.details, 'hotel.priceDetails', 0);
    let night = _.get(pricedetails, 'perNight', []).length;
    let avg = convertToNumber(_.get(pricedetails, 'avg', 0));
    let tripTotal =
      convertToNumber(_.get(this.props.details, 'hotel.hotelFees.total', 0)) +
      convertToNumber(_.get(this.props.details, 'customerPrice', 0));
    let rewards = _.find(
      _.get(this.props.details, 'transactions', []),
      (transaction) => transaction.purpose == 'savings',
    );

    return (
      <Paper className={styles.hotelDetails}>
        <div className={styles.numbers}>
          <div className={cx(styles.row, styles.cancelPolicyRow)}>
            <div className={styles.cancelPolicy}>
              <div className={styles.subtitle}>Provider</div>
              <div className={cx(styles.title, styles.highlight)}>
                {getProviderDisplayName(provider, providerCode)}
              </div>
            </div>
          </div>

          <div className={cx(styles.row, styles.cancelPolicyRow)}>
            <div className={styles.cancelPolicy}>
              <div className={styles.subtitle}>Cancelation Policy</div>
              <div className={cx(styles.title, styles.highlight)}>
                {refundable}
              </div>
            </div>
            {_.get(
              this.props.details,
              'hotel.roomDetails.0.cancelationPolicy',
              '',
            ) !== '' && (
              <div
                className={styles.cancelInfoIcon}
                onClick={this.onCLickCancelationInfo}
              >
                <SvgIconInfo
                  width={16}
                  height={16}
                />
              </div>
            )}
          </div>

          <div className={cx(styles.row, styles.withPrice)}>
            <div className={styles.title}>
              Room({night} Night x ${avg} avg/night)
            </div>
            <div className={styles.price}>
              +$
              {(_.get(pricedetails, 'base', 0) != null
                ? convertToNumber(_.get(pricedetails, 'base', 0))
                : 0
              ).toFixed(2)}
            </div>
          </div>

          <div
            className={
              this.state.openTaxes
                ? cx(styles.row, styles.withPrice, styles.rotate)
                : cx(styles.row, styles.withPrice)
            }
          >
            {this.renderTaxesAndFeesIcon()}
            <div className={styles.price}>
              +$
              {(_.get(pricedetails, 'taxes', 0) != null
                ? convertToNumber(_.get(pricedetails, 'taxes', 0))
                : 0
              ).toFixed(2)}
            </div>
          </div>

          {this.state.openTaxes &&
            _.get(pricedetails, 'taxDetails', []).map((taxes: any, i: any) => {
              return this.renderTaxesAndFees(taxes);
            })}

          {_.get(rewards, 'amount', 0) !== 0 && (
            <div className={cx(styles.row, styles.withPrice)}>
              <div className={styles.title}>Rewards</div>
              <div className={styles.price}>
                +$
                {rewards.amount
                  ? (convertToNumber(rewards.amount) / 100).toFixed(2)
                  : ' - - '}
              </div>
            </div>
          )}
          {_.get(this.props.details, 'promo', false) && (
            <div className={cx(styles.row, styles.withPrice)}>
              <div className={styles.title}>Promo Code</div>
              <div className={styles.price}>-${this.calculatePromo()}</div>
            </div>
          )}

          <div className={cx(styles.row, styles.withPrice, styles.highlight)}>
            <div className={styles.totalPrice}>Total Paid</div>
            <div className={cx(styles.price, styles.total)}>
              $
              {_.get(this.props.details, 'customerPrice', 0)
                ? convertToNumber(
                    _.get(this.props.details, 'customerPrice', 0),
                  ).toFixed(2)
                : ' - - '}
            </div>
          </div>

          <div className={cx(styles.row, styles.withPrice)}>
            <div className={cx(styles.title, styles.fees)}>
              Resort Fee Due at Hotel
            </div>
            <div className={cx(styles.price, styles.fees)}>
              +$
              {_.get(this.props.details, 'hotel.hotelFees.total', 0)
                ? convertToNumber(
                    _.get(this.props.details, 'hotel.hotelFees.total', 0),
                  ).toFixed(2)
                : ' - - '}
            </div>
          </div>

          <div
            className={cx(
              styles.row,
              styles.withPrice,
              styles.highlight,
              styles.noBorder,
            )}
          >
            <div className={styles.totalPrice}>Trip Total</div>
            <div className={cx(styles.price, styles.total)}>
              ${tripTotal ? tripTotal.toFixed(2) : ' - - '}
            </div>
          </div>
        </div>
        <InfoPopup
          open={this.state.openInfo}
          onClose={this.closeInfoModal}
          onSubmit={this.closeInfoModal}
          title="Cancelation Policy"
          bodyText={_.get(
            this.props.details,
            'hotel.roomDetails.0.cancelationPolicy',
            '',
          )}
          submitButtonText="OK"
          bodyTextStyle={styles.confirmationBodyTextStyle}
        />
      </Paper>
    );
  }
}

export default HotelDetails;

import * as React from 'react';
import classNames from 'classnames';
import cx from 'classnames';

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ReduxState } from '../../reducers/types';
import _ from 'lodash-es';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import SvgAddIcon from '../../static/icons/svgAddIcon';
import AddNegotiatedHotelPopup from 'components/Popup/AddNegotiatedHotelPopup';
import { deleteNegotiatedHotels } from '../../actions/Companies/DeleteNegotiatedHotels';
import { putFlightTourCodes } from '../../actions/Companies/Settings/PutFlightTourCodes';
import Loader from 'components/Loader/Loader';
import { hasPermission } from '../../helpers/Permission';
import NegotiatedSuppliersTableRow from './NegotiatedSuppliersTableRow';
import ConfirmationPopup from 'components/Popup/Confirmation';
import { AIRLINE_NAME_MAP } from '../../helpers/airlinemap';

const styles = require('./styles.pcss');

type Props = {
  negotiatedHotels: any;
  companyDetails: any;
  deleteNegotiatedHotels: Function;
  putFlightTourCodes: Function;
  flightTourCodes: any;
};

type State = {
  addNewOpen: boolean;
  openConfirmation: boolean;
  selectedCode: any;
  tabValue: string;
};

class NegotiatedSuppliers extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      tabValue: 'flight',
      addNewOpen: false,
      openConfirmation: false,
      selectedCode: null,
    };
  }

  handleTabChange = (event: any, value: any) => {
    this.setState({
      tabValue: value,
    });
  };

  onClickAddCode = () => {
    this.setState({
      addNewOpen: true,
    });
  };

  onClickClosePopup = () => {
    this.setState({
      addNewOpen: false,
    });
  };

  onClickRemove = (code: any) => {
    this.setState({
      openConfirmation: true,
      selectedCode: code,
    });
  };

  onSubmitDelete = () => {
    if (this.state.tabValue == 'flight') {
      let value = _.clone(
        _.get(this.props.flightTourCodes, 'flightTourCodes.value', []),
      );
      value = _.pull(value, this.state.selectedCode);

      this.props.putFlightTourCodes({
        value: value,
        organizationId: this.props.companyDetails.id,
      });
    } else {
      this.props.deleteNegotiatedHotels({
        organizationId: this.props.companyDetails.id,
        identifier: this.state.selectedCode,
      });
    }

    this.setState({
      openConfirmation: false,
      selectedCode: null,
    });
  };

  renderAddCodePopup() {
    if (this.state.addNewOpen === false) {
      return null;
    }

    return (
      <AddNegotiatedHotelPopup
        showPopup={this.state.addNewOpen}
        closePopup={this.onClickClosePopup}
        type={this.state.tabValue}
      />
    );
  }

  closeConfirmation = () => {
    this.setState({
      openConfirmation: false,
    });
  };

  renderTabContent = () => {
    if (this.state.tabValue === 'hotel') {
      let negotiatedHotels = _.clone(
        _.get(this.props.negotiatedHotels, 'negotiatedHotels', {}),
      );
      negotiatedHotels.sort((a: any, b: any) => a.name.localeCompare(b.name));

      return (
        <Table>
          <TableBody>
            {_.map(negotiatedHotels, (hotel) => {
              return (
                <NegotiatedSuppliersTableRow
                  clickRemove={this.onClickRemove.bind(
                    this,
                    _.get(hotel, 'identifier', ''),
                  )}
                  showRemove={
                    !this.state.addNewOpen &&
                    hasPermission('organizations', 'update')
                  }
                  key={_.get(hotel, 'identifier', '')}
                  rowColumns={[
                    _.get(hotel, 'name', ''),
                    _.get(hotel, 'identifier', ''),
                  ]}
                />
              );
            })}
          </TableBody>
        </Table>
      );
    } else {
      let tourCodes = _.clone(
        _.get(this.props.flightTourCodes, 'flightTourCodes.value', []),
      );
      tourCodes = _.sortBy(tourCodes, ['key']);

      return (
        <Table>
          <TableBody>
            {_.map(tourCodes, (code) => {
              const key = _.get(code, 'airlineCode', '');
              return (
                <NegotiatedSuppliersTableRow
                  clickRemove={this.onClickRemove.bind(this, code)}
                  showRemove={
                    !this.state.addNewOpen &&
                    hasPermission('organizations', 'update')
                  }
                  key={
                    _.get(AIRLINE_NAME_MAP, key, '') +
                    '-key-' +
                    _.get(code, 'number', '')
                  }
                  rowColumns={[
                    _.get(AIRLINE_NAME_MAP, key, '') +
                      ' ' +
                      _.get(code, 'number', ''),
                  ]}
                />
              );
            })}
          </TableBody>
        </Table>
      );
    }

    return null;
  };

  render() {
    const confText =
      this.state.tabValue === 'flight'
        ? 'Are you sure you want to remove this code?'
        : 'Are you sure you want to remove this hotel?';
    const isLoading =
      _.get(this.props.negotiatedHotels, 'isLoading', true) ||
      _.get(this.props.flightTourCodes, 'isLoading', true);

    return (
      <Paper
        className={isLoading ? cx(styles.paper, styles.loading) : styles.paper}
      >
        <Loader
          visible={
            _.get(this.props.negotiatedHotels, 'isLoading', false) ||
            _.get(this.props.flightTourCodes, 'isLoading', true)
          }
        />

        <div className={styles.header}>
          <h2>Negotiated Suppliers</h2>
          <Tabs
            value={this.state.tabValue}
            indicatorColor="primary"
            textColor="primary"
            onChange={this.handleTabChange}
            className={cx(styles.tabs, styles.corporateRateCodeTabs)}
            disabled={this.state.openConfirmation || this.state.addNewOpen}
          >
            <Tab
              disableRipple
              className={styles.tab}
              label="Flight Tour Codes"
              value="flight"
            />
            <Tab
              disableRipple
              className={styles.tab}
              label="Hotels"
              value="hotel"
            />
          </Tabs>
          {this.renderTabContent()}

          {hasPermission('organizations', 'update') && (
            <div
              className={styles.addProgram}
              onClick={this.onClickAddCode}
            >
              <div className={styles.addIcon}>
                <SvgAddIcon
                  width={18}
                  height={18}
                />
              </div>
              {this.state.tabValue === 'hotel' && <div>Add Hotel</div>}
              {this.state.tabValue === 'flight' && <div>Add TOUR Code</div>}
            </div>
          )}
        </div>
        {this.renderAddCodePopup()}
        <ConfirmationPopup
          open={this.state.openConfirmation}
          onClose={this.closeConfirmation}
          onSubmit={this.onSubmitDelete}
          title="Are you sure?"
          bodyText={confText}
          submitButtonText="Remove"
          closeButtonText="Cancel"
        />
      </Paper>
    );
  }
}
// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {
    companyDetails: state.company.company,
    negotiatedHotels: state.negotiatedHotels,
    flightTourCodes: state.flightTourCodes,
  };
}

// Wire up redux dispatch functions
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      deleteNegotiatedHotels,
      putFlightTourCodes,
    },
    dispatch,
  );
}

// Connect it to Redux
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NegotiatedSuppliers);

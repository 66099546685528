import {
  ACTION_GET_AGENCY,
  ACTION_GET_AGENCY_SUCCESS,
  ACTION_GET_AGENCY_FAILURE,
  ACTION_EMPTY_AGENCY,
  ACTION_PUT_AGENCY,
  ACTION_PUT_AGENCY_SUCCESS,
  ACTION_PUT_AGENCY_FAILURE,
  ACTION_PUT_AGENCY_COLOR,
  ACTION_POST_AGENCY,
  ACTION_POST_AGENCY_SUCCESS,
  ACTION_POST_AGENCY_FAILURE,
} from '../configs/ActionTypes';
import _ from 'lodash-es';

const initialState = {
  agency: {},
  isLoading: false,
  isLoadingAdd: false,
  success: null,
  isLoadingColor: false,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_PUT_AGENCY_COLOR: {
      return {
        ...state,
        isLoadingColor: true,
      };
    }

    case ACTION_POST_AGENCY: {
      return {
        ...state,
        isLoadingAdd: true,
        success: null,
      };
    }

    case ACTION_POST_AGENCY_SUCCESS: {
      return {
        ...state,
        isLoadingAdd: false,
        success: true,
      };
    }

    case ACTION_POST_AGENCY_FAILURE: {
      return {
        ...state,
        isLoadingAdd: false,
        success: false,
      };
    }

    case ACTION_GET_AGENCY:
    case ACTION_PUT_AGENCY: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ACTION_GET_AGENCY_SUCCESS:
    case ACTION_PUT_AGENCY_SUCCESS: {
      const agency = action.payload.payload;

      return {
        ...state,
        agency: agency,
        isLoading: false,
        isLoadingColor: false,
        success: true,
      };
    }
    case ACTION_GET_AGENCY_FAILURE: {
      return {
        ...state,
        agency: [],
        isLoading: false,
        isLoadingColor: false,
        loaded: false,
      };
    }
    case ACTION_PUT_AGENCY_FAILURE: {
      let agency: any = state.agency;

      return {
        ...state,
        agency: agency,
        isLoading: false,
        isLoadingColor: false,
        loaded: false,
      };
    }

    case ACTION_EMPTY_AGENCY: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

import {
  ACTION_GET_USER,
  ACTION_GET_USER_SUCCESS,
  ACTION_GET_USER_FAILURE,
  ACTION_PUT_USER,
  ACTION_PUT_USER_SUCCESS,
  ACTION_PUT_USER_RESET,
  ACTION_PUT_USER_FAILURE,
  ACTION_GET_USER_CREDITCARDS,
  ACTION_GET_USER_CREDITCARDS_SUCCESS,
  ACTION_GET_USER_CREDITCARDS_FAILURE,
  ACTION_PUT_USER_ORG_ANALYTICS_SUCCESS,
  ACTION_PUT_USER_STATUS_SUCCESS,
  ACTION_GET_USER_FLIGHTCREDITS_SUCCESS,
  ACTION_UPDATE_USER_FLIGHTCREDITS_SUCCESS,
} from '../configs/ActionTypes';

import { User } from './types';
import _ from 'lodash-es';
import CreditCard from 'components/Lookup/RecordForm/CreditCard';

const initialState = {
  user: {} as User,
  isLoading: false,
  isLoadingCC: false,
  updateSuccess: null,
  success: null,
  errors: {
    firstName: '',
    lastName: '',
    email: '',
  },
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_GET_USER_CREDITCARDS: {
      return {
        ...state,
        isLoadingCC: true,
        success: null,
      };
    }

    case ACTION_GET_USER:
    case ACTION_PUT_USER: {
      return {
        ...state,
        isLoading: true,
        success: null,
        updateSuccess: null,
        errors: {
          firstName: '',
          lastName: '',
          email: '',
        },
      };
    }

    case ACTION_PUT_USER_STATUS_SUCCESS: {
      let user: User = _.clone(state.user);

      _.set(user, 'status', action.payload.payload.status);

      return {
        ...state,
        user: user,
      };
    }

    case ACTION_GET_USER_SUCCESS:
    case ACTION_PUT_USER_ORG_ANALYTICS_SUCCESS: {
      const u = action.payload.payload;

      let multiOrganizationAnalytics = _.get(
        u,
        'multiOrganizationAnalytics',
        '',
      ).split(',');
      multiOrganizationAnalytics = multiOrganizationAnalytics.filter(Boolean);

      let user: User = {
        id: u.id,
        firstName: u.firstName,
        lastName: u.lastName,
        email: u.email,
        status: u.status,
        phoneNumber: _.get(u, 'phoneNumber', ''),
        created: u.created,
        updated: u.updated,
        avatar: _.get(u, 'avatar.thumbnail', ''),
        bypassSso: _.get(u, 'bypassSso', ''),
        lastUsedCreditCard: u.lastUsedCreditCard,
        organizations: u.organizations,
        creditCards: _.get(state.user, 'creditCards', []),
        flightCredits: _.get(state.user, 'flightCredits', []),
        _meta: _.get(u, '_meta', null),
        account: _.get(u, 'account', null),
        agency: _.get(u, 'agency', null),
        travelPolicyId: _.get(u, 'travelPolicyId', ''),
        departments: _.get(u, 'departments', []),
        multiOrganizationAnalytics,
        notes: _.get(u, 'notes', ''),
      };

      return {
        ...state,
        user: user,
        isLoading: false,
        success: true,
        errors: {
          firstName: '',
          lastName: '',
          email: '',
        },
      };
    }

    case ACTION_PUT_USER_SUCCESS: {
      const u = action.payload.payload;

      let multiOrganizationAnalytics = _.get(
        u,
        'multiOrganizationAnalytics',
        '',
      ).split(',');
      multiOrganizationAnalytics = multiOrganizationAnalytics.filter(Boolean);

      let user: User = {
        id: u.id,
        firstName: u.firstName,
        lastName: u.lastName,
        email: u.email,
        status: u.status,
        phoneNumber: _.get(u, 'phoneNumber', ''),
        created: u.created,
        updated: u.updated,
        avatar: _.get(u, 'avatar.thumbnail', ''),
        bypassSso: _.get(u, 'bypassSso', ''),
        lastUsedCreditCard: u.lastUsedCreditCard,
        organizations: state.user.organizations,
        creditCards: _.get(state.user, 'creditCards', []),
        flightCredits: _.get(state.user, 'flightCredits', []),
        _meta: _.get(u, '_meta', null),
        account: _.get(u, 'account', null),
        agency: _.get(u, 'agency', null),
        travelPolicyId: _.get(u, 'travelPolicyId', ''),
        departments: _.get(u, 'departments', []),
        multiOrganizationAnalytics,
        notes: _.get(u, 'notes', ''),
      };

      return {
        ...state,
        user: user,
        isLoading: false,
        updateSuccess: true,
        errors: {
          firstName: '',
          lastName: '',
          email: '',
        },
      };
    }

    case ACTION_GET_USER_CREDITCARDS_SUCCESS: {
      const creditCards = action.payload.payload;
      let user: User = _.clone(state.user);

      _.set(user, 'creditCards', creditCards);

      return {
        ...state,
        user: user,
        isLoadingCC: false,
        success: true,
        errors: {
          firstName: '',
          lastName: '',
          email: '',
        },
      };
    }

    case ACTION_GET_USER_FLIGHTCREDITS_SUCCESS: {
      const flightCredits = action.payload.payload;
      let user: User = _.clone(state.user);

      _.set(user, 'flightCredits', flightCredits);

      return {
        ...state,
        user: user,
        isLoadingCC: false,
        success: true,
        errors: {
          firstName: '',
          lastName: '',
          email: '',
        },
      };
    }

    case ACTION_UPDATE_USER_FLIGHTCREDITS_SUCCESS: {
      const update = action.payload;
      let user = _.clone(state.user);
      const updateCredIndex = user.flightCredits.findIndex(
        (cred: any) => cred.id === update.flightCreditId,
      );
      user.flightCredits[updateCredIndex].status = update.flightCreditStatus;
      return {
        ...state,
        user: user,
        isLoadingCC: false,
        updateSuccess: true,
        errors: {
          firstName: '',
          lastName: '',
          email: '',
        },
      };
    }

    case ACTION_PUT_USER_RESET: {
      return {
        ...state,
        updateSuccess: null,
        errors: {
          firstName: '',
          lastName: '',
          email: '',
        },
      };
    }

    case ACTION_PUT_USER_FAILURE: {
      return {
        ...state,
        isLoading: false,
        success: false,
        updateSuccess: false,
        errors: {
          firstName: checkFirstNameError(action.payload.payload),
          lastName: checkLastNameError(action.payload.payload),
          email:
            _.get(action.payload.payload, 'emailError', false) === true
              ? 'Invalid email address'
              : '',
        },
      };
    }

    default: {
      return state;
    }
  }
}

function checkFirstNameError(payload: any) {
  if (_.get(payload, 'firstNameEmptyError', false)) {
    return 'Empty first name';
  }

  return '';
}

function checkLastNameError(payload: any) {
  if (_.get(payload, 'lastNameEmptyError', false)) {
    return 'Empty last name';
  }

  return '';
}

import React from 'react';
import cx from 'classnames';
import Modal from '@material-ui/core/Modal';
import Popover from '@material-ui/core/Popover';
import MenuItem from '@material-ui/core/MenuItem';
import _ from 'lodash-es';
import Button from '@material-ui/core/Button';
import { Checkbox } from '@material-ui/core';
import SvgIconSearchSmall from '../../static/icons/svgIconSearchSmall';
const styles = require('../Inputs/MultiSelect/styles.pcss');
const styles2 = require('./styles.pcss');
import { debounce } from 'lodash-es';
import CircularProgress from '@material-ui/core/CircularProgress';

type Props = {
  onChange: Function;
  className?: any;
  id: any;
  disabled?: boolean;
  endText?: any;
  searchable?: boolean;
  onClickClear?: any;
  isMultiple?: boolean;
  selections: Array<any>;
  headerName: string;
  value: any;
  dynamicSearch?: boolean;
  getList?: any;
  isLoading?: any;
  removeFirstOptionOnSearch?: any;
};

type State = {
  modalAnchor: any;
  value: any;
  focused: boolean;
  searchValue: any;
  selections: Array<any>;
  selectedItems: any;
};

class TableHeadFilterPopup extends React.Component<Props, State> {
  containerRef: any;
  inputRef: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      modalAnchor: null,
      value: props.value,
      focused: false,
      searchValue: '',
      selections: props.selections,
      selectedItems: [],
    };

    this.containerRef = React.createRef();
    this.inputRef = React.createRef();
    this.handleSuggestionsFetchRequested = debounce(
      this.handleSuggestionsFetchRequested,
      250,
    );
  }

  componentWillReceiveProps = (nextProps: Props) => {
    this.setState({
      selections: nextProps.selections,
      value: nextProps.value,
    });
  };

  openModal = (event: any) => {
    this.setState({
      modalAnchor: this.containerRef,
      focused: true,
      searchValue: '',
    });
  };

  closeModal = () => {
    this.setState({
      modalAnchor: null,
      focused: false,
    });
    this.blurInput();
  };

  blurInput = () => {
    setTimeout(() => {
      if (document.activeElement) {
        (document.activeElement as HTMLElement).blur();
      }
    }, 100);
  };

  callGetList = (value: string) => {
    this.props.getList(value);
  };

  handleSuggestionsFetchRequested = (value: any) => {
    if (value.length > 2) {
      this.callGetList(value);
    }
  };

  save = () => {
    const localSelectedItems = this.state.selectedItems;
    const localValue = this.state.value;

    this.setState({
      value: [],
      selectedItems: null,
      modalAnchor: null,
      focused: false,
    });

    if (this.props.dynamicSearch) {
      let selectedElements = _.cloneDeep(localValue);
      let optionalItems = _.cloneDeep(this.state.selections);
      let stateValue = _.cloneDeep(localValue);
      const selectedItems =
        localSelectedItems == null ? [] : _.cloneDeep(localSelectedItems);

      if (selectedItems.length > 0) {
        const selectedItemsId = selectedItems.map((l: any) =>
          _.get(l, 'key', ''),
        );
        stateValue.push(...selectedItemsId);
        stateValue = _.uniq(stateValue);
        optionalItems.push(...selectedItems);
      }

      selectedElements = _.uniq(selectedElements);
      optionalItems = _.uniqBy(optionalItems, 'key');

      const selectedValues = _.filter(optionalItems, function (el: any) {
        return _.includes(selectedElements, el.key);
      });

      this.props.onChange(localValue, selectedValues);
    } else {
      this.props.onChange(localValue);
    }

    this.blurInput();
  };

  onClickClear = () => {
    this.setState({
      value: [],
      selectedItems: null,
      selections: [],
      modalAnchor: null,
      focused: false,
    });

    this.blurInput();
    this.props.onClickClear();
  };

  handleCheck = (e: any) => {
    let value = _.cloneDeep(this.state.value);
    let selectedItems =
      this.state.selectedItems == null
        ? []
        : _.cloneDeep(this.state.selectedItems);
    const selectedItemsId = selectedItems.map((l: any) => _.get(l, 'key', ''));

    value.push(...selectedItemsId);
    value = _.uniq(value);

    if (!_.includes(value, e.currentTarget.dataset.id)) {
      this.props.isMultiple
        ? value.push(e.currentTarget.dataset.id)
        : (value[0] = e.currentTarget.dataset.id);

      if (this.props.dynamicSearch) {
        const name = _.result(
          _.find(
            this.props.selections,
            (p) => p.key == e.currentTarget.dataset.id,
          ),
          'value',
        );
        selectedItems.push({ key: e.currentTarget.dataset.id, value: name });
      }
    } else {
      _.pull(value, e.currentTarget.dataset.id);

      if (this.props.dynamicSearch) {
        selectedItems = _.remove(selectedItems, function (item) {
          return _.get(item, 'key', '') != e.currentTarget.dataset.id;
        });
      }
    }

    this.setState({
      value,
      selectedItems,
    });
  };

  onChangeSearch = (event: any) => {
    const e = event.target.value.toLowerCase();

    if (this.props.dynamicSearch) {
      this.handleSuggestionsFetchRequested(e);
      this.setState({
        searchValue: event.target.value,
      });
    } else {
      const newItems: any = _.filter(this.props.selections, function (el: any) {
        return _.get(el, 'value', '').toLowerCase().includes(e);
      });

      this.setState({
        searchValue: event.target.value,
        selections: newItems,
      });
    }
  };

  filterSelectedItems = (items: any, searchValue: any) => {
    return _.filter(items, function (el: any) {
      return _.get(el, 'value', '').toLowerCase().includes(searchValue);
    });
  };

  renderContainer = () => {
    let classNameD = this.props.isMultiple
      ? cx(styles.dropdown, styles.dropdownMultiple)
      : styles.dropdown;
    let searchBoxClass =
      this.state.searchValue.length > 0
        ? cx(styles.searchBox, styles.hidden)
        : styles.searchBox;
    let selectedElements = _.cloneDeep(this.props.value);
    let optionalItems = _.cloneDeep(this.state.selections);
    let stateValue = _.cloneDeep(this.state.value);
    let selectedItems =
      this.state.selectedItems == null
        ? []
        : _.cloneDeep(this.state.selectedItems);

    if (
      this.state.searchValue.length > 0 &&
      this.props.removeFirstOptionOnSearch
    ) {
      optionalItems.shift();
    }

    if (this.state.searchValue.length > 0) {
      selectedItems = this.filterSelectedItems(
        selectedItems,
        this.state.searchValue,
      );
    }

    if (selectedItems.length > 0) {
      const selectedItemsId = selectedItems.map((l: any) =>
        _.get(l, 'key', ''),
      );
      stateValue.push(...selectedItemsId);
      stateValue = _.uniq(stateValue);
      optionalItems.push(...selectedItems);
      selectedItems = _.filter(optionalItems, function (f) {
        return selectedElements.includes(f.key);
      });
    }

    selectedElements = _.uniq(selectedElements);
    optionalItems = _.uniqBy(optionalItems, 'key');
    selectedItems = _.uniqBy(selectedItems, 'key');

    const selectedValues = _.filter(optionalItems, function (el: any) {
      return _.includes(selectedElements, el.key);
    });

    if (this.props.searchable)
      optionalItems = _.filter(optionalItems, function (el: any) {
        return !_.includes(selectedElements, el.key);
      });

    return (
      <Popover
        PaperProps={{
          style: {
            boxShadow: 'none',
            border: '1px solid #C6C8CC',
          },
        }}
        id={this.props.id + 'Container'}
        open={Boolean(this.state.modalAnchor)}
        onClose={this.closeModal}
        anchorEl={this.state.modalAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className={classNameD}>
          {this.props.searchable && (
            <div className={styles.searchContainer}>
              <div className={searchBoxClass}>
                <span>
                  <SvgIconSearchSmall
                    width={13}
                    height={13}
                  />
                </span>
                <input
                  className={styles.searchBar}
                  placeholder="Search"
                  onChange={this.onChangeSearch}
                  type="text"
                ></input>
              </div>
              {this.props.isLoading && (
                <CircularProgress
                  size={24}
                  className={styles.sCircularProgress}
                />
              )}
            </div>
          )}
          <ul>
            {optionalItems.map((selection: any) => {
              let className = styles.menuItemCheckbox;
              if (_.includes(stateValue, selection.key)) {
                className = cx(
                  styles.menuItemCheckbox,
                  styles.menuItemSelected,
                );
              }

              return (
                <li
                  key={selection.key}
                  data-id={selection.key}
                  value={selection.value}
                  className={className}
                  onClick={this.handleCheck.bind(this)}
                >
                  <Checkbox
                    checked={stateValue.indexOf(selection.key) > -1}
                    color={
                      stateValue.indexOf(selection.key) > -1
                        ? 'primary'
                        : 'default'
                    }
                  />
                  <div className={styles.menuItemText}>
                    <p className={styles.text}>{selection.value}</p>
                    {Boolean(selection.description) && (
                      <p className={styles.description}>
                        {selection.description}
                      </p>
                    )}
                  </div>
                </li>
              );
            })}
            {this.props.searchable &&
              optionalItems.length > 0 &&
              this.props.value.length > 0 && (
                <div className={styles2.lineBefore}></div>
              )}
          </ul>
          {this.props.searchable && this.props.value.length > 0 && (
            <div className={styles2.selectedContainer}>
              <div className={styles2.prevSelectedText}>
                {' '}
                Previously Selected
              </div>
              <ul>
                {selectedValues.map((selection: any) => {
                  let className = styles.menuItemCheckbox;
                  if (_.includes(this.state.value, selection.key)) {
                    className = cx(
                      styles.menuItemCheckbox,
                      styles.menuItemSelected,
                    );
                  }

                  return (
                    <li
                      key={selection.key}
                      data-id={selection.key}
                      value={selection.value}
                      className={className}
                      onClick={this.handleCheck.bind(this)}
                    >
                      <Checkbox
                        checked={this.state.value.indexOf(selection.key) > -1}
                        color={
                          this.state.value.indexOf(selection.key) > -1
                            ? 'primary'
                            : 'default'
                        }
                      />
                      <div className={styles.menuItemText}>
                        <p className={styles.text}>{selection.value}</p>
                        {Boolean(selection.description) && (
                          <p className={styles.description}>
                            {selection.description}
                          </p>
                        )}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          <div className={styles.buttons}>
            {this.state.value.length > 0 ? (
              <Button
                size="small"
                className={styles.buttonClear}
                color="primary"
                onClick={this.onClickClear}
                id={this.props.id + 'Clear'}
              >
                Clear
              </Button>
            ) : (
              <div></div>
            )}

            <Button
              size="small"
              className={styles.buttonSave}
              variant="contained"
              color="primary"
              onClick={this.save}
              id={this.props.id + 'Save'}
            >
              Apply
            </Button>
          </div>
        </div>
      </Popover>
    );
  };

  render() {
    let className = '';
    if (this.props.className) {
      className = cx(styles.dropdownCont, this.props.className);
    }

    className = Boolean(this.state.modalAnchor)
      ? cx(className, styles.selected)
      : className;

    const filterBorderClassName =
      _.get(this.props, 'value', []).length > 0
        ? cx(styles2.headerFilterBorder, styles2.headerFilterBorderActive)
        : styles2.headerFilterBorder;

    return (
      <div
        className={className}
        ref={(r: any) => (this.containerRef = r)}
      >
        <div
          className={filterBorderClassName}
          onClick={this.openModal}
        >
          {' '}
          {this.props.headerName}{' '}
        </div>
        {this.renderContainer()}
      </div>
    );
  }
}

export default TableHeadFilterPopup;

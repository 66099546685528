import * as React from 'react';
import _ from 'lodash-es';
import cx from 'classnames';
import Loader from 'components/Loader/Loader';
const styles = require('./styles.pcss');
import UpdatePopup from 'components/AgencyDetails/AgencyInventorySuppliers/UpdatePopup';
import Provider from 'components/AgencyDetails/AgencyInventorySuppliers/Provider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextFieldWithClear from 'components/TextFieldWithIcon/TextFieldWithIcon';
import { hasPermission } from '../../helpers/Permission';
import Checkbox from '@material-ui/core/Checkbox';
import IconCheckboxChecked from '../../static/icons/svgCheckboxChecked';
import IconCheckbox from '../../static/icons/svgCheckbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

type Props = {
  isLoading: boolean;
  agencyDetails?: any;
  putAgency: Function;
  specialRateCodes?: any;
  putSpecialRateCodes: Function;
  organizationId?: any;
  agency?: any;
};

type State = {
  openPopup: boolean;
  targetBranch: any;
  pcc: any;
  accessProfile: any;
  iataNr: any;
  enabledSwitch: boolean;
  title: string;
  type: any;
  enabledSwitchOnly: boolean;
  agencySetting: any;
  provider: any;
  tabValue: any;
  prepayCheck: any;
  postpayCheck: any;
  aaaRateCheck: boolean;
  governmentRateCheck: boolean;
  aarpRateCheck: boolean;
  seniorRateCheck: boolean;
  specialRates: any;
};

class AgencyInventorySuppliers extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const specialRates = _.get(this.props, 'specialRateCodes', []);
    this.state = {
      openPopup: false,
      targetBranch: undefined,
      pcc: undefined,
      accessProfile: '',
      iataNr: '',
      enabledSwitch: false,
      title: 'Update Details',
      type: '',
      enabledSwitchOnly: false,
      agencySetting: _.cloneDeep(_.get(this.props, 'agencyDetails', {})),
      provider: '',
      tabValue: 'flights',
      prepayCheck: '',
      postpayCheck: '',
      aaaRateCheck: specialRates?.includes('AAA'),
      governmentRateCheck: specialRates?.includes('GOV'),
      aarpRateCheck: specialRates?.includes('ARP'),
      seniorRateCheck: specialRates?.includes('SNR'),
      specialRates: _.cloneDeep(specialRates),
    };
  }

  componentWillReceiveProps = (nextProps: any) => {
    if (nextProps.agencyDetails != this.state.agencySetting) {
      this.setState({
        agencySetting: _.cloneDeep(_.get(nextProps, 'agencyDetails', {})),
      });
    }
  };

  updateSpecialRate = (rateCode: string) => {
    if (this.state.specialRates?.includes(rateCode)) {
      this.setState({
        specialRates: this.state.specialRates?.filter(
          (rate: string) => rate !== rateCode,
        ),
      });
    } else {
      this.setState({
        specialRates: this.state.specialRates?.concat([rateCode]),
      });
    }
  };

  closePopup = () => {
    this.setState({
      agencySetting: _.cloneDeep(_.get(this.props, 'agencyDetails', {})),
      openPopup: false,
    });
  };

  openPopup = () => {
    this.setState({
      openPopup: true,
    });
  };

  handleTabChange = (event: any, value: any) => {
    this.setState({
      tabValue: value,
    });
  };

  renderFormGroup = (
    checked: boolean,
    onChange: any,
    disabled: boolean,
    label: string,
  ) => {
    return (
      <div className={styles.checkName}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                icon={
                  <IconCheckbox
                    width={16}
                    height={16}
                  />
                }
                checkedIcon={
                  <IconCheckboxChecked
                    width={16}
                    height={16}
                  />
                }
                className={styles.checkboxRating}
                checked={checked}
                onChange={onChange}
                value={checked}
                disabled={disabled}
              />
            }
            label={label}
          />
        </FormGroup>
      </div>
    );
  };

  renderRateType = (disabled: boolean, provider: string) => {
    return (
      <div className={styles.rateContainer}>
        <div className={styles.labelName}>Rate Types </div>
        <div className={styles.description}>
          Select which rate types are available to book.
        </div>
        {this.renderFormGroup(
          this.state.prepayCheck,
          this.onChangePrepay,
          disabled,
          'Pre-pay',
        )}
        {this.renderFormGroup(
          this.state.postpayCheck,
          this.onChangePostPay,
          disabled,
          'Post-pay',
        )}

        {provider === 'travelport' && (
          <React.Fragment>
            <h3>Special Rates</h3>

            {this.renderFormGroup(
              this.state.seniorRateCheck,
              this.onChangeSeniorRate,
              disabled,
              'Senior Discount',
            )}
            {this.renderFormGroup(
              this.state.aaaRateCheck,
              this.onChangeAAA,
              disabled,
              'AAA Rate',
            )}
            {this.renderFormGroup(
              this.state.aarpRateCheck,
              this.onChangeAARP,
              disabled,
              'AARP Rate',
            )}
            {this.renderFormGroup(
              this.state.governmentRateCheck,
              this.onChangeGovernmentRate,
              disabled,
              'Government Rate',
            )}
          </React.Fragment>
        )}
      </div>
    );
  };

  onSubmitUpdate = () => {
    let agencySetting = _.clone(this.state.agencySetting);

    if (this.state.provider == 'travelport') {
      if (this.state.type == 'cars' || this.state.type == 'flights') {
        if (
          agencySetting.travelport?.pcc ||
          agencySetting.travelport?.targetBranch
        ) {
          _.set(
            agencySetting,
            `${this.state.type}.travelport.pcc`,
            this.state.pcc,
          );
          _.set(
            agencySetting,
            `${this.state.type}.travelport.targetBranch`,
            this.state.targetBranch,
          );
        }
      }

      if (this.state.type == 'hotels') {
        if (this.state.iataNr || this.state.pcc || this.state.accessProfile) {
          _.set(agencySetting, 'hotels.travelport.pcc', this.state.pcc);
          _.set(
            agencySetting,
            'hotels.travelport.accessProfile',
            this.state.accessProfile,
          );
          _.set(agencySetting, 'hotels.travelport.iataId', this.state.iataNr);
        }
        _.set(
          agencySetting,
          `hotels.${this.state.provider}.postpay`,
          this.state.postpayCheck,
        );
        _.set(
          agencySetting,
          `hotels.${this.state.provider}.prepay`,
          this.state.prepayCheck,
        );
        if (this.state.provider === 'travelport') {
          this.props.putSpecialRateCodes(
            this.props.organizationId,
            this.state.specialRates,
          );
        }
      }
    }

    if (
      _.get(this.state, `agencySetting.${this.state.type}`, {}).hasOwnProperty(
        'sabre',
      ) &&
      this.state.provider == 'sabre'
    ) {
      _.set(agencySetting, `${this.state.type}.sabre.pcc`, this.state.pcc);
    }

    if (this.state.type == 'hotels') {
      if (
        _.get(this.state, `agencySetting.hotels`, {}).hasOwnProperty(
          this.state.provider,
        ) ||
        this.state.provider == 'expedia'
      ) {
        _.set(
          agencySetting,
          `hotels.${this.state.provider}.postpay`,
          this.state.postpayCheck,
        );
        _.set(
          agencySetting,
          `hotels.${this.state.provider}.prepay`,
          this.state.prepayCheck,
        );
      }
    }

    if (this.props.organizationId) {
      this.props.putAgency(this.props.organizationId, {
        settings: agencySetting,
      });
    } else {
      this.props.putAgency(agencySetting);
    }

    this.setState({
      openPopup: false,
    });
  };

  onDeletePcc = () => {
    this.setState({
      pcc: '',
    });
  };

  onDeleteTargetBranch = () => {
    this.setState({
      pcc: '',
    });
  };

  handleCustomCredentialsChange = (event: any) => {
    let agencySetting = this.state.agencySetting;

    if (event.target.checked) {
      if (this.state.provider == 'travelport') {
        if (this.state.type == 'cars' || this.state.type == 'flights') {
          _.set(
            agencySetting,
            `${this.state.type}.travelport.pcc`,
            _.cloneDeep(
              _.get(
                this.state,
                `agencySetting.${this.state.type}.travelport.pcc`,
                '',
              ),
            ),
          );
          _.set(
            agencySetting,
            `${this.state.type}.travelport.targetBranch`,
            _.cloneDeep(
              _.get(
                this.state,
                `agencySetting.${this.state.type}.travelport.targetBranch`,
                '',
              ),
            ),
          );
        }

        if (this.state.type == 'hotels') {
          _.set(
            agencySetting,
            'hotels.travelport.pcc',
            _.cloneDeep(
              _.get(this.state, `agencySetting.hotels.travelport.pcc`, ''),
            ),
          );
          _.set(
            agencySetting,
            'hotels.travelport.accessProfile',
            _.cloneDeep(
              _.get(
                this.state,
                `agencySetting.hotels.travelport.accessProfile`,
                '',
              ),
            ),
          );
          _.set(
            agencySetting,
            'hotels.travelport.iataId',
            _.cloneDeep(
              _.get(this.state, `agencySetting.hotels.travelport.iataId`, ''),
            ),
          );
        }
      }

      if (this.state.provider == 'sabre') {
        _.set(
          agencySetting,
          `${this.state.type}.sabre.pcc`,
          _.cloneDeep(
            _.get(this.state, `agencySetting.${this.state.type}.sabre.pcc`, ''),
          ),
        );
      }
    } else {
      delete agencySetting[this.state.type][this.state.provider];
    }

    this.setState({
      agencySetting: agencySetting,
    });
  };

  handleEnabledChange = (event: any) => {
    const type = this.state.type.slice(0, -1);
    let agencySetting = _.cloneDeep(this.state.agencySetting);
    let agencySettingProvider = _.cloneDeep(
      _.get(agencySetting, `${type}Providers`, []),
    );

    if (event.target.checked) {
      agencySettingProvider.push(this.state.provider);
    } else {
      _.remove(agencySettingProvider, (v) => this.state.provider === v);
    }
    _.set(agencySetting, `${type}Providers`, agencySettingProvider);

    const isEnabled = _.get(agencySetting, `${type}Providers`, []).includes(
      this.state.provider,
    );
    this.setState({
      agencySetting: agencySetting,
      enabledSwitch: isEnabled,
    });
  };

  onChangePCC = (event: any) => {
    this.setState({
      pcc: event.target.value,
    });
  };

  onChangeTargetBranch = (event: any) => {
    this.setState({
      targetBranch: event.target.value,
    });
  };

  onChangeIata = (event: any) => {
    this.setState({
      iataNr: event.target.value,
    });
  };

  onChangePrepay = () => {
    this.setState({
      prepayCheck: !this.state.prepayCheck,
    });
  };

  onChangePostPay = () => {
    this.setState({
      postpayCheck: !this.state.postpayCheck,
    });
  };

  onChangeAARP = () => {
    this.setState({
      aarpRateCheck: !this.state.aarpRateCheck,
    });
    this.updateSpecialRate('ARP');
  };
  onChangeAAA = () => {
    this.setState({
      aaaRateCheck: !this.state.aaaRateCheck,
    });
    this.updateSpecialRate('AAA');
  };
  onChangeGovernmentRate = () => {
    this.setState({
      governmentRateCheck: !this.state.governmentRateCheck,
    });
    this.updateSpecialRate('GOV');
  };
  onChangeSeniorRate = () => {
    this.setState({
      seniorRateCheck: !this.state.seniorRateCheck,
    });
    this.updateSpecialRate('SNR');
  };

  onDeleteIata = (event: any) => {
    this.setState({
      iataNr: '',
    });
  };

  onChangeAccessProfile = (event: any) => {
    this.setState({
      accessProfile: event.target.value,
    });
  };

  onDeleteAccessProfile = (event: any) => {
    this.setState({
      accessProfile: '',
    });
  };

  renderPopupBody = (disabled: boolean) => {
    if (this.state.enabledSwitchOnly) {
      if (this.state.type == 'hotels') {
        return this.renderRateType(disabled, this.state.provider);
      }

      return null;
    }

    if (this.state.provider == 'sabre') {
      return (
        <>
          <div className={styles.inputContainerPop}>
            <div className={styles.inputWrapper}>
              <TextFieldWithClear
                onChange={this.onChangePCC}
                fullWidth
                label="PCC"
                variant="outlined"
                margin="normal"
                value={this.state.pcc}
                onClear={this.onDeletePcc}
                disabled={disabled || !this.customCredentialsEnabled()}
              />
            </div>
          </div>
          {this.state.type == 'hotels' && this.customCredentialsEnabled() && (
            <>{this.renderRateType(disabled, this.state.provider)}</>
          )}
        </>
      );
    }

    if (this.state.type == 'cars' || this.state.type == 'flights') {
      return (
        <div className={styles.inputContainerPop}>
          <div className={styles.inputWrapper}>
            <TextFieldWithClear
              onChange={this.onChangePCC}
              fullWidth
              label="PCC"
              variant="outlined"
              margin="normal"
              value={this.state.pcc}
              onClear={this.onDeletePcc}
              disabled={disabled || !this.customCredentialsEnabled()}
            />
          </div>
          <div className={styles.inputWrapper}>
            <TextFieldWithClear
              onChange={this.onChangeTargetBranch}
              fullWidth
              label="Target Branch"
              variant="outlined"
              margin="normal"
              value={this.state.targetBranch}
              onClear={this.onDeleteTargetBranch}
              disabled={disabled || !this.customCredentialsEnabled()}
            />
          </div>
        </div>
      );
    }
    if (this.state.type == 'hotels') {
      return (
        <div>
          <div className={styles.inputContainerPop}>
            <div className={styles.inputWrapper}>
              <TextFieldWithClear
                onChange={this.onChangePCC}
                fullWidth
                label="PCC"
                variant="outlined"
                margin="normal"
                value={this.state.pcc}
                onClear={this.onDeletePcc}
                disabled={disabled || !this.customCredentialsEnabled()}
              />
            </div>
            <div className={styles.inputWrapper}>
              <TextFieldWithClear
                onChange={this.onChangeAccessProfile}
                fullWidth
                label="Access Profile"
                variant="outlined"
                margin="normal"
                value={this.state.accessProfile}
                onClear={this.onDeleteAccessProfile}
                disabled={disabled || !this.customCredentialsEnabled()}
              />
            </div>
            <div className={styles.inputWrapper}>
              <TextFieldWithClear
                onChange={this.onChangeIata}
                fullWidth
                label="IATA Number"
                variant="outlined"
                margin="normal"
                value={this.state.iataNr}
                onClear={this.onDeleteIata}
                disabled={disabled || !this.customCredentialsEnabled()}
              />
            </div>
          </div>
          {this.renderRateType(disabled, this.state.provider)}
        </div>
      );
    }
    return null;
  };

  editProvider = (type: any, provider: any) => {
    const providerCapitalized =
      provider.charAt(0).toUpperCase() + provider.slice(1);
    const typeCapitalized = type.charAt(0).toUpperCase() + type.slice(1);
    const title = `Update ${providerCapitalized} Details - ${typeCapitalized}`;
    const enabledSwitchOnly =
      provider === 'travelport' || provider === 'sabre' ? false : true;
    const targetBranch = _.get(
      this.props.agencyDetails,
      `${type}.${provider}.targetBranch`,
      undefined,
    );
    const pcc = _.get(
      this.props.agencyDetails,
      `${type}.${provider}.pcc`,
      undefined,
    );
    const accessProfile = _.get(
      this.props.agencyDetails,
      `${type}.${provider}.accessProfile`,
      '',
    );
    const iataNr = _.get(
      this.props.agencyDetails,
      `${type}.${provider}.iataId`,
      '',
    );
    const prepay = _.get(
      this.props.agencyDetails,
      `${type}.${provider}.prepay`,
      false,
    );
    const postpay = _.get(
      this.props.agencyDetails,
      `${type}.${provider}.postpay`,
      false,
    );
    const providerName = `${type.slice(0, -1)}Providers`;
    let isEnabled = _.get(
      this.props.agencyDetails,
      `${providerName}`,
      [],
    ).includes(provider);

    if (this.props.organizationId) {
      isEnabled =
        _.get(this.props, `agency.settings.${providerName}`, []).includes(
          provider,
        ) &&
        (!_.get(this.props, `agencyDetails.${providerName}`, null) ||
          (_.get(this.props, `agencyDetails.${providerName}`, null) &&
            _.get(this.props, `agencyDetails.${providerName}`, []).includes(
              provider,
            )));
    }

    this.setState({
      type: type,
      provider: provider,
      enabledSwitchOnly: enabledSwitchOnly,
      title: title,
      openPopup: true,
      enabledSwitch: isEnabled,
      targetBranch: targetBranch,
      pcc: pcc,
      accessProfile: accessProfile,
      iataNr: iataNr,
      prepayCheck: prepay,
      postpayCheck: postpay,
    });
  };

  renderFlightsProvider = (disabled: any, title?: any) => {
    return (
      <Provider
        disabled={disabled}
        possibleProviders={['sabre', 'travelport', 'duffel']}
        type="flights"
        title={title}
        providers={_.get(this.props, 'agencyDetails.flightProviders', null)}
        providerName={'flightProviders'}
        editProvider={this.editProvider}
        componentStyle={true}
        organizationAgency={
          this.props.organizationId ? _.get(this.props, 'agency', {}) : null
        }
      />
    );
  };

  renderHotelsProvider = (disabled: any, title?: any) => {
    return (
      <Provider
        disabled={disabled}
        possibleProviders={['expedia', 'sabre', 'travelport']}
        type="hotels"
        title={title}
        providers={_.get(this.props, 'agencyDetails.hotelProviders', null)}
        providerName={'hotelProviders'}
        editProvider={this.editProvider}
        componentStyle={true}
        organizationAgency={
          this.props.organizationId ? _.get(this.props, 'agency', {}) : null
        }
      />
    );
  };

  renderCarsProvider = (disabled: any, title?: any) => {
    return (
      <Provider
        disabled={disabled}
        possibleProviders={['sabre', 'travelport']}
        type="cars"
        title={title}
        providers={_.get(this.props, 'agencyDetails.carProviders', null)}
        providerName={'carProviders'}
        editProvider={this.editProvider}
        componentStyle={true}
        organizationAgency={
          this.props.organizationId ? _.get(this.props, 'agency', {}) : null
        }
      />
    );
  };

  customCredentialsEnabled = () => {
    if (this.state.provider === 'travelport' && this.state.type === 'hotels') {
      return _.get(
        this.state.agencySetting,
        'hotels.travelport',
        {},
      ).hasOwnProperty('pcc');
    }
    return _.get(this.state.agencySetting, this.state.type, {}).hasOwnProperty(
      this.state.provider,
    );
  };

  renderTab = (disabled: any) => {
    switch (this.state.tabValue) {
      case 'flights':
        return <div>{this.renderFlightsProvider(disabled)}</div>;
        break;
      case 'hotels':
        return <div>{this.renderHotelsProvider(disabled)}</div>;
        break;
      default:
        return <div>{this.renderCarsProvider(disabled)}</div>;
        break;
    }
  };

  checkUpdatePermission = () => {
    if (this.props.organizationId) {
      return !hasPermission('organizations', 'update');
    }

    return !hasPermission('agency', 'update');
  };

  render() {
    const disabled = this.checkUpdatePermission();

    return (
      <div>
        <h2>Inventory Suppliers</h2>

        {this.props.isLoading && (
          <div>
            <Loader visible={this.props.isLoading} />
          </div>
        )}

        <Tabs
          value={this.state.tabValue}
          indicatorColor="primary"
          textColor="primary"
          onChange={this.handleTabChange}
          className={cx(styles.tabs, styles.loyalityTabs)}
        >
          <Tab
            disableRipple
            className={styles.tab}
            label="Flights"
            value="flights"
          />
          <Tab
            disableRipple
            className={styles.tab}
            label="Hotels"
            value="hotels"
          />
          <Tab
            disableRipple
            className={styles.tab}
            label="Cars"
            value="cars"
          />
        </Tabs>

        {this.renderTab(disabled)}
        <UpdatePopup
          open={this.state.openPopup}
          onClose={this.closePopup}
          onSubmit={this.onSubmitUpdate}
          title={this.state.title}
          isLoading={false}
          type={this.state.type}
          provider={this.state.provider}
          enabledSwitchOnly={this.state.enabledSwitchOnly}
          updatePermission={!disabled}
          handleCustomCredentialsChange={this.handleCustomCredentialsChange}
          handleEnabledChange={this.handleEnabledChange}
          body={this.renderPopupBody(disabled)}
          enabledSwitch={this.state.enabledSwitch}
          customCredentials={this.customCredentialsEnabled()}
          disabled={disabled}
        />
      </div>
    );
  }
}

export default AgencyInventorySuppliers;

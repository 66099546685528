import _ from 'lodash-es';

import {
  ACTION_POST_CREDITCARD,
  ACTION_POST_CREDITCARD_FAILURE,
  ACTION_POST_CREDITCARD_SUCCESS,
  ACTION_RESET_CREDITCARD,
} from '../configs/ActionTypes';

const initialState = {
  createCreditCardAttempt: false,
  success: null,
  card: null,
  in_process: false,
  errors: {
    name: '',
    ccNumber: '',
    cvv: '',
    expiry: '',
    country: '',
    postalCode: '',
  },
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_POST_CREDITCARD_SUCCESS: {
      const payload = action.payload.payload;

      const card = _.get(payload, 'card', null);

      return {
        ...state,
        createCreditCardAttempt: false,
        success: true,
        in_process: false,
        card,
        errors: {
          name: '',
          ccNumber: '',
          cvv: '',
          expiry: '',
          country: '',
          postalCode: '',
        },
      };

      break;
    }

    case ACTION_POST_CREDITCARD_FAILURE: {
      const payload = action.payload.payload;

      const name =
        _.get(payload, 'nameEmpty', false) === true ? 'Required' : '';

      let ccNumber = '';
      if (_.get(payload, 'ccNumberEmpty', false) === true) {
        ccNumber = 'Required';
      } else if (_.get(payload, 'ccNumberInvalid', false) === true) {
        ccNumber = 'Invalid credit card number';
      }

      let cvc = '';
      if (_.get(payload, 'cvvEmpty', false) === true) {
        cvc = 'Required';
      } else if (_.get(payload, 'cvvInvalid', false) === true) {
        cvc = 'Invalid credit card cvc number';
      } else if (_.get(payload, 'cvvTooShort', false) === true) {
        cvc = 'CVC number too short';
      } else if (_.get(payload, 'cvvTooLong', false) === true) {
        cvc = 'CVC number too long';
      }

      let postalCode = '';
      if (_.get(payload, 'postalCodeEmpty', false) === true) {
        postalCode = 'Required';
      } else if (_.get(payload, 'postalCodeInvalid', false) === true) {
        postalCode = 'Invalid postal code';
      }

      const country =
        _.get(payload, 'countryEmpty', false) === true ? 'Required' : '';

      let expiry = '';
      if (_.get(payload, 'expiryEmpty', false) === true) {
        expiry = 'Required';
      } else if (_.get(payload, 'expiryInvalid', false) === true) {
        expiry = 'Invalid credit card expiry';
      } else if (_.get(payload, 'expiryExpired', false) === true) {
        expiry = 'Expired credit card';
      }

      return {
        ...state,
        createCreditCardAttempt: false,
        success: false,
        in_process: false,
        errors: {
          name,
          ccNumber,
          cvc,
          postalCode,
          country,
          expiry,
        },
      };

      break;
    }

    case ACTION_POST_CREDITCARD: {
      return {
        ...state,
        success: null,
        in_process: true,
        createCreditCardAttempt: true,
      };

      break;
    }

    case ACTION_RESET_CREDITCARD: {
      return {
        ...state,
        createCreditCardAttempt: false,
        success: null,
        in_process: false,
        errors: {
          name: '',
          ccNumber: '',
          cvv: '',
          expiry: '',
          country: '',
          postalCode: '',
        },
      };

      break;
    }

    default: {
      return state;
    }
  }
}

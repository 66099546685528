import React from 'react';

type Props = {
  width: number;
  height: number;
};

const SvgIconSuccess = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Components"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Toast"
          transform="translate(-96.000000, -257.000000)"
          fill="#12DA9F"
          fillRule="nonzero"
        >
          <g
            id="Success-"
            transform="translate(80.000000, 243.000000)"
          >
            <path
              d="M28,36.5 C33.7989899,36.5 38.5,31.7989899 38.5,26 C38.5,20.2010101 33.7989899,15.5 28,15.5 C22.2010101,15.5 17.5,20.2010101 17.5,26 C17.5,31.7989899 22.2010101,36.5 28,36.5 Z M28,38 C21.372583,38 16,32.627417 16,26 C16,19.372583 21.372583,14 28,14 C34.627417,14 40,19.372583 40,26 C40,32.627417 34.627417,38 28,38 Z M26.2920865,28.9260819 L33.2395683,21.9786001 C33.5324615,21.6857069 34.0073352,21.6857069 34.3002284,21.9786001 C34.5931216,22.2714933 34.5931216,22.7463671 34.3002284,23.0392603 L26.2920865,31.0474022 L22.2196699,26.9749857 C21.9267767,26.6820924 21.9267767,26.2072187 22.2196699,25.9143255 C22.5125631,25.6214323 22.9874369,25.6214323 23.2803301,25.9143255 L26.2920865,28.9260819 Z"
              id="Combined-Shape"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

SvgIconSuccess.defaultProps = {
  width: 24,
  height: 24,
};

export default SvgIconSuccess;

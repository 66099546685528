import * as React from 'react';
import _ from 'lodash-es';
import cx from 'classnames';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import CustomSelect from 'components/Inputs/Select/Select';
import UsersRecordForm from '../RecordForm/Users';
import CompaniesRecordForm from '../RecordForm/Companies';
import AgenciesRecordForm from '../RecordForm/Agencies';
import CreditCardRecordForm from '../RecordForm/CreditCard';
import FlightsRecordForm from '../RecordForm/Flights';
import CarsRecordForm from '../RecordForm/Cars';
import HotelsRecordForm from '../RecordForm/Hotels';
import TravelersRecordForm from '../RecordForm/Travelers';

import { hasPermission } from '../../../helpers/Permission';

const styles = require('./styles.pcss');

class Header extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      typeOfRecord: '',
      typeOfBooking: '',
    };
  }

  componentDidMount = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const sPageURL = window.location.search.substring(1);
    const sURLVariables = sPageURL.split('&');

    if (urlParams.get('type')) {
      this.setState({
        typeOfRecord: urlParams.get('type'),
        typeOfBooking: urlParams.get('bookingtype')
          ? urlParams.get('bookingtype')
          : '',
      });
      if (
        (urlParams.get('type') == 'booking' && sURLVariables.length == 1) ||
        (urlParams.get('type') == 'booking' &&
          urlParams.get('bookingtype') &&
          sURLVariables.length == 2)
      ) {
        this.searchBookingInitial();
      }
    }
  };

  onChangeRecordType = (event: any) => {
    window.history.pushState({}, '', `/lookup?type=${event.target.value}`);

    this.setState({
      typeOfRecord: event.target.value,
      typeOfBooking: '',
    });

    this.props.onChangeRecordType();

    if (event.target.value == 'booking') {
      this.searchBookingInitial();
    }
  };

  onChangeBookingType = (event: any) => {
    window.history.pushState(
      {},
      '',
      `/lookup?type=booking&bookingtype=${event.target.value}`,
    );
    this.setState({
      typeOfBooking: event.target.value,
    });
  };

  renderRecordForm = () => {
    switch (this.state.typeOfRecord) {
      case 'agency': {
        return <AgenciesRecordForm onSubmit={this.searchRecord} />;
        break;
      }

      case 'user': {
        return <UsersRecordForm onSubmit={this.searchRecord} />;
        break;
      }

      case 'traveler': {
        return <TravelersRecordForm onSubmit={this.searchRecord} />;
        break;
      }

      case 'company': {
        return <CompaniesRecordForm onSubmit={this.searchRecord} />;
        break;
      }

      case 'creditcard': {
        return <CreditCardRecordForm onSubmit={this.searchRecord} />;
        break;
      }

      case 'booking': {
        switch (this.state.typeOfBooking) {
          case 'car': {
            return <CarsRecordForm onSubmit={this.searchRecord} />;
            break;
          }
          case 'flight': {
            return <FlightsRecordForm onSubmit={this.searchRecord} />;
            break;
          }
          case 'hotel': {
            return <HotelsRecordForm onSubmit={this.searchRecord} />;
            break;
          }
          default:
            return null;
            break;
        }
      }

      default:
        return null;
        break;
    }
  };

  searchRecord = (searchParams: any) => {
    this.props.searchRecord({
      searchParams: searchParams,
      type:
        this.state.typeOfBooking === ''
          ? this.state.typeOfRecord
          : this.state.typeOfBooking,
    });
  };

  searchBookingInitial = () => {
    this.props.searchRecord({
      searchParams: {},
      type: 'booking',
    });
  };

  renderBookingSelect = () => {
    if (this.state.typeOfRecord == 'booking') {
      return (
        <>
          <CustomSelect
            margin={'normal'}
            variant={'outlined'}
            className={styles.selectWrapper}
            disabled={this.props.readOnly}
            htmlFor={'input-type'}
            label={'Type'}
            id={'input-type'}
            value={this.state.typeOfBooking}
            onChange={this.onChangeBookingType}
            fullWidth={true}
            labelWidth={34}
            inputName={'type'}
            MenuProps={{
              style: { marginTop: 4, boxShadow: 'none' },
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
            }}
            menuItems={[
              {
                key: 'car',
                value: 'car',
                label: 'Car',
              },
              {
                key: 'flight',
                value: 'flight',
                label: 'Flight',
              },
              {
                key: 'hotel',
                value: 'hotel',
                label: 'Hotel',
              },
            ]}
          />
        </>
      );
    }

    return null;
  };

  setMenuItems = (
    menuItems: any,
    resource: any,
    premission: any,
    key: any,
    label: any,
  ) => {
    if (hasPermission(resource, premission)) {
      menuItems.push({
        key: key,
        value: key,
        label: label,
      });
    }
    return menuItems;
  };

  render() {
    let menuItems: any = [];

    const loginData = localStorage.getItem('login_data');
    let loginDataperse: any = null;
    if (loginData) {
      loginDataperse = JSON.parse(loginData);
    }

    menuItems = this.setMenuItems(
      menuItems,
      'agency',
      'read',
      'agency',
      'Agency',
    );
    menuItems = this.setMenuItems(
      menuItems,
      'bookings',
      'read',
      'booking',
      'Booking',
    );
    menuItems = this.setMenuItems(
      menuItems,
      'organizations',
      'read',
      'company',
      'Company',
    );
    menuItems = this.setMenuItems(
      menuItems,
      'creditCards',
      'read',
      'creditcard',
      'Credit Card',
    );
    menuItems = this.setMenuItems(
      menuItems,
      'travelers',
      'read',
      'traveler',
      'Traveler',
    );
    menuItems = this.setMenuItems(menuItems, 'users', 'read', 'user', 'User');

    return (
      <div className={styles.header}>
        <Paper className={styles.paper}>
          <h1 className={styles.h1}>Lookup</h1>
          <div className={styles.form}>
            <CustomSelect
              margin={'normal'}
              variant={'outlined'}
              className={styles.selectWrapper}
              disabled={this.props.readOnly}
              htmlFor={'input-type'}
              label={'Type of Record'}
              id={'type-of-record'}
              value={this.state.typeOfRecord}
              onChange={this.onChangeRecordType}
              fullWidth={true}
              labelWidth={110}
              inputName={'type'}
              MenuProps={{
                style: { marginTop: 4, boxShadow: 'none' },
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
              }}
              menuItems={menuItems}
            />

            {this.renderBookingSelect()}
          </div>

          {this.renderRecordForm()}
        </Paper>
      </div>
    );
  }
}

export default Header;

import * as React from 'react';
import cx from 'classnames';
const styles = require('./styles.pcss');

type Props = {
  children: React.ReactNode;
  className?: any;
};

const Breadcrumbs = (props: Props) => {
  let className = styles.breadcrumbs;
  if (props.className) {
    className = cx(styles.breadcrumbs, props.className);
  }

  return <div className={className}>{props.children}</div>;
};

export default Breadcrumbs;

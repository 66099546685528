/*
 * API middleware implementation
 */
import { API_URL, AUTH_HEADER } from '../configs/Api';
import {
  ACTION_REDIRECT_LOGIN,
  REDIRECT_NO_ACCESS,
} from '../configs/ActionTypes';
import _ from 'lodash-es';
import queryString from 'query-string';

const getHeaders = (type) => {
  let apiHeaders = {
    'Content-Type': 'application/json',
  };

  if (type === 'form') {
    delete apiHeaders['Content-Type'];
  }

  let loginData = JSON.parse(localStorage.getItem('login_data'));

  apiHeaders['Authorization'] = `Bearer ${_.get(loginData, 'token', null)}`;

  return apiHeaders;
};

const getEndpointUrl = (action) => {
  const endpoint = _.get(action, 'payload.endpoint', 'api');
  let apiCall = null;

  switch (endpoint) {
    default: {
      apiCall = API_URL;
      break;
    }
  }

  apiCall += _.get(action, 'payload.call', '');

  return apiCall;
};

/**
 * Send GET request and return the Promise
 */
const reqGet = (action) => {
  let data = _.get(action, 'payload.data', {});

  const qs = queryString.stringify(data, { arrayFormat: 'bracket' });
  let apiCall = getEndpointUrl(action);

  if (qs) {
    apiCall += `?${qs}`;
  }

  return fetch(apiCall, {
    method: 'get',
    headers: getHeaders(),
  });
};

/**
 * Send POST request and return the Promise
 */
const reqPost = (action) => {
  let data = _.get(action, 'payload.data', null);
  let type = _.get(action, 'payload.type', 'json');

  const apiCall = getEndpointUrl(action);

  return fetch(apiCall, {
    method: 'post',
    headers: getHeaders(type),
    body: data,
  });
};

/**
 * Send PUT request and return the Promise
 */
const reqPut = (action) => {
  let data = _.get(action, 'payload.data', {});

  const apiCall = getEndpointUrl(action);

  return fetch(apiCall, {
    method: 'put',
    headers: getHeaders(),
    body: data,
  });
};

/**
 * Send DELETE request and return the Promise
 */
const reqDelete = (action) => {
  let data = _.get(action, 'payload.data', {});

  const apiCall = getEndpointUrl(action);

  return fetch(apiCall, {
    method: 'delete',
    headers: getHeaders(),
    body: data,
  });
};

const validateAction = (action) => {
  let valid = true;

  valid = _.has(action, 'payload');
  valid = _.has(action, 'payload.call');
  valid = _.has(action, 'payload.method');

  return valid;
};

/**
 * ApiMiddleware will create promises for API_CALL type actions.
 */
const ApiMiddleware = (store) => (next) => (action) => {
  if (action.type !== 'API_CALL' || validateAction(action) !== true) {
    return next(action);
  }

  const requestMethod = action.payload.method.toLowerCase();

  if (_.includes(['get', 'post', 'put', 'delete'], requestMethod) === false) {
    return next(action);
  }

  // Optimistic validation of success / fail functions
  const funcSuccess = _.get(action, 'payload.success', function () {});
  const funcFail = _.get(action, 'payload.fail', function () {});
  const isDownload = _.get(action, 'payload.isDownload', false);
  const isFileURL = _.get(action, 'payload.isFileURL', false);

  // Send the request

  const requestFunc = `req${requestMethod
    .charAt(0)
    .toUpperCase()}${requestMethod.slice(1)}(action)`;

  const request = eval(requestFunc)
    .then((response) => {
      let resp = response.json();
      resp
        .then((jsonData) => {
          if (response.ok === true) {
            funcSuccess(jsonData);
          } else {
            // Unauthorized redirect to the login page
            if (response.status === 401) {
              if (_.get(action, 'payload.call', null) !== 'login') {
                store.dispatch({
                  type: REDIRECT_NO_ACCESS,
                  payload: jsonData.error,
                });
              } else {
                funcFail(jsonData);
              }
            }
            // Forbidden
            else if (response.status === 403) {
              store.dispatch({
                type: ACTION_REDIRECT_LOGIN,
                payload: jsonData.error,
              });
            } else {
              funcFail(jsonData);
            }
          }
        })
        .catch((error) => funcFail(error));
    })
    .catch((error) => funcFail(error));
};

export default ApiMiddleware;

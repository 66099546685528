import * as React from 'react';
import _ from 'lodash-es';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ReduxState } from 'reducers/types';
import {
  get as getTravelers,
  emptyResults as emptyTravelers,
  Params,
} from 'actions/Travelers/Get';
import { emptyTraveler } from 'actions/Travelers/GetSingle';
import { getSingle as getUser } from 'actions/Users/GetSingle';
import { get as getCreditCards } from 'actions/User/GetUserCreditCards';
import UserDetails from 'components/UserDetails/UserDetail';

type Props = any;
class PageUserDetails extends React.Component<Props, any> {
  componentDidMount() {
    const params: Params = {
      userId: this.props.userId,
    };

    this.props.getUser(this.props.userId);
    this.props.getTravelers(params);
    this.props.emptyTraveler();
    this.props.getCreditCards(this.props.userId);
  }

  componentWillUnmount() {
    this.props.emptyTravelers();
  }

  render() {
    return (
      <UserDetails
        userId={_.get(this.props, 'userId', null)}
        travelerId={_.get(this.props, 'location.state.travelerId', null)} // if coming from Lookup Traveler / travelerId needed for traveler dropdown pre selection
      />
    );
  }
}

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {};
}

// Wire up redux dispatch functions
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getTravelers,
      getUser,
      emptyTraveler,
      getCreditCards,
      emptyTravelers,
    },
    dispatch,
  );
}

// Connect it to Redux
export default connect(mapStateToProps, mapDispatchToProps)(PageUserDetails);

import * as React from 'react';
import _ from 'lodash-es';
import Paper from '@material-ui/core/Paper';
import cx from 'classnames';

const styles = require('./styles.pcss');

type Props = {
  ticketNumber: string;
  operatedBy: string;
  organizationId: string;
  source: string;
  clickViewPolicy: any;
};

class AdditionalFlightDetails extends React.Component<Props, any> {
  render() {
    const {
      ticketNumber,
      operatedBy,
      source,
      organizationId,
      clickViewPolicy,
    } = this.props;

    return (
      <Paper
        className={cx(styles.bookingAdditionalDetails, styles.bookingDetails)}
      >
        <div className={styles.header}>
          <h1 className={styles.additionalDetailsTitle}>
            Additional Flight Details
          </h1>
        </div>
        <div className={styles.additionalFlightDetails}>
          <div className={styles.infoContainer}>
            <div className={styles.Info}>
              <div className={styles.additionalInfoRow}>
                <p>
                  {' '}
                  Ticket Number: <span id="gender">{ticketNumber}</span>
                </p>
                <p>
                  {' '}
                  Operated By: <span id="gender">{operatedBy}</span>
                </p>
                <p>
                  {' '}
                  Source: <span id="source">{source}</span>
                </p>
                <p>
                  {' '}
                  Organization ID/Name:{' '}
                  <span id="organizationId">{organizationId}</span>
                </p>
                <p>
                  {' '}
                  Travel Policy:{' '}
                  <span
                    onClick={(e) => clickViewPolicy()}
                    className={styles.linkText}
                  >
                    View Here
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Paper>
    );
  }
}

export default AdditionalFlightDetails;

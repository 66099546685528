import {
  ACTION_GET_ROLES,
  ACTION_GET_ROLES_SUCCESS,
  ACTION_GET_ROLES_FAILURE,
  ACTION_EMPTY_ROLES,
} from '../configs/ActionTypes';

const initialState = {
  roles: [],
  isLoading: false,
  success: null,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_GET_ROLES: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ACTION_EMPTY_ROLES: {
      return initialState;
    }

    case ACTION_GET_ROLES_SUCCESS: {
      return {
        ...state,
        roles: action.payload.payload,
        isLoading: false,
        success: true,
      };
    }

    case ACTION_GET_ROLES_FAILURE: {
      return {
        ...state,
        isLoading: false,
        success: false,
      };
    }

    default: {
      return state;
    }
  }
}

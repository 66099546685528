import * as React from 'react';
import cx from 'classnames';
import CancelIcon from '@material-ui/icons/Cancel';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';

const styles = require('./styles.pcss');

type Props = {
  onChange: any;
  margin?: any;
  value: any;
  onClear: any;
  disabled?: any;
  error?: any;
  fullWidth?: any;
  className?: any;
  inputComponent?: any;
  id?: any;
  labelWidth?: any;
  name?: any;
  type?: any;
};

const TextFieldWithClear = (props: Props) => {
  let className = styles.outlinedInputClear;

  if (props.className) {
    className = cx(styles.outlinedInputClear, props.className);
  }

  const renderAdornment = (props: Props) => {
    if (props.value.trim() !== '') {
      return (
        <InputAdornment
          className={styles.close}
          position="end"
          onClick={props.onClear}
          disablePointerEvents={props.disabled}
        >
          <CancelIcon className={styles.icon} />
        </InputAdornment>
      );
    }
    return null;
  };

  return (
    <OutlinedInput
      disabled={props.disabled}
      onChange={props.onChange}
      error={props.error}
      margin={props.margin}
      value={props.value}
      fullWidth={props.fullWidth}
      className={className}
      labelWidth={props.labelWidth}
      name={props.name}
      id={props.id}
      inputComponent={props.inputComponent}
      endAdornment={renderAdornment(props)}
      type={props.type ? props.type : 'text'}
    />
  );
};

export default TextFieldWithClear;

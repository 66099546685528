import React from 'react';

type Props = {
  width: number;
  height: number;
};

const SvgIconError = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Components"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Toast"
          transform="translate(-591.000000, -257.000000)"
          fill="#FC3B60"
          fillRule="nonzero"
        >
          <g
            id="Error"
            transform="translate(575.000000, 243.000000)"
          >
            <path
              d="M28,36.5 C33.7989899,36.5 38.5,31.7989899 38.5,26 C38.5,20.2010101 33.7989899,15.5 28,15.5 C22.2010101,15.5 17.5,20.2010101 17.5,26 C17.5,31.7989899 22.2010101,36.5 28,36.5 Z M28,38 C21.372583,38 16,32.627417 16,26 C16,19.372583 21.372583,14 28,14 C34.627417,14 40,19.372583 40,26 C40,32.627417 34.627417,38 28,38 Z M28,19 C28.4142136,19 28.75,19.3357864 28.75,19.75 L28.75,28.25 C28.75,28.6642136 28.4142136,29 28,29 C27.5857864,29 27.25,28.6642136 27.25,28.25 L27.25,19.75 C27.25,19.3357864 27.5857864,19 28,19 Z M28,33 C28.5522847,33 29,32.5522847 29,32 C29,31.4477153 28.5522847,31 28,31 C27.4477153,31 27,31.4477153 27,32 C27,32.5522847 27.4477153,33 28,33 Z"
              id="Combined-Shape"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

SvgIconError.defaultProps = {
  width: 24,
  height: 24,
};

export default SvgIconError;

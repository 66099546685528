/**
 * Update user status
 */
import _ from 'lodash-es';
import { Dispatch } from 'redux';

import {
  ACTION_PUT_USER_STATUS,
  ACTION_PUT_USER_STATUS_SUCCESS,
  ACTION_PUT_USER_STATUS_FAILURE,
  ACTION_PUT_USER_STATUS_RESET,
  ACTION_UPDATE_COMPANY_EMPLOYEES,
  API_CALL,
} from '../../configs/ActionTypes';

import { enqueueSnackbarHandler } from '../Status';

export function updateUserStatus(userId: any, enabled: any) {
  return (dispatch: Dispatch, getState: Function) => {
    dispatch(requestStarted());

    //Dispatch the api call request.
    dispatch({
      type: API_CALL,
      payload: {
        call: `user/${userId}/status`,
        method: 'put',
        data: JSON.stringify(enabled),
        success: (response: object) => {
          enqueueSnackbarHandler(
            dispatch,
            { updateUserStatusSuccess: true },
            'success',
          );
          dispatch(updateCompanyEmployees(response));
          dispatch(requestSuccess(response));
        },
        fail: (error: any) => {
          enqueueSnackbarHandler(dispatch, error.payload, 'error');
          dispatch(requestFailed(error));
        },
      },
    });

    return null;
  };
}

function requestSuccess(responeJSON: object) {
  return {
    type: ACTION_PUT_USER_STATUS_SUCCESS,
    payload: responeJSON,
  };
}

function requestFailed(responeJSON: object) {
  return {
    type: ACTION_PUT_USER_STATUS_FAILURE,
    payload: responeJSON,
  };
}

function updateCompanyEmployees(responseJSON: object) {
  return {
    type: ACTION_UPDATE_COMPANY_EMPLOYEES,
    payload: responseJSON,
  };
}

export function resetUserStatusUpdate() {
  return {
    type: ACTION_PUT_USER_STATUS_RESET,
    payload: null,
  };
}

function requestStarted() {
  return {
    type: ACTION_PUT_USER_STATUS,
    payload: null,
  };
}

import * as React from 'react';
import classNames from 'classnames';
import _ from 'lodash-es';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import TableHeadFilterPopup from './TableHeadFilterPopup';
const styles = require('./styles.pcss');

type Props = {
  tableColumns: Array<any>;
  numSelected: any;
  rowCount: any;
  onSelectAllClick: any;
};

const EmployeeListTableHead = (props: Props) => {
  const tableColumn = (props: any, i: number) => (
    <TableCell
      scope="row"
      key={i}
      className={classNames(styles.empTableHead, styles.empListTableHead)}
    >
      {props.filterOptions ? (
        <TableHeadFilterPopup
          headerName={props.headerName}
          className={styles.inputWrapperPopup}
          id="permissions"
          onChange={props.onSubmit}
          selections={_.get(props, 'filterOptions', [])}
          value={props.value}
          isMultiple={true}
          onClickClear={props.onClear}
          searchable={props.searchable}
          isLoading={props.isLoading}
          getList={props.getList}
          dynamicSearch={props.dynamicSearch}
          removeFirstOptionOnSearch={props.removeFirstOptionOnSearch}
        />
      ) : (
        <>{props.headerName}</>
      )}
    </TableCell>
  );

  const renderCheckBox = () => {
    return (
      <TableCell
        className={classNames(
          styles.tableCellAvatar,
          styles.tableCellAvatarPaddingHead,
          styles.checkBoxPadding,
        )}
      >
        <Checkbox
          indeterminate={
            props.numSelected > 0 && props.numSelected < props.rowCount
          }
          checked={props.numSelected === props.rowCount}
          onChange={props.onSelectAllClick}
          inputProps={{ 'aria-label': 'select all' }}
        />
      </TableCell>
    );
  };

  return (
    <TableHead>
      <TableRow
        className={classNames(styles.empTableHead, styles.empListTableHead)}
      >
        <TableCell
          component="th"
          scope="row"
          className={classNames(styles.cellHide)}
        ></TableCell>

        <TableCell
          component="th"
          scope="row"
          key={'avatar'}
          className={classNames(styles.tableCellAvatar)}
        ></TableCell>
        {props.tableColumns.map(tableColumn)}
        <TableCell
          component="th"
          scope="row"
          className={classNames(styles.cellHide)}
        ></TableCell>
      </TableRow>
    </TableHead>
  );
};

export default EmployeeListTableHead;

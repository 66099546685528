import _ from 'lodash-es';
import { Dispatch } from 'redux';
import { track } from '../../analytics/analytics';

import {
  ACTION_IMPORT_EMPLOYEES_VALIDATE,
  ACTION_IMPORT_EMPLOYEES_VALIDATE_SUCCESS,
  ACTION_IMPORT_EMPLOYEES_VALIDATE_FAILURE,
  ACTION_IMPORT_EMPLOYEES_RESET,
  ACTION_IMPORT_EMPLOYEES_CONFIRM_FAILURE,
  ACTION_IMPORT_EMPLOYEES_CONFIRM_SUCCESS,
  ACTION_IMPORT_EMPLOYEES_CONFIRM,
  API_CALL,
} from '../../configs/ActionTypes';

import { enqueueSnackbarHandler } from '../Status';

export function resetImportEmployees() {
  return {
    type: ACTION_IMPORT_EMPLOYEES_RESET,
    payload: null,
  };
}
let uploadCSVResponse: any = '';

export function confirmCSVImport(organizationId: string, importId: string) {
  return (dispatch: Dispatch, getState: Function) => {
    dispatch(confirmRequestStarted());

    dispatch({
      type: API_CALL,
      payload: {
        call: 'organization/import/confirm',
        method: 'post',
        data: JSON.stringify({
          organizationId: organizationId,
          importId: importId,
        }),
        success: (response: any) => {
          if (response.type === 'error') {
            enqueueSnackbarHandler(dispatch, response.payload.payload, 'error');
            dispatch(requestFailed(response));
          } else {
            enqueueSnackbarHandler(
              dispatch,
              { confirmImportSuccess: true },
              'success',
            );
            handleTrack(response, uploadCSVResponse);
            dispatch(confirmSuccess(response));

            dispatch(resetImportEmployees());
          }
        },
        fail: (error: any) => {
          enqueueSnackbarHandler(dispatch, error.payload, 'error');
          dispatch(confirmRequestFailed(error));
        },
      },
    });

    return null;
  };
}

export function uploadCSV(
  organizationId: string,
  file: File,
  deactivateUsers: boolean,
) {
  return (dispatch: Dispatch, getState: Function) => {
    dispatch(requestStarted());
    uploadCSVResponse = '';
    const formData = new FormData();
    formData.append('csvFile', file, file.name);
    formData.append('organizationId', organizationId);

    if (deactivateUsers) {
      formData.append('deactivateUsers', deactivateUsers.toString());
    }
    dispatch({
      type: API_CALL,
      payload: {
        call: 'organization/import/validate',
        method: 'post',
        type: 'form',
        data: formData,
        success: (response: any) => {
          if (response.type === 'error') {
            enqueueSnackbarHandler(dispatch, response.payload.payload, 'error');
            dispatch(requestFailed(response));
          } else {
            dispatch(requestSuccess(response));
            uploadCSVResponse = response;
          }
        },
        fail: (error: any) => {
          enqueueSnackbarHandler(dispatch, error.payload, 'error');
          dispatch(requestFailed(error));
        },
      },
    });

    return null;
  };
}

function confirmRequestStarted() {
  return {
    type: ACTION_IMPORT_EMPLOYEES_CONFIRM,
    payload: null,
  };
}

function confirmRequestFailed(responeJSON: object) {
  return {
    type: ACTION_IMPORT_EMPLOYEES_CONFIRM_FAILURE,
    payload: responeJSON,
  };
}

function requestStarted() {
  return {
    type: ACTION_IMPORT_EMPLOYEES_VALIDATE,
    payload: null,
  };
}

function requestSuccess(responeJSON: object) {
  return {
    type: ACTION_IMPORT_EMPLOYEES_VALIDATE_SUCCESS,
    payload: responeJSON,
  };
}

function requestFailed(responeJSON: object) {
  return {
    type: ACTION_IMPORT_EMPLOYEES_VALIDATE_FAILURE,
    payload: responeJSON,
  };
}

function confirmSuccess(responeJSON: object) {
  return {
    type: ACTION_IMPORT_EMPLOYEES_CONFIRM_SUCCESS,
    payload: responeJSON,
  };
}

function handleTrack(data: any, resp: any) {
  if (data.payload.confirmImportSuccess) {
    let properties = {
      'Users Created': resp.payload.addedUsers.length,
      'Users Updated': resp.payload.updatedUsers.length,
      'Departments Created': resp.payload.addedDepartments.length,
    };

    track('BB_CSVImport', properties);
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import deburr from 'lodash-es/deburr';
import _ from 'lodash-es';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { withStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import InputAdornment from '@material-ui/core/InputAdornment';

import Select from 'react-select';
import MenuItem from '@material-ui/core/MenuItem';
import {
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  Menu,
  DropdownIndicator,
  IndicatorSeparator,
  inputComponent,
} from './Components';

const styles = require('./styles.pcss');

type Props = {
  onChange: Function;
  label: string;
  className: any;
  value: any;
  error?: any;
  disabled?: boolean;
  dataSet?: any;
  returnAll?: boolean;
  endText?: any;
  maxMenuHeight?: any;
  id?: any;
};

type State = {
  value: any;
  suggestions: any;
  options: any;
};

class AutoSuggest extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      options: props.dataSet.map((data: any) => ({
        value: data.code,
        label: data.name,
      })),
      value: this.getDefaultValue(props.value),
      suggestions: [],
    };
  }

  componentWillReceiveProps = (nextProps: Props) => {
    this.setState({
      value: this.getDefaultValue(nextProps.value),
      options: nextProps.dataSet.map((data: any) => ({
        value: data.code,
        label: data.name,
      })),
    });
  };

  getDefaultValue = (value: any) => {
    let val =
      _.find(this.props.dataSet, (option) => option.name === value) ||
      _.find(this.props.dataSet, (option) => option.code === value);
    return { label: _.get(val, 'name', ''), value: _.get(val, 'code', '') };
  };

  handleChange = (val: any) => {
    this.setState({
      value: val,
    });

    if (this.props.returnAll) {
      this.props.onChange(val);
    } else {
      this.props.onChange(val.label);
    }
  };

  render() {
    const components = {
      Control: (props: any) => (
        <TextField
          error={Boolean(this.props.error)}
          helperText={this.props.error}
          id={this.props.id ? this.props.id : ''}
          label={this.props.label}
          fullWidth
          value={_.get(this.state, 'value.label', '')}
          variant="outlined"
          margin="normal"
          className={styles.dropdownField}
          InputProps={{
            inputComponent,
            inputProps: {
              className: styles.input,
              inputRef: props.innerRef,
              children: props.children,
              ...props.innerProps,
            },
            endAdornment: Boolean(this.props.endText) && (
              <InputAdornment
                className={styles.endText}
                position="end"
              >
                {this.props.endText}
              </InputAdornment>
            ),
          }}
          {...props.selectProps.textFieldProps}
        />
      ),
      Menu,
      NoOptionsMessage,
      Option,
      DropdownIndicator,
      IndicatorSeparator,
      ValueContainer,
    };

    return (
      <div className={this.props.className}>
        <Select
          isDisabled={this.props.disabled}
          options={this.state.options}
          className={styles.select}
          components={components}
          value={this.state.value}
          onChange={this.handleChange}
          placeholder=""
          maxMenuHeight={this.props.maxMenuHeight}
        />
      </div>
    );
  }
}

export default AutoSuggest;

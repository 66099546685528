import React from 'react';

type Props = {
  width: number;
  height: number;
};

const SvgIconDinersClub = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      version="1.0"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 164.409 141.732"
      enableBackground="new 0 0 164.409 141.732"
      xmlSpace="preserve"
    >
      <g>
        <circle
          fill="#FFFFFF"
          cx="71.609"
          cy="72.091"
          r="68.419"
        />
        <path
          fill="#004A97"
          d="M71.703,141.731c-39.21,0.186-71.48-31.581-71.48-70.25c0-42.259,32.27-71.489,71.48-71.482h18.375
        c38.744-0.007,74.11,29.209,74.11,71.482c0,38.655-35.364,70.248-74.11,70.248L71.703,141.731L71.703,141.731z M71.861,5.862 C36.03,5.875,7,34.912,6.995,70.743c0.007,35.828,29.035,64.857,64.866,64.864c35.833-0.007,64.871-29.036,64.88-64.864
        C136.731,34.912,107.693,5.875,71.861,5.862z M57.133,109.107V32.369C41.716,38.306,30.779,53.228,30.74,70.743
        C30.779,88.254,41.716,103.177,57.133,109.107z M112.988,70.743c-0.029-17.521-10.982-32.465-26.412-38.381v76.76
        C102.005,103.197,112.959,88.268,112.988,70.743z"
        />
      </g>
    </svg>
  );
};

export default SvgIconDinersClub;

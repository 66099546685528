import {
  ACTION_PUT_UPDATE_BOOKING,
  ACTION_PUT_UPDATE_BOOKING_SUCCESS,
  ACTION_PUT_UPDATE_BOOKING_FAILURE,
  API_CALL,
} from '../../configs/ActionTypes';

import { Dispatch } from 'redux';
import { enqueueSnackbarHandler } from '../Status';

export function updateBooking(
  bookingId: any,
  status: string,
  confirmationNumber: string,
  userEmail: string,
  notes: string,
) {
  return (dispatch: Dispatch, getState: Function) => {
    dispatch(requestStarted());

    //Dispatch the api call request.
    dispatch({
      type: API_CALL,
      payload: {
        call: `bookings/details`,
        method: 'put',
        data:
          JSON.stringify({
            bookingId,
            status,
            confirmationNumber,
            userEmail,
            notes,
          }) || {},
        success: (response: any) => {
          if (response.type === 'error') {
            dispatch(requestFailed(response));
            enqueueSnackbarHandler(
              dispatch,
              { updateBookingDetailsFailure: true },
              'error',
            );
          } else {
            dispatch(requestSuccess(response));
            enqueueSnackbarHandler(
              dispatch,
              { updateBookingDetailsSuccess: true },
              'success',
            );
          }
        },
        fail: (error: any) => {
          dispatch(requestFailed(error));
          enqueueSnackbarHandler(
            dispatch,
            { updateBookingDetailsFailure: true },
            'error',
          );
        },
      },
    });

    return null;
  };
}

export function requestStarted() {
  return {
    type: ACTION_PUT_UPDATE_BOOKING,
    payload: null,
  };
}

export function requestSuccess(responseJSON: any) {
  return {
    type: ACTION_PUT_UPDATE_BOOKING_SUCCESS,
    payload: responseJSON,
  };
}

export function requestFailed(responseJSON: any) {
  return {
    type: ACTION_PUT_UPDATE_BOOKING_FAILURE,
    payload: responseJSON,
  };
}

import React, { Component } from 'react';
import cx from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextFieldWithClear from 'components/TextFieldWithIcon/TextFieldWithIcon';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

const styles = require('./styles.pcss');

type Props = {
  open: boolean;
  onClose: any;
  onSubmit: any;
  bookingNumber?: any;
  body?: any;
  icon: any;
  onChangeAmount: any;
  errorMessage?: any;
  label?: any;
  clearAmount: any;
  amount: any;
  isCorporate?: any;
  onFocusAmount: any;
  onBlurAmount: any;
  displayMoneyIcon: any;
};

const RefundConfirmation = (props: Props) => {
  return (
    <Dialog
      maxWidth={'md'}
      fullWidth={true}
      className={cx(styles.dialogBox, styles.confirmationDialog)}
      open={props.open}
      onClose={props.onClose}
    >
      <div className={styles.refundConfDialog}>
        <div className={cx(styles.title, styles.titleFontSizePopupConf)}>
          Refund Booking
          <IconButton
            color="inherit"
            onClick={props.onClose}
            className={cx(styles.closeIcon)}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        </div>

        <div className={styles.refundDesctiption}>
          The following amount will be refunded for booking{' '}
          <span>{props.bookingNumber}</span>.
        </div>

        <div className={styles.confRefundBody}>
          <div className={styles.textContainerRef}>
            {props.icon} {props.body}
            {props.isCorporate && (
              <>
                <span className={styles.dot}></span>
                <span> Corporate Card </span>
              </>
            )}
          </div>
          <div className={styles.inputWrapper}>
            <TextFieldWithClear
              error={Boolean(props.errorMessage)}
              helperText={props.errorMessage}
              onChange={props.onChangeAmount}
              label={props.label}
              variant="outlined"
              margin="normal"
              value={props.amount}
              onClear={props.clearAmount}
              fullWidth={true}
              onFocus={props.onFocusAmount}
              onBlur={props.onBlurAmount}
              startAdornment={props.displayMoneyIcon}
              icon={<AttachMoneyIcon fontSize={'small'} />}
            />
          </div>
        </div>

        <div className={cx(styles.refundConfButtons)}>
          <Button
            className={styles.button}
            color="primary"
            variant="outlined"
            onClick={props.onClose}
          >
            Cancel
          </Button>

          <Button
            className={styles.button}
            variant="contained"
            color="primary"
            onClick={props.onSubmit}
          >
            Refund
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default RefundConfirmation;

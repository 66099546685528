import * as React from 'react';

const styles = require('./style.pcss');

type Props = {
  children?: React.ReactNode;
  style?: any;
};

const TableColumn = ({ children, ...props }: Props) => {
  return (
    <div
      className={styles.column}
      style={props.style}
    >
      {children}
    </div>
  );
};

export default TableColumn;

import React, { Component } from 'react';
import _ from 'lodash-es';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextFieldWithClear from 'components/TextFieldWithIcon/TextFieldWithIcon';
import { Agency } from '../../../reducers/types';
const requiredRegionCountries = [
  'United States',
  'Canada',
  'Australia',
  'Brazil',
  'Japan',
  'Mexico',
];
const requiredPostalCountries = require('../../../helpers/requiredPostalCountries.js');
import AutoSuggest from 'components/Inputs/AutoSuggest/AutoSuggest';
import { states } from '../../../helpers/States.js';
import { provinces } from '../../../helpers/Provinces.js';
import { countries } from '../../../helpers/Countries.js';
import cx from 'classnames';
const styles = require('../styles.pcss');

type Props = {
  open: boolean;
  onClose: any;
  onSubmit: any;
  agency?: Agency;
  isLoading?: any;
};

type State = {
  friendlyName: string;
  appHost: string;
  friendlyNameError: string;
  appHostError: string;
  postalCode: any;
  zipCodeRequired: Boolean;
  region: string;
  city: string;
  streetAddress: string;
  country: any;
  marketingUrl: any;
  marketingUrlError: string;
  name: any;
  nameError: string;
};

class AgencyInfoPopup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      friendlyName: _.get(this.props, 'agency.friendlyName', ''),
      appHost: _.get(this.props, 'agency.appHost', ''),
      friendlyNameError: '',
      appHostError: '',
      country: _.get(this.props, 'agency.address.countryCode', ''),
      postalCode: _.get(this.props, 'agency.address.postalCode', ''),
      zipCodeRequired: true,
      region: _.get(this.props, 'agency.address.stateProvinceCode', ''),
      city: _.get(this.props, 'agency.address.cityName', ''),
      streetAddress: _.get(this.props, 'agency.address.street', ''),
      marketingUrlError: '',
      marketingUrl: _.get(this.props, 'agency.marketingUrl', ''),
      name: _.get(this.props, 'agency.name', ''),
      nameError: '',
    };
  }

  componentWillReceiveProps = (nextProps: any) => {
    if (_.get(nextProps, 'open', null) == false) {
      this.setState({
        friendlyName: _.get(this.props, 'agency.friendlyName', ''),
        appHost: _.get(this.props, 'agency.appHost', ''),
        friendlyNameError: '',
        appHostError: '',
        country: _.get(this.props, 'agency.address.countryCode', ''),
        postalCode: _.get(this.props, 'agency.address.postalCode', ''),
        zipCodeRequired: true,
        region: _.get(this.props, 'agency.address.stateProvinceCode', ''),
        city: _.get(this.props, 'agency.address.cityName', ''),
        streetAddress: _.get(this.props, 'agency.address.street', ''),
        marketingUrlError: '',
        marketingUrl: _.get(this.props, 'agency.marketingUrl', ''),
        name: _.get(this.props, 'agency.name', ''),
        nameError: '',
      });
    }
  };

  onClose = () => {
    this.setState({
      friendlyName: _.get(this.props, 'agency.friendlyName', ''),
      appHost: _.get(this.props, 'agency.appHost', ''),
      friendlyNameError: '',
      appHostError: '',
      country: _.get(this.props, 'agency.address.countryCode', ''),
      postalCode: _.get(this.props, 'agency.address.postalCode', ''),
      zipCodeRequired: true,
      region: _.get(this.props, 'agency.address.stateProvinceCode', ''),
      city: _.get(this.props, 'agency.address.cityName', ''),
      streetAddress: _.get(this.props, 'agency.address.street', ''),
      marketingUrlError: '',
      marketingUrl: _.get(this.props, 'agency.marketingUrl', ''),
      name: _.get(this.props, 'agency.name', ''),
      nameError: '',
    });

    this.props.onClose();
  };

  onDeleteFriendlyName = () => {
    this.setState({
      friendlyName: '',
      friendlyNameError: '',
    });
  };

  onDeleteAppHost = () => {
    this.setState({
      appHost: '',
      appHostError: '',
    });
  };

  onDeleteName = () => {
    this.setState({
      name: '',
      nameError: '',
    });
  };

  onDeleteMarketingUrl = () => {
    this.setState({
      marketingUrl: '',
      marketingUrlError: '',
    });
  };

  onChangeName = (event: any) => {
    this.setState({
      name: event.target.value,
      nameError: '',
    });
  };

  onChangeMarketingUrl = (event: any) => {
    this.setState({
      marketingUrl: event.target.value,
      marketingUrlError: '',
    });
  };

  onChangeFriendlyName = (event: any) => {
    this.setState({
      friendlyName: event.target.value,
      friendlyNameError: '',
    });
  };

  onChangeAppHost = (event: any) => {
    this.setState({
      appHost: event.target.value,
      appHostError: '',
    });
  };

  onChangeCountryAuto = (country: any) => {
    let zipCodeRequired =
      requiredPostalCountries.requiredPostalCountries.includes(
        _.get(country, 'label', ''),
      );

    this.setState({
      region: '',
      country: country,
      zipCodeRequired,
    });
  };

  onChangeRegion = (region: any) => {
    this.setState({
      region: region,
    });
  };

  onChangeRegionText = (event: any) => {
    this.setState({
      region: event.target.value,
    });
  };

  onChangePostalCode = (event: any) => {
    this.setState({
      postalCode: event.target.value,
    });
  };

  onChangeStreetAddress = (event: any) => {
    this.setState({
      streetAddress: event.target.value,
    });
  };

  onChangeCity = (event: any) => {
    this.setState({
      city: event.target.value,
    });
  };

  onDeleteStreetAddress = () => {
    this.setState({
      streetAddress: '',
    });
  };

  onDeleteCity = () => {
    this.setState({
      city: '',
    });
  };

  onDeletePostalCode = () => {
    this.setState({
      postalCode: '',
    });
  };

  onDeleteRegion = () => {
    this.setState({
      region: '',
    });
  };

  renderZipCode = () => {
    if (this.state.zipCodeRequired) {
      return (
        <div className={styles.inputWrapper}>
          <TextFieldWithClear
            onChange={this.onChangePostalCode}
            fullWidth
            label="Zip Code"
            variant="outlined"
            margin="normal"
            value={this.state.postalCode}
            onClear={this.onDeletePostalCode}
            disabled={this.props.isLoading}
            id="agency-popup-zipcode"
          />
        </div>
      );
    }

    return null;
  };

  renderRegion = () => {
    const country = _.get(this.state, 'country.value', this.state.country);

    if (country == 'US' || country == 'CA') {
      return (
        <div className={styles.inputWrapper}>
          <AutoSuggest
            className={styles.dropdownContainer}
            onChange={(value: any) => {
              this.onChangeRegion(value);
            }}
            label={this.state.country == 'Canada' ? 'Province' : 'State'}
            returnAll={true}
            value={_.get(this.state, 'region.label', this.state.region)}
            dataSet={
              this.state.country == 'Canada' || this.state.country == 'CA'
                ? provinces
                : states
            }
            disabled={this.props.isLoading}
            id="agency-popup-state"
          />
        </div>
      );
    }

    return (
      <div className={styles.inputWrapper}>
        <TextFieldWithClear
          onChange={this.onChangeRegionText}
          fullWidth
          label="State/Province/Region"
          variant="outlined"
          margin="normal"
          value={this.state.region}
          onClear={this.onDeleteRegion}
          disabled={this.props.isLoading}
        />
      </div>
    );
  };

  onSubmit = () => {
    let friendlyNameError = '';
    let appHostError = '';
    let nameError = '';
    let marketingUrlError = '';

    if (this.state.friendlyName.trim() === '') {
      friendlyNameError = 'Required';
    }

    if (this.state.appHost.trim() === '') {
      appHostError = 'Required';
    }

    if (this.state.marketingUrl.trim() === '') {
      marketingUrlError = 'Required';
    }

    if (this.state.name.trim() === '') {
      nameError = 'Required';
    }

    if (
      friendlyNameError != '' ||
      appHostError != '' ||
      marketingUrlError != '' ||
      nameError != ''
    ) {
      this.setState({
        friendlyNameError,
        appHostError,
        nameError,
        marketingUrlError,
      });

      return;
    }

    let data: any = {
      id: _.get(this.props, 'agency.id', null),
      friendlyName: this.state.friendlyName,
      appHost: this.state.appHost,
      settings: _.get(this.props, 'agency.settings', ''),
      uiSettings: _.get(this.props, 'agency.uiSettings', ''),
      name: this.state.name,
      marketingUrl: this.state.marketingUrl,
      address: {
        addressLine: this.state.friendlyName,
        street: this.state.streetAddress,
        cityName: this.state.city,
        postalCode: this.state.postalCode,
        stateProvinceCode: _.get(this.state, 'region.value', this.state.region),
        countryCode: _.get(this.state, 'country.value', this.state.country),
      },
    };

    this.props.onSubmit(data);
  };

  render() {
    return (
      <Dialog
        maxWidth={'md'}
        fullWidth={true}
        className={styles.dialogBox}
        open={this.props.open}
        onClose={this.onClose}
      >
        <div className={styles.title}>
          {this.props.agency ? 'Edit Agency Details' : 'Add Agency'}
          <IconButton
            color="inherit"
            onClick={this.onClose}
            className={styles.closeIcon}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className={styles.companyInfoDescription}>
          {this.props.agency && (
            <>
              You are now editing {_.get(this.props, 'agency.friendlyName', '')}{' '}
              details.
            </>
          )}
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.inputWrapper}>
            <TextFieldWithClear
              fullWidth
              label="Friendly Name"
              variant="outlined"
              margin="normal"
              value={this.state.friendlyName}
              onChange={this.onChangeFriendlyName}
              error={Boolean(this.state.friendlyNameError)}
              helperText={this.state.friendlyNameError}
              onClear={this.onDeleteFriendlyName}
              id="agency-popup-friendly-name"
            />
          </div>

          <div className={styles.inputWrapper}>
            <TextFieldWithClear
              fullWidth
              label="App URL"
              variant="outlined"
              margin="normal"
              value={this.state.appHost}
              onChange={this.onChangeAppHost}
              error={Boolean(this.state.appHostError)}
              helperText={this.state.appHostError}
              onClear={this.onDeleteAppHost}
              id="agency-popup-appUrl"
            />
          </div>
          <>
            <div className={styles.inputWrapper}>
              <TextFieldWithClear
                fullWidth
                label="Name"
                variant="outlined"
                margin="normal"
                value={this.state.name}
                onChange={this.onChangeName}
                error={Boolean(this.state.nameError)}
                helperText={this.state.nameError}
                onClear={this.onDeleteName}
                id="agency-popup-name"
              />
            </div>

            <div className={styles.inputWrapper}>
              <TextFieldWithClear
                fullWidth
                label="Marketing URL"
                variant="outlined"
                margin="normal"
                value={this.state.marketingUrl}
                onChange={this.onChangeMarketingUrl}
                error={Boolean(this.state.marketingUrlError)}
                helperText={this.state.marketingUrlError}
                onClear={this.onDeleteMarketingUrl}
                id="agency-popup-marketingUrl"
              />
            </div>
          </>
        </div>

        <div className={styles.updatePopup}>
          <div className={styles.billingAddressContainer}>
            <h2 className={styles.informationTitleCompany}>Address</h2>
          </div>
          <div
            className={cx(
              styles.inputContainer,
              styles.inputContainerCreditCard,
            )}
          >
            <div className={styles.inputWrapper100}>
              <TextFieldWithClear
                onChange={this.onChangeStreetAddress}
                fullWidth
                label="Street Address"
                variant="outlined"
                margin="normal"
                value={this.state.streetAddress}
                onClear={this.onDeleteStreetAddress}
                disabled={this.props.isLoading}
                id="agency-street-address"
              />
            </div>
            <div className={styles.inputWrapper}>
              <TextFieldWithClear
                onChange={this.onChangeCity}
                fullWidth
                label="City"
                variant="outlined"
                margin="normal"
                value={this.state.city}
                onClear={this.onDeleteCity}
                disabled={this.props.isLoading}
                id="agency-popup-city"
              />
            </div>

            {this.renderRegion()}

            <div className={styles.inputWrapper}>
              <AutoSuggest
                className={styles.dropdownContainer}
                onChange={this.onChangeCountryAuto}
                returnAll={true}
                label="Country"
                value={_.get(this.state, 'country.label', this.state.country)}
                dataSet={countries}
                disabled={this.props.isLoading}
                id="agency-popup-country"
              />
            </div>

            {this.renderZipCode()}
          </div>
        </div>

        <div className={styles.popupButtonsContainer}>
          <Button
            className={styles.compPopupButton}
            variant="contained"
            color="primary"
            onClick={this.onSubmit}
            id="agency-popup-submit"
          >
            Save
          </Button>
        </div>
      </Dialog>
    );
  }
}

export default AgencyInfoPopup;

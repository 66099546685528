import {
  ACTION_GET_ORGANIZATION_BANK_ACCOUNT,
  ACTION_GET_ORGANIZATION_BANK_ACCOUNT_SUCCESS,
  ACTION_GET_ORGANIZATION_BANK_ACCOUNT_FAILURE,
  ACTION_PUT_ORG_BANK_ACCOUNT,
  ACTION_PUT_ORG_BANK_ACCOUNT_SUCCESS,
  ACTION_PUT_ORG_BANK_ACCOUNT_FAILURE,
  ACTION_RESET_ORG_BANK_ACCOUNT_ERROR,
} from '../configs/ActionTypes';

import _ from 'lodash-es';

const initialState = {
  bankAccount: {},
  isLoading: false,
  success: null,
  errors: {
    errorBankName: '',
    errorRoutingNumber: '',
    errorAccountNumber: '',
    errorConfAccountNumber: '',
    errorAccountType: '',
  },
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_GET_ORGANIZATION_BANK_ACCOUNT:
    case ACTION_PUT_ORG_BANK_ACCOUNT: {
      return {
        ...state,
        isLoading: true,
        success: null,
        errors: {},
      };
    }
    case ACTION_GET_ORGANIZATION_BANK_ACCOUNT_SUCCESS:
    case ACTION_PUT_ORG_BANK_ACCOUNT_SUCCESS: {
      return {
        ...state,
        bankAccount: action.payload,
        isLoading: false,
        success: true,
      };
    }
    case ACTION_GET_ORGANIZATION_BANK_ACCOUNT_FAILURE: {
      return {
        ...state,
        bankAccount: {},
        isLoading: false,
        success: false,
      };
    }

    case ACTION_RESET_ORG_BANK_ACCOUNT_ERROR: {
      return {
        ...state,
        errors: {
          errorBankName: '',
          errorRoutingNumber: '',
          errorAccountNumber: '',
          errorConfAccountNumber: '',
          errorAccountType: '',
        },
      };
    }

    case ACTION_PUT_ORG_BANK_ACCOUNT_FAILURE: {
      let errorResp = _.get(action.payload, 'payload', {});

      let errors = {
        errorBankName: '',
        errorRoutingNumber: '',
        errorAccountNumber: '',
        errorConfAccountNumber: '',
        errorAccountType: '',
      };

      if (
        errorResp.hasOwnProperty('bankNameEmpty') &&
        errorResp.bankNameEmpty === true
      ) {
        errors.errorBankName = 'Required';
      }

      if (
        errorResp.hasOwnProperty('routingNumberEmpty') &&
        errorResp.routingNumberEmpty === true
      ) {
        errors.errorRoutingNumber = 'Required';
      }

      if (
        errorResp.hasOwnProperty('routingNumberInvalid') &&
        errorResp.routingNumberInvalid === true
      ) {
        errors.errorRoutingNumber = 'Invalid value';
      }

      if (
        errorResp.hasOwnProperty('accountNumberEmpty') &&
        errorResp.accountNumberEmpty === true
      ) {
        errors.errorAccountNumber = 'Required';
      }

      if (
        errorResp.hasOwnProperty('accountNumberInvalid') &&
        errorResp.accountNumberInvalid === true
      ) {
        errors.errorAccountNumber = 'Invalid value';
      }

      if (
        errorResp.hasOwnProperty('accountTypeEmpty') &&
        errorResp.accountTypeEmpty === true
      ) {
        errors.errorAccountType = 'Required';
      }

      if (
        errorResp.hasOwnProperty('accountTypeInvalid') &&
        errorResp.accountTypeInvalid === true
      ) {
        errors.errorAccountType = 'Invalid value';
      }

      return {
        ...state,
        errors,
        isLoading: false,
        success: false,
      };
    }

    default: {
      return state;
    }
  }
}

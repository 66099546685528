import {
  ACTION_GET_USER_BANK_ACCOUNT,
  ACTION_GET_USER_BANK_ACCOUNT_SUCCESS,
  ACTION_GET_USER_BANK_ACCOUNT_FAILURE,
  API_CALL,
} from '../../configs/ActionTypes';

import _ from 'lodash-es';
import { Dispatch } from 'redux';
import { enqueueSnackbarHandler } from '../Status';

export function getUserBankAccount(userId: string) {
  return (dispatch: Dispatch, getState: Function) => {
    //Dispatch the api call request.
    dispatch(requestStarted());

    dispatch({
      type: API_CALL,
      payload: {
        call: `user/${userId}/bank-account`,
        method: 'get',
        data: {},
        success: (response: any) => {
          if (response.type === 'error') {
            _.get(response.payload, 'payload.userPermissionError', false)
              ? enqueueSnackbarHandler(
                  dispatch,
                  response.payload.payload,
                  'error',
                )
              : enqueueSnackbarHandler(
                  dispatch,
                  { userBankAccountFailed: true },
                  'error',
                );
            dispatch(requestFailed(response));
          } else {
            dispatch(requestSuccess(response));
          }
        },
        fail: (error: any) => {
          if (error.payload) {
            _.get(error.payload, 'userPermissionError', false)
              ? enqueueSnackbarHandler(dispatch, error.payload, 'error')
              : enqueueSnackbarHandler(
                  dispatch,
                  { userBankAccountFailed: true },
                  'error',
                );
          }
          dispatch(requestFailed(error));
        },
      },
    });

    return null;
  };
}

export function requestStarted() {
  return {
    type: ACTION_GET_USER_BANK_ACCOUNT,
    payload: null,
  };
}

export function requestSuccess(responseJSON: object) {
  return {
    type: ACTION_GET_USER_BANK_ACCOUNT_SUCCESS,
    payload: responseJSON,
  };
}

export function requestFailed(responseJSON: object) {
  return {
    type: ACTION_GET_USER_BANK_ACCOUNT_FAILURE,
    payload: responseJSON,
  };
}

import {
  ACTION_UPDATE_CARBON_EMISSION_PRODUCT,
  ACTION_UPDATE_CARBON_EMISSION_PRODUCT_FAILURE,
  ACTION_UPDATE_CARBON_EMISSION_PRODUCT_SUCCESS,
  API_CALL,
} from '../../../configs/ActionTypes';
import { Dispatch } from 'redux';
import { enqueueSnackbarHandler } from '../../Status';

export function addUpdateCarbonEmissionProduct(
  organizationId: string,
  data: any,
) {
  return (dispatch: Dispatch) => {
    dispatch(requestStarted());

    dispatch({
      type: API_CALL,
      payload: {
        call: `organizations/${organizationId}/carbonOffsetProduct`,
        method: 'put',
        data: JSON.stringify(data),
        success: (response: any) => {
          if (response.type === 'error') {
            enqueueSnackbarHandler(dispatch, response.payload.payload, 'error');
            dispatch(requestFailed(response));
          } else {
            dispatch(requestSuccess(response));
          }
        },
        fail: (error: any) => {
          if (error.payload) {
            enqueueSnackbarHandler(dispatch, error.payload, 'error');
          }

          dispatch(requestFailed(error));
        },
      },
    });

    return null;
  };
}

export function requestStarted() {
  return {
    type: ACTION_UPDATE_CARBON_EMISSION_PRODUCT,
    payload: null,
  };
}

export function requestSuccess(responseJSON: object) {
  return {
    type: ACTION_UPDATE_CARBON_EMISSION_PRODUCT_SUCCESS,
    payload: responseJSON,
  };
}

export function requestFailed(responseJSON: object) {
  return {
    type: ACTION_UPDATE_CARBON_EMISSION_PRODUCT_FAILURE,
    payload: responseJSON,
  };
}

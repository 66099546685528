import {
  ACTION_POST_LOGIN_SUCCESS,
  ACTION_POST_LOGIN_FAILURE,
  ACTION_LOGOUT_SUCCESS,
  ACTION_REDIRECT_LOGIN,
  REDIRECT_NO_ACCESS,
  ACTION_AFTER_RESET_PASSWORD_SUCCESS,
  RESET_NO_ACCESS,
} from '../configs/ActionTypes';

import _ from 'lodash-es';
import moment from 'moment';

let loginData = JSON.parse(localStorage.getItem('login_data') || '{}');

let isLoggedIn = false;
let token = _.get(loginData, 'token', null);
let forceResetPassword = false;

if (token) {
  const sessionExpire = _.get(loginData, 'sessionExpire', null);
  forceResetPassword = _.get(loginData, 'forceResetPassword', false);

  if (sessionExpire && moment.unix(sessionExpire).isAfter()) {
    isLoggedIn = true;
  }
}

const initialState = {
  token: token,
  isLoggedIn: isLoggedIn,
  forceResetPassword: forceResetPassword,
  error: null,
  redirectLogin: false,
  redirectNoAccess: false,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_POST_LOGIN_SUCCESS: {
      let login_data = {
        token: action.payload.payload.token,
        sessionExpire: action.payload.payload.sessionExpire,
        userPermissions: action.payload.payload.userPermissions,
        userId: _.get(action.payload.payload, 'user.id', null),
        userRole: _.get(action.payload.payload, 'userRole', ''),
        agencyId: _.get(action.payload.payload, 'user.agencyId', ''),
        forceResetPassword: _.get(
          action.payload.payload,
          'user.resetPassword',
          false,
        ),
        //email: _.get(action.payload, 'email', null),
        //name: _.get(action.payload, 'name', null)
      };

      localStorage.setItem('login_data', JSON.stringify(login_data));

      return {
        ...state,
        token: action.payload.payload.token,
        isLoggedIn: true,
        forceResetPassword: _.get(
          action.payload.payload,
          'user.resetPassword',
          false,
        ),
        redirectLogin: false,
        error: null,
      };

      break;
    }
    case ACTION_AFTER_RESET_PASSWORD_SUCCESS: {
      let loginData = JSON.parse(localStorage.getItem('login_data') || '{}');
      localStorage.removeItem('login_data');

      _.set(loginData, 'forceResetPassword', false);

      localStorage.setItem('login_data', JSON.stringify(loginData));

      return {
        ...state,
        forceResetPassword: false,
      };

      break;
    }
    case ACTION_POST_LOGIN_FAILURE: {
      let login_data = {
        token: null,
      };
      localStorage.removeItem('login_data');

      return {
        ...state,
        token: null,
        isLoggedIn: false,
        error: _.get(
          action,
          'payload.error',
          'An unknown error occured. Please try again',
        ),
      };

      break;
    }

    case ACTION_LOGOUT_SUCCESS: {
      // Remove the sessionId from localStorage
      localStorage.removeItem('login_data');

      return {
        ...state,
        token: null,
        isLoggedIn: false,
        redirectLogin: true,
      };

      break;
    }
    case ACTION_REDIRECT_LOGIN: {
      // Remove the sessionId from localStorage
      localStorage.removeItem('login_data');

      return {
        ...state,
        token: null,
        isLoggedIn: false,
        redirectLogin: true,
      };

      break;
    }
    case REDIRECT_NO_ACCESS: {
      return {
        ...state,
        token: null,
        isLoggedIn: false,
        redirectLogin: false,
        redirectNoAccess: true,
      };

      break;
    }
    case RESET_NO_ACCESS: {
      return {
        ...state,
        token: null,
        isLoggedIn: false,
        redirectLogin: false,
        redirectNoAccess: false,
      };

      break;
    }
    default: {
      return state;
    }
  }
}

import {
  ACTION_PUT_CANCEL_BOOKING,
  ACTION_PUT_CANCEL_BOOKING_SUCCESS,
  ACTION_PUT_CANCEL_BOOKING_FAILURE,
  ACTION_EMPTY_PUT_CANCEL_BOOKING,
} from '../configs/ActionTypes';

const initialState = {
  isLoading: false,
  success: null,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_PUT_CANCEL_BOOKING: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ACTION_PUT_CANCEL_BOOKING_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        success: true,
      };
    }

    case ACTION_PUT_CANCEL_BOOKING_FAILURE: {
      return {
        ...state,
        success: false,
        isLoading: false,
      };
    }

    case ACTION_EMPTY_PUT_CANCEL_BOOKING: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

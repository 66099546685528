/**
 * UPDATE user flight credit Action
 */
import _ from 'lodash-es';
import {
  ACTION_UPDATE_USER_FLIGHTCREDITS,
  ACTION_UPDATE_USER_FLIGHTCREDITS_SUCCESS,
  ACTION_UPDATE_USER_FLIGHTCREDITS_FAILURE,
  API_CALL,
} from '../../configs/ActionTypes';
import { Dispatch } from 'redux';
import { enqueueSnackbar } from '../Status';

/**
 * UPDATE User flight credit call
 */
export function updateUserFlightCredit(
  flightCreditId: string,
  flightCreditStatus: string,
) {
  return (dispatch: Dispatch, getState: Function) => {
    // Dispatch the api call request.
    dispatch(requestStarted());

    dispatch({
      type: API_CALL,
      payload: {
        call: `user/flight-credits/update`,
        method: 'post',
        data: JSON.stringify({ flightCreditId, flightCreditStatus }) || {},
        success: (response: any) => {
          if (response.type === 'error') {
            let errors = response.payload.payload;
            Object.keys(errors).forEach(function (item) {
              if (errors[item] == true) {
                dispatch(enqueueSnackbar(item, 'error'));
              }
            });
            dispatch(requestFailed(response));
          } else {
            dispatch(requestSuccess({ flightCreditId, flightCreditStatus }));
          }
        },
        fail: (error: any) => {
          let errors = error.payload;
          Object.keys(errors).forEach(function (item) {
            if (errors[item] == true) {
              dispatch(enqueueSnackbar(item, 'error'));
            }
          });
          dispatch(requestFailed(error));
        },
      },
    });
    return null;
  };
}

export function requestStarted() {
  return {
    type: ACTION_UPDATE_USER_FLIGHTCREDITS,
    payload: null,
  };
}

export function requestSuccess(responseJSON: object) {
  return {
    type: ACTION_UPDATE_USER_FLIGHTCREDITS_SUCCESS,
    payload: responseJSON,
  };
}

export function requestFailed(responseJSON: object) {
  return {
    type: ACTION_UPDATE_USER_FLIGHTCREDITS_FAILURE,
    payload: responseJSON,
  };
}

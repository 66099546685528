import {
  ACTION_GET_USER_BANK_ACCOUNT,
  ACTION_GET_USER_BANK_ACCOUNT_SUCCESS,
  ACTION_GET_USER_BANK_ACCOUNT_FAILURE,
} from '../configs/ActionTypes';

import _ from 'lodash-es';

const initialState = {
  account: {},
  isLoading: false,
  success: null,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_GET_USER_BANK_ACCOUNT: {
      return {
        ...state,
        isLoading: true,
        success: null,
      };
    }

    case ACTION_GET_USER_BANK_ACCOUNT_SUCCESS: {
      let account = _.get(action.payload, 'payload', {});

      return {
        ...state,
        account,
        isLoading: false,
        success: true,
      };
    }

    case ACTION_GET_USER_BANK_ACCOUNT_FAILURE: {
      return {
        ...state,
        isLoading: false,
        success: false,
      };
    }

    default: {
      return state;
    }
  }
}

import React, { Component } from 'react';
import cx from 'classnames';
import _ from 'lodash-es';

import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Loader from 'components/Loader/Loader';
import TextFieldWithClear from 'components/TextFieldWithIcon/TextFieldWithIcon';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ReduxState } from 'reducers/types';

import { postHotel } from 'actions/ManageTB/Hotels/Post';

const styles = require('./styles.pcss');

type Props = {
  open: boolean;
  onCancel: any;
  exclusiveHotels: any;
  postHotel: Function;
};

type State = {
  identifierError: any;
  nameError: any;
  identifier: string;
  name: string;
};

class AddExclusiveHotelPopup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      identifierError: '',
      nameError: '',
      identifier: '',
      name: '',
    };
  }

  componentWillReceiveProps = (nextProps: Props) => {
    if (
      _.get(this.props.exclusiveHotels, 'isLoading', null) &&
      _.get(nextProps.exclusiveHotels, 'success', null) === false
    ) {
      this.setState({
        nameError: _.get(nextProps.exclusiveHotels, 'nameError', ''),
        identifierError: _.get(
          nextProps.exclusiveHotels,
          'identifierError',
          '',
        ),
      });
    } else if (
      _.get(this.props.exclusiveHotels, 'isLoading', null) &&
      _.get(nextProps.exclusiveHotels, 'success', null) === true
    ) {
      this.onCancel();
    }
  };

  onCancel = () => {
    this.setState({
      identifierError: '',
      nameError: '',
      identifier: '',
      name: '',
    });

    this.props.onCancel();
  };

  onChangeIdentfier = (event: any) => {
    this.setState({
      identifier: event.target.value,
      identifierError: '',
    });
  };

  onChangeName = (event: any) => {
    this.setState({
      name: event.target.value,
      nameError: '',
    });
  };

  onDeleteIdentifier = () => {
    this.setState({
      identifier: '',
      identifierError: '',
    });
  };

  onDeleteName = () => {
    this.setState({
      name: '',
      nameError: '',
    });
  };

  handleSubmit = () => {
    let nameError = '';
    let identifierError = '';
    let isValid = true;

    if (this.state.name.trim() == '') {
      nameError = 'Required';
      isValid = false;
    }
    if (this.state.identifier.trim() == '') {
      identifierError = 'Required';
      isValid = false;
    }

    this.setState({
      nameError: nameError,
      identifierError: identifierError,
    });

    if (isValid) {
      let data = {
        name: this.state.name,
        identifier: this.state.identifier,
      };

      this.props.postHotel(data);
    }
  };

  onKeyUp = (e: any) => {
    if (e.keyCode === 13) {
      this.handleSubmit();
    }
  };

  renderLoader = () => {
    if (!_.get(this.props, 'exclusiveHotels.isLoading', false)) {
      return null;
    }

    return (
      <div className={styles.loaderContainer}>
        <Loader visible={true} />
      </div>
    );
  };

  render() {
    return (
      <Dialog
        maxWidth={'md'}
        fullWidth={true}
        className={cx(styles.dialogBox, styles.ccPopup)}
        open={this.props.open}
        onClose={this.onCancel}
      >
        <div className={styles.title}>
          <div className={styles.titleStyle}>Add Hotel</div>
          <IconButton
            color="inherit"
            onClick={this.onCancel}
            className={cx(styles.closeIconPopupAdd)}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        </div>
        {this.renderLoader()}
        <div className={cx(styles.body, styles.addProgram)}>
          <div className={styles.row}>
            <div
              className={cx(styles.inputWrapper, styles.inputWrapperWithMargin)}
            >
              <TextFieldWithClear
                error={Boolean(this.state.nameError)}
                onChange={this.onChangeName}
                helperText={this.state.nameError}
                fullWidth
                label={'Hotel'}
                variant="outlined"
                margin="normal"
                value={this.state.name}
                onClear={this.onDeleteName}
              />
            </div>
            <div className={styles.inputWrapper}>
              <TextFieldWithClear
                error={Boolean(this.state.identifierError)}
                onChange={this.onChangeIdentfier}
                helperText={this.state.identifierError}
                fullWidth
                label={'Property Id'}
                variant="outlined"
                margin="normal"
                value={this.state.identifier}
                onClear={this.onDeleteIdentifier}
              />
            </div>
          </div>
          <div
            className={cx(styles.popupButtonsContainer, styles.addNewExHotel)}
          >
            <Button
              size="small"
              className={styles.cancelButton}
              color="primary"
              onClick={this.onCancel}
            >
              Cancel
            </Button>

            <Button
              size="small"
              className={styles.button}
              variant="contained"
              color="primary"
              onClick={this.handleSubmit}
            >
              Add
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {
    exclusiveHotels: state.exclusiveHotels,
  };
}

// Wire up redux dispatch functions
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      postHotel,
    },
    dispatch,
  );
}

// Connect it to Redux
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddExclusiveHotelPopup);

import React from 'react';

type Props = {
  width: number;
  height: number;
};

const SvgCloudIcon = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 32 26"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs></defs>
      <g
        id="Master"
        stroke="none"
        stroke-width="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <g
          id="Organization-Macro---Employees---Import"
          transform="translate(-638.000000, -503.000000)"
          stroke="#318AFB"
          strokeWidth="1.5"
        >
          <g
            id="Modal"
            transform="translate(370.000000, 356.000000)"
          >
            <g
              id="Upload"
              transform="translate(32.000000, 124.000000)"
            >
              <g
                id="Group-2"
                transform="translate(237.000000, 24.000000)"
              >
                <g
                  id="Upload"
                  transform="translate(-0.000000, 0.000000)"
                >
                  <path
                    d="M19.5384615,18.5870769 L25.1372308,18.5870769 C27.8233846,18.5870769 30,16.4104615 30,13.7249231 C30,11.0393846 27.8233846,8.86215385 25.1372308,8.86215385 C25.1249231,8.86215385 25.1138462,8.864 25.1015385,8.864 C25.1390769,8.56 25.1649231,8.25230769 25.1649231,7.93846154 C25.1649231,3.80923077 21.8178462,0.461538462 17.688,0.461538462 C14.4701538,0.461538462 11.736,2.49784615 10.6812308,5.34830769 C10.0123077,4.67815385 9.08676923,4.26276923 8.06523077,4.26276923 C6.02276923,4.26276923 4.36738462,5.91815385 4.36738462,7.96 C4.36738462,8.01230769 4.37353846,8.06276923 4.37538462,8.11446154 C2.12,8.73784615 0.461538462,10.7987692 0.461538462,13.2523077 C0.461538462,16.1987692 2.84984615,18.5870769 5.79692308,18.5870769 L10.9230769,18.5870769"
                    id="Stroke-1"
                    strokeLinejoin="round"
                  ></path>
                  <polyline
                    id="Stroke-3"
                    points="12.1538462 14.2793846 15.2307692 11.2024615 18.3076923 14.2793846"
                  ></polyline>
                  <line
                    x1="15.2307692"
                    y1="23.5101538"
                    x2="15.2307692"
                    y2="11.4073846"
                    id="Stroke-5"
                  ></line>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgCloudIcon;

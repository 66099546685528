import _ from 'lodash-es';

import {
  ACTION_PUT_COMPANY,
  ACTION_POST_COMPANY,
  ACTION_POST_COMPANY_SUCCESS,
  ACTION_POST_COMPANY_FAILURE,
  ACTION_PUT_COMPANY_SUCCESS,
  ACTION_PUT_COMPANY_FAILURE,
  ACTION_RESET_COMPANY_SUCCESS,
} from '../configs/ActionTypes';

const initialState = {
  createCompanyAttempt: false,
  success: null,
  errors: {
    name: '',
    primaryDomain: '',
    companyCode: '',
    additionalDomains: '',
  },
  isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ACTION_PUT_COMPANY:
    case ACTION_POST_COMPANY: {
      return {
        isLoading: true,
      };

      break;
    }

    case ACTION_POST_COMPANY_SUCCESS:
    case ACTION_PUT_COMPANY_SUCCESS: {
      return {
        ...state,
        createCompanyAttempt: true,
        success: true,
        isLoading: false,
      };

      break;
    }

    case ACTION_POST_COMPANY_FAILURE:
    case ACTION_PUT_COMPANY_FAILURE: {
      const nameError =
        _.get(action.payload, 'payload.name.requiredError', false) === true
          ? 'Required'
          : '';
      let primaryDomainError = '';
      let companyCodeError = '';
      let additionalDomainsError = '';

      if (
        _.get(action.payload, 'payload.primaryDomain.requiredError', false) ===
        true
      ) {
        primaryDomainError = 'Required';
      } else if (
        _.get(action.payload, 'payload.primaryDomain.uniqueError', false) ===
        true
      ) {
        primaryDomainError = 'Domain in use';
      }

      if (
        _.get(action.payload, 'payload.companyCode.requiredError', false) ===
        true
      ) {
        companyCodeError = 'Required';
      }

      if (
        _.get(
          action.payload,
          'payload.additionalDomains.uniqueError',
          false,
        ) === true
      ) {
        additionalDomainsError = 'Domain in use';
      } else if (
        _.get(action.payload, 'payload.additionalDomains.validError', false) ===
        true
      ) {
        additionalDomainsError = 'Domain is invalid or is not allowed';
      }

      return {
        ...state,
        createCompanyAttempt: true,
        success: false,
        isLoading: false,
        errors: {
          name: nameError,
          primaryDomain: primaryDomainError,
          companyCode: companyCodeError,
          additionalDomains: additionalDomainsError,
        },
      };

      break;
    }

    case ACTION_RESET_COMPANY_SUCCESS: {
      return {
        ...state,
        createCompanyAttempt: false,
        success: false,
        isLoading: false,
      };

      break;
    }

    default: {
      return state;
    }
  }
}

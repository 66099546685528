import {
  ACTION_SET_HOTEL_ROOMS_REQUEST_PARAM,
  ACTION_GET_HOTEL_DETAILS,
  ACTION_GET_HOTEL_DETAILS_SUCCESS,
  ACTION_GET_HOTEL_DETAILS_FAILURE,
  ACTION_GET_HOTEL_ROOMS,
  ACTION_GET_HOTEL_ROOMS_SUCCESS,
  ACTION_GET_HOTEL_ROOMS_FAILURE,
  ACTION_SELECT_HOTEL_ROOM,
  ACTION_RESET_HOTEL_ROOMS_BOOKING_DETAILS,
} from '../configs/ActionTypes';

import { Hotel } from './types';

import _ from 'lodash-es';

const initialState = {
  isLoadingDetails: false,
  isLoadingRooms: false,
  requestParam: null,
  hotelDetails: null,
  hotelRooms: [],
  isError: false,
  selectedRoom: null,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_SET_HOTEL_ROOMS_REQUEST_PARAM: {
      return {
        ...state,
        requestParam: action.payload,
        isError: false,
        selectedRoom: null,
      };
    }

    case ACTION_GET_HOTEL_DETAILS: {
      return {
        ...state,
        isLoadingDetails: true,
        selectedRoom: null,
      };
    }

    case ACTION_GET_HOTEL_ROOMS: {
      return {
        ...state,
        isLoadingRooms: true,
        selectedRoom: null,
      };
    }

    case ACTION_GET_HOTEL_DETAILS_SUCCESS: {
      return {
        ...state,
        hotelDetails: action.payload.payload,
        isLoadingDetails: false,
        selectedRoom: null,
      };
    }

    case ACTION_GET_HOTEL_ROOMS_SUCCESS: {
      return {
        ...state,
        hotelRooms: action.payload.payload,
        isLoadingRooms: false,
        selectedRoom: null,
      };
    }

    case ACTION_GET_HOTEL_DETAILS_FAILURE:
    case ACTION_GET_HOTEL_ROOMS_FAILURE: {
      return {
        ...state,
        isError: true,
        isLoadingRooms: false,
        isLoadingDetails: false,
        selectedRoom: null,
        hotelRooms: [],
      };
    }

    case ACTION_SELECT_HOTEL_ROOM: {
      return {
        ...state,
        selectedRoom: action.payload,
      };
    }

    case ACTION_RESET_HOTEL_ROOMS_BOOKING_DETAILS: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

import * as React from 'react';
import _ from 'lodash-es';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import cx from 'classnames';

const styles = require('./styles.pcss');

type Props = {
  passengers: any;
  numb: any;
  multi: any;
  type: any;
};

const PassengerDetails = (props: Props) => {
  const program = () => {
    let loyaltyProgram = '— —';
    if (!_.isEmpty(_.get(props.passengers, 'ffCardMap', null))) {
      loyaltyProgram = `${_.get(
        Object.keys(props.passengers.ffCardMap),
        '[0]',
        '—',
      )} #${_.get(Object.values(props.passengers.ffCardMap), '[0]', '—')}`;
    }

    return (
      <p>
        Loyalty Program:{' '}
        <span className={styles.passengerDesc}>{loyaltyProgram}</span>{' '}
      </p>
    );
  };

  let gender = _.get(props.passengers, 'gender', '') == 'F' ? 'Female' : 'Male';
  let date =
    _.get(props.passengers, 'birthDate', null) !== null
      ? moment(_.get(props.passengers, 'birthDate', 'null'), 'YYYYMMDD').format(
          'L',
        )
      : '— —';

  let passExpiry = '';
  let passNumber = '';
  let passCountry = '';

  if (props.passengers.hasOwnProperty('passportInfo')) {
    passExpiry =
      _.get(props.passengers.passportInfo, 'passExpiry', null) !== null
        ? moment(
            _.get(props.passengers.passportInfo, 'passExpiry', null),
            'YYYYMMDD',
          ).format('L')
        : '— —';
    passNumber = _.get(props.passengers.passportInfo, 'passNumber', '- -');
    passCountry = _.get(props.passengers.passportInfo, 'passCountry', '- -');
  } else {
    passExpiry =
      _.get(props.passengers, 'passExpiry', null) !== null
        ? moment(
            _.get(props.passengers, 'passExpiry', null),
            'YYYYMMDD',
          ).format('L')
        : '— —';
    passNumber = _.get(props.passengers, 'passNumber', '— —');
    passCountry = _.get(props.passengers, 'passCountry', '— —');
  }

  let passportInfo =
    (passNumber && passNumber.length > 1) ||
    (passCountry && passCountry.length > 1)
      ? true
      : false;

  return (
    <div className={styles.PassengerHeader}>
      {props.multi === true && (
        <div className={styles.title}>Passenger {props.numb}</div>
      )}

      <div className={styles.passengerInfo}>
        {props.multi === true && (
          <div className={styles.travelerName}>
            {' '}
            {_.get(props.passengers, 'firstName', '')}{' '}
            {_.get(props.passengers, 'lastName', '')}{' '}
          </div>
        )}
        <div className={styles.Info}>
          <div className={styles.passengerInfoRow}>
            <p>
              {' '}
              Gender:{' '}
              <span className={cx(styles.passengerDesc, 'gender')}>
                {gender}
              </span>{' '}
            </p>
            <p>
              {' '}
              Date of Birth:{' '}
              <span className={cx(styles.passengerDesc, 'dateOfBirth')}>
                {date}
              </span>{' '}
            </p>
            <p>
              {' '}
              Phone:{' '}
              <span className={cx(styles.passengerDesc, 'phone')}>
                {_.get(props.passengers, 'phone.number', '— —')}
              </span>{' '}
            </p>
            <p>
              {' '}
              Email:{' '}
              <span className={cx(styles.passengerDesc, 'email')}>
                {_.get(props.passengers, 'email', '— —')}
              </span>{' '}
            </p>
          </div>

          <div className={styles.passengerInfoRow}>
            <p>
              Known Traveler Number:{' '}
              <span className={styles.passengerDesc}>
                {_.get(props.passengers, 'knownTravelerNumber', '— —')}
              </span>{' '}
            </p>
            {program()}

            <div className={styles.passportInfo}>
              <p>Passport Information: </p>

              <div className={styles.passList}>
                {passportInfo ? (
                  <>
                    <p>
                      Passport{' '}
                      <span className={cx(styles.passengerDesc, 'passNumber')}>
                        {passNumber}
                      </span>{' '}
                    </p>
                    <p>
                      Country:{' '}
                      <span className={cx(styles.passengerDesc, 'passCountry')}>
                        {passCountry}
                      </span>{' '}
                    </p>
                    <p>
                      Expiration:{' '}
                      <span className={cx(styles.passengerDesc, 'passExpiry')}>
                        {passExpiry}
                      </span>
                    </p>{' '}
                  </>
                ) : (
                  <p> — — </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PassengerDetails;

import {
  ACTION_GET_CAR_LOYALTY_PROGRAM,
  ACTION_GET_CAR_LOYALTY_PROGRAM_SUCCESS,
  ACTION_GET_CAR_LOYALTY_PROGRAM_FAILURE,
  ACTION_EMPTY_CAR_LOYALTY_PROGRAM,
} from '../configs/ActionTypes';

const initialState = {
  carLoyaltyProgram: [],
  isLoading: false,
  success: null,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_GET_CAR_LOYALTY_PROGRAM: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ACTION_GET_CAR_LOYALTY_PROGRAM_SUCCESS: {
      const carLoyaltyProgram = action.payload.payload;

      return {
        ...state,
        carLoyaltyProgram: carLoyaltyProgram,
        isLoading: false,
        success: true,
      };
    }
    case ACTION_GET_CAR_LOYALTY_PROGRAM_FAILURE: {
      return {
        ...state,
        carLoyaltyProgram: [],
        isLoading: false,
        success: false,
      };
    }

    case ACTION_EMPTY_CAR_LOYALTY_PROGRAM: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

import React, { Component } from 'react';
import classNames from 'classnames';

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import _ from 'lodash-es';

import { navigate, redirectTo } from '@reach/router';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import Zoom from '@material-ui/core/Zoom';
import CloseIcon from '@material-ui/icons/Close';
import TextFieldWithClear from 'components/TextFieldWithIcon/TextFieldWithIcon';
import CustomSelect from 'components/Inputs/Select/Select';
import Loader from 'components/Loader/Loader';
const sizeOfCompanyOptions = require('../../helpers/sizeOfCompanyOptions.js');
import AutoSuggest from 'components/Inputs/AutoSuggest/AutoSuggest';
const requiredPostalCountries = require('../../helpers/requiredPostalCountries.js');
const styles = require('./styles.pcss');
const requiredRegionCountries = [
  'United States',
  'Canada',
  'Australia',
  'Brazil',
  'Japan',
  'Mexico',
];
import { states } from '../../helpers/States.js';
import { provinces } from '../../helpers/Provinces.js';
import { countries } from '../../helpers/Countries.js';
import { add, resetSaveCompanyStatus } from '../../actions/Companies/Add';
import { ReduxState } from '../../reducers/types';

type Props = {
  showAddCompany: boolean;
  closeAddCompany: any;
  add: Function;
  resetSaveCompanyStatus: Function;
  saveCompanyStatus: any;
};

type State = {
  name: string;
  domain: string;
  errorName: boolean;
  errorDomain: boolean;
  waitingResponse: boolean;
  saveCompanyStatus: boolean;
  companySize: any;
  errorCompanySize: boolean;
  country: any;
  postalCode: any;
  zipCodeRequired: Boolean;
  region: string;
  city: string;
  streetAddress: string;
  streetAddress2: string;
};

class AddCompanyPopup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      name: '',
      domain: '',
      errorName: false,
      errorDomain: false,
      waitingResponse: false,
      saveCompanyStatus: false,
      companySize: '',
      errorCompanySize: false,
      country: 'United States',
      postalCode: '',
      zipCodeRequired: true,
      region: '',
      city: '',
      streetAddress: '',
      streetAddress2: '',
    };
  }

  shouldComponentUpdate(nextProps: any) {
    if (
      _.get(nextProps, 'saveCompanyStatus.createCompanyAttempt', false) === true
    ) {
      if (_.get(nextProps, 'saveCompanyStatus.success', null) === true) {
        this.props.resetSaveCompanyStatus();
        this.props.closeAddCompany();
        this.setState(this.getInitialState());
        return false;
      }
    }

    return true;
  }

  onChangeCountryAuto = (country: any) => {
    let zipCodeRequired =
      requiredPostalCountries.requiredPostalCountries.includes(country);

    this.setState({
      region: '',
      country: country,
      zipCodeRequired,
    });
  };

  onChangeRegion = (region: any) => {
    this.setState({
      region: region,
    });
  };

  onChangeRegionText = (event: any) => {
    this.setState({
      region: event.target.value,
    });
  };

  onChangePostalCode = (event: any) => {
    this.setState({
      postalCode: event.target.value,
    });
  };

  onChangeStreetAddress = (event: any) => {
    this.setState({
      streetAddress: event.target.value,
    });
  };

  onChangeStreetAddress2 = (event: any) => {
    this.setState({
      streetAddress2: event.target.value,
    });
  };

  onChangeCity = (event: any) => {
    this.setState({
      city: event.target.value,
    });
  };

  onDeleteStreetAddress = () => {
    this.setState({
      streetAddress: '',
    });
  };

  onDeleteStreetAddress2 = () => {
    this.setState({
      streetAddress2: '',
    });
  };

  onDeleteCity = () => {
    this.setState({
      city: '',
    });
  };

  onDeletePostalCode = () => {
    this.setState({
      postalCode: '',
    });
  };

  onDeleteRegion = () => {
    this.setState({
      region: '',
    });
  };

  handleChangeName = (event: any) => {
    this.setState({
      name: event.target.value,
      errorName: false,
    });
  };

  handleChangeDomain = (event: any) => {
    this.setState({
      domain: event.target.value,
      errorDomain: false,
    });
  };

  /**
   * Handle form submit on enter
   */
  onKeyUp = (e: any) => {
    if (e.keyCode === 13) {
      this.handleSubmit();
    }
  };

  onDeleteName = () => {
    this.setState({
      name: '',
      errorName: false,
    });
  };

  renderZipCode = () => {
    if (this.state.zipCodeRequired) {
      return (
        <div className={styles.inputWrapper}>
          <TextFieldWithClear
            onChange={this.onChangePostalCode}
            fullWidth
            label="Zip Code"
            variant="outlined"
            margin="normal"
            value={this.state.postalCode}
            onClear={this.onDeletePostalCode}
            id={'zipcode'}
          />
        </div>
      );
    }

    return null;
  };

  renderRegion = () => {
    if (
      this.state.country == 'United States' ||
      this.state.country == 'Canada'
    ) {
      return (
        <div className={styles.inputWrapper}>
          <AutoSuggest
            className={styles.dropdownContainer}
            onChange={(value: any) => {
              this.onChangeRegion(value);
            }}
            label={this.state.country == 'Canada' ? 'Province' : 'State'}
            returnAll={true}
            value={_.get(this.state, 'region.label', this.state.region)}
            dataSet={this.state.country == 'Canada' ? provinces : states}
            id="province"
          />
        </div>
      );
    }

    return (
      <div className={styles.inputWrapper}>
        <TextFieldWithClear
          onChange={this.onChangeRegionText}
          fullWidth
          label="State/Province/Region"
          variant="outlined"
          margin="normal"
          value={this.state.region}
          onClear={this.onDeleteRegion}
        />
      </div>
    );
  };

  handleSubmit = () => {
    let errorName = false;
    let errorDomain = false;
    let errorCompanySize = false;

    if (this.state.name.trim() == '') {
      errorName = true;
    }
    if (this.state.domain.trim() == '') {
      errorDomain = true;
    }

    if (this.state.companySize.trim() == '') {
      errorCompanySize = true;
    }

    this.setState({
      errorName: errorName,
      errorDomain: errorDomain,
      errorCompanySize: errorCompanySize,
    });

    if (
      errorName == false &&
      errorDomain == false &&
      errorCompanySize == false
    ) {
      let companySize = _.find(sizeOfCompanyOptions.sizeOptions, [
        'id',
        this.state.companySize,
      ]);
      companySize = _.pick(companySize, ['min', 'max']);

      this.props.add({
        name: this.state.name,
        primaryDomain: this.state.domain,
        size: companySize,
        street: this.state.streetAddress,
        street2: this.state.streetAddress2,
        city: this.state.city,
        country: this.state.country,
        zip: this.state.zipCodeRequired ? this.state.postalCode : '',
        state: _.get(this.state, 'region.value', this.state.region),
      });

      this.setState({
        waitingResponse: true,
      });
    }
  };

  onChangeEmpNumber = (event: any) => {
    this.setState({
      companySize: event.target.value,
      errorCompanySize: false,
    });
  };

  renderLoading = () => {
    if (_.get(this.props, 'saveCompanyStatus.isLoading', false) == true) {
      return (
        <div className={styles.infoLoader}>
          <Loader visible={true} />
        </div>
      );
    }

    return null;
  };

  render() {
    let menuItems = _.map(sizeOfCompanyOptions.sizeOptions, (size) => {
      return { key: size.id, value: size.id, label: size.text };
    });

    return (
      <Dialog
        fullWidth={true}
        maxWidth="md"
        className={classNames(styles.dialogBox)}
        open={this.props.showAddCompany}
        onClose={this.props.closeAddCompany}
      >
        <div className={classNames(styles.title)}>
          New Company
          <IconButton
            id="close-button"
            color="inherit"
            onClick={this.props.closeAddCompany}
            className={classNames(styles.closeIcon)}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        </div>
        {this.renderLoading()}
        <div
          className={classNames(
            styles.content,
            styles.inputContainerAddCompany,
          )}
        >
          <div className={styles.inputWrapper}>
            <TextFieldWithClear
              fullWidth
              id="name"
              className={classNames(styles.textField)}
              label="Name"
              variant="outlined"
              margin="normal"
              value={this.state.name}
              onChange={this.handleChangeName}
              error={this.state.errorName}
              onKeyUp={this.onKeyUp}
              onClear={this.onDeleteName}
            />
          </div>
          <div className={styles.inputWrapper}>
            <TextField
              fullWidth
              id="domain"
              className={classNames(styles.textField)}
              label="Domain"
              variant="outlined"
              margin="normal"
              value={this.state.domain}
              onChange={this.handleChangeDomain}
              error={this.state.errorDomain}
              onKeyUp={this.onKeyUp}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip
                      title="E.g. example.com"
                      placement="top"
                      TransitionComponent={Zoom}
                    >
                      <IconButton aria-label="Info">
                        <InfoIcon
                          color="primary"
                          className={styles.icon}
                        />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className={styles.inputWrapper}>
            <CustomSelect
              margin={'normal'}
              variant={'outlined'}
              className={styles.selectWrapper}
              htmlFor={'input-type'}
              label={'Number of Employees'}
              id="number-of-employees"
              value={this.state.companySize}
              onChange={this.onChangeEmpNumber}
              fullWidth={true}
              labelWidth={153}
              inputName={'user'}
              error={this.state.errorCompanySize}
              MenuProps={{
                style: { marginTop: 4, boxShadow: 'none' },
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
              }}
              menuItems={menuItems}
            />
          </div>
        </div>
        <div className={styles.updatePopup}>
          <div className={styles.billingAddressContainer}>
            <h2 className={styles.informationTitle}>Address</h2>
          </div>
          <div
            className={classNames(
              styles.inputContainer,
              styles.inputContainerCreditCard,
            )}
          >
            <div className={styles.inputWrapper}>
              <TextFieldWithClear
                onChange={this.onChangeStreetAddress}
                fullWidth
                label="Street Address"
                variant="outlined"
                margin="normal"
                value={this.state.streetAddress}
                onClear={this.onDeleteStreetAddress}
                id="street-address"
              />
            </div>
            <div className={styles.inputWrapper}>
              <TextFieldWithClear
                onChange={this.onChangeStreetAddress2}
                fullWidth
                label="Street Address 2"
                variant="outlined"
                margin="normal"
                value={this.state.streetAddress2}
                onClear={this.onDeleteStreetAddress2}
              />
            </div>
            <div className={styles.inputWrapper}>
              <TextFieldWithClear
                onChange={this.onChangeCity}
                fullWidth
                label="City"
                variant="outlined"
                margin="normal"
                value={this.state.city}
                onClear={this.onDeleteCity}
                id="city"
              />
            </div>

            {this.renderRegion()}

            <div className={styles.inputWrapper}>
              <AutoSuggest
                className={styles.dropdownContainer}
                onChange={(value: any) => {
                  this.onChangeCountryAuto(value);
                }}
                label="Country"
                value={this.state.country}
                id="country"
                dataSet={countries}
              />
            </div>

            {this.renderZipCode()}
          </div>
          <div
            className={classNames(
              styles.actionsRight,
              styles.companyDetailsActionButton,
            )}
          >
            <Button
              className={styles.button}
              variant="contained"
              color="primary"
              onClick={this.handleSubmit}
              id="submit-add"
            >
              Add
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {
    saveCompanyStatus: state.saveCompanyStatus,
  };
}

// Wire up redux dispatch functions
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      add,
      resetSaveCompanyStatus,
    },
    dispatch,
  );
}

// Connect it to Redux
export default connect(mapStateToProps, mapDispatchToProps)(AddCompanyPopup);

import {
  ACTION_GET_ORG_SPECIAL_RATE_CODES,
  ACTION_GET_ORG_SPECIAL_RATE_CODES_SUCCESS,
  ACTION_GET_ORG_SPECIAL_RATE_CODES_FAILURE,
  ACTION_PUT_ORG_SPECIAL_RATE_CODES,
  ACTION_PUT_ORG_SPECIAL_RATE_CODES_SUCCESS,
  ACTION_PUT_ORG_SPECIAL_RATE_CODES_FAILURE,
} from '../configs/ActionTypes';

const initialState = {
  specialRateCodes: [],
  isLoading: false,
  success: null,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_PUT_ORG_SPECIAL_RATE_CODES:
    case ACTION_GET_ORG_SPECIAL_RATE_CODES: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ACTION_PUT_ORG_SPECIAL_RATE_CODES_SUCCESS:
    case ACTION_GET_ORG_SPECIAL_RATE_CODES_SUCCESS: {
      const specialRateCodes = action.payload?.payload?.value || [];
      return {
        ...state,
        specialRateCodes: specialRateCodes,
        isLoading: false,
        success: true,
      };
    }
    case ACTION_PUT_ORG_SPECIAL_RATE_CODES_FAILURE:
    case ACTION_GET_ORG_SPECIAL_RATE_CODES_FAILURE: {
      return {
        ...state,
        specialRateCodes: [],
        isLoading: false,
        success: false,
      };
    }

    default: {
      return state;
    }
  }
}

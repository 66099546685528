import * as React from 'react';
import _ from 'lodash-es';
import cx from 'classnames';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import FeaturesTableRow from './FeaturesTableRow';
import ConfirmationPopup from 'components/Popup/Confirmation';
import Loader from 'components/Loader/Loader';
import LayoutDashboard from 'components/Layout/Dashboard';
const styles = require('./styles.pcss');
import { hasPermission } from '../../helpers/Permission';
import { ReduxState } from '../../reducers/types';

type Props = {
  expenseSplitFeature: any;
  handleExpenseSplitChange: Function;
  isLoadingExpenseSplit: boolean;
  organziationProductsSettings?: any;
  handleProductSettingsChange?: Function;
  isLoadingProductSettings: boolean;
};

type State = {
  openSplitsConfirmationPopup: boolean;
};

class Features extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      openSplitsConfirmationPopup: false,
    };
  }

  openSplitsConfirmationPopup = () => {
    this.setState({
      openSplitsConfirmationPopup: true,
    });
  };

  closeSplitsConfirmationPopup = () => {
    this.setState({
      openSplitsConfirmationPopup: false,
    });
  };

  onSubmitSplitsConfirmationPopup = () => {
    this.props.handleExpenseSplitChange();
    this.closeSplitsConfirmationPopup();
  };

  render() {
    const updatePermission = !hasPermission('organizations', 'update');
    const columns: any = {
      labelName: 'Expenses',
      description: 'Employees can create and submit expenses.',
    };
    const expenseSplitFeature: any = {
      labelName: 'Splits',
      description:
        'Expenses can be split by department, category and custom fields.',
    };

    const expensesOrgSettingsEnabledEnabled = _.includes(
      _.get(this.props, 'organziationProductsSettings.value', []),
      'expenses',
    );
    const expenseSplitSettingEnabled = _.get(
      this.props,
      'expenseSplitFeature.value.enabled',
      false,
    );

    return (
      <>
        <Paper
          className={
            this.props.isLoadingProductSettings ||
            this.props.isLoadingExpenseSplit
              ? cx(styles.paper, styles.loading)
              : styles.paper
          }
        >
          <div className={styles.expensesFeatures}>
            <h2>Features</h2>
            {this.props.isLoadingProductSettings && (
              <Paper className={styles.pageWrapper}>
                <div>
                  <Loader visible={true} />
                </div>
              </Paper>
            )}
            <Table>
              <TableBody>
                <FeaturesTableRow
                  key={'expensesFeature'}
                  rowColumns={columns}
                  enabled={expensesOrgSettingsEnabledEnabled}
                  handleclick={this.props.handleProductSettingsChange}
                  updateOrganizationPermission={updatePermission}
                  description={true}
                />
                <FeaturesTableRow
                  key={'expenseSplitFeature'}
                  rowColumns={expenseSplitFeature}
                  enabled={expenseSplitSettingEnabled}
                  handleclick={this.openSplitsConfirmationPopup}
                  updateOrganizationPermission={
                    updatePermission || expenseSplitSettingEnabled
                  }
                  description={true}
                />
              </TableBody>
            </Table>
          </div>
        </Paper>
        <ConfirmationPopup
          open={this.state.openSplitsConfirmationPopup}
          onClose={this.closeSplitsConfirmationPopup}
          onSubmit={this.onSubmitSplitsConfirmationPopup}
          title="Confirm Before Turning On Splits"
          bodyText={`Turning on Splits for an organization is permanent and not reversible.
          
          Additionally, if the organization has a custom file, it must be updated before Splits is turned on. Reach out to the customer success/implementation team to confirm.`}
          submitButtonText="Confirm & Proceed"
          closeButtonText="Cancel"
          disableX={true}
        />
      </>
    );
  }
}

function mapStateToProps(state: ReduxState) {
  return {
    isLoadingProductSettings: state.organizationProductsSettings.isLoading,
    isLoadingExpensePolicy: state.expensePolicy.isLoading,
  };
}

export default connect(mapStateToProps, '')(Features);

import { ACTION_GET_FREQUENT_FLYERS_SUCCESS } from '../configs/ActionTypes';

const initialState = {
  frequentFlyers: [],
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_GET_FREQUENT_FLYERS_SUCCESS: {
      return {
        ...state,
        frequentFlyers: action.payload.payload,
      };
    }

    default: {
      return state;
    }
  }
}

import * as React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { navigate } from '@reach/router';
import _ from 'lodash-es';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { CreditCard } from '../../reducers/types';

const styles = require('./styles.pcss');

type Props = {
  creditCard: CreditCard;
};

const CreditCardTableRow = ({ creditCard }: Props) => (
  <TableRow
    className={classNames(styles.tableRow)}
    key={creditCard.id}
  >
    <TableCell className={classNames(styles.tableRowCell)}>
      {creditCard.name}
    </TableCell>
    <TableCell className={classNames(styles.tableRowCell)}>
      {creditCard.typeFull}
    </TableCell>
    <TableCell className={classNames(styles.tableRowCell)}>
      {creditCard.last4}
    </TableCell>
    <TableCell className={classNames(styles.tableRowCell)}>
      {moment(creditCard.expiry).format('MM/YY')}
    </TableCell>
    <TableCell className={classNames(styles.tableRowCell)}>
      {_.get(creditCard, 'organizationName', '')}
    </TableCell>
  </TableRow>
);

export default CreditCardTableRow;

import {
  ACTION_GET_ORG_REIMBURSEMENT_FEE_SETTING,
  ACTION_GET_ORG_REIMBURSEMENT_FEE_SETTING_SUCCESS,
  ACTION_GET_ORG_REIMBURSEMENT_FEE_SETTING_FAILURE,
} from '../configs/ActionTypes';

const initialState = {
  reimbursementFee: {},
  isLoading: false,
  success: null,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_GET_ORG_REIMBURSEMENT_FEE_SETTING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ACTION_GET_ORG_REIMBURSEMENT_FEE_SETTING_SUCCESS: {
      return {
        ...state,
        reimbursementFee: action.payload.payload,
        isLoading: false,
        success: true,
      };
    }
    case ACTION_GET_ORG_REIMBURSEMENT_FEE_SETTING_FAILURE: {
      return {
        ...state,
        reimbursementFee: {},
        isLoading: false,
        success: false,
      };
    }
    default: {
      return state;
    }
  }
}

import * as React from 'react';
import classNames from 'classnames';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';

const styles = require('./styles.pcss');

type Props = {
  rowColumns: Array<any>;
  showRemove?: boolean;
  clickRemove?: any;
  showEdit?: boolean;
  clickEdit?: any;
  firstItemToLeft?: boolean;
};

const TravelerTableRow = (props: Props) => {
  const tableColumn = (name: string, i: number) => (
    <TableCell
      key={i}
      className={styles.tableCell}
    >
      {name}
    </TableCell>
  );

  const displayRemove = (props: Props) => {
    if (props.showRemove) {
      return (
        <TableCell
          key={'rem'}
          className={classNames(styles.tableCell, styles.removeCell)}
        >
          <Button
            color="primary"
            onClick={props.clickRemove}
          >
            Remove
          </Button>
        </TableCell>
      );
    }
    if (props.showEdit) {
      return (
        <TableCell
          key={'rem'}
          className={classNames(styles.tableCell, styles.removeCell)}
        >
          <Button
            size="small"
            className={styles.editButton}
            variant="outlined"
            color="primary"
            onClick={props.clickEdit}
          >
            Edit
          </Button>
        </TableCell>
      );
    }

    return (
      <TableCell
        key={'rem'}
        className={classNames(styles.tableCell)}
      ></TableCell>
    );
  };
  const style = !props.firstItemToLeft
    ? styles.tableRow
    : classNames(styles.tableRow, styles.firstToLeft);

  return (
    <TableRow className={style}>
      {props.rowColumns.map(tableColumn)}
      {displayRemove(props)}
    </TableRow>
  );
};

export default TravelerTableRow;

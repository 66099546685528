import {
  ACTION_DELETE_FEATURE,
  ACTION_DELETE_FEATURES_SUCCESS,
  ACTION_DELETE_FEATURES_FAILURE,
  API_CALL,
} from '../../../configs/ActionTypes';
import { Dispatch } from 'redux';
import { enqueueSnackbarHandler } from '../../Status';

export function deleteFeature(featureIds: any) {
  return (dispatch: Dispatch, getState: Function) => {
    dispatch(requestStarted());
    //Dispatch the api call request.
    dispatch({
      type: API_CALL,
      payload: {
        call: `feature-flag/bulk-delete`,
        method: 'POST',
        data: JSON.stringify({ featureIds: featureIds }),
        success: (response: any) => {
          if (response.type === 'error') {
            dispatch(requestFailed(response));
          } else {
            enqueueSnackbarHandler(
              dispatch,
              { deleteFeatureSuccess: true },
              'success',
            );
            dispatch(requestSuccess(response));
          }
        },
        fail: (error: any) => {
          dispatch(requestFailed(error));
          if (error.payload) {
            enqueueSnackbarHandler(dispatch, error.payload, 'error');
          }
        },
      },
    });
    return null;
  };
}

export function requestStarted() {
  return {
    type: ACTION_DELETE_FEATURE,
    payload: null,
  };
}

export function requestSuccess(responseJSON: object) {
  return {
    type: ACTION_DELETE_FEATURES_SUCCESS,
    payload: responseJSON,
  };
}

export function requestFailed(responseJSON: object) {
  return {
    type: ACTION_DELETE_FEATURES_FAILURE,
    payload: responseJSON,
  };
}

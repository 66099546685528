import React from 'react';
import classNames from 'classnames';
import _ from 'lodash-es';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Select from 'components/Inputs/Select/Select';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import cx from 'classnames';

const styles = require('./styles.pcss');

type Props = {
  open: boolean;
  onClose: any;
  handleStatusSelect: any;
  ticketNumber: string;
  update: any;
  status: string;
  id: any;
};

const UpdateFlightCreditPopup = (props: Props) => {
  React.useState(false);

  function handleChangeStatus(event: any) {
    props.handleStatusSelect(event.target.value);
  }

  function handleSubmit() {
    props.update(props.id);
    props.onClose();
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      className={cx(styles.dialogBox, styles.sendEmailConfirmation)}
      open={props.open}
      onClose={props.onClose}
    >
      <div className={classNames(styles.title)}>
        <div className={styles.titleStyle}>Modify Flight Credit</div>
        <IconButton
          color="inherit"
          onClick={props.onClose}
          className={classNames(styles.closeIcon)}
          aria-label="Close"
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div className={classNames(styles.content)}>
        <div className={styles.flightCreditModalSubtext}>
          Ticket Number: {props.ticketNumber}
        </div>
        <Select
          fullWidth
          id="updatedStatus"
          className={styles.flightCreditModalStatus}
          label="Status"
          variant="outlined"
          margin=""
          value={props.status}
          onChange={handleChangeStatus}
          menuItems={[
            { key: 'OPEN', value: 'OPEN', label: 'OPEN' },
            { key: 'USED', value: 'USED', label: 'USED' },
            { key: 'EXPIRED', value: 'EXPIRED', label: 'EXPIRED' },
            { key: 'EXCHANGED', value: 'EXCHANGED', label: 'EXCHANGED' },
            { key: 'NO VALUE', value: 'NO VALUE', label: 'NO VALUE' },
            { key: 'REFUNDED', value: 'REFUNDED', label: 'REFUNDED' },
            { key: 'SUSPENDED', value: 'SUSPENDED', label: 'SUSPENDED' },
          ]}
        />
        <div className={styles.statusSubtext}>
          Only credits in OPEN status are visible to the app user.
        </div>
        <div className={classNames(styles.actions)}>
          <Button
            className={styles.button}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Save
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default UpdateFlightCreditPopup;

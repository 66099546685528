import {
  ACTION_GET_USER_EXPENSE_REPORTS,
  ACTION_GET_USER_EXPENSE_REPORTS_SUCCESS,
  ACTION_GET_USER_EXPENSE_REPORTS_FAILURE,
  ACTION_PUT_USER_EXPENSE_REPORTS,
  ACTION_PUT_USER_EXPENSE_REPORTS_SUCCESS,
  ACTION_PUT_USER_EXPENSE_REPORTS_FAILURE,
} from '../configs/ActionTypes';

import _ from 'lodash-es';

const initialState = {
  expenseReports: [],
  isLoading: false,
  success: null,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_GET_USER_EXPENSE_REPORTS:
    case ACTION_PUT_USER_EXPENSE_REPORTS: {
      return {
        ...state,
        isLoading: true,
        success: null,
      };
    }

    case ACTION_GET_USER_EXPENSE_REPORTS_SUCCESS: {
      let expenseReports = _.get(action.payload, 'payload', []);

      return {
        ...state,
        expenseReports: expenseReports,
        isLoading: false,
        success: null,
      };
    }

    case ACTION_PUT_USER_EXPENSE_REPORTS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        success: true,
      };
    }

    case ACTION_GET_USER_EXPENSE_REPORTS_FAILURE: {
      return {
        ...state,
        expenseReports: [],
        isLoading: false,
        success: false,
      };
    }

    case ACTION_PUT_USER_EXPENSE_REPORTS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        success: false,
      };
    }

    default: {
      return state;
    }
  }
}

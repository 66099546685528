export const requiredRegionCountries = [
  'United States',
  'Canada',
  'Australia',
  'Brazil',
  'Japan',
  'Mexico',
];

export const requiredRegionCountriesCode = ['US', 'CA', 'AU', 'BR', 'JP', 'MX'];

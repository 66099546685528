import {
  ACTION_GET_TRAVELER_SUCCESS,
  ACTION_GET_TRAVELER_FAILURE,
  ACTION_GET_TRAVELER,
  ACTION_UPDATE_TRAVELER,
  ACTION_UPDATE_TRAVELER_SUCCESS,
  ACTION_UPDATE_TRAVELER_FAILURE,
  ACTION_ADD_TRAVELER,
  ACTION_ADD_TRAVELER_SUCCESS,
  ACTION_ADD_TRAVELER_FAILURE,
  ACTION_RESET_TRAVELER_SAVE_SUCCEEDED,
  ACTION_EMPTY_TRAVELER,
} from '../configs/ActionTypes';

import { Traveler } from './types';

import _ from 'lodash-es';
import moment from 'moment';

const initialState = {
  traveler: null,
  isLoading: false,
  saveSucceeded: null,
  errors: {
    firstName: '',
    lastName: '',
    middleName: '',
    passExpiry: '',
    email: '',
  },
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_GET_TRAVELER:
    case ACTION_UPDATE_TRAVELER:
    case ACTION_ADD_TRAVELER: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ACTION_GET_TRAVELER_SUCCESS: {
      const t = action.payload.payload;

      let traveler: any = {
        id: t.id,
        userId: t.userId,
        firstName: _.get(t, 'firstName', ''),
        lastName: _.get(t, 'lastName', ''),
        middleName: _.get(t, 'middleName', ''),
        email: _.get(t, 'email', ''),
        birthDate: moment(t.birthDate).isValid() ? moment(t.birthDate) : null,
        phoneNumber: _.get(t, 'phone.number', ''),
        phoneCountryCode: _.get(t, 'phone.countryCode', ''),
        addressCity: _.get(t, 'deliveryAddress.city', ''),
        addressCountry: _.get(t, 'deliveryAddress.country', ''),
        addressPostalCode: _.get(t, 'deliveryAddress.postalCode', ''),
        addressState: _.get(t, 'deliveryAddress.state', ''),
        addressStreet: _.get(t, 'deliveryAddress.street', ''),
        knownTravelerNumber: t.knownTravelerNumber,
        passCountry: _.get(t, 'passportInfo.passCountry', ''),
        passNumber: _.get(t, 'passportInfo.passNumber', ''),
        passExpiry: moment(_.get(t, 'passportInfo.passExpiry', null)).isValid()
          ? moment(t.passportInfo.passExpiry)
          : null,
        gender: t.gender == null ? '' : t.gender,
        saveTraveler: t.saveTraveler,
        isPrimary: t.isPrimary,
        created: t.created,
        updated: t.updated,
        frequentFlyer: t.frequentFlyer,
        creditCards: _.get(t, 'creditCards', []),
        hotelLoyaltyPrograms: _.get(t, 'hotelLoyaltyPrograms', ''),
        carLoyalty: _.get(t, 'carLoyaltyPrograms', []),
      };

      return {
        ...state,
        isLoading: false,
        traveler: traveler,
        errors: {
          firstName: '',
          lastName: '',
          passExpiry: '',
          email: '',
        },
      };
    }

    case ACTION_UPDATE_TRAVELER_SUCCESS:
    case ACTION_ADD_TRAVELER_SUCCESS: {
      const t = action.payload.payload;

      let traveler: any = {
        id: t.id,
        userId: t.userId,
        firstName: _.get(t, 'firstName', ''),
        lastName: _.get(t, 'lastName', ''),
        middleName: _.get(t, 'middleName', ''),
        email: _.get(t, 'email', ''),
        birthDate: moment(t.birthDate).isValid() ? moment(t.birthDate) : null,
        phoneNumber: _.get(t, 'phone.number', ''),
        phoneCountryCode: _.get(t, 'phone.countryCode', ''),
        addressCity: _.get(t, 'deliveryAddress.city', ''),
        addressCountry: _.get(t, 'deliveryAddress.country', ''),
        addressPostalCode: _.get(t, 'deliveryAddress.postalCode', ''),
        addressState: _.get(t, 'deliveryAddress.state', ''),
        addressStreet: _.get(t, 'deliveryAddress.street', ''),
        knownTravelerNumber: t.knownTravelerNumber,
        passCountry: _.get(t, 'passportInfo.passCountry', ''),
        passNumber: _.get(t, 'passportInfo.passNumber', ''),
        passExpiry: moment(_.get(t, 'passportInfo.passExpiry', null)).isValid()
          ? moment(t.passportInfo.passExpiry)
          : null,
        gender: t.gender == null ? '' : t.gender,
        saveTraveler: t.saveTraveler,
        isPrimary: t.isPrimary,
        created: t.created,
        updated: t.updated,
        frequentFlyer: t.frequentFlyer,
        creditCards: _.get(t, 'creditCards', []),
        hotelLoyaltyPrograms: _.get(t, 'hotelLoyaltyPrograms', ''),
        carLoyalty: _.get(t, 'carLoyaltyPrograms', []),
      };

      return {
        ...state,
        isLoading: false,
        traveler: traveler,
        saveSucceeded: true,
        errors: {
          firstName: '',
          lastName: '',
          middleName: '',
          passExpiry: '',
          email: '',
        },
      };
    }

    case ACTION_UPDATE_TRAVELER_FAILURE:
    case ACTION_ADD_TRAVELER_FAILURE: {
      const payload = action.payload.payload;

      return {
        ...state,
        saveSucceeded: false,
        isLoading: false,
        errors: {
          firstName: checkFirstNameError(payload),
          lastName: checkLastNameError(payload),
          middleName:
            _.get(payload, 'middleNameSpecialCharsError', false) === true
              ? 'No spaces or special characters'
              : '',
          passExpiry:
            _.get(payload, 'passExpiryError', false) === true
              ? 'Expired passport'
              : '',
          email:
            _.get(payload, 'emailError', false) === true
              ? 'Invalid email address'
              : '',
        },
      };
    }

    case ACTION_RESET_TRAVELER_SAVE_SUCCEEDED: {
      return {
        ...state,
        saveSucceeded: null,
      };
    }

    case ACTION_EMPTY_TRAVELER: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

function checkFirstNameError(payload: any) {
  if (_.get(payload, 'firstNameEmptyError', false)) {
    return 'Empty first name';
  } else if (_.get(payload, 'firstNameSpecialCharsError', false)) {
    return 'No spaces or special characters';
  }
  return '';
}

function checkLastNameError(payload: any) {
  if (_.get(payload, 'lastNameEmptyError', false)) {
    return 'Empty last name';
  } else if (_.get(payload, 'lastNameSpecialCharsError', false)) {
    return 'No spaces or special characters';
  }
  return '';
}

import {
  ACTION_GET_USER_BUDGET_POLICY_RULES,
  ACTION_GET_USER_BUDGET_POLICY_RULES_SUCCESS,
  ACTION_GET_USER_BUDGET_POLICY_RULES_FAILURE,
  ACTION_EMPTY_USER_BUDGET_POLICY_RULES,
} from '../configs/ActionTypes';

const initialState = {
  policyRules: null,
  isLoading: false,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_GET_USER_BUDGET_POLICY_RULES: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ACTION_GET_USER_BUDGET_POLICY_RULES_SUCCESS: {
      return {
        ...state,
        policyRules: action.payload.payload,
        isLoading: false,
      };
    }

    case ACTION_GET_USER_BUDGET_POLICY_RULES_FAILURE:
    case ACTION_EMPTY_USER_BUDGET_POLICY_RULES: {
      return {
        ...state,
        policyRules: null,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
}

exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".pages-ManageTBHotels-styles___h1--1-dUO {\n  text-align: left;\n}\n\n.pages-ManageTBHotels-styles___paper--1dHiW {\n  padding: 64px 40px 32px;\n  padding-bottom: 0;\n  border-radius: 0;\n  box-shadow: none;\n  border-top: none;\n  border-left: none;\n  border-right: none;\n  background-color: transparent;\n}\n\n.pages-ManageTBHotels-styles___body--gqyYc {\n  padding-top: 30px;\n  display: flex;\n}\n\n.pages-ManageTBHotels-styles___header--25WJk {\n  text-align: left;\n  padding-top: 30px;\n}\n\n.pages-ManageTBHotels-styles___header--25WJk h1 {\n    margin: 0;\n    font-family: 'Roboto';\n    font-size: 40px;\n    color: #1b2432;\n    letter-spacing: 0;\n  }\n", "", {"version":3,"sources":["/tmp/build_c950a000/src/pages/ManageTBHotels/styles.pcss"],"names":[],"mappings":"AAAA;EACE,iBAAiB;CAClB;;AAED;EACE,wBAAwB;EACxB,kBAAkB;EAClB,iBAAiB;EACjB,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;EACnB,8BAA8B;CAC/B;;AAED;EACE,kBAAkB;EAClB,cAAc;CACf;;AAED;EACE,iBAAiB;EACjB,kBAAkB;CACnB;;AAED;IACI,UAAU;IACV,sBAAsB;IACtB,gBAAgB;IAChB,eAAe;IACf,kBAAkB;GACnB","file":"styles.pcss","sourcesContent":[".h1 {\n  text-align: left;\n}\n\n.paper {\n  padding: 64px 40px 32px;\n  padding-bottom: 0;\n  border-radius: 0;\n  box-shadow: none;\n  border-top: none;\n  border-left: none;\n  border-right: none;\n  background-color: transparent;\n}\n\n.body {\n  padding-top: 30px;\n  display: flex;\n}\n\n.header {\n  text-align: left;\n  padding-top: 30px;\n}\n\n.header h1 {\n    margin: 0;\n    font-family: 'Roboto';\n    font-size: 40px;\n    color: #1b2432;\n    letter-spacing: 0;\n  }\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"h1": "pages-ManageTBHotels-styles___h1--1-dUO",
	"paper": "pages-ManageTBHotels-styles___paper--1dHiW",
	"body": "pages-ManageTBHotels-styles___body--gqyYc",
	"header": "pages-ManageTBHotels-styles___header--25WJk"
};
import {
  ACTION_RESET_LICENSE_PRICING,
  ACTION_RESET_LICENSE_PRICING_SUCCESS,
  ACTION_RESET_LICENSE_PRICING_FAILURE,
  API_CALL,
} from '../../../configs/ActionTypes';

import { getLicensePricing } from './GetLicensePricing';
import { Dispatch } from 'redux';
import _ from 'lodash-es';

import { enqueueSnackbarHandler } from '../../Status';

export function resetLicensePricing(settingId: string) {
  return (dispatch: Dispatch, getState: Function) => {
    dispatch(requestStarted());
    //Dispatch the api call request.
    dispatch({
      type: API_CALL,
      payload: {
        call: `organizations/setting/${settingId}`,
        method: 'delete',
        data: {},
        success: (response: any) => {
          if (response.type === 'error') {
            enqueueSnackbarHandler(dispatch, response.payload.payload, 'error');
            dispatch(requestFailed(response));
          } else {
            enqueueSnackbarHandler(
              dispatch,
              { updateLicensePricingSuccess: true },
              'success',
            );
            dispatch(requestSuccess(response));
          }
        },
        fail: (error: any) => {
          _.get(error.payload, 'userPermissionError', false)
            ? enqueueSnackbarHandler(dispatch, error.payload, 'error')
            : enqueueSnackbarHandler(
                dispatch,
                { updateLicensePricingFailure: true },
                'error',
              );
          dispatch(requestFailed(error));
        },
      },
    });
    return null;
  };
}

export function requestStarted() {
  return {
    type: ACTION_RESET_LICENSE_PRICING,
    payload: null,
  };
}

export function requestSuccess(responseJSON: object) {
  return {
    type: ACTION_RESET_LICENSE_PRICING_SUCCESS,
    payload: responseJSON,
  };
}

export function requestFailed(responseJSON: object) {
  return {
    type: ACTION_RESET_LICENSE_PRICING_FAILURE,
    payload: responseJSON,
  };
}

import * as React from 'react';
import _ from 'lodash-es';
import cx from 'classnames';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Loader from 'components/Loader/Loader';

const styles = require('./styles.pcss');
import SvgIconCompany from '../../static/icons/svgIconCompany';
import { Company, ReduxState } from '../../reducers/types';
import CompanyDetailsPopup from './CompanyDetailsPopup';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { update } from '../../actions/Companies/Update';
import { getSingle } from '../../actions/Companies/GetSingle';
import { resetSaveCompanyStatus } from '../../actions/Companies/Add';
const sizeOfCompanyOptions = require('../../helpers/sizeOfCompanyOptions.js');

type Props = {
  company: Company;
  disabled: boolean;
  update: any;
  resetSaveCompanyStatus: any;
  saveCompanyStatus: any;
  getSingle: any;
};

type State = {
  openPopup: boolean;
};

class CompanyInfo extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      openPopup: false,
    };
  }

  openPopup = () => {
    this.setState({
      openPopup: true,
    });
  };

  closePopup = () => {
    this.setState({
      openPopup: false,
    });
  };

  onSuccess = () => {
    this.setState({
      openPopup: false,
    });
  };

  updateCompany = (data: any) => {
    this.props.update(data);
  };

  renderLoading() {
    if (_.get(this.props.saveCompanyStatus, 'isLoading', false) === true) {
      return (
        <div className={styles.infoLoader}>
          <Loader visible={true} />
        </div>
      );
    }

    return null;
  }

  render() {
    let paperStyle = styles.paper;

    if (_.get(this.props.saveCompanyStatus, 'isLoading', false) === true) {
      paperStyle = cx(styles.paper, styles.isLoading);
    }

    let companySize = _.get(
      _.find(sizeOfCompanyOptions.sizeOptions, [
        'min',
        _.get(this.props, 'company.size.min', ''),
      ]),
      'text',
      '',
    );

    return (
      <Paper className={paperStyle}>
        <div className={styles.companyInfoHeader}>
          <div className={styles.companyInfoHeaderIconContainer}>
            <SvgIconCompany
              width={10}
              height={16}
            />
          </div>
          <div className={styles.companyInfoHeaderText}>
            <h2>Company Details</h2>
            <div className={styles.description}>
              Basic details of the company.
            </div>
          </div>
        </div>
        <div className={styles.companyInfoContainer}>
          <div className={styles.companyInfoContainerRow}>
            <div
              className={styles.companyInfoTitle}
              id="name"
            >
              {_.get(this.props, 'company.name', '')}
            </div>
            {!this.props.disabled && (
              <Button
                size="small"
                className={cx(styles.button, styles.buttonEdit)}
                variant="outlined"
                color="primary"
                onClick={this.openPopup}
                id="edit-button"
              >
                Edit
              </Button>
            )}
          </div>
          <div className={styles.companyInfoContainerDetails}>
            <div className={styles.descr}>
              Domain: <p>{_.get(this.props, 'company.primaryDomain', '')}</p>
            </div>
            <div className={styles.descr}>
              Number of Employees: <p>{companySize}</p>
            </div>
            <div className={styles.descr}>
              Company Code:{' '}
              <p>{_.get(this.props, 'company.companyCode', '')}</p>
            </div>
          </div>
          <div
            className={cx(
              styles.companyInfoContainerDetails,
              styles.companyInfoAddress,
            )}
          >
            <div className={styles.descr}>
              Address:
              <div className={styles.infCont}>
                <div>
                  {_.get(this.props, 'company.address.street', '') != '' && (
                    <p> {_.get(this.props, 'company.address.street', '')} </p>
                  )}
                  {_.get(this.props, 'company.address.street2', '') != '' && (
                    <p> {_.get(this.props, 'company.address.street2', '')} </p>
                  )}
                </div>
                <div>
                  {_.get(this.props, 'company.address.city', '') != '' && (
                    <p> {_.get(this.props, 'company.address.city', '')}, </p>
                  )}
                  {_.get(this.props, 'company.address.state', '') != '' && (
                    <p> {_.get(this.props, 'company.address.state', '')} </p>
                  )}
                  {_.get(this.props, 'company.address.zip', '') != '' && (
                    <p> {_.get(this.props, 'company.address.zip', '')} </p>
                  )}
                </div>
                <div>
                  {_.get(this.props, 'company.address.country', '') != '' && (
                    <p> {_.get(this.props, 'company.address.country', '')} </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <CompanyDetailsPopup
          open={this.state.openPopup}
          onClose={this.closePopup}
          onSubmit={this.updateCompany}
          company={this.props.company}
          saveCompanyStatus={this.props.saveCompanyStatus}
          resetSaveCompanyStatus={this.props.resetSaveCompanyStatus}
          onSuccess={this.onSuccess}
          isLoading={_.get(this.props.saveCompanyStatus, 'isLoading', false)}
        />

        {this.renderLoading()}
      </Paper>
    );
  }
}

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {
    saveCompanyStatus: state.saveCompanyStatus,
  };
}

// Wire up redux dispatch functions
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      update,
      resetSaveCompanyStatus,
      getSingle,
    },
    dispatch,
  );
}

// Connect it to Redux
export default connect(mapStateToProps, mapDispatchToProps)(CompanyInfo);

import React from 'react';

type Props = {
  width: number;
  height: number;
};

const SvgIconMasterCard = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Mastercard</title>
      <desc>Created with Sketch.</desc>
      <defs>
        <rect
          id="path-1"
          x="0"
          y="0"
          width="32"
          height="32"
          rx="16"
        ></rect>
      </defs>
      <g
        id="Assets"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Mastercard">
          <mask
            id="mask-2"
            fill="white"
          >
            <use xlinkHref="#path-1"></use>
          </mask>
          <use
            id="Mask"
            fill="#F8F8F9"
            xlinkHref="#path-1"
          ></use>
          <rect
            id="Rectangle-path"
            fill="#FF5F00"
            fillRule="nonzero"
            x="12.5196581"
            y="10.5054795"
            width="5.92307692"
            height="10.8567123"
          ></rect>
          <path
            d="M12.8957265,15.9347945 C12.893512,13.8159026 13.8469621,11.8137208 15.4811966,10.5054795 C12.7057725,8.28083185 8.72015231,8.60487101 6.32532268,11.2498709 C3.93049305,13.8948708 3.93049305,17.9728004 6.32532268,20.6178003 C8.72015231,23.2628002 12.7057725,23.5868394 15.4811966,21.3621918 C13.847482,20.0543657 12.8940901,18.0530134 12.8957265,15.9347945 Z"
            id="_Path_"
            fill="#EB001B"
            fillRule="nonzero"
          ></path>
          <path
            d="M26.434188,15.9347945 C26.4341276,18.5781825 24.9542501,20.9894946 22.6230651,22.1446372 C20.2918801,23.2997798 17.5186579,22.9959524 15.4811966,21.3621918 C17.1141983,20.0532554 18.067662,18.0521692 18.067662,15.9338356 C18.067662,13.815502 17.1141983,11.8144158 15.4811966,10.5054795 C17.5186579,8.8717188 20.2918801,8.56789145 22.6230651,9.72303405 C24.9542501,10.8781766 26.4341276,13.2894887 26.434188,15.9328767 L26.434188,15.9347945 Z"
            id="Shape"
            fill="#F79E1B"
            fillRule="nonzero"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default SvgIconMasterCard;

/**
 * POST BB_USER
 */
import _ from 'lodash-es';
import { Dispatch } from 'redux';

import {
  ACTION_POST_BB_USER,
  ACTION_POST_BB_USER_SUCCESS,
  ACTION_POST_BB_USER_FAILURE,
  ACTION_RESET_BB_USER,
  API_CALL,
} from '../../../configs/ActionTypes';

import { track } from '../../../analytics/analytics';
import { enqueueSnackbarHandler } from '../../Status';

/**
 * POST Users Request Action
 */
export function add(dataToSend: any) {
  return (dispatch: Dispatch, getState: Function) => {
    dispatch({
      type: ACTION_POST_BB_USER,
      payload: null,
    });

    //Dispatch the api call request.
    dispatch({
      type: API_CALL,
      payload: {
        call: 'bb-user',
        method: 'post',
        data: JSON.stringify(dataToSend),
        success: (response: object) => {
          dispatch(requestSuccess(response));
          enqueueSnackbarHandler(
            dispatch,
            { addBBuserSuccess: true },
            'success',
          );
          handleTrack(_.get(dataToSend, 'agencyId', ''));
        },
        fail: (error: any) => {
          _.get(error.payload, 'userPermissionError', false)
            ? enqueueSnackbarHandler(dispatch, error.payload, 'error')
            : enqueueSnackbarHandler(
                dispatch,
                { addBBuserFailed: true },
                'error',
              );
          dispatch(requestFailed(error));
        },
      },
    });

    return null;
  };
}

export function resetAddUser() {
  return (dispatch: Dispatch, getState: Function) => {
    // Dispatch the api call request.
    dispatch({
      type: ACTION_RESET_BB_USER,
      payload: null,
    });
  };
}

function requestSuccess(responeJSON: object) {
  return {
    type: ACTION_POST_BB_USER_SUCCESS,
    payload: responeJSON,
  };
}

function requestFailed(responeJSON: object) {
  return {
    type: ACTION_POST_BB_USER_FAILURE,
    payload: responeJSON,
  };
}

function handleTrack(agencyId: string) {
  let properties = {
    Agency: agencyId,
  };

  track('BB_BBUserCreated', properties);
}

import React from 'react';

type Props = {
  width: number;
  height: number;
};

const SvgBankIcon = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs></defs>
      <g
        id="Master"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Organization-Macro---Expenses"
          transform="translate(-680.000000, -602.000000)"
          fill="#6D717A"
          fillRule="nonzero"
        >
          <g
            id="reimbursements"
            transform="translate(280.000000, 514.000000)"
          >
            <g
              id="Company-Bank-Account"
              transform="translate(32.000000, 62.000000)"
            >
              <g
                id="Group-6"
                transform="translate(344.000000, 0.000000)"
              >
                <g
                  id="Bank-Account"
                  transform="translate(24.000000, 26.000000)"
                >
                  <g id="Group-5">
                    <g id="bank">
                      <path
                        d="M19.3418594,17.1584699 L18.8247489,17.1584699 L18.8247489,15.6284153 C18.8247489,15.5846995 18.8247489,15.5846995 18.8247489,15.5409836 C18.8247489,15.0601093 18.4016586,14.7103825 17.9315581,14.7103825 L17.6965079,14.7103825 L17.6965079,5.4863388 L19.0597992,5.4863388 C19.5769096,5.4863388 19.95299,5.09289617 19.95299,4.6557377 C19.95299,4.30601093 19.7179397,3.95628415 19.3418594,3.86885246 L10.3629413,0.0655737705 C10.1278911,-0.0218579235 9.84583084,-0.0218579235 9.61078063,0.0655737705 L0.537842446,3.86885246 C0.161762107,4 -0.0732881053,4.43715847 0.0207319795,4.78688525 C0.114752064,5.18032787 0.490832404,5.44262295 0.913922785,5.44262295 L2.27721401,5.44262295 L2.27721401,14.6666667 L2.0421638,14.6666667 C1.52505334,14.6666667 1.148973,15.0601093 1.148973,15.4972678 L1.148973,17.1147541 L0.678872573,17.1147541 C0.349802276,17.1147541 0.0677420219,17.3770492 0.0677420219,17.6830601 L0.0677420219,19.431694 C0.0677420219,19.7377049 0.349802276,20 0.678872573,20 L19.3888694,20 C19.7179397,20 20,19.7377049 20,19.431694 L20,17.6830601 C20,17.420765 19.7179397,17.1584699 19.3418594,17.1584699 Z M13.1835439,5.3989071 L15.9101263,5.3989071 L15.9101263,14.7978142 L13.1835439,14.7978142 L13.1835439,5.3989071 L13.1835439,5.3989071 Z M8.62356974,5.3989071 L11.3501522,5.3989071 L11.3501522,14.7978142 L8.62356974,14.7978142 L8.62356974,5.3989071 Z M4.11060567,5.3989071 L6.83718813,5.3989071 L6.83718813,14.7978142 L4.11060567,14.7978142 L4.11060567,5.3989071 Z"
                        id="Shape"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgBankIcon;

import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import TextFieldWithClear from 'components/TextFieldWithIcon/TextFieldWithIcon';
const styles = require('./styles.pcss');
import _ from 'lodash-es';
import AutoSuggest from 'components/Inputs/AutoSuggest/AutoSuggest';
import { AIRLINE_NAME_MAP } from '../../helpers/airlinemap';
import DatePicker from 'components/DatePickers/DatePicker';
import Dialog from '@material-ui/core/Dialog';
import cx from 'classnames';
import { PostFlightCreditParams } from 'actions/FlightCredits/PostFlightCredit';

interface Props {
  firstName?: string;
  lastName?: string;
  ticketNumber?: string;
  pnr?: string;
  airline?: string;
  bookingId?: string;
  expiryDate?: Date;
  totalValue?: number;
  title?: string;
  submit: Function;
  close: Function;
  open: boolean;
}

export default function AddEditFlightCredit(props: Props) {
  const [firstName, setFirstName] = useState(props.firstName || '');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastName, setLastName] = useState(props.lastName || '');
  const [lastNameError, setLastNameError] = useState('');
  const [airline, setAirline] = useState(props.airline || '');
  const [airlineError, setAirlineError] = useState('');
  const [expiryDate, setExpiryDate] = useState(props.expiryDate || new Date());
  const [pnr, setPNR] = useState(props.pnr || '');
  const [pnrError, setPNRError] = useState('');
  const [totalValue, setTotalValue] = useState(props.totalValue || 0);
  const [totalValueError, setTotalValueError] = useState('');
  const [ticketNumber, setTicketNumber] = useState(props.ticketNumber || '');
  const [ticketNumberError, setTicketNumberError] = useState('');
  const [fieldsNotFilledError, setFieldsNotFilledError] = useState('');
  const onChangeFirstName = (event: any) => {
    setFieldsNotFilledError('');
    setFirstNameError('');
    setFirstName(event.target.value);
  };

  const onChangeLastName = (event: any) => {
    setFieldsNotFilledError('');
    setLastNameError('');
    setLastName(event.target.value);
  };

  const onChangePNR = (event: any) => {
    setFieldsNotFilledError('');
    setPNRError('');
    setPNR(event.target.value);
  };

  const onChangeTicketNumber = (event: any) => {
    setFieldsNotFilledError('');
    setTicketNumberError('');
    setTicketNumber(event.target.value);
  };

  const onChangeAirline = (airline: any) => {
    setFieldsNotFilledError('');
    setAirlineError('');
    if (!airline) {
      setAirlineError('Required');
    }

    setAirline(airline.value);
  };

  const onChangeExpiryDate = (value: any) => {
    setFieldsNotFilledError('');
    setExpiryDate(value);
  };

  const onChangeTotalValue = (event: any) => {
    setFieldsNotFilledError('');
    setTotalValueError('');
    setTotalValue(event.target.value);
  };

  const onClearTotalValue = () => {
    setTotalValue(0);
  };

  const onClearTicketNumber = () => {
    setTicketNumber('');
  };

  const onClearPNR = () => {
    setPNR('');
  };

  const onClearFirstName = () => {
    setFirstName('');
  };

  const onClearLastName = () => {
    setLastName('');
  };

  const onBlurTotalValue = () => {
    if (!totalValue) {
      setTotalValueError('Required');
    }
  };

  const onBlurTicketNumber = () => {
    if (!ticketNumber) {
      setTicketNumberError('Required');
    }
  };

  const onBlurPNR = () => {
    if (!pnr) {
      setPNRError('Required');
    }
  };

  const onBlurFirstName = () => {
    if (!firstName) {
      setFirstNameError('Required');
    }
  };

  const onBlurLastName = () => {
    if (!lastName) {
      setLastNameError('Required');
    }
  };

  const close = () => {
    props.close();
  };

  const fieldsFilled = () => {
    return (
      firstName &&
      lastName &&
      pnr &&
      ticketNumber &&
      expiryDate &&
      airline &&
      pnr
    );
  };

  const onClickSubmit = () => {
    if (fieldsFilled()) {
      props.submit({
        firstName: firstName.toUpperCase(),
        lastName: lastName.toUpperCase(),
        bookingId: props.bookingId,
        expiryDate,
        airline,
        pnr,
        ticketNumber,
        totalValue: Number(totalValue),
      } as PostFlightCreditParams);
      props.close();
    } else {
      setFieldsNotFilledError(
        'All fields are required. Please ensure all of the information is filled out above',
      );
    }
  };

  let airlines: any = [];

  _.forOwn(AIRLINE_NAME_MAP, function (value, key) {
    airlines.push({ code: key, name: value });
  });

  return (
    <Dialog
      maxWidth={'md'}
      fullWidth={true}
      className={cx(styles.dialogBox, styles.addFlightCreditPopup)}
      open={props.open}
      onClose={close}
    >
      <div>
        <h1>Add Flight Credit</h1>
        <h2 className={styles.syncHeader}>{props.title || ''}</h2>
        <div className={styles.syncContainer}>
          <TextFieldWithClear
            onChange={onChangeTicketNumber}
            label="Ticket Number of Credit"
            className={styles.addFlightCreditField}
            variant="outlined"
            margin="normal"
            value={ticketNumber}
            onClear={onClearTicketNumber}
            onBlur={onBlurTicketNumber}
            fullWidth={true}
            helperText={ticketNumberError}
            error={!!ticketNumberError}
          />
          <AutoSuggest
            className={cx(styles.addFlightCreditFieldSpecial)}
            onChange={(value: any) => {
              onChangeAirline(value);
            }}
            label={'Applicable Airline'}
            value={airline}
            dataSet={airlines}
            maxMenuHeight={90}
            returnAll={true}
            error={airlineError}
          />
          <TextFieldWithClear
            onChange={onChangeFirstName}
            label="First Name on Credit"
            className={styles.addFlightCreditField}
            variant="outlined"
            margin="normal"
            value={firstName}
            onBlur={onBlurFirstName}
            onClear={onClearFirstName}
            fullWidth={true}
            helperText={firstNameError}
            error={!!firstNameError}
          />
          <TextFieldWithClear
            onChange={onChangeLastName}
            label="Last Name on Credit"
            className={styles.addFlightCreditField}
            variant="outlined"
            margin="normal"
            value={lastName}
            onBlur={onBlurLastName}
            onClear={onClearLastName}
            fullWidth={true}
            helperText={lastNameError}
            error={!!lastNameError}
          />
          <TextFieldWithClear
            onChange={onChangeTotalValue}
            label="Total Value of Credit"
            className={styles.addFlightCreditField}
            variant="outlined"
            margin="normal"
            value={totalValue}
            onBlur={onBlurTotalValue}
            onClear={onClearTotalValue}
            helperText={totalValueError}
            error={!!totalValueError}
            fullWidth={true}
          />
          <TextFieldWithClear
            onChange={onChangePNR}
            label="PNR of orginal booking that resulted in this flight credit"
            className={styles.addFlightCreditField}
            variant="outlined"
            margin="normal"
            value={pnr}
            onBlur={onBlurPNR}
            onClear={onClearPNR}
            helperText={pnrError}
            error={!!pnrError}
            fullWidth={true}
          />

          <DatePicker
            className={styles.addFlightCreditFieldSpecial}
            id="expriry_date"
            date={expiryDate}
            label="Use by date"
            onChange={onChangeExpiryDate}
          />
          <p className={styles.fcFieldsNotFilledError}>
            {fieldsNotFilledError}
          </p>
          <div className={styles.flightCreditButtonContainer}>
            <Button
              color="primary"
              variant="outlined"
              className={styles.syncButton}
              onClick={close}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              className={styles.syncButton}
              onClick={onClickSubmit}
            >
              Add Credit
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

/**
 * GET Frequent Flyers
 */

import {
  ACTION_GET_FREQUENT_FLYERS_SUCCESS,
  API_CALL,
} from '../../configs/ActionTypes';

import { Dispatch } from 'redux';

/**
 * POST Users Request Action
 */
export function getFrequentFlyers() {
  return (dispatch: Dispatch, getState: Function) => {
    //Dispatch the api call request.
    dispatch({
      type: API_CALL,
      payload: {
        call: 'frequent-flyers',
        method: 'get',
        success: (response: any) => {
          dispatch(requestSuccess(response));
        },
      },
    });

    return null;
  };
}

export function requestSuccess(responseJSON: object) {
  return {
    type: ACTION_GET_FREQUENT_FLYERS_SUCCESS,
    payload: responseJSON,
  };
}

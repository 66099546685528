import * as React from 'react';
import _ from 'lodash-es';
import cx from 'classnames';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import TravelerTableRow from './TravelerTableRow';
import SvgAddIcon from '../../static/icons/svgAddIcon';
import ConfirmationPopup from 'components/Popup/Confirmation';
import SvgMembership from '../../static/icons/svgMembership';
import AddMileageProgramPopup from 'components/Popup/AddMileageProgramPopup';
import UpdateProgramPopup from 'components/Popup/UpdateProgramPopup';

const listOfHotelLoyaltyPrograms = require('../../helpers/HotelLoyaltyPrograms.js');

import { connect } from 'react-redux';

const styles = require('./styles.pcss');

import { ReduxState } from 'reducers/types';

import { FrequentFlyer } from 'reducers/types';

type Props = {
  travelerFrequentFlyerData: Array<FrequentFlyer>;
  frequentFlyers: any;
  update: any;
  hasPermission: any;
  updateHotelLoyalty?: any;
  travelerHotelLoyaltyProgram: any;
  updateHotelProgram: any;
  isLoading: any;
  userLoading: any;
  carLoyaltyProgram: any;
  travelerCarLoyaltyProgram: any;
  updateCarProgram: any;
};

type State = {
  tabValue: string;
  addNewOpen: boolean;
  openConfirmation: boolean;
  programToDelete: any;
  udateTitle: any;
  updateSubtitle: any;
  updateLable: any;
  updateNumberLabel: any;
  updateProgramPopup: boolean;
  updateProgram: any;
  updateNumber: any;
};

class LoyaltyPrograms extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      tabValue: 'flight',
      addNewOpen: false,
      openConfirmation: false,
      programToDelete: null,
      udateTitle: '',
      updateSubtitle: '',
      updateLable: '',
      updateNumberLabel: '',
      updateProgramPopup: false,
      updateProgram: '',
      updateNumber: '',
    };
  }

  handleTabChange = (event: any, value: any) => {
    this.setState({
      tabValue: value,
      addNewOpen: false,
    });
  };

  onClickAddProgram = () => {
    this.setState({
      addNewOpen: true,
    });
  };

  onClickCancelAddProgram = () => {
    this.setState({
      addNewOpen: false,
    });
  };

  onClickEdit = (number: any, name: any, index: any) => {
    let updateTitle = 'Update Rental Car Loyalty Program';
    let updateSubtitle =
      'Update Rental Car Loyalty Program Number to earn points and unlock status tiers.';
    let updateLabel = 'Rental Car Loyalty Program';
    let updateNumberLabel = 'Rental Car Loyalty Program Number';

    switch (this.state.tabValue) {
      case 'flight':
        updateTitle = 'Update Airline Loyalty Program';
        updateSubtitle =
          'Update Airline Loyalty Program Number to earn points and unlock status tiers.';
        updateLabel = 'Mileage Program';
        updateNumberLabel = 'Mileage Program Number';
        break;
      case 'hotel':
        updateTitle = 'Update Hotel Loyalty Program';
        updateSubtitle =
          'Update Hotel Loyalty Program Number to earn points and unlock status tiers.';
        updateLabel = 'Hotel Loyalty Program';
        updateNumberLabel = 'Hotel Loyalty Program Number';
        break;
    }

    this.setState({
      udateTitle: updateTitle,
      updateSubtitle: updateSubtitle,
      updateLable: updateLabel,
      updateNumberLabel: updateNumberLabel,
      updateProgramPopup: true,
      updateProgram: name,
      updateNumber: number,
      programToDelete: index,
    });
  };

  onClickRemove = () => {
    // let frequentFlyers = _.clone(this.props.travelerFrequentFlyerData);
    // _.pullAt(frequentFlyers, index);
    // this.props.update(frequentFlyers);

    this.setState({
      openConfirmation: true,
    });
  };

  closeConfirmation = () => {
    this.setState({
      openConfirmation: false,
    });
  };

  onSubmitDelete = () => {
    if (this.state.tabValue == 'flight') {
      let frequentFlyers = _.clone(this.props.travelerFrequentFlyerData);
      _.pullAt(frequentFlyers, this.state.programToDelete);

      this.props.update(frequentFlyers);
    } else if (this.state.tabValue == 'hotel') {
      let hotelLoyaltyProgram = _.clone(this.props.travelerHotelLoyaltyProgram);
      hotelLoyaltyProgram = _.reject(
        hotelLoyaltyProgram,
        this.state.programToDelete,
      );

      this.props.updateHotelProgram(hotelLoyaltyProgram);
    } else {
      let carLoyaltyProgram = _.clone(this.props.travelerCarLoyaltyProgram);
      let programToDelete = _.clone(this.state.programToDelete);
      delete programToDelete.name;

      carLoyaltyProgram = _.reject(carLoyaltyProgram, programToDelete);
      this.props.updateCarProgram(carLoyaltyProgram);
    }

    this.setState({
      openConfirmation: false,
      programToDelete: null,
      updateProgramPopup: false,
    });
  };

  handleSubmitProgram = (programNumber: string, program: string) => {
    const newFrequentFlyer: FrequentFlyer = {
      airlineCode: program,
      lastUsed: program,
      number: programNumber,
    };

    let frequentFlyers = _.clone(this.props.travelerFrequentFlyerData);

    frequentFlyers = [newFrequentFlyer, ...frequentFlyers];

    this.props.update(frequentFlyers);

    this.setState({
      addNewOpen: false,
    });
  };

  handleSubmitHotelProgram = (programNumber: string, program: string) => {
    const hotelLoyaltyProgram: any = {
      name: program,
      number: programNumber,
    };

    let loyaltyPrograms = _.clone(this.props.travelerHotelLoyaltyProgram);

    loyaltyPrograms = [hotelLoyaltyProgram, ...loyaltyPrograms];

    this.props.updateHotelProgram(loyaltyPrograms);

    this.setState({
      addNewOpen: false,
    });
  };

  handleSubmitCarProgram = (programNumber: string, program: string) => {
    const carLoyaltyProgram: any = {
      code: program,
      number: programNumber,
    };

    let loyaltyPrograms = _.clone(this.props.travelerCarLoyaltyProgram);

    loyaltyPrograms = [carLoyaltyProgram, ...loyaltyPrograms];
    this.props.updateCarProgram(loyaltyPrograms);

    this.setState({
      addNewOpen: false,
    });
  };
  handleUpdateProgram = (programNumber: string, program: string) => {
    if (this.state.tabValue == 'flight') {
      let frequentFlyers = _.clone(this.props.travelerFrequentFlyerData);
      _.pullAt(frequentFlyers, this.state.programToDelete);

      const newFrequentFlyer: FrequentFlyer = {
        airlineCode: program,
        lastUsed: program,
        number: programNumber,
      };

      frequentFlyers = [newFrequentFlyer, ...frequentFlyers];

      this.props.update(frequentFlyers);
    } else if (this.state.tabValue == 'hotel') {
      let loyaltyPrograms = _.clone(this.props.travelerHotelLoyaltyProgram);
      loyaltyPrograms = _.reject(loyaltyPrograms, this.state.programToDelete);

      const hotelLoyaltyProgram: any = {
        name: program,
        number: programNumber,
      };

      loyaltyPrograms = [hotelLoyaltyProgram, ...loyaltyPrograms];

      this.props.updateHotelProgram(loyaltyPrograms);
    } else {
      let loyaltyPrograms = _.clone(this.props.travelerCarLoyaltyProgram);
      let programToDelete = _.clone(this.state.programToDelete);
      delete programToDelete.name;

      loyaltyPrograms = _.reject(loyaltyPrograms, programToDelete);

      const hotelLoyaltyProgram: any = {
        code: program,
        number: programNumber,
      };

      loyaltyPrograms = [hotelLoyaltyProgram, ...loyaltyPrograms];

      this.props.updateCarProgram(loyaltyPrograms);
    }

    this.setState({
      updateProgramPopup: false,
    });
  };

  getAirlineName = () => {
    return this.props.travelerFrequentFlyerData.map(
      (frequentFlyer: any, i: number) => {
        const airlineCode = _.get(frequentFlyer, [i, 'airlineCode'], '');
        const flyerFromSource = _.find(
          this.props.frequentFlyers,
          (ff: any) => ff.airlineCode == frequentFlyer.airlineCode,
        );
        return {
          airlineName: `${_.get(flyerFromSource, 'airlineName', '')} / ${_.get(
            flyerFromSource,
            'programName',
            '',
          )}`,
          index: i,
          number: frequentFlyer.number,
          airlineCode: frequentFlyer.airlineCode,
        };
      },
    );
  };

  getProviderName = (carLoyaltyProgram: any) => {
    const code = _.get(
      _.find(this.props.carLoyaltyProgram, ['code', carLoyaltyProgram.code]),
      'name',
      '',
    );

    return {
      name: code,
      code: carLoyaltyProgram.code,
      number: carLoyaltyProgram.number,
    };
  };

  renderTabContent = () => {
    if (this.state.tabValue === 'flight') {
      var sortTravelerFrequentFlyerData: { [k: string]: any } =
        this.getAirlineName();

      sortTravelerFrequentFlyerData.sort((a: any, b: any) =>
        a.airlineName.localeCompare(b.airlineName),
      );
      return (
        <>
          {this.state.addNewOpen && (
            <AddMileageProgramPopup
              open={this.state.addNewOpen}
              in_process={this.props.isLoading || this.props.userLoading}
              onCancel={this.onClickCancelAddProgram}
              type={this.state.tabValue}
              submit={this.handleSubmitProgram}
              listOfLoyaltyProgram={this.props.frequentFlyers}
              label={'Mileage Program'}
              numberLabel={'Mileage Program Number'}
              title={'Add Airline Loyalty Program'}
              subtitle={
                'Add an Airline Loyalty Program Number to earn points and unlock status tiers.'
              }
            />
          )}
          <div className={styles.membershipDescription}>
            Add an Airline Loyalty Program Number to earn points and unlock
            status tiers.
          </div>
          <Table className={styles.loyaltyProgramTable}>
            <TableBody>
              {sortTravelerFrequentFlyerData.map((frequentFlyer: any) => {
                return (
                  <TravelerTableRow
                    firstItemToLeft={true}
                    clickEdit={this.onClickEdit.bind(
                      this,
                      frequentFlyer.number,
                      frequentFlyer.airlineCode,
                      frequentFlyer.index,
                    )}
                    clickRemove={this.onClickRemove.bind(
                      this,
                      frequentFlyer.index,
                    )}
                    showEdit={
                      !this.state.addNewOpen && this.props.hasPermission
                    }
                    key={frequentFlyer.index}
                    rowColumns={[
                      frequentFlyer.number,
                      frequentFlyer.airlineName,
                    ]}
                  />
                );
              })}
            </TableBody>
          </Table>
        </>
      );
    } else if (this.state.tabValue === 'hotel') {
      let travelerHotelLoyaltyProgramData = _.clone(
        this.props.travelerHotelLoyaltyProgram,
      );

      travelerHotelLoyaltyProgramData.sort((a: any, b: any) =>
        a.name.localeCompare(b.name),
      );

      let sortPrograms = _.clone(listOfHotelLoyaltyPrograms.list);
      sortPrograms.sort((a: any, b: any) => a.localeCompare(b));

      return (
        <>
          {this.state.addNewOpen && (
            <AddMileageProgramPopup
              open={this.state.addNewOpen}
              in_process={this.props.isLoading || this.props.userLoading}
              onCancel={this.onClickCancelAddProgram}
              type={this.state.tabValue}
              submit={this.handleSubmitHotelProgram}
              listOfLoyaltyProgram={sortPrograms}
              label={'Hotel Loyalty Program'}
              numberLabel={'Hotel Loyalty Program Number'}
              title={'Add Hotel Loyalty Program'}
              subtitle={
                'Add a Hotel Loyalty Program Number to earn points and unlock status tiers.'
              }
            />
          )}
          <div className={styles.membershipDescription}>
            Add a Hotel Loyalty Program Number to earn points and unlock status
            tiers.
          </div>
          <Table className={styles.loyaltyProgramTable}>
            <TableBody>
              {travelerHotelLoyaltyProgramData.map(
                (loyaltyProgram: any, ind: any) => {
                  return (
                    <TravelerTableRow
                      firstItemToLeft={true}
                      clickEdit={this.onClickEdit.bind(
                        this,
                        loyaltyProgram.number,
                        loyaltyProgram.name,
                        loyaltyProgram,
                      )}
                      clickRemove={this.onClickRemove.bind(
                        this,
                        loyaltyProgram,
                      )}
                      showEdit={
                        !this.state.addNewOpen && this.props.hasPermission
                      }
                      key={loyaltyProgram.name + ind}
                      rowColumns={[loyaltyProgram.number, loyaltyProgram.name]}
                    />
                  );
                },
              )}
            </TableBody>
          </Table>
        </>
      );
    } else {
      let travelerCarLoyaltyProgram = _.clone(
        this.props.travelerCarLoyaltyProgram,
      );

      travelerCarLoyaltyProgram = _.map(
        travelerCarLoyaltyProgram,
        this.getProviderName,
      );
      travelerCarLoyaltyProgram.sort((a: any, b: any) =>
        a.name.localeCompare(b.name),
      );

      const dataSet = _.orderBy(
        this.props.carLoyaltyProgram,
        [(vendor: any) => vendor.name.toLowerCase()],
        ['asc'],
      );

      return (
        <>
          {this.state.addNewOpen && (
            <AddMileageProgramPopup
              open={this.state.addNewOpen}
              in_process={this.props.isLoading || this.props.userLoading}
              onCancel={this.onClickCancelAddProgram}
              type={this.state.tabValue}
              submit={this.handleSubmitCarProgram}
              listOfLoyaltyProgram={dataSet}
              label={'Rental Car Loyalty Program'}
              numberLabel={'Rental Car Loyalty Program Number'}
              title={'Add Loyalty Program'}
              subtitle={
                'Add a Rental Car Loyalty Program Number to earn points and unlock status tiers.'
              }
            />
          )}
          <div className={styles.membershipDescription}>
            Add a Rental Car Loyalty Program Number to earn points and unlock
            status tiers.
          </div>
          <Table className={styles.loyaltyProgramTable}>
            <TableBody>
              {travelerCarLoyaltyProgram.map(
                (loyaltyProgram: any, ind: any) => {
                  return (
                    <TravelerTableRow
                      firstItemToLeft={true}
                      clickEdit={this.onClickEdit.bind(
                        this,
                        loyaltyProgram.number,
                        loyaltyProgram.code,
                        loyaltyProgram,
                      )}
                      clickRemove={this.onClickRemove.bind(
                        this,
                        loyaltyProgram,
                      )}
                      showEdit={
                        !this.state.addNewOpen && this.props.hasPermission
                      }
                      key={loyaltyProgram.name + 'car' + ind}
                      rowColumns={[loyaltyProgram.number, loyaltyProgram.name]}
                    />
                  );
                },
              )}
            </TableBody>
          </Table>
        </>
      );
    }

    return null;
  };

  renderIcon = () => {
    if (
      this.props.travelerFrequentFlyerData.length < 1 &&
      this.state.tabValue == 'flight'
    ) {
      return (
        <SvgMembership
          width={32}
          height={32}
          color={'#e0e1e6'}
        />
      );
    } else if (
      this.props.travelerHotelLoyaltyProgram.length < 1 &&
      this.state.tabValue == 'hotel'
    ) {
      return (
        <SvgMembership
          width={32}
          height={32}
          color={'#e0e1e6'}
        />
      );
    }

    return (
      <SvgMembership
        width={32}
        height={32}
        color={'#1B2432'}
      />
    );
  };

  onClickCancelUpdateProgram = () => {
    this.setState({
      updateProgramPopup: false,
    });
  };

  render() {
    let buttontext = 'Add Loyalty Program';
    let listOfLoyaltyProgram = '';

    switch (this.state.tabValue) {
      case 'flight':
        listOfLoyaltyProgram = this.props.frequentFlyers;
        break;
      case 'hotel':
        listOfLoyaltyProgram = listOfHotelLoyaltyPrograms.list;
        break;
      default:
        listOfLoyaltyProgram = this.props.carLoyaltyProgram;
    }

    return (
      <Paper className={styles.paper}>
        <div className={styles.iconAndInfoContainer}>
          <div className={styles.iconContainer}>{this.renderIcon()}</div>
          <div>
            <div className={styles.titleStyle}>Loyalty Program</div>
            <div className={styles.subtitleStyle}>
              An overview of the traveler’s loyalty programs used to book
              travel.
            </div>
          </div>
        </div>

        <Tabs
          value={this.state.tabValue}
          indicatorColor="primary"
          textColor="primary"
          onChange={this.handleTabChange}
          className={cx(styles.tabs, styles.loyalityTabs)}
        >
          <Tab
            disableRipple
            className={styles.tab}
            label="Flight"
            value="flight"
          />
          <Tab
            disableRipple
            className={styles.tab}
            label="Hotel"
            value="hotel"
          />
          <Tab
            disableRipple
            className={styles.tab}
            label="Rental Car"
            value="car"
          />
        </Tabs>

        {this.renderTabContent()}

        {this.state.addNewOpen === false &&
          this.props.hasPermission && ( // Disabled button on Hotel tab for now
            <div
              className={styles.addProgram}
              onClick={this.onClickAddProgram}
            >
              <div className={styles.addIcon}>
                <SvgAddIcon
                  width={18}
                  height={18}
                />
              </div>
              <div className={styles.addProgramButtonLabel}>{buttontext}</div>
            </div>
          )}

        <ConfirmationPopup
          open={this.state.openConfirmation}
          onClose={this.closeConfirmation}
          onSubmit={this.onSubmitDelete}
          title="Are you sure?"
          bodyText="Are you sure you want to remove this Loyalty Program?"
          submitButtonText="Remove"
          closeButtonText="Cancel"
        />

        <UpdateProgramPopup
          open={this.state.updateProgramPopup}
          in_process={this.props.isLoading || this.props.userLoading}
          onClose={this.onClickCancelUpdateProgram}
          type={this.state.tabValue}
          submit={this.handleUpdateProgram}
          listOfLoyaltyProgram={listOfLoyaltyProgram}
          label={this.state.updateLable}
          numberLabel={this.state.updateNumberLabel}
          title={this.state.udateTitle}
          subtitle={this.state.updateSubtitle}
          program={this.state.updateProgram}
          number={this.state.updateNumber}
          remove={this.onClickRemove}
        />
      </Paper>
    );
  }
}

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {
    frequentFlyers: state.frequentFlyers.frequentFlyers,
    isLoading: state.traveler.isLoading,
    userLoading: state.user.isLoading,
  };
}

// Connect it to Redux
export default connect(mapStateToProps)(LoyaltyPrograms);

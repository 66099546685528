import React from 'react';

type Props = {
  width: number;
  height: number;
};

const SvgWifiIcon = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 16 11"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs></defs>
      <g
        id="(WIP)-Refund-Card"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Booking-Macro---Flight---Flight-Modal"
          transform="translate(-950.000000, -572.000000)"
          fill="#5F6670"
        >
          <g
            id="create-modal"
            transform="translate(370.000000, 143.000000)"
          >
            <g id="content">
              <g
                id="Flight-Card"
                transform="translate(1.000000, 77.000000)"
              >
                <g
                  id="amenities-1"
                  transform="translate(31.000000, 333.000000)"
                >
                  <g
                    id="Group-17"
                    transform="translate(16.000000, 16.000000)"
                  >
                    <g
                      id="Group-16"
                      transform="translate(532.000000, 2.000000)"
                    >
                      <path
                        d="M7.99433546,2.87184826 C10.5806989,2.87184826 12.937815,3.75132561 14.7451372,5.19344995 L16,3.91459642 C13.8594694,2.19934247 11.0638511,1 7.99433546,1 C4.92948478,1 2.13786497,2.19570074 -5.32907052e-15,3.9054921 L1.25219709,5.18616649 C3.05818651,3.74829083 5.41130409,2.87184826 7.99433546,2.87184826 L7.99433546,2.87184826 Z M12.0528135,7.93870601 L13.3803157,6.58519705 C11.9355242,5.44048051 10.0562289,4.69271233 7.99433546,4.69271233 C5.9364405,4.69271233 4.05847807,5.43865964 2.61501937,6.57973446 L3.9405223,7.93506428 C5.03211296,7.08107903 6.44624932,6.56334668 7.99433546,6.56334668 C9.54442084,6.56334668 10.9605565,7.08289989 12.0528135,7.93870601 Z M10.7246449,9.29221496 C9.98558874,8.72592624 9.0339456,8.33383351 7.99366904,8.33383351 C6.95539173,8.33383351 6.00508143,8.72531928 5.26602524,9.28918019 L7.99366904,12.0769231 L10.7246449,9.29221496 L10.7246449,9.29221496 Z"
                        id="Wifi"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgWifiIcon;

import React, { Component } from 'react';
import classNames from 'classnames';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import IconCheckboxChecked from '../../../static/icons/svgCheckboxChecked';
import IconCheckbox from '../../../static/icons/svgCheckbox';
import TextFieldWithClear from 'components/TextFieldWithIcon/TextFieldWithIcon';

const styles = require('../styles.pcss');

type Props = {
  value: any;
  onChange: any;
  error: any;
  onClear: Function;
  label: any;
  id: any;
  checked: any;
  onChangeVersion: any;
  disabled: any;
};

const FeatureFlagPopupComponent = (props: Props) => {
  return (
    <>
      <div className={styles.checkName}>
        <FormGroup>
          <FormControlLabel
            disabled={props.disabled}
            control={
              <Checkbox
                icon={
                  <IconCheckbox
                    width={16}
                    height={16}
                  />
                }
                checkedIcon={
                  <IconCheckboxChecked
                    width={16}
                    height={16}
                  />
                }
                className={styles.checkboxRating}
                checked={props.checked}
                onChange={props.onChange}
                value={props.checked}
                disabled={props.disabled}
                id={props.id + 'Check'}
              />
            }
            label={props.label}
          />
        </FormGroup>
      </div>
      <div
        className={classNames(styles.inputWrapper, styles.noMarginAndPadding)}
      >
        <TextFieldWithClear
          fullWidth
          id={props.id + 'Version'}
          className={classNames(styles.textField)}
          label="Version"
          variant="outlined"
          margin="normal"
          value={props.value}
          onChange={(value: any) => props.onChangeVersion(props.id, value)}
          error={props.error}
          onClear={props.onClear}
          disabled={props.disabled}
        />
      </div>
    </>
  );
};

export default FeatureFlagPopupComponent;

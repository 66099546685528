import React, { Component } from 'react';
import classNames from 'classnames';
import _ from 'lodash-es';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AutoSuggest from 'components/Inputs/AutoSuggest/AutoSuggest';

const styles = require('./styles.pcss');

type Props = {
  showPopup: boolean;
  closePopup: any;
  handleSubmit: Function;
  title?: string;
  type: any;
  vendorList?: any;
};

type State = {
  rateCode: any;
  rateCodeError: any;
  vendor: any;
  vendorError: any;
  billingCode: string;
  billingCodeError: string;
};

class AddCorporateRateCodePopup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      rateCode: '',
      rateCodeError: '',
      vendor: '',
      vendorError: '',
      billingCode: '',
      billingCodeError: '',
    };
  }

  onChangeRateCode = (event: any) => {
    this.setState({
      rateCode: event.target.value.toUpperCase(),
      rateCodeError: '',
    });
  };

  onChangeBillingCode = (event: any) => {
    this.setState({
      billingCode: event.target.value.toUpperCase(),
      billingCodeError: '',
    });
  };

  validFields = () => {
    let isValid = true;
    let rateCodeError = '';
    let vendorError = '';

    if (this.props.type !== 'car' && this.state.rateCode.trim() === '') {
      rateCodeError = 'Required';
      isValid = false;
    }

    if (this.props.type == 'car' && this.state.vendor.trim() === '') {
      vendorError = 'Required';
      isValid = false;
    }

    this.setState({
      rateCodeError,
      vendorError,
    });

    return isValid;
  };

  handleSubmit = () => {
    if (!this.validFields()) {
      return;
    }

    if (this.props.type == 'car') {
      this.props.handleSubmit({
        vendorCode: this.state.vendor,
        discountNumber: _.toUpper(this.state.rateCode.trim()),
        billingCode: _.toUpper(this.state.billingCode.trim()),
      });
    } else {
      this.props.handleSubmit(_.toUpper(this.state.rateCode.trim()));
    }

    this.props.closePopup();
  };

  onChangeVendors = (vendor: any) => {
    this.setState({
      vendor: _.get(vendor, 'value', ''),
      vendorError: '',
    });
  };

  renderBillingCode(type: string) {
    if (type === 'car') {
      return (
        <div className={styles.inputWrapper}>
          <TextField
            error={Boolean(this.state.billingCodeError)}
            helperText={this.state.billingCodeError}
            onChange={this.onChangeBillingCode}
            fullWidth
            label="Billing Code"
            variant="outlined"
            margin="normal"
            value={this.state.billingCode}
          />
        </div>
      );
    }
    return;
  }

  render() {
    let title = '';
    let styleCont = styles.cont;
    switch (this.props.type) {
      case 'flight':
        title = 'Add Flight SNAP Code';
        break;
      case 'hotel':
        title = 'Add Hotel Rate Code';
        break;
      default:
        title = 'Add Car Discount Code';
        styleCont = styles.inputWrapperContainer;
    }

    const dataSet = _.orderBy(
      this.props.vendorList,
      [(vendor: any) => vendor.name.toLowerCase()],
      ['asc'],
    );

    return (
      <Dialog
        fullWidth={true}
        maxWidth="md"
        className={classNames(styles.dialogBox, styles.ccPopup)}
        open={this.props.showPopup}
        onClose={this.props.closePopup}
      >
        <div className={classNames(styles.title)}>
          <div className={styles.titleStyle}>{title}</div>
          <IconButton
            color="inherit"
            onClick={this.props.closePopup}
            className={classNames(styles.closeIconCreditCard)}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className={classNames(styles.rateCode)}>
          <div className={styleCont}>
            {this.props.type == 'car' && (
              <div className={styles.inputWrapper}>
                <AutoSuggest
                  error={this.state.vendorError}
                  className={styles.dropdownContainer}
                  onChange={(value: any) => {
                    this.onChangeVendors(value);
                  }}
                  label="Vendors"
                  value={this.state.vendor}
                  dataSet={dataSet}
                  maxMenuHeight={100}
                  returnAll={true}
                />
              </div>
            )}
            <div className={styles.inputWrapper}>
              <TextField
                error={Boolean(this.state.rateCodeError)}
                helperText={this.state.rateCodeError}
                onChange={this.onChangeRateCode}
                fullWidth
                label="Discount Code"
                variant="outlined"
                margin="normal"
                value={this.state.rateCode}
              />
            </div>
            {this.renderBillingCode(this.props.type)}
          </div>
          <div className={classNames(styles.actionsRight)}>
            <Button
              className={styles.button}
              variant="contained"
              color="primary"
              onClick={this.handleSubmit}
            >
              Add
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default AddCorporateRateCodePopup;

import {
  ACTION_GET_CORPORATE_RATE_CODES,
  ACTION_GET_CORPORATE_RATE_CODES_SUCCESS,
  ACTION_GET_CORPORATE_RATE_CODES_FAILURE,
  ACTION_PUT_CORPORATE_RATE_CODES,
  ACTION_PUT_CORPORATE_RATE_CODES_SUCCESS,
  ACTION_PUT_CORPORATE_RATE_CODES_FAILURE,
} from '../configs/ActionTypes';

import _ from 'lodash-es';

const initialState = {
  corporateRateCodes: [],
  isLoading: false,
  success: null,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_GET_CORPORATE_RATE_CODES:
    case ACTION_PUT_CORPORATE_RATE_CODES: {
      return {
        ...state,
        isLoading: true,
        success: null,
      };
    }

    case ACTION_GET_CORPORATE_RATE_CODES_SUCCESS:
    case ACTION_PUT_CORPORATE_RATE_CODES_SUCCESS: {
      return {
        ...state,
        corporateRateCodes: action.payload.payload,
        isLoading: false,
        success: null,
      };
    }

    case ACTION_GET_CORPORATE_RATE_CODES_FAILURE:
    case ACTION_PUT_CORPORATE_RATE_CODES_FAILURE: {
      return {
        ...state,
        isLoading: false,
        success: false,
      };
    }

    default: {
      return state;
    }
  }
}

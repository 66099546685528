import * as React from 'react';
import _ from 'lodash-es';
import Paper from '@material-ui/core/Paper';
const styles = require('./styles.pcss');
import cx from 'classnames';

type Props = {
  tags: any;
  onCLickEdit: Function;
};

const AdditionalBookingDetails = (props: Props) => {
  return (
    <Paper
      className={cx(styles.bookingAdditionalDetails, styles.bookingDetails)}
    >
      <div className={styles.header}>
        <h1 className={styles.additionalDetailsTitle}>
          Additional Booking Details
        </h1>
      </div>
      <div className={styles.additionalBookingContainer}>
        <div className={styles.tagsContainer}>
          <div className={styles.subTitle}>Custom Categories:</div>
          {_.get(props, 'tags', []).map((tag: any, i: any) => {
            return <div className={styles.tag}> {_.get(tag, 'name', '')}</div>;
          })}
        </div>
        <div
          className={styles.editButton}
          onClick={() => props.onCLickEdit()}
        >
          Edit
        </div>
      </div>
    </Paper>
  );
};

export default AdditionalBookingDetails;

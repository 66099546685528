import * as React from 'react';
import _ from 'lodash-es';
import moment from 'moment';
import cx from 'classnames';
import { navigate } from '@reach/router';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ConfirmationPopup from 'components/Popup/Confirmation';
import UserAnalyticsPopup from 'components/Popup/UserAnalyticsPopup';
import TextFieldWithClear from 'components/TextFieldWithIcon/TextFieldWithIcon';
import {
  updateUserStatus,
  resetUserStatusUpdate,
} from '../../actions/Companies/UpdateUserStatus';
import { hasPermission } from '../../helpers/Permission';
import Loader from 'components/Loader/Loader';
import CustomSelect from 'components/Inputs/Select/Select';
import SvgIconRewards from '../../static/icons/svgIconRewards';
import SvgBankIcon from '../../static/icons/svgBankIcon';
import SvgIconTower from '../../static/icons/svgIconTower';
import SvgCopyIcon from '../../static/icons/svgCopyIcon';
import FeaturesTableRow from 'components/Features/FeaturesTableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ReduxState } from 'reducers/types';
import { putSingle, resetUpdate } from 'actions/Users/PutSingle';
import { updateUserFraudRule } from 'actions/Users/PutUserFraudRule';
import { getFraudRule } from 'actions/Users/GetUserFraudRule';
import { getUserBankAccount } from 'actions/Users/GetUserBankAccount';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import UpdateUserDetails from 'components/Popup/UpdateUserDetails';
import Tooltip from '@material-ui/core/Tooltip';

const styles = require('./styles.pcss');

import { User } from 'reducers/types';

type Props = {
  readOnly?: any;
  user?: any;
  userLoading?: any;
  putSingle: Function;
  updateUserStatus: Function;
  resetUserStatusUpdate: Function;
  userStatus: any;
  updateSuccess: any;
  isLoadingFraudRule?: any;
  fraudCheck: string;
  updateUserFraudRule: Function;
  getFraudRule: Function;
  getUserBankAccount: Function;
  bankAccount: any;
  resetUpdate: Function;
};

type State = {
  openConfirmationPopup: boolean;
  activate: any;
  buttonText: string;
  popupText: string;
  openAnalyticsModal: any;
  bypassSso: any;
  openEditUserModal: any;
  isUserIdCopied: boolean;
  copyIconColor: string;
};

const _getNullSafe = (val: any, props: any, defaultVal: any) => {
  const v = _.get(val, props, defaultVal);
  return _.isNull(v) ? defaultVal : v;
};

class PersonalDetails extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      bypassSso: _getNullSafe(this.props, 'user.bypassSso', ''),
      openConfirmationPopup: false,
      activate: _.get(this.props.user, 'status', null),
      buttonText: '',
      popupText: '',
      openAnalyticsModal: false,
      openEditUserModal: false,
      isUserIdCopied: false,
      copyIconColor: '#0058FF',
    };
  }

  componentDidMount = () => {
    this.props.getFraudRule(_getNullSafe(this.props, 'user.id', ''));
    this.props.getUserBankAccount(_getNullSafe(this.props, 'user.id', ''));
  };

  componentWillReceiveProps = (nextProps: Props) => {
    if (this.state.activate === null && nextProps.user !== null) {
      this.setState({
        activate: _.get(nextProps.user, 'status', false),
      });
    }

    if (_.get(nextProps.userStatus, 'success', false) === true) {
      this.setState({
        activate: _.get(nextProps.userStatus, 'status', false),
      });

      this.props.resetUserStatusUpdate();
    }

    if (_.get(nextProps, 'updateSuccess', false) === true) {
      this.setState({
        bypassSso: _getNullSafe(nextProps, 'user.bypassSso', ''),
      });
    }
  };

  closePopup = () => {
    this.setState({
      openConfirmationPopup: false,
    });
  };

  onSubmitPopup = () => {
    if (this.state.activate == 'active') {
      this.props.updateUserStatus(this.props.user.id, { enabled: false });

      this.setState({
        openConfirmationPopup: false,
      });
    } else {
      this.props.updateUserStatus(this.props.user.id, { enabled: true });

      this.setState({
        openConfirmationPopup: false,
      });
    }
  };

  changeStatus = () => {
    if (this.state.activate != 'active') {
      this.setState({
        openConfirmationPopup: true,
        buttonText: 'Reactivate',
        popupText: 'Are you sure you want to reactivate?',
      });
    } else {
      this.setState({
        openConfirmationPopup: true,
        buttonText: 'Deactivate',
        popupText: 'Are you sure you want to deactivate?',
      });
    }
  };

  onClickEditAnalytics = () => {
    this.setState({
      openAnalyticsModal: true,
    });
  };

  onCloseEditAnalytics = () => {
    this.setState({
      openAnalyticsModal: false,
    });
  };

  onClickEditUserDetails = () => {
    this.setState({
      openEditUserModal: true,
    });
  };

  onCloseEditUserDetails = () => {
    this.props.resetUpdate();
    this.setState({
      openEditUserModal: false,
    });
  };

  renderEditAnalyticsModal = () => {
    if (this.state.openAnalyticsModal) {
      return (
        <UserAnalyticsPopup
          openPopup={this.state.openAnalyticsModal}
          closePopup={this.onCloseEditAnalytics}
          user={this.props.user}
        />
      );
    }

    return null;
  };

  async copyTextToClipboard(text: string) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  changeCopyIconColor = (color: string) => {
    this.setState({
      copyIconColor: color,
    });
  };

  onClickCopyUserId = () => {
    this.copyTextToClipboard(this.props.user.id).then(() => {
      this.setState({
        isUserIdCopied: true,
      });
      setTimeout(() => {
        this.setState({
          isUserIdCopied: false,
        });
      }, 1500);
    });
  };

  handleSubmit = (data: any) => {
    this.props.putSingle(this.props.user.id, data);
  };

  handleFraudRuleChange = (event: any) => {
    const param = {
      rule: event.target.value,
      userId: _getNullSafe(this.props, 'user.id', ''),
      email: _getNullSafe(this.props, 'user.email', ''),
    };

    this.props.updateUserFraudRule(param);
  };

  renderStatusButton = () => {
    if (
      ((this.state.activate == 'active' ||
        this.state.activate === 'unverified') &&
        hasPermission('users', 'delete')) ||
      (this.state.activate == 'disabled' && hasPermission('users', 'update'))
    ) {
      let buttonText =
        this.state.activate !== 'active'
          ? 'Reactivate User'
          : 'Deactivate User';

      return (
        <Button
          size="small"
          className={
            this.state.activate !== 'active'
              ? styles.buttonReactivate
              : styles.buttonDeactivate
          }
          variant="contained"
          onClick={this.changeStatus}
        >
          {buttonText}
        </Button>
      );
    }
    return null;
  };

  renderUserId = () => {
    return (
      <>
        <h2>User ID</h2>
        <div className={styles.userIdContainer}>
          <div className={styles.userId}>{this.props.user.id}</div>
          <div className={styles.copyUserIdButton}>
            <Tooltip
              title={
                this.state.isUserIdCopied
                  ? 'User ID has been copied to your clipboard.'
                  : 'Copy User ID'
              }
              placement="bottom"
            >
              <button
                className={styles.copyButton}
                onClick={this.onClickCopyUserId}
                onMouseOver={() => this.changeCopyIconColor('#FFFFFF')}
                onMouseLeave={() => this.changeCopyIconColor('#0058FF')}
              >
                <SvgCopyIcon
                  width={24}
                  height={24}
                  color={this.state.copyIconColor}
                />
              </button>
            </Tooltip>
          </div>
        </div>
      </>
    );
  };

  renderPointsText = (balance: any) => {
    return balance > 1 ? 'Points' : 'Point';
  };

  renderUserBancAccount = () => {
    if (_.get(this.props.user, 'organizations', []).length == 0) {
      return null;
    }

    let bankAccountText = 'No bank account on file';

    if (
      _.get(this.props, 'bankAccount.account.bankName', false) &&
      _.get(this.props, 'bankAccount.account.lastFour', false)
    ) {
      bankAccountText = `${_.get(
        this.props,
        'bankAccount.account.bankName',
        false,
      )} ... ${_.get(this.props, 'bankAccount.account.lastFour', false)}`;
    }

    return (
      <div className={styles.reimbursementContainer}>
        <h2>Reimbursement Acoount</h2>
        <div className={styles.rewardIconAndText}>
          <SvgBankIcon
            width={20}
            height={20}
          />
          <div className={styles.reimbursementText}>
            {_.get(this.props, 'bankAccount.isLoading', false) ? (
              <div className={styles.reimbursementLoader}>
                <CircularProgress
                  size={25}
                  className={styles.sCircularProgress}
                />
              </div>
            ) : (
              bankAccountText
            )}
          </div>
        </div>
      </div>
    );
  };

  renderCompanyNr = () => {
    if (_.get(this.props.user, 'multiOrganizationAnalytics', []).length == 0) {
      return <>Default Access</>;
    }

    if (_.get(this.props.user, 'multiOrganizationAnalytics', []).length == 1) {
      return <>1 Company</>;
    }

    return (
      <>
        {' '}
        {_.get(this.props.user, 'multiOrganizationAnalytics', []).length}{' '}
        Companies
      </>
    );
  };

  handleSSOChange = (event: any) => {
    const data = {
      bypassSso: !this.state.bypassSso,
      firstName: _getNullSafe(this.props, 'user.firstName', ''),
      lastName: _getNullSafe(this.props, 'user.lastName', ''),
      phoneNumber: _getNullSafe(this.props, 'user.phoneNumber', ''),
      email: _getNullSafe(this.props, 'user.email', ''),
      notes: _getNullSafe(this.props, 'user.notes', ''),
    };

    this.props.putSingle(this.props.user.id, data);
  };

  renderUpdateUserDetails = () => {
    return null;
  };

  getDepartments = () => {
    if (_.get(this.props, 'user.departments', []).length > 0) {
      const departmentNames = _.map(
        _.get(this.props, 'user.departments', []),
        (department) => {
          return department.name;
        },
      );
      return departmentNames.join(', ');
    }

    return null;
  };

  render() {
    const firstItem = (
      <div className="recommendedItemContainer">
        <span className={styles.recommendedItemFraud}>Fraud Rules Apply</span>{' '}
        <span className="recommended"> Recommended </span>{' '}
      </div>
    );

    let item = [
      { key: 'recommended', value: 'recommended', label: firstItem },
      { key: 'emailAllowList', value: 'emailAllowList', label: 'Always Allow' },
      { key: 'emailBlockList', value: 'emailBlockList', label: 'Block' },
    ];

    const balance = _.get(this.props.user, 'account.balance.total', 0);
    const balanceStr = balance
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

    const bypassSSODescription: any = {
      labelName: 'Bypass SSO',
      description:
        'Whether user is required to log into the app via SSO if configured by their company.',
    };

    return (
      <>
        <div className={styles.accountDetailsContiner}>
          <div>
            <div className={styles.AccountBalance}>
              <Paper
                className={
                  _.get(this.props, 'userStatus.isLoading', false)
                    ? styles.paperAccountLoading
                    : styles.paperAccount
                }
              >
                <Loader
                  visible={_.get(this.props, 'userStatus.isLoading', false)}
                />
                <h2>Account Details</h2>
                <div className={styles.accountDetailsCont}>
                  <div className={styles.accountDetailsContinerText}>
                    <div className={styles.infoText}>
                      First Name:{' '}
                      <span>
                        {_getNullSafe(this.props, 'user.firstName', '- -')}
                      </span>
                    </div>
                    <div className={styles.infoText}>
                      Last Name:{' '}
                      <span>
                        {_getNullSafe(this.props, 'user.lastName', '- -')}
                      </span>
                    </div>
                    <div className={styles.infoText}>
                      Email:{' '}
                      <span>
                        {_getNullSafe(this.props, 'user.email', '- -')}
                      </span>
                    </div>
                    <div className={styles.infoText}>
                      Phone Number:{' '}
                      <span>
                        {_getNullSafe(this.props, 'user.phoneNumber', '- -')}
                      </span>
                    </div>
                    {_.get(this.props, 'user.organizations', []).length > 0 && (
                      <div className={styles.infoText}>
                        Department: <span>{this.getDepartments()}</span>
                      </div>
                    )}
                  </div>
                  <div>
                    {!this.props.readOnly && (
                      <div className={styles.buttonContainer}>
                        <Button
                          size="small"
                          className={styles.editButton}
                          variant="outlined"
                          color="primary"
                          onClick={this.onClickEditUserDetails}
                          id="edit-user-button"
                        >
                          Edit
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </Paper>
            </div>

            <Paper
              className={
                _.get(this.props, 'userStatus.isLoading', false)
                  ? cx(styles.paperAccountLoading, styles.rule)
                  : cx(styles.paperAccount, styles.rule)
              }
            >
              <h2>Permissions</h2>

              <div className={styles.fraudRules}>
                <div className={cx(styles.fraud)}>
                  {'Analytics'}
                  <div className={cx(styles.description)}>
                    {
                      'List of companies whose data will be made available to the user in Analytics.'
                    }
                  </div>
                </div>

                <div className={styles.editAnalyticsContainer}>
                  <div className={styles.editAnalyticsText}>
                    <SvgIconTower
                      width={10}
                      height={16}
                    />
                    {this.renderCompanyNr()}
                  </div>
                  <div
                    className={styles.editAnalytics}
                    onClick={this.onClickEditAnalytics}
                  >
                    {' '}
                    Edit
                  </div>
                </div>
              </div>
              {this.renderEditAnalyticsModal()}
            </Paper>

            <Paper
              className={
                _.get(this.props, 'isLoadingFraudRule', false) ||
                _.get(this.props, 'userStatus.isLoading', false)
                  ? cx(styles.paperAccountLoading, styles.rule)
                  : cx(styles.paperAccount, styles.rule, styles.paper)
              }
            >
              <Loader
                visible={_.get(this.props, 'isLoadingFraudRule', false)}
              />

              <h2 className={styles.settingTitle}>Settings</h2>
              <FeaturesTableRow
                key={'bypassSSO'}
                rowColumns={bypassSSODescription}
                enabled={this.state.bypassSso}
                handleclick={this.handleSSOChange}
                updateOrganizationPermission={this.props.readOnly}
                description={true}
                tableCellStyle={styles.featureTableCell}
              />

              <div className={styles.fraudRules}>
                <div className={cx(styles.fraud)}>
                  {'Fraud Check'}
                  <div className={cx(styles.description)}>
                    {
                      'All Flight bookings must pass fraud rules in order to make a successful booking.'
                    }
                  </div>
                </div>

                <div className={cx(styles.fraudSelect)}>
                  <div className={styles.inputWrapper}>
                    <CustomSelect
                      margin={'normal'}
                      variant={'outlined'}
                      className={styles.selectWrapper}
                      htmlFor={'input-type'}
                      label={''}
                      disabled={
                        _.get(this.props, 'isLoadingFraudRule', false) ||
                        _.get(this.props, 'userStatus.isLoading', false)
                      }
                      id={'input-type'}
                      value={this.props.fraudCheck}
                      onChange={this.handleFraudRuleChange}
                      fullWidth={true}
                      labelWidth={'0'}
                      inputName={''}
                      MenuProps={{
                        style: { marginTop: 4, boxShadow: 'none' },
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                      }}
                      menuItems={item}
                    />
                  </div>
                </div>
              </div>
            </Paper>

            <div className={styles.buttonDeactivateCont}>
              {this.renderStatusButton()}
            </div>
          </div>
          <div>
            <div>
              <Paper className={cx(styles.paperAccount, styles.balance)}>
                <h2>Reward Balance</h2>
                <div className={styles.rewardIconAndText}>
                  <SvgIconRewards
                    width={50}
                    height={50}
                  />
                  <div className={styles.points}>
                    {balanceStr} {this.renderPointsText(balance)}
                  </div>
                </div>
                {this.renderUserBancAccount()}
              </Paper>

              <Paper className={cx(styles.paperAccount, styles.userIdPaper)}>
                {this.renderUserId()}
              </Paper>
            </div>
          </div>
        </div>
        <ConfirmationPopup
          open={this.state.openConfirmationPopup}
          onClose={this.closePopup}
          onSubmit={this.onSubmitPopup}
          title="Are you sure?"
          bodyText={this.state.popupText}
          submitButtonText={this.state.buttonText}
          closeButtonText="Cancel"
        />

        <UpdateUserDetails
          openPopup={this.state.openEditUserModal}
          closePopup={this.onCloseEditUserDetails}
          user={this.props.user}
          readOnly={false}
          handleSubmit={this.handleSubmit}
        />
      </>
    );
  }
}

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {
    userLoading: state.user.isLoading,
    userStatus: state.updateUserStatus,
    updateSuccess: state.user.updateSuccess,
    isLoadingFraudRule: state.userFraudRule.isLoading,
    fraudCheck: state.userFraudRule.rule,
    bankAccount: state.userBankAccount,
  };
}

// Wire up redux dispatch functions
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      putSingle,
      resetUserStatusUpdate,
      updateUserStatus,
      updateUserFraudRule,
      getFraudRule,
      getUserBankAccount,
      resetUpdate,
    },
    dispatch,
  );
}

// Connect it to Redux
export default connect(mapStateToProps, mapDispatchToProps)(PersonalDetails);

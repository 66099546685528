import * as React from 'react';
import LayoutDashboard from 'components/Layout/Dashboard';
import SvgIconFlag from '../../static/icons/svgIconFlag';
import SvgIconHotel from '../../static/icons/svgIconHotel';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/Breadcrumbs/Breadcrumb';
import Hotels from 'components/ManageTB/Hotels/Hotels';

const styles = require('./styles.pcss');
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { get as getExclusiveHotels } from '../../actions/ManageTB/Hotels/Get';
import { ReduxState } from '../../reducers/types';

type Props = {
  getExclusiveHotels: Function;
  exclusiveHotels: any;
};

class ManageTBHotels extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    this.props.getExclusiveHotels();
  }

  render() {
    return (
      <LayoutDashboard>
        <Breadcrumbs className={styles.breadcrumbs}>
          <Breadcrumb
            name="Manage TB"
            arrow
          />
          <Breadcrumb name="Hotels" />
        </Breadcrumbs>
        <div className={styles.header}>
          <h1 className={styles.h1}>Hotels</h1>
        </div>
        <div className={styles.body}>
          <Hotels />
        </div>
      </LayoutDashboard>
    );
  }
}

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {
    exclusiveHotels: state.exclusiveHotels,
  };
}

// Wire up redux dispatch functions
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getExclusiveHotels,
    },
    dispatch,
  );
}

// Connect it to Redux
export default connect(mapStateToProps, mapDispatchToProps)(ManageTBHotels);

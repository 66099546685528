import * as React from 'react';
import _ from 'lodash-es';
import cx from 'classnames';

import moment from 'moment';

import SimpleTable from 'components/SimpleTable';
import Loader from 'components/Loader/Loader';
import SendEmailConfirmationPopup from 'components/Popup/SendEmailConfirmationPopup';
import BookingsTableHead from './BookingsTableHead';
import BookingsTableRow from './BookingsTableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';

import { QUERY_LIMIT_25 } from '../../configs/General';

import { connect } from 'react-redux';

import { sendEmail } from 'actions/Bookings/SendEmail';

import { bindActionCreators, Dispatch } from 'redux';

const styles = require('./styles.pcss');

const appWindow: Window = window;

type State = {
  openConfirmation: boolean;
  id: string;
  selectedIdentifier: string;
  additionalEmails: string;
  userEmail: string;
};

class Hotels extends React.Component<any, State> {
  constructor(props: any) {
    super(props);

    this.handleScroll = this.handleScroll.bind(this);

    this.state = {
      openConfirmation: false,
      id: '',
      selectedIdentifier: '',
      additionalEmails: '',
      userEmail: '',
    };
  }

  componentWillUnmount() {
    appWindow.removeEventListener('scroll', this.handleScroll);
  }

  componentWillReceiveProps(nextProps: any) {
    if (nextProps.sort && nextProps.sort !== this.props.sort) {
      let params: any = {
        type: 'hotel',
        limit: QUERY_LIMIT_25,
        skip: 0,
      };

      if (this.props.travelerId) {
        params.travelerId = this.props.travelerId;
      }

      if (this.props.userId) {
        params.userId = this.props.userId;
      }

      if (nextProps.order) {
        params.order = nextProps.order;
      }

      params.sort = nextProps.sort;
      this.props.get(params);
    }
  }

  componentDidMount() {
    appWindow.addEventListener('scroll', this.handleScroll);

    if (this.props.hotels.length == 0) {
      let params: any = {
        type: 'hotel',
        limit: QUERY_LIMIT_25,
        skip: 0,
      };

      if (this.props.travelerId) {
        params.travelerId = this.props.travelerId;
      }

      if (this.props.userId) {
        params.userId = this.props.userId;
      }

      if (this.props.sort) {
        params.sort = this.props.sort;
      }

      if (this.props.order) {
        params.order = this.props.order;
      }

      if (this.props.searchParams) {
        params = {
          ...params,
          ...this.props.searchParams,
        };
      }

      this.props.get(params);
    }
  }

  handleScroll() {
    const body = document.body;
    const html = document.documentElement;

    if (!body || !html) {
      return;
    }

    const windowHeight =
      'innerHeight' in appWindow ? appWindow.innerHeight : html.offsetHeight;

    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight,
    );
    const windowBottom = windowHeight + window.pageYOffset;

    if (
      windowBottom >= docHeight &&
      this.props.isLoading === false &&
      this.props.loadedAllHotel === false
    ) {
      let params: any = {
        type: 'hotel',
        limit: QUERY_LIMIT_25,
        sort: this.props.sort,
        skip: this.props.hotels.length,
      };

      if (this.props.userId) {
        params.userId = this.props.userId;
      }

      if (this.props.sort) {
        params.sort = this.props.sort;
      }

      if (this.props.order) {
        params.order = this.props.order;
      }

      if (this.props.searchParams) {
        params = {
          ...params,
          ...this.props.searchParams,
        };
      }

      this.props.get(params);
    }
  }

  sendConfirmationEmail = (bookingId: any) => () => {
    this.props.sendEmail(bookingId);
  };

  onSendEmail =
    (id: any, itinerary: any, additionalEmails: any, userEmail: string) =>
    (event: any) => {
      this.setState({
        id: id,
        selectedIdentifier: itinerary,
        openConfirmation: true,
        additionalEmails: additionalEmails,
        userEmail: userEmail,
      });
    };

  closeConfirmation = () => {
    this.setState({
      openConfirmation: false,
      id: '',
      selectedIdentifier: '',
      additionalEmails: '',
      userEmail: '',
    });
  };

  onSubmitSend = () => {
    this.setState({
      openConfirmation: false,
      id: '',
      selectedIdentifier: '',
      additionalEmails: '',
      userEmail: '',
    });
  };

  renderTableRow = (cells: any, i: number) => {
    return (
      <BookingsTableRow
        key={i}
        id={this.props.hotels[i].id}
        cells={cells}
      />
    );
  };

  render() {
    function createMarkup(string: any) {
      return { __html: string };
    }

    let columns = [
      'Itinerary',
      'Conf #',
      'Traveler',
      'Hotel',
      'Check-In',
      'Check-Out',
      'Provider',
      'Status',
    ];

    let hotels = this.props.hotels;
    let hotels_data: Array<any> = [];

    Object.keys(hotels).forEach((key: any) => {
      let travelers = '';
      _.each(hotels[key].travelers, (p: any) => {
        travelers = travelers + `<p>${p.firstName} ${p.lastName}</p>`;
      });

      let hotelInfo = `<p>${_.get(
        hotels[key],
        'hotel.name',
        '',
      )}</p> <p>${_.get(hotels[key], 'hotel.city', '')}, ${_.get(
        hotels[key],
        'hotel.stateProvinceCode',
        '',
      )}</p>`;

      let hotel = {
        itinerary: _.get(hotels[key], 'itineraryId', ''),
        confirmation: _.get(hotels[key], 'confirmationNumber', ''),
        travelers: <div dangerouslySetInnerHTML={createMarkup(travelers)} />,
        hotel: <div dangerouslySetInnerHTML={createMarkup(hotelInfo)} />,
        checkIn: moment.utc(hotels[key].arrivalDate).format('MMM D, YYYY'),
        checkOut: moment.utc(hotels[key].departureDate).format('MMM D, YYYY'),
        provider: _.get(
          hotels[key],
          'provider',
          _.get(hotels[key], 'hotel.roomDetails.0._meta.provider', ''),
        ),
        status: _.get(hotels[key], 'status', ''),
      };

      hotels_data.push(hotel);
    });

    let data = hotels_data.map((v: any) => {
      return [
        v.itinerary,
        v.confirmation,
        v.travelers,
        v.hotel,
        v.checkIn,
        v.checkOut,
        v.provider,
        v.status,
      ];
    });

    if (hotels_data.length == 0 && this.props.isLoading == false) {
      return <div className={styles.noData}>Empty hotel bookings.</div>;
    } else {
      let className = this.props.noBorder
        ? cx(styles.noBorder, styles.paper)
        : styles.paper;

      return (
        <div>
          <Paper className={className}>
            <div className={styles.tableStyle}>
              <Table>
                <BookingsTableHead columns={columns} />
                <TableBody>{data.map(this.renderTableRow)}</TableBody>
              </Table>
            </div>
          </Paper>

          <Loader visible={this.props.isLoading} />
          <SendEmailConfirmationPopup
            open={this.state.openConfirmation}
            onClose={this.closeConfirmation}
            onSubmit={this.onSubmitSend}
            id={this.state.id}
            type="hotel"
            identifier={this.state.selectedIdentifier}
            additionalEmails={this.state.additionalEmails}
            userEmail={this.props.userEmail}
          />
        </div>
      );
    }
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      sendEmail,
    },
    dispatch,
  );
}

export default connect(null, mapDispatchToProps)(Hotels);

import _ from 'lodash-es';

import {
  ACTION_DELETE_CREDITCARD,
  ACTION_DELETE_CREDITCARD_FAILURE,
  ACTION_DELETE_CREDITCARD_SUCCESS,
  ACTION_RESET_DELETE_CREDITCARD,
} from '../configs/ActionTypes';

const initialState = {
  deleteCreditCardAttempt: false,
  success: null,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_DELETE_CREDITCARD_SUCCESS: {
      return {
        ...state,
        deleteCreditCardAttempt: false,
        success: true,
      };

      break;
    }

    case ACTION_DELETE_CREDITCARD_FAILURE: {
      return {
        ...state,
        deleteCreditCardAttempt: false,
        success: false,
      };

      break;
    }

    case ACTION_DELETE_CREDITCARD: {
      return {
        ...state,
        deleteCreditCardAttempt: true,
      };

      break;
    }

    case ACTION_RESET_DELETE_CREDITCARD: {
      return {
        ...state,
        deleteCreditCardAttempt: false,
        success: null,
      };

      break;
    }

    default: {
      return state;
    }
  }
}

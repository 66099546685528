import React, { useState, useMemo } from 'react';
import _ from 'lodash-es';
import classNames from 'classnames';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import FeaturesTableRow from './FeaturesTableRow';
import Loader from 'components/Loader/Loader';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CustomSelect from 'components/Inputs/Select/Select';
const styles = require('./styles.pcss');
import { hasPermission } from '../../helpers/Permission';
import { ReduxState } from '../../reducers/types';
import CustomizedSwitch from './CustomizedSwitch';
import UpdatePopup from './UpdatePopup';
import Button from '@material-ui/core/Button';

type Props = {
  handleFraudRuleChange?: Function;
  fraudCheck?: any;
  isLoadingFraudRule: boolean;
  basicEconomyFeature?: any;
  handleBasicEconomyChange?: Function;
  isLoadingBasicEconomy: boolean;
  isLoadingFlightBookingWindowFeature: boolean;
  handleFlightBookingWindowFeatureChange: Function;
  advancedFlightFeature: any;
  verifiedForTravelFeature: any;
  handleVerifiedForTravelChange?: Function;
  handleProductSettingsChange?: Function;
  organziationProductsSettings: any;
};

const TravelFeatures = (props: Props) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [value, setValue] = useState(
    _.get(props, 'advancedFlightFeature.value', ''),
  );
  const [enabled, setEnabled] = useState(
    _.get(props, 'advancedFlightFeature.id', '') ? true : false,
  );

  useMemo(() => {
    if (value !== _.get(props, 'advancedFlightFeature.value', '')) {
      setValue(_.get(props, 'advancedFlightFeature.value', ''));
      setEnabled(_.get(props, 'advancedFlightFeature.id', '') ? true : false);
    }
  }, [props]);

  const basicEconomyFeature: any = {
    labelName: 'Basic Economy',
    description: 'Employees can view and book basic economy flights.',
  };

  const verifiedForTravelRowColumns: any = {
    labelName: 'Verified For Travel',
    description:
      'Account has been verified by the TravelBank team to allow travel bookings.',
  };

  const productsSettingsRowColumns: any = {
    labelName: 'Travel',
    description: 'Employees can book Travel.',
  };

  const updatePermission = !hasPermission('organizations', 'update');
  const firstItem = (
    <div className="recommendedItemContainer">
      <span className={styles.recommendedItemFraud}>Fraud Rules Apply</span>{' '}
      <span className="recommended"> Recommended </span>{' '}
    </div>
  );
  const item = [
    { key: 'recommended', value: 'recommended', label: firstItem },
    {
      key: 'emailDomainAllowList',
      value: 'emailDomainAllowList',
      label: 'Always Allow',
    },
    {
      key: 'emailDomainBlockList',
      value: 'emailDomainBlockList',
      label: 'Block',
    },
  ];

  const travelOrgSettingsEnabledEnabled = _.includes(
    _.get(props, 'organziationProductsSettings.value', []),
    'travel',
  );

  return (
    <Paper
      className={
        props.isLoadingFraudRule || props.isLoadingBasicEconomy
          ? classNames(styles.paper, styles.loading)
          : styles.paper
      }
    >
      <div className={styles.expensesFeatures}>
        <h2>Features</h2>

        <Table>
          <TableBody>
            <FeaturesTableRow
              key="verifiedForTravelKey"
              rowColumns={verifiedForTravelRowColumns}
              enabled={_.get(
                props,
                'verifiedForTravelFeature.value.enabled',
                false,
              )}
              handleclick={props.handleVerifiedForTravelChange}
              updateOrganizationPermission={updatePermission}
              description={true}
            />
            <FeaturesTableRow
              key="productsSettingsKey"
              rowColumns={productsSettingsRowColumns}
              enabled={travelOrgSettingsEnabledEnabled}
              handleclick={props.handleProductSettingsChange}
              updateOrganizationPermission={updatePermission}
              description={true}
            />
            <TableRow className={classNames(styles.tableRow)}>
              <TableCell
                key={'FraudCheck'}
                className={classNames(styles.tableCell, styles.fraud)}
              >
                <div className={styles.labelName}>{'Fraud Check'}</div>
                <div className={styles.descriptionReimburs}>
                  {
                    'All Flight bookings must pass fraud rules in order to make a successful booking.'
                  }
                </div>
              </TableCell>
              <TableCell
                key={'FraudCheck'}
                className={classNames(styles.tableCell, styles.fraudSelect)}
              >
                <div className={styles.inputWrapper}>
                  <CustomSelect
                    margin={'normal'}
                    variant={'outlined'}
                    className={styles.selectWrapper}
                    htmlFor={'input-type'}
                    label={''}
                    id={'input-type'}
                    value={props.fraudCheck}
                    onChange={props.handleFraudRuleChange}
                    fullWidth={true}
                    labelWidth={'0'}
                    inputName={''}
                    MenuProps={{
                      style: { marginTop: 4, boxShadow: 'none' },
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                    }}
                    menuItems={item}
                  />
                </div>
              </TableCell>
            </TableRow>

            <TableRow
              className={classNames(
                styles.tableRow,
                styles.tableAdvancedFlightRow,
              )}
            >
              <TableCell
                key={'AdvancedFlightBooking'}
                className={classNames(styles.tableCell, styles.fraud)}
              >
                <div className={styles.labelName}>
                  {'Advanced Flight Booking'}
                </div>
                <div className={styles.descriptionReimburs}>
                  {
                    'Allow flight bookings up to x hours before take-off'
                  }
                </div>
              </TableCell>
              <TableCell
                key={'switch'}
                className={classNames(styles.tableCell, styles.buttonCell)}
              >
                <Button
                  size="small"
                  className={styles.button}
                  variant="outlined"
                  color="primary"
                  onClick={() => setPopupOpen(true)}
                  disabled={updatePermission}
                >
                  Edit
                </Button>
              </TableCell>
            </TableRow>

            <FeaturesTableRow
              key={_.get(props, 'basicEconomyFeature.feature.id', 3)}
              rowColumns={basicEconomyFeature}
              enabled={_.get(props, 'basicEconomyFeature.value.enabled', false)}
              handleclick={props.handleBasicEconomyChange}
              updateOrganizationPermission={updatePermission}
              description={true}
            />
          </TableBody>
        </Table>
        {(props.isLoadingFraudRule ||
          props.isLoadingBasicEconomy ||
          props.isLoadingFlightBookingWindowFeature) && (
          <Paper className={styles.pageWrapper}>
            <div>
              <Loader
                visible={
                  props.isLoadingFraudRule ||
                  props.isLoadingBasicEconomy ||
                  props.isLoadingFlightBookingWindowFeature
                }
              />
            </div>
          </Paper>
        )}
      </div>

      <UpdatePopup
        open={popupOpen}
        onClose={() => (
          setPopupOpen(false),
          setEnabled(
            _.get(props, 'advancedFlightFeature.id', '') ? true : false,
          ),
          setValue(_.get(props, 'advancedFlightFeature.value', ''))
        )}
        onSubmit={(value: any, enabled: any) => (
          props.handleFlightBookingWindowFeatureChange(value, enabled),
          setPopupOpen(false)
        )}
        isLoading={props.isLoadingFlightBookingWindowFeature}
        disabled={props.isLoadingFlightBookingWindowFeature}
        value={value}
        hasPermission={updatePermission}
        enabled={enabled}
        setValue={setValue}
        setEnabled={setEnabled}
      />
    </Paper>
  );
};

function mapStateToProps(state: ReduxState) {
  return {
    isLoadingFraudRule: state.organizationFraudRule.isLoading,
    isLoadingBasicEconomy: state.organizationBasicEconomyFeature.isLoading,
    isLoadingFlightBookingWindowFeature:
      state.organizationFlightBookingWindowFeature.isLoading,
  };
}

export default connect(mapStateToProps, '')(TravelFeatures);

import React, { Component } from 'react';
import cx from 'classnames';
import _ from 'lodash';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextFieldWithClear from 'components/TextFieldWithIcon/TextFieldWithIcon';
import Loader from 'components/Loader/Loader';

const styles = require('./styles.pcss');

type Props = {
  open: boolean;
  onClose: any;
  onSubmit: any;
  company: any;
  onSuccess: any;
  isLoading: any;
  licensePricing: any;
};

type State = {
  premiumAnnually: string;
  premiumMonthly: string;
  premiumOverage: string;
  travelAnnually: string;
  travelMonthly: string;
  travelOverage: string;
  expenseAnnually: string;
  expenseMonthly: string;
  expenseOverage: string;
};

const { format: formatCurrency } = Intl.NumberFormat('en-US', {
  currency: 'USD',
  style: 'currency',
});

class LicensePricingEditPopup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      premiumAnnually: formatCurrency(
        _.get(this.props.licensePricing, 'value.subscription.premiumYearly', 0),
      ).replace('$', ''),
      premiumMonthly: formatCurrency(
        _.get(
          this.props.licensePricing,
          'value.subscription.premiumMonthly',
          0,
        ),
      ).replace('$', ''),
      premiumOverage: formatCurrency(
        _.get(this.props.licensePricing, 'value.overage.premium', 0),
      ).replace('$', ''),
      travelAnnually: formatCurrency(
        _.get(this.props.licensePricing, 'value.subscription.travelYearly', 0),
      ).replace('$', ''),
      travelMonthly: formatCurrency(
        _.get(this.props.licensePricing, 'value.subscription.travelMonthly', 0),
      ).replace('$', ''),
      travelOverage: formatCurrency(
        _.get(this.props.licensePricing, 'value.overage.travel', 0),
      ).replace('$', ''),
      expenseAnnually: formatCurrency(
        _.get(this.props.licensePricing, 'value.subscription.expenseYearly', 0),
      ).replace('$', ''),
      expenseMonthly: formatCurrency(
        _.get(
          this.props.licensePricing,
          'value.subscription.expenseMonthly',
          0,
        ),
      ).replace('$', ''),
      expenseOverage: formatCurrency(
        _.get(this.props.licensePricing, 'value.overage.expense', 0),
      ).replace('$', ''),
    };
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.licensePricing !== this.props.licensePricing) {
      this.populateState();
    }
  }

  populateState = () => {
    this.setState({
      premiumAnnually: formatCurrency(
        _.get(this.props.licensePricing, 'value.subscription.premiumYearly', 0),
      ).replace('$', ''),
      premiumMonthly: formatCurrency(
        _.get(
          this.props.licensePricing,
          'value.subscription.premiumMonthly',
          0,
        ),
      ).replace('$', ''),
      premiumOverage: formatCurrency(
        _.get(this.props.licensePricing, 'value.overage.premium', 0),
      ).replace('$', ''),
      travelAnnually: formatCurrency(
        _.get(this.props.licensePricing, 'value.subscription.travelYearly', 0),
      ).replace('$', ''),
      travelMonthly: formatCurrency(
        _.get(this.props.licensePricing, 'value.subscription.travelMonthly', 0),
      ).replace('$', ''),
      travelOverage: formatCurrency(
        _.get(this.props.licensePricing, 'value.overage.travel', 0),
      ).replace('$', ''),
      expenseAnnually: formatCurrency(
        _.get(this.props.licensePricing, 'value.subscription.expenseYearly', 0),
      ).replace('$', ''),
      expenseMonthly: formatCurrency(
        _.get(
          this.props.licensePricing,
          'value.subscription.expenseMonthly',
          0,
        ),
      ).replace('$', ''),
      expenseOverage: formatCurrency(
        _.get(this.props.licensePricing, 'value.overage.expense', 0),
      ).replace('$', ''),
    });
  };

  renderLoading = () => {
    if (_.get(this.props, 'isLoading', false) === true) {
      return (
        <div className={styles.infoLoader}>
          <Loader visible={true} />
        </div>
      );
    }

    return null;
  };

  formatPrice = (value: any) => {
    const decimal = Number(value.toString().replace(/\D/g, '')) / 100;
    return formatCurrency(decimal || 0).replace('$', '');
  };

  onChangePremiumAnnualy = (event: any) => {
    this.setState({
      premiumAnnually: this.formatPrice(event.target.value),
    });
  };

  onDeletePremiumAnnualy = () => {
    this.setState({
      premiumAnnually: '',
    });
  };

  onChangePremiumMonthly = (event: any) => {
    this.setState({
      premiumMonthly: this.formatPrice(event.target.value),
    });
  };

  onDeletePremiumMonthly = () => {
    this.setState({
      premiumMonthly: '',
    });
  };

  onChangePremiumOverage = (event: any) => {
    this.setState({
      premiumOverage: this.formatPrice(event.target.value),
    });
  };

  onDeletePremiumOverage = () => {
    this.setState({
      premiumOverage: '',
    });
  };

  onChangeTravelAnnualy = (event: any) => {
    this.setState({
      travelAnnually: this.formatPrice(event.target.value),
    });
  };

  onDeleteTravelAnnualy = () => {
    this.setState({
      travelAnnually: '',
    });
  };

  onChangeTravelMonthly = (event: any) => {
    this.setState({
      travelMonthly: this.formatPrice(event.target.value),
    });
  };

  onDeleteTravelMonthly = () => {
    this.setState({
      travelMonthly: '',
    });
  };

  onChangeTravelOverage = (event: any) => {
    this.setState({
      travelOverage: this.formatPrice(event.target.value),
    });
  };

  onDeleteTravelOverage = () => {
    this.setState({
      travelOverage: '',
    });
  };

  onChangeExpenseAnnualy = (event: any) => {
    this.setState({
      expenseAnnually: this.formatPrice(event.target.value),
    });
  };

  onDeleteExpenseAnnualy = () => {
    this.setState({
      expenseAnnually: '',
    });
  };

  onChangeExpenseMonthly = (event: any) => {
    this.setState({
      expenseMonthly: this.formatPrice(event.target.value),
    });
  };

  onDeleteExpenseMonthly = () => {
    this.setState({
      expenseMonthly: '',
    });
  };

  onChangeExpenseOverage = (event: any) => {
    this.setState({
      expenseOverage: this.formatPrice(event.target.value),
    });
  };

  onDeleteExpenseOverage = () => {
    this.setState({
      expenseOverage: '',
    });
  };

  onSubmit = () => {
    const dataToSend = {
      subscription: {
        premiumYearly:
          this.reverseFormatNumber(this.state.premiumAnnually) || 0,
        premiumMonthly:
          this.reverseFormatNumber(this.state.premiumMonthly) || 0,
        expenseYearly:
          this.reverseFormatNumber(this.state.expenseAnnually) || 0,
        expenseMonthly:
          this.reverseFormatNumber(this.state.expenseMonthly) || 0,
        travelYearly: this.reverseFormatNumber(this.state.travelAnnually) || 0,
        travelMonthly: this.reverseFormatNumber(this.state.travelMonthly) || 0,
      },
      overage: {
        travel: this.reverseFormatNumber(this.state.travelOverage) || 0,
        expense: this.reverseFormatNumber(this.state.expenseOverage) || 0,
        premium: this.reverseFormatNumber(this.state.premiumOverage) || 0,
      },
      addons: {
        premiumInsights: _.get(
          this.props.licensePricing,
          'value.addons.premiumInsights',
          0,
        ),
      },
    };

    this.props.onSubmit(this.props.company.id, dataToSend);
    this.props.onSuccess();
  };

  reverseFormatNumber = (val: any) => {
    const group = new Intl.NumberFormat('en').format(1111).replace(/1/g, '');
    const decimal = new Intl.NumberFormat('en').format(1.1).replace(/1/g, '');
    let reversedVal = val.replace(new RegExp('\\' + group, 'g'), '');
    reversedVal = reversedVal.replace(new RegExp('\\' + decimal, 'g'), '.');

    return Number.isNaN(reversedVal) ? 0 : reversedVal;
  };

  onClose = () => {
    this.populateState();
    this.props.onClose();
  };

  render() {
    return (
      <Dialog
        maxWidth={'md'}
        fullWidth={true}
        className={styles.dialogBox}
        open={this.props.open}
        onClose={this.onClose}
      >
        <div className={cx(styles.title, styles.titleFontSizePopupConf)}>
          License Pricing
          <IconButton
            color="inherit"
            onClick={this.props.onClose}
            className={cx(styles.closeIcon)}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className={styles.licensePricingDescription}>
          Set the rates for the Paid Plan licenses. All rates are per user per
          month, in USD.
        </div>

        <div className={styles.body}>
          <div className={styles.updatePopup}>
            <div>
              <h2 className={styles.informationTitle}>
                Travel & Expense (Premium) License
              </h2>
            </div>
            <div
              className={cx(
                styles.inputContainer,
                styles.inputContainerCreditCard,
              )}
            >
              <div className={styles.inputWrapper}>
                <TextFieldWithClear
                  onChange={this.onChangePremiumAnnualy}
                  fullWidth
                  label="Travel & Expense Paid Annually"
                  variant="outlined"
                  margin="normal"
                  value={this.state.premiumAnnually}
                  onClear={this.onDeletePremiumAnnualy}
                  disabled={this.props.isLoading}
                  startAdornment="$"
                  placeHolder="0.00"
                />
              </div>
              <div className={styles.inputWrapper}>
                <TextFieldWithClear
                  onChange={this.onChangePremiumMonthly}
                  fullWidth
                  label="Travel & Expense Paid Monthly"
                  variant="outlined"
                  margin="normal"
                  value={this.state.premiumMonthly}
                  onClear={this.onDeletePremiumMonthly}
                  disabled={this.props.isLoading}
                  startAdornment="$"
                  placeHolder="0.00"
                />
              </div>
              <div className={styles.inputWrapper}>
                <TextFieldWithClear
                  onChange={this.onChangePremiumOverage}
                  fullWidth
                  label="Travel & Expense Additional Use"
                  variant="outlined"
                  margin="normal"
                  value={this.state.premiumOverage}
                  onClear={this.onDeletePremiumOverage}
                  disabled={this.props.isLoading}
                  startAdornment="$"
                  placeHolder="0.00"
                />
              </div>
            </div>
          </div>
          <div className={styles.updatePopup}>
            <div>
              <h2 className={styles.informationTitle}>Travel License</h2>
            </div>
            <div
              className={cx(
                styles.inputContainer,
                styles.inputContainerCreditCard,
              )}
            >
              <div className={styles.inputWrapper}>
                <TextFieldWithClear
                  onChange={this.onChangeTravelAnnualy}
                  fullWidth
                  label="Travel Paid Annually"
                  variant="outlined"
                  margin="normal"
                  value={this.state.travelAnnually}
                  onClear={this.onDeleteTravelAnnualy}
                  disabled={this.props.isLoading}
                  startAdornment="$"
                  placeHolder="0.00"
                />
              </div>
              <div className={styles.inputWrapper}>
                <TextFieldWithClear
                  onChange={this.onChangeTravelMonthly}
                  fullWidth
                  label="Travel Paid Monthly"
                  variant="outlined"
                  margin="normal"
                  value={this.state.travelMonthly}
                  onClear={this.onDeleteTravelMonthly}
                  disabled={this.props.isLoading}
                  startAdornment="$"
                  placeHolder="0.00"
                />
              </div>
              <div className={styles.inputWrapper}>
                <TextFieldWithClear
                  onChange={this.onChangeTravelOverage}
                  fullWidth
                  label="Travel Additional Use"
                  variant="outlined"
                  margin="normal"
                  value={this.state.travelOverage}
                  onClear={this.onDeleteTravelOverage}
                  disabled={this.props.isLoading}
                  startAdornment="$"
                  placeHolder="0.00"
                />
              </div>
            </div>
          </div>
          <div className={styles.updatePopup}>
            <div>
              <h2 className={styles.informationTitle}>Expense License</h2>
            </div>
            <div
              className={cx(
                styles.inputContainer,
                styles.inputContainerCreditCard,
              )}
            >
              <div className={styles.inputWrapper}>
                <TextFieldWithClear
                  onChange={this.onChangeExpenseAnnualy}
                  fullWidth
                  label="Expense Paid Annually"
                  variant="outlined"
                  margin="normal"
                  value={this.state.expenseAnnually}
                  onClear={this.onDeleteExpenseAnnualy}
                  disabled={this.props.isLoading}
                  startAdornment="$"
                  placeHolder="0.00"
                />
              </div>
              <div className={styles.inputWrapper}>
                <TextFieldWithClear
                  onChange={this.onChangeExpenseMonthly}
                  fullWidth
                  label="Expense Paid Monthly"
                  variant="outlined"
                  margin="normal"
                  value={this.state.expenseMonthly}
                  onClear={this.onDeleteExpenseMonthly}
                  disabled={this.props.isLoading}
                  startAdornment="$"
                  placeHolder="0.00"
                />
              </div>
              <div className={styles.inputWrapper}>
                <TextFieldWithClear
                  onChange={this.onChangeExpenseOverage}
                  fullWidth
                  label="Expense Additional Use"
                  variant="outlined"
                  margin="normal"
                  value={this.state.expenseOverage}
                  onClear={this.onDeleteExpenseOverage}
                  disabled={this.props.isLoading}
                  startAdornment="$"
                  placeHolder="0.00"
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.popupButtonsContainer}>
          <Button
            className={styles.compPopupButton}
            variant="outlined"
            color="primary"
            onClick={this.onClose}
            disabled={this.props.isLoading}
            id="submit-update"
          >
            Cancel
          </Button>

          <Button
            className={styles.compPopupButton}
            variant="contained"
            color="primary"
            onClick={this.onSubmit}
            disabled={this.props.isLoading}
            id="submit-update"
          >
            Save
          </Button>
        </div>
      </Dialog>
    );
  }
}

export default LicensePricingEditPopup;

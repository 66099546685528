import React from 'react';

type Props = {
  width: number;
  height: number;
};

const SvgIconSetting = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Menu---Top"
          transform="translate(-16.000000, -152.000000)"
          stroke="#FFFFFF"
          strokeWidth="1.5"
        >
          <g id="Menu">
            <g
              id="Manage-TB"
              transform="translate(17.000000, 150.000000)"
            >
              <g
                id="Settings"
                transform="translate(0.000000, 3.000000)"
              >
                <path
                  d="M12.2714145,7.48048247 C12.2852303,7.32160088 12.2959759,7.16195175 12.2959759,7 C12.2959759,6.83804825 12.2852303,6.67839912 12.2714145,6.51951753 L13.6138487,5.41425438 C13.7581469,5.2952851 13.7957566,5.08881578 13.7021162,4.92609649 L12.1086952,2.16600877 C12.0142873,2.00328947 11.8170285,1.93267544 11.641261,1.99791667 L10.0125329,2.60888158 C9.75156799,2.42467105 9.47371711,2.26578947 9.18128289,2.12993421 L8.89498902,0.412938598 C8.86428727,0.227960526 8.70387062,0.0921052634 8.5165899,0.0921052634 L5.32898026,0.0921052634 C5.14169954,0.0921052634 4.98128289,0.227960526 4.95058114,0.412938598 L4.66428727,2.12993421 C4.37262062,2.26578947 4.09400219,2.42467105 3.83380482,2.60888158 L2.20430921,1.99791667 C2.02854167,1.93267544 1.8312829,2.00328947 1.73764255,2.16600877 L0.143453947,4.92609649 C0.0498135964,5.08881578 0.0874232454,5.2952851 0.231721491,5.41425438 L1.5741557,6.51951753 C1.56033991,6.67839912 1.55036184,6.83804825 1.55036184,7 C1.55036184,7.16195175 1.56033991,7.32160088 1.5741557,7.48048247 L0.231721491,8.58574562 C0.0874232454,8.7047149 0.0498135964,8.91118422 0.143453947,9.07390351 L1.73764255,11.8339912 C1.8312829,11.9967105 2.02854167,12.0673245 2.20430921,12.0013158 L3.83380482,11.3911184 C4.09400219,11.575329 4.37262062,11.7342105 4.66428727,11.8700658 L4.95058114,13.5870614 C4.98128289,13.7720395 5.14169954,13.9078947 5.32898026,13.9078947 L8.5165899,13.9078947 C8.70387062,13.9078947 8.86428727,13.7720395 8.89498902,13.5870614 L9.18128289,11.8700658 C9.47371711,11.7342105 9.75156799,11.575329 10.0125329,11.3911184 L11.641261,12.0013158 C11.8170285,12.0673245 12.0142873,11.9967105 12.1086952,11.8339912 L13.7021162,9.07390351 C13.7957566,8.91118422 13.7581469,8.7047149 13.6138487,8.58574562 L12.2714145,7.48048247 L12.2714145,7.48048247 Z"
                  id="Stroke-1"
                ></path>
                <path
                  d="M9.55592104,7 C9.55592104,8.41150738 8.41150738,9.55592104 7,9.55592104 C5.58849262,9.55592104 4.44407896,8.41150738 4.44407896,7 C4.44407896,5.58849262 5.58849262,4.44407896 7,4.44407896 C8.41150738,4.44407896 9.55592104,5.58849262 9.55592104,7 L9.55592104,7 Z"
                  id="Stroke-3"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgIconSetting;

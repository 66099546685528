import {
  ACTION_GET_USER_EXPENSE_REPORTS,
  ACTION_GET_USER_EXPENSE_REPORTS_SUCCESS,
  ACTION_GET_USER_EXPENSE_REPORTS_FAILURE,
  API_CALL,
} from '../../configs/ActionTypes';

import { Dispatch } from 'redux';

import { enqueueSnackbarHandler } from '../Status';

export function getExpenseReports(userId: string) {
  return (dispatch: Dispatch, getState: Function) => {
    //Dispatch the api call request.
    dispatch(requestStarted());

    dispatch({
      type: API_CALL,
      payload: {
        call: `user/${userId}/expense-reports`,
        method: 'get',
        data: {},
        success: (response: any) => {
          if (response.type === 'error') {
            enqueueSnackbarHandler(dispatch, response.payload.payload, 'error');
            dispatch(requestFailed(response));
          } else {
            dispatch(requestSuccess(response));
          }
        },
        fail: (error: any) => {
          if (error.payload) {
            enqueueSnackbarHandler(dispatch, error.payload, 'error');
          }
          dispatch(requestFailed(error));
        },
      },
    });

    return null;
  };
}

export function requestStarted() {
  return {
    type: ACTION_GET_USER_EXPENSE_REPORTS,
    payload: null,
  };
}

export function requestSuccess(responseJSON: object) {
  return {
    type: ACTION_GET_USER_EXPENSE_REPORTS_SUCCESS,
    payload: responseJSON,
  };
}

export function requestFailed(responseJSON: object) {
  return {
    type: ACTION_GET_USER_EXPENSE_REPORTS_FAILURE,
    payload: responseJSON,
  };
}

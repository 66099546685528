import React, { Component } from 'react';
import cx from 'classnames';

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import _ from 'lodash-es';
import moment from 'moment';

import { navigate, redirectTo } from '@reach/router';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import Zoom from '@material-ui/core/Zoom';
import CloseIcon from '@material-ui/icons/Close';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconRadioChecked from '../../static/icons/svgRadioChecked';
import IconRadio from '../../static/icons/svgRadio';

import {
  TextMaskCC,
  TextMaskCVC,
  TextMaskExpiry,
} from '../TravelerDetails/MaskedFields/MaskedFields';
import OutlinedInputWithDelete from 'components/TextFieldWithIcon/OutlinedInputWithDelete';
import TextFieldWithClear from 'components/TextFieldWithIcon/TextFieldWithIcon';
import MultiSelect from '../Inputs/MultiSelect/MultiSelect';
import ConfirmationPopup from 'components/Popup/Confirmation';
import CustomSelect from 'components/Inputs/Select/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import AutoSuggest from 'components/Inputs/AutoSuggest/AutoSuggest';
import {
  putCreditCard,
  resetEditCredCardStatus,
} from '../../actions/CreditCards/EditCreditCard';
import {
  deleteCC,
  resetDeleteCreditCardStatus,
} from 'actions/CreditCards/Delete';
import { hasPermission } from '../../helpers/Permission';
import { ReduxState } from '../../reducers/types';
import Loader from 'components/Loader/Loader';
import {
  getOrganizationEmployeesSimple,
  emptyResults,
} from 'actions/Companies/GetOrganizationEmployeesSimple';

const requiredPostalCountries = require('../../helpers/requiredPostalCountries.js');
const styles = require('./styles.pcss');
const requiredRegionCountries = require('../../helpers/requiredRegionCountries.js');
const states = require('../../helpers/States.js');
const provinces = require('../../helpers/Provinces.js');
const countries = require('../../helpers/Countries.js');

type Props = {
  showEditCreditCardPopup: boolean;
  closeEditCreditCardPopup: any;
  putCreditCard: any;
  resetEditCredCardStatus: any;
  departments?: any;
  creditCard: any;
  onSuccess: any;
  companyDetails: any;
  deleteCC: any;
  deleteCreditCardStatus: any;
  resetDeleteCreditCardStatus: any;
  onCreditCardDeleted: any;
  users: any;
  getOrganizationEmployeesSimple: any;
  emptyResults: any;
  employeesSimple: any;
};

type State = {
  editCreditCardStatus: any;
  departments: Array<any>;
  openConfirmation: boolean;
  errorDepartments: string;
  userId: string;
  userError: string;
  name: any;
  expiry: any;
  cvc: any;
  country: any;
  postalCode: any;
  nameError: any;
  expiryError: any;
  countryError: any;
  postalCodeError: any;
  zipCodeRequired: Boolean;
  user: any;
  employees: string;
  regionError: any;
  region: string;
  city: string;
  cityError: any;
  streetAddress: string;
  streetAddressError: any;
  streetAddress2: string;
};

class EditCreditCardPopup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = this.getInitialState();
    this.props.emptyResults();
    this.props.getOrganizationEmployeesSimple(this.props.companyDetails.id);
  }

  getInitialState() {
    return {
      editCreditCardStatus: false,
      departments: _.get(this.props.creditCard, 'departmentIds', []) || [],
      openConfirmation: false,
      employees:
        (_.get(this.props.creditCard, 'departmentIds', []) || []).length === 0
          ? 'all'
          : 'specific',
      errorDepartments: '',
      userId: this.props.creditCard.userId,
      userError: '',
      name: _.get(this.props.creditCard, 'name', ''),
      expiry: moment(_.get(this.props.creditCard, 'expiry', '')).format(
        'MM/YY',
      ),
      cvc: _.get(this.props.creditCard, 'cvv', ''),
      country: _.get(this.props.creditCard.billingAddress, 'country', ''),
      postalCode: _.get(this.props.creditCard.billingAddress, 'postalCode', ''),
      nameError: '',
      expiryError: '',
      countryError: '',
      postalCodeError: '',
      zipCodeRequired: false,
      user: '',
      region: _.get(this.props.creditCard.billingAddress, 'state', ''),
      regionError: '',
      city: _.get(this.props.creditCard.billingAddress, 'city', ''),
      cityError: '',
      streetAddress: _.get(this.props.creditCard.billingAddress, 'street', ''),
      streetAddressError: '',
      streetAddress2: _.get(
        this.props.creditCard.billingAddress,
        'street2',
        '',
      ),
    };
  }

  shouldComponentUpdate(nextProps: any) {
    if (
      _.get(nextProps, 'editCreditCardStatus.editCreditCard', false) === true
    ) {
      if (_.get(nextProps, 'editCreditCardStatus.success', null) === true) {
        this.props.closeEditCreditCardPopup();
        this.props.resetEditCredCardStatus();
        this.setState(this.getInitialState());
        this.props.onSuccess();
        return false;
      }
    }

    if (nextProps.deleteCreditCardStatus.success === true) {
      this.props.resetDeleteCreditCardStatus();
      this.props.onCreditCardDeleted();
    }

    return true;
  }

  renderLoader = () => {
    if (this.props.employeesSimple.isLoading) {
      return (
        <div className={styles.loaderContainer}>
          <Loader visible={true} />
        </div>
      );
    }

    return null;
  };

  handleSubmit = () => {
    if (!this.validFields()) {
      return;
    }

    let type = this.state.employees == 'specific' ? 'department' : 'company';
    this.props.putCreditCard(
      {
        id: this.props.creditCard.id,
        departmentIds:
          this.state.employees == 'specific' ? this.state.departments : [],
        organizationId: this.props.companyDetails.id,
        userId: this.state.userId,
        name: this.state.name,
        expiry: moment(this.state.expiry, 'MM/YYYY').format('YYYY-MM'),
        city: this.state.city,
        country: this.state.country,
        postalCode: this.state.postalCode,
        state: _.get(this.state, 'region.value', this.state.region),
        street: this.state.streetAddress,
        street2: this.state.streetAddress2,
      },
      type,
    );
  };

  handleChangeDepartments = (departments: any) => {
    this.setState({
      departments,
      errorDepartments: '',
    });
  };

  handleDelete = () => {
    this.setState({
      openConfirmation: true,
    });
  };

  closeConfirmation = () => {
    this.setState({
      openConfirmation: false,
    });
  };

  onSubmitDelete = () => {
    this.props.deleteCC(
      this.props.creditCard.id,
      (_.get(this.props.creditCard, 'departmentIds', []) || []).length === 0
        ? 'company'
        : 'department',
    );
  };

  onChangeEmployees = (event: any, value: any) => {
    this.setState({
      employees: value,
      errorDepartments: '',
    });
  };

  onChangeUser = (event: any) => {
    this.setState({
      userId: event.target.value,
      userError: '',
    });
  };

  onChangeName = (event: any) => {
    this.setState({
      name: event.target.value,
      nameError: '',
    });
  };

  onChangeExpiry = (event: any) => {
    this.setState({
      expiry: event.target.value,
      expiryError: '',
    });
  };

  onChangeCountryAuto = (country: any) => {
    let zipCodeRequired =
      requiredPostalCountries.requiredPostalCountries.includes(country);

    this.setState({
      region: '',
      country: country,
      countryError: '',
      zipCodeRequired,
    });
  };

  onChangeRegion = (region: any) => {
    this.setState({
      region: region,
      regionError: '',
    });
  };

  onChangeRegionText = (event: any) => {
    this.setState({
      region: event.target.value,
      regionError: '',
    });
  };

  onChangePostalCode = (event: any) => {
    this.setState({
      postalCode: event.target.value,
      postalCodeError: '',
    });
  };

  onChangeStreetAddress = (event: any) => {
    this.setState({
      streetAddress: event.target.value,
      streetAddressError: '',
    });
  };

  onChangeStreetAddress2 = (event: any) => {
    this.setState({
      streetAddress2: event.target.value,
    });
  };

  onChangeCity = (event: any) => {
    this.setState({
      city: event.target.value,
      cityError: '',
    });
  };

  onDeleteStreetAddress = () => {
    this.setState({
      streetAddress: '',
      streetAddressError: '',
    });
  };

  onDeleteStreetAddress2 = () => {
    this.setState({
      streetAddress2: '',
    });
  };

  onDeleteCity = () => {
    this.setState({
      city: '',
      cityError: '',
    });
  };

  onDeleteName = () => {
    this.setState({
      name: '',
      nameError: '',
    });
  };

  onDeletePostalCode = () => {
    this.setState({
      postalCode: '',
      postalCodeError: '',
    });
  };

  onDeleteExpiry = () => {
    this.setState({
      expiry: '',
      expiryError: '',
    });
  };

  onDeleteRegion = () => {
    this.setState({
      regionError: '',
      region: '',
    });
  };

  validFields = () => {
    let isValid = true;
    let nameError = '';
    let expiryError = '';
    let countryError = '';
    let postalCodeError = '';
    let userError = '';
    let errorDepartments = '';
    let regionError = '';
    let cityError = '';
    let streetAddressError = '';

    if (
      (_.get(this.state, 'region.label', this.state.region) === null ||
        _.get(this.state, 'region.label', this.state.region).trim() === '') &&
      (requiredRegionCountries.requiredRegionCountries.indexOf(
        this.state.country.trim(),
      ) !== -1 ||
        requiredRegionCountries.requiredRegionCountriesCode.indexOf(
          this.state.country.trim(),
        ) !== -1)
    ) {
      regionError = 'Required';
      isValid = false;
    }

    if (this.state.name === null || this.state.name.trim() === '') {
      nameError = 'Required';
      isValid = false;
    }

    if (this.state.expiry === null || this.state.expiry.trim() === '') {
      expiryError = 'Required';
      isValid = false;
    } else if (!moment(this.state.expiry, 'MM/YYYY').isValid()) {
      expiryError = 'Invalid credit card expiry';
      isValid = false;
    }

    if (this.state.city === null || this.state.city.trim() === '') {
      cityError = 'Required';
      isValid = false;
    }

    if (
      this.state.streetAddress === null ||
      this.state.streetAddress.trim() === ''
    ) {
      streetAddressError = 'Required';
      isValid = false;
    }

    if (this.state.country === null || this.state.country.trim() === '') {
      countryError = 'Required';
      isValid = false;
    }

    if (
      (requiredPostalCountries.requiredPostalCountries.includes(
        this.state.country,
      ) ||
        requiredPostalCountries.requiredPostalCountriesCode.includes(
          this.state.country,
        )) &&
      (this.state.postalCode === null || this.state.postalCode.trim() === '')
    ) {
      postalCodeError = 'Required';
      isValid = false;
    }

    if (this.state.userId === null || this.state.userId.trim() === '') {
      userError = 'Required';
      isValid = false;
    }

    if (
      this.state.employees == 'specific' &&
      this.state.departments.length == 0
    ) {
      errorDepartments = 'Required';
      isValid = false;
    }

    this.setState({
      nameError,
      expiryError,
      countryError,
      postalCodeError,
      userError,
      errorDepartments,
      regionError,
      cityError,
      streetAddressError,
    });

    return isValid;
  };

  renderExpiryError = () => {
    if (Boolean(this.state.expiryError)) {
      return <FormHelperText error>{this.state.expiryError}</FormHelperText>;
    }

    return null;
  };

  renderUserError = () => {
    if (Boolean(this.state.userError)) {
      return <FormHelperText error>{this.state.userError}</FormHelperText>;
    }

    return null;
  };

  renderRegion = () => {
    if (this.state.country == 'United States' || this.state.country == 'US') {
      return (
        <div className={styles.inputWrapper}>
          <AutoSuggest
            error={this.state.regionError}
            className={styles.dropdownContainer}
            onChange={(value: any) => {
              this.onChangeRegion(value);
            }}
            label="State"
            value={_.get(this.state, 'region.label', this.state.region)}
            dataSet={states.states}
            returnAll={true}
          />
        </div>
      );
    }
    if (this.state.country == 'Canada' || this.state.country == 'CA') {
      return (
        <div className={styles.inputWrapper}>
          <AutoSuggest
            error={this.state.regionError}
            className={styles.dropdownContainer}
            onChange={(value: any) => {
              this.onChangeRegion(value);
            }}
            label="Province"
            value={_.get(this.state, 'region.label', this.state.region)}
            dataSet={provinces.provinces}
            returnAll={true}
          />
        </div>
      );
    }
    return (
      <div className={styles.inputWrapper}>
        <TextFieldWithClear
          error={Boolean(this.state.regionError)}
          helperText={this.state.regionError}
          onChange={this.onChangeRegionText}
          fullWidth
          label="State/Province/Region"
          variant="outlined"
          margin="normal"
          value={this.state.region}
          onClear={this.onDeleteRegion}
        />
      </div>
    );
  };

  renderZipCode = () => {
    if (
      requiredPostalCountries.requiredPostalCountries.includes(
        this.state.country,
      ) ||
      requiredPostalCountries.requiredPostalCountriesCode.includes(
        this.state.country,
      )
    ) {
      return (
        <div className={styles.inputWrapper}>
          <TextFieldWithClear
            error={Boolean(this.state.postalCodeError)}
            helperText={this.state.postalCodeError}
            onChange={this.onChangePostalCode}
            fullWidth
            label="Zip Code"
            variant="outlined"
            margin="normal"
            value={this.state.postalCode}
            onClear={this.onDeletePostalCode}
          />
        </div>
      );
    }

    return null;
  };

  renderDepartmentsInput = () => {
    if (this.state.employees === 'all') {
      return null;
    }

    let selections = [{ key: '', value: '' }];

    if (
      this.props.departments &&
      _.isArray(this.props.departments) &&
      this.props.departments.length > 0
    ) {
      selections = _.get(this.props, 'departments', []).filter(
        (department: any) => _.get(department, 'status', '') != 'disabled',
      );
      selections = _.map(selections, (department: any) => {
        return { key: department.id, value: department.name };
      });
      selections = selections
        ? selections.sort((a: any, b: any) => a.value.localeCompare(b.value))
        : [];
    }

    return (
      <MultiSelect
        error={this.state.errorDepartments}
        onChange={this.handleChangeDepartments}
        value={this.state.departments}
        selections={selections}
        labelWidth={171}
        label="Departments Available To"
      />
    );
  };

  renderUsersInput = () => {
    if (
      this.props.employeesSimple.employees &&
      _.isArray(this.props.employeesSimple.employees) &&
      this.props.employeesSimple.employees.length > 0
    ) {
      const selections = _.map(this.props.employeesSimple.employees, (user) => {
        return { key: user.id, value: user.name };
      });

      let menuItems = _.filter(this.props.employeesSimple.employees, [
        'status',
        'active',
      ]).map((user: any) => {
        let username = `${_.get(user, 'firstName', '')} ${_.get(
          user,
          'lastName',
          '',
        )}`;

        if (username.trim() == '') {
          username = user.email;
        }
        return { key: user.id, value: user.id, label: username };
      });

      menuItems = _.sortBy(menuItems, ['label']);

      return (
        <>
          <div className={styles.inputWrapper}>
            <CustomSelect
              margin={'normal'}
              variant={'outlined'}
              className={styles.selectWrapper}
              htmlFor={'input-type'}
              label={'Credit Card Owner'}
              id={'input-type'}
              value={this.state.userId}
              onChange={this.onChangeUser}
              fullWidth={true}
              error={Boolean(this.state.userError)}
              inputError={Boolean(this.state.userError)}
              labelWidth={123}
              inputName={'user'}
              renderError={this.renderUserError()}
              MenuProps={{
                style: { marginTop: 4, boxShadow: 'none' },
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
              }}
              menuItems={menuItems}
            />
          </div>
          <div className={styles.inputWrapper}></div>
        </>
      );
    }

    return null;
  };

  render() {
    return (
      <Dialog
        fullWidth={true}
        maxWidth="xl"
        className={cx(styles.dialogBox, styles.ccPopup)}
        open={this.props.showEditCreditCardPopup}
        onClose={this.props.closeEditCreditCardPopup}
      >
        <div className={cx(styles.title)}>
          Update Credit Card Ending in{' '}
          {_.get(this.props, 'creditCard.last4', '')}
          <IconButton
            color="inherit"
            onClick={this.props.closeEditCreditCardPopup}
            className={cx(styles.closeIconUpdatePopup)}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className={styles.subtitlePopup}>
          Update credit card information and view employees who can charge their
          bookings against this card.
        </div>
        {this.renderLoader()}

        <div className={styles.updatePopup}>
          <div className={cx(styles.informationTitle, styles.addMarginTop)}>
            <h2>1. Credit Card Information</h2>
          </div>
          <div className={styles.inputContainer}>
            {this.renderUsersInput()}

            <div className={styles.inputWrapper}>
              <TextFieldWithClear
                error={Boolean(this.state.nameError)}
                helperText={this.state.nameError}
                onChange={this.onChangeName}
                fullWidth
                label="Name on Card"
                variant="outlined"
                margin="normal"
                value={this.state.name}
                onClear={this.onDeleteName}
              />
            </div>

            <div className={styles.inputWrapper}>
              <FormControl
                margin="normal"
                variant="outlined"
                className={styles.selectWrapper}
              >
                <InputLabel
                  error={Boolean(this.state.expiryError)}
                  htmlFor="input-expiry"
                >
                  Expiration Date
                </InputLabel>
                <OutlinedInputWithDelete
                  error={Boolean(this.state.expiryError)}
                  fullWidth
                  id="input-expiry"
                  name="expiry"
                  labelWidth={102}
                  value={this.state.expiry}
                  onChange={this.onChangeExpiry}
                  inputComponent={TextMaskExpiry}
                  onClear={this.onDeleteExpiry}
                />
                {this.renderExpiryError()}
              </FormControl>
            </div>
          </div>
          <div className={styles.billingAddressContainer}>
            <h2 className={styles.informationTitle}>2. Billing Information</h2>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.inputWrapper}>
              <TextFieldWithClear
                error={Boolean(this.state.streetAddressError)}
                helperText={this.state.streetAddressError}
                onChange={this.onChangeStreetAddress}
                fullWidth
                label="Street Address"
                variant="outlined"
                margin="normal"
                value={this.state.streetAddress}
                onClear={this.onDeleteStreetAddress}
              />
            </div>
            <div className={styles.inputWrapper}>
              <TextFieldWithClear
                onChange={this.onChangeStreetAddress2}
                fullWidth
                label="Street Address 2"
                variant="outlined"
                margin="normal"
                value={this.state.streetAddress2}
                onClear={this.onDeleteStreetAddress2}
              />
            </div>
            <div className={styles.inputWrapper}>
              <TextFieldWithClear
                error={Boolean(this.state.cityError)}
                helperText={this.state.cityError}
                onChange={this.onChangeCity}
                fullWidth
                label="City"
                variant="outlined"
                margin="normal"
                value={this.state.city}
                onClear={this.onDeleteCity}
              />
            </div>

            {this.renderRegion()}

            <div className={styles.inputWrapper}>
              <AutoSuggest
                error={this.state.countryError}
                className={styles.dropdownContainer}
                onChange={(value: any) => {
                  this.onChangeCountryAuto(value);
                }}
                label="Country"
                value={this.state.country}
                dataSet={countries.countries}
              />
            </div>

            {this.renderZipCode()}
          </div>

          <h2 className={styles.informationTitle}>3. Credit Card Access</h2>
          <div className={styles.subtitleInfo}>
            These are the employees that can charge their bookings against this
            card.
          </div>

          <RadioGroup
            aria-label=""
            name="employees"
            className={styles.radioGroupUpdatePopup}
            value={this.state.employees}
            onChange={this.onChangeEmployees}
          >
            <FormControlLabel
              value={`all`}
              control={
                <Radio
                  icon={
                    <IconRadio
                      width={16}
                      height={16}
                    />
                  }
                  checkedIcon={
                    <IconRadioChecked
                      width={16}
                      height={16}
                    />
                  }
                  className={styles.checkboxReview}
                />
              }
              label={
                <span className={styles.radioGroupLabel}>All Employees</span>
              }
            />
            <FormControlLabel
              value={`specific`}
              control={
                <Radio
                  icon={
                    <IconRadio
                      width={16}
                      height={16}
                    />
                  }
                  checkedIcon={
                    <IconRadioChecked
                      width={16}
                      height={16}
                    />
                  }
                  className={styles.checkboxReview}
                />
              }
              label={
                <span className={styles.radioGroupLabel}>
                  Specific Employees
                </span>
              }
            />
          </RadioGroup>

          {this.renderDepartmentsInput()}

          {hasPermission('creditCards', 'delete') && (
            <div className={styles.deleteWrapper}>
              <Button
                className={styles.buttonDelete}
                color="secondary"
                onClick={this.handleDelete}
              >
                Delete Card
              </Button>
            </div>
          )}

          <div className={cx(styles.actions, styles.actionUpdatePopup)}>
            <Button
              className={styles.button}
              variant="contained"
              color="primary"
              onClick={this.handleSubmit}
            >
              Save
            </Button>
          </div>
        </div>
        <ConfirmationPopup
          open={this.state.openConfirmation}
          onClose={this.closeConfirmation}
          onSubmit={this.onSubmitDelete}
          title="Are you sure?"
          bodyText="Are you sure you want to delete this Credit Card?"
          submitButtonText="Delete"
          closeButtonText="Cancel"
        />
      </Dialog>
    );
  }
}

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {
    editCreditCardStatus: state.editCreditCardStatus,
    companyDetails: state.company.company,
    deleteCreditCardStatus: state.deleteCreditCardStatus,
    employeesSimple: state.employeesSimple,
  };
}

// Wire up redux dispatch functions
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      putCreditCard,
      resetEditCredCardStatus,
      deleteCC,
      resetDeleteCreditCardStatus,
      getOrganizationEmployeesSimple,
      emptyResults,
    },
    dispatch,
  );
}

// Connect it to Redux
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditCreditCardPopup);

import {
  ACTION_POST_DEPARTMENT,
  ACTION_POST_DEPARTMENT_SUCCESS,
  ACTION_POST_DEPARTMENT_FAILURE,
  ACTION_RESET_DEPARTMENT_SUCCESS,
} from '../configs/ActionTypes';

const initialState = {
  department: {},
  success: null,
  isLoading: false,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_POST_DEPARTMENT: {
      return {
        ...state,
        department: {},
        isLoading: true,
        success: null,
      };
    }

    case ACTION_POST_DEPARTMENT_SUCCESS: {
      const department = action.payload.payload;

      return {
        ...state,
        department: department,
        success: true,
        isLoading: false,
      };
    }

    case ACTION_POST_DEPARTMENT_FAILURE: {
      return {
        ...state,
        success: false,
        isLoading: false,
      };
    }

    case ACTION_RESET_DEPARTMENT_SUCCESS: {
      return {
        ...state,
        department: {},
        success: null,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
}

import * as React from 'react';
import { QUERY_LIMIT_100 } from '../../configs/General';

import _ from 'lodash-es';
import classNames from 'classnames';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import EmployeeListTableHead from './EmployeeListTableHead';
import EmployeeListTableRow from './EmployeeListTableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import SvgAddIcon from '../../static/icons/svgAddIcon';
import ConfirmationPopup from 'components/Popup/Confirmation';
import NewDepartmentPopup from './NewDepartmentPopup';
import Loader from 'components/Loader/Loader';
import {
  updateUserStatus,
  resetUserStatusUpdate,
} from '../../actions/Companies/UpdateUserStatus';
import SvgIconAvatar from '../../static/icons/svgIconAvatar';
import SvgIconSearchThin from '../../static/icons/svgIconSearchThin';
import AddNewEmployeePopup from './AddNewEmployeePopup';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import {
  getOrganizationEmployees,
  emptyResults,
} from 'actions/Companies/GetOrganizationEmployees';
import {
  getDepartments,
  resetGetDepartments,
} from 'actions/Companies/GetDepartment';
import Pagination from 'components/Pagination/Pagination';
import { debounce } from 'lodash-es';
import CustomSelect from 'components/Inputs/Select/Select';
import { getManagers, resetGetManagers } from 'actions/Companies/GetManagers';

import { ReduxState } from '../../reducers/types';
import cx from 'classnames';

import { hasPermission } from '../../helpers/Permission';

const styles = require('./styles.pcss');

import { User } from '../../reducers/types';

const roles = {
  orgAdmin: 'Admin',
  finance: 'Finance',
  orgFinance: 'Finance',
  manager: 'Manager',
};

type Props = {
  employees: any;
  onClickImportEmployees: Function;
  userStatus: any;
  updateUserStatus: Function;
  resetUserStatusUpdate: Function;
  getOrganizationEmployees: Function;
  departments: any;
  travelPolicies: any;
  organizationId: any;
  expensePolicies: any;
  isLoading: any;
  totalPages: any;
  departmentsAction: any;
  emptyResults: Function;
  getManagers: Function;
  resetGetManagers: Function;
  getManagersState: any;
  getDepartments: Function;
  resetGetDepartments: Function;
};

type State = {
  openConfirmationPopup: boolean;
  openNewEmployeePopup: boolean;
  openNewDepartmentPopup: boolean;
  userId: any;
  activate: boolean;
  buttonText: string;
  popupText: string;
  selected: any;
  searchValue: any;
  employees: any;
  employeeToUpdate: any;
  currentPage: any;
  permissions: any;
  travelPolicies: any;
  statusFilter: any;
  managerFilter: any;
  selectedManagers: any;
  selectedManagersAcc: any;
  selectedManagerItems: any;
  departmentsFilter: any;
  selectedDepartments: any;
  selectedDepartmentItems: any;
};

class EmployeeList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      openConfirmationPopup: false,
      openNewEmployeePopup: false,
      userId: '',
      activate: true,
      buttonText: '',
      popupText: '',
      selected: [],
      searchValue: '',
      employees: props.employees,
      employeeToUpdate: false,
      currentPage: 1,
      openNewDepartmentPopup: false,
      permissions: [],
      travelPolicies: [],
      statusFilter: ['active', 'notInvited', 'inviteSent'],
      managerFilter: [],
      selectedManagers: [],
      selectedManagersAcc: [],
      selectedManagerItems: [],
      departmentsFilter: [],
      selectedDepartments: [],
      selectedDepartmentItems: [],
    };

    this.props.emptyResults();

    let getEmplParamas: any = {
      keyword: this.state.searchValue,
      limit: QUERY_LIMIT_100,
      skip: null,
      filters: JSON.stringify({
        status: ['active', 'notInvited', 'inviteSent'],
      }),
    };

    this.props.getManagers(this.props.organizationId, { limit: 100 });
    this.props.getOrganizationEmployees(
      this.props.organizationId,
      getEmplParamas,
    );
    this.onChange = debounce(this.onChange, 250);
  }

  componentWillReceiveProps = (nextProps: Props) => {
    if (_.get(nextProps.userStatus, 'success', false) === true) {
      this.props.resetUserStatusUpdate();
    }
  };

  static getDerivedStateFromProps(props: any, state: any) {
    if (props.employees !== state.employees) {
      return {
        employees: props.employees,
      };
    }
    return null;
  }

  closePopup = () => {
    this.setState({
      openConfirmationPopup: false,
    });
  };

  closeNewEmployeePopup = () => {
    this.setState(
      {
        openNewEmployeePopup: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            employeeToUpdate: false,
          });
        }, 200);
      },
    );
  };

  onClickEdit = (employeeToUpdate: any) => {
    this.setState({
      openNewEmployeePopup: true,
      employeeToUpdate,
    });
  };

  closeDepartmentPopup = () => {
    this.setState({
      openNewDepartmentPopup: false,
    });
  };

  onChangeSearch = (event: any) => {
    if (event.target.value.length > 2) {
      this.onChange(event.target.value);
    }

    if (event.target.value.length == 0) {
      this.onChange('');
    }

    this.setState({
      searchValue: event.target.value,
    });
  };

  onChange = (value: any) => {
    this.props.getOrganizationEmployees(this.props.organizationId, {
      keyword: value,
      limit: QUERY_LIMIT_100,
      skip: null,
    });

    this.setState({
      currentPage: 1,
    });
  };

  onSubmitPopup = () => {
    this.props.updateUserStatus(this.state.userId, {
      enabled: this.state.activate,
    });

    this.setState({
      openConfirmationPopup: false,
      userId: '',
    });
  };

  onClickHeandle = (userId: any, resp: any) => {
    let newStates = {
      openConfirmationPopup: true,
      userId: userId,
      activate: false,
      buttonText: 'Deactivate',
      popupText: 'Are you sure you want to deactivate?',
    };

    if (resp == 'reactivate') {
      newStates.activate = true;
      newStates.buttonText = 'Reactivate';
      newStates.popupText = 'Are you sure you want to reactivate?';
    }

    this.setState(newStates);
  };

  onClickCheckbox = (userId: any, resp: any) => {
    let selected = this.state.selected;

    if (this.state.selected.indexOf(userId) == -1) {
      selected.push(userId);
    } else {
      selected.splice(selected.indexOf(userId), 1);
    }

    this.setState({
      selected: selected,
    });
  };

  handleSelectAll = (event: any) => {
    if (event.target.checked) {
      const newSelecteds = this.state.employees.map((n: any) => n.id);

      this.setState({
        selected: newSelecteds,
      });
      return;
    }

    this.setState({
      selected: [],
    });
  };

  onClickNextPage = () => {
    let skip: any = QUERY_LIMIT_100 * _.get(this.state, 'currentPage', 1);

    this.setState({
      currentPage: this.state.currentPage + 1,
    });

    let getEmplParamas: any = {
      keyword: this.state.searchValue,
      limit: QUERY_LIMIT_100,
      skip: skip,
      filters: this.getEmployeesFilters(
        this.state.permissions,
        this.state.travelPolicies,
        this.state.statusFilter,
        this.state.departmentsFilter,
        this.state.managerFilter,
      ),
    };

    this.props.getOrganizationEmployees(
      this.props.organizationId,
      getEmplParamas,
    );
  };

  onClickPreviousPage = () => {
    let skip: any = QUERY_LIMIT_100 * (_.get(this.state, 'currentPage', 1) - 2);

    this.setState({
      currentPage: this.state.currentPage - 1,
    });

    skip = skip ? skip : null;

    let getEmplParamas: any = {
      keyword: this.state.searchValue,
      limit: QUERY_LIMIT_100,
      skip: skip,
      filters: this.getEmployeesFilters(
        this.state.permissions,
        this.state.travelPolicies,
        this.state.statusFilter,
        this.state.departmentsFilter,
        this.state.managerFilter,
      ),
    };

    this.props.getOrganizationEmployees(
      this.props.organizationId,
      getEmplParamas,
    );
  };

  renderAvatar = (avatar: any, firstName: string, lastName: string) => {
    if (avatar.length > 1 && avatar != null) {
      return (
        <Avatar
          alt="Avatar"
          src={avatar}
          className={styles.avatar}
        />
      );
    } else {
      let name = `${firstName} ${lastName}`;

      if (name.trim() === '') {
        return (
          <SvgIconAvatar
            width={32}
            height={32}
          />
        );
      }

      return (
        <Avatar
          alt="avatar"
          className={styles.avatarLetter}
        >
          {firstName !== null ? firstName.charAt(0) : ''}
          {lastName !== null ? lastName.charAt(0) : ''}
        </Avatar>
      );
    }
  };

  onSuccessFullAddOrUpdate = () => {
    this.setState({
      searchValue: '',
    });

    let getEmplParamas: any = {
      keyword: '',
      limit: QUERY_LIMIT_100,
      skip: null,
      filters: this.getEmployeesFilters(
        this.state.permissions,
        this.state.travelPolicies,
        this.state.statusFilter,
        this.state.departmentsFilter,
        this.state.managerFilter,
      ),
    };

    this.props.getOrganizationEmployees(
      this.props.organizationId,
      getEmplParamas,
    );
  };

  renderEmployeeRow = (employee: any, index: any) => {
    let departments = _.uniq(
      _.get(employee, 'departments', []).map((el: any) => el.name),
    );
    const employeeId = _.get(employee, 'employeeId', '');
    const userId = _.get(employee, 'id', '');
    const firstName = _.get(employee, 'firstName', '');
    const lastName = _.get(employee, 'lastName', '');
    const email = _.get(employee, 'email', '');

    let tbRoles = _.get(employee, 'roles', [])
      .map((el: any) => el.name)
      .map((roleName: string) => _.result(roles, roleName) || null);
    tbRoles = _.filter(tbRoles, (role) => role !== null);
    tbRoles = _.uniq(tbRoles);
    tbRoles = tbRoles.length > 0 ? _.join(tbRoles, ', ') : '- -';

    let travelPolicy = _.get(employee, 'travelPolicy.name', 'Default');

    if (travelPolicy == 'Default Travel Policy') {
      travelPolicy = 'Default';
    }

    const avatar = _.get(employee, 'avatar[0].thumbnails.thumbnail', '');
    const status = _.get(employee, 'status', '');
    let name = `${_.get(employee.manager, 'firstName', '')} ${_.get(
      employee.manager,
      'lastName',
      '',
    )}`.trim();

    if (name == '') {
      name = _.get(employee.manager, 'email', '');
    }

    const managerName = name ? name : '- -';

    return (
      <EmployeeListTableRow
        key={index}
        tbRoles={tbRoles}
        tbRoleStatus={_.get(employee, 'roles[0].status', '')}
        departments={departments.length > 0 ? _.join(departments, ', ') : '- -'}
        manager={managerName}
        travelPolicy={travelPolicy}
        employeeId={employeeId}
        userId={userId}
        employeeName={`${firstName} ${lastName}`}
        employeeEmail={email}
        avatar={this.renderAvatar(avatar, firstName, lastName)}
        status={status}
        selected={this.state.selected}
        handleChangeCheckbox={this.onClickCheckbox.bind(this, userId)}
        isInvited={_.get(employee, 'isInvited', false)}
        showEdit={hasPermission('users', 'update')}
        clickEdit={this.onClickEdit.bind(this, employee)}
        isSolo={_.get(employee, 'roles', []).length === 0}
      />
    );
  };

  onChangeAdd = (event: any) => {
    if (event.target.value == 'addEmployee') {
      this.setState({
        openNewEmployeePopup: true,
        employeeToUpdate: false,
      });
    } else {
      this.setState({
        openNewDepartmentPopup: true,
      });
    }
  };

  onSubmitPermissions = (permissions: any) => {
    this.setState({
      permissions: permissions,
    });

    let getEmplParamas: any = {
      keyword: this.state.searchValue,
      limit: QUERY_LIMIT_100,
      skip: null,
      filters: this.getEmployeesFilters(
        permissions,
        this.state.travelPolicies,
        this.state.statusFilter,
        this.state.departmentsFilter,
        this.state.managerFilter,
      ),
    };

    this.props.getOrganizationEmployees(
      this.props.organizationId,
      getEmplParamas,
    );
  };

  onSubmitStatusFilter = (statusFilter: any) => {
    this.setState({
      statusFilter: statusFilter,
    });

    let getEmplParamas: any = {
      keyword: this.state.searchValue,
      limit: QUERY_LIMIT_100,
      skip: null,
      filters: this.getEmployeesFilters(
        this.state.permissions,
        this.state.travelPolicies,
        statusFilter,
        this.state.departmentsFilter,
        this.state.managerFilter,
      ),
    };

    this.props.getOrganizationEmployees(
      this.props.organizationId,
      getEmplParamas,
    );
  };

  onSubmitTravelPolicy = (travelPolicies: any) => {
    this.setState({
      travelPolicies: travelPolicies,
    });

    let getEmplParamas: any = {
      keyword: this.state.searchValue,
      limit: QUERY_LIMIT_100,
      skip: null,
      filters: this.getEmployeesFilters(
        this.state.permissions,
        travelPolicies,
        this.state.statusFilter,
        this.state.departmentsFilter,
        this.state.managerFilter,
      ),
    };

    this.props.getOrganizationEmployees(
      this.props.organizationId,
      getEmplParamas,
    );
  };

  onSubmitManager = (managerFilter: any, selectedManagerItems: any) => {
    this.setState({
      managerFilter: managerFilter,
      selectedManagerItems: selectedManagerItems,
    });

    let getEmplParamas: any = {
      keyword: this.state.searchValue,
      limit: QUERY_LIMIT_100,
      skip: null,
      filters: this.getEmployeesFilters(
        this.state.permissions,
        this.state.travelPolicies,
        this.state.statusFilter,
        this.state.departmentsFilter,
        managerFilter,
      ),
    };

    this.props.getOrganizationEmployees(
      this.props.organizationId,
      getEmplParamas,
    );
  };

  onSubmitDepartments = (departments: any, selectedDepartmentItems: any) => {
    this.setState({
      departmentsFilter: departments,
      selectedDepartmentItems: selectedDepartmentItems,
    });

    let getEmplParamas: any = {
      keyword: this.state.searchValue,
      limit: QUERY_LIMIT_100,
      skip: null,
      filters: this.getEmployeesFilters(
        this.state.permissions,
        this.state.travelPolicies,
        this.state.statusFilter,
        departments,
        this.state.managerFilter,
      ),
    };

    this.props.getOrganizationEmployees(
      this.props.organizationId,
      getEmplParamas,
    );
  };

  getEmployeesFilters = (
    permissions: any,
    travelPolicies: any,
    statusFilter: any,
    departmentsFilter: any,
    managerFilter: any,
  ) => {
    let filters: any = {};

    if (permissions.length > 0 && permissions.length < 4) {
      filters.permissions = permissions;
    }

    if (departmentsFilter.length > 0) {
      filters.departments = departmentsFilter;
    }

    if (
      travelPolicies.length > 0 &&
      travelPolicies.length < _.get(this.props, 'travelPolicies', []).length
    ) {
      filters.travelPolicies = travelPolicies;
    }

    if (managerFilter.length > 0) {
      filters.managers = managerFilter;
    }

    if (statusFilter.length > 0 && statusFilter.length < 4) {
      filters.status = statusFilter;
    }

    return JSON.stringify(filters);
  };

  onClearTravelPolicy = () => {
    this.setState({
      travelPolicies: [],
    });

    let getEmplParamas: any = {
      keyword: this.state.searchValue,
      limit: QUERY_LIMIT_100,
      skip: null,
      filters: this.getEmployeesFilters(
        this.state.permissions,
        [],
        this.state.statusFilter,
        this.state.departmentsFilter,
        this.state.managerFilter,
      ),
    };

    this.props.getOrganizationEmployees(
      this.props.organizationId,
      getEmplParamas,
    );
  };

  onClearDepartments = () => {
    this.setState({
      departmentsFilter: [],
      selectedDepartmentItems: [],
    });

    let getEmplParamas: any = {
      keyword: this.state.searchValue,
      limit: QUERY_LIMIT_100,
      skip: null,
      filters: this.getEmployeesFilters(
        this.state.permissions,
        this.state.travelPolicies,
        this.state.statusFilter,
        [],
        this.state.managerFilter,
      ),
    };

    this.props.getOrganizationEmployees(
      this.props.organizationId,
      getEmplParamas,
    );
  };

  onClearManagerFilter = () => {
    this.setState({
      managerFilter: [],
      selectedManagerItems: [],
    });

    let getEmplParamas: any = {
      keyword: this.state.searchValue,
      limit: QUERY_LIMIT_100,
      skip: null,
      filters: this.getEmployeesFilters(
        this.state.permissions,
        this.state.travelPolicies,
        this.state.statusFilter,
        this.state.departmentsFilter,
        [],
      ),
    };

    this.props.getOrganizationEmployees(
      this.props.organizationId,
      getEmplParamas,
    );
  };

  onClearPermissions = () => {
    this.setState({
      permissions: [],
    });

    let getEmplParamas: any = {
      keyword: this.state.searchValue,
      limit: QUERY_LIMIT_100,
      skip: null,
      filters: this.getEmployeesFilters(
        [],
        this.state.travelPolicies,
        this.state.statusFilter,
        this.state.departmentsFilter,
        this.state.managerFilter,
      ),
    };

    this.props.getOrganizationEmployees(
      this.props.organizationId,
      getEmplParamas,
    );
  };

  onClearStatusFilter = () => {
    this.setState({
      statusFilter: [],
    });

    let getEmplParamas: any = {
      keyword: this.state.searchValue,
      limit: QUERY_LIMIT_100,
      skip: null,
      filters: this.getEmployeesFilters(
        this.state.permissions,
        this.state.travelPolicies,
        [],
        this.state.departmentsFilter,
        this.state.managerFilter,
      ),
    };

    this.props.getOrganizationEmployees(
      this.props.organizationId,
      getEmplParamas,
    );
  };

  getManagersList = (value: string) => {
    this.props.resetGetManagers();
    this.props.getManagers(this.props.organizationId, {
      name: value,
      limit: 0,
    });
  };

  getName = (data: any) => {
    if (_.get(data, 'value', '')) {
      return data.value;
    }

    let managerName = `${_.get(data, 'firstName', '')} ${_.get(
      data,
      'lastName',
      '',
    )}`.trim();

    if (managerName == '') {
      managerName = _.get(data, 'email', '');
    }

    return managerName;
  };

  getList = (value: string) => {
    this.props.resetGetDepartments();
    this.props.getDepartments(value, this.props.organizationId);
  };

  render() {
    const totalPage =
      _.get(this.props, 'totalPages', 0) > QUERY_LIMIT_100
        ? Math.ceil(_.get(this.props, 'totalPages', 0) / QUERY_LIMIT_100)
        : 1;

    let menuItems = [];

    if (hasPermission('users', 'create')) {
      menuItems.push({
        key: 'addEmployee',
        value: 'addEmployee',
        label: 'Employee',
      });
    }

    if (hasPermission('department', 'create')) {
      menuItems.push({
        key: 'addDepartment',
        value: 'addDepartment',
        label: 'Department',
      });
    }

    let travelPolicies = _.get(this.props, 'travelPolicies', []).map(
      (data: any) => ({
        key: data.id,
        value: data.name,
      }),
    );

    travelPolicies = travelPolicies
      ? travelPolicies.sort((a: any, b: any) => a.value.localeCompare(b.value))
      : [];

    let managersAcc: any = [
      ..._.get(this.props.getManagersState, 'managers', []),
      ...this.state.selectedManagerItems,
    ];
    let managers = [];

    if (managersAcc.length > 0) {
      managers = managersAcc.map((data: any) => ({
        key: _.get(data, 'id', data.key),
        value: _.get(data, 'name', this.getName(data)),
      }));
    }

    managers = managers
      ? managers.sort((a: any, b: any) => a.value.localeCompare(b.value))
      : [];

    managers.unshift({
      key: 'noManager',
      value: 'No Manager',
    });

    let departments = [];
    let departmentsAcc: any = [
      ..._.get(this.props.departmentsAction, 'departments', []),
      ...this.state.selectedDepartmentItems,
    ];

    if (departmentsAcc.length > 0) {
      departments = departmentsAcc.map((data: any) => ({
        key: _.get(data, 'id', data.key),
        value: _.get(data, 'name', data.value),
      }));
    }

    const employeeListHeader = [
      { headerName: 'Employee' },
      {
        headerName: 'Permissions',
        onSubmit: this.onSubmitPermissions,
        onClear: this.onClearPermissions,
        filterOptions: [
          { key: 'none', value: 'None' },
          { key: 'admin', value: 'Admin' },
          { key: 'finance', value: 'Finance' },
          { key: 'manager', value: 'Manager' },
        ],
        value: this.state.permissions,
      },
      {
        headerName: 'Department',
        onSubmit: this.onSubmitDepartments,
        onClear: this.onClearDepartments,
        filterOptions: departments,
        value: this.state.departmentsFilter,
        searchable: true,
        isLoading: this.props.departmentsAction.isLoading,
        getList: this.getList,
        dynamicSearch: true,
      },
      {
        headerName: 'Manager',
        onSubmit: this.onSubmitManager,
        onClear: this.onClearManagerFilter,
        filterOptions: managers,
        value: this.state.managerFilter,
        searchable: true,
        isLoading: _.get(this.props, 'getManagersState.isLoading', false),
        getList: this.getManagersList,
        dynamicSearch: true,
        removeFirstOptionOnSearch: true,
      },
      {
        headerName: 'Travel Policy',
        onSubmit: this.onSubmitTravelPolicy,
        onClear: this.onClearTravelPolicy,
        filterOptions: travelPolicies,
        value: this.state.travelPolicies,
        searchable: true,
      },
      {
        headerName: 'Status',
        onSubmit: this.onSubmitStatusFilter,
        onClear: this.onClearStatusFilter,
        filterOptions: [
          { key: 'active', value: 'Active' },
          { key: 'notInvited', value: 'Not Invited' },
          { key: 'inviteSent', value: 'Invite Sent' },
          { key: 'terminated', value: 'Terminated' },
        ],
        value: this.state.statusFilter,
      },
    ];

    return (
      <div>
        <div className={classNames(styles.employeesTitle)}>
          {(hasPermission('users', 'create') ||
            hasPermission('department', 'create')) && (
            <CustomSelect
              margin={'normal'}
              variant={'outlined'}
              className={styles.selectWrapperEmpl}
              disabled={false}
              htmlFor={'input-type'}
              label={'Add'}
              id={'addNewEmployee'}
              value={''}
              onChange={this.onChangeAdd}
              fullWidth={false}
              labelWidth={110}
              inputName={'type'}
              MenuProps={{
                style: { marginTop: 4, boxShadow: 'none' },
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
              }}
              menuItems={menuItems}
            />
          )}

          {hasPermission('organizations', 'update') && (
            <div
              className={styles.importBox}
              onClick={() => {
                this.props.onClickImportEmployees();
              }}
            >
              <div>Import</div>
            </div>
          )}

          <div className={styles.searchBox}>
            <span>
              <SvgIconSearchThin
                width={17}
                height={17}
                fill="#1B2432"
              />
            </span>
            <input
              className={styles.searchBar}
              placeholder="Search"
              onChange={this.onChangeSearch}
              type="text"
            ></input>
          </div>
        </div>

        <Paper
          className={
            _.get(this.props.userStatus, 'isLoading', true)
              ? cx(styles.paperMax, styles.loading)
              : styles.paperMax
          }
        >
          <Loader
            visible={
              _.get(this.props.userStatus, 'isLoading', false) ||
              _.get(this.props, 'isLoading', false)
            }
            id="employeeListLoader"
          />

          <Table className={classNames(styles.table, styles.tableEmp)}>
            <EmployeeListTableHead
              tableColumns={employeeListHeader}
              numSelected={this.state.selected.length}
              rowCount={this.state.employees.length}
              onSelectAllClick={this.handleSelectAll}
            />

            <TableBody className={styles.empTableBody}>
              {this.state.employees.map(this.renderEmployeeRow)}
            </TableBody>
          </Table>

          {!_.get(this.props, 'isLoading', false) &&
          this.state.employees.length === 0 ? (
            <div className={styles.emptyDataEmplist}>No employees found</div>
          ) : null}
          <ConfirmationPopup
            open={this.state.openConfirmationPopup}
            onClose={this.closePopup}
            onSubmit={this.onSubmitPopup}
            title="Are you sure?"
            bodyText={this.state.popupText}
            submitButtonText={this.state.buttonText}
            closeButtonText="Cancel"
          />

          <NewDepartmentPopup
            open={this.state.openNewDepartmentPopup}
            onClose={this.closeDepartmentPopup}
            organizationId={this.props.organizationId}
          />

          <AddNewEmployeePopup
            open={this.state.openNewEmployeePopup}
            onClose={this.closeNewEmployeePopup}
            departments={this.props.departments}
            employees={this.props.employees}
            travelPolicies={_.get(this.props, 'travelPolicies', [])}
            expensePolicies={this.props.expensePolicies}
            organizationId={this.props.organizationId}
            employee={this.state.employeeToUpdate}
            onSuccess={this.onSuccessFullAddOrUpdate}
          />
        </Paper>
        {totalPage > 1 && (
          <Pagination
            currentPage={_.get(this.state, 'currentPage', 0)}
            maxPage={totalPage}
            onClickNextPage={this.onClickNextPage}
            onClickPreviousPage={this.onClickPreviousPage}
          />
        )}
      </div>
    );
  }
}

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {
    userStatus: state.updateUserStatus,
    employees: state.employees.employees,
    totalPages: state.employees.totalPages,
    isLoading: state.employees.isLoading,
    getManagersState: state.getManagers,
    departmentsAction: state.getDepartments,
  };
}

// Wire up redux dispatch functions
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      updateUserStatus,
      resetUserStatusUpdate,
      getOrganizationEmployees,
      emptyResults,
      getManagers,
      resetGetManagers,
      getDepartments,
      resetGetDepartments,
    },
    dispatch,
  );
}

// Connect it to Redux
export default connect(mapStateToProps, mapDispatchToProps)(EmployeeList);

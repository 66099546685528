import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import _ from 'lodash-es';
import { navigate, redirectTo } from '@reach/router';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextFieldWithClear from 'components/TextFieldWithIcon/TextFieldWithIcon';
import AutoSuggest from 'components/Inputs/AutoSuggest/AutoSuggest';
import MultiSelect from 'components/Inputs/MultiSelect/MultiSelect';
import SelectWithCheckBox from 'components/Inputs/MultiSelect/SelectWithCheckBox';
import DropdownAutoSuggestDynamic from 'components/Inputs/Dropdown/DropdownAutoSuggestDynamic';
import { Checkbox } from '@material-ui/core';
import { add, resetSaveEmployee } from '../../actions/Companies/NewEmployee';
import {
  update,
  reactivate,
  resetUpdateEmployee,
} from '../../actions/Companies/UpdateEmployee';
import {
  updateUserStatus,
  resetUserStatusUpdate,
} from '../../actions/Companies/UpdateUserStatus';
import {
  getOrganizationEmployeesSimple,
  emptyResults as emptyEmployeeSimple,
} from 'actions/Companies/GetOrganizationEmployeesSimple';
import { hasPermission } from '../../helpers/Permission';
const styles = require('./styles.pcss');
import Loader from 'components/Loader/Loader';
import { ReduxState } from '../../reducers/types';

type Props = {
  open: boolean;
  onClose: any;
  departments: any;
  employees: any;
  travelPolicies: any;
  expensePolicies: any;
  addEmployee: any;
  add: any;
  update: any;
  reactivate: any;
  organizationId: any;
  features: Array<any>;
  resetSaveEmployee: any;
  resetUpdateEmployee: any;
  updateEmployee: any;
  employee?: any;
  onSuccess: any;
  updateUserStatus: any;
  resetUserStatusUpdate: any;
  userStatus: any;
  emptyEmployeeSimple: any;
  getOrganizationEmployeesSimple: any;
  employeesSimple: any;
};

type State = {
  firstName: string;
  lastName: string;
  errorName: any;
  errorLastName: any;
  waitingResponse: boolean;
  saveCompanyStatus: boolean;
  email: string;
  errorEmail: string;
  errorDepartmentsFinance: string;
  employeeId: string;
  manager: any;
  permissions: any;
  travelPolicy: string;
  departments: any;
  departmentsFinance: any;
  entireOrgCheck: boolean;
  expensePolicy: string;
  openTerminateProfile: any;
  primaryDepartment: any;
};

class AddNewEmployeePopup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      firstName: _.get(this.props, 'employee.firstName', ''),
      lastName: _.get(this.props, 'employee.lastName', ''),
      errorName: '',
      errorLastName: '',
      errorDepartmentsFinance: '',
      waitingResponse: false,
      saveCompanyStatus: false,
      email: _.get(this.props, 'employee.email', ''),
      errorEmail: '',
      employeeId: _.get(this.props, 'employee.employeeId', ''),
      manager: this.props.employee.manager,
      permissions: this.getPermissions(_.get(this.props, 'employee.roles', [])),
      travelPolicy: _.get(this.props, 'employee.travelPolicyId', ''),
      departments: this.getDepartments(_.get(this.props, 'employee.roles', [])),
      departmentsFinance: _.filter(_.get(this.props, 'employee.roles', []), [
        'name',
        'finance',
      ]).map((el: any) => el.departmentId || ''),
      entireOrgCheck: _.includes(
        _.get(this.props, 'employee.roles', []).map((el: any) => el.name),
        'orgFinance',
      ),
      expensePolicy: _.get(this.props, 'employee.expensePolicyId', ''),
      primaryDepartment: _.get(this.props, 'employee.primaryDepartmentId', ''),
      openTerminateProfile: false,
    };
  }

  getDepartments = (roles: any) => {
    return roles
      .filter((roles: any) => roles.name != 'finance')
      .map((el: any) => el.departmentId)
      .filter((el: any) => el != null);
  };

  getPermissions = (roles: any) => {
    let perm = [];
    perm = _.uniq(roles.map((el: any) => el.name)).filter(
      (el: any) => el != null,
    );
    if (_.includes(perm, 'orgFinance') && !_.includes(perm, 'finance')) {
      perm.push('finance');
    }

    return perm;
  };

  componentWillReceiveProps = (nextProps: Props) => {
    if (_.get(nextProps.addEmployee, 'success', null) === true) {
      this.onClose();
      this.props.resetSaveEmployee();
      this.props.onSuccess();
    }

    if (_.get(nextProps.updateEmployee, 'success', null) === true) {
      this.onClose();
      this.props.resetUpdateEmployee();
      this.props.onSuccess();
    }

    if (_.get(nextProps.userStatus, 'success', null) === true) {
      this.onClose();
      this.props.resetUserStatusUpdate();
      this.props.onSuccess();
    }

    if (_.get(nextProps, 'employee', '') != _.get(this.props, 'employee', '')) {
      this.setState({
        firstName: _.get(nextProps, 'employee.firstName', ''),
        lastName: _.get(nextProps, 'employee.lastName', ''),
        email: _.get(nextProps, 'employee.email', ''),
        employeeId: _.get(nextProps, 'employee.employeeId', ''),
        manager: nextProps.employee.manager,
        permissions: this.getPermissions(
          _.get(nextProps, 'employee.roles', []),
        ),
        travelPolicy: _.get(nextProps, 'employee.travelPolicyId', ''),
        expensePolicy: _.get(nextProps, 'employee.expensePolicyId', ''),
        departments: this.getDepartments(
          _.get(nextProps, 'employee.roles', []),
        ),
        departmentsFinance: _.filter(_.get(nextProps, 'employee.roles', []), [
          'name',
          'finance',
        ]).map((el: any) => el.departmentId),
        entireOrgCheck: _.includes(
          _.get(nextProps, 'employee.roles', []).map((el: any) => el.name),
          'orgFinance',
        ),
        openTerminateProfile: false,
        primaryDepartment: _.get(nextProps, 'employee.primaryDepartmentId', ''),
      });
    }
  };

  onClose = () => {
    this.props.onClose();

    this.setState({
      firstName: '',
      lastName: '',
      errorName: '',
      errorLastName: '',
      waitingResponse: false,
      saveCompanyStatus: false,
      email: '',
      errorEmail: '',
      employeeId: '',
      manager: '',
      permissions: [],
      travelPolicy: '',
      expensePolicy: '',
      departments: [],
      departmentsFinance: [],
      entireOrgCheck: false,
      openTerminateProfile: false,
      primaryDepartment: '',
    });
  };

  handleChangeName = (event: any) => {
    this.setState({
      firstName: event.target.value,
      errorName: '',
    });
  };

  handleChangeEmployeeId = (event: any) => {
    this.setState({
      employeeId: event.target.value,
    });
  };

  handleChangeEmail = (event: any) => {
    this.setState({
      email: event.target.value,
      errorEmail: '',
    });
  };

  handleChangeLastName = (event: any) => {
    this.setState({
      lastName: event.target.value,
      errorLastName: '',
    });
  };

  onDeleteName = () => {
    this.setState({
      firstName: '',
      errorName: '',
    });
  };

  onDeleteEmployeeId = () => {
    this.setState({
      employeeId: '',
    });
  };

  onDeleteEmail = () => {
    this.setState({
      email: '',
      errorEmail: '',
    });
  };

  onDeleteLastName = () => {
    this.setState({
      lastName: '',
      errorLastName: '',
    });
  };

  onClickDeleteDepartments = () => {
    this.setState({
      departments: [],
    });
  };

  onClickDeleteFinanceDepartments = () => {
    this.setState({
      departmentsFinance: [],
      errorDepartmentsFinance: '',
    });
  };

  isValid = () => {
    if (this._isSolo()) {
      return true;
    }

    let errorName = '';
    let errorLastName = '';
    let errorEmail = '';
    let errorDepartmentsFinance = '';
    let isValid = true;

    if (this.state.firstName.trim() == '') {
      errorName = 'Required';
      isValid = false;
    }

    if (this.state.lastName.trim() == '') {
      errorLastName = 'Required';
      isValid = false;
    }

    if (this.state.email.trim() == '') {
      errorEmail = 'Required';
      isValid = false;
    }

    if (
      _.includes(this.state.permissions, 'finance') &&
      !this.state.entireOrgCheck &&
      this.state.departmentsFinance.length == 0
    ) {
      errorDepartmentsFinance = 'Reqiured';
      isValid = false;
    }

    if (!isValid) {
      this.setState({
        errorName,
        errorLastName,
        errorEmail,
        errorDepartmentsFinance,
      });
    }

    return isValid;
  };

  getData = (invite: any) => {
    let permissions = _.clone(this.state.permissions);

    if (this.state.entireOrgCheck) {
      _.pull(permissions, 'finance');
      permissions.push('orgFinance');
    } else {
      _.pull(permissions, 'orgFinance');
    }

    let data: any = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      employeeId: this.state.employeeId,
      departments: _.uniq(this.state.departments),
      manager: this.state.manager,
      travelPolicyId: _.get(this.state, 'travelPolicy.value', ''),
      expensePolicyId: _.get(
        this.state,
        'expensePolicy.value',
        this.state.expensePolicy,
      ),
      permissions: permissions,
      financeDepartments: this.state.entireOrgCheck
        ? []
        : this.state.departmentsFinance,
      invite: invite,
    };

    const expensePolicy = _.find(this.props.expensePolicies, [
      'id',
      data.expensePolicyId,
    ]);

    if (
      _.get(expensePolicy, 'allocationPrimaryDepartment', '') &&
      _.get(expensePolicy, 'allocation', '') &&
      _.get(this.state, 'primaryDepartment.value', this.state.primaryDepartment)
    ) {
      data.primaryDepartmentId = _.get(
        this.state,
        'primaryDepartment.value',
        this.state.primaryDepartment,
      );

      if (!_.includes(data.departments, data.primaryDepartmentId)) {
        data.departments.push(data.primaryDepartmentId);
      }
    } else {
      data.primaryDepartmentId = null;
    }

    return data;
  };

  handleSubmitInvite = () => {
    if (this.isValid()) {
      const req = this.getData(true);
      if (_.get(this.props.employee, 'id', '') !== '') {
        _.set(req, 'type', _.get(this.props.employee, 'type', ''));
        this.props.update(
          req,
          this.props.organizationId,
          _.get(this.props.employee, 'id', ''),
        );
      } else {
        this.props.add(req, this.props.organizationId);
      }
    }
  };

  handleSubmit = () => {
    if (this.isValid()) {
      const req = this.getData(false);
      if (_.get(this.props.employee, 'id', '') !== '') {
        _.set(req, 'type', _.get(this.props.employee, 'type', ''));
        this.props.update(
          req,
          this.props.organizationId,
          _.get(this.props.employee, 'id', ''),
        );
      } else {
        this.props.add(req, this.props.organizationId);
      }
    }
  };

  handleReactivateUser = () => {
    this.props.reactivate(this.props.organizationId, {
      userId: _.get(this.props.employee, 'id', ''),
    });
  };

  onChangeDepartments = (departments: any) => {
    this.setState({
      departments,
    });
  };

  onChangePrimaryDepartment = (primaryDepartment: any) => {
    this.setState({
      primaryDepartment,
    });
  };

  onClearPrimaryDepartment = () => {
    this.setState({
      primaryDepartment: '',
    });
  };

  handleChangeDepartmentsFinance = (departmentsFinance: any) => {
    this.setState({
      departmentsFinance,
      errorDepartmentsFinance: '',
    });
  };

  onChangeManager = (managerId: string, manager: any) => {
    this.setState({
      manager,
    });
  };

  onChangePermission = (permissions: any) => {
    this.setState({
      permissions,
    });
  };

  onChangeTravelPolicy = (travelPolicy: any) => {
    this.setState({
      travelPolicy,
    });
  };

  onChangeExpensePolicy = (expensePolicy: any) => {
    this.setState({
      expensePolicy,
    });
  };

  onChangeCheckbox = () => {
    if (_.get(this.props.employee, 'status', '') != 'disabled') {
      const perm = this.state.permissions;

      let departmentsFinance: any = [];

      if (this.state.entireOrgCheck) {
        departmentsFinance = _.filter(_.get(this.props, 'employee.roles', []), [
          'name',
          'finance',
        ]).map((el: any) => el.departmentId || '');
      }

      this.setState((prevState) => ({
        entireOrgCheck: !prevState.entireOrgCheck,
        departmentsFinance: departmentsFinance,
        errorDepartmentsFinance: '',
      }));
    }
  };

  renderFinance = (departments: any) => {
    if (
      _.get(this.state, 'permissions', []).includes('finance') ||
      _.get(this.state, 'permissions', []).includes('orgFinance')
    ) {
      return (
        <>
          <div className={styles.inputWrapperPop}>
            <div className={styles.checkContainer}>
              <Checkbox
                checked={this.state.entireOrgCheck}
                onChange={this.onChangeCheckbox}
                disabled={
                  _.get(this.props.employee, 'status', '') == 'disabled'
                }
              />
              <p
                onClick={this.onChangeCheckbox}
                className={styles.checkBoxText}
              >
                Finance Approver for Entire Organization
              </p>
            </div>
          </div>
          <div className={styles.inputWrapperPop}>
            <SelectWithCheckBox
              className={styles.inputWrapperPop}
              id="departmentsFinance"
              value={this.state.departmentsFinance}
              label="Finance Approver Departments"
              onChange={(value: any) => {
                this.handleChangeDepartmentsFinance(value);
              }}
              selections={departments}
              endText={'Optional'}
              isMultiple={true}
              searchable={true}
              disabled={
                this.state.entireOrgCheck ||
                _.get(this.props.employee, 'status', '') == 'disabled'
              }
              onClickClear={this.onClickDeleteFinanceDepartments}
              error={this.state.errorDepartmentsFinance}
            />
          </div>
        </>
      );
    }

    return null;
  };

  renderPrimaryDepartment = (departments: any) => {
    let expensePolicyId = _.get(
      this.state,
      'expensePolicy.value',
      this.state.expensePolicy,
    );

    if (!expensePolicyId) {
      return null;
    }

    let expensePolicy = _.find(this.props.expensePolicies, [
      'id',
      expensePolicyId,
    ]);

    if (
      _.get(expensePolicy, 'allocationPrimaryDepartment', '') &&
      _.get(expensePolicy, 'allocation', '')
    ) {
      return (
        <div className={styles.inputWrapperPop}>
          <AutoSuggest
            className={styles.dropdownContainer}
            onChange={(value: any) => this.onChangePrimaryDepartment(value)}
            label="Primary Department"
            value={_.get(
              this.state,
              'primaryDepartment.label',
              this.state.primaryDepartment,
            )}
            dataSet={departments}
            endText={'Optional'}
            returnAll
            disabled={_.get(this.props.employee, 'status', '') == 'disabled'}
            id="primaryDepartment"
          />
        </div>
      );
    }

    return null;
  };

  renderManagers = (employees: any) => {
    const organizationId = this.props.organizationId;
    const featuresList = _.filter(
      _.get(this.props, 'features', []),
      function (f) {
        return (
          _.get(f, 'name', null) == 'directApprovers' &&
          _.includes(_.get(f, 'scope.organizations', []), organizationId)
        );
      },
    );

    if (employees.length == 0) {
      return null;
    }
    employees = _.sortBy(employees, ['name']);

    return (
      <div className={styles.inputWrapperPop}>
        <DropdownAutoSuggestDynamic
          className={styles.dropdownContainer}
          onChange={this.onChangeManager}
          margin="normal"
          label="Manager"
          list={this.parseManagers()}
          getList={this.getEmployeeSimple}
          emptyResults={this.emptyEmployeeSimple}
          isLoading={this.props.employeesSimple.isLoading}
          value={this.getManagerName(this.state.manager)}
        />
      </div>
    );
  };

  getEmployeeSimple = (value: string) => {
    this.props.getOrganizationEmployeesSimple(this.props.organizationId, {
      filters: JSON.stringify({
        firstName: value,
        lastName: value,
        email: value,
        status: ['active', 'unverified'],
        type: 'or',
        withReset: true,
      }),
    });
  };

  getManagerName = (manager: any) => {
    if (!manager) {
      return '';
    }

    const name = `${manager.firstName || ''} ${manager.lastName || ''}`;

    if (name.trim() == '') {
      return manager.email || '';
    }

    return name;
  };

  parseManagers = () => {
    return _.get(this.props, 'employeesSimple.employees', []).map((u: any) => {
      let name = `${_.get(u, 'firstName', '')} ${_.get(u, 'lastName', '')}`;

      if (name.trim() == '') {
        return {
          id: u.id,
          name: `${_.get(u, 'email', '')}`,
        };
      }

      return {
        id: u.id,
        name: name,
        value: u,
      };
    });
  };

  emptyEmployeeSimple = () => {
    this.props.emptyEmployeeSimple();
  };

  renderLoader = () => {
    if (
      _.get(this.props, 'addEmployee.isLoading', false) ||
      _.get(this.props, 'updateEmployee.isLoading', false) ||
      _.get(this.props, 'userStatus.isLoading', false)
    ) {
      return (
        <div className={styles.loaderContainer}>
          <Loader
            visible={true}
            id="employeePopupLoader"
          />
        </div>
      );
    }

    return null;
  };

  getUserName = (user: any) => {
    let name = `${_.get(user, 'firstName', '')} ${_.get(user, 'lastName', '')}`;

    if (name.trim() === '') {
      name = _.get(user, 'email', '');
    }

    return name;
  };

  onClickTerminateProfile = () => {
    this.setState({
      openTerminateProfile: true,
    });
  };

  onClickTerminateProfileSubmit = () => {
    this.props.updateUserStatus(this.props.employee.id, { enabled: false });
  };

  onClickTerminateProfileCancel = () => {
    this.setState({
      openTerminateProfile: false,
    });
  };

  renderStatusUpdate = () => {
    if (this._isSolo()) {
      return null;
    }

    if (
      _.get(this.props.employee, 'id', '') !== '' &&
      hasPermission('users', 'delete') &&
      _.get(this.props.employee, 'status', '') != 'disabled'
    ) {
      if (!this.state.openTerminateProfile) {
        return (
          <div className={styles.deleteButtonContainer}>
            <Button
              size="small"
              className={styles.buttonTerminateProfile}
              variant="contained"
              onClick={this.onClickTerminateProfile}
            >
              Terminate
            </Button>
          </div>
        );
      } else {
        return (
          <div className={styles.deleteProfileContainer}>
            <div>
              <div className={styles.deleteTextStyle}>Terminate</div>
              <div className={styles.deleteTextDescStyle}>
                Please confirm that you want to terminate this employee’s
                account.
              </div>
            </div>
            <div
              className={classNames(styles.buttonCont, styles.profileDelete)}
            >
              <Button
                size="small"
                className={styles.cancelButton}
                variant="outlined"
                color="primary"
                onClick={this.onClickTerminateProfileCancel}
              >
                Cancel
              </Button>

              <Button
                className={styles.deleteButtonStyle}
                variant="contained"
                color="secondary"
                size="small"
                onClick={this.onClickTerminateProfileSubmit}
              >
                Terminate
              </Button>
            </div>
          </div>
        );
      }
    }

    return null;
  };

  renderActionButton = () => {
    if (
      _.get(this.props.employee, 'roles[0].status', '') === 'pending' &&
      _.get(this.props.employee, 'status', '') != 'disabled' &&
      _.get(this.props.employee, 'isInvited', false)
    ) {
      return (
        <>
          <Button
            className={styles.cancelButton}
            color="primary"
            variant="outlined"
            onClick={this.handleSubmitInvite}
          >
            Resend Invite
          </Button>

          <Button
            className={styles.button}
            variant="contained"
            color="primary"
            onClick={this.handleSubmit}
            disabled={this.state.openTerminateProfile}
          >
            Save
          </Button>
        </>
      );
    }

    if (
      _.get(this.props.employee, 'id', '') == '' ||
      this._isSolo() ||
      (_.get(this.props.employee, 'status', '') == 'unverified' &&
        !_.get(this.props.employee, 'isInvited', false))
    ) {
      return (
        <>
          <Button
            size="small"
            className={styles.cancelButton}
            color="primary"
            variant="outlined"
            onClick={this.handleSubmit}
            id="saveForLater"
          >
            Save for Later
          </Button>

          <Button
            className={styles.button}
            variant="contained"
            color="primary"
            onClick={this.handleSubmitInvite}
          >
            Send Invite
          </Button>
        </>
      );
    }

    if (_.get(this.props.employee, 'status', '') == 'disabled') {
      return (
        <Button
          className={styles.button}
          variant="contained"
          color="primary"
          onClick={this.handleReactivateUser}
        >
          Reactivate
        </Button>
      );
    }

    if (
      _.get(this.props.employee, 'status', '') == 'unverified' &&
      !_.get(this.props.employee, 'isInvited', false)
    ) {
      return (
        <>
          <Button
            className={styles.cancelButton}
            color="primary"
            variant="outlined"
            onClick={this.handleSubmit}
            id="saveForLater"
          >
            Save for Later
          </Button>

          <Button
            className={styles.button}
            variant="contained"
            color="primary"
            onClick={this.handleSubmitInvite}
          >
            Send Invite
          </Button>
        </>
      );
    }

    return (
      <Button
        className={styles.button}
        variant="contained"
        color="primary"
        onClick={this.handleSubmit}
        disabled={this.state.openTerminateProfile}
        id="saveEmployee"
      >
        Save
      </Button>
    );
  };

  _isSolo() {
    if (_.get(this.props, 'employee.roles', []).length === 0) {
      return true;
    }

    if (_.get(this.props, 'employee.roles[0].status', '') === 'pending') {
      return true;
    }

    return false;
  }

  render() {
    const employees = [
      {
        code: '',
        name: '',
      },
    ].concat(
      _.reject(this.props.employees, ['status', 'disabled']).map(
        (data: any) => ({
          code: data.id,
          name: this.getUserName(data),
        }),
      ),
    );

    let travelPolicies = _.get(this.props, 'travelPolicies', []).map(
      (data: any) => ({
        code: data.id,
        name: data.name,
      }),
    );
    travelPolicies = travelPolicies
      ? travelPolicies.sort((a: any, b: any) => a.name.localeCompare(b.name))
      : [];

    let expensePolicies = this.props.expensePolicies.map((data: any) => ({
      code: data.id,
      name: data.name,
    }));
    expensePolicies = expensePolicies
      ? expensePolicies.sort((a: any, b: any) => a.name.localeCompare(b.name))
      : [];

    let depart = _.get(this.props, 'departments', []).filter(
      (department: any) => _.get(department, 'status', '') != 'disabled',
    );
    depart = depart
      ? depart.sort((a: any, b: any) => a.name.localeCompare(b.name))
      : [];

    const departments = depart.map((data: any) => ({
      key: data.id,
      value: data.name,
    }));

    const primaryDepartments = [
      {
        code: '',
        name: '',
      },
    ].concat(
      depart.map((data: any) => ({
        code: data.id,
        name: data.name,
      })),
    );

    const permissions = [
      {
        key: 'orgAdmin',
        value: 'Admin',
        description:
          'Admin has administrator privileges to your company’s account and full access to company settings, users & analytics.',
      },
      {
        key: 'finance',
        value: 'Finance',
        description:
          'Finance will be sent expense reports after they have been approved by managers. Finance approval will authorize reimbursement.',
        description2:
          '*Employees will receive manager approval permissions when added as a manager for another employee.',
      },
    ];

    return (
      <Dialog
        fullWidth={true}
        maxWidth="md"
        className={classNames(styles.dialogBox, styles.companyEmployeePopup)}
        open={this.props.open}
        onClose={this.onClose}
      >
        <div className={classNames(styles.title)}>
          {_.get(this.props.employee, 'id', '') !== ''
            ? 'Edit Employee'
            : 'Add Employee'}
          <IconButton
            color="inherit"
            onClick={this.onClose}
            className={classNames(styles.closeIcon)}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className={classNames(styles.inputContainer)}>
          <div className={styles.inputWrapperPop}>
            <TextFieldWithClear
              fullWidth
              id="firstName"
              className={classNames(styles.textField)}
              label="First Name"
              variant="outlined"
              margin="normal"
              value={this.state.firstName}
              onChange={this.handleChangeName}
              error={Boolean(this.state.errorName)}
              helperText={this.state.errorName}
              onClear={this.onDeleteName}
              disabled={
                _.get(this.props.employee, 'id', '') &&
                (_.get(this.props.employee, 'status', '') == 'disabled' ||
                  this._isSolo())
              }
            />
          </div>
          <div className={styles.inputWrapperPop}>
            <TextFieldWithClear
              fullWidth
              id="lastName"
              className={classNames(styles.textField)}
              label="Last Name"
              variant="outlined"
              margin="normal"
              value={this.state.lastName}
              onChange={this.handleChangeLastName}
              error={Boolean(this.state.errorLastName)}
              helperText={this.state.errorLastName}
              onClear={this.onDeleteLastName}
              disabled={
                _.get(this.props.employee, 'id', '') &&
                (_.get(this.props.employee, 'status', '') == 'disabled' ||
                  this._isSolo())
              }
            />
          </div>
          <div className={styles.inputWrapperPop}>
            <TextFieldWithClear
              fullWidth
              id="email"
              className={classNames(styles.textField)}
              label="Work Email"
              variant="outlined"
              margin="normal"
              value={this.state.email}
              onChange={this.handleChangeEmail}
              error={Boolean(this.state.errorEmail)}
              helperText={this.state.errorEmail}
              onClear={this.onDeleteEmail}
              disabled={
                _.get(this.props.employee, 'id', '') &&
                (_.get(this.props.employee, 'status', '') == 'disabled' ||
                  this._isSolo())
              }
            />
          </div>
          <div className={styles.inputWrapperPop}>
            <TextFieldWithClear
              fullWidth
              id="employeeId"
              className={classNames(styles.textField)}
              label="Employee ID"
              variant="outlined"
              margin="normal"
              value={this.state.employeeId}
              onChange={this.handleChangeEmployeeId}
              onClear={this.onDeleteEmployeeId}
              endText={'Optional'}
              disabled={_.get(this.props.employee, 'status', '') == 'disabled'}
            />
          </div>
          <div className={styles.inputWrapperPop}>
            <SelectWithCheckBox
              className={styles.inputWrapperPop}
              id="departments"
              value={this.state.departments}
              label="Department"
              onChange={(value: any) => {
                this.onChangeDepartments(value);
              }}
              disabled={_.get(this.props.employee, 'status', '') == 'disabled'}
              selections={departments}
              endText={'Optional'}
              isMultiple={true}
              onClickClear={this.onClickDeleteDepartments}
              searchable={true}
            />
          </div>

          {this.renderManagers(employees)}

          <div className={styles.inputWrapperPop}>
            <AutoSuggest
              className={styles.dropdownContainer}
              onChange={(value: any) => this.onChangeExpensePolicy(value)}
              label="Expense Policy"
              value={_.get(
                this.state,
                'expensePolicy.label',
                this.state.expensePolicy,
              )}
              dataSet={expensePolicies}
              endText={'Optional'}
              returnAll
              disabled={_.get(this.props.employee, 'status', '') == 'disabled'}
              id="expensePolicy"
            />
          </div>

          {this.renderPrimaryDepartment(primaryDepartments)}

          <div className={styles.inputWrapperPop}>
            <AutoSuggest
              className={styles.dropdownContainer}
              onChange={(value: any) => this.onChangeTravelPolicy(value)}
              label="Travel Policy"
              value={_.get(
                this.state,
                'travelPolicy.label',
                this.state.travelPolicy,
              )}
              dataSet={travelPolicies}
              endText={'Optional'}
              returnAll
              disabled={_.get(this.props.employee, 'status', '') == 'disabled'}
              id="travelPolicy"
            />
          </div>
          <div className={styles.inputWrapperPop}>
            <SelectWithCheckBox
              className={styles.inputWrapperPop}
              id="permission"
              value={this.state.permissions}
              label="Advanced Permissions"
              onChange={(value: any) => {
                this.onChangePermission(value);
              }}
              disabled={_.get(this.props.employee, 'status', '') == 'disabled'}
              selections={permissions}
              endText={'Optional'}
              isMultiple={true}
            />
          </div>
        </div>
        <div className={classNames(styles.inputContainer)}>
          {this.renderFinance(departments)}
        </div>
        <div className={styles.statusUpdate}>{this.renderStatusUpdate()}</div>
        <div className={styles.actionButtons}>{this.renderActionButton()}</div>
        {this.renderLoader()}
      </Dialog>
    );
  }
}

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {
    addEmployee: state.addEmployee,
    updateEmployee: state.updateEmployee,
    features: state.betaFeatures.features,
    userStatus: state.updateUserStatus,
    employeesSimple: state.employeesSimple,
  };
}

// Wire up redux dispatch functions
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      add,
      update,
      resetSaveEmployee,
      resetUpdateEmployee,
      resetUserStatusUpdate,
      updateUserStatus,
      emptyEmployeeSimple,
      getOrganizationEmployeesSimple,
      reactivate,
    },
    dispatch,
  );
}

// Connect it to Redux
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddNewEmployeePopup);

import * as React from 'react';
import classNames from 'classnames';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const styles = require('./styles.pcss');

type Props = {
  cells: Array<string>;
};

const SimpleTableRow = ({ cells }: Props) => (
  <TableRow>
    {cells.map((v, i) => {
      return (
        <TableCell
          className={classNames(styles.tableRowCell)}
          scope="row"
          key={`table-cell-${i}`}
        >
          {v}
        </TableCell>
      );
    })}
  </TableRow>
);

export default SimpleTableRow;

import {
  ACTION_PUT_EXCLUSIVE_HOTEL,
  ACTION_PUT_EXCLUSIVE_HOTEL_SUCCESS,
  ACTION_PUT_EXCLUSIVE_HOTEL_FAILURE,
  API_CALL,
} from '../../../configs/ActionTypes';

import _ from 'lodash-es';

import { Dispatch } from 'redux';
import { track } from '../../../analytics/analytics';

import { enqueueSnackbarHandler } from '../../Status';

export type datatype = {
  name: string;
  identifier: string;
};

export function postHotel(data: datatype) {
  return (dispatch: Dispatch, getState: Function) => {
    dispatch(requestStarted());
    //Dispatch the api call request.
    dispatch({
      type: API_CALL,
      payload: {
        call: 'exclusive-hotel',
        method: 'POST',
        data: JSON.stringify(data),
        success: (response: any) => {
          if (response.type === 'error') {
            dispatch(requestFailed(response));
          } else {
            enqueueSnackbarHandler(
              dispatch,
              { updateExlusiveHotelSuccess: true },
              'success',
            );
            handleTrack();
            dispatch(requestSuccess(response));
          }
        },
        fail: (error: any) => {
          dispatch(requestFailed(error));
        },
      },
    });
    return null;
  };
}

export function requestStarted() {
  return {
    type: ACTION_PUT_EXCLUSIVE_HOTEL,
    payload: null,
  };
}

export function requestSuccess(responseJSON: object) {
  return {
    type: ACTION_PUT_EXCLUSIVE_HOTEL_SUCCESS,
    payload: responseJSON,
  };
}

export function requestFailed(responseJSON: object) {
  return {
    type: ACTION_PUT_EXCLUSIVE_HOTEL_FAILURE,
    payload: responseJSON,
  };
}

function handleTrack() {
  let properties = {
    Product: 'hotels',
    Badge: 'partner',
  };

  track('BB_PartnerCreated', properties);
}

import {
  ACTION_GET_USERS,
  ACTION_GET_USERS_SUCCESS,
  ACTION_GET_USERS_WITH_RESET_SUCCESS,
  ACTION_GET_USERS_FAILURE,
  ACTION_EMPTY_USERS,
} from '../configs/ActionTypes';

import { QUERY_LIMIT } from '../configs/General';
import { User } from './types';

import _ from 'lodash-es';

const initialState = {
  users: [],
  isLoading: false,
  loadedAll: false,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_GET_USERS: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ACTION_EMPTY_USERS: {
      return initialState;
    }

    case ACTION_GET_USERS_SUCCESS: {
      let users: Array<User> = _.clone(state.users);

      let loadedAll = state.loadedAll;
      const payload = action.payload.payload;

      if (payload.length < QUERY_LIMIT) {
        loadedAll = true;
      }

      _.each(payload, (u) => {
        let user: User = {
          id: u.id,
          firstName: _.get(u, 'firstName', ''),
          lastName: _.get(u, 'lastName', ''),
          email: _.get(u, 'email', ''),
          status: u.status,
          phoneNumber: u.phoneNumber,
          created: u.created,
          updated: u.updated,
          avatar: u.avatar,
        };

        users.push(user);
      });

      return {
        ...state,
        users: users,
        loadedAll: loadedAll,
        isLoading: false,
      };
    }

    // Populate users without persisting the old record
    case ACTION_GET_USERS_WITH_RESET_SUCCESS: {
      let users: Array<User> = [];
      const payload = action.payload.payload;

      _.each(payload, (u) => {
        let user: User = {
          id: u.id,
          firstName: _.get(u, 'firstName', ''),
          lastName: _.get(u, 'lastName', ''),
          email: _.get(u, 'email', ''),
          status: u.status,
          phoneNumber: u.phoneNumber,
          created: u.created,
          updated: u.updated,
          avatar: u.avatar,
        };

        users.push(user);
      });

      return {
        ...state,
        users: users,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
}

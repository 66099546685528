import * as React from 'react';
import cx from 'classnames';
import TextFieldWithClear from 'components/TextFieldWithIcon/TextFieldWithIcon';
import Button from '@material-ui/core/Button';

const styles = require('./styles.pcss');

class UsersRecordForm extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      firstName: '',
      lastName: '',
      email: '',
    };
  }

  componentDidMount = () => {
    this.onSubmitForm('initial');
  };

  onChangeFirstName = (event: any) => {
    this.setState({
      firstName: event.target.value,
    });
  };

  onChangeLastName = (event: any) => {
    this.setState({
      lastName: event.target.value,
    });
  };

  onChangeEmail = (event: any) => {
    this.setState({
      email: event.target.value,
    });
  };

  clearFirstName = () => {
    this.setState({
      firstName: '',
    });
  };

  clearLastName = () => {
    this.setState({
      lastName: '',
    });
  };

  clearEmail = () => {
    this.setState({
      email: '',
    });
  };

  onSubmitForm = (initial?: any) => {
    let req: any = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      order: 'lastName ASC',
    };

    if (initial == 'initial') {
      req.order = 'created DESC';

      const urlParams = new URLSearchParams(window.location.search);

      if (urlParams.get('firstName')) {
        req.firstName = urlParams.get('firstName');
      }

      if (urlParams.get('lastName')) {
        req.lastName = urlParams.get('lastName');
      }

      if (urlParams.get('email')) {
        req.email = urlParams.get('email');
      }

      this.setState({
        firstName: req.firstName,
        lastName: req.lastName,
        email: req.email,
      });

      if (urlParams.get('sort')) {
        req.order = decodeURIComponent(urlParams.get('sort') || 'created DESC');
      }
    } else {
      let state = { type: 'user' };
      let url = 'lookup?type=user';

      if (this.state.firstName) {
        url += `&firstName=${encodeURIComponent(this.state.firstName)}`;
      }

      if (this.state.lastName) {
        url += `&lastName=${encodeURIComponent(this.state.lastName)}`;
      }

      if (this.state.email) {
        url += `&email=${encodeURIComponent(this.state.email)}`;
      }

      url += `&sort=${encodeURIComponent('lastName ASC')}`;

      window.history.pushState(state, '', url);
    }

    this.props.onSubmit(req);
  };

  _getSearchButtonText = () => {
    return 'Search';
  };

  onKeyPressed = (e: any) => {
    if (e.key === 'Enter') {
      this.onSubmitForm();
    }
  };

  render() {
    return (
      <div
        className={styles.form}
        onKeyDown={(e) => this.onKeyPressed(e)}
      >
        <div className={styles.inputWrapper}>
          <TextFieldWithClear
            onChange={this.onChangeFirstName}
            label="First Name"
            variant="outlined"
            margin="normal"
            value={this.state.firstName}
            onClear={this.clearFirstName}
            fullWidth={true}
          />
        </div>

        <div className={styles.inputWrapper}>
          <TextFieldWithClear
            onChange={this.onChangeLastName}
            label="Last Name"
            variant="outlined"
            margin="normal"
            value={this.state.lastName}
            onClear={this.clearLastName}
            fullWidth={true}
          />
        </div>

        <div className={styles.inputWrapper}>
          <TextFieldWithClear
            onChange={this.onChangeEmail}
            label="Email"
            variant="outlined"
            margin="normal"
            value={this.state.email}
            onClear={this.clearEmail}
            fullWidth={true}
          />
        </div>

        <div className={styles.inputWrapper}>
          <Button
            fullWidth
            className={styles.button}
            variant="contained"
            color="primary"
            onClick={this.onSubmitForm}
          >
            {this._getSearchButtonText()}
          </Button>
        </div>
      </div>
    );
  }
}

export default UsersRecordForm;

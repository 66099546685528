import {
  ACTION_POST_AGENCY,
  ACTION_POST_AGENCY_SUCCESS,
  ACTION_POST_AGENCY_FAILURE,
  API_CALL,
} from '../../configs/ActionTypes';
import { enqueueSnackbarHandler } from '../Status';

import { Dispatch } from 'redux';

import { enqueueSnackbar } from '../Status';

export function postSingle(data: any) {
  return (dispatch: Dispatch, getState: Function) => {
    // Dispatch the api call request.
    dispatch(requestStarted());

    dispatch({
      type: API_CALL,
      payload: {
        call: `agency`,
        method: 'post',
        data: JSON.stringify(data),
        success: (response: any) => {
          if (response.type === 'error') {
            let errors = response.payload.payload;
            Object.keys(errors).forEach(function (item) {
              if (errors[item] == true) {
                dispatch(enqueueSnackbar(item, 'error'));
              }
            });
            dispatch(requestFailed(response));
          } else {
            enqueueSnackbarHandler(
              dispatch,
              { addAgencySuccess: true },
              'success',
            );
            dispatch(requestSuccess(response));
          }
        },
        fail: (error: any) => {
          let errors = error.payload;
          Object.keys(errors).forEach(function (item) {
            if (errors[item] == true) {
              dispatch(enqueueSnackbar(item, 'error'));
            }
          });
          dispatch(requestFailed(error));
        },
      },
    });

    return null;
  };
}

export function requestStarted() {
  return {
    type: ACTION_POST_AGENCY,
    payload: null,
  };
}

export function requestSuccess(responeJSON: object) {
  return {
    type: ACTION_POST_AGENCY_SUCCESS,
    payload: responeJSON,
  };
}

export function requestFailed(responeJSON: object) {
  return {
    type: ACTION_POST_AGENCY_FAILURE,
    payload: responeJSON,
  };
}

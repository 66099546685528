import * as React from 'react';
import classNames from 'classnames';
import { navigate } from '@reach/router';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { Company } from '../../reducers/types';

const styles = require('./styles.pcss');

type Props = {
  company: Company;
};

const CompanyTableRow = ({ company }: Props) => (
  <TableRow
    className={classNames(styles.clickable, styles.tableRow)}
    key={company.id}
    onClick={() => navigate(`/company/${company.id}`)}
  >
    <TableCell
      className={classNames(styles.tableRowCell)}
      scope="row"
    >
      {company.name}
    </TableCell>
    <TableCell className={classNames(styles.tableRowCell)}>
      {company.primaryDomain}
    </TableCell>
    <TableCell className={classNames(styles.tableRowCell)}>
      {company?.agency?.friendlyName ?? ''}
    </TableCell>
    <TableCell className={classNames(styles.tableRowCell)}></TableCell>
  </TableRow>
);

export default CompanyTableRow;

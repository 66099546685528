import * as React from 'react';
import _ from 'lodash-es';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import LayoutDashboard from 'components/Layout/Dashboard';
import ChangePasswordPopup from 'components/Popup/ChangePassword';
import SvgIconLock from '../../static/icons/svgIconLock';
import TextFieldWithClear from 'components/TextFieldWithIcon/TextFieldWithIcon';

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { get as getMyProfile } from '../../actions/User/GetProfile';
import {
  save as saveMyProfile,
  resetErrors,
} from '../../actions/User/SaveProfile';

import { ReduxState } from '../../reducers/types';

const styles = require('./styles.pcss');

type Props = {
  path: string;
  getMyProfile: Function;
  saveMyProfile: Function;
  resetErrors: Function;
  myProfile: any;
};

type State = {
  openChangePasswordPopup: boolean;
  name: string;
  email: string;
  nameError: string;
  emailError: string;
};

class MyAccount extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      openChangePasswordPopup: false,
      name: '',
      email: '',
      nameError: '',
      emailError: '',
    };
  }

  componentWillReceiveProps = (nextProps: Props) => {
    // Keeping name and email states if there are errors.
    if (
      _.get(nextProps.myProfile, 'nameError', '') === '' &&
      _.get(nextProps.myProfile, 'emailError', '') === ''
    ) {
      this.setState({
        name: nextProps.myProfile.name,
        email: nextProps.myProfile.email,
      });
    } else {
      this.setState({
        nameError: nextProps.myProfile.nameError,
        emailError: nextProps.myProfile.emailError,
      });
    }
  };

  componentDidMount() {
    this.props.getMyProfile();
  }

  componentWillUnmount() {
    this.props.resetErrors();
  }

  openChangePasswordPopup = () => {
    this.setState({
      openChangePasswordPopup: true,
    });
  };

  closeChangePasswordPopup = () => {
    this.setState({
      openChangePasswordPopup: false,
    });
  };

  handleChangeName = (event: any) => {
    this.setState({
      name: event.target.value,
      nameError: '',
    });
  };

  handleChangeEmail = (event: any) => {
    this.setState({
      email: event.target.value,
      emailError: '',
    });
  };

  onDeleteEmail = () => {
    this.setState({
      email: '',
      emailError: '',
    });
  };

  onDeleteName = () => {
    this.setState({
      name: '',
      nameError: '',
    });
  };

  validFields = () => {
    let isValid = true;
    let nameError = '';
    let emailError = '';

    if (this.state.name.trim() === '') {
      nameError = 'Required';
      isValid = false;
    }

    if (this.state.email.trim() === '') {
      emailError = 'Required';
      isValid = false;
    }

    this.setState({
      nameError,
      emailError,
    });

    return isValid;
  };

  onClickSubmit = () => {
    if (this.validFields()) {
      this.props.saveMyProfile(this.state.name, this.state.email);
    }
  };

  render() {
    return (
      <LayoutDashboard>
        <div className="pageWrapper pageWrapperThin">
          <h1>{this.props.myProfile.name}</h1>
          <div className={styles.accountLayout}>
            <Paper className={styles.form}>
              <h2>Personal Details</h2>

              <div className={styles.inputContainer}>
                <div className={styles.inputWrapper}>
                  <TextFieldWithClear
                    error={Boolean(this.state.nameError)}
                    helperText={this.state.nameError}
                    fullWidth
                    label="Name"
                    variant="outlined"
                    margin="normal"
                    value={this.state.name}
                    onChange={this.handleChangeName}
                    onClear={this.onDeleteName}
                  />
                </div>

                <div className={styles.inputWrapper}>
                  <TextFieldWithClear
                    error={Boolean(this.state.emailError)}
                    helperText={this.state.emailError}
                    fullWidth
                    label="Email"
                    variant="outlined"
                    margin="normal"
                    value={this.state.email}
                    onChange={this.handleChangeEmail}
                    onClear={this.onDeleteEmail}
                  />
                </div>
              </div>

              <div className={styles.actions}>
                <div
                  className={styles.link}
                  onClick={this.openChangePasswordPopup}
                >
                  <SvgIconLock
                    width={10}
                    height={13}
                  />
                  Change Password
                </div>

                <div className={styles.buttonContainer}>
                  <Button
                    className={styles.button}
                    variant="contained"
                    color="primary"
                    onClick={this.onClickSubmit}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Paper>
          </div>
        </div>
        <ChangePasswordPopup
          showChangePassword={this.state.openChangePasswordPopup}
          closeChangePassword={this.closeChangePasswordPopup}
        />
      </LayoutDashboard>
    );
  }
}

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {
    myProfile: state.myProfile,
  };
}

// Wire up redux dispatch functions
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getMyProfile,
      saveMyProfile,
      resetErrors,
    },
    dispatch,
  );
}

// Connect it to Redux
export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);

import React, { useState, useMemo } from 'react';
import _ from 'lodash-es';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CustomizedSwitch from './CustomizedSwitch';
import Loader from 'components/Loader/Loader';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import classNames from 'classnames';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
const styles = require('./styles.pcss');
import TextFieldWithClear from 'components/TextFieldWithIcon/TextFieldWithIcon';

type Props = {
  open: boolean;
  onClose: any;
  onSubmit: any;
  isLoading: boolean;
  disabled?: boolean;
  value?: any;
  hasPermission: any;
  enabled: any;
  setValue: Function;
  setEnabled: Function;
};

const UpdatePopup = (props: Props) => {
  const [errorMessage, setErrorMessage] = useState('');

  useMemo(() => {
    setErrorMessage('');
  }, [props.open]);

  return (
    <Dialog
      maxWidth={'md'}
      fullWidth={true}
      className={styles.dialogBoxUpdate}
      open={props.open}
      onClose={props.onClose}
    >
      <div className={styles.title}>
        Update Advanced Flight Booking
        <IconButton
          color="inherit"
          onClick={props.onClose}
          className={styles.closeIcon}
          aria-label="Close"
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div className={styles.popupBody}>
        <div className={styles.switch}>
          {props.isLoading && (
            <div>
              <Loader visible={props.isLoading} />
            </div>
          )}
          <Table>
            <TableRow
              className={classNames(
                styles.tableRow,
                styles.tableAdvancedFlightRow,
              )}
            >
              <TableCell
                key={'AdvancedFlightBookingKey'}
                className={classNames(styles.tableCell)}
              >
                <div className={styles.tableAdvancedFlightRowHeader}>
                  {'Last-Minute Booking'}
                </div>
                <div className={styles.tableAdvancedFlightRowDescription}>
                  Allow flight bookings up to x hours before take-off
                </div>
              </TableCell>
              <TableCell />
            </TableRow>

            <TableRow
              className={classNames(styles.tableRow, styles.tableRowFlight)}
            >
              <TableCell
                key={'FraudCheck'}
                className={classNames(styles.tableCell)}
              >
                <div className={styles.inputWrapper}>
                  <TextFieldWithClear
                    onChange={(val: any) => props.setValue(val.target.value)}
                    fullWidth
                    label="Value"
                    variant="outlined"
                    margin="normal"
                    value={props.value}
                    onClear={(val: any) => props.setValue('')}
                    disabled={!props.enabled}
                    error={Boolean(errorMessage)}
                    helperText={errorMessage}
                  />
                </div>
              </TableCell>
              <TableCell
                key={'switch'}
                className={classNames(styles.tableCell, styles.buttonCell)}
              >
                <CustomizedSwitch
                  enabled={props.enabled}
                  handleClick={() => props.setEnabled(!props.enabled)}
                  id={'csName'}
                  hasPermission={props.hasPermission}
                />
              </TableCell>
            </TableRow>
          </Table>
        </div>
      </div>

      <div className={styles.confirmationPopupButton}>
        <Button
          className={styles.button}
          variant="contained"
          color="primary"
          onClick={() => {
            isValide(props.value)
              ? (setErrorMessage(''),
                props.onSubmit(parseInt(props.value), props.enabled))
              : setErrorMessage('Invalid value');
          }}
          disabled={props.disabled}
        >
          Save
        </Button>
      </div>
    </Dialog>
  );
};

const isValide = (value: any) => {
  const num = parseInt(value);

  if (Number.isInteger(num) && num > 0) {
    return true;
  }

  return false;
};

export default UpdatePopup;

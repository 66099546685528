import {
  ACTION_POST_FEATURE,
  ACTION_POST_FEATURE_SUCCESS,
  ACTION_POST_FEATURE_FAILURE,
  ACTION_RESET_FEATURES_EXCEPT_LIST,
  API_CALL,
} from '../../../configs/ActionTypes';

import _ from 'lodash-es';
import { track } from '../../../analytics/analytics';

import { Dispatch } from 'redux';

import { enqueueSnackbarHandler } from '../../Status';

export function postFeature(data: any) {
  return (dispatch: Dispatch, getState: Function) => {
    dispatch(requestStarted());
    //Dispatch the api call request.
    dispatch({
      type: API_CALL,
      payload: {
        call: 'feature-flag',
        method: 'POST',
        data: JSON.stringify(data),
        success: (response: any) => {
          if (response.type === 'error') {
            dispatch(requestFailed(response));
          } else {
            enqueueSnackbarHandler(
              dispatch,
              { addFeatureSuccess: true },
              'success',
            );
            dispatch(requestSuccess(response));
            handleTrack(data);
          }
        },
        fail: (error: any) => {
          dispatch(requestFailed(error));
        },
      },
    });
    return null;
  };
}

export function requestStarted() {
  return {
    type: ACTION_POST_FEATURE,
    payload: null,
  };
}

export function requestSuccess(responseJSON: object) {
  return {
    type: ACTION_POST_FEATURE_SUCCESS,
    payload: responseJSON,
  };
}

export function requestFailed(responseJSON: object) {
  return {
    type: ACTION_POST_FEATURE_FAILURE,
    payload: responseJSON,
  };
}

export function resetFeatureExceptList(responseJSON: object) {
  return {
    type: ACTION_RESET_FEATURES_EXCEPT_LIST,
    payload: responseJSON,
  };
}

function handleTrack(data: any) {
  const scopeEmpty = _.isEmpty(_.get(data, 'scope', ''));

  let properties = {
    'Feature Flag': _.get(data, 'name', ''),
    Confidence: _.get(data, 'confidence', ''),
    Scope: scopeEmpty ? 'all' : 'beta',
  };

  track('BB_FeatureFlagCreated', properties);
}

import React from 'react';

type Props = {
  width: number;
  height: number;
};

const SvgIconImportRed = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.93242 7.93348C7.93242 8.44891 7.51452 8.86681 6.99909 8.86681C6.48366 8.86681 6.06576 8.44891 6.06576 7.93348V3.26681C6.06576 2.75138 6.48366 2.33348 6.99909 2.33348C7.51452 2.33348 7.93242 2.75138 7.93242 3.26681V7.93348ZM6.99909 11.6667C6.48366 11.6667 6.06576 11.2488 6.06576 10.7334C6.06576 10.2179 6.48366 9.80003 6.99909 9.80003C7.51452 9.80003 7.93242 10.2179 7.93242 10.7334C7.93242 11.2488 7.51452 11.6667 6.99909 11.6667V11.6667ZM7 0C3.13413 0 0 3.13413 0 7C0 10.8659 3.13413 14 7 14C10.8659 14 14 10.8659 14 7C14 3.13413 10.8659 0 7 0V0Z"
        fill="#FC3B60"
      />
    </svg>
  );
};

export default SvgIconImportRed;

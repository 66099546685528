import * as React from 'react';
import classNames from 'classnames';
import _ from 'lodash-es';
import { navigate } from '@reach/router';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';

import CreditCardTableHead from 'components/CreditCardList/CreditCardTableHead';
import CreditCardTableRow from 'components/CreditCardList/CreditCardTableRow';
import Loader from 'components/Loader/Loader';

import { connect } from 'react-redux';

import { get, emptyResults, Params } from 'actions/CreditCards/GetCreditCards';

import { bindActionCreators, Dispatch } from 'redux';
import { QUERY_LIMIT } from '../../../configs/General';
import { ReduxState, CreditCard } from '../../../reducers/types';

const styles = require('./styles.pcss');

type Props = any;

type State = {
  searchKeyword: string;
};

const appWindow: Window = window;

class CreditCardBody extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      searchKeyword: '',
    };

    this.handleScroll = this.handleScroll.bind(this);
  }

  handleScroll() {
    const body = document.body;
    const html = document.documentElement;

    if (!body || !html) {
      return;
    }

    const windowHeight =
      'innerHeight' in appWindow ? appWindow.innerHeight : html.offsetHeight;

    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight,
    );
    const windowBottom = windowHeight + window.pageYOffset;

    if (
      windowBottom >= docHeight &&
      this.props.isLoading === false &&
      this.props.loadedAll === false
    ) {
      const params: any = {
        limit: QUERY_LIMIT,
        skip: this.props.creditCardList.length,
        ...this.props.searchParams,
      };

      this.props.get(params);
    }
  }

  componentDidMount() {
    appWindow.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    appWindow.removeEventListener('scroll', this.handleScroll);
    this.props.emptyResults();
  }

  renderTableRow = (creditCard: CreditCard, i: number) => {
    return (
      <CreditCardTableRow
        key={i}
        creditCard={creditCard}
      />
    );
  };

  render() {
    if (this.props.creditCardList.length == 0 && this.props.loadedAll) {
      return (
        <Paper className={styles.bodyPaper}>
          <div className={styles.notFound}>No records found</div>
        </Paper>
      );
    }
    if (this.props.creditCardList.length == 0) {
      return <Loader visible={this.props.isLoading} />;
    }
    return (
      <Paper className={styles.bodyPaper}>
        <Loader visible={this.props.isLoading} />
        <Table>
          <CreditCardTableHead />
          <TableBody>
            {this.props.creditCardList.map(this.renderTableRow)}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      get,
      emptyResults,
    },
    dispatch,
  );
}

function mapStateToProps(state: ReduxState) {
  return {
    creditCardList: state.getCreditCards.creditCardList,
    isLoading: state.getCreditCards.isLoading,
    loadedAll: state.getCreditCards.loadedAll,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreditCardBody);

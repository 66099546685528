import * as React from 'react';
import cx from 'classnames';
import * as _ from 'lodash-es';
import Paper from '@material-ui/core/Paper';
import Segment from './Segment';

const styles = require('./styles.pcss');

type Props = {
  segments: any;
  confirmationNumbers: Array<any>;
  locations: Array<any>;
  passengers: Array<any>;
  seatAssignments: Array<any>;
};

class Segments extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Paper className={styles.flightDetails}>
        <div>
          {this.props.segments.map((segment: any, i: any) => {
            return (
              <Segment
                seatAssignments={this.props.seatAssignments}
                segment={segment}
                key={i}
                last={this.props.segments.length - 1 == i}
                confirmationNumbers={this.props.confirmationNumbers}
                locations={this.props.locations}
                passengers={_.get(this.props, 'passengers', [])}
              />
            );
          })}
        </div>
      </Paper>
    );
  }
}

export default Segments;

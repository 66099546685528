import {
  ACTION_GET_MANAGER,
  ACTION_GET_MANAGER_SUCCESS,
  ACTION_GET_MANAGER_FAILURE,
  ACTION_RESET_GET_MANAGER_SUCCESS,
} from '../configs/ActionTypes';

const initialState = {
  managers: [],
  success: null,
  isLoading: false,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_GET_MANAGER: {
      return {
        ...state,
        managers: [],
        isLoading: true,
        success: null,
      };
    }

    case ACTION_GET_MANAGER_SUCCESS: {
      const managers = action.payload.payload;

      return {
        ...state,
        managers: managers,
        success: true,
        isLoading: false,
      };
    }

    case ACTION_GET_MANAGER_FAILURE: {
      return {
        ...state,
        success: false,
        isLoading: false,
      };
    }

    case ACTION_RESET_GET_MANAGER_SUCCESS: {
      return {
        ...state,
        department: [],
        success: null,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
}

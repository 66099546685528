import React, { useState } from 'react';
import cx from 'classnames';
import Paper from '@material-ui/core/Paper';
import CustomSelect from 'components/Inputs/Select/Select';
import { Props, CarbonOffsetProduct } from './types';
import Button from '@material-ui/core/Button';

const styles = require('./styles.pcss');

const CreateUpdateCarbonEmissionProduct: React.FC<Props> = ({
  isLoading = false,
  productId = '',
  products = [],
  onUpdateCarbonEmissionProduct,
}): JSX.Element => {
  const [selectedProduct, onSelectProduct] = useState(productId);

  const onGenerateMenuItems = () => {
    return products.map((product = {} as CarbonOffsetProduct) => {
      const { title, id } = product || {};

      return {
        key: id,
        value: id,
        label: title,
      };
    });
  };

  const onChangeRecordType = (event: any) =>
    onSelectProduct(event.target.value);

  const onAddUpdateCarbonProduct = async () => {
    if (!selectedProduct) {
      return;
    }

    const product = products.find(
      (pr = {} as CarbonOffsetProduct) => pr.id === selectedProduct,
    );

    if (!product) {
      return;
    }

    const { id = '', title = '' } = product || {};
    await onUpdateCarbonEmissionProduct({
      id: 'carbonOffsetProduct',
      value: {
        id,
        title,
      },
    });
  };

  return (
    <Paper
      className={isLoading ? cx(styles.paper, styles.loading) : styles.paper}
    >
      Carbon Emission Offset Product
      <CustomSelect
        margin="normal"
        className={styles.selectWrapper}
        htmlFor="input-type"
        id="value"
        value={selectedProduct}
        onChange={onChangeRecordType}
        inputName="label"
        MenuProps={{
          style: { marginTop: 4, boxShadow: 'none' },
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        menuItems={onGenerateMenuItems()}
      />
      <Button
        size="small"
        className={styles.button}
        variant="contained"
        color="primary"
        onClick={onAddUpdateCarbonProduct}
        id="addUpdateCarbonProduct"
      >
        Update
      </Button>
    </Paper>
  );
};

export default CreateUpdateCarbonEmissionProduct;

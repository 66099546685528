/**
 * GET Users Action
 */

import {
  ACTION_GET_USERS,
  ACTION_GET_USERS_SUCCESS,
  ACTION_GET_USERS_FAILURE,
  ACTION_EMPTY_USERS,
  ACTION_GET_USERS_WITH_RESET_SUCCESS,
  API_CALL,
} from '../../configs/ActionTypes';

import { Dispatch } from 'redux';

import _ from 'lodash-es';

import { enqueueSnackbar } from '../Status';

export type Params = {
  search?: any;
  limit?: number;
  skip?: number;
};

/**
 * POST Users Request Action
 */
export function get(params?: any) {
  return (dispatch: Dispatch, getState: Function) => {
    dispatch(requestStarted());

    //Dispatch the api call request.

    if (params.hasOwnProperty('email')) {
      _.set(
        params,
        'email',
        _.get(params, 'email', '').replace(/[.*+?^${}()|[\]\\]/g, '\\$&'),
      );
    }

    if (params.hasOwnProperty('firstName')) {
      _.set(
        params,
        'firstName',
        _.get(params, 'firstName', '').replace(/[.*+?^${}()|[\]\\]/g, '\\$&'),
      );
    }

    if (params.hasOwnProperty('lastName')) {
      _.set(
        params,
        'lastName',
        _.get(params, 'lastName', '').replace(/[.*+?^${}()|[\]\\]/g, '\\$&'),
      );
    }

    if (params.hasOwnProperty('notes')) {
      _.set(
        params,
        'notes',
        _.get(params, 'notes', '').replace(/[.*+?^${}()|[\]\\]/g, '\\$&'),
      );
    }

    dispatch({
      type: API_CALL,
      payload: {
        call: 'users',
        method: 'get',
        data: params || {},
        success: (response: any) => {
          if (response.type === 'error') {
            let errors = response.payload.payload;
            Object.keys(errors).forEach(function (item) {
              if (errors[item] == true) {
                dispatch(enqueueSnackbar(item, 'error'));
              }
            });
            dispatch(requestFailed(response));
          } else {
            if (params.withReset) {
              dispatch(requestSuccessWithReset(response));
            } else {
              dispatch(requestSuccess(response));
            }
          }
        },
        fail: (error: any) => {
          let errors = error.payload;
          Object.keys(errors).forEach(function (item) {
            if (errors[item] == true) {
              dispatch(enqueueSnackbar(item, 'error'));
            }
          });
          dispatch(requestFailed(error));
        },
      },
    });

    return null;
  };
}

export function emptyResults() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ACTION_EMPTY_USERS,
      payload: null,
    });
  };
}

export function requestStarted() {
  return {
    type: ACTION_GET_USERS,
    payload: null,
  };
}

export function requestSuccess(responseJSON: object) {
  return {
    type: ACTION_GET_USERS_SUCCESS,
    payload: responseJSON,
  };
}

export function requestSuccessWithReset(responseJSON: object) {
  return {
    type: ACTION_GET_USERS_WITH_RESET_SUCCESS,
    payload: responseJSON,
  };
}

export function requestFailed(responseJSON: object) {
  return {
    type: ACTION_GET_USERS_FAILURE,
    payload: responseJSON,
  };
}

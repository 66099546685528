/**
 * PUT EMPLOYEE
 */
import _ from 'lodash-es';
import { Dispatch } from 'redux';

import {
  ACTION_PUT_EMPLOYEE,
  ACTION_PUT_EMPLOYEE_SUCCESS,
  ACTION_PUT_EMPLOYEE_FAILURE,
  ACTION_RESET_PUT_EMPLOYEE_SUCCESS,
  API_CALL,
} from '../../configs/ActionTypes';

import { enqueueSnackbarHandler } from '../Status';

export function update(params: any, organizationId: string, userId: string) {
  return (dispatch: Dispatch, getState: Function) => {
    dispatch(requestStarted());

    dispatch({
      type: API_CALL,
      payload: {
        call: `organizations/${organizationId}/users/${userId}`,
        method: 'put',
        data: JSON.stringify(params),
        success: (response: object) => {
          dispatch(requestSuccess(response));
          enqueueSnackbarHandler(
            dispatch,
            { updateEmployeeSuccess: true },
            'success',
          );
        },
        fail: (error: any) => {
          _.get(error.payload, 'userPermissionError', false)
            ? enqueueSnackbarHandler(dispatch, error.payload, 'error')
            : enqueueSnackbarHandler(
                dispatch,
                { updateEmployeeFailed: true },
                'error',
              );
          dispatch(requestFailed());
        },
      },
    });

    return null;
  };
}

export function reactivate(organizationId: string, userId: any) {
  return (dispatch: Dispatch, getState: Function) => {
    dispatch(requestStarted());

    dispatch({
      type: API_CALL,
      payload: {
        call: `organizations/${organizationId}/reactivate-user`,
        method: 'put',
        data: JSON.stringify(userId),
        success: (response: object) => {
          dispatch(requestSuccess(response));
          enqueueSnackbarHandler(
            dispatch,
            { updateEmployeeSuccess: true },
            'success',
          );
        },
        fail: (error: any) => {
          _.get(error.payload, 'userPermissionError', false)
            ? enqueueSnackbarHandler(dispatch, error.payload, 'error')
            : enqueueSnackbarHandler(
                dispatch,
                { updateEmployeeFailed: true },
                'error',
              );
          dispatch(requestFailed());
        },
      },
    });

    return null;
  };
}

export function requestStarted() {
  return {
    type: ACTION_PUT_EMPLOYEE,
    payload: null,
  };
}

export function resetUpdateEmployee() {
  return (dispatch: Dispatch, getState: Function) => {
    // Dispatch the api call request.
    dispatch({
      type: ACTION_RESET_PUT_EMPLOYEE_SUCCESS,
      payload: null,
    });
  };
}

function requestSuccess(responeJSON: object) {
  return {
    type: ACTION_PUT_EMPLOYEE_SUCCESS,
    payload: responeJSON,
  };
}

function requestFailed() {
  return {
    type: ACTION_PUT_EMPLOYEE_FAILURE,
    payload: null,
  };
}

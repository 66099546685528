import React from 'react';

type Props = {
  width: number;
  height: number;
};

const SvgPowerIcon = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 8 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs></defs>
      <g
        id="(WIP)-Refund-Card"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Booking-Macro---Flight---Flight-Modal"
          transform="translate(-982.000000, -572.000000)"
          fill="#5F6670"
        >
          <g
            id="create-modal"
            transform="translate(370.000000, 143.000000)"
          >
            <g id="content">
              <g
                id="Flight-Card"
                transform="translate(1.000000, 77.000000)"
              >
                <g
                  id="amenities-1"
                  transform="translate(31.000000, 333.000000)"
                >
                  <g
                    id="Group-17"
                    transform="translate(16.000000, 16.000000)"
                  >
                    <g
                      id="Group-16"
                      transform="translate(532.000000, 2.000000)"
                    >
                      <path
                        d="M36.801108,13 L36.801108,10.2963977 C38.6260388,9.94358712 40,8.40704772 40,6.56166171 L40,4.62944693 C40,4.35467236 39.7684211,4.1348527 39.4803324,4.1348527 L38.4897507,4.1348527 L38.4897507,1.74651214 C38.4897507,1.37171962 38.2038781,1.03979194 37.8149584,1.0035217 C37.3684211,0.961755963 36.9916898,1.29698094 36.9916898,1.71683648 L36.9916898,4.1348527 L35.0149584,4.1348527 L35.0149584,1.74651214 C35.0149584,1.37171962 34.7301939,1.03979194 34.3368421,1.0035217 C33.8903047,0.965053258 33.5135734,1.29698094 33.5135734,1.71683648 L33.5135734,4.1348527 L32.5196676,4.1348527 C32.2315789,4.1348527 32,4.35467236 32,4.62944693 L32,6.56166171 C32,8.40704772 33.3750693,9.94358712 35.198892,10.2963977 L35.198892,13 C35.198892,13 36.801108,13 36.801108,13 Z"
                        id="power"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgPowerIcon;

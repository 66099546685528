import {
  ACTION_GET_AGENCIES,
  ACTION_GET_AGENCIES_SUCCESS,
  ACTION_GET_AGENCIES_FAILURE,
  ACTION_EMPTY_AGENCIES,
  ACTION_POST_AGENCY_SUCCESS,
} from '../configs/ActionTypes';

import { QUERY_LIMIT } from '../configs/General';

import { Agency } from './types';

import _ from 'lodash-es';

const initialState = {
  agencies: [],
  isLoading: false,
  loadedAll: false,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_GET_AGENCIES: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ACTION_EMPTY_AGENCIES: {
      return initialState;
    }

    case ACTION_POST_AGENCY_SUCCESS: {
      const agency = action.payload.payload;
      let stateAgencies: any = _.clone(state.agencies);
      stateAgencies.push(agency);

      return {
        ...state,
        agencies: stateAgencies,
      };
    }

    case ACTION_GET_AGENCIES_SUCCESS: {
      let agencies: Array<Agency> = _.clone(state.agencies);
      let loadedAll = state.loadedAll;

      const payload = action.payload.payload;

      if (payload.length < QUERY_LIMIT) {
        loadedAll = true;
      }

      _.each(payload, (t) => {
        const agency: Agency = {
          id: t.id,
          name: t.name,
          friendlyName: t.friendlyName,
          appHost: t.appHost,
          settings: _.get(t, 'settings', []),
          uiSettings: _.get(t, 'uiSettings', []),
        };

        agencies.push(agency);
      });

      return {
        ...state,
        agencies: agencies,
        loadedAll: loadedAll,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
}

import {
  ACTION_GET_DEPARTMENT,
  ACTION_GET_DEPARTMENT_SUCCESS,
  ACTION_GET_DEPARTMENT_FAILURE,
  ACTION_RESET_DEPARTMENT_SUCCESS,
} from '../configs/ActionTypes';

const initialState = {
  departments: [],
  success: null,
  isLoading: false,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_GET_DEPARTMENT: {
      return {
        ...state,
        departments: [],
        isLoading: true,
        success: null,
      };
    }

    case ACTION_GET_DEPARTMENT_SUCCESS: {
      const departments = action.payload.payload;

      return {
        ...state,
        departments: departments,
        success: true,
        isLoading: false,
      };
    }

    case ACTION_GET_DEPARTMENT_FAILURE: {
      return {
        ...state,
        success: false,
        isLoading: false,
      };
    }

    case ACTION_RESET_DEPARTMENT_SUCCESS: {
      return {
        ...state,
        department: [],
        success: null,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
}

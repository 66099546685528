declare global {
    interface Window { analytics: window.analytics; }
}

import _ from 'lodash-es';

export const SEGMENT_USERID_PREFIX = 'bbuser_';

export const track = (name, properties) => {

  let user = window.analytics.user();

  if (user.id() === null) {

    identify();
  }

  window.analytics.track(name, properties);

}

export const identify = () => {

  const loginData = (localStorage.getItem('login_data'));
  let parsedLoginData = JSON.parse(loginData);
  let userId = _.get(parsedLoginData, 'userId', '');
  let userRole = _.get(parsedLoginData, 'userRole', '');

  if (userId !== '' && userId !== undefined && userId !== null  ) {
    window.analytics.identify(`${SEGMENT_USERID_PREFIX}${userId}`, {Role: userRole});
  }

}

/**
 * We run identify after user login, but before saving login data to localStorage
 * We have to pass userId and userRole by parameters to identify. So needed a separate function for this.
 */
export const identifyPublic = (id = null, userRole = null) => {

  if (id !== null) {
    window.analytics.identify(`${SEGMENT_USERID_PREFIX}${id}`, {Role: userRole});
  }

}

export const reset = () => {

  window.analytics.reset();
}

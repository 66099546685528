import React, { Component } from 'react';
import cx from 'classnames';

import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = require('./styles.pcss');

type Props = {
  open: boolean;
  onClose: any;
  onSubmit: any;
  title?: any;
  bodyText?: any;
  submitButtonText?: string;
  bodyTextStyle?: any;
};

const InfoPopup = (props: Props) => {
  const bodytextStyle = props.bodyTextStyle
    ? cx(styles.body, props.bodyTextStyle)
    : cx(styles.body);
  return (
    <Dialog
      maxWidth={'sm'}
      fullWidth={true}
      className={cx(styles.dialogBox, styles.confirmationDialog)}
      open={props.open}
      onClose={props.onClose}
    >
      <div className={cx(styles.title, styles.titleFontSizePopupConf)}>
        {props.title}
      </div>

      <div className={bodytextStyle}>{props.bodyText}</div>
      <div className={cx(styles.content, styles.infoPopupButton)}>
        <Button
          className={styles.button}
          variant="contained"
          color="primary"
          onClick={props.onSubmit}
        >
          {props.submitButtonText || `OK`}
        </Button>
      </div>
    </Dialog>
  );
};

export default InfoPopup;

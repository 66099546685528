export const API_CALL = 'API_CALL';

export const ACTION_REDIRECT_LOGIN = 'ACTION_REDIRECT_LOGIN';
export const REDIRECT_LOGIN = 'ACTION_REDIRECT_LOGIN';
export const REDIRECT_NO_ACCESS = 'REDIRECT_NO_ACCESS';
export const RESET_NO_ACCESS = 'RESET_NO_ACCESS';

export const ACTION_STATUS_SHOW = 'ACTION_STATUS_SHOW';
export const ACTION_STATUS_CLEAR = 'ACTION_STATUS_CLEAR';

export const ACTION_POST_LOGIN_SUCCESS = 'ACTION_POST_LOGIN_SUCCESS';
export const ACTION_POST_LOGIN_FAILURE = 'ACTION_POST_LOGIN_FAILURE';
export const ACTION_LOGOUT_SUCCESS = 'ACTION_LOGOUT_SUCCESS';

export const ACTION_GET_TRAVELERS = 'ACTION_GET_TRAVELERS';
export const ACTION_GET_TRAVELERS_SUCCESS = 'ACTION_GET_TRAVELERS_SUCCESS';
export const ACTION_GET_TRAVELERS_FAILURE = 'ACTION_GET_TRAVELERS_FAILURE';
export const ACTION_GET_TRAVELER = 'ACTION_GET_TRAVELER';
export const ACTION_GET_TRAVELER_SUCCESS = 'ACTION_GET_TRAVELER_SUCCESS';
export const ACTION_GET_TRAVELER_FAILURE = 'ACTION_GET_TRAVELER_FAILURE';
export const ACTION_EMPTY_TRAVELERS = 'ACTION_EMPTY_TRAVELERS';

export const ACTION_GET_USERS = 'ACTION_GET_USERS';
export const ACTION_GET_USERS_SUCCESS = 'ACTION_GET_USERS_SUCCESS';
export const ACTION_GET_USERS_FAILURE = 'ACTION_GET_USERS_FAILURE';
export const ACTION_GET_USER_SUCCESS = 'ACTION_GET_USER_SUCCESS';
export const ACTION_GET_USER_FAILURE = 'ACTION_GET_USER_FAILURE';
export const ACTION_EMPTY_USERS = 'ACTION_EMPTY_USERS';

export const ACTION_GET_COMPANIES = 'ACTION_GET_COMPANIES';
export const ACTION_GET_COMPANIES_SUCCESS = 'ACTION_GET_COMPANIES_SUCCESS';
export const ACTION_GET_COMPANIES_FAILURE = 'ACTION_GET_COMPANIES_FAILURE';
export const ACTION_EMPTY_COMPANIES = 'ACTION_EMPTY_COMPANIES';

export const ACTION_POST_COMPANY = 'ACTION_POST_COMPANY';
export const ACTION_POST_COMPANY_SUCCESS = 'ACTION_POST_COMPANY_SUCCESS';
export const ACTION_POST_COMPANY_FAILURE = 'ACTION_POST_COMPANY_FAILURE';
export const ACTION_RESET_COMPANY_SUCCESS = 'ACTION_RESET_COMPANY_SUCCESS';
export const ACTION_GET_COMPANY = 'ACTION_GET_COMPANY';
export const ACTION_GET_COMPANY_SUCCESS = 'ACTION_GET_COMPANY_SUCCESS';
export const ACTION_GET_COMPANY_FAILURE = 'ACTION_GET_COMPANY_FAILURE';
export const ACTION_EMPTY_COMPANY = 'ACTION_EMPTY_COMPANY';

export const ACTION_GET_PLACES = 'ACTION_GET_PLACES';
export const ACTION_GET_PLACES_SUCCESS = 'ACTION_GET_PLACES_SUCCESS';
export const ACTION_GET_PLACES_FAILURE = 'ACTION_GET_PLACES_FAILURE';
export const ACTION_EMPTY_PLACES = 'ACTION_EMPTY_PLACES';

export const ACTION_FILTER_HOTELS = 'ACTION_FILTER_HOTELS';
export const ACTION_FILTER_HOTELS_SUCCESS = 'ACTION_FILTER_HOTELS_SUCCESS';

export const ACTION_SEARCH_HOTELS = 'ACTION_SEARCH_HOTELS';
export const ACTION_SEARCH_HOTELS_SUCCESS = 'ACTION_SEARCH_HOTELS_SUCCESS';
export const ACTION_SEARCH_HOTELS_FAILURE = 'ACTION_SEARCH_HOTELS_FAILURE';
export const ACTION_EMPTY_HOTELS = 'ACTION_EMPTY_HOTELS';

export const ACTION_IMPORT_EMPLOYEES_VALIDATE =
  'ACTION_IMPORT_EMPLOYEES_VALIDATE';
export const ACTION_IMPORT_EMPLOYEES_VALIDATE_SUCCESS =
  'ACTION_IMPORT_EMPLOYEES_VALIDATE_SUCCESS';
export const ACTION_IMPORT_EMPLOYEES_VALIDATE_FAILURE =
  'ACTION_IMPORT_EMPLOYEES_VALIDATE_FAILURE';
export const ACTION_IMPORT_EMPLOYEES_RESET = 'ACTION_IMPORT_EMPLOYEES_RESET';
export const ACTION_IMPORT_EMPLOYEES_CONFIRM_SUCCESS =
  'ACTION_IMPORT_EMPLOYEES_CONFIRM_SUCCESS';
export const ACTION_IMPORT_EMPLOYEES_CONFIRM_FAILURE =
  'ACTION_IMPORT_EMPLOYEES_CONFIRM_FAILURE';
export const ACTION_IMPORT_EMPLOYEES_CONFIRM =
  'ACTION_IMPORT_EMPLOYEES_CONFIRM;';

export const ACTION_GET_PROFILE_SUCCESS = 'ACTION_GET_PROFILE_SUCCESS';
export const ACTION_SAVE_PROFILE_SUCCESS = 'ACTION_SAVE_PROFILE_SUCCESS';
export const ACTION_SAVE_PROFILE_FAILURE = 'ACTION_SAVE_PROFILE_FAILURE';
export const ACTION_SAVE_PROFILE_RESET_ERRORS =
  'ACTION_SAVE_PROFILE_RESET_ERRORS';
export const ACTION_RESET_PASSWORD_SUCCESS = 'ACTION_RESET_PASSWORD_SUCCESS';
export const ACTION_RESET_PASSWORD_FAILURE = 'ACTION_RESET_PASSWORD_FAILURE';
export const ACTION_RESET_PASSWORD_RESET_STATES =
  'ACTION_RESET_PASSWORD_RESET_STATES';

export const ACTION_GET_BOOKINGS = 'ACTION_GET_BOOKINGS';
export const ACTION_GET_BOOKINGS_SUCCESS = 'ACTION_GET_BOOKINGS_SUCCESS';
export const ACTION_GET_BOOKINGS_FAILURE = 'ACTION_GET_BOOKINGS_FAILURE';
export const ACTION_EMPTY_BOOKINGS = 'ACTION_EMPTY_BOOKINGS';

export const ACTION_UPDATE_TRAVELER = 'ACTION_UPDATE_TRAVELER';
export const ACTION_UPDATE_TRAVELER_SUCCESS = 'ACTION_UPDATE_TRAVELER_SUCCESS';
export const ACTION_UPDATE_TRAVELER_FAILURE = 'ACTION_UPDATE_TRAVELER_FAILURE';

export const ACTION_GET_FREQUENT_FLYERS_SUCCESS =
  'ACTION_GET_FREQUENT_FLYERS_SUCCESS';

export const ACTION_SET_HOTEL_ROOMS_REQUEST_PARAM =
  'ACTION_SET_HOTEL_ROOMS_REQUEST_PARAM';
export const ACTION_GET_HOTEL_DETAILS = 'ACTION_GET_HOTEL_DETAILS';
export const ACTION_GET_HOTEL_DETAILS_SUCCESS =
  'ACTION_GET_HOTEL_DETAILS_SUCCESS';
export const ACTION_GET_HOTEL_DETAILS_FAILURE =
  'ACTION_GET_HOTEL_DETAILS_FAILURE';
export const ACTION_GET_HOTEL_ROOMS = 'ACTION_GET_HOTEL_ROOMS';
export const ACTION_GET_HOTEL_ROOMS_SUCCESS = 'ACTION_GET_HOTEL_ROOMS_SUCCESS';
export const ACTION_GET_HOTEL_ROOMS_FAILURE = 'ACTION_GET_HOTEL_ROOMS_FAILURE';
export const ACTION_SELECT_HOTEL_ROOM = 'ACTION_SELECT_HOTEL_ROOM';

export const ACTION_ADD_TRAVELER = 'ACTION_ADD_TRAVELER';
export const ACTION_ADD_TRAVELER_SUCCESS = 'ACTION_ADD_TRAVELER_SUCCESS';
export const ACTION_ADD_TRAVELER_FAILURE = 'ACTION_ADD_TRAVELER_FAILURE';
export const ACTION_POST_CREDITCARD = 'ACTION_POST_CREDITCARD';
export const ACTION_POST_CREDITCARD_SUCCESS = 'ACTION_POST_CREDITCARD_SUCCESS';
export const ACTION_POST_CREDITCARD_FAILURE = 'ACTION_POST_CREDITCARD_FAILURE';
export const ACTION_RESET_CREDITCARD = 'ACTION_RESET_CREDITCARD';
export const ACTION_PUT_CREDITCARD = 'ACTION_PUT_CREDITCARD';
export const ACTION_PUT_CREDITCARD_SUCCESS = 'ACTION_PUT_CREDITCARD_SUCCESS';
export const ACTION_PUT_CREDITCARD_FAILURE = 'ACTION_PUT_CREDITCARD_FAILURE';
export const ACTION_RESET_EDIT_CREDIT_CARD_SUCCESS =
  'ACTION_RESET_EDIT_CREDIT_CARD_SUCCESS';

export const ACTION_DELETE_CREDITCARD = 'ACTION_DELETE_CREDITCARD';
export const ACTION_DELETE_CREDITCARD_SUCCESS =
  'ACTION_DELETE_CREDITCARD_SUCCESS';
export const ACTION_DELETE_CREDITCARD_FAILURE =
  'ACTION_DELETE_CREDITCARD_FAILURE';
export const ACTION_RESET_DELETE_CREDITCARD = 'ACTION_RESET_DELETE_CREDITCARD';

export const ACTION_RESET_TRAVELER_SAVE_SUCCEEDED =
  'ACTION_RESET_TRAVELER_SAVE_SUCCEEDED';
export const ACTION_EMPTY_TRAVELER = 'ACTION_EMPTY_TRAVELER';

export const ACTION_POST_BOOKING = 'ACTION_POST_BOOKING';
export const ACTION_POST_BOOKING_SUCCESS = 'ACTION_POST_BOOKING_SUCCESS';
export const ACTION_POST_BOOKING_FAILURE = 'ACTION_POST_BOOKING_FAILURE';
export const ACTION_RESET_POST_BOOKING = 'ACTION_RESET_POST_BOOKING';
export const ACTION_RESET_HOTEL_ROOMS_BOOKING_DETAILS =
  'ACTION_RESET_HOTEL_ROOMS_BOOKING_DETAILS';

export const ACTION_GET_CREDITCARDS = 'ACTION_GET_CREDITCARDS';
export const ACTION_GET_CREDITCARDS_SUCCESS = 'ACTION_GET_CREDITCARDS_SUCCESS';
export const ACTION_GET_CREDITCARDS_FAILURE = 'ACTION_GET_CREDITCARDS_FAILURE';
export const ACTION_RESET_GET_CREDITCARDS = 'ACTION_RESET_GET_CREDITCARDS';
export const ACTION_RESET_POST_BOOKING_ERRORS =
  'ACTION_RESET_POST_BOOKING_ERRORS';

export const ACTION_GET_USER_TRAVEL_POLICY_RULES =
  'ACTION_GET_USER_TRAVEL_POLICY_RULES';
export const ACTION_GET_USER_TRAVEL_POLICY_RULES_SUCCESS =
  'ACTION_GET_USER_TRAVEL_POLICY_RULES_SUCCESS';
export const ACTION_GET_USER_TRAVEL_POLICY_RULES_FAILURE =
  'ACTION_GET_USER_TRAVEL_POLICY_RULES_FAILURE';
export const ACTION_EMPTY_USER_TRAVEL_POLICY_RULES =
  'ACTION_EMPTY_USER_TRAVEL_POLICY_RULES';
export const ACTION_EMPTY_USER_TRAVEL_POLICY_SUCCESS_PROPERTY =
  'ACTION_EMPTY_USER_TRAVEL_POLICY_SUCCESS_PROPERTY';

export const ACTION_GET_FEATURES = 'ACTION_GET_FEATURES';
export const ACTION_GET_FEATURES_SUCCESS = 'ACTION_GET_FEATURES_SUCCESS';
export const ACTION_GET_FEATURES_FAILURE = 'ACTION_GET_FEATURES_FAILURE';
export const ACTION_EMPTY_FEATURES = 'ACTION_EMPTY_FEATURES';
export const ACTION_RESET_FEATURES = 'ACTION_RESET_FEATURES';

export const ACTION_PUT_FEATURE = 'ACTION_PUT_FEATURE';
export const ACTION_PUT_FEATURES_SUCCESS = 'ACTION_PUT_FEATURES_SUCCESS';
export const ACTION_PUT_FEATURES_FAILURE = 'ACTION_PUT_FEATURES_FAILURE';

export const ACTION_PUT_COMPANY = 'ACTION_PUT_COMPANY';
export const ACTION_PUT_COMPANY_SUCCESS = 'ACTION_PUT_COMPANY_SUCCESS';
export const ACTION_PUT_COMPANY_FAILURE = 'ACTION_PUT_COMPANY_FAILURE';

export const ACTION_GET_USER = 'ACTION_GET_USER';

export const ACTION_GET_ORGANIZATIONS_PRODUCTS_SETTINGS =
  'ACTION_GET_ORGANIZATIONS_PRODUCTS_SETTINGS';
export const ACTION_GET_ORGANIZATIONS_PRODUCTS_SETTINGS_SUCCESS =
  'ACTION_GET_ORGANIZATIONS_PRODUCTS_SETTINGS_SUCCESS';
export const ACTION_GET_ORGANIZATIONS_PRODUCTS_SETTINGS_FAILURE =
  'ACTION_GET_ORGANIZATIONS_PRODUCTS_SETTINGS_FAILURE';

export const ACTION_PUT_ORGANIZATIONS_PRODUCTS_SETTINGS =
  'ACTION_PUT_ORGANIZATIONS_PRODUCTS_SETTINGS';
export const ACTION_PUT_ORGANIZATIONS_PRODUCTS_SETTINGS_SUCCESS =
  'ACTION_PUT_ORGANIZATIONS_PRODUCTS_SETTINGS_SUCCESS';
export const ACTION_PUT_ORGANIZATIONS_PRODUCTS_SETTINGS_FAILURE =
  'ACTION_PUT_ORGANIZATIONS_PRODUCTS_SETTINGS_FAILURE';

export const ACTION_GET_CORPORATE_RATE_CODES =
  'ACTION_GET_CORPORATE_RATE_CODES';
export const ACTION_GET_CORPORATE_RATE_CODES_SUCCESS =
  'ACTION_GET_CORPORATE_RATE_CODES_SUCCESS';
export const ACTION_GET_CORPORATE_RATE_CODES_FAILURE =
  'ACTION_GET_CORPORATE_RATE_CODES_FAILURE';

export const ACTION_PUT_CORPORATE_RATE_CODES =
  'ACTION_PUT_CORPORATE_RATE_CODES';
export const ACTION_PUT_CORPORATE_RATE_CODES_SUCCESS =
  'ACTION_PUT_CORPORATE_RATE_CODES_SUCCESS';
export const ACTION_PUT_CORPORATE_RATE_CODES_FAILURE =
  'ACTION_PUT_CORPORATE_RATE_CODES_FAILURE';

export const ACTION_PUT_USER_STATUS = 'ACTION_PUT_USER_STATUS';
export const ACTION_PUT_USER_STATUS_SUCCESS = 'ACTION_PUT_USER_STATUS_SUCCESS';
export const ACTION_PUT_USER_STATUS_FAILURE = 'ACTION_PUT_USER_STATUS_FAILURE';
export const ACTION_PUT_USER_STATUS_RESET = 'ACTION_PUT_USER_STATUS_RESET';
export const ACTION_UPDATE_COMPANY_EMPLOYEES =
  'ACTION_UPDATE_COMPANY_EMPLOYEES';

export const ACTION_EMPTY_CREDITCARDS = 'ACTION_EMPTY_CREDITCARDS';
export const ACTION_RESET_GET_CREDIT_CARDS = 'ACTION_RESET_GET_CREDIT_CARDS';

export const ACTION_PUT_USER = 'ACTION_PUT_USER';
export const ACTION_PUT_USER_SUCCESS = 'ACTION_PUT_USER_SUCCESS';
export const ACTION_PUT_USER_FAILURE = 'ACTION_PUT_USER_FAILURE';
export const ACTION_PUT_USER_RESET = 'ACTION_PUT_USER_RESET';

export const ACTION_GET_BOOKING = 'ACTION_GET_BOOKING';
export const ACTION_GET_BOOKING_SUCCESS = 'ACTION_GET_BOOKING_SUCCESS';
export const ACTION_GET_BOOKING_FAILURE = 'ACTION_GET_BOOKING_FAILURE';
export const ACTION_EMPTY_BOOKING = 'ACTION_EMPTY_BOOKING';
export const ACTION_GET_USERS_WITH_RESET_SUCCESS =
  'ACTION_GET_USERS_WITH_RESET_SUCCESS';

export const ACTION_BOOKINGS_SEND_CONFIRMATION =
  'ACTION_BOOKINGS_SEND_CONFIRMATION';
export const ACTION_BOOKINGS_SEND_CONFIRMATION_SUCCESS =
  'ACTION_BOOKINGS_SEND_CONFIRMATION_SUCCESS';
export const ACTION_BOOKINGS_SEND_CONFIRMATION_FAILURE =
  'ACTION_BOOKINGS_SEND_CONFIRMATION_FAILURE';

export const ACTION_GET_USER_CREDITCARDS = 'ACTION_GET_USER_CREDITCARDS';
export const ACTION_GET_USER_CREDITCARDS_SUCCESS =
  'ACTION_GET_USER_CREDITCARDS_SUCCESS';
export const ACTION_GET_USER_CREDITCARDS_FAILURE =
  'ACTION_GET_USER_CREDITCARDS_FAILURE';

export const ACTION_GET_APPROVERS = 'ACTION_GET_APPROVERS';
export const ACTION_GET_APPROVERS_SUCCESS = 'ACTION_GET_APPROVERS_SUCCESS';
export const ACTION_GET_APPROVERS_FAILURE = 'ACTION_GET_APPROVERS_FAILURE';
export const ACTION_EMPTY_APPROVERS = 'ACTION_EMPTY_APPROVERS';

export const ACTION_PUT_REIMBURSEMENT_FEATURE =
  'ACTION_PUT_REIMBURSEMENT_FEATURE';
export const ACTION_PUT_REIMBURSEMENT_FEATURE_SUCCESS =
  'ACTION_PUT_REIMBURSEMENT_FEATURE_SUCCESS';
export const ACTION_PUT_REIMBURSEMENT_FEATURE_FAILURE =
  'ACTION_PUT_REIMBURSEMENT_FEATURE_FAILURE';

export const ACTION_GET_REIMBURSEMENT_FEATURE =
  'ACTION_GET_REIMBURSEMENT_FEATURE';
export const ACTION_GET_REIMBURSEMENT_FEATURE_SUCCESS =
  'ACTION_GET_REIMBURSEMENT_FEATURE_SUCCESS';
export const ACTION_GET_REIMBURSEMENT_FEATURE_FAILURE =
  'ACTION_GET_REIMBURSEMENT_FEATURE_FAILURE';

export const ACTION_GET_NEGOTIATED_HOTELS = 'ACTION_GET_NEGOTIATED_HOTELS';
export const ACTION_GET_NEGOTIATED_HOTELS_SUCCESS =
  'ACTION_GET_NEGOTIATED_HOTELS_SUCCESS';
export const ACTION_GET_NEGOTIATED_HOTELS_FAILURE =
  'ACTION_GET_NEGOTIATED_HOTELS_FAILURE';

export const ACTION_PUT_NEGOTIATED_HOTELS = 'ACTION_PUT_NEGOTIATED_HOTELS';
export const ACTION_PUT_NEGOTIATED_HOTELS_SUCCESS =
  'ACTION_PUT_NEGOTIATED_HOTELS_SUCCESS';
export const ACTION_PUT_NEGOTIATED_HOTELS_FAILURE =
  'ACTION_PUT_NEGOTIATED_HOTELS_FAILURE';

export const ACTION_DELETE_NEGOTIATED_HOTELS =
  'ACTION_DELETE_NEGOTIATED_HOTELS';
export const ACTION_DELETE_NEGOTIATED_HOTELS_SUCCESS =
  'ACTION_DELETE_NEGOTIATED_HOTELS_SUCCESS';
export const ACTION_DELETE_NEGOTIATED_HOTELS_FAILURE =
  'ACTION_DELETE_NEGOTIATED_HOTELS_FAILURE';

export const ACTION_PUT_BOOKINGS_CUSTOM_TAG = 'ACTION_PUT_BOOKINGS_CUSTOM_TAG';
export const ACTION_PUT_BOOKINGS_CUSTOM_TAG_SUCCESS =
  'ACTION_PUT_BOOKINGS_CUSTOM_TAG_SUCCESS';
export const ACTION_PUT_BOOKINGS_CUSTOM_TAG_FAILURE =
  'ACTION_PUT_BOOKINGS_CUSTOM_TAG_FAILURE';

export const ACTION_GET_EXCLUSIVE_HOTELS = 'ACTION_GET_EXCLUSIVE_HOTELS';
export const ACTION_GET_EXCLUSIVE_HOTELS_SUCCESS =
  'ACTION_GET_EXCLUSIVE_HOTELS_SUCCESS';
export const ACTION_GET_EXCLUSIVE_HOTELS_FAILURE =
  'ACTION_GET_EXCLUSIVE_HOTELS_FAILURE';
export const ACTION_RESET_EXCLUSIVE_HOTELS = 'ACTION_RESET_EXCLUSIVE_HOTELS';

export const ACTION_PUT_EXCLUSIVE_HOTEL = 'ACTION_PUT_EXCLUSIVE_HOTEL';
export const ACTION_PUT_EXCLUSIVE_HOTEL_SUCCESS =
  'ACTION_PUT_EXCLUSIVE_HOTEL_SUCCESS';
export const ACTION_PUT_EXCLUSIVE_HOTEL_FAILURE =
  'ACTION_PUT_EXCLUSIVE_HOTEL_FAILURE';
export const ACTION_RESET_EXCLUSIVE_HOTELS_SUCCESS =
  'ACTION_RESET_EXCLUSIVE_HOTELS_SUCCESS';

export const ACTION_DELETE_EXCLUSIVE_HOTEL = 'ACTION_DELETE_EXCLUSIVE_HOTEL';
export const ACTION_DELETE_EXCLUSIVE_HOTEL_SUCCESS =
  'ACTION_DELETE_EXCLUSIVE_HOTEL_SUCCESS';
export const ACTION_DELETE_EXCLUSIVE_HOTEL_FAILURE =
  'ACTION_DELETE_EXCLUSIVE_HOTEL_FAILURE';

export const ACTION_POST_FEATURE = 'ACTION_POST_FEATURE';
export const ACTION_POST_FEATURE_SUCCESS = 'ACTION_POST_FEATURE_SUCCESS';
export const ACTION_POST_FEATURE_FAILURE = 'ACTION_POST_FEATURE_FAILURE';
export const ACTION_RESET_FEATURES_EXCEPT_LIST =
  'ACTION_RESET_FEATURES_EXCEPT_LIST';

export const ACTION_PUT_FEATURES_SUCCESS_MANAGE =
  'ACTION_PUT_FEATURES_SUCCESS_MANAGE';
export const ACTION_AFTER_RESET_PASSWORD_SUCCESS =
  'ACTION_AFTER_RESET_PASSWORD_SUCCESS';

export const ACTION_GET_USER_FRAUD_RULE = 'ACTION_GET_USER_FRAUD_RULE';
export const ACTION_GET_USER_FRAUD_RULE_SUCCESS =
  'ACTION_GET_USER_FRAUD_RULE_SUCCESS';
export const ACTION_GET_USER_FRAUD_RULE_FAILURE =
  'ACTION_GET_USER_FRAUD_RULE_FAILURE';

export const ACTION_PUT_USER_FRAUD_RULE = 'ACTION_PUT_USER_FRAUD_RULE';
export const ACTION_PUT_USER_FRAUD_RULE_SUCCESS =
  'ACTION_PUT_USER_FRAUD_RULE_SUCCESS';
export const ACTION_PUT_USER_FRAUD_RULE_FAILURE =
  'ACTION_PUT_USER_FRAUD_RULE_FAILURE';

export const ACTION_GET_ORGANIZATION_FRAUD_RULE =
  'ACTION_GET_ORGANIZATION_FRAUD_RULE';
export const ACTION_GET_ORGANIZATION_FRAUD_RULE_SUCCESS =
  'ACTION_GET_ORGANIZATION_FRAUD_RULE_SUCCESS';
export const ACTION_GET_ORGANIZATION_FRAUD_RULE_FAILURE =
  'ACTION_GET_ORGANIZATION_FRAUD_RULE_FAILURE';
export const ACTION_PUT_ORGANIZATION_FRAUD_RULE =
  'ACTION_PUT_ORGANIZATION_FRAUD_RULE';
export const ACTION_PUT_ORGANIZATION_FRAUD_RULE_SUCCESS =
  'ACTION_PUT_ORGANIZATION_FRAUD_RULE_SUCCESS';
export const ACTION_PUT_ORGANIZATION_FRAUD_RULE_FAILURE =
  'ACTION_PUT_ORGANIZATION_FRAUD_RULE_FAILURE';

export const ACTION_POST_BOOKING_NEW_PAYMENT =
  'ACTION_POST_BOOKING_NEW_PAYMENT';
export const ACTION_POST_BOOKING_NEW_PAYMENT_SUCCESS =
  'ACTION_POST_BOOKING_NEW_PAYMENT_SUCCESS';
export const ACTION_POST_BOOKING_NEW_PAYMENT_FAILURE =
  'ACTION_POST_BOOKING_NEW_PAYMENT_FAILURE';
export const ACTION_RESET_POST_BOOKING_NEW_PAYMENT =
  'ACTION_RESET_POST_BOOKING_NEW_PAYMENT';
export const ACTION_RESET_POST_BOOKING_NEW_PAYMENT_ERRORS =
  'ACTION_RESET_POST_BOOKING_NEW_PAYMENT_ERRORS';

export const ACTION_POST_REFUND_BOOKING = 'ACTION_POST_REFUND_BOOKING';
export const ACTION_POST_REFUND_BOOKING_SUCCESS =
  'ACTION_POST_REFUND_BOOKING_SUCCESS';
export const ACTION_POST_REFUND_BOOKING_FAILURE =
  'ACTION_POST_REFUND_BOOKING_FAILURE';
export const ACTION_RESET_POST_REFUND_BOOKING =
  'ACTION_RESET_POST_REFUND_BOOKING';

export const ACTION_PUT_REIMBURSEMENT_SETTINGS_FEATURE =
  'ACTION_PUT_REIMBURSEMENT_SETTINGS_FEATURE';
export const ACTION_PUT_REIMBURSEMENT_SETTINGS_FEATURE_SUCCESS =
  'ACTION_PUT_REIMBURSEMENT_SETTINGS_FEATURE_SUCCESS';
export const ACTION_PUT_REIMBURSEMENT_SETTINGS_FEATURE_FAILURE =
  'ACTION_PUT_REIMBURSEMENT_SETTINGS_FEATURE_FAILURE';

export const ACTION_GET_REIMBURSEMENT_SETTINGS_FEATURE =
  'ACTION_GET_REIMBURSEMENT_SETTINGS_FEATURE';
export const ACTION_GET_REIMBURSEMENT_SETTINGS_FEATURE_SUCCESS =
  'ACTION_GET_REIMBURSEMENT_SETTINGS_FEATURE_SUCCESS';
export const ACTION_GET_REIMBURSEMENT_SETTINGS_FEATURE_FAILURE =
  'ACTION_GET_REIMBURSEMENT_SETTINGS_FEATURE_FAILURE';

export const ACTION_POST_EMPLOYEE_SUCCESS = 'ACTION_POST_EMPLOYEE_SUCCESS';
export const ACTION_POST_EMPLOYEE_FAILURE = 'ACTION_POST_EMPLOYEE_FAILURE';
export const ACTION_RESET_EMPLOYEE_SUCCESS = 'ACTION_RESET_EMPLOYEE_SUCCESS';
export const ACTION_POST_EMPLOYEE = 'ACTION_POST_EMPLOYEE';

export const ACTION_GET_BASIC_ECONOMY_FEATURE =
  'ACTION_GET_BASIC_ECONOMY_FEATURE';
export const ACTION_GET_BASIC_ECONOMY_FEATURE_SUCCESS =
  'ACTION_GET_BASIC_ECONOMY_FEATURE_SUCCESS';
export const ACTION_GET_BASIC_ECONOMY_FEATURE_FAILURE =
  'ACTION_GET_BASIC_ECONOMY_FEATURE_FAILURE';
export const ACTION_PUT_BASIC_ECONOMY_FEATURE =
  'ACTION_PUT_BASIC_ECONOMY_FEATURE';
export const ACTION_PUT_BASIC_ECONOMY_FEATURE_SUCCESS =
  'ACTION_PUT_BASIC_ECONOMY_FEATURE_SUCCESS';
export const ACTION_PUT_BASIC_ECONOMY_FEATURE_FAILURE =
  'ACTION_PUT_BASIC_ECONOMY_FEATURE_FAILURE';

export const ACTION_PUT_EMPLOYEE = 'ACTION_PUT_EMPLOYEE';
export const ACTION_PUT_EMPLOYEE_SUCCESS = 'ACTION_PUT_EMPLOYEE_SUCCESS';
export const ACTION_PUT_EMPLOYEE_FAILURE = 'ACTION_PUT_EMPLOYEE_FAILURE';
export const ACTION_RESET_PUT_EMPLOYEE_SUCCESS =
  'ACTION_RESET_PUT_EMPLOYEE_SUCCESS';

export const ACTION_GET_ACTIVE_EXPENSE_INTEGRATION =
  'ACTION_GET_ACTIVE_EXPENSE_INTEGRATION';
export const ACTION_GET_ACTIVE_EXPENSE_INTEGRATION_SUCCESS =
  'ACTION_GET_ACTIVE_EXPENSE_INTEGRATION_SUCCESS';
export const ACTION_GET_ACTIVE_EXPENSE_INTEGRATION_FAILURE =
  'ACTION_GET_ACTIVE_EXPENSE_INTEGRATION_FAILURE';

export const ACTION_GET_BOOKING_CANCEL_INFO = 'ACTION_GET_BOOKING_CANCEL_INFO';
export const ACTION_GET_BOOKING_CANCEL_INFO_SUCCESS =
  'ACTION_GET_BOOKING_CANCEL_INFO_SUCCESS';
export const ACTION_GET_BOOKING_CANCEL_INFO_FAILURE =
  'ACTION_GET_BOOKING_CANCEL_INFO_FAILURE';
export const ACTION_EMPTY_BOOKING_CANCEL_INFO =
  'ACTION_EMPTY_BOOKING_CANCEL_INFO';

export const ACTION_PUT_CANCEL_BOOKING = 'ACTION_PUT_CANCEL_BOOKING';
export const ACTION_PUT_CANCEL_BOOKING_SUCCESS =
  'ACTION_PUT_CANCEL_BOOKING_SUCCESS';
export const ACTION_PUT_CANCEL_BOOKING_FAILURE =
  'ACTION_PUT_CANCEL_BOOKING_FAILURE';
export const ACTION_EMPTY_PUT_CANCEL_BOOKING =
  'ACTION_EMPTY_PUT_CANCEL_BOOKING';

export const ACTION_GET_AGENCIES = 'ACTION_GET_AGENCIES';
export const ACTION_GET_AGENCIES_SUCCESS = 'ACTION_GET_AGENCIES_SUCCESS';
export const ACTION_GET_AGENCIES_FAILURE = 'ACTION_GET_AGENCIES_FAILURE';
export const ACTION_EMPTY_AGENCIES = 'ACTION_EMPTY_AGENCIES';

export const ACTION_GET_AGENCY = 'ACTION_GET_AGENCY';
export const ACTION_GET_AGENCY_SUCCESS = 'ACTION_GET_AGENCY_SUCCESS';
export const ACTION_GET_AGENCY_FAILURE = 'ACTION_GET_AGENCY_FAILURE';
export const ACTION_EMPTY_AGENCY = 'ACTION_EMPTY_AGENCY';

export const ACTION_PUT_AGENCY = 'ACTION_PUT_AGENCY';
export const ACTION_PUT_AGENCY_SUCCESS = 'ACTION_PUT_AGENCY_SUCCESS';
export const ACTION_PUT_AGENCY_FAILURE = 'ACTION_PUT_AGENCY_FAILURE';

export const ACTION_GET_ORG_AGENCY_SETTING = 'ACTION_GET_ORG_AGENCY_SETTING';
export const ACTION_GET_ORG_AGENCY_SETTING_SUCCESS =
  'ACTION_GET_ORG_AGENCY_SETTING_SUCCESS';
export const ACTION_GET_ORG_AGENCY_SETTING_FAILURE =
  'ACTION_GET_ORG_AGENCY_SETTING_FAILURE';

export const ACTION_PUT_ORG_AGENCY_SETTING = 'ACTION_PUT_ORG_AGENCY_SETTING';
export const ACTION_PUT_ORG_AGENCY_SETTING_SUCCESS =
  'ACTION_PUT_ORG_AGENCY_SETTING_SUCCESS';
export const ACTION_PUT_ORG_AGENCY_SETTING_FAILURE =
  'ACTION_PUT_ORG_AGENCY_SETTING_FAILURE';

export const ACTION_GET_ACTIVE_EXPENSE_MANAGEMENT =
  'ACTION_GET_ACTIVE_EXPENSE_MANAGEMENT';
export const ACTION_GET_ACTIVE_EXPENSE_MANAGEMENT_SUCCESS =
  'ACTION_GET_ACTIVE_EXPENSE_MANAGEMENT_SUCCESS';
export const ACTION_GET_ACTIVE_EXPENSE_MANAGEMENT_FAILURE =
  'ACTION_GET_ACTIVE_EXPENSE_MANAGEMENT_FAILURE';

export const ACTION_GET_BB_USERS = 'ACTION_GET_BB_USERS';
export const ACTION_GET_BB_USERS_SUCCESS = 'ACTION_GET_BB_USERS_SUCCESS';
export const ACTION_GET_BB_USERS_FAILURE = 'ACTION_GET_BB_USERS_FAILURE';
export const ACTION_RESET_BB_USERS = 'ACTION_RESET_BB_USERS';

export const ACTION_PUT_AGENCY_COLOR = 'ACTION_PUT_AGENCY_COLOR';

export const ACTION_GET_BB_AGENCY_PERMISSIONS =
  'ACTION_GET_BB_AGENCY_PERMISSIONS';
export const ACTION_GET_BB_AGENCY_PERMISSIONS_SUCCESS =
  'ACTION_GET_BB_AGENCY_PERMISSIONS_SUCCESS';
export const ACTION_GET_BB_AGENCY_PERMISSIONS_FAILURE =
  'ACTION_GET_BB_AGENCY_PERMISSIONS_FAILURE';

export const ACTION_GET_DEFAULT_EXPENSE_POLICY =
  'ACTION_GET_DEFAULT_EXPENSE_POLICY';
export const ACTION_GET_DEFAULT_EXPENSE_POLICY_SUCCESS =
  'ACTION_GET_DEFAULT_EXPENSE_POLICY_SUCCESS';
export const ACTION_GET_DEFAULT_EXPENSE_POLICY_FAILURE =
  'ACTION_GET_DEFAULT_EXPENSE_POLICY_FAILURE';

export const ACTION_PUT_EXPENSE_POLICY = 'ACTION_PUT_EXPENSE_POLICY';
export const ACTION_PUT_EXPENSE_POLICY_SUCCESS =
  'ACTION_PUT_EXPENSE_POLICY_SUCCESS';
export const ACTION_PUT_EXPENSE_POLICY_FAILURE =
  'ACTION_PUT_EXPENSE_POLICY_FAILURE';

export const ACTION_GET_CAR_LOYALTY_PROGRAM = 'ACTION_GET_CAR_LOYALTY_PROGRAM';
export const ACTION_GET_CAR_LOYALTY_PROGRAM_SUCCESS =
  'ACTION_GET_CAR_LOYALTY_PROGRAM_SUCCESS';
export const ACTION_GET_CAR_LOYALTY_PROGRAM_FAILURE =
  'ACTION_GET_CAR_LOYALTY_PROGRAM_FAILURE';
export const ACTION_EMPTY_CAR_LOYALTY_PROGRAM =
  'ACTION_EMPTY_CAR_LOYALTY_PROGRAM';

export const ACTION_GET_ORGANIZATION_BANK_ACCOUNT =
  'ACTION_GET_ORGANIZATION_BANK_ACCOUNT';
export const ACTION_GET_ORGANIZATION_BANK_ACCOUNT_SUCCESS =
  'ACTION_GET_ORGANIZATION_BANK_ACCOUNT_SUCCESS';
export const ACTION_GET_ORGANIZATION_BANK_ACCOUNT_FAILURE =
  'ACTION_GET_ORGANIZATION_BANK_ACCOUNT_FAILURE';

export const ACTION_GET_ROLES = 'ACTION_GET_ROLES';
export const ACTION_GET_ROLES_SUCCESS = 'ACTION_GET_ROLES_SUCCESS';
export const ACTION_GET_ROLES_FAILURE = 'ACTION_GET_ROLES_FAILURE';
export const ACTION_EMPTY_ROLES = 'ACTION_EMPTY_ROLES';

export const ACTION_PUT_ORG_BANK_ACCOUNT = 'ACTION_PUT_ORG_BANK_ACCOUNT';
export const ACTION_PUT_ORG_BANK_ACCOUNT_SUCCESS =
  'ACTION_PUT_ORG_BANK_ACCOUNT_SUCCESS';
export const ACTION_PUT_ORG_BANK_ACCOUNT_FAILURE =
  'ACTION_PUT_ORG_BANK_ACCOUNT_FAILURE';
export const ACTION_RESET_ORG_BANK_ACCOUNT_ERROR =
  'ACTION_RESET_ORG_BANK_ACCOUNT_ERROR';

export const ACTION_GET_USER_BANK_ACCOUNT = 'ACTION_GET_USER_BANK_ACCOUNT';
export const ACTION_GET_USER_BANK_ACCOUNT_SUCCESS =
  'ACTION_GET_USER_BANK_ACCOUNT_SUCCESS';
export const ACTION_GET_USER_BANK_ACCOUNT_FAILURE =
  'ACTION_GET_USER_BANK_ACCOUNT_FAILURE';

export const ACTION_GET_ORG_REIMBURSEMENT_FEE_SETTING =
  'ACTION_GET_ORG_REIMBURSEMENT_FEE_SETTING';
export const ACTION_GET_ORG_REIMBURSEMENT_FEE_SETTING_SUCCESS =
  'ACTION_GET_ORG_REIMBURSEMENT_FEE_SETTING_SUCCESS';
export const ACTION_GET_ORG_REIMBURSEMENT_FEE_SETTING_FAILURE =
  'ACTION_GET_ORG_REIMBURSEMENT_FEE_SETTING_FAILURE';

export const ACTION_POST_BB_USER = 'ACTION_POST_BB_USER';
export const ACTION_POST_BB_USER_SUCCESS = 'ACTION_POST_BB_USER_SUCCESS';
export const ACTION_POST_BB_USER_FAILURE = 'ACTION_POST_BB_USER_FAILURE';
export const ACTION_RESET_BB_USER = 'ACTION_RESET_BB_USER';

export const ACTION_GET_COMPANY_EMPLOYEES = 'ACTION_GET_COMPANY_EMPLOYEES';
export const ACTION_GET_COMPANY_EMPLOYEES_SUCCESS =
  'ACTION_GET_COMPANY_EMPLOYEES_SUCCESS';
export const ACTION_GET_COMPANY_EMPLOYEES_FAILURE =
  'ACTION_GET_COMPANY_EMPLOYEES_FAILURE';
export const ACTION_EMPTY_COMPANY_EMPLOYEES = 'ACTION_EMPTY_COMPANY_EMPLOYEES';

export const ACTION_PUT_USER_ORG_ANALYTICS = 'ACTION_PUT_USER_ORG_ANALYTICS';
export const ACTION_PUT_USER_ORG_ANALYTICS_SUCCESS =
  'ACTION_PUT_USER_ORG_ANALYTICS_SUCCESS';
export const ACTION_PUT_USER_ORG_ANALYTICS_FAILURE =
  'ACTION_PUT_USER_ORG_ANALYTICS_FAILURE';
export const ACTION_PUT_USER_ORG_ANALYTICS_RESET =
  'ACTION_PUT_USER_ORG_ANALYTICS_RESET';

export const ACTION_PUT_FLIGT_TOUR_CODES = 'ACTION_PUT_FLIGT_TOUR_CODES';
export const ACTION_PUT_FLIGT_TOUR_CODES_SUCCESS =
  'ACTION_PUT_FLIGT_TOUR_CODES_SUCCESS';
export const ACTION_PUT_FLIGT_TOUR_CODES_FAILURE =
  'ACTION_PUT_FLIGT_TOUR_CODES_FAILURE';
export const ACTION_GET_FLIGT_TOUR_CODES = 'ACTION_GET_FLIGT_TOUR_CODES';
export const ACTION_GET_FLIGT_TOUR_CODES_SUCCESS =
  'ACTION_GET_FLIGT_TOUR_CODES_SUCCESS';
export const ACTION_GET_FLIGT_TOUR_CODES_FAILURE =
  'ACTION_GET_FLIGT_TOUR_CODES_FAILURE';

export const ACTION_GET_USER_BUDGET_POLICY_RULES =
  'ACTION_GET_USER_BUDGET_POLICY_RULES';
export const ACTION_GET_USER_BUDGET_POLICY_RULES_SUCCESS =
  'ACTION_GET_USER_BUDGET_POLICY_RULES_SUCCESS';
export const ACTION_GET_USER_BUDGET_POLICY_RULES_FAILURE =
  'ACTION_GET_USER_BUDGET_POLICY_RULES_FAILURE';
export const ACTION_EMPTY_USER_BUDGET_POLICY_RULES =
  'ACTION_EMPTY_USER_BUDGET_POLICY_RULES';

export const ACTION_DELETE_FEATURE = 'ACTION_DELETE_FEATURE';
export const ACTION_DELETE_FEATURES_SUCCESS = 'ACTION_DELETE_FEATURES_SUCCESS';
export const ACTION_DELETE_FEATURES_FAILURE = 'ACTION_DELETE_FEATURES_FAILURE';

export const ACTION_UPDATE_BB_USER = 'ACTION_UPDATE_BB_USER';
export const ACTION_UPDATE_BB_USER_SUCCESS = 'ACTION_UPDATE_BB_USER_SUCCESS';
export const ACTION_UPDATE_BB_USER_FAILURE = 'ACTION_UPDATE_BB_USER_FAILURE';
export const ACTION_RESET_UPDATE_BB_USER = 'ACTION_RESET_BB_USER';

export const ACTION_POST_AGENCY = 'ACTION_POST_AGENCY';
export const ACTION_POST_AGENCY_SUCCESS = 'ACTION_POST_AGENCY_SUCCESS';
export const ACTION_POST_AGENCY_FAILURE = 'ACTION_POST_AGENCY_FAILURE';

export const ACTION_GET_COMPANY_EMPLOYEES_SIMPLE =
  'ACTION_GET_COMPANY_EMPLOYEES_SIMPLE';
export const ACTION_GET_COMPANY_EMPLOYEES_SIMPLE_SUCCESS =
  'ACTION_GET_COMPANY_EMPLOYEES_SIMPLE_SUCCESS';
export const ACTION_GET_COMPANY_EMPLOYEES_SIMPLE_FAILURE =
  'ACTION_GET_COMPANY_EMPLOYEES_SIMPLE_FAILURE';
export const ACTION_EMPTY_COMPANY_EMPLOYEES_SIMPLE =
  'ACTION_EMPTY_COMPANY_EMPLOYEES_SIMPLE';

export const ACTION_GET_USER_EXPENSE_REPORTS =
  'ACTION_GET_USER_EXPENSE_REPORTS';
export const ACTION_GET_USER_EXPENSE_REPORTS_SUCCESS =
  'ACTION_GET_USER_EXPENSE_REPORTS_SUCCESS';
export const ACTION_GET_USER_EXPENSE_REPORTS_FAILURE =
  'ACTION_GET_USER_EXPENSE_REPORTS_FAILURE';

export const ACTION_PUT_USER_EXPENSE_REPORTS =
  'ACTION_PUT_USER_EXPENSE_REPORTS';
export const ACTION_PUT_USER_EXPENSE_REPORTS_SUCCESS =
  'ACTION_PUT_USER_EXPENSE_REPORTS_SUCCESS';
export const ACTION_PUT_USER_EXPENSE_REPORTS_FAILURE =
  'ACTION_PUT_USER_EXPENSE_REPORTS_FAILURE';

export const ACTION_POST_DEPARTMENT = 'ACTION_POST_DEPARTMENT';
export const ACTION_POST_DEPARTMENT_SUCCESS = 'ACTION_POST_DEPARTMENT_SUCCESS';
export const ACTION_POST_DEPARTMENT_FAILURE = 'ACTION_POST_DEPARTMENT_FAILURE';
export const ACTION_RESET_DEPARTMENT_SUCCESS =
  'ACTION_RESET_DEPARTMENT_SUCCESS';

export const ACTION_GET_MANAGER = 'ACTION_GET_MANAGER';
export const ACTION_GET_MANAGER_SUCCESS = 'ACTION_GET_MANAGER_SUCCESS';
export const ACTION_GET_MANAGER_FAILURE = 'ACTION_GET_MANAGER_FAILURE';
export const ACTION_RESET_GET_MANAGER_SUCCESS =
  'ACTION_RESET_GET_MANAGER_SUCCESS';

export const ACTION_PUT_FLIGHT_BOOKING_WINDOW_FEATURE =
  'ACTION_PUT_FLIGHT_BOOKING_WINDOW_FEATURE';
export const ACTION_PUT_FLIGHT_BOOKING_WINDOW_FEATURE_SUCCESS =
  'ACTION_PUT_FLIGHT_BOOKING_WINDOW_FEATURE_SUCCESS';
export const ACTION_PUT_FLIGHT_BOOKING_WINDOW_FEATURE_FAILURE =
  'ACTION_PUT_FLIGHT_BOOKING_WINDOW_FEATURE_FAILURE';

export const ACTION_GET_FLIGHT_BOOKING_WINDOW_FEATURE =
  'ACTION_GET_FLIGHT_BOOKING_WINDOW_FEATURE';
export const ACTION_GET_FLIGHT_BOOKING_WINDOW_FEATURE_SUCCESS =
  'ACTION_GET_FLIGHT_BOOKING_WINDOW_FEATURE_SUCCESS';
export const ACTION_GET_FLIGHT_BOOKING_WINDOW_FEATURE_FAILURE =
  'ACTION_GET_FLIGHT_BOOKING_WINDOW_FEATURE_FAILURE';

export const ACTION_DELETE_FLIGHT_BOOKING_WINDOW_FEATURE =
  'ACTION_DELETE_FLIGHT_BOOKING_WINDOW_FEATURE';
export const ACTION_DELETE_FLIGHT_BOOKING_WINDOW_FEATURE_SUCCESS =
  'ACTION_DELETE_FLIGHT_BOOKING_WINDOW_FEATURE_SUCCESS';
export const ACTION_DELETE_FLIGHT_BOOKING_WINDOW_FEATURE_FAILURE =
  'ACTION_DELETE_FLIGHT_BOOKING_WINDOW_FEATURE_FAILURE';

export const ACTION_GET_DEPARTMENT = 'ACTION_GET_DEPARTMENT';
export const ACTION_GET_DEPARTMENT_SUCCESS = 'ACTION_GET_DEPARTMENT_SUCCESS';
export const ACTION_GET_DEPARTMENT_FAILURE = 'ACTION_GET_DEPARTMENT_FAILURE';
export const ACTION_RESET_GET_DEPARTMENT_SUCCESS =
  'ACTION_RESET_GET_DEPARTMENT_SUCCESS';

export const ACTION_PUT_UPDATE_BOOKING = 'ACTION_PUT_UPDATE_BOOKING';
export const ACTION_PUT_UPDATE_BOOKING_SUCCESS =
  'ACTION_PUT_UPDATE_BOOKING_SUCCESS';
export const ACTION_PUT_UPDATE_BOOKING_FAILURE =
  'ACTION_PUT_UPDATE_BOOKING_FAILURE';

export const ACTION_DUFFEL_SYNC_BOOKING = 'ACTION_DUFFEL_SYNC_BOOKING';
export const ACTION_DUFFEL_SYNC_BOOKING_SUCCESS =
  'ACTION_DUFFEL_SYNC_BOOKING_SUCCESS';
export const ACTION_DUFFEL_SYNC_BOOKING_FAILURE =
  'ACTION_DUFEEL_SYNC_BOOKING_FAILURE';

export const ACTION_GET_USER_FLIGHTCREDITS = 'ACTION_GET_USER_FLIGHTCREDITS';
export const ACTION_GET_USER_FLIGHTCREDITS_SUCCESS =
  'ACTION_GET_USER_FLIGHTCREDITS_SUCCESS';
export const ACTION_GET_USER_FLIGHTCREDITS_FAILURE =
  'ACTION_GET_USER_FLIGHTCREDITS_FAILURE';

export const ACTION_UPDATE_USER_FLIGHTCREDITS =
  'ACTION_UPDATE_USER_FLIGHTCREDITS';
export const ACTION_UPDATE_USER_FLIGHTCREDITS_SUCCESS =
  'ACTION_UPDATE_USER_FLIGHTCREDITS_SUCCESS';
export const ACTION_UPDATE_USER_FLIGHTCREDITS_FAILURE =
  'ACTION_UPDATE_USER_FLIGHTCREDITS_FAILURE';

export const ACTION_PUT_EXPENSE_SPLIT_SETTING =
  'ACTION_PUT_EXPENSE_SPLIT_SETTING';
export const ACTION_PUT_EXPENSE_SPLIT_SETTING_SUCCESS =
  'ACTION_PUT_EXPENSE_SPLIT_SETTING_SUCCESS';
export const ACTION_PUT_EXPENSE_SPLIT_SETTING_FAILURE =
  'ACTION_PUT_EXPENSE_SPLIT_SETTING_FAILURE';

export const ACTION_GET_EXPENSE_SPLIT_SETTING =
  'ACTION_GET_EXPENSE_SPLIT_SETTING';
export const ACTION_GET_EXPENSE_SPLIT_SETTING_SUCCESS =
  'ACTION_GET_EXPENSE_SPLIT_SETTING_SUCCESS';
export const ACTION_GET_EXPENSE_SPLIT_SETTING_FAILURE =
  'ACTION_GET_EXPENSE_SPLIT_SETTING_FAILURE';

export const ACTION_GET_VERIFIED_FOR_TRAVEL_SETTINGS =
  'ACTION_GET_VERIFIED_FOR_TRAVEL_FEATURE';
export const ACTION_GET_VERIFIED_FOR_TRAVEL_SETTINGS_SUCCESS =
  'ACTION_GET_VERIFIED_FOR_TRAVEL_FEATURE_SUCCESS';
export const ACTION_GET_VERIFIED_FOR_TRAVEL_SETTINGS_FAILURE =
  'ACTION_GET_VERIFIED_FOR_TRAVEL_FEATURE_FAILURE';

export const ACTION_PUT_VERIFIED_FOR_TRAVEL_SETTINGS =
  'ACTION_PUT_VERIFIED_FOR_TRAVEL_SETTINGS';
export const ACTION_PUT_VERIFIED_FOR_TRAVEL_SETTINGS_SUCCESS =
  'ACTION_PUT_VERIFIED_FOR_TRAVEL_SETTINGS_SUCCESS';
export const ACTION_PUT_VERIFIED_FOR_TRAVEL_SETTINGS_FAILURE =
  'ACTION_PUT_VERIFIED_FOR_TRAVEL_SETTINGS_FAILURE';

export const ACTION_DELETE_VERIFIED_FOR_TRAVEL_SETTINGS =
  'ACTION_DELETE_VERIFIED_FOR_TRAVEL_FEATURE';
export const ACTION_DELETE_VERIFIED_FOR_TRAVEL_SETTINGS_SUCCESS =
  'ACTION_DELETE_VERIFIED_FOR_TRAVEL_SETTINGS_SUCCESS';
export const ACTION_DELETE_VERIFIED_FOR_TRAVEL_SETTINGS_FAILURE =
  'ACTION_DELETE_VERIFIED_FOR_TRAVEL_SETTINGS_FAILURE';

export const ACTION_GET_LICENSE_PRICING = 'ACTION_GET_LICENSE_PRICING';
export const ACTION_GET_LICENSE_PRICING_SUCCESS =
  'ACTION_GET_LICENSE_PRICING_SUCCESS';
export const ACTION_GET_LICENSE_PRICING_FAILURE =
  'ACTION_GET_LICENSE_PRICING_FAILURE';

export const ACTION_RESET_LICENSE_PRICING = 'ACTION_RESET_LICENSE_PRICING';
export const ACTION_RESET_LICENSE_PRICING_SUCCESS =
  'ACTION_RESET_LICENSE_PRICING_SUCCESS';
export const ACTION_RESET_LICENSE_PRICING_FAILURE =
  'ACTION_RESET_LICENSE_PRICING_FAILURE';

export const ACTION_UPDATE_LICENSE_PRICING = 'ACTION_UPDATE_LICENSE_PRICING';
export const ACTION_UPDATE_LICENSE_PRICING_SUCCESS =
  'ACTION_UPDATE_LICENSE_PRICING_SUCCESS';
export const ACTION_UPDATE_LICENSE_PRICING_FAILURE =
  'ACTION_UPDATE_LICENSE_PRICING_FAILURE';

export const ACTION_BOOKINGS_SYNC_DUFFEL_BOOKING =
  'ACTION_BOOKINGS_SYNC_DUFFEL_BOOKING';
export const ACTION_BOOKINGS_SYNC_DUFFEL_BOOKING_SUCCESS =
  'ACTION_BOOKINGS_SYNC_DUFFEL_BOOKING_SUCCESS';
export const ACTION_BOOKINGS_SYNC_DUFFEL_BOOKING_FAILURE =
  'ACTION_BOOKINGS_SYNC_DUFFEL_BOOKING_FAILURE';

export const ACTION_GET_ORG_SPECIAL_RATE_CODES =
  'ACTION_GET_ORG_SPECIAL_RATE_CODES';
export const ACTION_GET_ORG_SPECIAL_RATE_CODES_FAILURE =
  'ACTION_GET_ORG_SPECIAL_RATE_CODES_FAILURE';
export const ACTION_GET_ORG_SPECIAL_RATE_CODES_SUCCESS =
  'ACTION_GET_ORG_SPECIAL_RATE_CODES_SUCCESS';

export const ACTION_PUT_ORG_SPECIAL_RATE_CODES =
  'ACTION_PUT_ORG_SPECIAL_RATE_CODES';
export const ACTION_PUT_ORG_SPECIAL_RATE_CODES_FAILURE =
  'ACTION_PUT_ORG_SPECIAL_RATE_CODES_FAILURE';
export const ACTION_PUT_ORG_SPECIAL_RATE_CODES_SUCCESS =
  'ACTION_PUT_ORG_SPECIAL_RATE_CODES_SUCCESS';

export const ACTION_GET_CARBON_EMISSION_PRODUCTS =
  'ACTION_GET_CARBON_EMISSION_PRODUCTS';
export const ACTION_GET_CARBON_EMISSION_PRODUCTS_FAILURE =
  'ACTION_GET_CARBON_EMISSION_PRODUCTS_FAILURE';
export const ACTION_GET_CARBON_EMISSION_PRODUCTS_SUCCESS =
  'ACTION_GET_CARBON_EMISSION_PRODUCTS_SUCCESS';

export const ACTION_UPDATE_CARBON_EMISSION_PRODUCT =
  'ACTION_UPDATE_CARBON_EMISSION_PRODUCT';
export const ACTION_UPDATE_CARBON_EMISSION_PRODUCT_FAILURE =
  'ACTION_UPDATE_CARBON_EMISSION_PRODUCT_FAILURE';
export const ACTION_UPDATE_CARBON_EMISSION_PRODUCT_SUCCESS =
  'ACTION_UPDATE_CARBON_EMISSION_PRODUCT_SUCCESS';

export const ACTION_GET_CARBON_EMISSION_ORG_PRODUCT =
  'ACTION_GET_CARBON_EMISSION_ORG_PRODUCT';
export const ACTION_GET_CARBON_EMISSION_ORG_PRODUCT_FAILURE =
  'ACTION_GET_CARBON_EMISSION_ORG_PRODUCT_FAILURE';
export const ACTION_GET_CARBON_EMISSION_ORG_PRODUCT_SUCCESS =
  'ACTION_GET_CARBON_EMISSION_ORG_PRODUCT_SUCCESS';

export const ACTION_GET_ORG_SETTINGS = 'ACTION_GET_ORG_SETTINGS';
export const ACTION_GET_ORG_SETTINGS_SUCCESS =
  'ACTION_GET_ORG_SETTINGS_SUCCESS';
export const ACTION_GET_ORG_SETTINGS_FAILURE =
  'ACTION_GET_ORG_SETTINGS_FAILURE';

export const ACTION_POST_BOOKING_FLIGHTCREDITS =
  'ACTION_POST_BOOKING_FLIGHTCREDITS';
export const ACTION_POST_BOOKING_FLIGHTCREDITS_SUCCESS =
  'ACTION_POST_BOOKING_FLIGHTCREDITS_SUCCESS';
export const ACTION_POST_BOOKING_FLIGHTCREDITS_FAILURE =
  'ACTION_POST_BOOKING_FLIGHTCREDITS_FAILURE';

export const ACTION_PUT_PAID_PLAN = 'ACTION_PUT_PAID_PLAN';
export const ACTION_PUT_PAID_PLAN_SUCCESS = 'ACTION_PUT_PAID_PLAN_SUCCESS';
export const ACTION_PUT_PAID_PLAN_FAILURE = 'ACTION_PUT_PAID_PLAN_FAILURE';
  
export const ACTION_GET_PAID_PLAN = 'ACTION_GET_PAID_PLAN';
export const ACTION_GET_PAID_PLAN_SUCCESS = 'ACTION_GET_PAID_PLAN_SUCCESS';
export const ACTION_GET_PAID_PLAN_FAILURE = 'ACTION_GET_PAID_PLAN_FAILURE';
import * as React from 'react';
import _ from 'lodash-es';
import cx from 'classnames';
import moment from 'moment';

import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import AutoSuggest from 'components/Inputs/AutoSuggest/AutoSuggest';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import MultiSelect from 'components/Inputs/MultiSelect/MultiSelect';
import FormHelperText from '@material-ui/core/FormHelperText';
import {
  TextMaskCC,
  TextMaskCVC,
  TextMaskExpiry,
} from '../TravelerDetails/MaskedFields/MaskedFields';
import TextFieldWithClear from 'components/TextFieldWithIcon/TextFieldWithIcon';
import OutlinedInputWithDelete from 'components/TextFieldWithIcon/OutlinedInputWithDelete';
import DatePicker from 'components/DatePickers/DatePicker';
import Loader from 'components/Loader/Loader';
import CustomSelect from 'components/Inputs/Select/Select';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconRadioChecked from '../../static/icons/svgRadioChecked';
import IconRadio from '../../static/icons/svgRadio';

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ReduxState } from 'reducers/types';
import { add, resetAddCreditCard } from 'actions/CreditCards/Add';
import {
  getOrganizationEmployeesSimple,
  emptyResults,
} from 'actions/Companies/GetOrganizationEmployeesSimple';

const requiredPostalCountries = require('../../helpers/requiredPostalCountries.js');
const styles = require('./styles.pcss');
const requiredRegionCountries = [
  'United States',
  'Canada',
  'Australia',
  'Brazil',
  'Japan',
  'Mexico',
];

import { states } from '../../helpers/States.js';
import { provinces } from '../../helpers/Provinces.js';
import { countries } from '../../helpers/Countries.js';

type Props = {
  userId?: string;
  organizationId?: string;
  add: any;
  resetAddCreditCard: any;
  getOrganizationEmployeesSimple: any;
  emptyResults: any;
  addCreditCard: any;
  employeesSimple: any;
  departments?: any;
  users?: any;
  onSuccess?: any;
  in_process: any;
  open: boolean;
  onClose: any;
  title?: any;
  description?: any;
};

type State = {
  name: any;
  ccNumber: any;
  expiry: any;
  cvc: any;
  country: any;
  postalCode: any;
  nameError: any;
  ccNumberError: any;
  expiryError: any;
  cvcError: any;
  countryError: any;
  postalCodeError: any;
  userError: any;
  departments: Array<any>;
  zipCodeRequired: Boolean;
  user: any;
  employees: string;
  errorDepartments: string;
  regionError: any;
  region: string;
  city: string;
  cityError: any;
  streetAddress: string;
  streetAddressError: any;
  streetAddress2: string;
};

class AddCreditCardPopup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = this._getInitialState();
    this.props.emptyResults();

    if (!this.props.userId) {
      this.props.getOrganizationEmployeesSimple(this.props.organizationId);
    }
  }

  _getInitialState = () => {
    return {
      name: '',
      ccNumber: '',
      expiry: '',
      cvc: '',
      country: 'United States',
      postalCode: '',
      nameError: '',
      ccNumberError: '',
      expiryError: '',
      cvcError: '',
      countryError: '',
      postalCodeError: '',
      userError: '',
      departments: [],
      zipCodeRequired: true,
      user: '',
      employees: 'all',
      errorDepartments: '',
      region: '',
      regionError: '',
      city: '',
      cityError: '',
      streetAddress: '',
      streetAddressError: '',
      streetAddress2: '',
    };
  };

  componentWillReceiveProps = (nextProps: Props) => {
    if (_.get(nextProps.addCreditCard, 'success', null) === false) {
      this.setState({
        nameError: _.get(nextProps.addCreditCard, 'errors.name', ''),
        ccNumberError: _.get(nextProps.addCreditCard, 'errors.ccNumber', ''),
        expiryError: _.get(nextProps.addCreditCard, 'errors.expiry', ''),
        cvcError: _.get(nextProps.addCreditCard, 'errors.cvc', ''),
        countryError: _.get(nextProps.addCreditCard, 'errors.country', ''),
        postalCodeError: _.get(
          nextProps.addCreditCard,
          'errors.postalCode',
          '',
        ),
        cityError: _.get(nextProps.addCreditCard, 'errors.city', ''),
        streetAddressError: _.get(
          nextProps.addCreditCard,
          'errors.streetAddress',
          '',
        ),
        regionError: _.get(nextProps.addCreditCard, 'errors.state', ''),
      });
    } else if (_.get(nextProps.addCreditCard, 'success', null) === true) {
      this.props.onSuccess();
      this.props.resetAddCreditCard();
      this.setState(this._getInitialState());
    }
  };

  renderLoader = () => {
    if (this.props.in_process || this.props.employeesSimple.isLoading) {
      return (
        <div className={styles.loaderContainer}>
          <Loader visible={true} />
        </div>
      );
    }

    return null;
  };

  onChangeName = (event: any) => {
    this.setState({
      name: event.target.value,
      nameError: '',
    });
  };

  onChangeCcNumber = (event: any) => {
    this.setState({
      ccNumber: event.target.value,
      ccNumberError: '',
    });
  };

  onChangeExpiry = (event: any) => {
    this.setState({
      expiry: event.target.value,
      expiryError: '',
    });
  };

  onChangeCvc = (event: any) => {
    this.setState({
      cvc: event.target.value,
      cvcError: '',
    });
  };

  onChangeCountryAuto = (country: any) => {
    let zipCodeRequired =
      requiredPostalCountries.requiredPostalCountries.includes(country);

    this.setState({
      region: '',
      country: country,
      countryError: '',
      zipCodeRequired,
    });
  };

  onChangeRegion = (region: any) => {
    this.setState({
      region: region,
      regionError: '',
    });
  };

  onChangeRegionText = (event: any) => {
    this.setState({
      region: event.target.value,
      regionError: '',
    });
  };

  onChangePostalCode = (event: any) => {
    this.setState({
      postalCode: event.target.value,
      postalCodeError: '',
    });
  };

  onChangeUser = (event: any) => {
    this.setState({
      user: event.target.value,
      userError: '',
    });
  };

  onChangeStreetAddress = (event: any) => {
    this.setState({
      streetAddress: event.target.value,
      streetAddressError: '',
    });
  };

  onChangeStreetAddress2 = (event: any) => {
    this.setState({
      streetAddress2: event.target.value,
    });
  };

  onChangeCity = (event: any) => {
    this.setState({
      city: event.target.value,
      cityError: '',
    });
  };

  onDeleteStreetAddress = () => {
    this.setState({
      streetAddress: '',
      streetAddressError: '',
    });
  };

  onDeleteStreetAddress2 = () => {
    this.setState({
      streetAddress2: '',
    });
  };

  onDeleteCity = () => {
    this.setState({
      city: '',
      cityError: '',
    });
  };

  onDeleteName = () => {
    this.setState({
      name: '',
      nameError: '',
    });
  };

  onDeletePostalCode = () => {
    this.setState({
      postalCode: '',
      postalCodeError: '',
    });
  };

  onDeleteCreditCardNumber = () => {
    this.setState({
      ccNumber: '',
      ccNumberError: '',
    });
  };

  onDeleteExpiry = () => {
    this.setState({
      expiry: '',
      expiryError: '',
    });
  };

  onDeletecvc = () => {
    this.setState({
      cvc: '',
      cvcError: '',
    });
  };

  onDeleteRegion = () => {
    this.setState({
      regionError: '',
      region: '',
    });
  };

  validFields = () => {
    let isValid = true;
    let nameError = '';
    let ccNumberError = '';
    let expiryError = '';
    let cvcError = '';
    let countryError = '';
    let postalCodeError = '';
    let userError = '';
    let errorDepartments = '';
    let regionError = '';
    let cityError = '';
    let streetAddressError = '';
    const onlyLettersAndSpaces = RegExp(/^[A-Za-z][A-Za-z\s]*$/);

    if (
      _.get(this.state, 'region.label', this.state.region).trim() === '' &&
      requiredRegionCountries.indexOf(this.state.country.trim()) !== -1
    ) {
      regionError = 'Required';
      isValid = false;
    }

    if (
      !_.isEmpty(this.state.name) &&
      !onlyLettersAndSpaces.test(this.state.name)
    ) {
      nameError = 'No special characters';
      isValid = false;
    }

    if (this.state.name.trim() === '') {
      nameError = 'Required';
      isValid = false;
    }

    if (this.state.ccNumber.trim() === '') {
      ccNumberError = 'Required';
      isValid = false;
    }

    if (this.state.expiry.trim() === '') {
      expiryError = 'Required';
      isValid = false;
    } else if (!moment(this.state.expiry, 'MM/YYYY').isValid()) {
      expiryError = 'Invalid credit card expiry';
      isValid = false;
    }

    if (this.state.city.trim() === '') {
      cityError = 'Required';
      isValid = false;
    }

    if (this.state.streetAddress.trim() === '') {
      streetAddressError = 'Required';
      isValid = false;
    }

    if (this.state.country.trim() === '') {
      countryError = 'Required';
      isValid = false;
    }

    if (this.state.cvc.trim() === '') {
      cvcError = 'Required';
      isValid = false;
    }

    if (this.state.zipCodeRequired) {
      if (this.state.postalCode.trim() === '') {
        postalCodeError = 'Required';
        isValid = false;
      }
    }

    if (
      this.props.employeesSimple.employees &&
      _.isArray(this.props.employeesSimple.employees) &&
      this.props.employeesSimple.employees.length > 0
    ) {
      if (this.state.user.trim() === '') {
        userError = 'Required';
        isValid = false;
      }
    }

    if (
      this.state.employees == 'specific' &&
      this.state.departments.length == 0
    ) {
      errorDepartments = 'Required';
      isValid = false;
    }

    this.setState({
      nameError,
      ccNumberError,
      expiryError,
      countryError,
      cvcError,
      postalCodeError,
      userError,
      errorDepartments,
      regionError,
      cityError,
      streetAddressError,
    });

    return isValid;
  };

  handleSubmit = () => {
    if (this.validFields()) {
      //Whem saving a personal card for a user
      let userId = this.props.userId;

      //When saving a company card, and choosing a user from rhe organization
      if (
        this.props.employeesSimple.employees &&
        _.isArray(this.props.employeesSimple.employees) &&
        this.props.employeesSimple.employees.length > 0
      ) {
        userId = this.state.user;
      }

      this.props.add({
        name: this.state.name,
        ccNumber: this.state.ccNumber.replaceAll(' ', ''),
        cvv: this.state.cvc,
        expiry: moment(this.state.expiry, 'MM/YYYY').format('YYYY-MM'),
        userId: userId,
        organizationId: this.props.organizationId,
        departmentIds:
          this.state.employees == 'specific' ? this.state.departments : [],
        city: this.state.city,
        country: this.state.country,
        postalCode: this.state.zipCodeRequired ? this.state.postalCode : '',
        state: _.get(this.state, 'region.value', this.state.region),
        street: this.state.streetAddress,
        street2: this.state.streetAddress2,
      });
    }
  };

  renderCCError = () => {
    if (Boolean(this.state.ccNumberError)) {
      return <FormHelperText error>{this.state.ccNumberError}</FormHelperText>;
    }

    return null;
  };

  renderExpiryError = () => {
    if (Boolean(this.state.expiryError)) {
      return <FormHelperText error>{this.state.expiryError}</FormHelperText>;
    }

    return null;
  };

  renderCVCError = () => {
    if (Boolean(this.state.cvcError)) {
      return <FormHelperText error>{this.state.cvcError}</FormHelperText>;
    }

    return null;
  };

  renderUserError = () => {
    if (Boolean(this.state.userError)) {
      return <FormHelperText error>{this.state.userError}</FormHelperText>;
    }

    return null;
  };

  handleChangeDepartments = (departments: any) => {
    this.setState({
      departments,
      errorDepartments: '',
    });
  };

  onChangeEmployees = (event: any, value: any) => {
    this.setState({
      employees: value,
      errorDepartments: '',
    });
  };

  renderRegion = () => {
    if (
      this.state.country == 'United States' ||
      this.state.country == 'Canada'
    ) {
      return (
        <div className={styles.inputWrapper}>
          <AutoSuggest
            error={this.state.regionError}
            className={styles.dropdownContainer}
            onChange={(value: any) => {
              this.onChangeRegion(value);
            }}
            label={this.state.country == 'Canada' ? 'Province' : 'State'}
            returnAll={true}
            value={_.get(this.state, 'region.label', this.state.region)}
            dataSet={this.state.country == 'Canada' ? provinces : states}
            id="province"
          />
        </div>
      );
    }

    return (
      <div className={styles.inputWrapper}>
        <TextFieldWithClear
          error={Boolean(this.state.regionError)}
          helperText={this.state.regionError}
          onChange={this.onChangeRegionText}
          fullWidth
          label="State/Province/Region"
          variant="outlined"
          margin="normal"
          value={this.state.region}
          onClear={this.onDeleteRegion}
        />
      </div>
    );
  };

  renderDepartmentsInput = () => {
    if (
      this.props.departments &&
      _.isArray(this.props.departments) &&
      this.props.departments.length > 0
    ) {
      let selections = _.get(this.props, 'departments', []).filter(
        (department: any) => _.get(department, 'status', '') != 'disabled',
      );
      selections = _.map(selections, (department: any) => {
        return { key: department.id, value: department.name };
      });
      selections = selections
        ? selections.sort((a: any, b: any) => a.value.localeCompare(b.value))
        : [];

      return (
        <div className={cx(styles.inputContainer, styles.wrapperContainer)}>
          <h2 className={styles.informationTitle}>3. Credit Card Access</h2>
          <div className={styles.subtitle}>
            These are the employees that can charge their bookings against this
            card.
          </div>

          <RadioGroup
            aria-label=""
            name="employees"
            className={styles.radioGroup}
            value={this.state.employees}
            onChange={this.onChangeEmployees}
          >
            <FormControlLabel
              value={`all`}
              control={
                <Radio
                  icon={
                    <IconRadio
                      width={16}
                      height={16}
                    />
                  }
                  checkedIcon={
                    <IconRadioChecked
                      width={16}
                      height={16}
                    />
                  }
                  className={styles.checkboxReview}
                />
              }
              label={
                <span className={styles.radioGroupLabel}>All Employees</span>
              }
            />
            <FormControlLabel
              value={`specific`}
              control={
                <Radio
                  icon={
                    <IconRadio
                      width={16}
                      height={16}
                    />
                  }
                  checkedIcon={
                    <IconRadioChecked
                      width={16}
                      height={16}
                    />
                  }
                  className={styles.checkboxReview}
                />
              }
              label={
                <span className={styles.radioGroupLabel}>
                  Specific Employees
                </span>
              }
            />
          </RadioGroup>

          {this.state.employees == 'specific' && (
            <div className={styles.inputWrapper_department}>
              <MultiSelect
                error={this.state.errorDepartments}
                onChange={this.handleChangeDepartments}
                value={this.state.departments}
                selections={selections}
                labelWidth={171}
                label="Departments Available To"
              />
            </div>
          )}
        </div>
      );
    }

    return null;
  };

  onCancelSave = () => {
    this.props.onClose();
  };

  renderUsersInput = () => {
    if (
      this.props.employeesSimple.employees &&
      _.isArray(this.props.employeesSimple.employees) &&
      this.props.employeesSimple.employees.length > 0
    ) {
      const selections = _.map(this.props.employeesSimple.employees, (user) => {
        return { key: user.id, value: user.name };
      });

      let menuItems = _.filter(this.props.employeesSimple.employees, [
        'status',
        'active',
      ]).map((user: any) => {
        let username = `${_.get(user, 'firstName', '')} ${_.get(
          user,
          'lastName',
          '',
        )}`;

        if (username.trim() == '') {
          username = user.email;
        }
        return { key: user.id, value: user.id, label: username };
      });

      menuItems = _.sortBy(menuItems, ['label']);

      return (
        <>
          <div className={styles.inputWrapper}>
            <CustomSelect
              margin={'normal'}
              variant={'outlined'}
              className={styles.selectWrapper}
              htmlFor={'input-type'}
              label={'Credit Card Owner'}
              id={'input-type'}
              value={this.state.user}
              onChange={this.onChangeUser}
              fullWidth={true}
              inputError={Boolean(this.state.userError)}
              labelWidth={123}
              inputName={'user'}
              renderError={this.renderUserError()}
              MenuProps={{
                style: { marginTop: 4, boxShadow: 'none' },
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
              }}
              menuItems={menuItems}
            />
          </div>
          <div className={styles.inputWrapper}></div>
        </>
      );
    }

    return null;
  };

  renderZipCode = () => {
    if (this.state.zipCodeRequired) {
      return (
        <div className={styles.inputWrapper}>
          <TextFieldWithClear
            error={Boolean(this.state.postalCodeError)}
            helperText={this.state.postalCodeError}
            onChange={this.onChangePostalCode}
            fullWidth
            label="Zip Code"
            variant="outlined"
            margin="normal"
            value={this.state.postalCode}
            onClear={this.onDeletePostalCode}
            id="zipcode"
          />
        </div>
      );
    }

    return null;
  };

  render() {
    return (
      <>
        <Dialog
          fullWidth={true}
          maxWidth="xl"
          className={cx(styles.dialogBox, styles.ccPopup)}
          open={this.props.open}
          onClose={this.props.onClose}
        >
          <div className={styles.title}>
            <div className={styles.titleStyle}>
              {this.props.title || 'Add New Payment Method'}
            </div>
            <IconButton
              color="inherit"
              onClick={this.props.onClose}
              className={cx(styles.closeIconCreditCard)}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
          </div>
          {this.renderLoader()}
          <p className={cx(styles.subtitleStyle, styles.maxWidthSubtitle)}>
            {this.props.description || 'Add credit card information.'}
          </p>
          <div className={styles.updatePopup}>
            <div className={cx(styles.informationTitle, styles.addMarginTop)}>
              <h2>1. Credit Card Information</h2>
            </div>
            <div
              className={cx(
                styles.inputContainer,
                styles.inputContainerCreditCard,
              )}
            >
              {this.renderUsersInput()}

              <div className={styles.inputWrapper}>
                <TextFieldWithClear
                  error={Boolean(this.state.nameError)}
                  helperText={this.state.nameError}
                  onChange={this.onChangeName}
                  fullWidth
                  label="Name on Card"
                  variant="outlined"
                  margin="normal"
                  value={this.state.name}
                  onClear={this.onDeleteName}
                  id="name"
                />
              </div>

              <div className={styles.inputWrapper}>
                <FormControl
                  margin="normal"
                  variant="outlined"
                  className={styles.selectWrapper}
                >
                  <InputLabel
                    error={Boolean(this.state.ccNumberError)}
                    htmlFor="input-ccNumber"
                  >
                    Credit Card Number
                  </InputLabel>
                  <OutlinedInputWithDelete
                    error={Boolean(this.state.ccNumberError)}
                    fullWidth
                    id="input-ccNumber"
                    name="ccNumber"
                    labelWidth={132}
                    value={this.state.ccNumber}
                    onChange={this.onChangeCcNumber}
                    inputComponent={TextMaskCC}
                    onClear={this.onDeleteCreditCardNumber}
                  />
                  {this.renderCCError()}
                </FormControl>
              </div>

              <div className={styles.inputWrapper}>
                <FormControl
                  margin="normal"
                  variant="outlined"
                  className={styles.selectWrapper}
                >
                  <InputLabel
                    error={Boolean(this.state.expiryError)}
                    htmlFor="input-expiry"
                  >
                    Expiration Date
                  </InputLabel>
                  <OutlinedInputWithDelete
                    error={Boolean(this.state.expiryError)}
                    fullWidth
                    id="input-expiry"
                    name="expiry"
                    labelWidth={102}
                    value={this.state.expiry}
                    onChange={this.onChangeExpiry}
                    inputComponent={TextMaskExpiry}
                    onClear={this.onDeleteExpiry}
                  />
                  {this.renderExpiryError()}
                </FormControl>
              </div>

              <div className={styles.inputWrapper}>
                <FormControl
                  margin="normal"
                  variant="outlined"
                  className={styles.selectWrapper}
                >
                  <InputLabel
                    error={Boolean(this.state.cvcError)}
                    htmlFor="input-cvc"
                  >
                    CVC
                  </InputLabel>
                  <OutlinedInputWithDelete
                    error={Boolean(this.state.cvcError)}
                    fullWidth
                    id="input-cvc"
                    name="cvc"
                    labelWidth={31}
                    value={this.state.cvc}
                    onChange={this.onChangeCvc}
                    inputComponent={TextMaskCVC}
                    onClear={this.onDeletecvc}
                  />
                  {this.renderCVCError()}
                </FormControl>
              </div>
            </div>
            <div className={styles.billingAddressContainer}>
              <h2 className={styles.informationTitle}>
                2. Billing Information
              </h2>
            </div>
            <div
              className={cx(
                styles.inputContainer,
                styles.inputContainerCreditCard,
              )}
            >
              <div className={styles.inputWrapper}>
                <TextFieldWithClear
                  error={Boolean(this.state.streetAddressError)}
                  helperText={this.state.streetAddressError}
                  onChange={this.onChangeStreetAddress}
                  fullWidth
                  label="Street Address"
                  variant="outlined"
                  margin="normal"
                  value={this.state.streetAddress}
                  onClear={this.onDeleteStreetAddress}
                  id="street-address"
                />
              </div>
              <div className={styles.inputWrapper}>
                <TextFieldWithClear
                  onChange={this.onChangeStreetAddress2}
                  fullWidth
                  label="Street Address 2"
                  variant="outlined"
                  margin="normal"
                  value={this.state.streetAddress2}
                  onClear={this.onDeleteStreetAddress2}
                />
              </div>
              <div className={styles.inputWrapper}>
                <TextFieldWithClear
                  error={Boolean(this.state.cityError)}
                  helperText={this.state.cityError}
                  onChange={this.onChangeCity}
                  fullWidth
                  label="City"
                  variant="outlined"
                  margin="normal"
                  value={this.state.city}
                  onClear={this.onDeleteCity}
                  id="city"
                />
              </div>

              {this.renderRegion()}

              <div className={styles.inputWrapper}>
                <AutoSuggest
                  error={this.state.countryError}
                  className={styles.dropdownContainer}
                  onChange={(value: any) => {
                    this.onChangeCountryAuto(value);
                  }}
                  label="Country"
                  value={this.state.country}
                  dataSet={countries}
                  id="country"
                />
              </div>

              {this.renderZipCode()}
            </div>

            {this.renderDepartmentsInput()}

            <div className={styles.popupButtonsContainer}>
              <Button
                size="small"
                className={cx(styles.button, styles.custombuttonStyle)}
                variant="contained"
                color="primary"
                onClick={this.handleSubmit}
                id="submit-add-popup"
              >
                Save
              </Button>
            </div>
          </div>
        </Dialog>
      </>
    );
  }
}

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {
    addCreditCard: state.addCreditCard,
    in_process: state.addCreditCard.in_process,
    employeesSimple: state.employeesSimple,
  };
}

// Wire up redux dispatch functions
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      add,
      resetAddCreditCard,
      getOrganizationEmployeesSimple,
      emptyResults,
    },
    dispatch,
  );
}

// Connect it to Redux
export default connect(mapStateToProps, mapDispatchToProps)(AddCreditCardPopup);

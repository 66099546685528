import {
  ACTION_STATUS_SHOW,
  ACTION_STATUS_CLEAR,
} from '../configs/ActionTypes';

const initialState = {
  notifications: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'ACTION_STATUS_SHOW':
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            ...action.payload,
          },
        ],
      };

    case 'ACTION_STATUS_CLEAR':
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.id !== action.payload,
        ),
      };

    default:
      return state;
  }
}

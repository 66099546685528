import {
  ACTION_GET_BOOKINGS,
  ACTION_GET_BOOKINGS_SUCCESS,
  ACTION_BOOKINGS_SEND_CONFIRMATION,
  ACTION_BOOKINGS_SEND_CONFIRMATION_SUCCESS,
  ACTION_BOOKINGS_SEND_CONFIRMATION_FAILURE,
  ACTION_GET_BOOKINGS_FAILURE,
  ACTION_EMPTY_BOOKINGS,
} from '../configs/ActionTypes';

import { QUERY_LIMIT_25 } from '../configs/General';

import _ from 'lodash-es';

const initialState = {
  bookings: [],
  loadedAll: false,
  isLoading: false,
  sort: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ACTION_BOOKINGS_SEND_CONFIRMATION:
    case ACTION_GET_BOOKINGS: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ACTION_EMPTY_BOOKINGS: {
      return initialState;
    }

    case ACTION_GET_BOOKINGS_SUCCESS: {
      const payload = action.payload.payload.bookings;
      let bookings, loadedAll;
      if (action.payload.payload.usedSort == state.sort) {
        bookings = _.clone(state.bookings);
        loadedAll = state.loadedAll;
      } else {
        bookings = [];
        loadedAll = false;
      }

      if (payload.length < QUERY_LIMIT_25) {
        loadedAll = true;
      }

      if (payload.length > 0) {
        bookings = _.union(bookings, payload);
      }
      return {
        ...state,
        bookings: bookings,
        loadedAll: loadedAll,
        isLoading: false,
        sort: action.payload.payload.usedSort,
      };
    }

    case ACTION_BOOKINGS_SEND_CONFIRMATION_SUCCESS: {
      const payload = action.payload.payload;

      if (payload.additionalEmails.length > 5) {
        let index = _.findIndex(state.bookings, function (o) {
          return o.id == payload.bookingId;
        });

        if (index > -1) {
          let additionalEmails = payload.additionalEmails;
          let bookingAdditionalEmails = _.get(
            state.bookings[index],
            'additionalEmails',
            null,
          );

          bookingAdditionalEmails = bookingAdditionalEmails
            ? bookingAdditionalEmails.split(',')
            : [];

          let newAdditionalEmails = additionalEmails
            ? additionalEmails.split(',')
            : [];
          let updateAdditionalEmail = false;

          newAdditionalEmails = newAdditionalEmails.map((e) => e.trim());

          _.each(newAdditionalEmails, (emails) => {
            if (!_.includes(bookingAdditionalEmails, emails)) {
              updateAdditionalEmail = true;
            }
          });

          let allEmails = _.union(newAdditionalEmails, bookingAdditionalEmails);

          if (updateAdditionalEmail) {
            state.bookings[index].additionalEmails = allEmails.toString();
          }
        }
      }

      return {
        ...state,
        isLoading: false,
      };
    }

    case ACTION_BOOKINGS_SEND_CONFIRMATION_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
}

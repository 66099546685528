import React, { Component } from 'react';
import classNames from 'classnames';

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import _ from 'lodash-es';

import { navigate, redirectTo } from '@reach/router';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import Zoom from '@material-ui/core/Zoom';
import CloseIcon from '@material-ui/icons/Close';
import TextFieldWithClear from 'components/TextFieldWithIcon/TextFieldWithIcon';
import cx from 'classnames';
import { sendEmail } from 'actions/Bookings/SendEmail';

const styles = require('./styles.pcss');

import { ReduxState } from '../../reducers/types';

type Props = {
  sendEmail: Function;
  open: boolean;
  onClose: any;
  onSubmit: any;
  type: string;
  id: any;
  identifier: any;
  additionalEmails: string;
  isLoading: any;
  userEmail: string;
};

type State = {
  additionalEmails: string;
  errorAdditionalEmails: any;
  waitingResponse: boolean;
};

class SendEmailConfirmationPopup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      additionalEmails: '',
      errorAdditionalEmails: false,
      waitingResponse: false,
    };
  }

  componentDidMount() {
    this.setState(this.getInitialState());
  }

  shouldComponentUpdate(nextProps: any) {
    if (this.props.isLoading == true && !_.get(nextProps, 'isLoading', true)) {
      this.onClose();
    }

    return true;
  }

  handleChangeEmails = (event: any) => {
    this.setState({
      additionalEmails: event.target.value,
      errorAdditionalEmails: false,
    });
  };

  onKeyUp = (e: any) => {
    if (e.keyCode === 13) {
      this.handleSubmit();
    }
  };

  onDeleteEmails = () => {
    this.setState({
      additionalEmails: '',
      errorAdditionalEmails: false,
    });
  };

  handleSubmit = () => {
    let errorEmail = false;

    let emails = this.state.additionalEmails.trim().replace(/,\s*$/, '');

    if (emails.length > 1) {
      _.forEach(emails.split(','), (email) => {
        if (!email.trim().match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
          errorEmail = true;
        }
      });
    }

    if (errorEmail == false) {
      this.props.sendEmail(
        this.props.id,
        this.props.type,
        this.state.additionalEmails,
      );
      this.props.onSubmit(this.state.additionalEmails);

      this.setState({
        waitingResponse: true,
      });
    } else {
      this.setState({
        errorAdditionalEmails: 'Invalid Email Addresses',
      });
    }
  };

  onClose = () => {
    this.setState(this.getInitialState());
    this.props.onClose();
  };

  renderAdditionalEmails = () => {
    if (
      _.get(this.props, 'additionalEmails', null) === null ||
      _.get(this.props, 'additionalEmails', null) === undefined ||
      _.get(this.props, 'additionalEmails', null) === ''
    ) {
      return '';
    }

    return this.props.additionalEmails.split(',').join(', ');
  };

  render() {
    return (
      <Dialog
        fullWidth={true}
        maxWidth="md"
        className={cx(styles.dialogBox, styles.sendEmailConfirmation)}
        open={this.props.open}
        onClose={this.onClose}
      >
        <div className={classNames(styles.title)}>
          <div className={styles.titleStyle}>Send Email Confirmation</div>
          <IconButton
            color="inherit"
            onClick={this.onClose}
            className={classNames(styles.closeIcon)}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className={classNames(styles.content)}>
          <div className={classNames(styles.sendEmailText)}>
            A copy of the booking confirmation for {this.props.identifier} will
            be sent to the following email addresses:
          </div>
          <div className={classNames(styles.additionalEmailsText)}>
            {_.get(this.props, 'userEmail', ' ').length > 5 && (
              <>
                {' '}
                {_.get(this.props, 'userEmail', ' ')}
                {_.get(this.props, 'additionalEmails', null) !== null &&
                  _.get(this.props, 'additionalEmails', '') !== undefined &&
                  _.get(this.props, 'additionalEmails', '') !== '' && <>, </>}
              </>
            )}
            {this.renderAdditionalEmails()}
          </div>
          <div className={classNames(styles.sendEmailText)}>
            To send to additional receipients, add their email addresses below.
          </div>
          <TextFieldWithClear
            fullWidth
            id="emailAddress"
            className={classNames(styles.textField)}
            label="Email address"
            variant="outlined"
            margin="normal"
            value={this.state.additionalEmails}
            onChange={this.handleChangeEmails}
            error={Boolean(this.state.errorAdditionalEmails)}
            helperText={this.state.errorAdditionalEmails}
            onKeyUp={this.onKeyUp}
            onClear={this.onDeleteEmails}
          />

          <div className={classNames(styles.actions, styles.actionsRight)}>
            <Button
              className={styles.button}
              variant="contained"
              color="primary"
              onClick={this.handleSubmit}
            >
              Send Email
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {
    isLoading: state.bookings.isLoading,
  };
}

// Wire up redux dispatch functions
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      sendEmail,
    },
    dispatch,
  );
}

// Connect it to Redux
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SendEmailConfirmationPopup);

import * as React from 'react';
import _ from 'lodash-es';
import Button from '@material-ui/core/Button';
const styles = require('./styles.pcss');

type Props = {
  text?: any;
  onSubmit?: any;
  onCancel?: any;
  submitText?: string;
  secondaryText?: string;
};

const FooterConfirm = (props: Props) => {
  return (
    <div className={styles.footerConfirm}>
      <div className={styles.footerText}>
        {props.text}
        {props.secondaryText && (
          <div className={styles.secondaryText}>{props.secondaryText}</div>
        )}
      </div>
      <div className={styles.buttonContainer}>
        {props.onCancel != undefined && (
          <Button
            className={styles.button}
            variant="outlined"
            color="primary"
            onClick={props.onCancel}
          >
            Cancel
          </Button>
        )}
        {props.onSubmit != undefined && (
          <Button
            className={styles.button}
            variant="contained"
            color="primary"
            onClick={props.onSubmit}
          >
            {props.submitText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default FooterConfirm;

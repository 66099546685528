import React from 'react';

type Props = {
  width: number;
  height: number;
};

const SvgIconAmex = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Artboard</title>
      <desc>Created with Sketch.</desc>
      <defs></defs>
      <g
        id="Artboard"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M0,16 C0,7.164 7.162,0 16,0 C24.836,0 32,7.162 32,16 C32,24.836 24.838,32 16,32 C7.164,32 0,24.838 0,16"
          id="Fill-1"
          fill="#007CC5"
        ></path>
        <path
          d="M7.8486,16.2573 L9.2586,16.2573 L8.5536,14.5433 L7.8486,16.2573 Z M27.4996,13.0003 L23.8876,13.0003 L23.0056,13.9433 L22.2126,13.0003 L14.5456,13.0003 L13.8406,14.5433 L13.1366,13.0003 L10.0516,13.0003 L10.0516,13.6853 L9.6996,13.0003 L7.0556,13.0003 L4.4996,19.0003 L7.5846,19.0003 L7.9366,18.0573 L8.8176,18.0573 L9.1706,19.0003 L12.6076,19.0003 L12.6076,18.3143 L12.8716,19.0003 L14.6346,19.0003 L14.8986,18.2283 L14.8986,19.0003 L21.9486,19.0003 L22.8296,18.0573 L23.6226,19.0003 L27.2356,19.0003 L24.9446,16.0003 L27.4996,13.0003 Z M16.8376,18.1433 L15.8676,18.1433 L15.8676,14.7993 L14.3696,18.1433 L13.4886,18.1433 L11.9906,14.7993 L11.9906,18.1433 L9.9636,18.1433 L9.6116,17.2003 L7.4956,17.2003 L7.1436,18.1433 L5.9976,18.1433 L7.8486,13.8573 L9.3466,13.8573 L11.0206,17.8853 L11.0206,13.8573 L12.6956,13.8573 L14.0166,16.7713 L15.2506,13.8573 L16.9256,13.8573 L16.9256,18.1433 L16.8376,18.1433 Z M25.5616,18.1433 L24.2396,18.1433 L23.0936,16.6853 L21.7716,18.1433 L17.8066,18.1433 L17.8066,13.8573 L21.8606,13.8573 L23.0936,15.2283 L24.4156,13.8573 L25.6496,13.8573 L23.7106,16.0003 L25.5616,18.1433 Z M18.7756,14.7143 L18.7756,15.4863 L20.9786,15.4863 L20.9786,16.3423 L18.7756,16.3423 L18.7756,17.2003 L21.2436,17.2003 L22.3886,15.9143 L21.3316,14.7143 L18.7756,14.7143 Z"
          id="Fill-3"
          fill="#FFFFFF"
        ></path>
      </g>
    </svg>
  );
};

export default SvgIconAmex;

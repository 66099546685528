import * as React from 'react';
import _ from 'lodash-es';
import cx from 'classnames';

const styles = require('./styles.pcss');

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { navigate } from '@reach/router';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import CircularProgress from '@material-ui/core/CircularProgress';

import { ReduxState } from '../../reducers/types';

import {
  resetPassword as resetPw,
  resetStates,
} from '../../actions/User/ResetPassword';

type Props = any;

type State = {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
  errorCurrentPassword: any;
  errorNewPassword: any;
  errorConfirmNewPassword: any;
  showCurrentPassword: boolean;
  showNewPassword: boolean;
  showConfirmNewPassword: boolean;
  loading: boolean;
};

class ResetPassword extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      errorCurrentPassword: '',
      errorNewPassword: '',
      errorConfirmNewPassword: '',
      showCurrentPassword: false,
      showNewPassword: false,
      showConfirmNewPassword: false,
      loading: false,
    };

    if (
      _.get(this.props.userLogin, 'isLoggedIn', false) === true &&
      _.get(this.props.userLogin, 'forceResetPassword', false) === false
    ) {
      navigate(`/lookup/`);
    }
  }

  componentWillReceiveProps = (nextProps: any) => {
    this.setState({
      loading: false,
    });

    if (_.get(nextProps, 'resetPassword.success', false) === true) {
      this.props.resetStates();

      this.setState({
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
      });

      if (_.get(nextProps, 'resetPassword.success', false) === true) {
        navigate(`/login/`);
      }

      return;
    }

    this.setState({
      errorCurrentPassword: _.get(
        nextProps,
        'resetPassword.currentPasswordError',
        '',
      ),
      errorNewPassword: _.get(nextProps, 'resetPassword.newPasswordError', ''),
      errorConfirmNewPassword: _.get(
        nextProps,
        'resetPassword.confirmNewPasswordError',
        '',
      ),
    });
  };

  handleChangeCurrentPassword = (event: any) => {
    this.setState({
      currentPassword: event.target.value,
      errorCurrentPassword: false,
    });
  };

  handleChangeNewPassword = (event: any) => {
    this.setState({
      newPassword: event.target.value,
      errorNewPassword: false,
    });
  };

  handleChangeConfirmNewPassword = (event: any) => {
    this.setState({
      confirmNewPassword: event.target.value,
      errorConfirmNewPassword: false,
    });
  };

  validFields = () => {
    let isValid = true;
    let errorCurrentPassword = '';
    let errorNewPassword = '';
    let errorConfirmNewPassword = '';

    if (this.state.currentPassword.trim() === '') {
      errorCurrentPassword = 'Required';
      isValid = false;
    }

    if (this.state.newPassword.trim() === '') {
      errorNewPassword = 'Required';
      isValid = false;
    }

    if (this.state.confirmNewPassword !== this.state.newPassword) {
      errorConfirmNewPassword =
        'New password and confirm new password does not match';
      isValid = false;
    }

    if (this.state.confirmNewPassword.trim() === '') {
      errorConfirmNewPassword = 'Required';
      isValid = false;
    }

    this.setState({
      errorCurrentPassword,
      errorNewPassword,
      errorConfirmNewPassword,
    });

    return isValid;
  };

  handleSubmit = () => {
    if (this.state.loading === true) {
      return;
    }

    if (this.validFields()) {
      this.setState({
        loading: true,
      });

      this.props.resetPw(
        this.state.currentPassword,
        this.state.newPassword,
        this.state.confirmNewPassword,
      );
    }
  };

  handleClickShowCurrentPassword = () => {
    this.setState({
      showCurrentPassword: !this.state.showCurrentPassword,
    });
  };

  handleClickShowNewPassword = () => {
    this.setState({
      showNewPassword: !this.state.showNewPassword,
    });
  };

  handleClickShowConfirmNewPassword = () => {
    this.setState({
      showConfirmNewPassword: !this.state.showConfirmNewPassword,
    });
  };

  /**
   * Handle form submit on enter
   */
  onKeyUp = (e: any) => {
    if (e.keyCode === 13) {
      this.handleSubmit();
    }
  };

  render() {
    return (
      <div className={styles.pageLogin}>
        <Card className={styles.card}>
          <CardContent className={styles.cardContent}>
            <h1 className={styles.title}>Change Password</h1>
            <div className={styles.subTitle}>
              {' '}
              You must change your password to continue.
            </div>
            <div className={cx(styles.content)}></div>
            <TextField
              fullWidth
              id="currentPassword"
              className={cx(styles.textField)}
              label="Current Password"
              variant="outlined"
              margin="normal"
              value={this.state.currentPassword}
              onChange={this.handleChangeCurrentPassword}
              error={Boolean(this.state.errorCurrentPassword)}
              helperText={this.state.errorCurrentPassword}
              onKeyUp={this.onKeyUp}
              type={this.state.showCurrentPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: this.state.currentPassword !== '' && (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={this.handleClickShowCurrentPassword}
                    >
                      {this.state.showCurrentPassword ? (
                        <Visibility
                          className={cx(styles.icon, styles.eyeVisible)}
                        />
                      ) : (
                        <Visibility className={styles.icon} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              fullWidth
              id="newPassword"
              className={cx(styles.textField)}
              label="New Password"
              variant="outlined"
              margin="normal"
              value={this.state.newPassword}
              onChange={this.handleChangeNewPassword}
              error={Boolean(this.state.errorNewPassword)}
              helperText={this.state.errorNewPassword}
              onKeyUp={this.onKeyUp}
              type={this.state.showNewPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: this.state.newPassword !== '' && (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={this.handleClickShowNewPassword}
                    >
                      {this.state.showNewPassword ? (
                        <Visibility
                          className={cx(styles.icon, styles.eyeVisible)}
                        />
                      ) : (
                        <Visibility className={styles.icon} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              fullWidth
              id="confirmNewPassword"
              className={cx(styles.textField)}
              label="Confirm New Password"
              variant="outlined"
              margin="normal"
              value={this.state.confirmNewPassword}
              onChange={this.handleChangeConfirmNewPassword}
              error={Boolean(this.state.errorConfirmNewPassword)}
              helperText={this.state.errorConfirmNewPassword}
              onKeyUp={this.onKeyUp}
              type={this.state.showConfirmNewPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: this.state.confirmNewPassword !== '' && (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={this.handleClickShowConfirmNewPassword}
                    >
                      {this.state.showConfirmNewPassword ? (
                        <Visibility
                          className={cx(styles.icon, styles.eyeVisible)}
                        />
                      ) : (
                        <Visibility className={styles.icon} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Button
              fullWidth
              className={styles.button}
              variant="contained"
              color="primary"
              onClick={this.handleSubmit}
              disableRipple={true}
            >
              Submit
            </Button>

            {this.state.loading && (
              <CircularProgress
                size={24}
                style={{
                  position: 'absolute',
                  left: '50%',
                  marginTop: 28,
                  marginLeft: -12,
                }}
              />
            )}
          </CardContent>
        </Card>
      </div>
    );
  }
}

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {
    resetPassword: state.resetPassword,
    userLogin: state.userLogin,
  };
}

// // Wire up redux dispatch functions
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      resetPw,
      resetStates,
    },
    dispatch,
  );
}

// Connect it to Redux
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);

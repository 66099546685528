import React from 'react';

type Props = {
  width: number;
  height: number;
  color: string;
};

const SvgMembership = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      version="1.0"
      viewBox="0 0 32 32"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
    >
      <g
        id="Master"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="User-Macro---Profile---Multiple-Travelers"
          transform="translate(-312.000000, -697.000000)"
        >
          <g
            id="memberships"
            transform="translate(280.000000, 659.000000)"
          >
            <g
              id="Group-6"
              transform="translate(32.000000, 38.000000)"
            >
              <path
                d="M0,16 C0,7.164 7.162,0 16,0 C24.836,0 32,7.162 32,16 C32,24.836 24.838,32 16,32 C7.164,32 0,24.838 0,16"
                id="Fill-1"
                fill={props.color}
              ></path>
              <path
                d="M16,20 L13.0911901,21.5292518 C12.6023455,21.7862527 11.9977184,21.5983061 11.7407176,21.1094615 C11.6383785,20.9148011 11.6030638,20.691833 11.6402407,20.4750754 L12.1957739,17.236068 L12.1957739,17.236068 L9.84249732,14.9421902 C9.44701374,14.556689 9.43892144,13.9235757 9.82442265,13.5280922 C9.97793125,13.3706085 10.1790737,13.2681213 10.3967107,13.2364968 L13.648859,12.763932 L13.648859,12.763932 L15.1032639,9.81698575 C15.3476862,9.32173209 15.9473121,9.11839309 16.4425657,9.36281539 C16.6397783,9.46014562 16.7994058,9.61977315 16.8967361,9.81698575 L18.351141,12.763932 L18.351141,12.763932 L21.6032893,13.2364968 C22.1498342,13.3159144 22.5285156,13.8233573 22.449098,14.3699022 C22.4174735,14.5875392 22.3149863,14.7886816 22.1575027,14.9421902 L19.8042261,17.236068 L19.8042261,17.236068 L20.3597593,20.4750754 C20.4531203,21.0194118 20.0875325,21.5363677 19.5431961,21.6297287 C19.3264384,21.6669056 19.1034703,21.6315909 18.9088099,21.5292518 L16,20 L16,20 Z"
                id="Star"
                fill="#FFFFFF"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgMembership;

import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
};

const SvgIconSearchThin = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 17 17"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Master"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Organization-Macro---Employees"
          transform="translate(-1181.000000, -234.000000)"
          fill="#1B2432"
        >
          <g
            id="search"
            transform="translate(1170.000000, 216.000000)"
          >
            <g id="Empty-Form-Field">
              <g id="Group-11">
                <g
                  id="Text"
                  transform="translate(11.323077, 16.000000)"
                >
                  <g
                    id="Magnifiying-Glass"
                    transform="translate(0.000000, 2.000000)"
                  >
                    <path
                      d="M6.75,13.5 C3.02207794,13.5 0,10.4779221 0,6.75 C0,3.02207794 3.02207794,0 6.75,0 C10.4779221,0 13.5,3.02207794 13.5,6.75 C13.5,10.4779221 10.4779221,13.5 6.75,13.5 Z M6.75,12 C9.64949494,12 12,9.64949494 12,6.75 C12,3.85050506 9.64949494,1.5 6.75,1.5 C3.85050506,1.5 1.5,3.85050506 1.5,6.75 C1.5,9.64949494 3.85050506,12 6.75,12 Z"
                      id="Oval-4"
                      fillRule="nonzero"
                    ></path>
                    <path
                      d="M13.265165,9.39752436 C13.6793786,9.39752436 14.015165,9.73331079 14.015165,10.1475244 L14.015165,16.3828057 C14.015165,16.7970193 13.6793786,17.1328057 13.265165,17.1328057 C12.8509515,17.1328057 12.515165,16.7970193 12.515165,16.3828057 L12.515165,10.1475244 C12.515165,9.73331079 12.8509515,9.39752436 13.265165,9.39752436 Z"
                      id="Rectangle-4"
                      transform="translate(13.265165, 13.265165) rotate(-45.000000) translate(-13.265165, -13.265165) "
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

SvgIconSearchThin.defaultProps = {
  width: 15,
  height: 15,
  fill: '#FFFFFF',
};

export default SvgIconSearchThin;

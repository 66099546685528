import React, { useState } from 'react';
import _ from 'lodash-es';
import classNames from 'classnames';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import FeaturesTableRow from './FeaturesTableRow';
import Loader from 'components/Loader/Loader';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CustomSelect from 'components/Inputs/Select/Select';
const styles = require('./styles.pcss');
import { hasPermission } from '../../helpers/Permission';
import { Features, ReduxState } from '../../reducers/types';
import SvgBankIcon from '../../static/icons/svgBankIcon';
import BankAccountPopup from '../Popup/BankAccountPopup';

type Props = {
  trustedCompanyFeature?: any;
  organizationName: any;
  handleTrustedCompanyChange?: Function;
  handleReimbursementScheduleChange?: Function;
  reimbursement?: any;
  isLoading: boolean;
  isLoadingTrustedCompany: boolean;
  isLoadingReimbursementSettings: boolean;
  organizationBankAccount: any;
  organizationId: any;
};

const Reimbursements = (props: Props) => {
  const updatePermission = !hasPermission('organizations', 'update');
  const [openBankAccountPopup, setBankAccountPopupState] = useState(false);

  let description =
    _.get(props, 'organizationName', '') +
    ' can select to reimburse their employees daily. Company withdrawal and employee deposits will occur on the same day. This should be enabled for any paying company.';

  let dailyReinbursements: any = {
    labelName: 'Trusted Reimbursements',
    description: description,
  };
  let trustedCompanyFeatureEnabled = _.get(
    props,
    'trustedCompanyFeature.value.trusted',
    false,
  );
  let item = [
    { key: 'disabled', value: 'disabled', label: 'Disabled' },
    { key: 'daily', value: 'daily', label: 'Daily' },
    { key: 'semiMonthly', value: 'semiMonthly', label: 'Semi-Monthly' },
    { key: 'monthly', value: 'monthly', label: 'Monthly' },
  ];

  let bankAccountText = 'No bank account on file';
  let bankAccountActionButtonText = 'Add';
  let orgBankAccounts = _.get(props, 'organizationBankAccount.payload');
  if (orgBankAccounts.bankName && orgBankAccounts.lastFour) {
    bankAccountActionButtonText = 'Edit';
    bankAccountText = `${orgBankAccounts.bankName} ... ${orgBankAccounts.lastFour}`;
  }

  return (
    <Paper
      className={
        props.isLoading ||
        props.isLoadingTrustedCompany ||
        props.isLoadingReimbursementSettings
          ? classNames(styles.paper, styles.loading)
          : styles.paper
      }
    >
      <div className={styles.expensesFeatures}>
        <div className={styles.labelName}>
          <h2>Reimbursements</h2>
        </div>
        {(props.isLoadingTrustedCompany ||
          props.isLoadingReimbursementSettings) && (
          <Paper className={styles.pageWrapper}>
            <div>
              <Loader visible={true} />
            </div>
          </Paper>
        )}
        <Table>
          <TableBody>
            <TableRow className={classNames(styles.tableRow)}>
              <TableCell
                key={'bankAccount text'}
                className={classNames(styles.tableCell, styles.fraud)}
              >
                {'Company Bank Account'}
              </TableCell>
              <TableCell
                key={'bankAccount'}
                className={classNames(styles.bankAccountExists)}
              >
                <div className={styles.bankAccountContainer}>
                  <div className={styles.bankAccountContent}>
                    <SvgBankIcon
                      width={20}
                      height={20}
                    />{' '}
                    {bankAccountText}
                  </div>
                  <div
                    key={'bankAccountEdit'}
                    className={classNames(styles.editBankAccount)}
                    onClick={() => setBankAccountPopupState(true)}
                  >
                    {bankAccountActionButtonText}
                  </div>
                </div>
              </TableCell>
            </TableRow>
            <FeaturesTableRow
              key={_.get(props, 'trustedCompanyFeature.id', 2)}
              rowColumns={dailyReinbursements}
              enabled={trustedCompanyFeatureEnabled}
              handleclick={props.handleTrustedCompanyChange}
              updateOrganizationPermission={updatePermission}
              description={true}
            />
            <TableRow className={classNames(styles.tableRow)}>
              <TableCell
                key={'Reimbursements'}
                className={classNames(styles.tableCell, styles.fraud)}
              >
                {'Reimbursement Schedule'}
                <div className={styles.descriptionReimburs}>
                  The frequency to process reimbursement of expense reports.
                </div>
              </TableCell>
              <TableCell
                key={'Reimbursements value'}
                className={classNames(styles.tableCell, styles.fraudSelect)}
              >
                <div className={styles.inputWrapper}>
                  <CustomSelect
                    margin={'normal'}
                    variant={'outlined'}
                    className={styles.selectWrapper}
                    htmlFor={'input-type'}
                    label={''}
                    id={'input-type'}
                    value={_.get(
                      props,
                      'reimbursement.value.frequency',
                      'disabled',
                    )}
                    onChange={props.handleReimbursementScheduleChange}
                    fullWidth={true}
                    labelWidth={'0'}
                    inputName={''}
                    MenuProps={{
                      style: { marginTop: 4, boxShadow: 'none' },
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                    }}
                    menuItems={item}
                  />
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
      <BankAccountPopup
        open={openBankAccountPopup}
        onClose={() => setBankAccountPopupState(false)}
        companyName={_.get(props, 'organizationName', '')}
        organizationId={props.organizationId}
        bankAccountId={orgBankAccounts.id || ''}
      />
    </Paper>
  );
};

function mapStateToProps(state: ReduxState) {
  return {
    isLoading: state.betaFeatures.isLoading,
    isLoadingTrustedCompany: state.organizationTrustedCompanyFeature.isLoading,
    isLoadingReimbursementSettings: state.reimbursementSettings.isLoading,
  };
}

export default connect(mapStateToProps, '')(Reimbursements);

import * as React from 'react';
import _ from 'lodash-es';
import cx from 'classnames';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TravelPolicyTableRows from './TravelPolicyTableRows';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Loader from 'components/Loader/Loader';

const styles = require('./styles.pcss');
import { ReduxState } from 'reducers/types';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

type Props = {
  flightPolicy: any;
  hotelPolicy: any;
  loading: any;
};

class NotificationsAndApprovals extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    let advanceBooking: any = {
      name: 'Notifications',
      description: 'Bookings for which managers are notified about.',
    };

    let hardCap: any = {
      name: 'Approvals',
      description: 'Bookings that require manager approval.',
    };

    let flightNotifications =
      _.get(
        this.props.flightPolicy,
        'rules.always.actions.sendEmail.value',
        false,
      ) ||
      _.get(
        this.props.flightPolicy,
        'rules.overBudget.actions.sendEmail.value',
        false,
      )
        ? 'All Booking'
        : '-';
    let hotelNotifications =
      _.get(
        this.props.hotelPolicy,
        'rules.always.actions.sendEmail.value',
        false,
      ) ||
      _.get(
        this.props.hotelPolicy,
        'rules.overBudget.actions.sendEmail.value',
        false,
      )
        ? 'All Booking'
        : '-';

    let flightOverbudget =
      _.get(
        this.props.flightPolicy,
        'rules.overBudget.actions.approval.value',
        false,
      ) ||
      _.get(
        this.props.flightPolicy,
        'rules.always.actions.approval.value',
        false,
      )
        ? 'Out of Policy'
        : '-';
    let hoteltOverbudget =
      _.get(
        this.props.hotelPolicy,
        'rules.overBudget.actions.approval.value',
        false,
      ) ||
      _.get(
        this.props.hotelPolicy,
        'rules.always.actions.approval.value0',
        false,
      )
        ? 'Out of Policy'
        : '-';

    return (
      <Paper
        className={
          this.props.loading ? cx(styles.paper, styles.loading) : styles.paper
        }
      >
        <div className={styles.policyRules}>
          <h2>Notifications and Approvals</h2>
          <div>
            <Loader visible={this.props.loading} />
          </div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  align="right"
                  className={cx(styles.tableCell, styles.header)}
                ></TableCell>
                <TableCell
                  align="center"
                  className={cx(styles.tableCell, styles.header)}
                >
                  Flight
                </TableCell>
                <TableCell
                  align="center"
                  className={cx(styles.tableCell, styles.header)}
                >
                  Lodging
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TravelPolicyTableRows
                key={2}
                text={advanceBooking}
                flight={flightNotifications}
                hotel={hotelNotifications}
                description={true}
              />
              <TravelPolicyTableRows
                key={4}
                text={hardCap}
                flight={flightOverbudget}
                hotel={hoteltOverbudget}
                description={true}
              />
            </TableBody>
          </Table>
        </div>
      </Paper>
    );
  }
}

export default NotificationsAndApprovals;

import React, { Component } from 'react';
import cx from 'classnames';
import _ from 'lodash-es';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import TextFieldWithClear from 'components/TextFieldWithIcon/TextFieldWithIcon';
import { add, resetAddDepartment } from '../../actions/Companies/NewDepartment';
import { ReduxState } from '../../reducers/types';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import Loader from 'components/Loader/Loader';

const styles = require('./styles.pcss');

type Props = {
  open: boolean;
  onClose: any;
  addDepartment: any;
  resetAddDepartment: any;
  add: Function;
  organizationId: any;
};

type State = {
  name: string;
  nameError: any;
};

class NewDepartmentPopup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      name: '',
      nameError: '',
    };
  }

  componentWillReceiveProps = (nextProps: Props) => {
    if (_.get(nextProps.addDepartment, 'success', null) === true) {
      this.props.resetAddDepartment();
      this.onClose();
    }
  };

  onDeleteName = () => {
    this.setState({
      name: '',
      nameError: '',
    });
  };

  onChangeName = (nameVal: any) => {
    this.setState({
      name: nameVal.target.value,
      nameError: '',
    });
  };

  onClose = () => {
    this.setState({
      name: '',
      nameError: '',
    });

    this.props.onClose();
  };

  renderLoading = () => {
    if (_.get(this.props, 'addDepartment.isLoading', false) === true) {
      return (
        <div className={styles.infoLoaderDep}>
          <Loader visible={true} />
        </div>
      );
    }

    return null;
  };

  onSubmit = () => {
    if (this.state.name) {
      this.props.add(this.state.name, this.props.organizationId);
    } else {
      this.setState({
        nameError: 'Reqired',
      });
    }
  };

  render() {
    return (
      <Dialog
        maxWidth={'sm'}
        fullWidth={true}
        className={cx(styles.dialogBox, styles.confirmationDialog)}
        open={this.props.open}
        onClose={this.onClose}
      >
        {this.renderLoading()}

        <div className={cx(styles.title, styles.titleFontSizePopupConf)}>
          Add Department
        </div>
        <div className={styles.body}>
          <div className={styles.inputWrapper}>
            <TextFieldWithClear
              onChange={this.onChangeName}
              fullWidth
              label="Department Name"
              id="departmentName"
              variant="outlined"
              margin="normal"
              value={this.state.name}
              onClear={this.onDeleteName}
              disabled={this.props.addDepartment.isLoading}
              error={Boolean(this.state.nameError)}
              helperText={this.state.nameError}
            />
          </div>
        </div>

        <div className={cx(styles.content, styles.confirmationPopupButtons)}>
          <Button
            className={styles.cancelButton}
            color="primary"
            onClick={this.onClose}
          >
            {`Cancel`}
          </Button>

          <Button
            className={styles.button}
            variant="contained"
            color="primary"
            onClick={this.onSubmit}
            disabled={this.props.addDepartment.isLoading}
          >
            {`Add`}
          </Button>
        </div>
      </Dialog>
    );
  }
}

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {
    addDepartment: state.addDepartment,
  };
}

// Wire up redux dispatch functions
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      add,
      resetAddDepartment,
    },
    dispatch,
  );
}

// Connect it to Redux
export default connect(mapStateToProps, mapDispatchToProps)(NewDepartmentPopup);

import * as React from 'react';
import classNames from 'classnames';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import CustomizedSwitch from '../../Features/CustomizedSwitch';
const styles = require('../styles.pcss');

type Props = {
  rowColumns: Array<any>;
  handleclick?: Function;
  description?: any;
  enabled?: any;
  permission: boolean;
  key?: any;
};

const SwitchTableRow = (props: Props) => {
  const tableColumn = (row: any) => (
    <TableCell
      key={row.id}
      className={classNames(styles.tableCell)}
    >
      <div className={styles.labelName}>{row.labelName} </div>
      {props.description && (
        <div className={styles.description}>{row.description}</div>
      )}
    </TableCell>
  );

  const displayButton = (row: any) => {
    return (
      <TableCell
        key={'switch'}
        className={classNames(styles.tableCell, styles.buttonCell)}
      >
        <CustomizedSwitch
          enabled={props.enabled}
          handleClick={props.handleclick}
          id={row.name}
          hasPermission={props.permission}
        />
      </TableCell>
    );
  };

  return (
    <TableRow
      className={classNames(styles.tableRow)}
      key={props.key}
    >
      {tableColumn(props.rowColumns)}
      {displayButton(props.rowColumns)}
    </TableRow>
  );
};

export default SwitchTableRow;

import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import SvgIconUpload from '../../static/icons/svgIconUpload';

const styles = require('./styles.pcss');
import { TB_ONBOARDING_CHECKLIST_URL } from '../../configs/Api';

const OnboardingComp = (props: any) => {
  return (
    <Paper className={styles.onboardingCompPaper}>
      <div className={styles.titleStyle}>Onboarding</div>
      <a
        className={styles.imgAndTextContainerOnboard}
        href={TB_ONBOARDING_CHECKLIST_URL}
        target="_blank"
      >
        <div className={styles.legdeImgContainer}>
          <SvgIconUpload
            width={30}
            height={24}
          />
        </div>
        <div className={styles.onboardingTextContainer}>
          Copy Onboarding Checklist
        </div>
      </a>
    </Paper>
  );
};

export default OnboardingComp;

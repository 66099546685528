import {
  ACTION_GET_ORG_AGENCY_SETTING,
  ACTION_GET_ORG_AGENCY_SETTING_SUCCESS,
  ACTION_GET_ORG_AGENCY_SETTING_FAILURE,
  ACTION_PUT_ORG_AGENCY_SETTING,
  ACTION_PUT_ORG_AGENCY_SETTING_SUCCESS,
  ACTION_PUT_ORG_AGENCY_SETTING_FAILURE,
} from '../configs/ActionTypes';

import _ from 'lodash-es';

const initialState = {
  agencySetting: [],
  isLoading: false,
  success: null,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_GET_ORG_AGENCY_SETTING:
    case ACTION_PUT_ORG_AGENCY_SETTING: {
      return {
        ...state,
        isLoading: true,
        success: null,
      };
    }

    case ACTION_PUT_ORG_AGENCY_SETTING_SUCCESS:
    case ACTION_GET_ORG_AGENCY_SETTING_SUCCESS: {
      return {
        ...state,
        agencySetting: _.get(action.payload.payload, 'value', {}),
        isLoading: false,
        success: null,
      };
    }

    case ACTION_GET_ORG_AGENCY_SETTING_FAILURE:
    case ACTION_PUT_ORG_AGENCY_SETTING_FAILURE: {
      return {
        ...state,
        isLoading: false,
        success: false,
      };
    }

    default: {
      return state;
    }
  }
}

import React from 'react';

import Loader from 'components/Loader/Loader';

const styles = require('./styles.pcss');

const ImportEmployeesLoading = () => {
  return (
    <div className={styles.loadingContent}>
      <Loader visible={true} />
    </div>
  );
};

export default ImportEmployeesLoading;

import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { KeyboardArrowDown } from '@material-ui/icons';
import Chip from '@material-ui/core/Chip';
import CloseIcon from '@material-ui/icons/Close';

const styles = require('./styles.pcss');

export function NoOptionsMessage(props: any) {
  return (
    <Typography
      color="textSecondary"
      className={styles.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

export function inputComponent({ inputRef, ...props }: any) {
  return (
    <div
      ref={inputRef}
      {...props}
    />
  );
}

export function Option(props: any) {
  return (
    <MenuItem
      classes={{ root: styles.listItem }}
      className={styles.dropdown}
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

export function Placeholder(props: any) {
  return (
    <Typography
      color="textSecondary"
      className={styles.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

export function SingleValue(props: any) {
  return (
    <Typography
      className={styles.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

export function ValueContainer(props: any) {
  return <div className={styles.valueContainer}>{props.children}</div>;
}

export function ValueContainerMulti(props: any) {
  return <div className={styles.valueContainerMulti}>{props.children}</div>;
}

export function ValueContainerChips(props: any) {
  return <div className={styles.chips}>{props.children}</div>;
}

export function MultiValue(props: any) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={styles.chip}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CloseIcon className={styles.deleteChipIcon} />}
    />
  );
}

export function Menu(props: any) {
  return (
    <Paper
      square
      className={styles.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

export function DropdownIndicator(props: any) {
  return <KeyboardArrowDown className={styles.iconDrop} />;
}

export function DropdownIndicatorMulti(props: any) {
  return <KeyboardArrowDown className={styles.iconStyle} />;
}

export function IndicatorSeparator(props: any) {
  return <span></span>;
}

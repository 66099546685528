import * as React from 'react';
import _ from 'lodash-es';
import cx from 'classnames';

import Paper from '@material-ui/core/Paper';
import CreditCards from './CreditCards';
import EditCreditCardPopup from 'components/Popup/EditCreditCardPopup';
import SvgCreditCardIcon from '../../../static/icons/svgCreditCardIcon';
import AddCreditCardPopup from 'components/Popup/AddCreditCardPopup';
import Button from '@material-ui/core/Button';

const styles = require('./styles.pcss');
import { hasPermission } from '../../../helpers/Permission';

import { CreditCard } from 'reducers/types';

type Props = {
  creditCards: any;
  organizationId?: any;
  departments?: any;
  onSaveCreditCard?: any;
  users: any;
  organizationName: any;
};

type State = {
  tabValue: string;
  editCreditCardPopupOpen: boolean;
  creditCard: any;
  addCreditCardPopupOpen: boolean;
};

class CorporateCards extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      tabValue: 'list',
      editCreditCardPopupOpen: false,
      creditCard: [],
      addCreditCardPopupOpen: false,
    };
  }

  onClickEditCreditcardPopupOpen = (param: any) => {
    this.setState({
      creditCard: param,
      editCreditCardPopupOpen: true,
    });
  };

  onClickEditCreditCardPopup = () => {
    this.setState({
      editCreditCardPopupOpen: false,
    });
  };

  handleTabChange = (event: any, value: any) => {
    this.setState({
      tabValue: value,
    });
  };

  renderContent = () => {
    let creditCards = _.clone(this.props.creditCards);

    //Parsing user from organization with credit card
    _.each(creditCards, (cc: any) => {
      cc.user = _.find(this.props.users, (u) => u.id === cc.userId);
    });

    return (
      <CreditCards
        creditCards={creditCards}
        onClickEditCreditcardPopupOpen={this.onClickEditCreditcardPopupOpen}
        editEnabled={hasPermission('organizations', 'update')}
      />
    );
  };

  renderEditCreditCardPopup() {
    if (this.state.editCreditCardPopupOpen === false) {
      return null;
    }

    return (
      <EditCreditCardPopup
        showEditCreditCardPopup={this.state.editCreditCardPopupOpen}
        closeEditCreditCardPopup={this.onClickEditCreditCardPopup}
        departments={this.props.departments}
        creditCard={this.state.creditCard}
        onSuccess={this.handleCrediCardEdit}
        onCreditCardDeleted={this.handleCrediCardEdit}
        users={this.props.users}
      />
    );
  }

  handleCrediCardEdit = () => {
    this.props.onSaveCreditCard();
  };

  openPopup = () => {
    this.setState({
      addCreditCardPopupOpen: true,
    });
  };

  addCreditCard = () => {
    this.props.onSaveCreditCard();
  };

  onClickAddCreditCardPopupClose = () => {
    this.setState({
      addCreditCardPopupOpen: false,
    });
  };

  renderAddCreditCardPopup = () => {
    if (this.state.addCreditCardPopupOpen == false) {
      return null;
    }

    return (
      <AddCreditCardPopup
        organizationId={this.props.organizationId}
        open={this.state.addCreditCardPopupOpen}
        onClose={this.onClickAddCreditCardPopupClose}
        onSuccess={this.addCreditCard}
        users={this.props.users}
        departments={this.props.departments}
        description="Add credit card information and idebtify which employees can charge their bookings against this card."
        title="Add Credit Card"
      />
    );
  };

  render() {
    return (
      <Paper className={styles.paper}>
        <div className={styles.companyInfoHeader}>
          <div>
            <SvgCreditCardIcon
              width={32}
              height={32}
            />
          </div>
          <div className={styles.companyInfoHeaderText}>
            <h2>Payment Method</h2>
            <div className={styles.description}>
              Corporate cards available for {this.props.organizationName}{' '}
              employees to book travel.
            </div>
          </div>
        </div>

        {this.renderContent()}
        {this.renderEditCreditCardPopup()}
        {this.renderAddCreditCardPopup()}
        {hasPermission('organizations', 'update') && (
          <div className={styles.buttonContainer}>
            <Button
              size="small"
              className={cx(styles.button, styles.buttonEdit)}
              variant="contained"
              color="primary"
              onClick={this.openPopup}
            >
              Add
            </Button>
          </div>
        )}
      </Paper>
    );
  }
}

export default CorporateCards;

import * as React from 'react';
import classNames from 'classnames';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';

const styles = require('./styles.pcss');

type Props = {
  rowColumns: Array<any>;
  showRemove?: boolean;
  clickRemove?: any;
};

const NegotiatedSuppliersTableRow = (props: Props) => {
  const tableColumn = (name: string, i: number) => (
    <TableCell
      key={i}
      className={classNames(styles.tableCell)}
    >
      {name}
    </TableCell>
  );

  const displayRemove = (props: Props) => {
    return (
      <TableCell
        key={'rem'}
        className={classNames(styles.tableCell, styles.removeCell)}
      >
        {
          <Button
            color="primary"
            onClick={props.clickRemove}
          >
            Remove
          </Button>
        }
      </TableCell>
    );
  };

  return (
    <TableRow className={classNames(styles.tableRow)}>
      {props.rowColumns.map(tableColumn)}
      {props.showRemove && displayRemove(props)}
    </TableRow>
  );
};

export default NegotiatedSuppliersTableRow;

import React from 'react';

type Props = {
  width: number;
  height: number;
};

const SvgIconHotel = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 64 64"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <circle
          id="path-1"
          cx="32"
          cy="32"
          r="32"
        ></circle>
      </defs>
      <g
        id="Master"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Manage-TB"
          transform="translate(-704.000000, -186.000000)"
        >
          <g
            id="Feature-Flags"
            transform="translate(664.000000, 143.000000)"
          >
            <g
              id="Flag-Icon"
              transform="translate(40.000000, 43.000000)"
            >
              <mask
                id="mask-2"
                fill="white"
              >
                <use xlinkHref="#path-1"></use>
              </mask>
              <use
                id="Mask"
                fill="#1B2432"
                xlinkHref="#path-1"
              ></use>
              <path
                d="M24,48 L22,48 L22,20 L24,20 L24,48 Z M46,22 L26,22 L26,36 L46,36 L41.5,29.0361667 L46,22 Z"
                id="Shape"
                fill="#FFFFFF"
                fillRule="nonzero"
                mask="url(#mask-2)"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgIconHotel;

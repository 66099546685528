import React from 'react';

type Props = {
  width: number;
  height: number;
};

const SvgWarningIcon = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3074 3.34407L15.8877 13.2646C16.4501 14.2645 15.7275 15.5 14.5803 15.5H3.4197C2.27246 15.5 1.54988 14.2645 2.11233 13.2646L7.69264 3.34407C8.26609 2.32459 9.73391 2.32459 10.3074 3.34407Z"
        fill="#FCA33B"
        stroke="#FCA33B"
      />
      <path
        d="M10 5.5C10 4.94772 9.55228 4.5 9 4.5C8.44772 4.5 8 4.94772 8 5.5H10ZM8 10C8 10.5523 8.44772 11 9 11C9.55228 11 10 10.5523 10 10H8ZM8 5.5V10H10V5.5H8Z"
        fill="white"
      />
      <circle
        cx="9"
        cy="13"
        r="1"
        fill="white"
      />
    </svg>
  );
};

SvgWarningIcon.defaultProps = {
  width: 18,
  height: 16,
};

export default SvgWarningIcon;

import * as React from 'react';
import * as _ from 'lodash-es';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import BookingsTable from 'components/BookingsList/Table';
import DownIcon from '@material-ui/icons/ExpandMore';
import UpIcon from '@material-ui/icons/ExpandLess';
import Paper from '@material-ui/core/Paper';
import Loader from 'components/Loader/Loader';

import Flights from 'components/BookingsList/Flights';
import Hotels from 'components/BookingsList/Hotels';
import Cars from 'components/BookingsList/Cars';

import { connect } from 'react-redux';
import { get, emptyResults } from 'actions/Bookings/Get';
import { bindActionCreators, Dispatch } from 'redux';
import { ReduxState } from '../../../reducers/types';

import { QUERY_LIMIT_25 } from '../../../configs/General';

const styles = require('./styles.pcss');

type Props = any;

type State = {
  sortBy: any;
  type: any;
  activeTabIndex: any;
};

class BookingsBody extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      sortBy: 'upcoming',
      type: this.props.type,
      activeTabIndex: 0,
    };
  }

  clearType = () => {
    this.setState({
      type: '',
    });
  };

  componentWillUnmount() {
    this.props.emptyResults();
  }

  componentWillReceiveProps = (nextProps: any) => {
    if (this.props.type != nextProps.type) {
      this.setState({
        type: nextProps.type,
      });
    }
  };

  renderTable() {
    if (this.props.bookings.length == 0 && this.props.loadedAll) {
      return (
        <Paper className={styles.bodyPaper}>
          <div className={styles.notFound}>No records found</div>
        </Paper>
      );
    }
    if (this.props.bookings.length == 0) {
      return <Loader visible={this.props.isLoading} />;
    }
    switch (this.state.type) {
      case 'flight':
      case 'booking': {
        let order = 'departureDate ASC';
        if (_.isEmpty(this.props.searchParams)) {
          order = 'created DESC';
        }

        return (
          <Flights
            flights={this.props.bookings}
            get={this.props.get}
            isLoading={this.props.isLoading}
            loadedAllFlight={this.props.loadedAll}
            searchParams={this.props.searchParams}
            order={order}
          />
        );
      }

      case 'hotel': {
        let order = 'arrivalDate ASC';
        if (_.isEmpty(this.props.searchParams)) {
          order = 'created DESC';
        }

        return (
          <Hotels
            hotels={this.props.bookings}
            get={this.props.get}
            isLoading={this.props.isLoading}
            loadedAllHotel={this.props.loadedAll}
            searchParams={this.props.searchParams}
            order={order}
          />
        );
      }

      case 'car': {
        let order = 'pickupDateTime ASC';
        if (_.isEmpty(this.props.searchParams)) {
          order = 'created DESC';
        }

        return (
          <Cars
            cars={this.props.bookings}
            get={this.props.get}
            isLoading={this.props.isLoading}
            loadedAllCars={this.props.loadedAll}
            sort={false}
            searchParams={this.props.searchParams}
            order={order}
          />
        );
      }

      default: {
        return null;
      }
    }
  }
  onTabChange = (e: any, idx: any) => {
    let params = {};
    let type = '';

    switch (idx) {
      case 0:
        {
          type = 'flight';
        }
        break;
      case 1:
        {
          type = 'hotel';
        }
        break;
      case 2:
        {
          type = 'car';
        }
        break;
    }

    params = {
      type: type,
      limit: QUERY_LIMIT_25,
      search: '',
      skip: 0,
      sort: 'false',
      order: 'created DESC',
    };

    this.props.emptyResults();
    this.props.get(params);

    this.setState({
      activeTabIndex: idx,
      type: type,
    });
  };

  render() {
    return (
      <div className={styles.bodyPaper}>
        {this.props.tabs && (
          <Tabs
            className={styles.tabs}
            value={this.state.activeTabIndex}
            indicatorColor="primary"
            textColor="primary"
            onChange={this.onTabChange}
          >
            <Tab
              className={styles.tab}
              label="Flights"
            />
            <Tab
              className={styles.tab}
              label="Hotels"
            />
            <Tab
              className={styles.tab}
              label="Cars"
            />
          </Tabs>
        )}
        {this.renderTable()}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      get,
      emptyResults,
    },
    dispatch,
  );
}

function mapStateToProps(state: ReduxState) {
  return {
    sort: state.bookings.sort,
    isLoading: state.bookings.isLoading,
    bookings: state.bookings.bookings,
    loadedAll: state.bookings.loadedAll,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingsBody);

import * as React from 'react';
import cx from 'classnames';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';

import SimpleTableHead from './Head';
import SimpleTableRow from './Row';

import { connect } from 'react-redux';

import { bindActionCreators, Dispatch } from 'redux';

import { ReduxState } from 'reducers/types';

const styles = require('./styles.pcss');

type Props = {
  columns: any;
  data: any;
  type?: string;
  noBorder?: boolean;
  scrollVertical?: boolean;
  className?: any;
};

class SimpleTable extends React.Component<Props> {
  renderRows() {
    const rowArr = [];

    for (let i = 0, mi = this.props.data.length; i < mi; i++) {
      rowArr.push(
        <SimpleTableRow
          key={`row-${i}`}
          cells={this.props.data[i]}
        />,
      );
    }

    return rowArr;
  }

  render() {
    let className = cx(
      styles.paper,
      this.props.type === 'wide' ? styles.wideTable : null,
      this.props.noBorder ? styles.noBorder : null,
      this.props.scrollVertical ? styles.scrollVertical : null,
      this.props.className ? this.props.className : null,
    );

    return (
      <Paper className={cx(className, 'styled-scrollbars')}>
        <Table>
          {this.props.columns && (
            <SimpleTableHead columns={this.props.columns} />
          )}
          <TableBody>{this.renderRows()}</TableBody>
        </Table>
      </Paper>
    );
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({}, dispatch);
}

function mapStateToProps(state: ReduxState) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(SimpleTable);

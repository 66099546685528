import * as React from 'react';
import * as _ from 'lodash-es';
import moment from 'moment';
import classNames from 'classnames';
import { navigate } from '@reach/router';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { Traveler } from '../../reducers/types';

const styles = require('./styles.pcss');

type Props = {
  traveler: Traveler;
  lookup?: any;
};

const TravelerTableRow = ({ traveler, lookup }: Props) => {
  let userName = `${_.get(traveler.user, 'firstName', '')} ${_.get(
    traveler.user,
    'lastName',
    '',
  )}`;

  if (userName.trim() == '') {
    userName = _.get(traveler.user, 'email', '');
  }

  return (
    <TableRow
      className={classNames(styles.clickable, styles.tableRow)}
      key={traveler.id}
      onClick={() =>
        navigate(`/users/${traveler.userId}`, {
          state: { travelerId: traveler.id },
        })
      }
    >
      <TableCell
        className={classNames(styles.tableRowCell)}
        scope="row"
      >
        {traveler.firstName}
      </TableCell>
      <TableCell className={classNames(styles.tableRowCell)}>
        {traveler.lastName}
      </TableCell>
      <TableCell className={classNames(styles.tableRowCell)}>
        {userName}
      </TableCell>
      <TableCell
        className={classNames(
          styles.tableRowCell,
          styles.tableRowCellBirthDate,
        )}
      >
        {moment(traveler.birthDate).isValid()
          ? moment(traveler.birthDate).format('MMM DD, YYYY')
          : true}
      </TableCell>
      <TableCell
        className={classNames(styles.tableRowCell, styles.tableRowCellEmail)}
      >
        {traveler.email}
      </TableCell>
      <TableCell className={classNames(styles.tableRowCell)}>
        {traveler.phoneCountryCode ? `+${traveler.phoneCountryCode}` : ''}
        {traveler.phoneNumber}
      </TableCell>
    </TableRow>
  );
};

export default TravelerTableRow;

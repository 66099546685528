import {
  ACTION_GET_PROFILE_SUCCESS,
  ACTION_SAVE_PROFILE_SUCCESS,
  ACTION_SAVE_PROFILE_FAILURE,
  ACTION_SAVE_PROFILE_RESET_ERRORS,
} from '../configs/ActionTypes';

import { MyProfile } from './types';

const initialState = {
  name: '',
  email: '',
  nameError: '',
  emailError: '',
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_GET_PROFILE_SUCCESS: {
      const p = action.payload.payload;

      const profile: MyProfile = {
        name: p.name,
        email: p.email,
        nameError: '',
        emailError: '',
      };

      return {
        ...state,
        name: profile.name,
        email: profile.email,
      };
    }

    case ACTION_SAVE_PROFILE_SUCCESS: {
      const p = action.payload.payload.putProfileSuccess;

      const profile: MyProfile = {
        name: p.name,
        email: p.email,
        nameError: '',
        emailError: '',
      };

      return {
        ...state,
        name: profile.name,
        email: profile.email,
        nameError: '',
        emailError: '',
      };
    }

    case ACTION_SAVE_PROFILE_FAILURE: {
      const p = action.payload.payload;

      let nameError: string = '';
      let emailError: string = '';

      if (p.hasOwnProperty('emailExists') && p.emailExists === true) {
        emailError = 'This email address is already taken';
      }

      if (p.hasOwnProperty('emailInvalid') && p.emailInvalid === true) {
        emailError = 'Invalid email address';
      }

      if (p.hasOwnProperty('emailEmpty') && p.emailEmpty === true) {
        emailError = 'Required';
      }

      if (p.hasOwnProperty('nameEmpty') && p.nameEmpty === true) {
        nameError = 'Required';
      }

      return {
        ...state,
        emailError: emailError,
        nameError: nameError,
      };
    }

    case ACTION_SAVE_PROFILE_RESET_ERRORS: {
      return {
        ...state,
        nameError: '',
        emailError: '',
      };
    }

    default: {
      return state;
    }
  }
}

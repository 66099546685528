import React, { Component } from 'react';
import classNames from 'classnames';
import _ from 'lodash-es';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = require('./styles.pcss');

type Props = {
  error: string;
  showPopup: boolean;
  closePopup: any;
  handleSubmit: any;
  additionalDomains: string[];
};

type State = {
  domain: string;
  error: string;
};

class AddDomainPopup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      error: '',
      domain: '',
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    let newStates: any = {
      error: nextProps.error,
    };

    if (!nextProps.showPopup) {
      newStates.domain = '';
    }

    this.setState(newStates);
  }

  onChangeDomain = (event: any) => {
    this.setState({
      domain: event.target.value,
      error: '',
    });
  };

  validFields = () => {
    let isValid = true;
    let error = '';
    const domain = this.state.domain.trim();

    if (domain === '') {
      error = 'Required';
      isValid = false;
    } else if (
      this.props.additionalDomains &&
      this.props.additionalDomains.includes(domain)
    ) {
      error = 'Additional domain already exists';
      isValid = false;
    }

    this.setState({
      error,
    });

    return isValid;
  };

  handleSubmit = () => {
    if (this.validFields()) {
      this.props.handleSubmit(this.state.domain);
    }
  };

  render() {
    return (
      <Dialog
        fullWidth={true}
        maxWidth="md"
        className={styles.dialogBox}
        open={this.props.showPopup}
        onClose={this.props.closePopup}
      >
        <div className={classNames(styles.title)}>
          <div className={styles.titleStyle}>Add Domain</div>
          <IconButton
            color="inherit"
            onClick={this.props.closePopup}
            className={classNames(styles.closeIcon)}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className={classNames(styles.domainInput)}>
          <div className={styles.inputWrapper}>
            <TextField
              error={Boolean(this.state.error)}
              helperText={this.state.error}
              onChange={this.onChangeDomain}
              fullWidth
              label="Domain"
              variant="outlined"
              margin="normal"
              value={this.state.domain}
            />
          </div>
          <div className={classNames(styles.actionsRight)}>
            <Button
              className={styles.button}
              variant="contained"
              color="primary"
              onClick={this.handleSubmit}
            >
              Add
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default AddDomainPopup;

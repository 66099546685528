import {
  ACTION_IMPORT_EMPLOYEES_VALIDATE,
  ACTION_IMPORT_EMPLOYEES_VALIDATE_SUCCESS,
  ACTION_IMPORT_EMPLOYEES_CONFIRM_SUCCESS,
  ACTION_IMPORT_EMPLOYEES_VALIDATE_FAILURE,
  ACTION_IMPORT_EMPLOYEES_RESET,
  ACTION_IMPORT_EMPLOYEES_CONFIRM,
} from '../configs/ActionTypes';

const initialState = {
  validating: false,
  validationReport: null,
  success: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ACTION_IMPORT_EMPLOYEES_VALIDATE: {
      return {
        ...state,
        validating: true,
        success: null,
      };
    }
    case ACTION_IMPORT_EMPLOYEES_CONFIRM: {
      return {
        ...state,
        validating: true,
      };
    }

    case ACTION_IMPORT_EMPLOYEES_CONFIRM_SUCCESS: {
      return {
        ...state,
        success: true,
      };
    }

    case ACTION_IMPORT_EMPLOYEES_VALIDATE_SUCCESS: {
      return {
        ...state,
        validationReport: action.payload.payload,
        validating: false,
      };
    }

    case ACTION_IMPORT_EMPLOYEES_RESET: {
      return {
        ...state,
        validationReport: null,
        validating: false,
        success: null,
      };
    }

    default: {
      return state;
    }
  }
}

import React, { Component } from 'react';
import cx from 'classnames';

import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = require('./styles.pcss');

type Props = {
  open: boolean;
  onClose: any;
  onSubmit: any;
  title?: any;
  bodyText?: any;
  submitButtonText?: string;
  closeButtonText?: string;
  disableX?: boolean;
  bodyTextStyle?: any;
  id?: any;
};

const Confirmation = (props: Props) => {
  const boditextStyle = props.bodyTextStyle
    ? cx(styles.body, props.bodyTextStyle)
    : cx(styles.body);
  return (
    <Dialog
      maxWidth={'sm'}
      fullWidth={true}
      className={cx(styles.dialogBox, styles.confirmationDialog)}
      open={props.open}
      onClose={props.onClose}
      style={{ whiteSpace: 'pre-line' }}
    >
      <div className={cx(styles.title, styles.titleFontSizePopupConf)}>
        {props.title}
        {!props.disableX && (
          <IconButton
            color="inherit"
            onClick={props.onClose}
            className={cx(styles.closeIcon)}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        )}
      </div>

      <div className={boditextStyle}>{props.bodyText}</div>

      <div className={cx(styles.content, styles.confirmationPopupButtons)}>
        <Button
          className={styles.cancelButton}
          color="primary"
          onClick={props.onClose}
        >
          {props.closeButtonText || `Close`}
        </Button>

        <Button
          className={styles.button}
          variant="contained"
          color="primary"
          onClick={props.onSubmit}
          id={props.id ? props.id : ''}
        >
          {props.submitButtonText || `Submit`}
        </Button>
      </div>
    </Dialog>
  );
};

export default Confirmation;

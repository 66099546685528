import * as React from 'react';
import cx from 'classnames';
import TextFieldWithClear from 'components/TextFieldWithIcon/TextFieldWithIcon';
import Button from '@material-ui/core/Button';

const styles = require('./styles.pcss');

class CarsRecordForm extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      pnr: '',
      confirmationNumber: '',
    };
  }

  componentDidMount = () => {
    this.onSubmitForm('initial');
  };

  onChangePNR = (event: any) => {
    this.setState({
      pnr: event.target.value,
    });
  };

  onChangeConfirmationNumber = (event: any) => {
    this.setState({
      confirmationNumber: event.target.value,
    });
  };

  clearPNR = () => {
    this.setState({
      pnr: '',
    });
  };

  clearConfirmationNumber = () => {
    this.setState({
      confirmationNumber: '',
    });
  };

  onSubmitForm = (initial?: any) => {
    let req: any = {
      pnr: this.state.pnr,
      confirmationNumber: this.state.confirmationNumber,
    };

    if (initial == 'initial') {
      const urlParams = new URLSearchParams(window.location.search);

      if (urlParams.get('pnr')) {
        req.pnr = urlParams.get('pnr');
      }

      if (urlParams.get('confirmationNumber')) {
        req.confirmationNumber = urlParams.get('confirmationNumber');
      }

      this.setState({
        pnr: req.pnr,
        confirmationNumber: req.confirmationNumber,
      });
    } else {
      let url = 'lookup?type=booking&bookingtype=flight';

      if (this.state.pnr) {
        url += `&pnr=${encodeURIComponent(this.state.pnr)}`;
      }

      if (this.state.confirmationNumber) {
        url += `&confirmationNumber=${encodeURIComponent(
          this.state.confirmationNumber,
        )}`;
      }

      window.history.pushState({}, '', url);
    }

    this.props.onSubmit(req);
  };

  _getSearchButtonText = () => {
    return 'Search';
  };

  onKeyPressed = (e: any) => {
    if (e.key === 'Enter') {
      this.onSubmitForm();
    }
  };

  render() {
    return (
      <div
        className={styles.form}
        onKeyDown={(e) => this.onKeyPressed(e)}
      >
        <div className={styles.inputWrapper}>
          <TextFieldWithClear
            onChange={this.onChangePNR}
            label="PNR"
            variant="outlined"
            margin="normal"
            value={this.state.pnr}
            onClear={this.clearPNR}
            fullWidth={true}
          />
        </div>

        <div className={styles.inputWrapper}>
          <TextFieldWithClear
            onChange={this.onChangeConfirmationNumber}
            label="Airline Conf #"
            variant="outlined"
            margin="normal"
            value={this.state.confirmationNumber}
            onClear={this.clearConfirmationNumber}
            fullWidth={true}
          />
        </div>

        <div className={styles.inputWrapper}>
          <Button
            fullWidth
            className={styles.button}
            variant="contained"
            color="primary"
            onClick={this.onSubmitForm}
          >
            {this._getSearchButtonText()}
          </Button>
        </div>
      </div>
    );
  }
}

export default CarsRecordForm;

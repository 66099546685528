import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import SvgIconRewardsProgram from '../../../static/icons/svgIconRewardsProgram';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ReduxState } from '../../../reducers/types';
import CustomSelect from 'components/Inputs/Select/Select';
import { getOrganizationSettings } from 'actions/Companies/Settings/GetOrganizationSettings';
import { OrganizationSettingsState } from 'reducers/OrganizationSettings';

const styles = require('./styles.pcss');

type Props = {
  company: any;
  organizationSettings: OrganizationSettingsState;
  getOrganizationSettings: Function;
};

type SelectedRewardProgram = 'none' | 'points' | 'rebate';

const rewardsProgramOptions = [
  { key: 'none', value: 'none', label: 'None' },
  { key: 'points', value: 'points', label: 'Points' },
  { key: 'rebate', value: 'rebate', label: 'Rebate' },
];

const RewardsProgram: React.FC<Props> = ({
  company,
  organizationSettings,
  getOrganizationSettings,
}) => {
  const [selectedRewardProgram, setSelectedRewardProgram] =
    useState<SelectedRewardProgram>('none');

  useEffect(() => {
    if (company?.id) {
      getOrganizationSettings(company.id);
    }
  }, []);

  useEffect(() => {
    if (
      organizationSettings.isLoading === false &&
      organizationSettings.success === true
    ) {
      setSelectedRewardProgram(
        organizationSettings.settings['rewardType']?.value || 'none',
      );
    }
  }, [organizationSettings.isLoading, organizationSettings.success]);

  const handleRewardProgramSelect = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const selected: SelectedRewardProgram = event.target
      .value as SelectedRewardProgram;
    setSelectedRewardProgram(selected);
  };

  return (
    <Paper className={styles.paper}>
      <div className={styles.commercialRewardsHeader}>
        <div className={styles.icon}>
          <SvgIconRewardsProgram
            width={32}
            height={32}
          />
        </div>
        <div className={styles.text}>
          <h2>Commercial Rewards</h2>
          <div className={styles.description}>
            Details for the company's rewards program.
          </div>
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.row}>
          <div className={styles.text}>
            <div className={styles.title}>
              <h2>Rewards Program</h2>
            </div>
            <div className={styles.description}>
              <p>The company's reward program: Rebate, Points, or None.</p>
            </div>
          </div>
          <CustomSelect
            margin={'none'}
            variant={'outlined'}
            className={styles.selectWrapper}
            htmlFor={'input-type'}
            label={''}
            id={'rewards-program'}
            value={selectedRewardProgram}
            disabled={true}
            onChange={handleRewardProgramSelect}
            fullWidth={true}
            labelWidth={'0'}
            inputName={'type'}
            menuItems={rewardsProgramOptions}
          />
        </div>
      </div>
    </Paper>
  );
};

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {
    company: state.company.company,
    organizationSettings: state.organizationSettings,
  };
}

// Wire up redux dispatch functions
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getOrganizationSettings,
    },
    dispatch,
  );
}

// Connect it to Redux
export default connect(mapStateToProps, mapDispatchToProps)(RewardsProgram);

import * as React from 'react';
import classNames from 'classnames';
import _ from 'lodash-es';
import { navigate } from '@reach/router';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';

import UserTableHead from 'components/UsersList/UserTableHead';
import UserTableRow from 'components/UsersList/UserTableRow';
import Loader from 'components/Loader/Loader';

import { connect } from 'react-redux';
import { get, emptyResults, Params } from 'actions/Users/Get';
import { bindActionCreators, Dispatch } from 'redux';
import { QUERY_LIMIT } from '../../../configs/General';
import { ReduxState, User } from '../../../reducers/types';

const styles = require('./styles.pcss');

type Props = any;

type State = {
  searchKeyword: string;
};

const appWindow: Window = window;

class UsersBody extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      searchKeyword: '',
    };

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentWillReceiveProps = (nextProps: any) => {
    if (_.get(nextProps, 'users', []).length === 1) {
      const urlParams = new URLSearchParams(window.location.search);
      const email = (urlParams.get('email') ?? '').toLowerCase();
      const firstName = (urlParams.get('firstName') ?? '').toLowerCase();
      const lastName = (urlParams.get('lastName') ?? '').toLowerCase();

      if (
        nextProps.users[0].email.toLowerCase() === email ||
        nextProps.users[0].lastName.toLowerCase() === lastName ||
        nextProps.users[0].firstName.toLowerCase() === firstName
      ) {
        navigate(`/users/${nextProps.users[0].id}`);
      }
    }
  };

  handleScroll() {
    const body = document.body;
    const html = document.documentElement;

    if (!body || !html) {
      return;
    }

    const windowHeight =
      'innerHeight' in appWindow ? appWindow.innerHeight : html.offsetHeight;

    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight,
    );
    const windowBottom = windowHeight + window.pageYOffset;

    if (
      windowBottom >= docHeight &&
      this.props.isLoading === false &&
      this.props.loadedAll === false
    ) {
      const params: any = {
        limit: QUERY_LIMIT,
        skip: this.props.users.length,
        ...this.props.searchParams,
      };

      this.props.get(params);
    }
  }

  componentDidMount() {
    appWindow.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    appWindow.removeEventListener('scroll', this.handleScroll);
    this.props.emptyResults();
  }

  renderTableRow = (user: User, i: number) => {
    return (
      <UserTableRow
        key={i}
        user={user}
      />
    );
  };

  render() {
    if (this.props.users.length == 0 && this.props.loadedAll) {
      return (
        <Paper className={styles.bodyPaper}>
          <div className={styles.notFound}>No records found</div>
        </Paper>
      );
    }
    if (this.props.users.length == 0) {
      return <Loader visible={this.props.isLoading} />;
    }
    return (
      <Paper className={styles.bodyPaper}>
        <Loader visible={this.props.isLoading} />

        <Table>
          <UserTableHead />
          <TableBody>{this.props.users.map(this.renderTableRow)}</TableBody>
        </Table>
      </Paper>
    );
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      get,
      emptyResults,
    },
    dispatch,
  );
}

function mapStateToProps(state: ReduxState) {
  return {
    users: state.users.users,
    isLoading: state.users.isLoading,
    loadedAll: state.users.loadedAll,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersBody);

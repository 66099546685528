import {
  ACTION_GET_BB_AGENCY_PERMISSIONS,
  ACTION_GET_BB_AGENCY_PERMISSIONS_SUCCESS,
  ACTION_GET_BB_AGENCY_PERMISSIONS_FAILURE,
} from '../configs/ActionTypes';

import _ from 'lodash-es';

const initialState = {
  agencyPermissions: [],
  isLoading: false,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_GET_BB_AGENCY_PERMISSIONS: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ACTION_GET_BB_AGENCY_PERMISSIONS_SUCCESS: {
      return {
        ...state,
        agencyPermissions: _.get(action.payload, 'payload', []),
        isLoading: false,
      };
    }

    case ACTION_GET_BB_AGENCY_PERMISSIONS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
}

import * as React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';

const styles = require('./styles.pcss');

const UserTableHead = () => (
  <TableHead
    className={classNames(styles.tableHeadBoxShadow, styles.bbusersTable)}
  >
    <TableRow>
      <TableCell
        component="th"
        scope="row"
        className={styles.cellHide}
      ></TableCell>
      <TableCell className={styles.tableHead}>Name</TableCell>
      <TableCell className={styles.tableHead}>Email</TableCell>
      <TableCell className={styles.tableHead}>Role</TableCell>
      <TableCell className={styles.tableHead}>Status</TableCell>
      <TableCell
        component="th"
        scope="row"
        className={styles.cellHide}
      ></TableCell>
    </TableRow>
  </TableHead>
);

export default UserTableHead;

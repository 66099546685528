/**
 * POST Company
 */
import _ from 'lodash-es';
import { Dispatch } from 'redux';
import { track } from '../../analytics/analytics';

import {
  ACTION_POST_COMPANY,
  ACTION_POST_COMPANY_SUCCESS,
  ACTION_POST_COMPANY_FAILURE,
  ACTION_RESET_COMPANY_SUCCESS,
  API_CALL,
} from '../../configs/ActionTypes';

import { enqueueSnackbarHandler } from '../Status';

/**
 * POST Users Request Action
 */
export function add(dataToSend: any) {
  return (dispatch: Dispatch, getState: Function) => {
    dispatch({
      type: ACTION_POST_COMPANY,
      payload: null,
    });

    //Dispatch the api call request.
    dispatch({
      type: API_CALL,
      payload: {
        call: 'organization',
        method: 'post',
        data: JSON.stringify(dataToSend),
        success: (response: object) => {
          dispatch(requestSuccess(response));
          handleTrack(response);
        },
        fail: (error: any) => {
          enqueueSnackbarHandler(dispatch, error.payload, 'error');
          dispatch(requestFailed(error));
        },
      },
    });

    return null;
  };
}

export function resetSaveCompanyStatus() {
  return (dispatch: Dispatch, getState: Function) => {
    // Dispatch the api call request.
    dispatch({
      type: ACTION_RESET_COMPANY_SUCCESS,
      payload: null,
    });
  };
}

function requestSuccess(responeJSON: object) {
  return {
    type: ACTION_POST_COMPANY_SUCCESS,
    payload: responeJSON,
  };
}

function requestFailed(responeJSON: object) {
  return {
    type: ACTION_POST_COMPANY_FAILURE,
    payload: responeJSON,
  };
}

function handleTrack(data: any) {
  if (data.payload.id !== undefined) {
    let properties = {
      Company: data.payload.id,
    };

    track('BB_CompanyCreated', properties);
  }
}

import React from 'react';

type Props = {
  width: number;
  height: number;
};

const SvgTvIcon = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 16 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs></defs>
      <g
        id="(WIP)-Refund-Card"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Booking-Macro---Flight---Flight-Modal"
          transform="translate(-1006.000000, -571.000000)"
        >
          <g
            id="create-modal"
            transform="translate(370.000000, 143.000000)"
          >
            <g id="content">
              <g
                id="Flight-Card"
                transform="translate(1.000000, 77.000000)"
              >
                <g
                  id="amenities-1"
                  transform="translate(31.000000, 333.000000)"
                >
                  <g
                    id="Group-17"
                    transform="translate(16.000000, 16.000000)"
                  >
                    <g
                      id="Group-16"
                      transform="translate(532.000000, 2.000000)"
                    >
                      <g
                        id="TV"
                        transform="translate(56.000000, 0.000000)"
                      >
                        <rect
                          id="Rectangle"
                          stroke="#5F6670"
                          strokeWidth="2"
                          x="1"
                          y="1"
                          width="14"
                          height="12"
                          rx="1"
                        ></rect>
                        <path
                          d="M8.54504798,8.70611658 C8.24397354,9.09792655 7.75588859,9.09799573 7.45476099,8.70611658 L5.15205207,5.7094347 C4.85097764,5.31762474 5.01108105,5 5.5080359,5 L10.4917731,5 C10.9894519,5 11.1488845,5.31755556 10.8477569,5.7094347 L8.54504798,8.70611658 Z"
                          id="Triangle"
                          fill="#5F6670"
                          transform="translate(8.000000, 7.000000) rotate(-90.000000) translate(-8.000000, -7.000000) "
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgTvIcon;

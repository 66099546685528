import React from 'react';

type Props = {
  width: number;
  height: number;
};

const SvgGreenCheck = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="8"
        cy="8"
        r="7.5"
        fill="#12DA9F"
        stroke="white"
      />
      <path
        d="M4 8.57143L6.27273 11L12 5"
        stroke="white"
        stroke-width="1.5"
      />
    </svg>
  );
};

export default SvgGreenCheck;

import * as React from 'react';
const styles = require('./styles.pcss');
import SvgRightArrowIcon from '../../static/icons/svgRightArrowIcon';

type Props = {
  currentPage: any;
  maxPage: any;
  onClickNextPage: any;
  onClickPreviousPage: any;
};

const Pagination = (props: Props) => {
  let colorNext,
    colorPrev = '#E0E2E6';

  if (props.currentPage < props.maxPage) {
    colorNext = '#318AFB';
  }

  if (props.currentPage > 1) {
    colorPrev = '#318AFB';
  }

  return (
    <div className={styles.paginationPageCont}>
      <div className={styles.paginationContainer}>
        <div
          className={styles.buttonPaginationPrev}
          onClick={props.currentPage > 1 ? props.onClickPreviousPage : () => {}}
        >
          {' '}
          <SvgRightArrowIcon
            width={13}
            height={13}
            color={colorPrev}
          />{' '}
        </div>
        <div className={styles.numberHolder}>
          {' '}
          {props.currentPage} of {props.maxPage}{' '}
        </div>
        <div
          className={styles.buttonPagination}
          onClick={
            props.currentPage < props.maxPage ? props.onClickNextPage : () => {}
          }
        >
          {' '}
          <SvgRightArrowIcon
            width={13}
            height={13}
            color={colorNext}
          />{' '}
        </div>
      </div>
    </div>
  );
};

export default Pagination;

import * as React from 'react';
import _ from 'lodash-es';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import LayoutDashboard from 'components/Layout/Dashboard';
import Loader from 'components/Loader/Loader';
import Travelers from 'components/BookingDetails/Travelers';
import FlightDetails from 'components/BookingDetails/Flight/FlightDetails';
import AdditionalFlightDetails from 'components/BookingDetails/AdditionalFlightDetails';
import Notes from 'components/Notes/Notes';
import PaymentInfo from 'components/BookingDetails/Payment/PaymentInfo';
import Segments from 'components/BookingDetails/Flight/Segments';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/Breadcrumbs/Breadcrumb';
import SendEmailConfirmationPopup from 'components/Popup/SendEmailConfirmationPopup';
import NewPaymentPopup from 'components/Popup/NewPaymentPopup';
import HotelDetails from 'components/BookingDetails/Hotel/HotelDetails';
import HotelBookingDetails from 'components/BookingDetails/Hotel/HotelBookingDetails';
import Transactions from 'components/BookingDetails/Transactions/Transactions';
import AdditionalBookingDetails from 'components/BookingDetails/AdditionalBookingDetails';
import EditCustomCategoriesBookingPopup from 'components/Popup/EditCustomCategoriesBookingPopup';
import ConfirmationPopup from 'components/Popup/Confirmation';
import ModifyBookingDetailsPopup from 'components/Popup/ModifyBookingDetailsPopup';
import CarDetails from 'components/BookingDetails/Car/CarDetails';
import CarBookingDetails from 'components/BookingDetails/Car/CarBookingDetails';
import BookingSyncError from 'components/BookingDetails/SyncError/BookingSyncError';
import { get as getCreditCards } from 'actions/User/GetUserCreditCards';
import { hasPermission } from '../../helpers/Permission';
import cx from 'classnames';
import { navigate } from '@reach/router';
import { sendEmail } from 'actions/Bookings/SendEmail';
import { putCustomTags } from 'actions/Bookings/PutCustomTag';
import {
  getCancelInfo,
  emptyCancelInfoResults,
} from 'actions/Bookings/GetCancelInfo';
import {
  emptyCancelResults,
  cancelFlightBooking,
} from 'actions/Bookings/CancelBooking';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ReduxState } from 'reducers/types';
import { getSingle as getBooking } from 'actions/Bookings/GetSingle';
import { getProviderDisplayName } from '../../helpers/getProviderDisplayName';
import { syncDuffelBooking } from 'actions/Bookings/SyncDuffelBooking';
const styles = require('./styles.pcss');
import { refund } from 'actions/Bookings/RefundBooking';
import AddEditFlightCredit from 'components/Popup/AddFlightCreditPopup';
import {
  PostFlightCreditParams,
  postBookingFlightCredit,
} from 'actions/FlightCredits/PostFlightCredit';

type Props = any;

export type Params = {
  bookingId: string;
};

type State = {
  openConfirmation: boolean;
  openNewPaymentPopup: boolean;
  openModifyDetails: boolean;
  id: string;
  additionalEmails: any;
  selectedPNR: string;
  userEmail: string;
  activeTabIndex: number;
  openCategories: boolean;
  openFlightCancelConfirmation: boolean;
  openFlightCreditPopup: boolean;
  curFraudRule: string;
};

class BookingDetails extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      openConfirmation: false,
      id: '',
      additionalEmails: '',
      selectedPNR: '',
      userEmail: '',
      activeTabIndex: 0,
      openNewPaymentPopup: false,
      openCategories: false,
      openFlightCancelConfirmation: false,
      openModifyDetails: false,
      curFraudRule: '',
      openFlightCreditPopup: false,
    };
  }

  componentDidMount() {
    const params: Params = {
      bookingId: this.props.bookingId,
    };

    this.props.getBooking(this.props.bookingId);
  }

  componentWillReceiveProps = (nextProps: Props) => {
    if (
      this.props.booking.isLoading == true &&
      nextProps.booking.isLoading !== true
    ) {
      this.closeCategoriesPopup();
      this.props.getCreditCards(_.get(nextProps.booking, 'booking.userId', ''));
    }

    if (nextProps.cancelBookingState.success !== null) {
      this.props.emptyCancelResults();
    }

    if (
      this.props.bookingCancelInfo.isLoading == true &&
      nextProps.bookingCancelInfo.success == false
    ) {
      this.props.emptyCancelInfoResults();
    }
  };

  closeConfirmation = () => {
    this.setState({
      openConfirmation: false,
      id: '',
      selectedPNR: '',
      userEmail: '',
    });
  };

  closeModify = () => {
    this.setState({
      openModifyDetails: false,
      id: '',
      userEmail: '',
    });
  };

  onCancelNewPaymentPopup = () => {
    this.setState({
      openNewPaymentPopup: false,
    });
  };

  onOpenNewPaymentPopup = () => {
    let pnr = _.get(this.props.booking, 'booking.pnr', '');
    const flightCreditDbRecordExists = _.get(
      this.props.booking,
      'booking.flightCreditRecord.ticketNumber',
      '',
    );
    const flightCreditNumber = _.get(
      this.props.booking,
      'booking.flight.appliedFlightCreditNumber',
      '',
    );
    const hasAttemptedToAddFlightCredit = _.get(
      this.props.booking,
      'attemptedFlightCreditAdd',
      '',
    );
    if (
      !flightCreditDbRecordExists &&
      flightCreditNumber &&
      !hasAttemptedToAddFlightCredit
    ) {
      this.setState({ openFlightCreditPopup: true });
    } else {
      this.setState({
        openNewPaymentPopup: true,
        selectedPNR: pnr,
      });
    }
  };

  onSubmitRefundPopup = (id: string, amount: number) => {
    const relatedTransaction: any = _.find(
      _.get(this.props.booking, 'booking.transactions', []),
      (t) => t.id == id,
    );
    this.props.refund(
      id,
      _.get(this.props.booking, 'booking.type', ''),
      _.get(relatedTransaction, 'type', '') == 'brexPoints'
        ? 'brex points'
        : 'credit card',
      amount,
    );
  };

  closeCategoriesPopup = () => {
    this.setState({
      openCategories: false,
    });
  };

  open = () => {
    this.setState({
      openCategories: true,
    });
  };

  onCLickEditTags = (event: any) => {
    this.setState({
      openCategories: true,
    });
  };

  closeFlightCancelConfirmation = () => {
    this.setState({
      openFlightCancelConfirmation: false,
    });
  };

  onSubmitSend = (additionEmails: any) => {
    if (this.state.additionalEmails !== '') {
      additionEmails = `${this.state.additionalEmails}, ${additionEmails}`;
    }

    this.setState({
      openConfirmation: false,
      id: '',
      selectedPNR: '',
      additionalEmails: additionEmails,
      userEmail: '',
    });
  };

  onSubmitModify = () => {
    this.setState({
      openModifyDetails: false,
      id: '',
      userEmail: '',
    });
  };

  onClickEmailSend = (event: any) => {
    let id = _.get(this.props.booking, 'booking.id', '');
    let pnr = _.get(this.props.booking, 'booking.pnr', '');
    let additionalEmails = _.get(
      this.props.booking,
      'booking.additionalEmails',
      null,
    );
    let userEmail = _.get(this.props.booking, 'booking.user.email', '');

    this.setState({
      id: id,
      selectedPNR: pnr,
      openConfirmation: true,
      userEmail: userEmail,
    });
  };

  onClickModify = (event: any) => {
    let id = _.get(this.props.booking, 'booking.id', '');
    let pnr = _.get(this.props.booking, 'booking.pnr', '');
    let userEmail = _.get(this.props.booking, 'booking.user.email', '');

    this.setState({
      id: id,
      openModifyDetails: true,
      userEmail: userEmail,
    });
  };

  onClickCancelFlightButton = (event: any) => {
    this.setState({
      openFlightCancelConfirmation: true,
    });
  };

  handleViewPolicy = (event: any) => {
    navigate(
      `/users/${_.get(
        this.props.booking,
        'booking.userId',
        '',
      )}?activeTab=travel-policy`,
    );
  };

  onSubmitFlightCancel = () => {
    this.props.cancelFlightBooking({ bookingId: this.props.bookingId });
    this.closeFlightCancelConfirmation();
  };

  onTabChange = (e: any, idx: number) => {
    this.setState({
      activeTabIndex: idx,
    });
  };

  onSyncDuffel = () => {
    this.props.syncDuffelBooking(
      _.get(this.props.booking, 'booking.userId', ''),
      _.get(this.props.booking, 'booking.orderId', ''),
    );
  };

  closeFlightCreditPopup = () => {
    this.setState({ openFlightCreditPopup: false });
  };

  submitBookingFlightCredit = (params: PostFlightCreditParams) => {
    this.props.postBookingFlightCredit(params);
    this.onOpenNewPaymentPopup();
  };

  getProvider = (type: string) => {
    switch (type) {
      case 'flight':
        const segments = _.get(this.props.booking, 'booking.segments', []);
        return segments?.[0]?.segment?.[0]?._meta?.provider;
      case 'hotel':
        return _.get(
          this.props.booking,
          'booking.hotel.roomDetails.0._meta.provider',
          '',
        );
      case 'car':
        return _.get(this.props.details, '_meta.providerName', 'See dev team');
      default:
        return '';
    }
  };

  renderDetails = (type: string) => {
    if (type == 'flight') {
      const segments = _.get(this.props.booking, 'booking.segments', []);
      const provider =
        segments?.[0]?.segment?.[0]?._meta?.provider || 'Unknown';
      const providerCode = _.get(
        this.props.booking,
        'booking.providerCodes.uapiProviderCode',
        'Provider Code Unkown',
      );
      const providerName = getProviderDisplayName(provider, providerCode);

      let basePrice = _.get(
        this.props.booking,
        'booking.reservations.[0].priceInfo.BasePrice',
        '',
      );
      let passengers = _.get(
        this.props.booking,
        'booking.passengers',
        [],
      ).length;
      let taxes = _.get(
        this.props.booking,
        'booking.reservations.[0].priceInfo.Taxes',
        '',
      );
      let totalPrice = _.get(this.props.booking, 'booking.customerPrice', '');

      if (taxes) {
        taxes = taxes.replace(/^USD/, '');
        taxes = parseFloat(taxes);
      }

      if (basePrice) {
        basePrice = basePrice.replace(/^USD/, '');
        basePrice = parseFloat(basePrice);
      }

      return (
        <FlightDetails
          basePrice={basePrice}
          providerName={providerName}
          taxes={taxes}
          taxesArray={_.get(
            this.props.booking,
            'booking.reservations[0].priceInfo.TaxesInfo',
            [],
          )}
          passenger={passengers}
          totalPrice={totalPrice}
        />
      );
    }

    if (type == 'hotel') {
      return (
        <HotelDetails details={_.get(this.props.booking, 'booking', '')} />
      );
    }
    return <CarDetails details={_.get(this.props.booking, 'booking', '')} />;
  };

  renderBookingsDetail = (type: string) => {
    if (type == 'flight') {
      return (
        <Segments
          seatAssignments={_.get(
            this.props.booking,
            'booking.seatAssignments',
            [],
          )}
          segments={_.get(this.props.booking, 'booking.segments', [])}
          confirmationNumbers={_.get(
            this.props.booking,
            'booking.confirmationNumbers',
            [],
          )}
          locations={_.get(this.props.booking, 'booking.airportLocation', [])}
          passengers={_.get(this.props.booking, 'booking.passengers', [])}
        />
      );
    }

    if (type == 'hotel') {
      return (
        <HotelBookingDetails
          booking={_.get(this.props.booking, 'booking', '')}
        />
      );
    }

    return (
      <CarBookingDetails booking={_.get(this.props.booking, 'booking', '')} />
    );
  };

  renderCancelButton = (type: string, provider: string, status: string) => {
    if (status == 'canceled') {
      return null;
    }

    if (
      type == 'flight' &&
      _.get(this.props.booking, 'booking.customerPrice', 0)
    ) {
      return (
        <div
          className={styles.cancelButton}
          onClick={this.onClickCancelFlightButton}
        >
          Cancel
        </div>
      );
    }

    return null;
  };

  renderFraud = (isFraud: any) => {
    if (isFraud) {
      return <div className={cx(styles.fraud, styles.canceled)}>Fraud</div>;
    }
    return;
  };

  renderStatus = (status: any) => {
    let style = '';

    switch (status) {
      case 'Completed':
        style = cx(styles.buttonCompleted);
        break;
      case 'Canceled':
        style = cx(styles.buttonCompleted, styles.canceled);
        break;
      case 'Errored':
        style = cx(styles.buttonCompleted, styles.canceled);
        break;
      case 'Pending':
        style = cx(styles.buttonCompleted, styles.pending);
        break;
      default:
        return null;
    }

    return <div className={style}>{status}</div>;
  };

  renderLoader = () => {
    return (
      <div className={styles.loaderContainer}>
        <Loader visible={true} />
      </div>
    );
  };

  onSubmitCategories = (tags: any) => {
    this.props.putCustomTags({
      tags: tags,
      bookingId: _.get(this.props.booking, 'booking.id', ''),
    });
  };

  renderBookingSyncError = () => {
    return (
      <BookingSyncError
        syncError={_.get(this.props.booking, 'booking.syncError', null)}
      />
    );
  };

  renderLoading = () => {
    if (
      _.get(this.props.bookingCancelInfo, 'isLoading', false) ||
      _.get(this.props.cancelBookingState, 'isLoading', false)
    ) {
      return (
        <div className={styles.loaderContainer}>
          <Loader visible={true} />
        </div>
      );
    }

    return null;
  };

  renderDuffelSync = (provider: string, type: string) => {
    if (provider === 'duffel' && type === 'flight') {
      return (
        <div
          className={styles.sendEmailButton}
          onClick={this.onSyncDuffel}
        >
          Sync Duffel
        </div>
      );
    }
    return;
  };

  render() {
    let breadcrumbName = _.get(this.props.booking, 'booking.pnr', '');
    let status = _.get(this.props.booking, 'booking.status', '');
    let isFraud = _.get(this.props.booking, 'booking.isFraud', '');

    status = status.charAt(0).toUpperCase() + status.slice(1);

    let type = _.get(this.props.booking, 'booking.type', '');
    const provider = this.getProvider(type);

    if (this.props.booking.isLoading) {
      return (
        <LayoutDashboard>
          <Loader
            visible={true}
            id={'bookingLoad'}
          />
        </LayoutDashboard>
      );
    }

    const propsAdditionalEmails =
      _.get(this.props.booking, 'booking.additionalEmails', '') === '' ||
      _.get(this.props.booking, 'booking.additionalEmails', '') === null ||
      _.get(this.props.booking, 'booking.additionalEmails', '') === undefined
        ? ''
        : this.props.booking.booking.additionalEmails;

    const additionEmails =
      propsAdditionalEmails !== '' && this.state.additionalEmails !== ''
        ? `${propsAdditionalEmails}, ${this.state.additionalEmails}`
        : `${propsAdditionalEmails}${this.state.additionalEmails}`;

    let tags = _.union(
      _.get(this.props.booking, 'booking.possibleTags', []),
      _.get(this.props.booking, 'booking.tags', []),
    );
    let possibleTagsArray = tags.map((data: any) => ({
      value: data.name,
      key: data.id,
    }));

    possibleTagsArray = _.uniqWith(possibleTagsArray, _.isEqual);
    const selectedTags = _.get(this.props.booking, 'booking.tags', []).map(
      (data: any) => data.id,
    );
    const isBrexPoints =
      _.get(this.props.booking, 'booking.alternativePaymentType', false) ==
      'brexPoints';
    const cardNumber = _.get(
      this.props.booking,
      'booking.paymentInfo.last4',
      '',
    );
    const cancelInfo = this.props.bookingCancelInfo.cancelInfo;

    return (
      <LayoutDashboard>
        <div>
          <Breadcrumbs className={styles.breadcrumbs}>
            <Breadcrumb
              name="Lookup"
              arrow
            />
            <Breadcrumb name={breadcrumbName} />
          </Breadcrumbs>
        </div>

        <div className={styles.headerPnr}>
          <div className={styles.id}>
            <span id="bookingPNR">{breadcrumbName}</span>
            {this.renderStatus(status)}
            {this.renderFraud(isFraud)}
          </div>
          <div className={styles.buttonContainer}>
            {hasPermission('bookings', 'update') && (
              <div
                className={styles.sendEmailButton}
                onClick={this.onClickModify}
              >
                Modify
              </div>
            )}
            <div
              className={styles.sendEmailButton}
              onClick={this.onClickEmailSend}
            >
              Send Email
            </div>
            {this.renderCancelButton(
              type,
              provider,
              _.get(this.props.booking, 'booking.status', ''),
            )}

            {hasPermission('creditCards', 'charge') && type !== 'car' && (
              <div
                className={styles.sendEmailButton}
                onClick={this.onOpenNewPaymentPopup}
              >
                Add Charge
              </div>
            )}

            {this.renderDuffelSync(provider, type)}
          </div>
        </div>

        <div className={styles.loaderWrapper}>
          {type !== 'car' && (
            <Tabs
              className={styles.tabs}
              value={this.state.activeTabIndex}
              indicatorColor="primary"
              textColor="primary"
              onChange={this.onTabChange}
            >
              <Tab
                className={styles.tab}
                label="Details"
              />
              <Tab
                className={styles.tab}
                label="Transactions"
              />
            </Tabs>
          )}
          {this.renderDetailsTab()}
          {this.renderTransactionsTab()}
        </div>

        <SendEmailConfirmationPopup
          open={this.state.openConfirmation}
          onClose={this.closeConfirmation}
          onSubmit={this.onSubmitSend}
          id={this.state.id}
          type={_.get(this.props.booking, 'booking.type', '')}
          identifier={this.state.selectedPNR}
          additionalEmails={additionEmails}
          userEmail={_.get(
            this.props.booking,
            'booking.travelers[0].user.email',
            '',
          )}
        />

        <ModifyBookingDetailsPopup
          open={this.state.openModifyDetails}
          onClose={this.closeModify}
          onSubmit={this.onSubmitModify}
          id={this.state.id}
          confirmationNumber={_.get(
            this.props.booking,
            'booking.confirmationNumber',
            '',
          )}
          status={_.get(this.props.booking, 'booking.status', '')}
          type={_.get(this.props.booking, 'booking.type', '')}
          identifier={this.state.selectedPNR}
          additionalEmails={additionEmails}
          userEmail={_.get(this.props.booking, 'booking.user.email', '')}
          notes={_.get(this.props.booking, 'booking.notes', '')}
        />

        <NewPaymentPopup
          open={this.state.openNewPaymentPopup}
          in_process={_.get(this.props.user, 'isLoading', false)}
          onCancel={this.onCancelNewPaymentPopup}
          listOfCreditCards={_.get(this.props.user, 'user.creditCards', [])}
          bookingId={_.get(this.props.booking, 'booking.id', '')}
          pnr={_.get(this.props.booking, 'booking.pnr', '')}
          type={_.get(this.props.booking, 'booking.type', '')}
          rateType={_.get(this.props.booking, 'booking.rateType', '')}
          userId={_.get(this.props.booking, 'booking.userId', '')}
          isFlightCreditApplied={
            !!_.get(this.props.booking, 'booking.isFlightCreditApplied', '')
          }
          organizationId={_.get(
            this.props.booking,
            'booking.organizationId',
            '',
          )}
          getCreditCards={this.props.getCreditCards}
          user={_.get(this.props.booking, 'booking.user', false)}
        />
        <AddEditFlightCredit
          open={this.state.openFlightCreditPopup}
          close={this.closeFlightCreditPopup}
          submit={this.submitBookingFlightCredit}
          bookingId={_.get(this.props.booking, 'booking.id', '')}
          title="The synced PNR shows a flight credit was used but we don't have it in our system. Please add the flight credit used for this booking before entering the transaction amount"
        />

        {this.props.isLoadingRefund && this.renderLoader()}

        {type !== 'car' && (
          <EditCustomCategoriesBookingPopup
            open={this.state.openCategories}
            onCancel={this.closeCategoriesPopup}
            submit={this.onSubmitCategories}
            possibleTags={possibleTagsArray}
            defValue={selectedTags}
          />
        )}

        <ConfirmationPopup
          open={this.state.openFlightCancelConfirmation}
          onClose={this.closeFlightCancelConfirmation}
          onSubmit={this.onSubmitFlightCancel}
          title="Cancel Booking?"
          bodyText={`A refund of $${
            _.get(this.props.booking, 'booking.customerPrice', 0)
              ? _.get(this.props.booking, 'booking.customerPrice', 0).toFixed(2)
              : ' - - '
          } will be made to the credit card ending in ${cardNumber}`}
          submitButtonText="Continue"
          closeButtonText="Cancel"
          disableX={true}
        />

        {this.renderLoading()}
      </LayoutDashboard>
    );
  }

  renderDetailsTab() {
    if (this.state.activeTabIndex !== 0) {
      return null;
    }

    const type = _.get(this.props.booking, 'booking.type', '');
    const passengers = _.map(
      _.get(this.props.booking, 'booking.travelers', []),
      (passenger: any) => {
        _.set(
          passenger,
          'ffCardMap',
          _.get(this.props.booking, 'booking.passengers.0.ffCardMap', null),
        );
        return passenger;
      },
    );
    let operatingAirline = _.get(
      this.props.booking,
      'booking.segments[0].operatingAirline',
      '-',
    );
    if (operatingAirline === '-') {
      operatingAirline = _.get(this.props.booking, 'booking.merchant', '-');
    }
    return (
      <div className={styles.container}>
        <div className={styles.content}>
          {this.renderBookingsDetail(type)}

          <Travelers
            type={type}
            traveler={_.get(this.props.booking, 'booking.traveler[0]', '')}
            passengers={passengers}
            isLoading={_.get(this.props.booking, 'isLoading', true)}
          />

          {type !== 'car' && (
            <AdditionalBookingDetails
              tags={_.get(this.props.booking, 'booking.tags', [])}
              onCLickEdit={this.onCLickEditTags}
            />
          )}

          {type === 'flight' && (
            <AdditionalFlightDetails
              ticketNumber={_.get(
                this.props.booking,
                'booking.ticketNumbers[0]',
                '-',
              )}
              source={_.get(this.props.booking, 'booking.source', '-')}
              organizationId={_.get(
                this.props.booking,
                'booking.organizationId',
                '-',
              )}
              operatedBy={operatingAirline}
              clickViewPolicy={this.handleViewPolicy}
            />
          )}

          <Notes
            wider={false}
            id={this.props.bookingId}
            entity={_.get(this.props.booking, 'booking')}
            type="booking"
          />
        </div>
        <div className={styles.sidebar}>
          {this.renderDetails(type)}
          {(!_.isEmpty(_.get(this.props.booking, 'booking.paymentInfo', {})) ||
            _.get(
              this.props.booking,
              'booking.alternativePaymentType',
              false,
            ) == 'brexPoints') &&
            type !== 'car' && (
              <PaymentInfo booking={_.get(this.props.booking, 'booking', {})} />
            )}
          {this.renderBookingSyncError()}
        </div>
      </div>
    );
  }

  renderTransactionsTab() {
    if (this.state.activeTabIndex !== 1) {
      return null;
    }

    const userCreditCards = _.get(this.props.user, 'user.creditCards', []);

    return (
      <Transactions
        transactions={_.get(this.props.booking, 'booking.transactions', [])}
        refundable={hasPermission('creditCards', 'refund')}
        onClickRefund={this.onSubmitRefundPopup}
        organization={_.get(this.props.booking, 'booking.organization', [])}
        bookingPnr={_.get(this.props.booking, 'booking.pnr', '')}
      />
    );
  }
}

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {
    booking: state.booking,
    bookingCancelInfo: state.bookingCancelInfo,
    cancelBookingState: state.cancelBooking,
    user: state.user,
    isLoadingRefund: state.bookingRefund.isLoading,
  };
}

// Wire up redux dispatch functions
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getBooking,
      sendEmail,
      putCustomTags,
      getCancelInfo,
      syncDuffelBooking,
      emptyCancelInfoResults,
      emptyCancelResults,
      getCreditCards,
      refund,
      cancelFlightBooking,
      postBookingFlightCredit,
    },
    dispatch,
  );
}

// Connect it to Redux
export default connect(mapStateToProps, mapDispatchToProps)(BookingDetails);

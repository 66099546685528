/**
 * GET BOOKINGS Action
 */

import {
  ACTION_GET_BOOKINGS,
  ACTION_GET_BOOKINGS_SUCCESS,
  ACTION_GET_BOOKINGS_FAILURE,
  ACTION_EMPTY_BOOKINGS,
  API_CALL,
} from '../../configs/ActionTypes';

/**
 * POST Users Request Action
 */
export function get(params) {
  return (dispatch, getState) => {
    dispatch(requestStarted());

    //Dispatch the api call request.
    dispatch({
      type: API_CALL,
      payload: {
        call: 'bookings',
        method: 'get',
        data: params || {},
        success: (response) => {
          if (response.type === 'error') {
            dispatch(requestFailed(response));
          } else {
            dispatch(requestSuccess(response));
          }
        },
        fail: (error) => {
          // dispatch(showStatus(error));
          dispatch(requestFailed(error));
        },
      },
    });

    return null;
  };
}

export function emptyResults() {
  return (dispatch) => {
    dispatch({
      type: ACTION_EMPTY_BOOKINGS,
      payload: null,
    });
  };
}

export function requestStarted() {
  return {
    type: ACTION_GET_BOOKINGS,
    payload: null,
  };
}

export function requestSuccess(responseJSON) {
  return {
    type: ACTION_GET_BOOKINGS_SUCCESS,
    payload: responseJSON,
  };
}

export function requestFailed(responseJSON) {
  return {
    type: ACTION_GET_BOOKINGS_FAILURE,
    payload: responseJSON,
  };
}

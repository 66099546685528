import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import _ from 'lodash-es';
import { navigate, redirectTo } from '@reach/router';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { AIRLINE_NAME_MAP } from '../../helpers/airlinemap';
import AutoSuggest from 'components/Inputs/AutoSuggest/AutoSuggest';

import { putNegotiatedHotels } from '../../actions/Companies/PutNegotiatedHotels';
import { putFlightTourCodes } from '../../actions/Companies/Settings/PutFlightTourCodes';

import { ReduxState } from '../../reducers/types';

const styles = require('./styles.pcss');

type Props = {
  showPopup: boolean;
  closePopup: any;
  companyDetails: any;
  negotiatedHotels: any;
  putNegotiatedHotels: Function;
  putFlightTourCodes: Function;
  type: any;
  flightTourCodes: any;
};

type State = {
  supplier: any;
  hotelError: any;
  propertyId: any;
  propertyIdError: any;
};

class AddNegotiatedHotelPopup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      supplier: '',
      hotelError: '',
      propertyId: '',
      propertyIdError: '',
    };
  }

  onChangeHotel = (event: any) => {
    this.setState({
      supplier: event.target.value,
      hotelError: '',
    });
  };

  onchangePropertyId = (event: any) => {
    this.setState({
      propertyId: event.target.value,
      propertyIdError: '',
    });
  };

  validFields = () => {
    let isValid = true;
    let hotelError = '';
    let propertyIdError = '';

    if (
      _.get(this.state.supplier, 'label', this.state.supplier).trim() === ''
    ) {
      hotelError = 'Required';
      isValid = false;
    }

    if (this.state.propertyId.trim() === '') {
      propertyIdError = 'Required';
      isValid = false;
    }

    this.setState({
      hotelError,
      propertyIdError,
    });

    return isValid;
  };

  handleSubmit = () => {
    if (!this.validFields()) {
      return;
    }

    if (this.props.type == 'flight') {
      const param = {
        airlineCode: _.get(this.state.supplier, 'value', ''),
        number: this.state.propertyId.trim(),
      };

      let value = _.clone(
        _.get(this.props.flightTourCodes, 'flightTourCodes.value', []),
      );
      value.push(param);
      value = _.uniqWith(value, _.isEqual);

      this.props.putFlightTourCodes({
        value: value,
        organizationId: this.props.companyDetails.id,
      });
    } else {
      this.props.putNegotiatedHotels({
        name: this.state.supplier.trim(),
        identifier: this.state.propertyId.trim(),
        organizationId: this.props.companyDetails.id,
      });
    }

    this.props.closePopup();
  };

  onChangeAirline = (value: any) => {
    this.setState({
      supplier: value,
    });
  };

  renderFirstField = () => {
    if (this.props.type == 'flight') {
      let airlines: any = [];

      _.forOwn(AIRLINE_NAME_MAP, function (value, key) {
        airlines.push({ code: key, name: value });
      });

      airlines = airlines.sort((a: any, b: any) =>
        a.name.localeCompare(b.name),
      );

      return (
        <div className={styles.inputWrapper}>
          <AutoSuggest
            className={styles.dropdownContainer}
            onChange={(value: any) => {
              this.onChangeAirline(value);
            }}
            label={'Airline'}
            value={_.get(this.state.supplier, 'label', '')}
            dataSet={airlines}
            maxMenuHeight={90}
            returnAll={true}
            error={this.state.hotelError}
          />
        </div>
      );
    }

    return (
      <div className={styles.inputWrapper}>
        <TextField
          error={Boolean(this.state.hotelError)}
          helperText={this.state.hotelError}
          onChange={this.onChangeHotel}
          className={styles.textField}
          fullWidth
          label={'Hotel'}
          variant="outlined"
          margin="normal"
          value={this.state.supplier}
        />
      </div>
    );
  };

  render() {
    let title = 'Add Hotel';
    let codeText = 'Property Id';

    if (this.props.type == 'flight') {
      title = 'Add TOUR Code';
      codeText = 'TOUR Code';
    }

    return (
      <Dialog
        fullWidth={true}
        maxWidth="md"
        className={classNames(styles.dialogBox)}
        open={this.props.showPopup}
        onClose={this.props.closePopup}
      >
        <div className={classNames(styles.title)}>
          <div className={styles.titleStyle}>{title}</div>
          <IconButton
            color="inherit"
            onClick={this.props.closePopup}
            className={classNames(styles.closeIcon)}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        </div>

        <div className={classNames(styles.content, styles.negotiatedPopup)}>
          <div className={styles.inputContainerAddCompany}>
            {this.renderFirstField()}
            <div className={styles.inputWrapper}>
              <TextField
                error={Boolean(this.state.propertyIdError)}
                helperText={this.state.propertyIdError}
                className={styles.textField}
                onChange={this.onchangePropertyId}
                fullWidth
                label={codeText}
                variant="outlined"
                margin="normal"
                value={this.state.propertyId}
              />
            </div>
          </div>
          <div className={classNames(styles.actionsRight)}>
            <Button
              className={styles.button}
              variant="contained"
              color="primary"
              onClick={this.handleSubmit}
            >
              Add
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {
    companyDetails: state.company.company,
    negotiatedHotels: state.negotiatedHotels,
    flightTourCodes: state.flightTourCodes,
  };
}

// Wire up redux dispatch functions
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      putNegotiatedHotels,
      putFlightTourCodes,
    },
    dispatch,
  );
}

// Connect it to Redux
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddNegotiatedHotelPopup);

import * as React from 'react';
import cx from 'classnames';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import _ from 'lodash-es';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import SvgAddIcon from '../../static/icons/svgAddIcon';
import AddCorporateRateCodePopup from 'components/Popup/AddCorporateRateCodePopup';
import Loader from 'components/Loader/Loader';
import RateCodeTableRow from './RateCodeTableRow';
import ConfirmationPopup from 'components/Popup/Confirmation';

const styles = require('./styles.pcss');

type Props = {
  flightCodes: any;
  hotelCodes: any;
  carCodes?: any;
  organization?: any;
  putCorporateCodes: Function;
  isLoading: any;
  hasPermission: boolean;
  carLoyaltyProgram?: any;
};

type State = {
  addNewOpen: boolean;
  openConfirmation: boolean;
  selectedCode: any;
  tabValue: string;
};

class CorporateRateCodes extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      tabValue: 'flight',
      addNewOpen: false,
      openConfirmation: false,
      selectedCode: null,
    };
  }

  handleTabChange = (event: any, value: any) => {
    this.setState({
      tabValue: value,
    });
  };

  onClickAddCode = () => {
    this.setState({
      addNewOpen: true,
    });
  };

  onClickClosePopup = () => {
    this.setState({
      addNewOpen: false,
    });
  };

  onClickRemove = (code: any) => {
    this.setState({
      openConfirmation: true,
      selectedCode: code,
    });
  };

  onSubmitDelete = () => {
    let value: any = {
      hotels: _.clone(_.get(this.props, 'hotelCodes', [])),
      flights: _.clone(_.get(this.props, 'flightCodes', [])),
      cars: _.clone(_.get(this.props, 'carCodes', [])),
    };

    if (this.state.tabValue == 'hotel') {
      let hotels = _.clone(_.get(this.props, 'hotelCodes', []));
      if (_.includes(hotels, this.state.selectedCode)) {
        hotels.splice(_.indexOf(hotels, this.state.selectedCode), 1);
      }
      value.hotels = hotels;
    } else if (this.state.tabValue == 'flight') {
      let flights = _.clone(_.get(this.props, 'flightCodes', []));
      if (_.includes(flights, this.state.selectedCode)) {
        flights.splice(_.indexOf(flights, this.state.selectedCode), 1);
      }
      value.flights = flights;
    } else {
      let cars = _.clone(_.get(this.props, 'carCodes', []));
      let codeToDelete = _.clone(this.state.selectedCode);
      delete codeToDelete.name;

      value.cars = _.reject(cars, codeToDelete);
    }

    this.props.putCorporateCodes({ value: value });

    this.setState({
      openConfirmation: false,
      selectedCode: null,
    });
  };

  handleSubmitAdd = (code: any) => {
    let value: any = {
      hotels: _.clone(_.get(this.props, 'hotelCodes', [])),
      flights: _.clone(_.get(this.props, 'flightCodes', [])),
      cars: _.clone(_.get(this.props, 'carCodes', [])),
    };

    if (this.state.tabValue == 'hotel') {
      let hotels = _.clone(_.get(this.props, 'hotelCodes', []));
      hotels.push(_.toUpper(code));
      value.hotels = _.uniq(hotels);
    } else if (this.state.tabValue == 'flight') {
      let flights = _.clone(_.get(this.props, 'flightCodes', []));
      flights.push(_.toUpper(code));
      value.flights = _.uniq(flights);
    } else {
      let cars = _.clone(_.get(this.props, 'carCodes', []));
      cars.push(code);
      cars = _.uniqWith(cars, _.isEqual);

      value.cars = cars;
    }

    this.props.putCorporateCodes({ value: value });
  };

  renderAddCodePopup() {
    if (this.state.addNewOpen === false) {
      return null;
    }

    return (
      <AddCorporateRateCodePopup
        showPopup={this.state.addNewOpen}
        closePopup={this.onClickClosePopup}
        handleSubmit={this.handleSubmitAdd}
        type={this.state.tabValue}
        vendorList={this.props.carLoyaltyProgram}
      />
    );
  }

  closeConfirmation = () => {
    this.setState({
      openConfirmation: false,
    });
  };

  getProviderName = (corporateRateC: any) => {
    const name = _.get(
      _.find(this.props.carLoyaltyProgram, ['code', corporateRateC.vendorCode]),
      'name',
      '',
    );

    return {
      name: name,
      vendorCode: corporateRateC.vendorCode,
      discountNumber: corporateRateC.discountNumber,
      billingCode: corporateRateC.billingCode,
    };
  };

  renderTabContent = () => {
    if (this.state.tabValue === 'car') {
      let corporateRateC = _.clone(_.get(this.props, 'carCodes', []));
      corporateRateC = _.map(corporateRateC, this.getProviderName);
      corporateRateC.sort((a: any, b: any) => a.name.localeCompare(b.name));

      return (
        <Table>
          <TableBody>
            {this.state.tabValue === 'car' && (
              <RateCodeTableRow
                showRemove={false}
                key="header-key"
                rowColumns={['Vendor:', 'Discount Number:', 'Billing Code:']}
              />
            )}
            {corporateRateC.map((code: any) => {
              return (
                <RateCodeTableRow
                  clickRemove={this.onClickRemove.bind(this, code)}
                  showRemove={
                    !this.state.addNewOpen && this.props.hasPermission
                  }
                  key={
                    _.get(code, 'vendorCode', '') +
                    '-' +
                    _.get(code, 'discountNumber', '')
                  }
                  rowColumns={[
                    _.get(code, 'name', ''),
                    _.get(code, 'discountNumber', ''),
                    _.get(code, 'billingCode', ''),
                  ]}
                />
              );
            })}
          </TableBody>
        </Table>
      );
    }

    let corporateRateCodes = _.clone(_.get(this.props, 'flightCodes', []));
    corporateRateCodes.sort((a: any, b: any) => a.localeCompare(b));

    if (this.state.tabValue === 'hotel') {
      corporateRateCodes = _.clone(_.get(this.props, 'hotelCodes', []));
      corporateRateCodes.sort((a: any, b: any) => a.localeCompare(b));
    }

    return (
      <Table>
        <TableBody>
          {corporateRateCodes.map((code: any) => {
            code = _.toUpper(code);
            return (
              <RateCodeTableRow
                clickRemove={this.onClickRemove.bind(this, code)}
                showRemove={!this.state.addNewOpen && this.props.hasPermission}
                key={code}
                rowColumns={[code]}
              />
            );
          })}
        </TableBody>
      </Table>
    );
  };

  render() {
    return (
      <Paper
        className={
          _.get(this.props, 'isLoading', true)
            ? cx(styles.paper, styles.loading)
            : styles.paper
        }
      >
        <Loader visible={_.get(this.props, 'isLoading', false)} />

        <div className={styles.header}>
          <h2>Corporate Discounts</h2>
          <Tabs
            value={this.state.tabValue}
            indicatorColor="primary"
            textColor="primary"
            onChange={this.handleTabChange}
            className={cx(styles.tabs, styles.corporateRateCodeTabs)}
            disabled={this.state.openConfirmation || this.state.addNewOpen}
          >
            <Tab
              disableRipple
              className={styles.tab}
              label="Flight SNAP Codes"
              value="flight"
            />
            <Tab
              disableRipple
              className={styles.tab}
              label="Hotel Rate Codes"
              value="hotel"
            />
            {this.props.organization && (
              <Tab
                disableRipple
                className={styles.tab}
                label="Car Discount Codes"
                value="car"
              />
            )}
          </Tabs>
          {this.renderTabContent()}
          {this.props.hasPermission && (
            <div
              className={styles.addProgram}
              onClick={this.onClickAddCode}
            >
              <div className={styles.addIcon}>
                <SvgAddIcon
                  width={18}
                  height={18}
                />
              </div>
              <div>Add Discount Code</div>
            </div>
          )}
        </div>

        {this.renderAddCodePopup()}
        <ConfirmationPopup
          open={this.state.openConfirmation}
          onClose={this.closeConfirmation}
          onSubmit={this.onSubmitDelete}
          title="Are you sure?"
          bodyText="Are you sure you want to remove this code?"
          submitButtonText="Remove"
          closeButtonText="Cancel"
        />
      </Paper>
    );
  }
}

export default CorporateRateCodes;

import React from 'react';

type Props = {
  width: number;
  height: number;
};

const SvgIconQuestion = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 10 15"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Master"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Agency-Macro---Profile---READ"
          transform="translate(-323.000000, -932.000000)"
          fill="#FFFFFF"
          fill-rule="nonzero"
        >
          <g
            id="Agency-Details-Copy"
            transform="translate(280.000000, 892.000000)"
          >
            <g
              id="Avatar"
              transform="translate(32.000000, 32.000000)"
            >
              <g
                id="help"
                transform="translate(11.000000, 8.000000)"
              >
                <path
                  d="M2.95015015,4.42042042 C2.87327327,2.34474474 6.56336336,1.42222222 7.25525526,3.34414414 C7.71651652,4.57417417 6.87087087,5.57357357 5.94834835,6.18858859 C4.71831832,7.11111111 4.25705706,8.34114114 4.18018018,9.8018018 C4.1033033,11.1855856 6.25585586,11.1855856 6.33273273,9.8018018 C6.40960961,8.26426426 6.94774775,8.26426426 7.87027027,7.4954955 C8.63903904,6.88048048 9.1003003,5.88108108 9.33093093,4.88168168 C9.71531532,3.11351351 9.1003003,1.26846847 7.33213213,0.4996997 C4.48768769,-0.73033033 0.49009009,0.884084084 0.643843844,4.34354354 C0.797597598,5.8042042 2.95015015,5.8042042 2.95015015,4.42042042 L2.95015015,4.42042042 Z"
                  id="Path"
                ></path>
                <circle
                  id="Oval"
                  cx="5.25645646"
                  cy="13.1075075"
                  r="1.30690691"
                ></circle>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgIconQuestion;

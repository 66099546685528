import * as React from 'react';
import classNames from 'classnames';
import _ from 'lodash-es';
import { navigate } from '@reach/router';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';

import CompanyTableHead from 'components/CompaniesList/CompanyTableHead';
import CompanyTableRow from 'components/CompaniesList/CompanyTableRow';
import AddCompanyPopup from 'components/Popup/AddCompanyPopup';
import Loader from 'components/Loader/Loader';

import { connect } from 'react-redux';
import { get, emptyResults } from 'actions/Companies/Get';
import { bindActionCreators, Dispatch } from 'redux';
import { QUERY_LIMIT } from '../../../configs/General';
import { ReduxState } from '../../../reducers/types';

const styles = require('./styles.pcss');

type Props = any;

type State = {
  searchKeyword: string;
  showAddCompany: boolean;
};

const appWindow: Window = window;

class CompaniesBody extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      searchKeyword: '',
      showAddCompany: false,
    };

    this.handleScroll = this.handleScroll.bind(this);
    this.onCloseAddCompany = this.onCloseAddCompany.bind(this);
  }

  componentWillReceiveProps = (nextProps: any) => {
    if (_.get(nextProps, 'companies', []).length === 1) {
      const urlParams = new URLSearchParams(window.location.search);
      const name = (urlParams.get('name') ?? '').toLowerCase();
      const domain = (urlParams.get('domain') ?? '').toLowerCase();

      if (
        nextProps.companies[0].name.toLowerCase() === name ||
        nextProps.companies[0].primaryDomain.toLowerCase() === domain
      ) {
        navigate(`/company/${nextProps.companies[0].id}`);
      }
    }
  };

  handleScroll() {
    const body = document.body;
    const html = document.documentElement;

    if (!body || !html) {
      return;
    }

    const windowHeight =
      'innerHeight' in appWindow ? appWindow.innerHeight : html.offsetHeight;

    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight,
    );
    const windowBottom = windowHeight + window.pageYOffset;

    if (
      windowBottom >= docHeight &&
      this.props.isLoading === false &&
      this.props.loadedAll === false
    ) {
      const params: any = {
        limit: QUERY_LIMIT,
        skip: this.props.companies.length,
        ...this.props.searchParams,
      };

      this.props.get(params);
    }
  }

  componentDidMount() {
    appWindow.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    appWindow.removeEventListener('scroll', this.handleScroll);
    this.props.emptyResults();
  }

  renderTableRow = (company: any, i: number) => {
    return (
      <CompanyTableRow
        key={i}
        company={company}
      />
    );
  };

  onClickAddCompany = () => {
    this.setState({
      showAddCompany: true,
    });
  };

  onCloseAddCompany = () => {
    this.setState({
      showAddCompany: false,
    });
  };

  render() {
    if (this.props.companies.length == 0 && this.props.loadedAll) {
      return (
        <Paper className={styles.bodyPaper}>
          <div className={styles.notFound}>No records found</div>
        </Paper>
      );
    }
    if (this.props.companies.length == 0) {
      return <Loader visible={this.props.isLoading} />;
    }
    return (
      <Paper className={styles.bodyPaper}>
        <Loader visible={this.props.isLoading} />
        <Table>
          <CompanyTableHead onClickAddCompany={this.onClickAddCompany} />
          <TableBody>{this.props.companies.map(this.renderTableRow)}</TableBody>
        </Table>

        <AddCompanyPopup
          showAddCompany={this.state.showAddCompany}
          closeAddCompany={this.onCloseAddCompany}
        />
      </Paper>
    );
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      get,
      emptyResults,
    },
    dispatch,
  );
}

function mapStateToProps(state: ReduxState) {
  return {
    companies: state.companies.companies,
    isLoading: state.companies.isLoading,
    loadedAll: state.companies.loadedAll,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesBody);

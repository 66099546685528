import {
  ACTION_PUT_EMPLOYEE,
  ACTION_PUT_EMPLOYEE_SUCCESS,
  ACTION_PUT_EMPLOYEE_FAILURE,
  ACTION_RESET_PUT_EMPLOYEE_SUCCESS,
} from '../configs/ActionTypes';

const initialState = {
  success: null,
  isLoading: false,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_PUT_EMPLOYEE: {
      return {
        ...state,
        isLoading: true,
        success: null,
      };
    }

    case ACTION_PUT_EMPLOYEE_SUCCESS: {
      return {
        ...state,
        success: true,
        isLoading: false,
      };
    }

    case ACTION_PUT_EMPLOYEE_FAILURE: {
      return {
        ...state,
        success: false,
        isLoading: false,
      };
    }

    case ACTION_RESET_PUT_EMPLOYEE_SUCCESS: {
      return {
        ...state,
        success: null,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
}

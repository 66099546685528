import {
  ACTION_PUT_REIMBURSEMENT_FEATURE,
  ACTION_PUT_REIMBURSEMENT_FEATURE_SUCCESS,
  ACTION_PUT_REIMBURSEMENT_FEATURE_FAILURE,
  API_CALL,
} from '../../configs/ActionTypes';
import { track } from '../../analytics/analytics';

import _ from 'lodash-es';

import { Dispatch } from 'redux';

import { enqueueSnackbarHandler } from '../Status';

export type datatype = {
  organizationId?: string;
  settingId?: string;
  enabled?: any;
};

export function putReimbursement(data: datatype) {
  return (dispatch: Dispatch, getState: Function) => {
    dispatch(requestStarted());
    //Dispatch the api call request.
    dispatch({
      type: API_CALL,
      payload: {
        call: 'organizations/feature/achReimbursement',
        method: 'PUT',
        data: JSON.stringify(data),
        success: (response: any) => {
          if (response.type === 'error') {
            enqueueSnackbarHandler(dispatch, response.payload.payload, 'error');
            dispatch(requestFailed(response));
          } else {
            enqueueSnackbarHandler(
              dispatch,
              { updateFeatureSuccess: true },
              'success',
            );
            dispatch(requestSuccess(response));
            // handleTrack(data);
          }
        },
        fail: (error: any) => {
          _.get(error.payload, 'userPermissionError', false)
            ? enqueueSnackbarHandler(dispatch, error.payload, 'error')
            : enqueueSnackbarHandler(
                dispatch,
                { updateFeatureError: true },
                'error',
              );
          dispatch(requestFailed(error));
        },
      },
    });
    return null;
  };
}

export function requestStarted() {
  return {
    type: ACTION_PUT_REIMBURSEMENT_FEATURE,
    payload: null,
  };
}

export function requestSuccess(responseJSON: object) {
  return {
    type: ACTION_PUT_REIMBURSEMENT_FEATURE_SUCCESS,
    payload: responseJSON,
  };
}

export function requestFailed(responseJSON: object) {
  return {
    type: ACTION_PUT_REIMBURSEMENT_FEATURE_FAILURE,
    payload: responseJSON,
  };
}

function handleTrack(data: any) {
  if (data.organizationId !== undefined) {
    let result = 'removed';

    if (data.enabled) {
      result = 'added';
    }

    let properties = {
      Company: data.organizationId,
      Modified: 'features',
      Feature: 'Trusted',
      Result: result,
    };

    track('BB_CompanyUpdated', properties);
  }
}

import * as React from 'react';
import _ from 'lodash-es';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import UserTableHead from './UsersTable/UserTableHead';
import UserTableRow from './UsersTable/UserTableRow';
import Loader from 'components/Loader/Loader';

import { connect } from 'react-redux';
import { get, emptyResults } from 'actions/ManageBB/Users/Get';
import { bindActionCreators, Dispatch } from 'redux';
import { QUERY_LIMIT } from '../../../configs/General';
import { ReduxState } from '../../../reducers/types';
import { hasPermission } from '../../../helpers/Permission';

const styles = require('./styles.pcss');

type Props = any;
type State = any;

const appWindow: Window = window;

class UsersBody extends React.Component<Props, State> {
  hasPermissionToUpdate = hasPermission('blackBox', 'update');

  constructor(props: Props) {
    super(props);

    this.handleScroll = this.handleScroll.bind(this);
  }

  handleScroll() {
    const body = document.body;
    const html = document.documentElement;

    if (!body || !html) {
      return;
    }

    const windowHeight =
      'innerHeight' in appWindow ? appWindow.innerHeight : html.offsetHeight;

    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight,
    );
    const windowBottom = windowHeight + window.pageYOffset;

    if (
      windowBottom >= docHeight &&
      this.props.isLoading === false &&
      this.props.loadedAll === false
    ) {
      const loginData = localStorage.getItem('login_data') as string;
      const parsedLoginData = JSON.parse(loginData);

      const params: any = {
        limit: QUERY_LIMIT,
        skip: this.props.users.length,
        agencyId: _.get(parsedLoginData, 'agencyId', ''),
      };

      this.props.get(params);
    }
  }

  componentDidMount() {
    appWindow.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    appWindow.removeEventListener('scroll', this.handleScroll);
    this.props.emptyResults();
  }

  renderTableRow = (user: any, i: number) => {
    return (
      <UserTableRow
        key={i}
        user={user}
        showEdit={this.hasPermissionToUpdate}
        clickEdit={this.props.onClickEdit.bind(this, user)}
      />
    );
  };

  render() {
    if (this.props.users.length == 0 && this.props.loadedAll) {
      return (
        <Paper className={styles.bodyPaper}>
          <div className={styles.notFound}>No records found</div>
        </Paper>
      );
    }
    if (this.props.users.length == 0) {
      return <Loader visible={this.props.isLoading} />;
    }
    return (
      <Paper className={styles.bodyPaper}>
        <Loader visible={this.props.isLoading} />
        <Table className={styles.bbusersTable}>
          <UserTableHead />
          <TableBody>{this.props.users.map(this.renderTableRow)}</TableBody>
        </Table>
      </Paper>
    );
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      get,
      emptyResults,
    },
    dispatch,
  );
}

function mapStateToProps(state: ReduxState) {
  return {
    users: state.bbusers.users,
    isLoading: state.bbusers.isLoading,
    loadedAll: state.bbusers.loadedAll,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersBody);

import * as React from 'react';
import classNames from 'classnames';

import _ from 'lodash-es';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import SvgAddIcon from '../../static/icons/svgAddIcon';

import { hasPermission } from '../../helpers/Permission';
const styles = require('./styles.pcss');

class AgencyTableHead extends React.Component<any> {
  render() {
    return (
      <TableHead>
        <TableRow>
          <TableCell className={classNames(styles.tableHead)}>Name</TableCell>
          <TableCell className={classNames(styles.tableHead)}>
            App URL
          </TableCell>
          <TableCell className={classNames(styles.tableHead)}>
            {hasPermission('agency', 'create') && (
              <div
                onClick={this.props.onClickAddAgency}
                className={classNames(styles.addAgency)}
              >
                <div className={classNames(styles.addIcon)}>
                  <SvgAddIcon
                    width={18}
                    height={18}
                  />
                </div>
                <div>Add Agency</div>
              </div>
            )}
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }
}

export default AgencyTableHead;

import * as React from 'react';

const styles = require('./style.pcss');

type Props = {
  children?: React.ReactNode;
};

const Table = ({ children, ...props }: Props) => {
  return <div className={styles.table}>{children}</div>;
};

export default Table;

import {
  ACTION_GET_ORGANIZATIONS_PRODUCTS_SETTINGS,
  ACTION_GET_ORGANIZATIONS_PRODUCTS_SETTINGS_SUCCESS,
  ACTION_GET_ORGANIZATIONS_PRODUCTS_SETTINGS_FAILURE,
  ACTION_PUT_ORGANIZATIONS_PRODUCTS_SETTINGS,
  ACTION_PUT_ORGANIZATIONS_PRODUCTS_SETTINGS_SUCCESS,
  ACTION_PUT_ORGANIZATIONS_PRODUCTS_SETTINGS_FAILURE,
} from '../configs/ActionTypes';

import _ from 'lodash-es';

const initialState = {
  products: [],
  isLoading: false,
  success: null,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_GET_ORGANIZATIONS_PRODUCTS_SETTINGS:
    case ACTION_PUT_ORGANIZATIONS_PRODUCTS_SETTINGS: {
      return {
        ...state,
        isLoading: true,
        success: null,
      };
    }

    case ACTION_GET_ORGANIZATIONS_PRODUCTS_SETTINGS_SUCCESS:
    case ACTION_PUT_ORGANIZATIONS_PRODUCTS_SETTINGS_SUCCESS: {
      return {
        ...state,
        products: action.payload.payload,
        isLoading: false,
        success: null,
      };
    }

    case ACTION_GET_ORGANIZATIONS_PRODUCTS_SETTINGS_FAILURE:
    case ACTION_PUT_ORGANIZATIONS_PRODUCTS_SETTINGS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        success: false,
      };
    }

    default: {
      return state;
    }
  }
}

import * as React from 'react';
import classNames from 'classnames';

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const styles = require('./styles.pcss');

type Props = {
  tableColumns: Array<string>;
};

const FeaturesTableHead = (props: Props) => {
  const tableColumn = (name: string, i: number) => (
    <TableCell
      component="th"
      scope="row"
      key={i}
      className={classNames(styles.tableHead)}
    >
      {name}
    </TableCell>
  );

  return (
    <TableHead className={styles.tableHeadFeature}>
      <TableRow>
        <TableCell
          component="th"
          scope="row"
          key={'emp1'}
          className={classNames(styles.emptyHead)}
        ></TableCell>
        {props.tableColumns.map(tableColumn)}
        <TableCell
          component="th"
          scope="row"
          key={'emp2'}
          className={classNames(styles.emptyHead)}
        ></TableCell>
      </TableRow>
    </TableHead>
  );
};

export default FeaturesTableHead;

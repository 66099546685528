import React, { Component } from 'react';
import _ from 'lodash-es';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SwitchTableRow from './SwitchTableRow';
import Loader from 'components/Loader/Loader';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

const styles = require('../styles.pcss');

type Props = {
  open: boolean;
  onClose: any;
  onSubmit: any;
  title?: any;
  isLoading: boolean;
  updatePermission: boolean;
  handleCustomCredentialsChange: Function;
  handleEnabledChange: Function;
  body: any;
  enabledSwitch: boolean;
  customCredentials: boolean;
  type: string;
  provider: string;
  enabledSwitchOnly: boolean;
  disabled?: boolean;
};

const UpdatePopup = (props: Props) => {
  const enabled: any = {
    labelName: 'Enabled',
    description: `Whether employees can search and book ${props.type.slice(
      0,
      -1,
    )} inventory from ${_.capitalize(props.provider)}.`,
  };

  const customCredentials: any = {
    labelName: 'Use Custom Credentials',
    description: `Connect to a different ${_.capitalize(
      props.provider,
    )} instance.`,
  };

  return (
    <Dialog
      maxWidth={'md'}
      fullWidth={true}
      className={styles.dialogBox}
      open={props.open}
      onClose={props.onClose}
    >
      <div className={styles.title}>
        {props.title}
        <IconButton
          color="inherit"
          onClick={props.onClose}
          className={styles.closeIcon}
          aria-label="Close"
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div className={styles.popupBody}>
        <div className={styles.switch}>
          {props.isLoading && (
            <div>
              <Loader visible={props.isLoading} />
            </div>
          )}
          <Table>
            <TableBody>
              <SwitchTableRow
                key={`settingEnabled${props.provider}${props.type}`}
                rowColumns={enabled}
                enabled={props.enabledSwitch}
                handleclick={props.handleEnabledChange}
                permission={!_.get(props, 'updatePermission', true)}
                description={true}
              />
              {!props.enabledSwitchOnly && (
                <SwitchTableRow
                  key={`customCredentials${props.provider}${props.type}`}
                  rowColumns={customCredentials}
                  enabled={props.customCredentials}
                  handleclick={props.handleCustomCredentialsChange}
                  permission={!_.get(props, 'updatePermission', true)}
                  description={true}
                />
              )}
            </TableBody>
          </Table>
        </div>
        {props.body}
      </div>

      <div className={styles.confirmationPopupButton}>
        <Button
          className={styles.button}
          variant="contained"
          color="primary"
          onClick={props.onSubmit}
          disabled={props.disabled}
        >
          Save
        </Button>
      </div>
    </Dialog>
  );
};

export default UpdatePopup;

import * as React from 'react';
import _ from 'lodash';
import cx from 'classnames';
import Loader from 'components/Loader/Loader';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Company, ReduxState } from '../../../reducers/types';
import FeaturesTableRow from '../../Features/FeaturesTableRow';
import ConfirmationPopup from 'components/Popup/Confirmation';
import SvgFunctionalIcon from '../../../static/icons/svgFunctionalIcon';
import { putPaidPlanSetting } from 'actions/Companies/Settings/PutPaidPlan';
import { hasPermission } from '../../../helpers/Permission';
const styles = require('./styles.pcss');

type Props = {
  company: Company;
  loading: boolean;
  setting: any;
  putPaidPlanSetting: Function;
};

type State = {
  openConfirmPopup: boolean;
  paidPlanToUpdate: object;
};

type SettingValue = {
  expenseEnabled: boolean,
  travelEnabled: boolean
}

class PaidPlan extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      openConfirmPopup: false,
      paidPlanToUpdate: {
        expenseEnabled: false,
        travelEnabled: false
      }
    };
  }

  openConfirmPopup = () => {
    this.setState({
      openConfirmPopup: true,
    });
  };

  closeConfirmationPopup = () => {
    this.setState({
      openConfirmPopup: false,
    });
  };

  onSubmit = () => {
    this.setState({
      openConfirmPopup: false,
    });

    this.props.putPaidPlanSetting({
      organizationId: this.props.company.id,
      value: this.state.paidPlanToUpdate
    })
  };  

  checkAndUpdateSetting = (value: SettingValue) => {
    if (value.expenseEnabled === false && value.travelEnabled === false) {
      this.setState({
        paidPlanToUpdate: value
      }) 
      this.openConfirmPopup();

      } else {
      this.props.putPaidPlanSetting({
        organizationId: this.props.company.id,
        value: value
      })
    }
  }

  updatePaidExpense = () => {
    const travelEnabled = this.props.setting?.value?.travelEnabled || false;
    const expenseEnabled = this.props.setting?.value?.expenseEnabled || false;
    const value = {
      expenseEnabled: !expenseEnabled,
      travelEnabled
    };

    this.checkAndUpdateSetting(value);
  }

  updatePaidTravel = () => {
    const travelEnabled = this.props.setting?.value?.travelEnabled || false;
    const expenseEnabled = this.props.setting?.value?.expenseEnabled || false;
    const value = {
      expenseEnabled,
      travelEnabled: !travelEnabled
    };

    this.checkAndUpdateSetting(value);
  }

  updatePlanSetting = () => {
    let value = {
      expenseEnabled: false,
      travelEnabled: false
    };

    if (!this.props.setting?.value?.travelEnabled && !this.props.setting?.value?.expenseEnabled) {
      value = {
        expenseEnabled: true,
        travelEnabled: true
      }; 
    }

    this.props.putPaidPlanSetting({
      organizationId: this.props.company.id,
      value,
    })
  } 

  renderLoading() {
    if (this.props.loading === true) {
      return (
        <div className={styles.infoLoader}>
          <Loader visible={true} />
        </div>
      );
    }

    return null;
  }

  renderPaidOptions() {
    if (!this.props.setting?.value?.travelEnabled && !this.props.setting?.value?.expenseEnabled) {
      return null;
    }
    
    const updatePermission = !hasPermission('organizations', 'update');
    const paidExpense: any = {
      labelName: 'Paid Expense',
      description: 'The company can access paid expense features.',
    };
    const paidTravel: any = {
      labelName: 'Paid Travel',
      description: 'The company can access paid travel features.',
    };

    return (
      <>
        <div className={styles.infoMessage}>
          <SvgFunctionalIcon
            width={20}
            height={20}
          />
          When a company is on the Paid Plan, at least one sub-setting must be enabled.
        </div>
        <div className={styles.expenseTravelContainer}>
          <Table>
            <TableBody>
              <FeaturesTableRow
                key={"paidExpense"}
                rowColumns={paidExpense}
                enabled={this.props.setting?.value?.expenseEnabled}
                handleclick={this.updatePaidExpense}
                updateOrganizationPermission={updatePermission}
                description={true}
              />
              <FeaturesTableRow
                key={"paidTravel"}
                rowColumns={paidTravel}
                enabled={this.props.setting?.value?.travelEnabled}
                handleclick={this.updatePaidTravel}
                updateOrganizationPermission={updatePermission}
                description={true}
              />
            </TableBody>
          </Table>
        </div>
      </>
    ); 
  }

  render() {
    let paidPlanContainer = styles.paidPlanContainer;
    const updatePermission = !hasPermission('organizations', 'update');
        
    if (this.props.loading === true) {
      paidPlanContainer = cx(styles.paidPlanContainer, styles.isLoading);
    }

    const dailyReinbursements: any = {
      labelName: 'Paid Plan',
      description: 'The company is on the Paid Plan and has access to paid features.',
    };

    return (
      <>         
        <div className={paidPlanContainer}>
          {this.renderLoading()}
          <Table>
            <TableBody>
              <FeaturesTableRow
                key={"paidPlanSetting"}
                rowColumns={dailyReinbursements}
                enabled={this.props.setting?.value?.travelEnabled || this.props.setting?.value?.expenseEnabled}
                handleclick={this.updatePlanSetting}
                updateOrganizationPermission={updatePermission}
                description={true}
              />
            </TableBody>
          </Table>
          {this.renderPaidOptions()}
          </div>

          <ConfirmationPopup
            open={this.state.openConfirmPopup}
            onClose={this.closeConfirmationPopup}
            onSubmit={this.onSubmit}
            title="Are you sure you want to downgrade to the Free Plan?"
            bodyText="By turning off access to all paid sub-settings, this company is no longer receiving the bulk of Paid Plan features and will be downgraded to Free Plan access."
            submitButtonText="Confirm & Proceed"
            closeButtonText="Cancel"
            disableX={true}
          />          
      </>
    );
  }
}

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {
    loading: state.organizationPaidPlanSetting.isLoading,
    setting: state.organizationPaidPlanSetting.setting,
  };
}

// Wire up redux dispatch functions
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      putPaidPlanSetting,
    },
    dispatch,
  );
}

// Connect it to Redux
export default connect(mapStateToProps, mapDispatchToProps)(PaidPlan);

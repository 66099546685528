import * as React from 'react';
import _ from 'lodash-es';
import cx from 'classnames';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TravelPolicyTableRows from './TravelPolicyTableRows';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Loader from 'components/Loader/Loader';
const styles = require('./styles.pcss');
import { ReduxState } from 'reducers/types';

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

type Props = {
  policyRules?: any;
  loading: any;
};

class BudgetRules extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    let budgetClass: any = {
      name: 'Class',
      description:
        'Fare Class or Hotel Class that the budget will be based on.',
    };

    let comfort: any = {
      name: 'Comfort',
      description:
        'Number of stops or distance from search that the budget will be based on.',
    };

    let flightClass = _.get(this.props.policyRules, '[0].airClass', '-');
    let hotelRating =
      _.get(this.props.policyRules, '[0].hotelRating', '') !== ''
        ? _.get(this.props.policyRules, '[0].hotelRating', '') + ' Star'
        : '-';
    let flightComfort =
      _.get(this.props.policyRules, '[0].airNonStop', '-') === true
        ? 'Nonstop'
        : _.get(this.props.policyRules, '[0].airNonStop', '-') === false
          ? '1+ Stops'
          : '-';
    let hotelDistance =
      _.get(this.props.policyRules, '[0].hotelDistance', '') !== ''
        ? _.get(this.props.policyRules, '[0].hotelDistance', '') + ' miles'
        : '-';

    return (
      <Paper
        className={
          this.props.loading ? cx(styles.paper, styles.loading) : styles.paper
        }
      >
        <div className={styles.policyRules}>
          <h2>Budget</h2>
          <div>
            <Loader visible={this.props.loading} />
          </div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  align="right"
                  className={cx(styles.tableCell, styles.header)}
                ></TableCell>
                <TableCell
                  align="center"
                  className={cx(styles.tableCell, styles.header)}
                >
                  Flight
                </TableCell>
                <TableCell
                  align="center"
                  className={cx(styles.tableCell, styles.header)}
                >
                  Lodging
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TravelPolicyTableRows
                key={1}
                text={budgetClass}
                flight={flightClass}
                hotel={hotelRating}
                description={true}
              />
              <TravelPolicyTableRows
                key={2}
                text={comfort}
                flight={flightComfort}
                hotel={hotelDistance}
                description={true}
              />
            </TableBody>
          </Table>
        </div>
      </Paper>
    );
  }
}

export default BudgetRules;

import * as React from 'react';
import _ from 'lodash-es';
import moment from 'moment';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import CreditCardTableRow from './CreditCardTableRow';
import ConfirmationPopup from 'components/Popup/Confirmation';

const styles = require('./styles.pcss');

import { CreditCard, ReduxState } from 'reducers/types';

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import {
  deleteCC,
  resetDeleteCreditCardStatus,
} from 'actions/CreditCards/Delete';
import {
  putCreditCard,
  resetEditCredCardStatus,
} from '../../actions/CreditCards/EditCreditCard';

import { missingBillingAddress } from '../../helpers/MissingBillingAddress';

type Props = {
  creditCards: Array<CreditCard>;
  onCreditCardDeleted: any;
  deleteCC: any;
  deleteCreditCardStatus: any;
  resetDeleteCreditCardStatus: any;
  deletePermission?: boolean;
  updatePermission?: boolean;
  putCreditCard: Function;
  resetEditCredCardStatus: Function;
  user: any;
  editCreditCardStatus: any;
  onClickEditCreditcardPopupOpen: any;
  onClickEditCreditCardPopupClose: any;
};

type State = {
  openConfirmation: boolean;
  selectedCard: any;
  bodyText: any;
  submitButtonText: any;
  deleteCard: any;
};

class CreditCards extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      openConfirmation: false,
      selectedCard: null,
      bodyText: '',
      submitButtonText: '',
      deleteCard: null,
    };
  }

  componentWillReceiveProps = (nextProps: Props) => {
    if (nextProps.deleteCreditCardStatus.success === true) {
      this.props.resetDeleteCreditCardStatus();
      this.props.onCreditCardDeleted();
    }

    if (
      _.get(nextProps, 'editCreditCardStatus.editCreditCard', false) === true &&
      _.get(nextProps, 'editCreditCardStatus.success', null) === true
    ) {
      this.props.resetEditCredCardStatus();
      this.props.onClickEditCreditCardPopupClose();
      this.props.onCreditCardDeleted();
    }
  };

  onclickMarkAsCorporate = (creditCardId: string) => {
    this.setState({
      openConfirmation: true,
      selectedCard: creditCardId,
      bodyText: 'Are you sure you want to mark as Corporate Card?',
      submitButtonText: 'Mark',
      deleteCard: false,
    });
  };

  onClickRemove = (creditCardId: string) => {
    this.setState({
      openConfirmation: true,
      selectedCard: creditCardId,
      bodyText: 'Are you sure you want to delete this Credit Card?',
      submitButtonText: 'Delete',
      deleteCard: true,
    });
  };

  closeConfirmation = () => {
    this.setState({
      openConfirmation: false,
    });
  };

  onSubmitDelete = () => {
    if (this.state.deleteCard) {
      this.props.deleteCC(this.state.selectedCard, 'personal');
    } else {
      this.props.putCreditCard(
        {
          id: this.state.selectedCard,
          organizationId: _.get(this.props.user, 'organizations[0].id', null),
        },
        'company',
      );
    }

    this.setState({
      openConfirmation: false,
      selectedCard: null,
      bodyText: '',
      submitButtonText: '',
      deleteCard: null,
    });
  };

  missingBillingAddressExistsF = (card: Array<CreditCard>) => {
    let exist = false;

    for (let i = 0; i < card.length; i++) {
      if (
        !(
          card[i].organizationId !== null ||
          (_.isArray(card[i].departmentIds) && card[i].departmentIds.length > 0)
        )
      ) {
        if (missingBillingAddress(card[i])) {
          exist = true;
          break;
        }
      }
    }

    return exist;
  };

  render() {
    if (this.props.creditCards.length < 1) {
      return null;
    }

    var cards = _.clone(this.props.creditCards);

    cards.sort((a: any, b: any) => a.last4.localeCompare(b.last4));

    let missingBillingAddressExists = this.missingBillingAddressExistsF(cards);

    return (
      <>
        <Table>
          <TableBody>
            {cards.map((card: CreditCard, i: number) => {
              let name = card.name;
              let showRemove = true;
              let corporate = '';
              if (
                (card.organizationId != undefined &&
                  card.organizationId !== null &&
                  card.organizationId !== '') ||
                (_.isArray(card.departmentIds) && card.departmentIds.length > 0)
              ) {
                corporate = 'Corporate';
                showRemove = false;
              }

              return (
                <CreditCardTableRow
                  clickRemove={this.onClickRemove.bind(this, card.id)}
                  clickMarkAsCorporate={this.onclickMarkAsCorporate.bind(
                    this,
                    card.id,
                  )}
                  showRemove={showRemove && this.props.deletePermission}
                  showMarkAsCorporate={
                    showRemove && this.props.updatePermission
                  }
                  missingBillingAddress={
                    showRemove && missingBillingAddress(card)
                  }
                  missingBillingAddressExists={missingBillingAddressExists}
                  key={i}
                  type={`${card.typeFull}... ${card.last4}`}
                  name={name}
                  corporate={corporate}
                  card={card}
                  expiration={`Exp: ${moment(card.expiry).format('MM/YY')}`}
                  clickUpdateBillingAddress={
                    this.props.onClickEditCreditcardPopupOpen
                  }
                />
              );
            })}
          </TableBody>
        </Table>
        <ConfirmationPopup
          open={this.state.openConfirmation}
          onClose={this.closeConfirmation}
          onSubmit={this.onSubmitDelete}
          title="Are you sure?"
          bodyText={this.state.bodyText}
          submitButtonText={this.state.submitButtonText}
          closeButtonText="Cancel"
          id="submit-delete"
        />
      </>
    );
  }
}

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {
    editCreditCardStatus: state.editCreditCardStatus,
    deleteCreditCardStatus: state.deleteCreditCardStatus,
    user: state.user.user,
  };
}

// Wire up redux dispatch functions
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      deleteCC,
      resetDeleteCreditCardStatus,
      putCreditCard,
      resetEditCredCardStatus,
    },
    dispatch,
  );
}

// Connect it to Redux
export default connect(mapStateToProps, mapDispatchToProps)(CreditCards);

import {
  ACTION_GET_TRAVELERS,
  ACTION_GET_TRAVELERS_SUCCESS,
  ACTION_GET_TRAVELERS_FAILURE,
  ACTION_EMPTY_TRAVELERS,
  ACTION_ADD_TRAVELER_SUCCESS,
} from '../configs/ActionTypes';

import { QUERY_LIMIT } from '../configs/General';

import { Traveler } from './types';

import _ from 'lodash-es';
import moment from 'moment';

const initialState = {
  travelers: [],
  isLoading: false,
  loadedAll: false,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_GET_TRAVELERS: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ACTION_EMPTY_TRAVELERS: {
      return initialState;
    }

    case ACTION_ADD_TRAVELER_SUCCESS: {
      const t = action.payload.payload;

      let newTraveler: any = {
        id: t.id,
        userId: t.userId,
        firstName: _.get(t, 'firstName', ''),
        lastName: _.get(t, 'lastName', ''),
        middleName: _.get(t, 'middleName', ''),
        email: _.get(t, 'email', ''),
        birthDate: moment(t.birthDate).isValid() ? moment(t.birthDate) : null,
        phoneNumber: _.get(t, 'phone.number', ''),
        phoneCountryCode: _.get(t, 'phone.countryCode', ''),
        addressCity: _.get(t, 'deliveryAddress.city', ''),
        addressCountry: _.get(t, 'deliveryAddress.country', ''),
        addressPostalCode: _.get(t, 'deliveryAddress.postalCode', ''),
        addressState: _.get(t, 'deliveryAddress.state', ''),
        addressStreet: _.get(t, 'deliveryAddress.street', ''),
        knownTravelerNumber: t.knownTravelerNumber,
        passCountry: _.get(t, 'passportInfo.passCountry', ''),
        passNumber: _.get(t, 'passportInfo.passNumber', ''),
        passExpiry: moment(_.get(t, 'passportInfo.passExpiry', null)).isValid()
          ? moment(t.passportInfo.passExpiry)
          : null,
        gender: t.gender == null ? '' : t.gender,
        saveTraveler: t.saveTraveler,
        isPrimary: t.isPrimary,
        created: t.created,
        updated: t.updated,
        frequentFlyer: t.frequentFlyer,
        creditCards: _.get(t, 'creditCards', []),
        hotelLoyaltyPrograms: _.get(t, 'hotelLoyaltyPrograms', ''),
        carLoyalty: _.get(t, 'carLoyaltyPrograms', []),
      };

      let travelers: Array<Traveler> = _.clone(state.travelers);
      travelers.push(newTraveler);

      return {
        ...state,
        travelers: travelers,
      };
    }

    case ACTION_GET_TRAVELERS_SUCCESS: {
      let travelers: Array<Traveler> = _.clone(state.travelers);
      let loadedAll = state.loadedAll;
      const payload = action.payload.payload;

      if (payload.length < QUERY_LIMIT) {
        loadedAll = true;
      }
      _.each(payload, (t) => {
        let traveler: Traveler = {
          id: t.id,
          userId: t.userId,
          firstName: t.firstName,
          middleName: t.middleName,
          lastName: t.lastName,
          email: t.email,
          birthDate: moment(t.birthDate),
          phoneNumber: _.get(t, 'phone.number', ''),
          phoneCountryCode: _.get(t, 'phone.countryCode', ''),
          addressCity: _.get(t, 'deliveryAddress.city', ''),
          addressCountry: _.get(t, 'deliveryAddress.country', ''),
          addressPostalCode: _.get(t, 'deliveryAddress.postalCode', ''),
          addressState: _.get(t, 'deliveryAddress.state', ''),
          addressStreet: _.get(t, 'deliveryAddress.street', ''),
          knownTravelerNumber: t.knownTravelerNumber,
          passCountry: t.passCountry,
          passNumber: t.passNumber,
          gender: t.gender == null ? '' : t.gender,
          saveTraveler: t.saveTraveler,
          isPrimary: t.isPrimary,
          created: t.created,
          updated: t.updated,
          frequentFlyer: t.frequentFlyer,
          creditCards: _.get(t, 'creditCards', []),
          user: _.get(t, 'user', null),
        };

        travelers.push(traveler);
      });

      return {
        ...state,
        travelers: travelers,
        loadedAll: loadedAll,
        isLoading: false,
      };
    }

    case ACTION_GET_TRAVELERS_FAILURE: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

/**
 * GET MANAGERS
 */
import _ from 'lodash-es';
import { Dispatch } from 'redux';
import {
  ACTION_GET_MANAGER,
  ACTION_GET_MANAGER_SUCCESS,
  ACTION_GET_MANAGER_FAILURE,
  ACTION_RESET_GET_MANAGER_SUCCESS,
  API_CALL,
} from '../../configs/ActionTypes';
import { enqueueSnackbarHandler } from '../Status';

export function getManagers(organizationId: string, data: any) {
  return (dispatch: Dispatch, getState: Function) => {
    dispatch(requestStarted());

    dispatch({
      type: API_CALL,
      payload: {
        call: `organizations/${organizationId}/manager`,
        method: 'get',
        data: data,
        success: (response: object) => {
          dispatch(requestSuccess(response));
        },
        fail: (error: any) => {
          _.get(error.payload, 'userPermissionError', false)
            ? enqueueSnackbarHandler(dispatch, error.payload, 'error')
            : enqueueSnackbarHandler(
                dispatch,
                { getManagersFailed: true },
                'error',
              );
          dispatch(requestFailed());
        },
      },
    });
    return null;
  };
}

export function requestStarted() {
  return {
    type: ACTION_GET_MANAGER,
    payload: null,
  };
}

export function resetGetManagers() {
  return (dispatch: Dispatch, getState: Function) => {
    // Dispatch the api call request.
    dispatch({
      type: ACTION_RESET_GET_MANAGER_SUCCESS,
      payload: null,
    });
  };
}

function requestSuccess(responeJSON: object) {
  return {
    type: ACTION_GET_MANAGER_SUCCESS,
    payload: responeJSON,
  };
}

function requestFailed() {
  return {
    type: ACTION_GET_MANAGER_FAILURE,
    payload: null,
  };
}

import {
  ACTION_GET_ACTIVE_EXPENSE_INTEGRATION,
  ACTION_GET_ACTIVE_EXPENSE_INTEGRATION_SUCCESS,
  ACTION_GET_ACTIVE_EXPENSE_INTEGRATION_FAILURE,
} from '../configs/ActionTypes';

import _ from 'lodash-es';

const initialState = {
  provider: null,
  isLoading: false,
  success: null,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_GET_ACTIVE_EXPENSE_INTEGRATION: {
      return {
        ...state,
        isLoading: true,
        success: null,
      };
    }

    case ACTION_GET_ACTIVE_EXPENSE_INTEGRATION_SUCCESS: {
      return {
        ...state,
        provider: _.get(action.payload.payload, 'provider', ''),
        isLoading: false,
        success: null,
      };
    }

    case ACTION_GET_ACTIVE_EXPENSE_INTEGRATION_FAILURE: {
      return {
        ...state,
        isLoading: false,
        success: false,
      };
    }

    default: {
      return state;
    }
  }
}

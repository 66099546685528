import * as React from 'react';
import classNames from 'classnames';

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const styles = require('./styles.pcss');

const CreditCardTableHead = () => (
  <TableHead>
    <TableRow>
      <TableCell className={classNames(styles.tableHead)}>Name</TableCell>
      <TableCell className={classNames(styles.tableHead)}>Type</TableCell>
      <TableCell className={classNames(styles.tableHead)}>Last 4</TableCell>
      <TableCell className={classNames(styles.tableHead)}>Exp Date</TableCell>
      <TableCell className={classNames(styles.tableHead)}>
        Corporate Card
      </TableCell>
    </TableRow>
  </TableHead>
);

export default CreditCardTableHead;

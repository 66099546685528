import * as React from 'react';
import _ from 'lodash-es';
import { navigate } from '@reach/router';

import classNames from 'classnames';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import SvgIconSearch from '../../static/icons/svgIconSearch';
import SvgIconCreditCard from '../../static/icons/svgIconCreditCard';
import SvgIconSetting from '../../static/icons/svgIconSetting';
import { TB_AGENCY_ID } from '../../configs/Api';

const styles = require('./styles.pcss');

class SidebarMenu extends React.Component<any, any> {
  render() {
    const loginData = localStorage.getItem('login_data');
    let loginDataperse: any = null;

    if (loginData) {
      loginDataperse = JSON.parse(loginData);
    }

    return (
      <div className={styles.menu}>
        <List
          component="nav"
          className={classNames(styles.list)}
        >
          <ListItem
            className={
              window.location.pathname.includes('/lookup')
                ? classNames(styles.listItem, styles.active)
                : classNames(styles.listItem)
            }
          >
            <ListItemIcon className={styles.iconWrapper}>
              <SvgIconSearch
                width={14}
                height={14}
              />
            </ListItemIcon>
            <ListItemText
              primary="Lookup"
              className={classNames(styles.listItemText)}
              onClick={() => {
                navigate(`/lookup`);
              }}
            />
          </ListItem>

          {(!_.get(loginDataperse, 'agencyId', false) ||
            _.get(loginDataperse, 'agencyId', false) == TB_AGENCY_ID) && (
            <ListItem
              className={
                window.location.pathname.includes('/refund-charge')
                  ? classNames(styles.listItem, styles.active)
                  : classNames(styles.listItem)
              }
            >
              <ListItemIcon className={styles.iconWrapper}>
                <SvgIconCreditCard
                  width={23}
                  height={14}
                />
              </ListItemIcon>
              <ListItemText
                primary="Refund/Charge Form"
                className={classNames(styles.listItemText)}
                onClick={() => {
                  navigate(`/refund-charge`);
                }}
              />
            </ListItem>
          )}

          {(!_.get(loginDataperse, 'agencyId', false) ||
            _.get(loginDataperse, 'agencyId', false) == TB_AGENCY_ID) && (
            <ListItem
              className={
                window.location.pathname.includes('/manage-tb')
                  ? classNames(styles.listItem, styles.active)
                  : classNames(styles.listItem)
              }
            >
              <ListItemIcon className={styles.iconWrapper}>
                <SvgIconSetting
                  width={16}
                  height={16}
                />
              </ListItemIcon>
              <ListItemText
                primary="Manage TB"
                className={classNames(styles.listItemText)}
                onClick={() => {
                  navigate(`/manage-tb`);
                }}
              />
            </ListItem>
          )}
        </List>
      </div>
    );
  }
}

export default SidebarMenu;

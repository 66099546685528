import * as React from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { navigate } from '@reach/router';
import _ from 'lodash-es';
import LayoutDashboard from 'components/Layout/Dashboard';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/Breadcrumbs/Breadcrumb';
import SimpleTable from 'components/SimpleTable';
import SvgIconImportRed from '../../static/icons/svgIconImportRed';
import SvgWarningIcon from '../../static/icons/svgWarningIcon';
import SvgGreenCheck from '../../static/icons/svgGreenCheck';
import FooterConfirm from 'components/FooterConfirm/FooterConfirm';
import Loader from 'components/Loader/Loader';

const styles = require('./styles.pcss');
import {
  resetImportEmployees,
  confirmCSVImport,
} from 'actions/Companies/ImportEmployees';

import { ReduxState } from 'reducers/types';

type Props = {
  path: string;
  report: any;
  companyId?: string;
  companyDetails?: any;
  resetImportEmployees: Function;
  confirmCSVImport: Function;
  importEmployees: any;
};

class CSVImport extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);
  }

  componentWillReceiveProps = (nextProps: any) => {
    const self = this;

    if (
      _.get(nextProps, 'importEmployees.success', false) &&
      !_.get(this.props, 'importEmployees.success', true)
    ) {
      setTimeout(function () {
        navigate(`/company/${self.props.companyId}/`);
        self.props.resetImportEmployees();
      }, 500);
    }
  };

  renderTable = (
    data: any,
    text: any,
    columns: any,
    icon: any,
    type: string,
    className?: any,
  ) => {
    const classNames = className
      ? cx(styles.tableClass, className)
      : styles.tableClass;
    return (
      <>
        <div className={cx(styles.importTextContainer, styles[type])}>
          <div>{icon}</div>
          <div>{text}</div>
        </div>
        <SimpleTable
          columns={columns}
          data={data}
          scrollVertical={true}
          className={classNames}
        />
      </>
    );
  };

  onSubmitImport = () => {
    this.props.confirmCSVImport(
      this.props.companyDetails.id,
      this.props.importEmployees.validationReport.importId,
    );
  };

  onCancelImport = () => {
    this.props.resetImportEmployees();
    navigate(`/company/${this.props.companyId}/`);
  };

  render() {
    const employeeColumns = [
      'Employee Name',
      'Email',
      'Employee ID',
      'Department',
      'Admin?',
      'Finance?',
      'Manager Email',
      'Expense Policy',
      'Travel Policy',
    ];
    const deactivatedUsersCoulmns = ['Name', 'Email'];
    const invalidColumns = ['Name', 'Email', 'Error'];
    const departmentColumns = ['Department Name'];
    const expensePolicyColumns = ['Expense Policy Name'];
    const travelPolicyColumns = ['Travel Policy Name'];
    const updatedUsersData = _.get(this.props.report, 'updatedUsers', []).map(
      (v: any) => {
        return [
          `${v['First Name']} ${v['Last Name']}`,
          _.get(v, ['Email'], '- -'),
          _.get(v, ['Employee ID (optional)'], '- -'),
          _.get(v, ['Department(s)'], '- -'),
          _.get(v, ['Admin?'], '- -'),
          _.get(v, ['Finance Approver?'], '- -'),
          _.get(v, ["Manager's Email"], '- -'),
          _.get(v, ['Expense Policy'], '- -'),
          _.get(v, ['Travel Policy'], '- -'),
        ];
      },
    );
    const addedUsersData = _.get(this.props.report, 'addedUsers', []).map(
      (v: any) => {
        return [
          `${v['First Name']} ${v['Last Name']}`,
          _.get(v, ['Email'], '- -'),
          _.get(v, ['Employee ID (optional)'], '- -'),
          _.get(v, ['Department(s)'], '- -'),
          _.get(v, ['Admin?'], '- -'),
          _.get(v, ['Finance Approver?'], '- -'),
          _.get(v, ["Manager's Email"], '- -'),
          _.get(v, ['Expense Policy'], '- -'),
          _.get(v, ['Travel Policy'], '- -'),
        ];
      },
    );

    const departmentData = _.get(this.props.report, 'addedDepartments', []).map(
      (v: any) => {
        return [`${v}`];
      },
    );

    const expensePolicyData = _.get(
      this.props.report,
      'addedExpensePolicies',
      [],
    ).map((v: any) => {
      return [`${v}`];
    });

    const travelPolicyData = _.get(
      this.props.report,
      'addedTravelPolicies',
      [],
    ).map((v: any) => {
      return [`${v}`];
    });

    const invalidData = _.get(this.props.report, 'invalid', []).map(
      (v: any) => {
        return [
          `${v.data['First Name']} ${v.data['Last Name']}`,
          _.get(v.data, ['Email'], '- -'),
          _.get(v, 'errors').join(', '),
        ];
      },
    );

    const deactivatedUsersData = _.get(
      this.props.report,
      'deactivatedUsers',
      [],
    ).map((v: any) => {
      return [`${v.firstName} ${v.lastName}`, _.get(v, 'email', '- -')];
    });

    const totalChanges =
      addedUsersData.length +
      updatedUsersData.length +
      departmentData.length +
      expensePolicyData.length +
      travelPolicyData.length +
      deactivatedUsersData.length;

    let changesData = [];

    if (addedUsersData.length) {
      changesData.push([
        `Invite ${addedUsersData.length} New ${
          addedUsersData.length > 1 ? 'Employees' : 'Employee'
        }`,
      ]);
    }

    if (updatedUsersData.length) {
      changesData.push([
        `${updatedUsersData.length} Modified ${
          updatedUsersData.length > 1 ? 'Employees' : 'Employee'
        }`,
      ]);
    }

    if (deactivatedUsersData.length) {
      changesData.push([
        `${deactivatedUsersData.length} Deactivated ${
          deactivatedUsersData.length > 1 ? 'Employees' : 'Employee'
        }`,
      ]);
    }

    if (departmentData.length) {
      changesData.push([
        `Create ${departmentData.length} New ${
          departmentData.length > 1 ? 'Departments' : 'Department'
        }`,
      ]);
    }

    if (expensePolicyData.length) {
      changesData.push([
        `Create ${expensePolicyData.length} New ${
          expensePolicyData.length > 1 ? 'Expense policies' : 'Expense policy'
        }`,
      ]);
    }

    if (travelPolicyData.length) {
      changesData.push([
        `Create ${travelPolicyData.length} New ${
          travelPolicyData.length > 1 ? 'Travel policies' : 'Travel policy'
        }`,
      ]);
    }

    return (
      <>
        <LayoutDashboard>
          <div>
            <Breadcrumbs className={styles.breadcrumbs}>
              <Breadcrumb
                name="Lookup"
                arrow
              />
              <Breadcrumb
                name={_.get(this.props, 'companyDetails.name', '')}
                arrow
              />
              <Breadcrumb name="CSV Import" />
            </Breadcrumbs>
          </div>

          <div className="pageWrapper pageWrapperMid">
            <div className={styles.companyNameContainer}>
              <h1>CSV Import</h1>
            </div>
          </div>

          {_.get(this.props, 'importEmployees.validating', false) && (
            <div className="pageWrapper">
              <Loader visible={true} />
            </div>
          )}

          <div className={styles.csvtables}>
            {changesData.length > 0 &&
              this.renderTable(
                changesData,
                `${totalChanges} ${
                  totalChanges > 1 ? 'Changes to Review' : 'Change to Review'
                }`,
                false,
                <SvgGreenCheck
                  height={16}
                  width={16}
                />,
                'info',
                styles.simple,
              )}
            {invalidData.length > 0 &&
              this.renderTable(
                invalidData,
                `${invalidData.length} ${
                  invalidData.length > 1 ? 'Invalid Records' : 'Invalid Record'
                }`,
                invalidColumns,
                <SvgIconImportRed
                  height={14}
                  width={14}
                />,
                'error',
                styles.withHeader,
              )}
            {addedUsersData.length > 0 &&
              this.renderTable(
                addedUsersData,
                `${addedUsersData.length} New ${
                  addedUsersData.length > 1 ? 'Employees' : 'Employee'
                }`,
                employeeColumns,
                <SvgGreenCheck
                  height={16}
                  width={16}
                />,
                'info',
                styles.withHeader,
              )}
            {updatedUsersData.length > 0 &&
              this.renderTable(
                updatedUsersData,
                `${updatedUsersData.length} Existing or Modified ${
                  updatedUsersData.length > 1 ? 'Employees' : 'Employee'
                }`,
                employeeColumns,
                <SvgWarningIcon
                  height={16}
                  width={18}
                />,
                'warn',
                styles.withHeader,
              )}
            {deactivatedUsersData.length > 0 &&
              this.renderTable(
                deactivatedUsersData,
                `${deactivatedUsersData.length} Deactivated ${
                  deactivatedUsersData.length > 1 ? 'Employees' : 'Employee'
                }`,
                deactivatedUsersCoulmns,
                <SvgIconImportRed
                  height={14}
                  width={14}
                />,
                'error',
                styles.withHeader,
              )}
            {departmentData.length > 0 &&
              this.renderTable(
                departmentData,
                `${departmentData.length} New ${
                  departmentData.length > 1 ? 'Departments' : 'Department'
                }`,
                departmentColumns,
                <SvgGreenCheck
                  height={16}
                  width={16}
                />,
                'info',
                styles.withHeader,
              )}
            {expensePolicyData.length > 0 &&
              this.renderTable(
                expensePolicyData,
                `${expensePolicyData.length} New ${
                  expensePolicyData.length > 1
                    ? 'Expense Policies'
                    : 'Expense Policy'
                }`,
                expensePolicyColumns,
                <SvgGreenCheck
                  height={16}
                  width={16}
                />,
                'info',
                styles.withHeader,
              )}
            {travelPolicyData.length > 0 &&
              this.renderTable(
                travelPolicyData,
                `${travelPolicyData.length} New ${
                  travelPolicyData.length > 1
                    ? 'Travel Policies'
                    : 'Travel Policy'
                }`,
                travelPolicyColumns,
                <SvgGreenCheck
                  height={16}
                  width={16}
                />,
                'info',
                styles.withHeader,
              )}
          </div>
        </LayoutDashboard>

        <FooterConfirm
          text="Employee Import"
          secondaryText={`${totalChanges} changes to confirm`}
          onSubmit={this.onSubmitImport}
          onCancel={this.onCancelImport}
          submitText={`Accept ${totalChanges} Changes`}
        />
      </>
    );
  }
}

function mapStateToProps(state: ReduxState) {
  return {
    report: state.importEmployees.validationReport,
    companyDetails: state.company.company,
    importEmployees: state.importEmployees,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      resetImportEmployees,
      confirmCSVImport,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CSVImport);

import * as React from 'react';
import * as _ from 'lodash-es';
import { navigate } from '@reach/router';

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Avatar from '@material-ui/core/Avatar';

import SvgIconAvatar from '../../static/icons/svgIconAvatar';
import LayoutDashboard from 'components/Layout/Dashboard';
import Loader from 'components/Loader/Loader';
import PersonalDetails from 'components/TravelerDetails/PersonalDetails';
import LoyaltyPrograms from 'components/TravelerDetails/LoyaltyPrograms';
import FlightCredits from 'components/TravelerDetails/FlightCredits';
import PaymentMethods from 'components/TravelerDetails/PaymentMethods';
import BookingRules from 'components/TravelerDetails/TravelPolicy/BookingRules';
import BudgetRules from 'components/TravelerDetails/TravelPolicy/BudgetRules';
import NotificationsAndApprovals from 'components/TravelerDetails/TravelPolicy/NotificationsAndApprovals';
import AccountDetails from 'components/TravelerDetails/AccountDetails';
import BookingsTable from 'components/TravelerDetails/BookingsTable';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/Breadcrumbs/Breadcrumb';
import Approvers from 'components/TravelerDetails/TravelPolicy/Approvers';
import ExpenseReportsTable from 'components/TravelerDetails/ExpenseReportsTable';
import DuffelSync from './DuffelSync';
import { hasPermission } from '../../helpers/Permission';

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { getSingle } from 'actions/Travelers/GetSingle';
import { getFrequentFlyers } from 'actions/FrequentFlyers/Get';
import { update as updateTraveler } from 'actions/Travelers/Update';
import {
  getTravelPolicyRules,
  emptyResults as resetTravelPolicy,
  resetSuccessProperty as resetTravelPolicySuccessProp,
} from 'actions/Users/GetTravelPolicyRules';
import { getExpenseReports } from 'actions/Users/GetExpenseReports';
import { getBudgetPolicyRules } from 'actions/Users/GetBudgetPolicyRules';
import { get as getCreditCards } from 'actions/User/GetUserCreditCards';
import { getUserFlightCredits } from 'actions/User/GetUserFlightCredits';
import { getApprovers } from 'actions/Travelers/GetApprovers';
import { getCarLoyaltyProgram } from '../../actions/LoyaltyPrograms/GetCarLoyaltyProgram';
import { getFraudRule } from 'actions/Users/GetUserFraudRule';
import { putFeatures } from 'actions/Features/PutUserBetaFeature';
import { getFeatures, resetFeatures } from 'actions/Features/Get';
import { syncDuffelBooking } from 'actions/Bookings/SyncDuffelBooking';
import BetaFeatures from 'components/Features/BetaFeatures';
import { TB_BLACKBOX_ENV } from '../../configs/Api';
import Notes from 'components/Notes/Notes';

import { Params } from 'actions/Travelers/Get';

const styles = require('./styles.pcss');

import { ReduxState, Traveler as TravelerType } from 'reducers/types';
import BookTrain from 'components/BookTrain/BookTrain';
import { postTrainBooking } from 'actions/Bookings/PostTrainBooking';

type Props = {
  travelerId?: string;
  getSingle: Function;
  postTrainBooking: Function;
  updateTraveler: Function;
  getFrequentFlyers: Function;
  traveler: TravelerType;
  isLoading: boolean;
  isLoadingCC: any;
  getTravelPolicyRules: Function;
  getExpenseReports: Function;
  user: any;
  userLoading: any;
  travelPolicy: any;
  budgetPolicy: any;
  resetTravelPolicy: Function;
  resetTravelPolicySuccessProp: Function;
  userId: any;
  travelers: any;
  getCreditCards: Function;
  getUserFlightCredits: Function;
  getApprovers: Function;
  approversIsLoading: boolean;
  approversList: any;
  carLoyaltyProgram: any;
  expenseReports: any;
  getCarLoyaltyProgram: Function;
  getBudgetPolicyRules: Function;
  getFraudRule: Function;
  syncDuffelBooking: Function;
  getFeatures: Function;
  resetFeatures: Function;
  putFeatures: Function;
  isLoadingFeatures: boolean;
  features: any;
};

type State = {
  activeTab: string;
};

class UserDetails extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const searchParams = window.location.search;
    const activeTabParam = new RegExp('[^?]*activeTab=([^&s]+)');
    const activeTabValue = searchParams.match(activeTabParam);
    const activeTab = activeTabValue?.[1] ? activeTabValue[1] : 'profile';

    this.state = {
      activeTab: activeTab,
    };

    this.onTabChange = this.onTabChange.bind(this);

    let param: any = {
      scopeType: 'user',
    };

    if (TB_BLACKBOX_ENV === 'production') {
      param.confidence = 'production';
    }

    if (hasPermission('features', 'read')) {
      this.props.getFeatures(param);
    }
  }

  onTabChange(event: any, value: any) {
    if (value === 'profile') {
      if (this.props.travelers.travelers.length > 0) {
        let traveler = _.find(
          this.props.travelers.travelers,
          (t) => t.isPrimary === true,
        );

        if (traveler !== undefined && traveler.hasOwnProperty('id')) {
          this.props.getSingle(traveler.id);
        }
      }
    }

    if (value === 'travel-policy' && hasPermission('users', 'read')) {
      this.props.getTravelPolicyRules(this.props.userId, 'hotel');
      this.props.getTravelPolicyRules(this.props.userId, 'flight');
      this.props.getApprovers(this.props.userId);

      if (_.get(this.props.user, 'travelPolicyId', '')) {
        this.props.getBudgetPolicyRules(
          _.get(this.props.user, 'travelPolicyId', ''),
        );
      }
    }

    if (value === 'expenses' && hasPermission('expenseReport', 'read')) {
      this.props.getExpenseReports(this.props.userId);
    }

    this.setState({
      activeTab: value,
    });
  }

  componentDidMount() {
    const params: Params = {
      userId: this.props.userId,
    };
    this.props.getFraudRule(this.props.userId);
    this.props.resetTravelPolicy();
    this.props.getFrequentFlyers();
    this.props.getCarLoyaltyProgram();
    this.props.getUserFlightCredits(this.props.userId);
    if (this.props.travelerId && this.props.travelerId !== null) {
      this.props.getSingle(this.props.travelerId);
    }
  }

  componentWillUnmount() {
    this.props.resetTravelPolicy();
    this.props.resetFeatures();
  }

  componentWillReceiveProps = (nextProps: Props) => {
    if (
      nextProps.travelers.travelers.length > 0 &&
      nextProps.travelers != this.props.travelers
    ) {
      if (!this.props.travelerId || this.props.travelerId === null) {
        let traveler = _.find(
          nextProps.travelers.travelers,
          (t) => t.isPrimary === true,
        );
        if (traveler !== undefined && traveler.hasOwnProperty('id')) {
          this.props.getSingle(traveler.id);
        }
      }
    }

    if (
      this.state.activeTab == 'travel-policy' &&
      !_.get(this.props.user, 'travelPolicyId', '') &&
      nextProps.travelPolicy.success &&
      !nextProps.budgetPolicy.isLoading &&
      !nextProps.budgetPolicy.policyRules
    ) {
      const defaultPolicyId =
        _.get(nextProps.travelPolicy, 'travelPolicyRules.id', '') ||
        _.get(nextProps.travelPolicy, 'travelPolicyFlight.id', '');

      if (defaultPolicyId) {
        this.props.getBudgetPolicyRules(defaultPolicyId);
        this.props.resetTravelPolicySuccessProp();
      }
    }
  };

  returnTravelerReadOnlyPermission() {
    let readOnly = false;
    let loginData: any = localStorage.getItem('login_data');

    if (loginData !== null) {
      let loginDataperse = JSON.parse(loginData);
      let userPermissions = _.get(loginDataperse, 'userPermissions', null);
      let updatePermission = _.result(
        _.find(userPermissions, (p) => p.resource == 'travelers'),
        'update',
      );
      let createPermission = _.result(
        _.find(userPermissions, (p) => p.resource == 'travelers'),
        'create',
      );
      let readPermission = _.result(
        _.find(userPermissions, (p) => p.resource == 'travelers'),
        'read',
      );

      if (!updatePermission && !createPermission && readPermission) {
        readOnly = true;
      }
    }

    return readOnly;
  }

  updateFrequentFlyers = (frequentFlyers: any) => {
    let traveler = _.clone(this.props.traveler);

    const data: any = {
      travelerId: traveler.id,
      firstName: traveler.firstName,
      middleName: traveler.middleName,
      lastName: traveler.lastName,
      gender: traveler.gender,
      birthDate: traveler.birthDate,
      knownTravelerNumber: traveler.knownTravelerNumber,
      email: traveler.email,
      phone: {
        countryCode: traveler.phoneCountryCode,
        number: traveler.phoneNumber,
      },
      passportInfo: {
        passCountry: traveler.passCountry,
        passNumber: traveler.passNumber,
        passExpiry: traveler.passExpiry,
      },
      frequentFlyer: frequentFlyers,
    };

    this.props.updateTraveler(data);
  };

  updateHotelLoyaltyProgram = (hotelLoyaltyPrograms: any) => {
    let traveler = _.clone(this.props.traveler);

    const data: any = {
      travelerId: traveler.id,
      firstName: traveler.firstName,
      middleName: traveler.middleName,
      lastName: traveler.lastName,
      gender: traveler.gender,
      birthDate: traveler.birthDate,
      knownTravelerNumber: traveler.knownTravelerNumber,
      email: traveler.email,
      phone: {
        countryCode: traveler.phoneCountryCode,
        number: traveler.phoneNumber,
      },
      passportInfo: {
        passCountry: traveler.passCountry,
        passNumber: traveler.passNumber,
        passExpiry: traveler.passExpiry,
      },
      frequentFlyer: traveler.frequentFlyer,
      hotelLoyaltyPrograms: hotelLoyaltyPrograms,
    };

    this.props.updateTraveler(data);
  };

  updateCarLoyaltyProgram = (carLoyalty: any) => {
    let traveler = _.clone(this.props.traveler);

    const data: any = {
      travelerId: traveler.id,
      firstName: traveler.firstName,
      middleName: traveler.middleName,
      lastName: traveler.lastName,
      gender: traveler.gender,
      birthDate: traveler.birthDate,
      knownTravelerNumber: traveler.knownTravelerNumber,
      email: traveler.email,
      phone: {
        countryCode: traveler.phoneCountryCode,
        number: traveler.phoneNumber,
      },
      passportInfo: {
        passCountry: traveler.passCountry,
        passNumber: traveler.passNumber,
        passExpiry: traveler.passExpiry,
      },
      carLoyaltyPrograms: carLoyalty,
    };

    this.props.updateTraveler(data);
  };

  _onCreditCardsChanged = () => {
    this.props.getCreditCards(this.props.userId);
  };

  renderCustomerDetails = () => {
    const createPermission = hasPermission('travelers', 'create');

    const newTraveler =
      _.get(this.props.travelers, 'travelers', []).length == 0 &&
      createPermission;

    return (
      <PersonalDetails
        traveler={this.props.traveler}
        newTraveler={newTraveler}
        userId={this.props.userId}
        readOnly={this.returnTravelerReadOnlyPermission()}
      />
    );
  };

  renderFlightCredits = () => {
    return (
      _.get(this.props, 'traveler', false) && (
        <FlightCredits
          flightCredits={_.get(this.props.user, 'flightCredits', [])}
          hasPermission={hasPermission('travelers', 'update')}
        />
      )
    );
  };

  renderNotes = () => {
    return (
      <Notes
        id={this.props.userId}
        type="user"
        entity={this.props.user}
      />
    );
  };

  handleFeatureChange = (feature: any, checked: boolean) => {
    let param = {
      userId: _.get(this.props, 'userId', null),
      featureName: feature.name,
      confidence: feature.confidence,
      enabled: checked,
      orgId: _.get(this.props.user?.organizations[0], 'id', null),
    };

    this.props.putFeatures(param);
  };

  renderContent() {
    if (this.state.activeTab == 'profile') {
      return (
        <div>
          {this.renderCustomerDetails()}
          {_.get(this.props, 'traveler', false) && (
            <LoyaltyPrograms
              travelerFrequentFlyerData={_.get(
                this.props,
                'traveler.frequentFlyer',
                [],
              )}
              travelerHotelLoyaltyProgram={_.get(
                this.props,
                'traveler.hotelLoyaltyPrograms',
                [],
              )}
              updateHotelProgram={this.updateHotelLoyaltyProgram}
              update={this.updateFrequentFlyers}
              hasPermission={hasPermission('bookings', 'update')}
              carLoyaltyProgram={this.props.carLoyaltyProgram}
              travelerCarLoyaltyProgram={_.get(
                this.props,
                'traveler.carLoyalty',
                [],
              )}
              updateCarProgram={this.updateCarLoyaltyProgram}
            />
          )}
          {this.renderFlightCredits()}
          {this.renderNotes()}
        </div>
      );
    }

    if (this.state.activeTab == 'credit-cards') {
      return (
        <div>
          <PaymentMethods
            creditCards={_.get(this.props.user, 'creditCards', [])}
            userId={this.props.userId}
            onCreditCardsChanged={this._onCreditCardsChanged}
          />
        </div>
      );
    }

    if (this.state.activeTab == 'travel-policy') {
      return (
        <div className={styles.travelPolicyRulesRow}>
          <div className={styles.travelPolicyRules}>
            <BudgetRules
              loading={
                this.props.budgetPolicy.isLoading ||
                this.props.travelPolicy.isLoading
              }
              policyRules={this.props.budgetPolicy.policyRules}
            />
            <BookingRules
              loading={this.props.travelPolicy.isLoading}
              hotelPolicy={this.props.travelPolicy.travelPolicyRules}
              flightPolicy={this.props.travelPolicy.travelPolicyFlight}
            />
            <NotificationsAndApprovals
              loading={this.props.travelPolicy.isLoading}
              hotelPolicy={this.props.travelPolicy.travelPolicyRules}
              flightPolicy={this.props.travelPolicy.travelPolicyFlight}
            />
          </div>
          <div>
            <Approvers
              loading={this.props.approversIsLoading}
              approversList={this.props.approversList}
            />
          </div>
        </div>
      );
    }

    if (this.state.activeTab == 'bookings') {
      return (
        <div>
          <Paper className={styles.syncPaper}>
            <DuffelSync
              userId={this.props.userId}
              sync={this.props.syncDuffelBooking}
            />
          </Paper>
          <Paper className={styles.syncPaper}>
            <BookTrain
              onSubmit={this.props.postTrainBooking}
              userId={this.props.userId}
            />
          </Paper>
          <Paper className={styles.paper}>
            <BookingsTable
              // travelerId={this.props.travelerId}
              userId={this.props.userId}
            />
          </Paper>
        </div>
      );
    }

    if (this.state.activeTab == 'expenses') {
      return (
        <Paper className={styles.paper}>
          <ExpenseReportsTable userId={this.props.userId} />
        </Paper>
      );
    }

    if (this.state.activeTab == 'account') {
      return (
        <div>
          <AccountDetails user={this.props.user} />
        </div>
      );
    }

    if (this.state.activeTab == 'beta') {
      const features = _.get(this.props, 'features', []);
      return (
        <BetaFeatures
          features={features}
          userId={_.get(this.props, 'userId', null)}
          handleChange={this.handleFeatureChange}
          type={'user'}
        />
      );
    }

    return null;
  }

  renderAvatar = () => {
    if (this.props.isLoading || this.props.userLoading) {
      return (
        <Avatar
          alt="Avatar"
          className={styles.avatar}
        ></Avatar>
      );
    }

    if (
      _.get(this.props.user, 'avatar', '').length > 1 &&
      _.get(this.props.user, 'avatar', '') != null
    ) {
      return (
        <Avatar
          alt="Avatar"
          src={_.get(this.props.user, 'avatar', '')}
          className={styles.avatar}
        />
      );
    } else {
      let firstName =
        _.get(this.props, 'user.firstName', null) !== null
          ? _.get(this.props, 'user.firstName', null)
          : '';
      let lastName =
        _.get(this.props, 'user.lastName', null) !== null
          ? _.get(this.props, 'user.lastName', null)
          : '';
      let name = `${firstName} ${lastName}`;

      if (name.trim() === '') {
        return (
          <SvgIconAvatar
            width={50}
            height={50}
          />
        );
      }

      return (
        <Avatar
          alt="avatar"
          className={styles.avatarLetter}
        >
          {firstName.charAt(0)}
          {lastName.charAt(0)}
        </Avatar>
      );
    }
  };

  getUserName = () => {
    let name = `${_.get(this.props.user, 'firstName', '')} ${_.get(
      this.props.user,
      'lastName',
      '',
    )}`;

    if (name.trim() === '') {
      name = _.get(this.props.user, 'email', '');
    }

    return name;
  };

  renderFraud = () => {
    const userFraudStatus = _.get(this.props, 'fraudCheck');
    const orgFraudStatus = _.get(this.props, 'user.organizations', '')?.[0]
      ?.fraudStatus;
    if (userFraudStatus === 'emailBlockList') {
      return <div className={styles.fraud}>Fraud</div>;
    } else if (orgFraudStatus === 'fraudulent') {
      return <div className={styles.fraud}>Fraud Org</div>;
    }

    return;
  };

  render() {
    let title;
    if (
      ['profile', 'credit-cards', 'travel-policy', 'beta'].includes(
        this.state.activeTab,
      )
    ) {
      title =
        _.get(this.props, 'traveler.firstName', '') +
        ' ' +
        _.get(this.props, 'traveler.lastName', '');
    } else {
      title =
        'Bookings for ' +
        _.get(this.props, 'traveler.firstName', '') +
        ' ' +
        _.get(this.props, 'traveler.lastName', '');
    }

    const orgs = _.uniqBy(_.get(this.props.user, 'organizations', []), 'id');

    return (
      <LayoutDashboard>
        {(this.props.isLoading ||
          this.props.userLoading ||
          this.props.isLoadingCC) && (
          <div className={styles.detailsLoaderContainer}>
            <Loader
              visible={true}
              id="userLoader"
            />
          </div>
        )}

        <div className="pageWrapper">
          <Breadcrumbs className={styles.breadcrumbs}>
            <Breadcrumb
              name="Lookup"
              arrow
            />
            <Breadcrumb name={this.getUserName()} />
          </Breadcrumbs>
          <div className={styles.header}>
            <div className={styles.avatarAndName}>
              <div className={styles.avatarContainer}>
                {this.renderAvatar()}
              </div>
              <div>
                <div className={styles.nameAndBrexContainer}>
                  <h1 className={styles.travelerName}>{this.getUserName()}</h1>

                  <div className={styles.chips}>
                    {_.get(this.props.user, 'agency.friendlyName', null) !==
                      null && (
                      <div
                        style={{
                          background: _.get(
                            this.props.user,
                            'agency.uiSettings.primaryColor',
                            '#6d717a',
                          ),
                        }}
                        className={styles.agency}
                      >
                        {this.props.user.agency.friendlyName}
                      </div>
                    )}
                    {_.get(this.props.user, '_meta.brex', null) !== null && (
                      <div className={styles.brex}>Brex</div>
                    )}
                    {_.get(this.props.user, 'status', '') == 'disabled' && (
                      <div className={styles.terminated}>Terminated</div>
                    )}
                    {this.renderFraud()}
                  </div>
                </div>

                <div className={styles.companyName}>
                  {orgs.map((organization: any, i: number) => {
                    const compName = _.get(organization, 'name', '');
                    return (
                      <>
                        <div
                          className={styles.link}
                          onClick={() => {
                            navigate(
                              `/company/${_.get(organization, 'id', '')}`,
                            );
                          }}
                        >
                          {compName}{' '}
                        </div>
                        {i + 1 < orgs.length && ', '}
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          <Tabs
            className={styles.tabs}
            value={this.state.activeTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={this.onTabChange}
          >
            {hasPermission('travelers', 'read') && (
              <Tab
                className={styles.tab}
                label="Profile"
                value="profile"
              />
            )}
            {hasPermission('features', 'read') && (
              <Tab
                disableRipple
                className={styles.tab}
                label="Beta"
                value="beta"
              />
            )}
            {hasPermission('creditCards', 'read') && (
              <Tab
                className={styles.tab}
                label="Payment"
                id="payment"
                value="credit-cards"
              />
            )}
            {hasPermission('users', 'read') && (
              <Tab
                className={styles.tab}
                label="Travel Policy"
                value="travel-policy"
              />
            )}
            {hasPermission('bookings', 'read') && (
              <Tab
                className={styles.tab}
                label="Bookings"
                value="bookings"
              />
            )}
            {hasPermission('expenseReport', 'read') && (
              <Tab
                className={styles.tab}
                label="Expenses"
                value="expenses"
              />
            )}
            {hasPermission('users', 'read') && (
              <Tab
                className={styles.tab}
                label="Account"
                id="account-tab"
                value="account"
              />
            )}
          </Tabs>

          {this.renderContent()}
        </div>
      </LayoutDashboard>
    );
  }
}

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {
    traveler: state.traveler.traveler,
    isLoading: state.traveler.isLoading,
    user: state.user.user,
    isLoadingCC: state.user.isLoadingCC,
    userLoading: state.user.isLoading,
    travelPolicy: state.userTravelPolicyRules,
    budgetPolicy: state.userBudgetPolicyRules,
    travelers: state.travelers,
    approversList: state.approvers.users,
    approversIsLoading: state.approvers.isLoading,
    carLoyaltyProgram: state.carLoyaltyPrograms.carLoyaltyProgram,
    expenseReports: state.userExpenseReports.expenseReports,
    fraudCheck: state.userFraudRule.rule,
    features: state.betaFeatures.features,
    isLoadingFeatures: state.betaFeatures.isLoading,
  };
}

// Wire up redux dispatch functions
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getSingle,
      getFrequentFlyers,
      updateTraveler,
      getTravelPolicyRules,
      getBudgetPolicyRules,
      getExpenseReports,
      resetTravelPolicy,
      resetTravelPolicySuccessProp,
      getCreditCards,
      getUserFlightCredits,
      getApprovers,
      getCarLoyaltyProgram,
      getFraudRule,
      resetFeatures,
      putFeatures,
      getFeatures,
      syncDuffelBooking,
      postTrainBooking,
    },
    dispatch,
  );
}

// Connect it to Redux
export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);

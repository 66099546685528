import {
  ACTION_GET_COMPANIES,
  ACTION_GET_COMPANIES_SUCCESS,
  ACTION_GET_COMPANIES_FAILURE,
  ACTION_EMPTY_COMPANIES,
  API_CALL,
} from '../../configs/ActionTypes';

import { Dispatch } from 'redux';

import { enqueueSnackbarHandler } from '../Status';

export function get(params?: any) {
  return (dispatch: Dispatch, getState: Function) => {
    //Dispatch the api call request.
    dispatch({
      type: API_CALL,
      payload: {
        call: 'organizations',
        method: 'get',
        data: params || {},
        success: (response: any) => {
          if (response.type === 'error') {
            enqueueSnackbarHandler(dispatch, response.payload.payload, 'error');
            dispatch(requestFailed(response));
          } else {
            dispatch(requestSuccess(response));
          }
        },
        fail: (error: any) => {
          enqueueSnackbarHandler(dispatch, error.payload, 'error');
          dispatch(requestFailed(error));
        },
      },
    });

    dispatch(requestStarted());

    return null;
  };
}

export function emptyResults() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ACTION_EMPTY_COMPANIES,
      payload: null,
    });
  };
}

export function requestStarted() {
  return {
    type: ACTION_GET_COMPANIES,
    payload: null,
  };
}

export function requestSuccess(responseJSON: object) {
  return {
    type: ACTION_GET_COMPANIES_SUCCESS,
    payload: responseJSON,
  };
}

export function requestFailed(responseJSON: object) {
  return {
    type: ACTION_GET_COMPANIES_FAILURE,
    payload: responseJSON,
  };
}

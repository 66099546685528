import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import _ from 'lodash-es';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MultiSelectWithSearchDynamic from '../Inputs/MultiSelect/MultiSelectWithSearchDynamic';
import SelectWithCheckBox from 'components/Inputs/MultiSelect/SelectWithCheckBox';
import TextFieldWithClear from 'components/TextFieldWithIcon/TextFieldWithIcon';
import cx from 'classnames';
import Loader from 'components/Loader/Loader';
const styles = require('./styles.pcss');
import { ReduxState } from '../../reducers/types';

type Props = {
  openPopup: boolean;
  closePopup: any;
  user: any;
  readOnly: any;
  handleSubmit: Function;
  userLoading: any;
  updateSuccess: any;
  userErrors: any;
};

type State = {
  firstName: any;
  lastName: any;
  phoneNumber: any;
  firstNameError: string;
  lastNameError: string;
  emailError: string;
  email: any;
  departments: any;
  notes?: string;
};

const _getNullSafe = (val: any, props: any, defaultVal: any) => {
  const v = _.get(val, props, defaultVal);
  return _.isNull(v) ? defaultVal : v;
};

class UserAnalyticsPopup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      firstNameError: '',
      lastNameError: '',
      emailError: '',
      firstName: _getNullSafe(this.props, 'user.firstName', ''),
      lastName: _getNullSafe(this.props, 'user.lastName', ''),
      phoneNumber: _getNullSafe(this.props, 'user.phoneNumber', ''),
      email: _getNullSafe(this.props, 'user.email', ''),
      departments: this.getDepartments(this.props),
      notes: _getNullSafe(this.props, 'user.notes', ''),
    };
  }

  onChangeFirstName = (event: any) => {
    this.setState({
      firstName: event.target.value,
      firstNameError: '',
    });
  };

  clearFirstName = () => {
    this.setState({
      firstName: '',
      firstNameError: '',
    });
  };

  clearLastName = () => {
    this.setState({
      lastName: '',
      lastNameError: '',
    });
  };

  clearPhoneNumber = () => {
    this.setState({
      phoneNumber: '',
    });
  };

  clearEmail = () => {
    this.setState({
      email: '',
      emailError: '',
    });
  };

  onChangeLastName = (event: any) => {
    this.setState({
      lastName: event.target.value,
      lastNameError: '',
    });
  };

  onChangePhoneNumber = (event: any) => {
    this.setState({
      phoneNumber: event.target.value,
    });
  };

  onChangeEmail = (event: any) => {
    this.setState({
      email: event.target.value,
      emailError: '',
    });
  };

  componentWillReceiveProps = (nextProps: any) => {
    if (
      (nextProps.openPopup == true && !this.props.openPopup) ||
      _.get(nextProps, 'updateSuccess', false) === true
    ) {
      this.setState({
        firstNameError: '',
        lastNameError: '',
        emailError: '',
        firstName: _getNullSafe(nextProps, 'user.firstName', ''),
        lastName: _getNullSafe(nextProps, 'user.lastName', ''),
        phoneNumber: _getNullSafe(nextProps, 'user.phoneNumber', ''),
        email: _getNullSafe(nextProps, 'user.email', ''),
        departments: this.getDepartments(nextProps),
        notes: _getNullSafe(nextProps, 'user.notes', ''),
      });
    }

    if (_.get(nextProps, 'updateSuccess', false) === true) {
      this.closePopup();
    }

    const firstNameError = _.get(nextProps, 'userErrors.firstName', '');
    const lastNameError = _.get(nextProps, 'userErrors.lastName', '');
    const emailError = _.get(nextProps, 'userErrors.email', '');

    if (firstNameError != '' || lastNameError != '' || emailError != '') {
      this.setState({
        firstNameError,
        lastNameError,
        emailError,
      });

      return;
    }
  };

  onClickDeleteDepartments = () => {
    this.setState({
      departments: [],
    });
  };

  onChangeDepartments = (departments: any) => {
    this.setState({
      departments,
    });
  };

  renderDepartments = () => {
    if (_.get(this.props, 'user.organizations', []).length == 0) {
      return null;
    }

    const depart = _.get(
      _.get(this.props, 'user.organizations[0]', {}),
      'departments',
      [],
    ).map((data: any) => ({
      key: data.id,
      value: data.name,
    }));

    const departments = depart
      ? depart.sort((a: any, b: any) => a.value.localeCompare(b.value))
      : [];

    return (
      <div className={styles.inputWrapper}>
        <SelectWithCheckBox
          className={styles.inputWrapper}
          id="departments"
          value={this.state.departments}
          label="Department"
          onChange={(value: any) => {
            this.onChangeDepartments(value);
          }}
          disabled={_.get(this.props, 'employee.status') === 'disabled'}
          selections={departments}
          endText={'Optional'}
          isMultiple={true}
          onClickClear={this.onClickDeleteDepartments}
          searchable={true}
        />
      </div>
    );
  };

  getDepartments = (props: any) => {
    if (_.get(props, 'user.departments', []).length < 1) {
      return [];
    }

    return _.map(_.get(props, 'user.departments', []), (department) => {
      return department.id;
    });
  };

  validFields = () => {
    let isValid = true;
    let firstNameError = '';
    let lastNameError = '';

    if (this.state.firstName.trim() === '') {
      firstNameError = 'Required';
      isValid = false;
    }

    if (this.state.lastName.trim() === '') {
      lastNameError = 'Required';
      isValid = false;
    }

    this.setState({
      firstNameError,
      lastNameError,
    });

    return isValid;
  };

  handleSubmit = () => {
    let organizationId = null;

    if (_.get(this.props, 'user.organizations', []).length > 0) {
      organizationId = _.get(this.props, 'user.organizations', [])[0].id;
    }

    const propsDepartment = this.getDepartments(this.props);
    const departmentUpdate =
      this.state.departments.every((dep: any) =>
        propsDepartment.includes(dep),
      ) &&
      this.state.departments.length == propsDepartment.length &&
      propsDepartment.length > 0;

    let data: any = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      phoneNumber: this.state.phoneNumber,
      departments: this.state.departments,
      organizationId: organizationId,
      departmentUpdate: !departmentUpdate,
      notes: this.state.notes,
    };

    if (!this.validFields()) {
      return;
    }

    this.props.handleSubmit(data);
  };

  renderLoader = () => {
    if (!_.get(this.props, 'userLoading', false)) {
      return null;
    }

    return (
      <div className={styles.loaderContainer}>
        <Loader visible={true} />
      </div>
    );
  };

  closePopup = () => {
    this.props.closePopup();
  };

  render() {
    return (
      <Dialog
        fullWidth={true}
        maxWidth="md"
        className={classNames(styles.dialogBox, styles.ccPopup)}
        open={this.props.openPopup}
        onClose={this.closePopup}
      >
        <div
          className={classNames(styles.title, styles.editUserAnalyticsPopup)}
        >
          <div className={styles.titleStyle}>{'Edit User Details'}</div>
          <IconButton
            color="inherit"
            onClick={this.closePopup}
            className={classNames(styles.closeIcon)}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        </div>
        {this.renderLoader()}
        <div className={styles.updatePopup}>
          <div
            className={classNames(
              styles.userAnalyticsDescription,
              styles.addMarginTop,
            )}
          >
            Update details for {_.get(this.props, 'user.firstName', '')}{' '}
            {_.get(this.props, 'user.lastName', '')}`s profile
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.inputWrapper}>
              <TextFieldWithClear
                disabled={this.props.readOnly}
                error={Boolean(this.state.firstNameError)}
                helperText={this.state.firstNameError}
                onChange={this.onChangeFirstName}
                label="First Name"
                variant="outlined"
                margin="normal"
                value={this.state.firstName}
                onClear={this.clearFirstName}
                fullWidth={true}
                id="first-name"
              />
            </div>

            <div className={styles.inputWrapper}>
              <TextFieldWithClear
                disabled={this.props.readOnly}
                error={Boolean(this.state.lastNameError)}
                helperText={this.state.lastNameError}
                onChange={this.onChangeLastName}
                label="Last Name"
                variant="outlined"
                margin="normal"
                value={this.state.lastName}
                onClear={this.clearLastName}
                fullWidth={true}
                id="last-name"
              />
            </div>

            <div className={styles.inputWrapper}>
              <TextFieldWithClear
                disabled={this.props.readOnly}
                error={Boolean(this.state.emailError)}
                helperText={this.state.emailError}
                onChange={this.onChangeEmail}
                fullWidth={true}
                label="Email"
                variant="outlined"
                margin="normal"
                value={this.state.email}
                onClear={this.clearEmail}
                id="email"
              />
            </div>

            <div className={styles.inputWrapper}>
              <TextFieldWithClear
                disabled={this.props.readOnly}
                onChange={this.onChangePhoneNumber}
                fullWidth={true}
                label="Phone Number"
                variant="outlined"
                margin="normal"
                value={this.state.phoneNumber}
                onClear={this.clearPhoneNumber}
                id="phone"
              />
            </div>

            {this.renderDepartments()}
          </div>
        </div>

        <div
          className={classNames(
            styles.actions,
            styles.actionsRight,
            styles.buttonContainerMargin,
          )}
        >
          <div
            className={classNames(
              styles.popupButtonsContainer,
              styles.userDetailsButton,
            )}
          >
            <Button
              size="small"
              className={styles.button}
              variant="contained"
              color="primary"
              onClick={this.handleSubmit}
            >
              Save
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {
    userErrors: state.user.errors,
    userLoading: state.user.isLoading,
    updateSuccess: state.user.updateSuccess,
  };
}

// Wire up redux dispatch functions
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({}, dispatch);
}

// Connect it to Redux
export default connect(mapStateToProps, mapDispatchToProps)(UserAnalyticsPopup);

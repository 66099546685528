import * as React from 'react';
import cx from 'classnames';
import _ from 'lodash-es';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import SvgArrowDown from '../../../static/icons/svgArrowDown';
import { convertToNumber } from '../../../helpers/ConvertToNumber';

const styles = require('./styles.pcss');

type Props = {
  basePrice: any;
  taxes: any;
  passenger: any;
  totalPrice: any;
  taxesArray: any;
  providerName: string;
  refundable?: boolean;
};

type State = {
  openTaxes: boolean;
};

class FlightDetails extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);

    this.state = {
      openTaxes: false,
    };
  }

  renderTaxesAndFees = (taxes: any) => {
    let tax = _.get(taxes, 'value', '')
      ? parseFloat(taxes.value.substring(3)).toFixed(2)
      : ' - - ';

    return (
      <div className={cx(styles.row, styles.withPrice, styles.dropdown)}>
        <div className={styles.title}>{taxes.type}</div>
        <div className={styles.price}>+${tax}</div>
      </div>
    );
  };

  onClickTaxesAndFees = () => {
    this.setState((currentState: any) => ({
      openTaxes: !currentState.openTaxes,
    }));
  };

  renderTaxesAndFeesIcon = () => {
    if (_.get(this.props, 'taxesArray', []).length > 0) {
      return (
        <div
          className={styles.title}
          onClick={this.onClickTaxesAndFees}
        >
          Taxes & Fees{' '}
          <SvgArrowDown
            width={9}
            height={6}
            color={'#6D717A'}
          />
        </div>
      );
    }

    return <div className={styles.title}>Taxes & Fees</div>;
  };

  render() {
    let nr_passenger = convertToNumber(_.get(this.props, 'passenger', 0));

    let providerName = _.get(this.props, 'providerName', 'Unknown');

    let refundable = 'Non-refundable';

    if (_.get(this.props, 'refundable', false) === true) {
      refundable = 'Refundable';
    }

    return (
      <Paper className={styles.flightDetails}>
        <div className={styles.numbers}>
          <div className={cx(styles.row, styles.cancelPolicyRow)}>
            <div className={styles.cancelPolicy}>
              <div className={styles.flightDetailsTitle}>Flight Details</div>
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.subtitle}>Provider</div>
            <div
              className={cx(styles.title, styles.highlight)}
              id="providerName"
            >
              {providerName}
            </div>
          </div>

          <div className={styles.row}>
            <div
              className={styles.title}
              id="passengerNR"
            >
              {nr_passenger <= 1
                ? `${nr_passenger} Passenger`
                : `${nr_passenger} Passengers`}
            </div>
          </div>

          <div className={cx(styles.row, styles.cancelPolicyRow)}>
            <div className={styles.cancelPolicy}>
              <div className={styles.subtitle}>Rate Type</div>
              <div className={cx(styles.title, styles.highlight)}>
                {refundable}
              </div>
            </div>
          </div>

          <div className={cx(styles.row, styles.withPrice)}>
            <div className={styles.title}>Base Fare </div>
            <div className={styles.baseFare}>
              <div
                className={styles.baseprice}
                id="basePrice"
              >
                {_.get(this.props, 'basePrice', 0)
                  ? '$' +
                    convertToNumber(
                      _.get(this.props, 'basePrice', 0) * nr_passenger,
                    ).toFixed(2)
                  : ' - - '}
              </div>
              <div className={styles.price}>
                (
                {_.get(this.props, 'basePrice', 0)
                  ? '$' +
                    convertToNumber(_.get(this.props, 'basePrice', 0)).toFixed(
                      2,
                    )
                  : ' - - '}{' '}
                x{' '}
                {nr_passenger < 1
                  ? `${nr_passenger} Passenger`
                  : `${nr_passenger} Passengers`}
                )
              </div>
            </div>
          </div>

          <div
            className={
              this.state.openTaxes
                ? cx(styles.row, styles.withPrice, styles.rotate, styles.isLink)
                : cx(styles.row, styles.withPrice, styles.isLink)
            }
          >
            {this.renderTaxesAndFeesIcon()}
            <div
              className={styles.price}
              id="taxes"
            >
              {_.get(this.props, 'taxes', 0)
                ? '+$' +
                  convertToNumber(_.get(this.props, 'taxes', 0)).toFixed(2)
                : ' - - '}
            </div>
          </div>

          {this.state.openTaxes &&
            this.props.taxesArray.map((taxes: any, i: any) => {
              return this.renderTaxesAndFees(taxes);
            })}

          <div
            className={cx(
              styles.row,
              styles.withPrice,
              styles.highlight,
              styles.noBorder,
            )}
          >
            <div className={styles.totalPrice}>Trip Total</div>
            <div
              className={cx(styles.price, styles.total)}
              id="tripTotal"
            >
              {_.get(this.props, 'totalPrice', 0)
                ? '$' + _.get(this.props, 'totalPrice', 0)
                : ' - - '}
            </div>
          </div>
        </div>
      </Paper>
    );
  }
}

export default FlightDetails;

/**
 * PUT BOOKINGS Action
 */
import {
  ACTION_PUT_BOOKINGS_CUSTOM_TAG,
  ACTION_PUT_BOOKINGS_CUSTOM_TAG_SUCCESS,
  ACTION_PUT_BOOKINGS_CUSTOM_TAG_FAILURE,
  ACTION_EMPTY_BOOKINGS,
  API_CALL,
} from '../../configs/ActionTypes';

import _ from 'lodash-es';
import { Dispatch } from 'redux';
import { enqueueSnackbarHandler } from '../Status';
/**
 * PutCustomTag Request Action
 */
export function putCustomTags(params: any) {
  return (dispatch: Dispatch, getState: Function) => {
    dispatch(requestStarted());

    //Dispatch the api call request.
    dispatch({
      type: API_CALL,
      payload: {
        call: 'bookings/tags',
        method: 'put',
        data: JSON.stringify(params) || {},
        success: (response: any) => {
          if (response.type === 'error') {
            dispatch(requestFailed(response));
            _.get(response.payload.payload, 'userPermissionError', false)
              ? enqueueSnackbarHandler(
                  dispatch,
                  response.payload.payload,
                  'error',
                )
              : enqueueSnackbarHandler(
                  dispatch,
                  { updateBookingCustomTagsFailed: true },
                  'error',
                );
          } else {
            dispatch(requestSuccess(response));
            enqueueSnackbarHandler(
              dispatch,
              { updateBookingCustomTags: true },
              'success',
            );
          }
        },
        fail: (error: any) => {
          // dispatch(showStatus(error));
          dispatch(requestFailed(error));
          _.get(error.payload, 'userPermissionError', false)
            ? enqueueSnackbarHandler(dispatch, error.payload, 'error')
            : enqueueSnackbarHandler(
                dispatch,
                { updateBookingCustomTagsFailed: true },
                'error',
              );
        },
      },
    });

    return null;
  };
}

export function requestStarted() {
  return {
    type: ACTION_PUT_BOOKINGS_CUSTOM_TAG,
    payload: null,
  };
}

export function requestSuccess(responseJSON: any) {
  return {
    type: ACTION_PUT_BOOKINGS_CUSTOM_TAG_SUCCESS,
    payload: responseJSON,
  };
}

export function requestFailed(responseJSON: any) {
  return {
    type: ACTION_PUT_BOOKINGS_CUSTOM_TAG_FAILURE,
    payload: responseJSON,
  };
}

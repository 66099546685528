import React from 'react';

type Props = {
  width: number;
  height: number;
};

const SvgIconCreditCard = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      version="1.0"
      viewBox="0 0 23 14"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      enableBackground="new 0 0 164.409 141.732"
      xmlSpace="preserve"
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="menu"
          transform="translate(-12.000000, -108.000000)"
          fill="#FFFFFF"
          fillRule="nonzero"
        >
          <g id="creditCard">
            <g transform="translate(12.000000, 108.000000)">
              <path
                d="M0,12.8882353 C0,13.4235294 0.452941176,13.8764706 0.988235294,13.8764706 L21.7823529,13.8764706 C22.3176471,13.8764706 22.7705882,13.4235294 22.7705882,12.8882353 L22.7705882,5.47647059 L0,5.47647059 L0,12.8882353 Z M16.6764706,8.97647059 C16.6764706,8.31764706 17.2117647,7.82352941 17.8294118,7.82352941 L18.9411765,7.82352941 C19.6,7.82352941 20.0941176,8.35882353 20.0941176,8.97647059 L20.0941176,10.0882353 C20.0941176,10.7470588 19.5588235,11.2411765 18.9411765,11.2411765 L17.8294118,11.2411765 C17.1705882,11.2411765 16.6764706,10.7058824 16.6764706,10.0882353 L16.6764706,8.97647059 Z M2.55294118,10.2941176 C2.55294118,10.1705882 2.67647059,10.0470588 2.8,10.0470588 L6.54705882,10.0470588 C6.67058824,10.0470588 6.79411765,10.1705882 6.79411765,10.2941176 L6.79411765,11.0352941 C6.79411765,11.1588235 6.67058824,11.2823529 6.54705882,11.2823529 L2.8,11.2823529 C2.67647059,11.2823529 2.55294118,11.1588235 2.55294118,11.0352941 L2.55294118,10.2941176 Z"
                id="Shape"
              ></path>
              <path
                d="M22.7705882,1.07058824 C22.7705882,0.535294118 22.3176471,0.0823529412 21.7823529,0.0823529412 L0.988235294,0.0823529412 C0.452941176,0.0823529412 0,0.535294118 0,1.07058824 L0,3.54117647 L22.7705882,3.54117647 L22.7705882,1.07058824 Z"
                id="Path"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgIconCreditCard;

import React from 'react';

type Props = {
  width: number;
  height: number;
  color: string;
};

const SvgLocation = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 12 15"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs></defs>
      <g
        id="(WIP)-Refund-Card"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Booking-Macro---Flight---Flight-Modal"
          transform="translate(-966.000000, -262.000000)"
          fill={props.color}
        >
          <g
            id="create-modal"
            transform="translate(370.000000, 143.000000)"
          >
            <g id="content">
              <g
                id="Flight-Card"
                transform="translate(1.000000, 77.000000)"
              >
                <g
                  id="route-1"
                  transform="translate(31.000000, 32.000000)"
                >
                  <path
                    d="M569.769231,24.9210526 C569.769231,24.9210526 564,18.4224445 564,15.4258373 C564,12.4292301 566.582973,10 569.769231,10 C572.955489,10 575.538462,12.4292301 575.538462,15.4258373 C575.538462,18.4224445 569.769231,24.9210526 569.769231,24.9210526 Z M569.769231,18.034413 C571.043734,18.034413 572.076923,17.0066618 572.076923,15.7388664 C572.076923,14.471071 571.043734,13.4433198 569.769231,13.4433198 C568.494728,13.4433198 567.461538,14.471071 567.461538,15.7388664 C567.461538,17.0066618 568.494728,18.034413 569.769231,18.034413 Z"
                    id="Combined-Shape"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

SvgLocation.defaultProps = {
  width: 13,
  height: 13,
  color: '#318AFB',
};

export default SvgLocation;

import {
  ACTION_RESET_PASSWORD_SUCCESS,
  ACTION_RESET_PASSWORD_FAILURE,
  ACTION_RESET_PASSWORD_RESET_STATES,
} from '../configs/ActionTypes';

import _ from 'lodash-es';

const initialState = {
  currentPasswordError: '',
  newPasswordError: '',
  confirmNewPasswordError: '',
  success: null,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        success: true,
      };
    }
    case ACTION_RESET_PASSWORD_FAILURE: {
      const p = action.payload.payload;

      let currentPasswordError: string = '';
      let newPasswordError: string = '';
      let confirmNewPasswordError: string = '';

      if (
        p.hasOwnProperty('currentPasswordWrong') &&
        p.currentPasswordWrong === true
      ) {
        currentPasswordError = 'Wrong password';
      }

      if (p.hasOwnProperty('newPasswordEmpty') && p.newPasswordEmpty === true) {
        newPasswordError = 'Required';
      }

      if (p.hasOwnProperty('newPasswordWeak') && p.newPasswordWeak === true) {
        newPasswordError = 'Password should contain at least 8 characters';
      }

      if (
        p.hasOwnProperty('newPasswordCommon') &&
        p.newPasswordCommon === true
      ) {
        newPasswordError = 'Password is common';
      }

      if (
        p.hasOwnProperty('confirmNewPasswordWrong') &&
        p.confirmNewPasswordWrong === true
      ) {
        confirmNewPasswordError =
          'New password and confirm new password does not match';
      }

      return {
        ...state,
        currentPasswordError,
        newPasswordError,
        confirmNewPasswordError,
        success: false,
      };
    }
    case ACTION_RESET_PASSWORD_RESET_STATES: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

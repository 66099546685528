import * as React from 'react';
import cx from 'classnames';

const styles = require('./style.pcss');

type Props = {
  children?: React.ReactNode;
  header?: boolean;
};

const TableRow = ({ children, ...props }: Props) => {
  let cls = styles.row;

  if (props.header) {
    cls = cx(styles.row, styles.header);
  }

  return <div className={cls}>{children}</div>;
};

export default TableRow;

import * as React from 'react';
import LayoutDashboard from 'components/Layout/Dashboard';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/Breadcrumbs/Breadcrumb';
import { QUERY_LIMIT_25 } from '../../configs/General';
import UsersBody from 'components/ManageBlackBox/Users/Users';
import AddUserPopup from 'components/Popup/AddUserPopup';
const styles = require('./styles.pcss');
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import {
  get as getBBUsers,
  emptyResults,
} from '../../actions/ManageBB/Users/Get';
import { ReduxState } from '../../reducers/types';
import { hasPermission } from '../../helpers/Permission';
import _ from 'lodash-es';
import Button from '@material-ui/core/Button';

type State = {
  openAddPopup: boolean;
  userToUpdate: any;
};

class ManageBlackBoxUsers extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      openAddPopup: false,
      userToUpdate: '',
    };
  }

  componentDidMount() {
    const loginData = localStorage.getItem('login_data') as string;
    const parsedLoginData = JSON.parse(loginData);

    this.props.getBBUsers({
      limit: QUERY_LIMIT_25,
      skip: 0,
      agencyId: _.get(parsedLoginData, 'agencyId', ''),
    });
  }

  openPopup = () => {
    this.setState({
      openAddPopup: true,
    });
  };

  onClosePopup = () => {
    this.setState({
      openAddPopup: false,
      userToUpdate: '',
    });
  };

  onAddBBUserSuccess = () => {
    const loginData = localStorage.getItem('login_data') as string;
    const parsedLoginData = JSON.parse(loginData);
    this.props.emptyResults();
    this.props.getBBUsers({
      limit: QUERY_LIMIT_25,
      skip: 0,
      agencyId: _.get(parsedLoginData, 'agencyId', ''),
    });
  };

  onClickEdit = (user: any) => {
    this.setState({
      userToUpdate: user,
      openAddPopup: true,
    });
  };

  renderAddButton = () => {
    if (!hasPermission('blackBox', 'create')) {
      return null;
    }

    return (
      <Button
        className={styles.buttonAddUser}
        variant="contained"
        color="primary"
        onClick={this.openPopup}
        id="add-user"
      >
        Add
      </Button>
    );
  };

  render() {
    return (
      <LayoutDashboard>
        <Breadcrumbs className={styles.breadcrumbs}>
          <Breadcrumb
            name="BB Settings"
            arrow
          />
          <Breadcrumb name="Users" />
        </Breadcrumbs>
        <div className={styles.header}>
          <h1 className={styles.h1}>Users</h1>
          {this.renderAddButton()}
        </div>
        <div className={styles.body}>
          <UsersBody onClickEdit={this.onClickEdit} />
        </div>
        <AddUserPopup
          showAddUser={this.state.openAddPopup}
          closeAddUser={this.onClosePopup}
          onSuccess={this.onAddBBUserSuccess}
          updateUser={this.state.userToUpdate}
        />
      </LayoutDashboard>
    );
  }
}

// Wire up redux dispatch functions
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getBBUsers,
      emptyResults,
    },
    dispatch,
  );
}

// Connect it to Redux
export default connect(null, mapDispatchToProps)(ManageBlackBoxUsers);

import * as React from 'react';
import Button from '@material-ui/core/Button';
import LayoutDashboard from 'components/Layout/Dashboard';
import SvgIconFlag from '../../static/icons/svgIconFlag';
import SvgIconHotel from '../../static/icons/svgIconHotel';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/Breadcrumbs/Breadcrumb';
import Features from 'components/ManageTB/Features/Features';
import { hasPermission } from '../../helpers/Permission';

const styles = require('./styles.pcss');
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { getFeatures } from '../../actions/Features/Get';
import { ReduxState } from '../../reducers/types';

type Props = {
  getFeatures: Function;
  features: any;
};

type State = {
  openAddPopup: boolean;
};

class ManageTBFeatures extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      openAddPopup: false,
    };
  }

  componentDidMount() {
    this.props.getFeatures();
  }

  onClickAddButton = () => {
    this.setState({
      openAddPopup: true,
    });
  };

  onCloseAddFlagPopup = () => {
    this.setState({
      openAddPopup: false,
    });
  };

  render() {
    return (
      <LayoutDashboard>
        <Breadcrumbs className={styles.breadcrumbs}>
          <Breadcrumb
            name="Manage TB"
            arrow
          />
          <Breadcrumb name="Feature Flags" />
        </Breadcrumbs>
        <div className={styles.header}>
          <h1 className={styles.h1}>Feature Flags</h1>

          {hasPermission('features', 'create') && (
            <Button
              className={styles.addButton}
              onClick={this.onClickAddButton}
              variant="contained"
              color="primary"
            >
              Add
            </Button>
          )}
        </div>
        <div className={styles.body}>
          <Features
            addPopupState={this.state.openAddPopup}
            onCloseAddFlagPopup={this.onCloseAddFlagPopup}
          />
        </div>
      </LayoutDashboard>
    );
  }
}

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {
    features: state.betaFeatures.features,
  };
}

// Wire up redux dispatch functions
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getFeatures,
    },
    dispatch,
  );
}

// Connect it to Redux
export default connect(mapStateToProps, mapDispatchToProps)(ManageTBFeatures);

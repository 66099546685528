import * as React from 'react';
import _ from 'lodash-es';
import cx from 'classnames';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Loader from 'components/Loader/Loader';
const styles = require('../styles.pcss');
import IconRadioChecked from '../../../static/icons/svgCheckboxCheckedCircle';
import { Agency, ReduxState } from '../../../reducers/types';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

type Props = {
  possibleProviders: any;
  providerName: any;
  type: string;
  providers: any;
  title?: string;
  disabled: boolean;
  organizationAgency?: any;
  editProvider: Function;
  componentStyle?: boolean;
};

class Provider extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);
  }

  editProvider = (provider: any) => {
    this.props.editProvider(this.props.type, provider);
  };

  render() {
    const buttonText = !this.props.disabled ? 'Edit' : 'View';
    let style = cx(styles.paper);
    if (this.props.componentStyle) {
      style = cx(styles.component);
    }

    return (
      <Paper className={cx(style)}>
        {this.props.title && (
          <h2 className={styles.providerTitle}>{this.props.title}</h2>
        )}

        {_.map(this.props.possibleProviders, (provider) => {
          let disabledFromAgency: any = false;

          if (
            this.props.organizationAgency &&
            !_.get(
              this.props.organizationAgency,
              `settings.${this.props.providerName}`,
              [],
            ).includes(provider)
          ) {
            disabledFromAgency = `Disabled by ${_.get(
              this.props.organizationAgency,
              'friendlyName',
              _.get(this.props.organizationAgency, 'name', ''),
            )}`;
          }

          let exists = false;

          if (this.props.organizationAgency) {
            exists =
              _.get(
                this.props.organizationAgency,
                `settings.${this.props.providerName}`,
                [],
              ).includes(provider) &&
              (!this.props.providers ||
                (this.props.providers &&
                  this.props.providers.includes(provider)));
          } else {
            exists = this.props.providers
              ? this.props.providers.includes(provider)
              : false;
          }

          return (
            <li
              className={styles.providerContainer}
              key={`providerContainer${this.props.type}${provider}`}
            >
              <div className={styles.providerName}>
                {provider}
                {exists && (
                  <IconRadioChecked
                    width={16}
                    height={16}
                  />
                )}
              </div>

              <div>
                {disabledFromAgency ? (
                  <div className={styles.disabledAgencyName}>
                    {disabledFromAgency}
                  </div>
                ) : (
                  <Button
                    size="small"
                    className={styles.button}
                    variant="outlined"
                    color="primary"
                    onClick={this.editProvider.bind(this, provider)}
                  >
                    {buttonText}
                  </Button>
                )}
              </div>
            </li>
          );
        })}
      </Paper>
    );
  }
}

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {};
}

// Wire up redux dispatch functions
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({}, dispatch);
}

// Connect it to Redux
export default connect(mapStateToProps, mapDispatchToProps)(Provider);

import {
  ACTION_GET_USER_FRAUD_RULE,
  ACTION_GET_USER_FRAUD_RULE_SUCCESS,
  ACTION_GET_USER_FRAUD_RULE_FAILURE,
  ACTION_PUT_USER_FRAUD_RULE,
  ACTION_PUT_USER_FRAUD_RULE_SUCCESS,
  ACTION_PUT_USER_FRAUD_RULE_FAILURE,
} from '../configs/ActionTypes';

import _ from 'lodash-es';

const initialState = {
  rule: '',
  isLoading: false,
  success: null,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_GET_USER_FRAUD_RULE:
    case ACTION_PUT_USER_FRAUD_RULE: {
      return {
        ...state,
        isLoading: true,
        success: null,
      };
    }

    case ACTION_GET_USER_FRAUD_RULE_SUCCESS:
    case ACTION_PUT_USER_FRAUD_RULE_SUCCESS: {
      let rule = _.get(action.payload, 'payload', '');

      return {
        ...state,
        rule: rule.length > 0 ? rule : 'recommended',
        isLoading: false,
        success: null,
      };
    }

    case ACTION_GET_USER_FRAUD_RULE_FAILURE:
    case ACTION_PUT_USER_FRAUD_RULE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        success: false,
      };
    }

    default: {
      return state;
    }
  }
}

import _ from 'lodash-es';

export function hasPermission(resource: string, action: string) {
  let permission = false;
  let loginData: any = localStorage.getItem('login_data');

  if (loginData !== null) {
    let loginDataperse = JSON.parse(loginData);
    let userPermissions = _.get(loginDataperse, 'userPermissions', null);
    permission = _.result(
      _.find(userPermissions, (p) => p.resource == resource),
      action,
    );
  }

  return permission;
}

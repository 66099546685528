import {
  ACTION_PUT_FEATURE,
  ACTION_PUT_FEATURES_SUCCESS,
  ACTION_PUT_FEATURES_FAILURE,
  API_CALL,
} from '../../configs/ActionTypes';
import { Dispatch } from 'redux';
import { enqueueSnackbarHandler } from '../Status';

export type datatype = {
  agencyId?: string;
  featureName?: string;
  enabled?: boolean;
  confidence?: string;
};

export function putFeatures(data: datatype) {
  return (dispatch: Dispatch, getState: Function) => {
    dispatch(requestStarted());
    //Dispatch the api call request.
    dispatch({
      type: API_CALL,
      payload: {
        call: 'agency/feature',
        method: 'PUT',
        data: JSON.stringify(data),
        success: (response: any) => {
          if (response.type === 'error') {
            dispatch(requestFailed(response));
          } else {
            enqueueSnackbarHandler(
              dispatch,
              { updateFeatureSuccess: true },
              'success',
            );
            dispatch(requestSuccess(response));
          }
        },
        fail: (error: any) => {
          dispatch(requestFailed(error));
        },
      },
    });
    return null;
  };
}

export function requestStarted() {
  return {
    type: ACTION_PUT_FEATURE,
    payload: null,
  };
}

export function requestSuccess(responseJSON: object) {
  return {
    type: ACTION_PUT_FEATURES_SUCCESS,
    payload: responseJSON,
  };
}

export function requestFailed(responseJSON: object) {
  return {
    type: ACTION_PUT_FEATURES_FAILURE,
    payload: responseJSON,
  };
}

import React from 'react';

type Props = {
  width: number;
  height: number;
  color: string;
};

const SvgIconStarColorProp = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 13 13"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Master"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Travel---Hotel-Search-Results"
          transform="translate(-622.000000, -551.000000)"
          fill={props.color}
        >
          <g
            id="results"
            transform="translate(280.000000, 378.000000)"
          >
            <g id="Group">
              <polygon
                id="Star"
                points="348.5 183.779335 344.482779 186 345.25 181.296556 342 177.965558 346.49139 177.279335 348.5 173 350.50861 177.279335 355 177.965558 351.75 181.296556 352.517221 186"
              ></polygon>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

SvgIconStarColorProp.defaultProps = {
  width: 13,
  height: 13,
  color: '#e4e7e7',
};

export default SvgIconStarColorProp;

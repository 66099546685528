/**
 * Status Action
 */

import {
  ACTION_STATUS_SHOW,
  ACTION_STATUS_CLEAR,
} from '../configs/ActionTypes';

const errorMessages = {
  unknownOrganization: 'Organization not found',
  organizationsNotFound: 'Organizations not found',
  userDetailsNotFound: 'User details not found',
  usersNotFound: 'Users not found',
  travelersNotFound: 'Travelers not found',
  noTravelerDetails: 'The Traveler has no details',
  noTravelerCreditCards: 'The traveler does not have credit cards',
  userCredentialsError: 'Email or password is invalid.',
  userMissingEmail: 'Missing email address',
  userMissingPassword: 'Missing password',
  userWrongPassword: 'Wrong password',
  organizationsParseRequest:
    'Could not process request, one or more fields are invalid.',
  organizationsValidateRequest:
    'Could not validate request, one or more fields are invalid.',
  organizationDomainInUser: 'Domain in use, please use another domain.',
  selectedDateIsTooLong28:
    'We cannot accept a reservation for longer than 28 days',
  firstNameError: 'Empty first name',
  lastNameError: 'Empty last name',
  passExpiryError: 'Expired passport',
  emailError: 'Invalid email address',
  userPermissionError: 'You do not have permission to perform this action.',
  roomError: 'Unable to retrieve rooms. Please try again.',
  updateFeatureError: 'Feature update failed.',
  updateCorporateRateCodeFailed: 'Rate Codes update failed.',
  updateUserStatusError: 'User status update failed.',
  bookingConfirmationSendFailed: 'Unable to send email.',
  updateNegotiatedhotelsFailed: 'Negotiated Hotels update failed.',
  deleteNegotiatedhotelsFailed: 'Negotiated Hotels delete failed.',
  getNegotiatedhotelsFailed: 'Failed to fetch Negotiated Hotels.',
  updateBookingCustomTagsFailed: 'Custom categories update failed.',
  updateEmployeeFailed: 'Employee update failed',
  cancelInfoFailed: 'Failed to get cancel information.',
  bookingCancelInfoError: 'Failed to get cancel information.',
  cancelFailed: 'Failed to cancel booking.',
  bookingCancelError: 'Failed to cancel booking.',
  bookingNewPaymentPostFailed: 'Failed to add new payment.',
  bookingRefundFailed: 'Failed to refund booking with this amount',
  addEmployeeFailed: 'Failed to add/invite new employee.',
  unknownAgency: 'Agency not found',
  updateAgencySettingFailed: 'Inventory Supplier update failed.',
  updateExpensePolicy: 'Expense policy update failed.',
  addBankAccountSettingFailed: 'Failed to add Bank Account.',
  userBankAccountFailed: 'Failed to get user bank account.',
  addBBuserFailed: 'Failed to add BlackBox user.',
  updateBBuserFailed: 'Failed to update BlackBox user.',
  invalidOrgIds: 'Failed to update Analytics Access.',
  updateFlighTourCodeFailed: 'Tour Codes update failed.',
  deleteFeatureFailed: 'Failed to delete feature.',
  failedToDeleteFeature: 'Failed to delete feature.',
  postAgencyFailed: 'Failed to add agency.',
  userExpenseReportsError: 'Failed to get user expense reports.',
  syncExpenseReportFailed: 'Failed to update expense report',
  addDepartmentFailed: 'Failed to add department.',
  getDepartmentFailed: 'Failed to get department.',
  updateBookingDetailsFailure: 'Booking details failed to update',
  duffelSyncFailed: 'Duffel sync failed',
  amtrakBookingFailed: 'Amtrak booking failed',
  paidPlanUpdateFailed:
    'An error occurred while updating the current subscription. Please try again.',
};

const successMessages = {
  addBankAccountSetting: 'Bank Account has been added.',
  confirmImportSuccess: 'Import employees was successful.',
  putProfileSuccess: 'Your profile has been updated.',
  resetPasswordSuccess: 'Your password has been changed.',
  updateTravelerSuccess: 'Traveler data has been updated.',
  deleteTravelerSuccess: 'Traveler profile has been deleted.',
  addTravelerSuccess: 'Traveler has been added.',
  addCreditCardSuccess: 'Credit card has been added.',
  editCreditCardSuccess: 'Credit card has been edited.',
  deleteCreditCardSuccess: 'Credit card has been deleted.',
  updateFeatureSuccess: 'Feature data has been updated.',
  addFeatureSuccess: 'Feature data has been added.',
  updateCompanySuccess: 'Company has been updated.',
  updateCorporateRateCode: 'Rate Codes has been updated.',
  updateUserStatusSuccess: 'User status has been updated.',
  updateUserSuccess: 'User has been updated.',
  updateBookingCustomTags: 'Custom categories has been updated',
  updateUserSettingSuccess: 'User setting has been updated.',
  bookingConfirmationSent: 'Booking confirmation email has been sent.',
  updateNegotiatedhotels: 'Negotiated Hotels has been updated.',
  bookingNewPaymentPostSuccess: 'New payment has been added.',
  bookingRefundSuccess: 'Booking successfully been refunded.',
  updateExlusiveHotelSuccess: 'Exclusive Hotel has been added.',
  cancelBooking: 'Booking has been canceled',
  addEmployeeSuccess: 'Employee has been added',
  inviteEmployeeSuccess: 'Employee has been invited',
  updateEmployeeSuccess: 'Employee has been updated',
  updateAgencySetting: 'Inventory Supplier has been updated.',
  updateAgencySuccess: 'Agency has been updated.',
  addAgencySuccess: 'Agency has been added.',
  updateExpensePolicy: 'Expense policy has been updated.',
  addBBuserSuccess: 'BlackBox user has been added',
  updateBBuserSuccess: 'BlackBox user has been updated',
  updateUserOrgAnalytics: 'Analytics Access has been updated.',
  updateFlightTourCode: 'Tour Codes has been updated.',
  deleteFeatureSuccess: 'Feature has been deleted.',
  syncExpenseReportSuccess: 'Expense report has been updated',
  addDepartmentSuccess: 'Department has been added.',
  updateBookingDetailsSuccess: 'Booking details have been updated',
  updateLicensePricingSuccess: 'License pricing has been successfully updated.',
  updateLicensePricingFailure:
    'An error occurred while editing license pricing. Please try again.',
  duffelSyncSucceded: 'Duffel sync succeeded',
  amtrakBookingSuccess: 'Amtrak booking succeeded',
  postFlightCreditSuccess: 'Flight credit successfully created',
  paidPlanUpdateSuccess:
    'The current subscription has been successfully updated.',
};

const defaultMessage = 'An unknown error occurred. Please try again.';

export function enqueueSnackbar(notification, type) {
  let message;

  if (type == 'success') {
    message = successMessages[notification];
  } else if (type == 'error') {
    if (notification in errorMessages) {
      message = errorMessages[notification];
    } else {
      message = defaultMessage;
    }
  } else {
    message = defaultMessage;
    type = 'error';
  }

  return {
    type: 'ACTION_STATUS_SHOW',
    payload: {
      id: new Date().getTime() + Math.random(),
      message: message,
      options: {
        variant: type,
      },
    },
  };
}

export const removeSnackbar = (id) => ({
  type: 'ACTION_STATUS_CLEAR',
  payload: id,
});

export function enqueueSnackbarHandler(dispatch, data, type) {
  Object.keys(data).forEach(function (item) {
    if (data[item] == true) {
      dispatch(enqueueSnackbar(item, type));
    }
  });
}

import React, { Component } from 'react';
import { SnackbarProvider } from 'notistack';
import SvgIconSuccess from '../../static/icons/svgIconSuccess';
import SvgIconError from '../../static/icons/svgIconError';

const SnackBarWrapper = (props: any) => (
  <SnackbarProvider
    autoHideDuration={2000}
    classes={{
      variantSuccess: 'success-message',
      variantError: 'error-message',
    }}
    iconVariant={
      {
        error: (
          <SvgIconError
            width={24}
            height={24}
          />
        ),
        success: (
          <SvgIconSuccess
            width={24}
            height={24}
          />
        ),
      } as any
    }
    maxSnack={5}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
  >
    {props.children}
  </SnackbarProvider>
);

export default SnackBarWrapper;

import * as React from 'react';
import cx from 'classnames';
import TextFieldWithClear from 'components/TextFieldWithIcon/TextFieldWithIcon';
import Button from '@material-ui/core/Button';

const styles = require('./styles.pcss');

class AgenciesRecordForm extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      name: '',
      appHost: '',
    };
  }

  componentDidMount = () => {
    this.onSubmitForm('initial');
  };

  onChangeName = (event: any) => {
    this.setState({
      name: event.target.value,
    });
  };

  onChangeApp = (event: any) => {
    this.setState({
      appHost: event.target.value,
    });
  };

  clearName = () => {
    this.setState({
      name: '',
    });
  };

  clearApp = () => {
    this.setState({
      appHost: '',
    });
  };

  onSubmitForm = (initial?: any) => {
    let req: any = {
      name: this.state.name,
      appHost: this.state.appHost,
      order: 'friendlyName ASC',
    };

    if (initial == 'initial') {
      const urlParams = new URLSearchParams(window.location.search);

      if (urlParams.get('name')) {
        req.name = urlParams.get('name');
      }

      if (urlParams.get('appHost')) {
        req.appHost = urlParams.get('appHost');
      }

      this.setState({
        name: req.name,
        appHost: req.appHost,
      });
    } else {
      let url = 'lookup?type=agency';

      if (this.state.name) {
        url += `&name=${encodeURIComponent(this.state.name)}`;
      }

      if (this.state.appHost) {
        url += `&appHost=${encodeURIComponent(this.state.appHost)}`;
      }

      window.history.pushState({}, '', url);
    }

    this.props.onSubmit(req);
  };

  _getSearchButtonText = () => {
    return 'Search';
  };

  onKeyPressed = (e: any) => {
    if (e.key === 'Enter') {
      this.onSubmitForm();
    }
  };

  render() {
    return (
      <div
        className={styles.form}
        onKeyDown={(e) => this.onKeyPressed(e)}
      >
        <div className={styles.inputWrapper}>
          <TextFieldWithClear
            onChange={this.onChangeName}
            label="Name"
            variant="outlined"
            margin="normal"
            value={this.state.name}
            onClear={this.clearName}
            fullWidth={true}
          />
        </div>

        <div className={styles.inputWrapper}>
          <TextFieldWithClear
            onChange={this.onChangeApp}
            label="App"
            variant="outlined"
            margin="normal"
            value={this.state.appHost}
            onClear={this.clearApp}
            fullWidth={true}
          />
        </div>

        <div className={styles.inputWrapper}>
          <Button
            fullWidth
            className={styles.button}
            variant="contained"
            color="primary"
            onClick={this.onSubmitForm}
          >
            {this._getSearchButtonText()}
          </Button>
        </div>
      </div>
    );
  }
}

export default AgenciesRecordForm;

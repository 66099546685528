exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".components-Layout-styles___layout--3dZ_t {\n  display: flex;\n}\n\n.components-Layout-styles___content--3vQoI {\n  flex: 1 0 0;\n  padding: 40px 16px;\n  max-width: 1200px;\n  margin: auto;\n}\n\n.components-Layout-styles___content--3vQoI.components-Layout-styles___full--2pIqQ {\n    padding: 0;\n    max-width: 100%;\n  }\n\n@media only screen and (min-width: 1024px) {\n\n.components-Layout-styles___content--3vQoI {\n    padding: 40px 32px\n}\n  }\n\n@media only screen and (min-width: 1440px) {\n\n.components-Layout-styles___content--3vQoI {\n    padding: 40px\n}\n  }\n", "", {"version":3,"sources":["/tmp/build_c950a000/src/components/Layout/styles.pcss"],"names":[],"mappings":"AAAA;EACE,cAAc;CACf;;AAED;EACE,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;EAClB,aAAa;CACd;;AAED;IACI,WAAW;IACX,gBAAgB;GACjB;;AAEH;;AAEA;IACI,kBAAkB;CACrB;GACE;;AAEH;;AAEA;IACI,aAAa;CAChB;GACE","file":"styles.pcss","sourcesContent":[".layout {\n  display: flex;\n}\n\n.content {\n  flex: 1 0 0;\n  padding: 40px 16px;\n  max-width: 1200px;\n  margin: auto;\n}\n\n.content.full {\n    padding: 0;\n    max-width: 100%;\n  }\n\n@media only screen and (min-width: 1024px) {\n\n.content {\n    padding: 40px 32px\n}\n  }\n\n@media only screen and (min-width: 1440px) {\n\n.content {\n    padding: 40px\n}\n  }\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"layout": "components-Layout-styles___layout--3dZ_t",
	"content": "components-Layout-styles___content--3vQoI",
	"full": "components-Layout-styles___full--2pIqQ"
};
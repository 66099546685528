import {
  ACTION_GET_BB_AGENCY_PERMISSIONS,
  ACTION_GET_BB_AGENCY_PERMISSIONS_SUCCESS,
  ACTION_GET_BB_AGENCY_PERMISSIONS_FAILURE,
  API_CALL,
} from '../../../configs/ActionTypes';

import _ from 'lodash-es';

import { Dispatch } from 'redux';

import { enqueueSnackbarHandler } from '../../Status';

export function getAgencyPermissions(agencyId: string) {
  return (dispatch: Dispatch, getState: Function) => {
    dispatch(requestStarted());

    //Dispatch the api call request.
    dispatch({
      type: API_CALL,
      payload: {
        call: 'agency-permissions/' + agencyId,
        method: 'get',
        success: (response: any) => {
          if (response.type === 'error') {
            enqueueSnackbarHandler(dispatch, response.payload.payload, 'error');
            dispatch(requestFailed(response));
          } else {
            dispatch(requestSuccess(response));
          }
        },
        fail: (error: any) => {
          if (error.payload) {
            enqueueSnackbarHandler(dispatch, error.payload, 'error');
          }
          dispatch(requestFailed(error));
        },
      },
    });

    return null;
  };
}

export function requestStarted() {
  return {
    type: ACTION_GET_BB_AGENCY_PERMISSIONS,
    payload: null,
  };
}

export function requestSuccess(responseJSON: object) {
  return {
    type: ACTION_GET_BB_AGENCY_PERMISSIONS_SUCCESS,
    payload: responseJSON,
  };
}

export function requestFailed(responseJSON: object) {
  return {
    type: ACTION_GET_BB_AGENCY_PERMISSIONS_FAILURE,
    payload: responseJSON,
  };
}

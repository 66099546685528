import * as React from 'react';
import _ from 'lodash-es';
import cx from 'classnames';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { KeyboardArrowDown } from '@material-ui/icons';
const styles = require('./styles.pcss');
import InputAdornment from '@material-ui/core/InputAdornment';

type Props = {
  margin?: any;
  variant?: any;
  className?: any;
  disabled?: any;
  htmlFor?: any;
  label?: any;
  id?: any;
  value?: any;
  onChange?: any;
  fullWidth?: any;
  labelWidth?: any;
  inputName?: any;
  MenuProps?: any;
  menuItems: Array<any>;
  error?: any;
  inputError?: any;
  renderError?: any;
  endText?: any;
};

type State = {
  open: any;
};

class CustomSelect extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  onOpen = (e: any) => {
    this.setState({
      open: true,
    });
  };

  onClose = (e: any) => {
    this.setState({
      open: false,
    });
  };

  render() {
    let labelWidth = this.props.value !== '' ? this.props.labelWidth || 50 : 0;
    let shrink = this.props.value !== '' ? true : false;
    return (
      <FormControl
        error={this.props.error}
        margin={this.props.margin}
        variant={this.props.variant}
        className={this.props.className}
        disabled={this.props.disabled}
      >
        <InputLabel
          htmlFor={this.props.htmlFor}
          shrink={shrink}
        >
          {this.props.label}
        </InputLabel>
        <Select
          className={this.state.open ? styles.selected : styles.select}
          id={this.props.id}
          value={this.props.value}
          onChange={this.props.onChange}
          input={
            <OutlinedInput
              fullWidth={this.props.fullWidth}
              error={this.props.inputError}
              labelWidth={labelWidth}
              name={this.props.inputName}
            />
          }
          MenuProps={this.props.MenuProps}
          onOpen={this.onOpen}
          onClose={this.onClose}
          open={this.state.open}
          endAdornment={
            this.props.endText && (
              <InputAdornment
                className={styles.endText}
                position="end"
              >
                {this.props.endText}
              </InputAdornment>
            )
          }
          IconComponent={() => <KeyboardArrowDown />}
        >
          {this.props.menuItems.map((menuItem) => {
            return (
              <MenuItem
                key={_.get(menuItem, 'key', '')}
                value={_.get(menuItem, 'value', '')}
              >
                {_.get(menuItem, 'label', '')}
              </MenuItem>
            );
          })}
        </Select>
        {this.props.renderError && this.props.renderError}
      </FormControl>
    );
  }
}

export default CustomSelect;

import * as React from 'react';
import cx from 'classnames';
import _ from 'lodash-es';
import Paper from '@material-ui/core/Paper';
import SvgDefaultCar from '../../../static/icons/svgDefaultCar';
import { convertToNumber } from '../../../helpers/ConvertToNumber';
import { getProviderDisplayName } from '../../../helpers/getProviderDisplayName';
const styles = require('./styles.pcss');

type Props = {
  details: any;
};

class CarDetails extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);
  }

  renderImage = () => {
    if (_.get(this.props.details, 'car.thumbnailUrl', '')) {
      return <img src={_.get(this.props.details, 'car.thumbnailUrl', '')} />;
    }

    return (
      <SvgDefaultCar
        width={238}
        height={116}
      />
    );
  };

  render() {
    const provider = _.get(
      this.props.details,
      '_meta.providerName',
      'See dev team',
    );
    const providerCode = _.get(
      this.props.details,
      'providerCode',
      'TP providerCode unknown',
    );
    const day = convertToNumber(
      _.get(this.props.details, 'car.priceDetails.days', 0),
    );
    const pricePerDay = convertToNumber(
      _.get(this.props.details, 'car.priceDetails.pricePerDay', 0),
    );
    const charges = convertToNumber(
      _.get(this.props.details, 'requiredCharges', 0),
    );
    const total = convertToNumber(
      _.get(this.props.details, 'car.priceDetails.estimatedTotalPrice', 0),
    );

    return (
      <Paper className={styles.carDetails}>
        <div className={styles.numbers}>
          <div className={styles.carImage}>{this.renderImage()}</div>
          <div className={styles.row}>
            <div className={styles.subtitle}>Provider</div>
            <div className={cx(styles.title, styles.highlight)}>
              {getProviderDisplayName(provider, providerCode)}
            </div>
          </div>
          <div className={cx(styles.row, styles.withPrice)}>
            <div className={styles.title}>
              Est. Rental ({day} days x ${pricePerDay}/day)
            </div>
            <div className={styles.price}>
              {pricePerDay * day
                ? `+$` + (pricePerDay * day).toFixed(2)
                : ' - - '}
            </div>
          </div>

          <div className={cx(styles.row, styles.withPrice)}>
            <div className={styles.title}>Est. Taxes & Fees</div>
            <div className={styles.price}>
              {charges ? `+$` + charges.toFixed(2) : ' - - '}
            </div>
          </div>

          <div
            className={cx(
              styles.row,
              styles.withPrice,
              styles.highlight,
              styles.noBorder,
            )}
          >
            <div className={styles.totalPrice}>Estimated Total</div>
            <div className={cx(styles.price, styles.total)}>
              ${total ? total.toFixed(2) : ' - - '}
            </div>
          </div>
        </div>
      </Paper>
    );
  }
}

export default CarDetails;

import * as React from 'react';
import * as _ from 'lodash-es';
import moment from 'moment';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import RenderIcon from 'components/CreditCard/RenderIcon';
import SvgIconBrex from '../../../static/icons/svgIconBrex';
import SvgIconRewards from '../../../static/icons/svgIconRewards';
import { cardType } from '../../../helpers/creditCardType';

const styles = require('./styles.pcss');

type Props = {
  transaction: any;
  key: any;
  onClickRefund: any;
  showRefund: any;
};

const TransactionTableRow = ({
  transaction,
  key,
  onClickRefund,
  showRefund,
}: Props) => {
  const fop = () => {
    if (transaction.purpose == 'savings') {
      return 'Travelbank Rewards';
    }

    if (transaction.type === 'brexPoints') {
      return 'Brex Points';
    }

    if (_.get(transaction, 'data.payment_method_details.card', false)) {
      return `${_.capitalize(
        _.get(transaction, 'data.payment_method_details.card.brand', ''),
      )}... ${_.get(
        transaction,
        'data.payment_method_details.card.last4',
        '',
      )}`;
    }

    if (_.get(transaction, 'creditCard', false)) {
      return `${_.capitalize(
        _.get(cardType, _.get(transaction, 'creditCard.type', '')),
      )}... ${_.get(transaction, 'creditCard.last4', '')}`;
    }

    return '';
  };

  const amount = () => {
    if (_.toLower(transaction.amountType) === 'usd') {
      if (transaction.purpose == 'refund') {
        return `${
          _.get(transaction, 'amount', '')
            ? `-$` + (Math.abs(transaction.amount) / 100).toFixed(2)
            : ' - - '
        }`;
      }

      return `${
        _.get(transaction, 'amount', '')
          ? `$` + (transaction.amount / 100).toFixed(2)
          : ' - - '
      }`;
    }

    if (
      _.toLower(transaction.amountType) === 'points' ||
      _.toLower(transaction.amountType) === 'brexpoints'
    ) {
      let amount = _.get(transaction, 'amount', 0) / 100;
      return (
        amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' points'
      );
    }

    return '';
  };

  const amountIcon = () => {
    if (_.toLower(transaction.amountType) === 'points') {
      return (
        <SvgIconRewards
          width={18}
          height={18}
        />
      );
    }

    if (_.toLower(transaction.amountType) === 'brexpoints') {
      return (
        <SvgIconBrex
          width={18}
          height={18}
        />
      );
    }

    return (
      <RenderIcon
        type={
          _.get(transaction, 'data.source.brand', false) ||
          _.get(cardType, _.get(transaction, 'creditCard.type', ''))
        }
        width={18}
        height={18}
      />
    );
  };

  const status = () => _.get(transaction, 'status', 'completed');

  return (
    <TableRow
      className={styles.tableRow}
      key={key}
    >
      <TableCell className={styles.tableRowCell}>
        {moment(transaction.created).format('MM/DD/YYYY')}
      </TableCell>
      <TableCell
        className={classNames(styles.tableRowCell, styles.description)}
      >
        {_.get(transaction, 'data.description', false) ||
          _.get(transaction, 'description', '')}
      </TableCell>
      <TableCell className={styles.tableRowCell}>
        {_.get(transaction, 'merchant', '')}
      </TableCell>
      <TableCell className={classNames(styles.tableRowCell)}>
        <div className={styles.tableRowCellWithIcon}>
          {amountIcon()}
          {fop()}
        </div>
      </TableCell>
      <TableCell className={styles.tableRowCell}>
        {<div className={styles.amountContainer}>{amount()}</div>}
      </TableCell>
      <TableCell className={styles.tableRowCell}>
        <div className={classNames(styles.status, styles[status()])}>
          {_.capitalize(status())}
        </div>
      </TableCell>
      <TableCell className={styles.tableRowCell}>
        {showRefund && (
          <Button
            className={styles.refundButton}
            onClick={onClickRefund}
          >
            Refund
          </Button>
        )}
      </TableCell>
      <TableCell className={styles.tableRowCell}>
        {transaction.blackboxUserName || 'app'}
      </TableCell>
    </TableRow>
  );
};

export default TransactionTableRow;

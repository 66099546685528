import {
  ACTION_POST_BOOKING_NEW_PAYMENT,
  ACTION_POST_BOOKING_NEW_PAYMENT_SUCCESS,
  ACTION_POST_BOOKING_NEW_PAYMENT_FAILURE,
  ACTION_RESET_POST_BOOKING_NEW_PAYMENT,
} from '../configs/ActionTypes';

import _ from 'lodash-es';

const initialState = {
  isLoading: false,
  success: null,
  amountError: '',
  creditCardError: '',
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_POST_BOOKING_NEW_PAYMENT: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ACTION_POST_BOOKING_NEW_PAYMENT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        success: true,
        amountError: '',
        creditCardError: '',
      };
    }

    case ACTION_POST_BOOKING_NEW_PAYMENT_FAILURE: {
      const payload = action.payload.payload;

      return {
        ...state,
        success: false,
        isLoading: false,
        amountError:
          _.get(payload, 'amountError', false) === true ? 'Required' : '',
        creditCardError:
          _.get(payload, 'creditCardError', false) === true ? 'Required' : '',
      };
    }

    case ACTION_RESET_POST_BOOKING_NEW_PAYMENT: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

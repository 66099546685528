import {
  ACTION_UPDATE_BB_USER,
  ACTION_UPDATE_BB_USER_SUCCESS,
  ACTION_UPDATE_BB_USER_FAILURE,
  ACTION_RESET_BB_USER,
} from '../configs/ActionTypes';

import _ from 'lodash-es';

const initialState = {
  success: null,
  isLoading: false,
  error: null,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_UPDATE_BB_USER: {
      return {
        ...state,
        isLoading: true,
        success: null,
        error: null,
      };
    }

    case ACTION_UPDATE_BB_USER_SUCCESS: {
      return {
        ...state,
        success: true,
        isLoading: false,
        error: null,
      };
    }

    case ACTION_UPDATE_BB_USER_FAILURE: {
      const errorName =
        _.get(action.payload, 'payload.nameEmpty', false) === true
          ? 'Required'
          : '';
      const errorRole =
        _.get(action.payload, 'payload.roleEmpty', false) === true
          ? 'Required'
          : '';
      let errorPassword = '';
      let errorEmail = '';

      if (_.get(action.payload, 'payload.emailEmpty', false) === true) {
        errorEmail = 'Required';
      } else if (
        _.get(action.payload, 'payload.emailInvalid', false) === true
      ) {
        errorEmail = 'Invalid email address';
      } else if (_.get(action.payload, 'payload.emailExists', false) === true) {
        errorEmail = 'This email address is already taken';
      }

      if (_.get(action.payload, 'payload.passwordShort', false) === true) {
        errorPassword = 'Password should contain at least 8 characters';
      } else if (
        _.get(action.payload, 'payload.passwordIsCommon', false) === true
      ) {
        errorPassword = 'Password is common';
      }

      return {
        ...state,
        success: false,
        isLoading: false,
        error: {
          errorName,
          errorRole,
          errorEmail,
          errorPassword,
        },
      };
    }

    case ACTION_RESET_BB_USER: {
      return {
        ...state,
        success: null,
        isLoading: false,
        error: null,
      };
    }

    default: {
      return state;
    }
  }
}

/**
 * Config file for various API settings.
 */
let propApiUrl = 'http://localhost:20000/';

if (process.env.TB_BLACKBOX_API_URL !== undefined) {
  propApiUrl = process.env.TB_BLACKBOX_API_URL;
}

let propOnboardingUrl =
  'https://docs.google.com/spreadsheets/d/1yBfxW0QX8-RzTd7Ae0Q2z6LkxSM8zc7YoR7LZN3adI0/copy';

if (process.env.TB_ONBOARDING_CHECKLIST_URL !== undefined) {
  propOnboardingUrl = process.env.TB_ONBOARDING_CHECKLIST_URL;
}

let blackboxEnv = 'preview';
if (process.env.TB_BLACKBOX_ENV !== undefined) {
  blackboxEnv = process.env.TB_BLACKBOX_ENV;
}

export const TB_BLACKBOX_ENV = blackboxEnv;
export const TB_ONBOARDING_CHECKLIST_URL: string = propOnboardingUrl;
export const API_URL = propApiUrl;
export const MAPBOX_ACCESS_TOKEN = process.env.MAPBOX_ACCESS_TOKEN;
export const TB_AGENCY_ID = process.env.TB_AGENCY_ID;

export const AUTH_HEADER = {
  key: 'Authorization',
  value: 'Bearer 3a844508-6d66-4f39-9fbf-13804448c795',
};

import * as React from 'react';
import classNames from 'classnames';

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const styles = require('./styles.pcss');

type Props = {
  lookup?: any;
};

const TravelerTableHead = (props: Props) => (
  <TableHead>
    <TableRow>
      <TableCell className={classNames(styles.tableHead)}>First Name</TableCell>
      <TableCell className={classNames(styles.tableHead)}>Last Name</TableCell>
      <TableCell className={classNames(styles.tableHead)}>User Name</TableCell>
      <TableCell className={classNames(styles.tableHead)}>
        Date of Birth
      </TableCell>
      <TableCell className={classNames(styles.tableHead)}>Email</TableCell>
      <TableCell className={classNames(styles.tableHead)}>
        Phone Number
      </TableCell>
    </TableRow>
  </TableHead>
);

export default TravelerTableHead;

import * as React from 'react';
import _ from 'lodash-es';
import moment from 'moment';
import cx from 'classnames';
import { navigate } from '@reach/router';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Loader from 'components/Loader/Loader';
import { hasPermission } from '../../helpers/Permission';

import DatePicker from 'components/DatePickers/DatePicker';
import AutoSuggest from 'components/Inputs/AutoSuggest/AutoSuggest';
import TextFieldWithClear from 'components/TextFieldWithIcon/TextFieldWithIcon';
import CustomSelect from 'components/Inputs/Select/Select';

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ReduxState } from 'reducers/types';
import {
  update as updateTraveler,
  softDeleteTraveler,
} from 'actions/Travelers/Update';
import { addTraveler as addTraveler } from 'actions/Travelers/Add';
import { resetSaveSuccess } from 'actions/Travelers/ResetSaveSuccess';
const countries = require('../../helpers/Countries.js');

const styles = require('./styles.pcss');

import { Traveler } from 'reducers/types';

type Props = {
  traveler?: Traveler;
  updateTraveler: any;
  addTraveler: any;
  userId?: string;
  newTraveler?: boolean;
  resetSaveSuccess: any;
  saveSucceeded: any;
  onTravelerUpdate?: any;
  readOnly?: boolean;
  onClose?: any;
  open: boolean;
  onClickAddUserPopupClose?: any;
  travelerIsLoading?: any;
  addNewTraveler?: boolean;
  onTravelerProfileDelete?: any;
  softDeleteTraveler: Function;
};

type State = {
  firstName: any;
  middleName: any;
  lastName: any;
  gender: any;
  birthDate: any;
  countryCode: any;
  phoneNumber: any;
  passExpiry: any;
  passNumber: any;
  passCountry: any;
  knownTravelerNumber: any;
  firstNameError: string;
  lastNameError: string;
  middleNameError: string;
  passExpiryError: string;
  emailError: string;
  email: any;
  openDeleteProfile: boolean;
};

const _getNullSafe = (val: any, props: any, defaultVal: any) => {
  const v = _.get(val, props, defaultVal);
  return _.isNull(v) ? defaultVal : v;
};

class PersonalDetails extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      firstName: '',
      middleName: '',
      lastName: '',
      gender: '',
      birthDate: null,
      phoneNumber: '',
      countryCode: '',
      passExpiry: null,
      passNumber: '',
      passCountry: '',
      knownTravelerNumber: '',
      email: '',
      firstNameError: '',
      lastNameError: '',
      middleNameError: '',
      emailError: '',
      passExpiryError: '',
      openDeleteProfile: false,
    };
  }

  componentWillReceiveProps = (nextProps: Props) => {
    if (nextProps.travelerIsLoading == false) {
      if (nextProps.saveSucceeded === true) {
        this.props.resetSaveSuccess();
        this.props.onClickAddUserPopupClose();

        if (
          typeof this.props.onTravelerProfileDelete === 'function' &&
          this.state.openDeleteProfile
        ) {
          this.props.onTravelerProfileDelete();
          this.setState({
            openDeleteProfile: false,
          });
        }

        this.setState({
          firstName: _getNullSafe(nextProps, 'traveler.firstName', ''),
          middleName: _getNullSafe(nextProps, 'traveler.middleName', ''),
          lastName: _getNullSafe(nextProps, 'traveler.lastName', ''),
          gender: _.get(nextProps, 'traveler.gender', ''),
          birthDate: _.get(nextProps, 'traveler.birthDate', null),
          phoneNumber: _getNullSafe(nextProps, 'traveler.phoneNumber', ''),
          countryCode: _getNullSafe(nextProps, 'traveler.phoneCountryCode', ''),
          passExpiry: _.get(nextProps, 'traveler.passExpiry', null),
          passNumber: _getNullSafe(nextProps, 'traveler.passNumber', ''),
          passCountry: _getNullSafe(nextProps, 'traveler.passCountry', ''),
          knownTravelerNumber: _getNullSafe(
            nextProps,
            'traveler.knownTravelerNumber',
            '',
          ),
          email: _getNullSafe(nextProps, 'traveler.email', ''),
        });

        if (typeof this.props.onTravelerUpdate === 'function') {
          this.props.onTravelerUpdate();
        }
      }

      const firstNameError = _.get(nextProps, 'travelerErrors.firstName', '');
      const lastNameError = _.get(nextProps, 'travelerErrors.lastName', '');
      const emailError = _.get(nextProps, 'travelerErrors.email', '');
      const middleNameError = _.get(nextProps, 'travelerErrors.middleName', '');
      const passExpiryError = _.get(nextProps, 'travelerErrors.passExpiry', '');

      if (
        firstNameError != '' ||
        lastNameError != '' ||
        passExpiryError != '' ||
        emailError != '' ||
        middleNameError != ''
      ) {
        this.setState({
          firstNameError,
          lastNameError,
          passExpiryError,
          emailError,
          middleNameError,
        });

        return;
      }

      if (!nextProps.addNewTraveler) {
        this.setState({
          firstName: _getNullSafe(nextProps, 'traveler.firstName', ''),
          middleName: _getNullSafe(nextProps, 'traveler.middleName', ''),
          lastName: _getNullSafe(nextProps, 'traveler.lastName', ''),
          gender: _.get(nextProps, 'traveler.gender', ''),
          birthDate: _.get(nextProps, 'traveler.birthDate', null),
          phoneNumber: _getNullSafe(nextProps, 'traveler.phoneNumber', ''),
          countryCode: _getNullSafe(nextProps, 'traveler.phoneCountryCode', ''),
          passExpiry: _.get(nextProps, 'traveler.passExpiry', null),
          passNumber: _getNullSafe(nextProps, 'traveler.passNumber', ''),
          passCountry: _getNullSafe(nextProps, 'traveler.passCountry', ''),
          knownTravelerNumber: _getNullSafe(
            nextProps,
            'traveler.knownTravelerNumber',
            '',
          ),
          email: _getNullSafe(nextProps, 'traveler.email', ''),
        });
      }
    }
  };

  onChangeFirstName = (event: any) => {
    this.setState({
      firstName: event.target.value,
      firstNameError: '',
    });
  };

  clearFirstName = () => {
    this.setState({
      firstName: '',
      firstNameError: '',
    });
  };

  clearLastName = () => {
    this.setState({
      lastName: '',
      lastNameError: '',
    });
  };

  clearMiddleName = () => {
    this.setState({
      middleName: '',
      middleNameError: '',
    });
  };

  clearCountryCode = () => {
    this.setState({
      countryCode: '',
    });
  };

  clearPhoneNumber = () => {
    this.setState({
      phoneNumber: '',
    });
  };

  clearTravelerNumber = () => {
    this.setState({
      knownTravelerNumber: '',
    });
  };

  clearEmail = () => {
    this.setState({
      email: '',
      emailError: '',
    });
  };

  clearPassportNumber = () => {
    this.setState({
      passNumber: '',
    });
  };

  onChangeLastName = (event: any) => {
    this.setState({
      lastName: event.target.value,
      lastNameError: '',
    });
  };

  onChangeMiddleName = (event: any) => {
    this.setState({
      middleName: event.target.value,
      middleNameError: '',
    });
  };

  onChangePhoneNumber = (event: any) => {
    this.setState({
      phoneNumber: event.target.value,
    });
  };

  onChangeKnownTravelerNumber = (event: any) => {
    this.setState({
      knownTravelerNumber: event.target.value,
    });
  };

  onChangeEmail = (event: any) => {
    this.setState({
      email: event.target.value,
    });
  };

  onChangePassNumber = (event: any) => {
    this.setState({
      passNumber: event.target.value,
    });
  };

  onChangePassCountryAuto = (passCountry: any) => {
    this.setState({
      passCountry: passCountry,
    });
  };

  onChangeBirthDate = (value: any) => {
    this.setState({
      birthDate: value,
    });
  };

  onChangeGender = (event: any) => {
    this.setState({
      gender: event.target.value,
    });
  };

  onChangeCountryCode = (event: any) => {
    this.setState({
      countryCode: event.target.value,
    });
  };

  onChangepassExpiry = (value: any) => {
    this.setState({
      passExpiry: value,
      passExpiryError: '',
    });
  };

  validFields = () => {
    let isValid = true;
    let firstNameError = '';
    let lastNameError = '';
    let passExpiryError = '';

    if (this.state.firstName.trim() === '') {
      firstNameError = 'Required';
      isValid = false;
    }

    if (this.state.lastName.trim() === '') {
      lastNameError = 'Required';
      isValid = false;
    }

    if (
      this.state.passExpiry !== null &&
      this.state.passExpiry !== _.get(this.props, 'traveler.passExpiry', null)
    ) {
      if (this.state.passExpiry.isBefore()) {
        passExpiryError = 'Expired passport';
        isValid = false;
      }
    }

    this.setState({
      firstNameError,
      lastNameError,
      passExpiryError,
    });

    return isValid;
  };

  handleSubmit = () => {
    let data: any = {
      firstName: this.state.firstName,
      middleName: this.state.middleName,
      lastName: this.state.lastName,
      gender: this.state.gender,
      birthDate: this.state.birthDate,
      knownTravelerNumber: this.state.knownTravelerNumber,
      email: this.state.email,
      phone: {
        countryCode: this.state.countryCode,
        number: this.state.phoneNumber,
      },
      passportInfo: {
        passCountry: this.state.passCountry,
        passNumber: this.state.passNumber,
        passExpiry: this.state.passExpiry,
      },
    };

    if (!this.validFields()) {
      return;
    }

    if (this.props.addNewTraveler === true) {
      data.userId = this.props.userId;
      this.props.addTraveler(data);
    } else if (this.props.traveler) {
      data.travelerId = this.props.traveler.id;
      this.props.updateTraveler(data);
    }

    this.setState({
      openDeleteProfile: false,
    });
  };

  onCancelSave = () => {
    this.setState({
      firstName: _getNullSafe(this.props, 'traveler.firstName', ''),
      middleName: _getNullSafe(this.props, 'traveler.middleName', ''),
      lastName: _getNullSafe(this.props, 'traveler.lastName', ''),
      gender: _.get(this.props, 'traveler.gender', ''),
      birthDate: _.get(this.props, 'traveler.birthDate', null),
      phoneNumber: _getNullSafe(this.props, 'traveler.phoneNumber', ''),
      countryCode: _getNullSafe(this.props, 'traveler.phoneCountryCode', ''),
      passExpiry: _.get(this.props, 'traveler.passExpiry', null),
      passNumber: _getNullSafe(this.props, 'traveler.passNumber', ''),
      passCountry: _getNullSafe(this.props, 'traveler.passCountry', ''),
      knownTravelerNumber: _getNullSafe(
        this.props,
        'traveler.knownTravelerNumber',
        '',
      ),
      email: _getNullSafe(this.props, 'traveler.email', ''),
    });
  };

  renderLoader = () => {
    if (!this.props.travelerIsLoading) {
      return null;
    }

    return (
      <div className={styles.loaderContainer}>
        <Loader visible={true} />
      </div>
    );
  };

  getUserName = () => {
    let name = `${_.get(this.props, 'traveler.firstName', '')} ${_.get(
      this.props,
      'traveler.lastName',
      '',
    )}`;

    if (name.trim() === '') {
      name = _.get(this.props, 'traveler.email', '');
    }

    if (name.trim() === '') {
      return '';
    }

    return name;
  };

  onClickDeleteProfile = () => {
    this.setState({
      openDeleteProfile: true,
    });
  };

  onClickDeleteProfileSubmit = () => {
    if (this.props.traveler) {
      this.props.softDeleteTraveler(this.props.traveler.id);
    }
  };

  onClickDeleteProfileCancel = () => {
    this.setState({
      openDeleteProfile: false,
    });
  };

  renderDeleteProfile = () => {
    if (!this.props.addNewTraveler && hasPermission('travelers', 'delete')) {
      if (!this.state.openDeleteProfile) {
        return (
          <div className={styles.deleteButtonContainer}>
            <Button
              size="small"
              className={styles.buttonDeleteProfile}
              variant="contained"
              onClick={this.onClickDeleteProfile}
              id="deleteProfile"
            >
              Delete Profile
            </Button>
          </div>
        );
      } else {
        return (
          <div className={styles.deleteProfileContainer}>
            <div>
              <div className={styles.deleteTextStyle}>Delete Profile</div>
              <div className={styles.deleteTextDescStyle}>
                Please confirm you want to delete{' '}
                <span className={styles.bolder}>{this.getUserName()}</span>{' '}
                profile.
              </div>
            </div>
            <div className={cx(styles.buttonCont, styles.profileDelete)}>
              <Button
                size="small"
                className={styles.cancelButton}
                variant="outlined"
                color="primary"
                onClick={this.onClickDeleteProfileCancel}
              >
                Cancel
              </Button>

              <Button
                className={styles.deleteButtonStyle}
                variant="contained"
                color="secondary"
                size="small"
                onClick={this.onClickDeleteProfileSubmit}
                id="deleteProfileConf"
              >
                Delete
              </Button>
            </div>
          </div>
        );
      }
    }
    return null;
  };
  onClickAddUserPopupClose = () => {
    this.setState({
      openDeleteProfile: false,
    });
    this.props.onClickAddUserPopupClose();
  };

  renderDescription = () => {
    if (this.props.addNewTraveler) {
      return 'Please ensure traveler information is correct.';
    }
    return <>You are now editing {this.getUserName()} profile</>;
  };

  render() {
    const title = this.props.addNewTraveler
      ? 'Add Traveler Information'
      : 'Edit Traveler Information';

    return (
      <Dialog
        maxWidth={'md'}
        fullWidth={true}
        className={cx(styles.dialogBox, styles.ccPopup)}
        open={this.props.open}
        onClose={this.onClickAddUserPopupClose}
      >
        <div className={styles.title}>
          <div className={styles.titleStyle}>{title}</div>
          <IconButton
            color="inherit"
            onClick={this.onClickAddUserPopupClose}
            className={styles.closeIconCreditCard}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        </div>
        <p className={styles.subtitleStyle}>{this.renderDescription()}</p>

        <div className={cx(styles.updatePopup, styles.editTraveler)}>
          <div
            className={cx(
              styles.inputContainer,
              styles.inputContainerCreditCard,
            )}
          >
            <div className={styles.inputWrapper}>
              <TextFieldWithClear
                disabled={this.props.readOnly}
                error={Boolean(this.state.firstNameError)}
                helperText={this.state.firstNameError}
                onChange={this.onChangeFirstName}
                label="First Name"
                variant="outlined"
                margin="normal"
                value={this.state.firstName}
                onClear={this.clearFirstName}
                fullWidth={true}
                id="firstNamePopup"
              />
            </div>

            <div className={styles.inputWrapper}>
              <TextFieldWithClear
                disabled={this.props.readOnly}
                error={Boolean(this.state.middleNameError)}
                helperText={this.state.middleNameError}
                onChange={this.onChangeMiddleName}
                label="Middle Name"
                variant="outlined"
                margin="normal"
                value={this.state.middleName}
                onClear={this.clearMiddleName}
                fullWidth={true}
                id="middleNamePopup"
              />
            </div>

            <div className={styles.inputWrapper}>
              <TextFieldWithClear
                disabled={this.props.readOnly}
                error={Boolean(this.state.lastNameError)}
                helperText={this.state.lastNameError}
                onChange={this.onChangeLastName}
                label="Last Name"
                variant="outlined"
                margin="normal"
                value={this.state.lastName}
                onClear={this.clearLastName}
                fullWidth={true}
                id="lastNamePopup"
              />
            </div>

            <div className={styles.inputWrapper}>
              <CustomSelect
                margin={'normal'}
                variant={'outlined'}
                className={styles.selectWrapper}
                disabled={this.props.readOnly}
                htmlFor={'input-gender'}
                label={'Gender'}
                id={'input-gender'}
                value={this.state.gender}
                onChange={this.onChangeGender}
                fullWidth={true}
                labelWidth={50}
                inputName={'gender'}
                MenuProps={{
                  style: { marginTop: 4, boxShadow: 'none' },
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
                menuItems={[
                  {
                    key: 'M',
                    value: 'M',
                    label: 'Male',
                  },
                  {
                    key: 'F',
                    value: 'F',
                    label: 'Female',
                  },
                ]}
              />
            </div>

            <DatePicker
              disabled={this.props.readOnly}
              className={styles.inputWrapper}
              id="birth_date"
              date={this.state.birthDate}
              label="Date of Birth"
              onChange={this.onChangeBirthDate}
            />

            <div className={cx(styles.inputWrapper, styles.phoneFields)}>
              <TextFieldWithClear
                disabled={this.props.readOnly}
                className={styles.countryCode}
                onChange={this.onChangeCountryCode}
                label="Country Code"
                variant="outlined"
                margin="normal"
                value={this.state.countryCode}
                onClear={this.clearCountryCode}
                id="countryCodePopup"
              />

              <TextFieldWithClear
                disabled={this.props.readOnly}
                onChange={this.onChangePhoneNumber}
                fullWidth={true}
                label="Phone Number"
                variant="outlined"
                margin="normal"
                value={this.state.phoneNumber}
                onClear={this.clearPhoneNumber}
                id="phoneNumberPopup"
              />
            </div>

            <div className={styles.inputWrapper}>
              <TextFieldWithClear
                disabled={this.props.readOnly}
                onChange={this.onChangeKnownTravelerNumber}
                fullWidth={true}
                label="Known Traveler Number"
                variant="outlined"
                margin="normal"
                value={this.state.knownTravelerNumber}
                onClear={this.clearTravelerNumber}
                id="knownTravelerNumberPopup"
              />
            </div>

            <div className={styles.inputWrapper}>
              <TextFieldWithClear
                disabled={this.props.readOnly}
                error={Boolean(this.state.emailError)}
                helperText={this.state.emailError}
                onChange={this.onChangeEmail}
                fullWidth={true}
                label="Email"
                variant="outlined"
                margin="normal"
                value={this.state.email}
                onClear={this.clearEmail}
                id="emailPopup"
              />
            </div>
          </div>

          <h2>Passport Information</h2>

          <div className={cx(styles.inputContainer, styles.passportInfo)}>
            <div className={styles.inputWrapper}>
              <TextFieldWithClear
                disabled={this.props.readOnly}
                onChange={this.onChangePassNumber}
                fullWidth
                label="Passport Number"
                variant="outlined"
                margin="normal"
                value={this.state.passNumber}
                onClear={this.clearPassportNumber}
                id="passportNumberPopup"
              />
            </div>

            <div className={styles.inputWrapper}>
              <AutoSuggest
                className={styles.dropdownContainer}
                onChange={(value: any) => {
                  this.onChangePassCountryAuto(value);
                }}
                label="Country"
                value={this.state.passCountry}
                disabled={this.props.readOnly}
                dataSet={countries.countries}
                id="countryPopup"
              />
            </div>

            <div className={styles.inputWrapper}>
              <DatePicker
                maxYear={'2050'}
                error={this.state.passExpiryError}
                className={styles.inputWrapper}
                id="pass_expiration"
                date={this.state.passExpiry}
                label="Passport Expiration"
                onChange={this.onChangepassExpiry}
                disabled={this.props.readOnly}
              />
            </div>
            {this.renderLoader()}
          </div>

          {this.renderDeleteProfile()}

          <div className={styles.popupButtonsContainer}>
            <Button
              size="small"
              className={styles.cancelButton}
              color="primary"
              onClick={this.onClickAddUserPopupClose}
            >
              Cancel
            </Button>

            <Button
              size="small"
              className={styles.button}
              variant="contained"
              color="primary"
              onClick={this.handleSubmit}
              id="submitTravelerPopup"
            >
              {this.props.addNewTraveler ? 'Add' : 'Update'}
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {
    travelerErrors: state.traveler.errors,
    saveSucceeded: state.traveler.saveSucceeded,
    travelerIsLoading: state.traveler.isLoading,
  };
}

// Wire up redux dispatch functions
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      updateTraveler,
      addTraveler,
      resetSaveSuccess,
      softDeleteTraveler,
    },
    dispatch,
  );
}

// Connect it to Redux
export default connect(mapStateToProps, mapDispatchToProps)(PersonalDetails);

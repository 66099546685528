import {
  ACTION_GET_DEFAULT_EXPENSE_POLICY,
  ACTION_GET_DEFAULT_EXPENSE_POLICY_SUCCESS,
  ACTION_GET_DEFAULT_EXPENSE_POLICY_FAILURE,
  ACTION_PUT_EXPENSE_POLICY,
  ACTION_PUT_EXPENSE_POLICY_SUCCESS,
  ACTION_PUT_EXPENSE_POLICY_FAILURE,
} from '../configs/ActionTypes';

const initialState = {
  expensePolicy: {},
  isLoading: false,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_GET_DEFAULT_EXPENSE_POLICY:
    case ACTION_PUT_EXPENSE_POLICY: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ACTION_GET_DEFAULT_EXPENSE_POLICY_SUCCESS:
    case ACTION_PUT_EXPENSE_POLICY_SUCCESS: {
      const expensePolicy = action.payload.payload;

      return {
        ...state,
        expensePolicy,
        isLoading: false,
      };
    }
    case ACTION_GET_DEFAULT_EXPENSE_POLICY_FAILURE:
    case ACTION_PUT_EXPENSE_POLICY_FAILURE: {
      return {
        ...state,
        expensePolicy: {},
        isLoading: false,
      };
    }
    default: {
      return state;
    }
  }
}

import {
  ACTION_PUT_PAID_PLAN,
  ACTION_PUT_PAID_PLAN_SUCCESS,
  ACTION_PUT_PAID_PLAN_FAILURE,
  API_CALL,
} from '../../../configs/ActionTypes';
import { Dispatch } from 'redux';
import { enqueueSnackbarHandler } from '../../Status';
import _ from 'lodash-es';

export type datatype = {
  organizationId: string;
  value: any;
};

export function putPaidPlanSetting(data: datatype) {
  return (dispatch: Dispatch, getState: Function) => {
    dispatch(requestStarted());
    //Dispatch the api call request.
    dispatch({
      type: API_CALL,
      payload: {
        call: `organizations/${data.organizationId}/paidplan`,
        method: 'PUT',
        data: JSON.stringify(data),
        success: (response: any) => {
          if (response.type === 'error') {
            dispatch(requestFailed(response));
            _.get(response.payload, 'payload.userPermissionError', false)
              ? enqueueSnackbarHandler(
                  dispatch,
                  response.payload.payload,
                  'error',
                )
              : enqueueSnackbarHandler(
                  dispatch,
                  { paidPlanUpdateFailed: true },
                  'error',
                );
          } else {
            enqueueSnackbarHandler(
              dispatch,
              { paidPlanUpdateSuccess: true },
              'success',
            );
            dispatch(requestSuccess(response));
          }
        },
        fail: (error: any) => {
          dispatch(requestFailed(error));
          _.get(error.payload, 'userPermissionError', false)
            ? enqueueSnackbarHandler(dispatch, error.payload, 'error')
            : enqueueSnackbarHandler(
                dispatch,
                { paidPlanUpdateFailed: true },
                'error',
              );
        },
      },
    });
    return null;
  };
}

export function requestStarted() {
  return {
    type: ACTION_PUT_PAID_PLAN,
    payload: null,
  };
}

export function requestSuccess(responseJSON: object) {
  return {
    type: ACTION_PUT_PAID_PLAN_SUCCESS,
    payload: responseJSON,
  };
}

export function requestFailed(responseJSON: object) {
  return {
    type: ACTION_PUT_PAID_PLAN_FAILURE,
    payload: responseJSON,
  };
}

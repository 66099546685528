import * as React from 'react';
import _ from 'lodash-es';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Loader from 'components/Loader/Loader';
const styles = require('./styles.pcss');
import { Agency, ReduxState } from '../../reducers/types';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { putSingle } from 'actions/Agencies/PutSingle';
import AgencyInfoPopup from './AgencyInfo/AgencyInfoPopup';
import SvgIconCompany from '../../static/icons/svgIconCompany';
import cx from 'classnames';
import { countries } from '../../helpers/Countries.js';

type Props = {
  agency: Agency;
  disabled: boolean;
  putSingle: Function;
  isLoading: any;
};

type State = {
  openPopup: boolean;
};

class AgencyInfo extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      openPopup: false,
    };
  }

  onSubmit = (data: any) => {
    this.props.putSingle(_.get(this.props, 'agency.id', null), data);
  };

  openPopup = () => {
    this.setState({
      openPopup: true,
    });
  };

  closePopup = () => {
    this.setState({
      openPopup: false,
    });
  };

  renderLoading = () => {
    if (_.get(this.props, 'isLoading', false) === true) {
      return (
        <div className={styles.infoLoader}>
          <Loader visible={true} />
        </div>
      );
    }

    return null;
  };

  render() {
    const country: any = _.find(
      countries,
      (c) => c.code == _.get(this.props, 'agency.address.countryCode', null),
    );

    return (
      <Paper className={styles.paper}>
        <div className={styles.companyInfoHeader}>
          <div className={styles.companyInfoHeaderIconContainer}>
            <SvgIconCompany
              width={10}
              height={16}
            />
          </div>
          <div className={styles.companyInfoHeaderText}>
            <h2>Agency Details</h2>
            <div className={styles.description}>
              Basic details of the agency.
            </div>
          </div>
        </div>
        <div className={styles.companyInfoContainer}>
          <div className={styles.companyInfoContainerRow}>
            <div className={styles.companyInfoTitle}>
              {_.get(this.props, 'agency.friendlyName', '')}
            </div>
            {!this.props.disabled && (
              <Button
                size="small"
                className={cx(styles.button, styles.buttonEdit)}
                variant="outlined"
                color="primary"
                onClick={this.openPopup}
                id="edit-agency-button"
              >
                Edit
              </Button>
            )}
          </div>
          <div className={styles.companyInfoContainerDetails}>
            <div className={styles.descr}>
              App URL: <p>{_.get(this.props, 'agency.appHost', '')}</p>
            </div>
            <div className={styles.descr}>
              Marketing URL:{' '}
              <p>{_.get(this.props, 'agency.marketingUrl', '')}</p>
            </div>
            <div
              className={cx(
                styles.companyInfoContainerDetails,
                styles.companyInfoAddress,
              )}
            >
              <div className={styles.descr}>
                Address:
                <div className={styles.infCont}>
                  <div>
                    {_.get(this.props, 'agency.address.street', '') != '' && (
                      <p> {_.get(this.props, 'agency.address.street', '')} </p>
                    )}
                  </div>
                  <div>
                    {_.get(this.props, 'agency.address.cityName', '') != '' && (
                      <p>
                        {' '}
                        {_.get(this.props, 'agency.address.cityName', '')},{' '}
                      </p>
                    )}
                    {_.get(
                      this.props,
                      'agency.address.stateProvinceCode',
                      '',
                    ) != '' && (
                      <p>
                        {' '}
                        {_.get(
                          this.props,
                          'agency.address.stateProvinceCode',
                          '',
                        )}{' '}
                      </p>
                    )}
                    {_.get(this.props, 'agency.address.postalCode', '') !=
                      '' && (
                      <p>
                        {' '}
                        {_.get(
                          this.props,
                          'agency.address.postalCode',
                          '',
                        )}{' '}
                      </p>
                    )}
                  </div>
                  <div>
                    {_.get(country, 'name', '') != '' && (
                      <p> {_.get(country, 'name', '')} </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <AgencyInfoPopup
          open={this.state.openPopup}
          onClose={this.closePopup}
          onSubmit={this.onSubmit}
          agency={this.props.agency}
          isLoading={this.props.isLoading}
        />

        {this.renderLoading()}
      </Paper>
    );
  }
}

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {
    isLoading: state.agency.isLoading,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      putSingle,
    },
    dispatch,
  );
}

// Connect it to Redux
export default connect(mapStateToProps, mapDispatchToProps)(AgencyInfo);

import React from 'react';

type Props = {
  width: number;
  height: number;
};

const SvgUserIcon = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 64 64"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <circle
          id="path-1"
          cx="32"
          cy="32"
          r="32"
        ></circle>
      </defs>
      <g
        id="Master"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="BB-Settings"
          transform="translate(-320.000000, -186.000000)"
        >
          <g
            id="Users"
            transform="translate(280.000000, 143.000000)"
          >
            <g
              id="User-Icon"
              transform="translate(40.000000, 43.000000)"
            >
              <mask
                id="mask-2"
                fill="white"
              >
                <use xlinkHref="#path-1"></use>
              </mask>
              <use
                id="Mask"
                fill="#1B2432"
                xlinkHref="#path-1"
              ></use>
              <g
                id="Avatar"
                mask="url(#mask-2)"
                fill="#FFFFFF"
                fillRule="nonzero"
              >
                <g transform="translate(18.000000, 18.000000)">
                  <path
                    d="M28,28 C28,21.372583 21.7319864,16 14,16 C6.2680135,16 0,21.372583 0,28 C6.36363636,28 21.6363636,28 28,28 Z"
                    id="Path"
                  ></path>
                  <circle
                    id="Oval"
                    cx="14"
                    cy="6"
                    r="6"
                  ></circle>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgUserIcon;

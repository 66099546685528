import {
  ACTION_GET_FEATURES,
  ACTION_GET_FEATURES_SUCCESS,
  ACTION_GET_FEATURES_FAILURE,
  ACTION_EMPTY_FEATURES,
  ACTION_RESET_FEATURES,
  ACTION_PUT_FEATURE,
  ACTION_PUT_FEATURES_SUCCESS_MANAGE,
  ACTION_PUT_FEATURES_FAILURE,
  ACTION_POST_FEATURE,
  ACTION_POST_FEATURE_SUCCESS,
  ACTION_POST_FEATURE_FAILURE,
  ACTION_RESET_FEATURES_EXCEPT_LIST,
  ACTION_DELETE_FEATURE,
  ACTION_DELETE_FEATURES_SUCCESS,
  ACTION_DELETE_FEATURES_FAILURE,
} from '../configs/ActionTypes';

import { Features } from './types';

import _ from 'lodash-es';

const initialState = {
  features: [],
  isLoading: false,
  success: null,
  errors: {
    name: '',
    confidence: '',
    platform: '',
  },
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_PUT_FEATURE:
    case ACTION_POST_FEATURE:
    case ACTION_GET_FEATURES:
    case ACTION_DELETE_FEATURE: {
      return {
        ...state,
        isLoading: true,
        success: null,
      };
    }

    case ACTION_GET_FEATURES_SUCCESS: {
      let features: Array<Features> = [];

      let payload = action.payload.payload;

      _.each(payload, (t: any) => {
        let feature: Features = {
          id: t.id,
          confidence: t.confidence,
          created: t.created,
          updated: t.updated,
          platform: t.platform,
          version: _.get(t, 'version', []),
          scope: _.get(t, 'scope', []),
          name: t.name,
        };

        if (_.get(t, 'friendlyName', '') !== '') {
          feature.friendlyName = t.friendlyName;
        }

        features.push(feature);
      });

      return {
        ...state,
        features: features,
        isLoading: false,
        success: true,
      };
    }

    case ACTION_PUT_FEATURES_SUCCESS_MANAGE: {
      let features_refresh: Array<Features> = _.clone(state.features);
      let payload = action.payload.resp.payload;
      let name = action.payload.name;

      _.remove(features_refresh, function (f) {
        return f.name == name;
      });

      _.each(payload, (t: any) => {
        let feature: Features = {
          id: t.id,
          confidence: t.confidence,
          created: t.created,
          updated: t.updated,
          platform: t.platform,
          version: t.version,
          scope: _.get(t, 'scope', []),
          name: t.name,
        };

        if (_.get(t, 'friendlyName', '') !== '') {
          feature.friendlyName = t.friendlyName;
        }

        features_refresh.push(feature);
      });

      return {
        ...state,
        features: features_refresh,
        isLoading: false,
        success: true,
      };
    }

    case ACTION_DELETE_FEATURES_SUCCESS: {
      let features_refresh: Array<Features> = _.clone(state.features);
      let payload = action.payload.payload;

      _.remove(features_refresh, function (f) {
        return payload.includes(f.id);
      });

      return {
        ...state,
        features: features_refresh,
        isLoading: false,
        success: true,
      };
    }

    case ACTION_POST_FEATURE_SUCCESS: {
      let features_refresh: Array<Features> = _.clone(state.features);
      let payload = action.payload.payload;

      _.each(payload, (t: any) => {
        let feature: Features = {
          id: t.id,
          confidence: t.confidence,
          created: t.created,
          updated: t.updated,
          platform: t.platform,
          version: t.version,
          scope: _.get(t, 'scope', []),
          name: t.name,
        };

        if (_.get(t, 'friendlyName', '') !== '') {
          feature.friendlyName = t.friendlyName;
        }

        features_refresh.push(feature);
      });

      return {
        ...state,
        features: features_refresh,
        isLoading: false,
        success: true,
      };
    }

    case ACTION_EMPTY_FEATURES: {
      return {
        ...state,
        features: [],
        isLoading: false,
        success: null,
        errors: {
          name: '',
          confidence: '',
          platform: '',
        },
      };
    }

    case ACTION_DELETE_FEATURES_FAILURE: {
      return {
        ...state,
        isLoading: false,
        success: false,
      };
    }

    case ACTION_GET_FEATURES_FAILURE:
    case ACTION_DELETE_FEATURES_FAILURE: {
      return {
        ...state,
        features: [],
        isLoading: false,
        success: false,
      };
    }
    case ACTION_PUT_FEATURES_FAILURE:
    case ACTION_POST_FEATURE_FAILURE: {
      let nameError = '';
      let confidenceError = '';
      let platformError = '';

      if (
        _.get(action.payload, 'payload.confidence.requiredError', false) ===
        true
      ) {
        confidenceError = 'Required';
      }

      if (
        _.get(action.payload, 'payload.platform.requiredError', false) === true
      ) {
        platformError = 'Required';
      }

      if (_.get(action.payload, 'payload.name.requiredError', false)) {
        nameError = 'Required';
      } else if (
        _.get(action.payload, 'payload.name.uniqueError', false) === true
      ) {
        nameError = 'Name in use';
      }

      return {
        ...state,
        isLoading: false,
        success: false,
        errors: {
          name: nameError,
          confidence: confidenceError,
          platform: platformError,
        },
      };
    }

    case ACTION_RESET_FEATURES: {
      return {
        ...state,
        features: [],
        isLoading: false,
        success: null,
      };
    }

    case ACTION_RESET_FEATURES_EXCEPT_LIST: {
      return {
        ...state,
        isLoading: false,
        success: null,
        errors: {
          name: '',
          confidence: '',
          platform: '',
        },
      };
    }

    default: {
      return state;
    }
  }
}

import React from 'react';

type Props = {
  width: number;
  height: number;
};

const SvgCompletedAvatar = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs></defs>
      <g
        id="(WIP)-Refund-Card"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Booking-Macro---Flight"
          transform="translate(-310.000000, -637.000000)"
          fillRule="nonzero"
        >
          <g
            id="traveler-info"
            transform="translate(282.000000, 598.000000)"
          >
            <g
              id="Completed-Avatar"
              transform="translate(28.000000, 39.000000)"
            >
              <circle
                id="path-1"
                fill="#1B2432"
                cx="16"
                cy="16"
                r="16"
              ></circle>
              <g
                id="Avatar"
                transform="translate(9.000000, 9.000000)"
                fill="#FFFFFF"
              >
                <path
                  d="M14,14 C14,10.6862915 10.8659932,8 7,8 C3.13400675,8 0,10.6862915 0,14 C3.18181818,14 10.8181818,14 14,14 Z"
                  id="Path"
                ></path>
                <circle
                  id="Oval"
                  cx="7"
                  cy="3"
                  r="3"
                ></circle>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgCompletedAvatar;

import React from 'react';

type Props = {
  width: number;
  height: number;
};

const SvgIconCompany = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 16 25"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Master"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Organization-List"
          transform="translate(-16.000000, -102.000000)"
          fill="#FFFFFF"
        >
          <g id="menu">
            <g
              id="company"
              transform="translate(16.000000, 102.000000)"
            >
              <rect
                id="Rectangle"
                x="4.7"
                y="11.4"
                width="2.7"
                height="2.7"
              ></rect>
              <rect
                id="Rectangle"
                x="8.6"
                y="11.4"
                width="2.7"
                height="2.7"
              ></rect>
              <rect
                id="Rectangle"
                x="4.8"
                y="15.4"
                width="2.7"
                height="2.7"
              ></rect>
              <rect
                id="Rectangle"
                x="8.6"
                y="15.4"
                width="2.7"
                height="2.7"
              ></rect>
              <rect
                id="Rectangle"
                x="4.8"
                y="19.4"
                width="2.7"
                height="2.7"
              ></rect>
              <rect
                id="Rectangle"
                x="8.6"
                y="19.4"
                width="2.7"
                height="2.7"
              ></rect>
              <path
                d="M14.9,6.8 C14.1,6.8 13.2,6.8 12.4,6.8 C12.4,5.7 12.4,4.6 12.4,3.5 C12.4,3 11.9,2.5 11.4,2.5 C10.6,2.5 9.8,2.5 9,2.5 C9,2 9,1.5 9,1 C9,-0.3 7,-0.3 7,1 C7,1.5 7,2 7,2.6 C6.2,2.6 5.4,2.6 4.6,2.6 C4.1,2.6 3.6,3.1 3.6,3.6 C3.6,4.7 3.6,5.8 3.6,6.9 C2.8,6.9 1.9,6.9 1.1,6.9 C0.6,6.9 0.1,7.4 0.1,7.9 C0.1,13.3 0.1,18.7 0.1,24 C0.1,25.3 2.1,25.3 2.1,24 C2.1,19 2.1,13.9 2.1,8.9 C6,8.9 9.9,8.9 13.9,8.9 C13.9,13.9 13.9,19 13.9,24 C13.9,25.3 15.9,25.3 15.9,24 C15.9,18.6 15.9,13.2 15.9,7.9 C15.9,7.3 15.4,6.8 14.9,6.8 Z M5.7,4.5 C7.3,4.5 8.9,4.5 10.5,4.5 C10.5,5.3 10.5,6 10.5,6.8 C8.9,6.8 7.3,6.8 5.7,6.8 C5.7,6 5.7,5.3 5.7,4.5 Z"
                id="Shape"
                fillRule="nonzero"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgIconCompany;

import * as React from 'react';
import classNames from 'classnames';

import _ from 'lodash-es';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import SvgAddIcon from '../../static/icons/svgAddIcon';

import { hasPermission } from '../../helpers/Permission';
const styles = require('./styles.pcss');

type Props = {
  onClickAddCompany: any;
};

class CompanyTableHead extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <TableHead>
        <TableRow>
          <TableCell className={classNames(styles.tableHead)}>Name</TableCell>
          <TableCell className={classNames(styles.tableHead)}>Domain</TableCell>
          <TableCell className={classNames(styles.tableHead)}>Agency</TableCell>
          <TableCell className={classNames(styles.tableHead)}>
            {hasPermission('organizations', 'create') && (
              <div
                id="add-company"
                onClick={this.props.onClickAddCompany}
                className={classNames(styles.addCompany)}
              >
                <div className={classNames(styles.addIcon)}>
                  <SvgAddIcon
                    width={18}
                    height={18}
                  />
                </div>
                <div>Add Company</div>
              </div>
            )}
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }
}

export default CompanyTableHead;

import * as React from 'react';
import * as _ from 'lodash-es';
import moment from 'moment-timezone';
import Paper from '@material-ui/core/Paper';

const styles = require('./styles.pcss');

type Props = {
  booking: any;
};

class CarBookingDetails extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    let pickUp = '';
    let dropOff = '';

    if (moment(_.get(this.props.booking, 'pickupDateTime', '')).isValid()) {
      if (_.get(this.props.booking, 'car.location.pickup.tz', '')) {
        pickUp = moment
          .tz(
            this.props.booking.pickupDateTime,
            this.props.booking.car.location.pickup.tz,
          )
          .format('LT ddd, MMM D');
      } else {
        pickUp =
          moment
            .utc(this.props.booking.pickupDateTime)
            .format('LT ddd, MMM D') + ' UTC';
      }
    }

    if (moment(_.get(this.props.booking, 'returnDateTime', '')).isValid()) {
      if (_.get(this.props.booking, 'car.location.return.tz', '')) {
        dropOff = moment
          .tz(
            this.props.booking.returnDateTime,
            this.props.booking.car.location.return.tz,
          )
          .format('LT ddd, MMM D');
      } else {
        dropOff =
          moment
            .utc(this.props.booking.returnDateTime)
            .format('LT ddd, MMM D') + ' UTC';
      }
    }

    const pickUpAddress = `${_.get(
      this.props.booking,
      'car.location.pickup.street',
      '',
    )}${
      _.get(this.props.booking, 'car.location.pickup.street', '') ? ', ' : ''
    }${_.get(this.props.booking, 'car.location.pickup.city', '')}${
      _.get(this.props.booking, 'car.location.pickup.city', '') ? ', ' : ''
    }${_.get(this.props.booking, 'car.location.pickup.state', '')}`;
    const dropOffAddress = `${_.get(
      this.props.booking,
      'car.location.return.street',
      '',
    )}${
      _.get(this.props.booking, 'car.location.return.street', '') ? ', ' : ''
    }${_.get(this.props.booking, 'car.location.return.city', '')}${
      _.get(this.props.booking, 'car.location.return.city', '') ? ', ' : ''
    }${_.get(this.props.booking, 'car.location.return.state', '')}`;
    const carName = _.get(this.props.booking, 'car.carName', '')
      ? `${_.get(this.props.booking, 'car.carName', '')} or similar`
      : '';

    return (
      <Paper className={styles.carBookingDetails}>
        <div className={styles.header}>
          <div className={styles.carName}>
            <p className={styles.carName}>
              {_.get(this.props.booking, 'car.vendorName', '')}
            </p>
            <p className={styles.class}>
              {_.get(this.props.booking, 'car.class', '')}
            </p>
            {carName && <p className={styles.carDetName}>{carName}</p>}
            <div className={styles.carDetailsRow}>
              {_.get(this.props.booking, 'car.doorCount', '') && (
                <p>{_.get(this.props.booking, 'car.doorCount', '')} Doors</p>
              )}
              {_.get(this.props.booking, 'car.seatCount', '') && (
                <p>{_.get(this.props.booking, 'car.seatCount', '')} Seats</p>
              )}
              {_.get(this.props.booking, 'car.transmissionType', '') && (
                <p>{_.get(this.props.booking, 'car.transmissionType', '')}</p>
              )}
            </div>
          </div>
          <div>
            <p className={styles.confNumb}>Confirmation Number</p>
            <p className={styles.description}>
              {' '}
              {_.get(this.props.booking, 'confirmationNumber', '')}{' '}
            </p>
          </div>
        </div>
        <div className={styles.carInfo}>
          <div>
            <p className={styles.title}>Pick-up</p>
            <p className={styles.description}> {pickUp} </p>
          </div>
          <div>
            <p className={styles.title}>Address</p>
            <p className={styles.description}>
              {' '}
              {pickUpAddress ? pickUpAddress : '- -'}{' '}
            </p>
          </div>
        </div>
        <div className={styles.carInfo}>
          <div>
            <p className={styles.title}>Drop-off</p>
            <p className={styles.description}> {dropOff} </p>
          </div>
          <div>
            <p className={styles.title}>Address</p>
            <p className={styles.description}>
              {' '}
              {dropOffAddress ? dropOffAddress : '- -'}{' '}
            </p>
          </div>
        </div>
      </Paper>
    );
  }
}

export default CarBookingDetails;

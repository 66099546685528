import {
  ACTION_GET_AGENCY,
  ACTION_GET_AGENCY_SUCCESS,
  ACTION_GET_AGENCY_FAILURE,
  ACTION_EMPTY_AGENCY,
  API_CALL,
} from '../../configs/ActionTypes';

import { Dispatch } from 'redux';
import { enqueueSnackbarHandler } from '../Status';
import { navigate } from '@reach/router';

export function getSingle(agencyId: string) {
  return (dispatch: Dispatch) => {
    // Dispatch the api call request.
    dispatch(requestStarted());

    dispatch({
      type: API_CALL,
      payload: {
        call: `agencies/${agencyId}`,
        method: 'get',
        data: {},
        success: (response: any) => {
          if (response.type === 'error') {
            enqueueSnackbarHandler(dispatch, response.payload.payload, 'error');
            dispatch(requestFailed(response));

            if (response.payload.unknownAgency === true) {
              navigate(`/lookup`);
              return;
            }
          } else {
            dispatch(requestSuccess(response));
          }
        },
        fail: (error: any) => {
          enqueueSnackbarHandler(dispatch, error.payload, 'error');

          if (error.payload.unknownAgency === true) {
            navigate(`/lookup`);
            return;
          }

          dispatch(requestFailed(error));
        },
      },
    });

    return null;
  };
}

export function emptyResults() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ACTION_EMPTY_AGENCY,
      payload: null,
    });
  };
}

function requestStarted() {
  return {
    type: ACTION_GET_AGENCY,
    payload: null,
  };
}

function requestSuccess(responseJSON: object) {
  return {
    type: ACTION_GET_AGENCY_SUCCESS,
    payload: responseJSON,
  };
}

function requestFailed(responseJSON: object) {
  return {
    type: ACTION_GET_AGENCY_FAILURE,
    payload: responseJSON,
  };
}

import { Features } from 'reducers/types';

export enum ScopeType {
  ORGANIZATION = 'organization',
  AGENCY = 'agency',
  USER = 'user',
}

const isFeatureEnabled = (
  featureFlag: Features,
  type: ScopeType,
  id: string,
) => {
  if (!featureFlag || featureFlag.scope?.type !== type) {
    return false;
  }

  switch (type) {
    case ScopeType.USER:
      return featureFlag.scope.users?.includes(id) ?? false;
    case ScopeType.ORGANIZATION:
      return featureFlag.scope.organization?.includes(id) ?? false;
    case ScopeType.AGENCY:
      return featureFlag.scope.agencies?.includes(id) ?? false;
    default:
      return false;
  }
};

export default isFeatureEnabled;

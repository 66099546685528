import * as React from 'react';
import _ from 'lodash-es';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import SvgCompletedAvatar from '../../static/icons/svgCompletedAvatar';
import SvgCompletedAvatarSilver from '../../static/icons/svgCompletedAvatarSilver';
import SvgCheck from '../../static/icons/svgCheck';
import SvgUnCheck from '../../static/icons/svgUnCheck';
const styles = require('./styles.pcss');
import cx from 'classnames';
import { hasPermission } from '../../helpers/Permission';
import Button from '@material-ui/core/Button';

type Props = {
  traveler: any;
  isLoading?: any;
  onClickAddUserPopupOpen: any;
  addTraveler?: any;
};

const renderAvatar = (exist: boolean) => {
  return exist ? (
    <SvgCompletedAvatar
      width={32}
      height={32}
    />
  ) : (
    <SvgCompletedAvatarSilver
      width={32}
      height={32}
    />
  );
};

const renderIcon = (value: any) => {
  if (value != '— —') {
    return (
      <SvgCheck
        width={16}
        height={12}
      />
    );
  }

  return (
    <SvgUnCheck
      width={16}
      height={12}
    />
  );
};

const UserProfileTravelerInformation = (props: Props) => {
  let firstName =
    _.get(props, 'traveler.firstName', null) !== null
      ? _.get(props, 'traveler.firstName', null)
      : '';
  let lastName =
    _.get(props, 'traveler.lastName', null) !== null
      ? _.get(props, 'traveler.lastName', null)
      : '';
  let name = `${firstName} ${lastName}`;
  let phoneCountryCode =
    _.get(props.traveler, 'phoneCountryCode', '') !== '' &&
    _.get(props.traveler, 'phoneCountryCode', null) !== null
      ? `+${_.get(props.traveler, 'phoneCountryCode', '')}`
      : '';
  let phoneNumber =
    _.get(props.traveler, 'phoneNumber', null) !== null &&
    _.get(props.traveler, 'phoneNumber', '') !== ''
      ? `${phoneCountryCode}${_.get(props.traveler, 'phoneNumber', '')}`
      : '— —';
  let gender =
    _.get(props.traveler, 'gender', '') == 'F'
      ? 'Female'
      : _.get(props.traveler, 'gender', '') == 'M'
        ? 'Male'
        : '— —';
  let date =
    _.get(props.traveler, 'birthDate', null) !== null
      ? moment(_.get(props.traveler, 'birthDate', 'null'), 'YYYYMMDD')
          .utc()
          .format('L')
      : '— —';

  let passExpiry = '';
  let passNumber = '';
  let passCountry = '';

  if (_.get(props.traveler, 'passportInfo', false)) {
    passExpiry =
      _.get(props.traveler.passportInfo, 'passExpiry', null) !== null
        ? moment(
            _.get(props.traveler.passportInfo, 'passExpiry', null),
            'YYYYMMDD',
          )
            .utc()
            .format('L')
        : '— —';
    passNumber =
      _.get(props.traveler.passportInfo, 'passNumber', null) !== null
        ? _.get(props.traveler, 'knownTravelerNumber', '').length > 0
          ? '#' + _.get(props.traveler, 'knownTravelerNumber', '- -')
          : '— —'
        : '— —';
    passCountry = _.get(props.traveler.passportInfo, 'passCountry', '- -');
  } else {
    passExpiry =
      _.get(props.traveler, 'passExpiry', null) !== null
        ? moment(_.get(props.traveler, 'passExpiry', null), 'YYYYMMDD')
            .utc()
            .format('L')
        : '— —';
    passNumber =
      _.get(props.traveler, 'passNumber', null) !== null
        ? _.get(props.traveler, 'passNumber', '').length > 0
          ? '#' + _.get(props.traveler, 'passNumber', '- -')
          : '— —'
        : '— —';
    passCountry =
      _.get(props.traveler, 'passCountry', null) !== null
        ? _.get(props.traveler, 'passCountry', '').length > 0
          ? _.get(props.traveler, 'passCountry', '- -')
          : '— —'
        : '— —';
  }

  let passportInfo = false;
  passportInfo =
    passNumber != '— —' || passCountry != '— —' || passExpiry != '— —'
      ? true
      : false;
  let knownTravelerNumber =
    _.get(props.traveler, 'knownTravelerNumber', '') != null
      ? _.get(props.traveler, 'knownTravelerNumber', '').length > 0
        ? _.get(props.traveler, 'knownTravelerNumber', '- -')
        : '— —'
      : '— —';

  const travelerExists =
    _.get(props.traveler, 'id', null) !== null &&
    _.get(props.traveler, 'saveTraveler', false) === true;

  if (!travelerExists && !hasPermission('travelers', 'create')) {
    return <div>No traveler profile exists for this user.</div>;
  }

  return (
    <div className={styles.bookingDetails}>
      <div className={styles.header}>
        <div className={styles.avatarAndNameActionButton}>
          <div className={styles.avatarAndName}>
            <div className={styles.avatarContainer}>
              {renderAvatar(!props.addTraveler || travelerExists)}
            </div>
            <div>
              <div className={styles.titleStyle}>Traveler Information</div>
              <div className={styles.subtitleStyle}>
                <span className={styles.boldText}>
                  Add traveler information to start booking all travel within
                  TravelBank
                </span>
              </div>
            </div>
          </div>
          <div>
            {props.addTraveler &&
              !travelerExists &&
              hasPermission('travelers', 'create') && (
                <div className={styles.buttonContainer}>
                  <Button
                    size="small"
                    className={styles.editButton}
                    variant="outlined"
                    color="primary"
                    onClick={props.onClickAddUserPopupOpen}
                    id="addTraveler"
                  >
                    Add
                  </Button>
                </div>
              )}
          </div>
        </div>
      </div>
      {(!props.addTraveler || travelerExists) && (
        <div className={styles.userDetailsHeader}>
          <div className={styles.userDetailsInfo}>
            <div className={styles.userNameAndActionbuttonContaner}>
              <div className={styles.userName}>
                {' '}
                {_.get(props.traveler, 'firstName', '')}{' '}
                {_.get(props.traveler, 'middleName', '')}{' '}
                {_.get(props.traveler, 'lastName', '')}{' '}
              </div>
              {hasPermission('travelers', 'update') && (
                <div className={styles.buttonContainer}>
                  <Button
                    size="small"
                    className={styles.editButton}
                    variant="outlined"
                    color="primary"
                    onClick={props.onClickAddUserPopupOpen}
                    id="updateTraveler"
                  >
                    Edit
                  </Button>
                </div>
              )}
            </div>
            <div className={styles.profileInfo}>
              <div className={styles.userInfoRow}>
                <div className={styles.userInfoRowWithIcon}>
                  <p>
                    {' '}
                    Gender:{' '}
                    <span
                      className={styles.passengerDesc}
                      id="gender"
                    >
                      {gender}
                    </span>{' '}
                  </p>{' '}
                </div>
                <div className={styles.userInfoRowWithIcon}>
                  <p>
                    {' '}
                    Date of Birth:{' '}
                    <span
                      className={styles.passengerDesc}
                      id="dateOfBirth"
                    >
                      {date}
                    </span>{' '}
                  </p>
                </div>
                <div className={styles.userInfoRowWithIcon}>
                  <p>
                    {' '}
                    Phone:{' '}
                    <span
                      className={styles.passengerDesc}
                      id="phoneNumber"
                    >
                      {phoneNumber}
                    </span>{' '}
                  </p>
                </div>
                <div className={styles.userInfoRowWithIcon}>
                  <p>
                    {' '}
                    Email:{' '}
                    <span
                      className={styles.passengerDesc}
                      id="email"
                    >
                      {_.get(props.traveler, 'email', '— —')}
                    </span>{' '}
                  </p>
                </div>
              </div>

              <div className={cx(styles.passengerInfoRow, styles.userInfoRow)}>
                <div className={styles.userInfoRowWithIcon}>
                  {renderIcon(knownTravelerNumber)}
                  <p>
                    Known Traveler Number:{' '}
                    <span
                      className={styles.passengerDesc}
                      id="knownTravelerNumber"
                    >
                      {knownTravelerNumber}
                    </span>{' '}
                  </p>
                </div>

                {!passportInfo ? (
                  <div className={styles.userInfoRowWithIcon}>
                    {renderIcon('— —')}
                    <p className={styles.passportInfoLabel}>
                      Passport Information: — —
                    </p>
                  </div>
                ) : (
                  <>
                    <div className={styles.userInfoRowWithIcon}>
                      {renderIcon(
                        passNumber != '— —' &&
                          passCountry != '— —' &&
                          passExpiry != '— —'
                          ? 'ok'
                          : '— —',
                      )}
                      <p>
                        Passport Information:{' '}
                        <span className={styles.passengerDesc}>
                          {passNumber}
                        </span>{' '}
                      </p>
                    </div>
                    <div className={styles.userInfoRowWithIcon}>
                      <p className={styles.passengerDesc}>
                        Country:{' '}
                        <span className={styles.passengerDesc}>
                          {passCountry}
                        </span>{' '}
                      </p>
                    </div>
                    <div className={styles.userInfoRowWithIcon}>
                      <p className={styles.passengerDesc}>
                        Expiration:{' '}
                        <span className={styles.passengerDesc}>
                          {passExpiry}
                        </span>
                      </p>
                    </div>{' '}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserProfileTravelerInformation;

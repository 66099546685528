import * as React from 'react';
import _ from 'lodash';
import cx from 'classnames';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Loader from 'components/Loader/Loader';
import SvgLockIcon from '../../../static/icons/svgLockIcon';
import ConfirmationPopup from 'components/Popup/Confirmation';
import LicensePricingEditPopup from './LicensePricingEditPopup';
import { convertToNumber } from '../../../helpers/ConvertToNumber';

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Company, ReduxState } from '../../../reducers/types';
import { resetLicensePricing } from 'actions/Companies/Settings/ResetLicensePricing';
import { updateLicensePricing } from 'actions/Companies/Settings/UpdateLicensePricing';
import PaidPlan from '../PaidPlan/PaidPlan'
const styles = require('./styles.pcss');

type Props = {
  company: Company;
  licensePricing: any;
  loading: boolean;
  resetLicensePricing: Function;
  updateLicensePricing: Function;
};

type State = {
  openResetConfirmationPopup: boolean;
  openEditPopup: boolean;
};

const { format: formatCurrency } = Intl.NumberFormat('en-US', {
  currency: 'USD',
  style: 'currency',
});

class LicensePricing extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      openResetConfirmationPopup: false,
      openEditPopup: false,
    };
  }

  openResetConfirmation = () => {
    this.setState({
      openResetConfirmationPopup: true,
    });
  };

  closeResetConfirmation = () => {
    this.setState({
      openResetConfirmationPopup: false,
    });
  };

  resetPricing = () => {
    this.props.resetLicensePricing(_.get(this.props.licensePricing, 'id'));
    this.closeResetConfirmation();
  };

  openEditPopup = () => {
    this.setState({
      openEditPopup: true,
    });
  };

  closeEditPopup = () => {
    this.setState({
      openEditPopup: false,
    });
  };

  onSuccess = () => {
    this.setState({
      openEditPopup: false,
    });
  };

  renderLoading() {
    if (this.props.loading === true) {
      return (
        <div className={styles.infoLoader}>
          <Loader visible={true} />
        </div>
      );
    }

    return null;
  }

  render() {
    let paperStyle = styles.paper;

    if (this.props.loading === true) {
      paperStyle = cx(styles.paper, styles.isLoading);
    }

    let resetButton: any = '';
    if (_.get(this.props.licensePricing, 'name', '') === 'preferredPricing') {
      resetButton = (
        <Button
          size="small"
          className={cx(styles.button, styles.buttonReset)}
          variant="outlined"
          onClick={this.openResetConfirmation}
          id="reset-button"
        >
          Reset to Default
        </Button>
      );
    }

    return (
      <>
        <Paper className={paperStyle}>
          <div className={styles.licensePricingHeader}>
            <div className={styles.licensePricingHeaderIconContainer}>
              <SvgLockIcon
                width={32}
                height={32}
              />
            </div>
            <div className={styles.licensePricingHeaderText}>
              <h2>Subscription Details</h2>
              <div className={styles.description}>
                Settings for the company's subscription level and paid license pricing. Please note that any enabled feature flags will still apply.
              </div>
            </div>
          </div>
          <PaidPlan
             company={this.props.company}           
          />
          <div className={styles.licensePricingContainer}>
            <div className={styles.licensePricingContainerRow}>
              <div className={styles.licensePricingTitle}>
                License Pricing
                <div className={styles.licensePricingSubTitle}>
                  All rates are per user per month, in USD.
                </div>
              </div>
              {resetButton}
              <Button
                size="small"
                className={cx(styles.button, styles.buttonEdit)}
                variant="outlined"
                color="primary"
                onClick={this.openEditPopup}
                id="edit-button"
              >
                Edit
              </Button>
            </div>

            <div className={styles.companyInfoContainerDetails}>
              <div className={styles.companyInfoContainerDetailsSection}>
                <div className={styles.descrTitle}>
                  Travel & Expense (Premium)
                </div>
                <div className={styles.descr}>
                  Paid Annually:{' '}
                  <p>
                    {formatCurrency(
                      _.get(
                        this.props.licensePricing,
                        'value.subscription.premiumYearly',
                        0,
                      ),
                    )}
                  </p>
                </div>
                <div className={styles.descr}>
                  Paid Monthly:{' '}
                  <p>
                    {formatCurrency(
                      _.get(
                        this.props.licensePricing,
                        'value.subscription.premiumMonthly',
                        0,
                      ),
                    )}
                  </p>
                </div>
                <div className={styles.descr}>
                  Additional Usage:{' '}
                  <p>
                    {formatCurrency(
                      _.get(
                        this.props.licensePricing,
                        'value.overage.premium',
                        0,
                      ),
                    )}
                  </p>
                </div>
              </div>
              <div className={styles.companyInfoContainerDetailsSection}>
                <div className={styles.descrTitle}>Travel</div>
                <div className={styles.descr}>
                  Paid Annually:{' '}
                  <p>
                    {formatCurrency(
                      _.get(
                        this.props.licensePricing,
                        'value.subscription.travelYearly',
                        0,
                      ),
                    )}
                  </p>
                </div>
                <div className={styles.descr}>
                  Paid Monthly:{' '}
                  <p>
                    {formatCurrency(
                      _.get(
                        this.props.licensePricing,
                        'value.subscription.travelMonthly',
                        0,
                      ),
                    )}
                  </p>
                </div>
                <div className={styles.descr}>
                  Additional Usage:{' '}
                  <p>
                    {formatCurrency(
                      _.get(
                        this.props.licensePricing,
                        'value.overage.travel',
                        0,
                      ),
                    )}
                  </p>
                </div>
              </div>
              <div className={styles.companyInfoContainerDetailsSection}>
                <div className={styles.descrTitle}>Expense</div>
                <div className={styles.descr}>
                  Paid Annually:{' '}
                  <p>
                    {formatCurrency(
                      _.get(
                        this.props.licensePricing,
                        'value.subscription.expenseYearly',
                        0,
                      ),
                    )}
                  </p>
                </div>
                <div className={styles.descr}>
                  Paid Monthly:{' '}
                  <p>
                    {formatCurrency(
                      _.get(
                        this.props.licensePricing,
                        'value.subscription.expenseMonthly',
                        0,
                      ),
                    )}
                  </p>
                </div>
                <div className={styles.descr}>
                  Additional Usage:{' '}
                  <p>
                    {formatCurrency(
                      _.get(
                        this.props.licensePricing,
                        'value.overage.expense',
                        0,
                      ),
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <LicensePricingEditPopup
            open={this.state.openEditPopup}
            onClose={this.closeEditPopup}
            onSubmit={this.props.updateLicensePricing}
            licensePricing={this.props.licensePricing}
            company={this.props.company}
            onSuccess={this.onSuccess}
            isLoading={_.get(this.props.licensePricing, 'loading', false)}
          />

          {this.renderLoading()}
        </Paper>
        <ConfirmationPopup
          open={this.state.openResetConfirmationPopup}
          onClose={this.closeResetConfirmation}
          onSubmit={this.resetPricing}
          title="Reset pricing to Default Settings"
          bodyText="Resetting pricing rates will replace all current settings with the default rates."
          submitButtonText="Confirm Reset"
          closeButtonText="Cancel"
          disableX={true}
        />
      </>
    );
  }
}

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {
    loading: state.licensePricingSetting.loading,
  };
}

// Wire up redux dispatch functions
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      resetLicensePricing,
      updateLicensePricing,
    },
    dispatch,
  );
}

// Connect it to Redux
export default connect(mapStateToProps, mapDispatchToProps)(LicensePricing);

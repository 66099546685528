const providerCodeMap: { [index: string]: any } = {
  '1G': 'Galileo',
  '1V': 'Apollo',
};

const getProviderDisplayName = (
  provider: string,
  providerCode: string,
): string => {
  if (provider === 'travelport') {
    const providerCodeName = providerCodeMap?.[providerCode] || providerCode;
    return `TravelPort: ${providerCodeName}`;
  }

  return provider?.[0].toUpperCase() + provider.substring(1);
};

export { getProviderDisplayName, providerCodeMap };

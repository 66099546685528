import * as React from 'react';
import _ from 'lodash-es';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from './TableRow';

const styles = require('./styles.pcss');

import { missingBillingAddress } from '../../../helpers/MissingBillingAddress';

import { CreditCard } from 'reducers/types';

type Props = {
  creditCards: Array<CreditCard>;
  onClickEditCreditcardPopupOpen: any;
  editEnabled?: boolean;
};

type State = {
  tabValue: boolean;
};

const missingBillingAddressExistsF = (card: Array<CreditCard>) => {
  let exist = false;

  for (let i = 0; i < card.length; i++) {
    if (missingBillingAddress(card[i])) {
      exist = true;
      break;
    }
  }

  return exist;
};

const Creditcards = (props: Props) => {
  if (props.creditCards.length < 1) {
    return (
      <div className={styles.emptyData}>
        This organization has no associated credit cards data.
      </div>
    );
  }

  var cards = _.clone(props.creditCards);

  cards.sort((a: any, b: any) => a.last4.localeCompare(b.last4));

  let missingBillingAddressExists = missingBillingAddressExistsF(cards);

  return (
    <Table>
      <TableBody>
        {cards.map((card: CreditCard, i: number) => {
          return (
            <TableRow
              key={i}
              card={card}
              clickEdit={props.onClickEditCreditcardPopupOpen}
              editEnabled={props.editEnabled}
              missingBillingAddress={missingBillingAddress(card)}
              missingBillingAddressExists={missingBillingAddressExists}
            />
          );
        })}
      </TableBody>
    </Table>
  );
};

export default Creditcards;

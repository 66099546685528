import * as React from 'react';
import _ from 'lodash-es';
import cx from 'classnames';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TravelPolicyTableRows from './TravelPolicyTableRows';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Loader from 'components/Loader/Loader';

const styles = require('./styles.pcss');

import { ReduxState } from 'reducers/types';

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

type Props = {
  flightPolicy: any;
  hotelPolicy: any;
  loading: any;
};

class BookingRules extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    let advanceBooking: any = {
      name: 'Advance Booking',
      description:
        'Number of days in advance a booking must be made to be considered in policy.',
    };

    let hardCap: any = {
      name: 'Hard Cap',
      description:
        'Maximum amount a booking can be to be considered in policy.',
    };

    let flightAdvanceBooking =
      _.get(this.props.flightPolicy, 'rules.daysInAdvance.value', '') !== ''
        ? _.get(this.props.flightPolicy, 'rules.daysInAdvance.value', '') +
          ' Days'
        : '-';
    let hotelAdvanceBooking =
      _.get(this.props.hotelPolicy, 'rules.daysInAdvance.value', '') !== ''
        ? _.get(this.props.hotelPolicy, 'rules.daysInAdvance.value', '') +
          ' Days'
        : '-';
    let flightOverbudget =
      _.get(this.props.flightPolicy, 'rules.overValue.value', '') !== ''
        ? '$' + _.get(this.props.flightPolicy, 'rules.overValue.value', '')
        : '-';
    let hoteltOverbudget =
      _.get(this.props.hotelPolicy, 'rules.overValue.value', '') !== ''
        ? '$' + _.get(this.props.hotelPolicy, 'rules.overValue.value', '')
        : '-';

    return (
      <Paper
        className={
          this.props.loading ? cx(styles.paper, styles.loading) : styles.paper
        }
      >
        <div className={styles.policyRules}>
          <h2>Booking Rules</h2>
          <div>
            <Loader visible={this.props.loading} />
          </div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  align="right"
                  className={cx(styles.tableCell, styles.header)}
                ></TableCell>
                <TableCell
                  align="center"
                  className={cx(styles.tableCell, styles.header)}
                >
                  Flight
                </TableCell>
                <TableCell
                  align="center"
                  className={cx(styles.tableCell, styles.header)}
                >
                  Lodging
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TravelPolicyTableRows
                key={1}
                text={advanceBooking}
                flight={flightAdvanceBooking}
                hotel={hotelAdvanceBooking}
                description={true}
              />
              <TravelPolicyTableRows
                key={2}
                text={hardCap}
                flight={flightOverbudget}
                hotel={hoteltOverbudget}
                description={true}
              />
            </TableBody>
          </Table>
        </div>
      </Paper>
    );
  }
}

export default BookingRules;

import * as React from 'react';
import cx from 'classnames';
import ForwardIcon from '@material-ui/icons/ArrowForwardIos';

const styles = require('./styles.pcss');

type Props = {
  arrow?: boolean;
  name: string;
  onClick?: any;
};

const Breadcrumb = (props: Props) => {
  const onClick = () => {
    if (typeof props.onClick !== 'undefined') {
      props.onClick();
    }
  };

  let className = styles.breadcrumb;

  if (typeof props.onClick !== 'undefined') {
    className = cx(styles.breadcrumb, styles.breadcrumbLink);
  }

  return (
    <div
      onClick={onClick}
      className={className}
    >
      {props.name}
      {props.arrow && <ForwardIcon className={styles.icon} />}
    </div>
  );
};

export default Breadcrumb;

import {
  ACTION_GET_EXPENSE_SPLIT_SETTING,
  ACTION_GET_EXPENSE_SPLIT_SETTING_SUCCESS,
  ACTION_GET_EXPENSE_SPLIT_SETTING_FAILURE,
  ACTION_PUT_EXPENSE_SPLIT_SETTING,
  ACTION_PUT_EXPENSE_SPLIT_SETTING_SUCCESS,
  ACTION_PUT_EXPENSE_SPLIT_SETTING_FAILURE,
} from '../configs/ActionTypes';

const initialState = {
  feature: {},
  isLoading: false,
  success: null,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_GET_EXPENSE_SPLIT_SETTING:
    case ACTION_PUT_EXPENSE_SPLIT_SETTING: {
      return {
        ...state,
        isLoading: true,
        success: null,
      };
    }

    case ACTION_GET_EXPENSE_SPLIT_SETTING_SUCCESS:
    case ACTION_PUT_EXPENSE_SPLIT_SETTING_SUCCESS: {
      return {
        ...state,
        feature: action.payload.payload,
        isLoading: false,
        success: null,
      };
    }

    case ACTION_GET_EXPENSE_SPLIT_SETTING_FAILURE:
    case ACTION_PUT_EXPENSE_SPLIT_SETTING_FAILURE: {
      return {
        ...state,
        isLoading: false,
        success: false,
      };
    }

    default: {
      return state;
    }
  }
}

import {
  ACTION_DELETE_NEGOTIATED_HOTELS,
  ACTION_DELETE_NEGOTIATED_HOTELS_SUCCESS,
  ACTION_DELETE_NEGOTIATED_HOTELS_FAILURE,
  API_CALL,
} from '../../configs/ActionTypes';

import _ from 'lodash-es';

import { Dispatch } from 'redux';

import { enqueueSnackbarHandler } from '../Status';

export type datatype = {
  identifier?: string;
  organizationId?: any;
};

export function deleteNegotiatedHotels(data: datatype) {
  return (dispatch: Dispatch, getState: Function) => {
    dispatch(requestStarted());
    //Dispatch the api call request.
    dispatch({
      type: API_CALL,
      payload: {
        call: `organizations/${data.organizationId}/badge-hotel/${data.identifier}`,
        method: 'delete',
        success: (response: any) => {
          if (response.type === 'error') {
            dispatch(requestFailed(response));
            _.get(response.payload, 'payload.userPermissionError', false)
              ? enqueueSnackbarHandler(
                  dispatch,
                  response.payload.payload,
                  'error',
                )
              : enqueueSnackbarHandler(
                  dispatch,
                  { updateNegotiatedhotelsFailed: true },
                  'error',
                );
          } else {
            enqueueSnackbarHandler(
              dispatch,
              { updateNegotiatedhotels: true },
              'success',
            );
            dispatch(requestSuccess(response));
          }
        },
        fail: (error: any) => {
          dispatch(requestFailed(error));
          _.get(error.payload, 'userPermissionError', false)
            ? enqueueSnackbarHandler(dispatch, error.payload, 'error')
            : enqueueSnackbarHandler(
                dispatch,
                { updateNegotiatedhotelsFailed: true },
                'error',
              );
        },
      },
    });
    return null;
  };
}

export function requestStarted() {
  return {
    type: ACTION_DELETE_NEGOTIATED_HOTELS,
    payload: null,
  };
}

export function requestSuccess(responseJSON: object) {
  return {
    type: ACTION_DELETE_NEGOTIATED_HOTELS_SUCCESS,
    payload: responseJSON,
  };
}

export function requestFailed(responseJSON: object) {
  return {
    type: ACTION_DELETE_NEGOTIATED_HOTELS_FAILURE,
    payload: responseJSON,
  };
}

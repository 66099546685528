import * as React from 'react';
import _ from 'lodash-es';

import moment from 'moment';
import cx from 'classnames';

import SimpleTable from 'components/SimpleTable';
import Loader from 'components/Loader/Loader';

import BookingsTableHead from './BookingsTableHead';
import BookingsTableRow from './BookingsTableRow';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';

import SendEmailConfirmationPopup from 'components/Popup/SendEmailConfirmationPopup';

import { QUERY_LIMIT, QUERY_LIMIT_25 } from '../../configs/General';

import { connect } from 'react-redux';

import { sendEmail } from 'actions/Bookings/SendEmail';

import { bindActionCreators, Dispatch } from 'redux';

const styles = require('./styles.pcss');

const appWindow: Window = window;

type State = {
  openConfirmation: boolean;
  id: string;
  additionalEmails: any;
  selectedPNR?: string;
  userEmail: string;
  selectedIdentifier: any;
};

class Flights extends React.Component<any, State> {
  constructor(props: any) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);

    this.state = {
      openConfirmation: false,
      id: '',
      selectedIdentifier: '',
      additionalEmails: '',
      userEmail: '',
    };
  }

  componentWillUnmount() {
    appWindow.removeEventListener('scroll', this.handleScroll);
  }

  componentWillReceiveProps(nextProps: any) {
    if (nextProps.sort && nextProps.sort !== this.props.sort) {
      let params: any = {
        type: 'flight',
        limit: QUERY_LIMIT_25,
        skip: 0,
      };

      if (this.props.travelerId) {
        params.travelerId = this.props.travelerId;
      }

      if (this.props.userId) {
        params.userId = this.props.userId;
      }

      if (nextProps.order) {
        params.order = nextProps.order;
      }

      params.sort = nextProps.sort;
      this.props.get(params);
    }
  }

  componentDidMount() {
    appWindow.addEventListener('scroll', this.handleScroll);

    if (this.props.flights.length == 0) {
      let params: any = {
        type: 'flight',
        limit: QUERY_LIMIT_25,
        skip: 0,
      };

      if (this.props.travelerId) {
        params.travelerId = this.props.travelerId;
      }

      if (this.props.userId) {
        params.userId = this.props.userId;
      }

      if (this.props.sort) {
        params.sort = this.props.sort;
      }

      if (this.props.order) {
        params.order = this.props.order;
      }

      if (this.props.searchParams) {
        params = {
          ...params,
          ...this.props.searchParams,
        };
      }

      this.props.get(params);
    }
  }

  closeConfirmation = () => {
    this.setState({
      openConfirmation: false,
      id: '',
      selectedPNR: '',
      additionalEmails: '',
      userEmail: '',
    });
  };

  onSubmitSend = () => {
    this.setState({
      openConfirmation: false,
      id: '',
      selectedPNR: '',
      additionalEmails: '',
      userEmail: '',
    });
  };

  onSendEmail =
    (id: any, pnr: any, additionalEmails: any, userEmail: string) =>
    (event: any) => {
      this.setState({
        id: id,
        selectedPNR: pnr,
        openConfirmation: true,
        additionalEmails,
        userEmail,
      });
    };

  handleScroll() {
    const body = document.body;
    const html = document.documentElement;

    if (!body || !html) {
      return;
    }

    const windowHeight =
      'innerHeight' in appWindow ? appWindow.innerHeight : html.offsetHeight;

    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight,
    );
    const windowBottom = windowHeight + window.pageYOffset;

    if (
      windowBottom >= docHeight &&
      this.props.isLoading === false &&
      this.props.loadedAllFlight === false
    ) {
      let params: any = {
        type: 'flight',
        limit: QUERY_LIMIT_25,
        sort: this.props.sort,
        skip: this.props.flights.length,
      };

      if (this.props.userId) {
        params.userId = this.props.userId;
      }

      if (this.props.sort) {
        params.sort = this.props.sort;
      }

      if (this.props.order) {
        params.order = this.props.order;
      }

      if (this.props.searchParams) {
        params = {
          ...params,
          ...this.props.searchParams,
        };
      }

      this.props.get(params);
    }
  }

  renderTableRow = (cells: any, i: number) => {
    return (
      <BookingsTableRow
        key={i}
        id={this.props.flights[i].id}
        cells={cells}
      />
    );
  };

  render() {
    function createMarkup(string: any) {
      return { __html: string };
    }

    let columns = [
      'PNR',
      'Conf #',
      'Traveler',
      'Flight #',
      'From/To',
      'Departure',
      'Arrival',
      'Provider',
      'Status',
    ];

    let flights = this.props.flights;
    let flights_data: Array<any> = [];

    Object.keys(flights).forEach((key: any) => {
      let travelers = '';
      _.each(flights[key].travelers, (p) => {
        travelers = travelers + `<p>${p.firstName} ${p.lastName}</p>`;
      });

      let flightNumber = '';
      let fromTo = '';
      let departure = '';
      let arrival = '';

      _.each(flights[key].segments, (p: any) => {
        let dep = moment(p.departure)
          .utcOffset(p.departure)
          .format('MMM D, YYYY<br>h:mm a');
        let arr = moment(p.arrival)
          .utcOffset(p.arrival)
          .format('MMM D, YYYY<br>h:mm a');

        flightNumber =
          flightNumber +
          `<p>${_.get(p, 'airline', '') + _.get(p, 'flightNumber', '')}</p>`;
        fromTo = fromTo + `<p>${p.from} - ${p.to}</p>`;
        departure = departure + `<p>${dep}</p>`;
        arrival = arrival + `<p>${arr}</p>`;
      });

      let flight = {
        pnr: flights[key].pnr,
        confirmation: flights[key].confirmationNumber,
        travelers: <div dangerouslySetInnerHTML={createMarkup(travelers)} />,
        flightNumber: (
          <div dangerouslySetInnerHTML={createMarkup(flightNumber)} />
        ),
        fromTo: <div dangerouslySetInnerHTML={createMarkup(fromTo)} />,
        departure: <div dangerouslySetInnerHTML={createMarkup(departure)} />,
        arrival: <div dangerouslySetInnerHTML={createMarkup(arrival)} />,
        provider: (
          <div dangerouslySetInnerHTML={createMarkup(flights[key].provider)} />
        ),
        status: flights[key].status,
      };

      flights_data.push(flight);
    });

    let data = flights_data.map((v: any) => {
      return [
        v.pnr,
        v.confirmation,
        v.travelers,
        v.flightNumber,
        v.fromTo,
        v.departure,
        v.arrival,
        v.provider,
        v.status,
      ];
    });

    if (flights_data.length == 0 && this.props.isLoading == false) {
      return <div className={styles.noData}>Empty flight bookings.</div>;
    } else {
      let classNames = cx(
        styles.paper,
        styles.wideTable,
        this.props.noBorder ? styles.noBorder : styles.withBorder,
      );

      return (
        <div>
          <Paper className={classNames}>
            <Table>
              <BookingsTableHead columns={columns} />
              <TableBody>{data.map(this.renderTableRow)}</TableBody>
            </Table>
          </Paper>

          <Loader visible={this.props.isLoading} />
          <SendEmailConfirmationPopup
            open={this.state.openConfirmation}
            onClose={this.closeConfirmation}
            onSubmit={this.onSubmitSend}
            id={this.state.id}
            type="flight"
            identifier={this.state.selectedIdentifier}
            additionalEmails={this.state.additionalEmails}
            userEmail={this.state.userEmail}
          />
        </div>
      );
    }
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      sendEmail,
    },
    dispatch,
  );
}

export default connect(null, mapDispatchToProps)(Flights);

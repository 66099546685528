import React from 'react';

type Props = {
  width: number;
  height: number;
};

const SvgTriangle = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 8 13"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs></defs>
      <g
        id="(WIP)-Refund-Card"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Booking-Macro---Flight---Flight-Modal"
          transform="translate(-455.000000, -263.000000)"
          fill="#318AFB"
        >
          <g
            id="create-modal"
            transform="translate(370.000000, 143.000000)"
          >
            <g id="content">
              <g
                id="Flight-Card"
                transform="translate(1.000000, 77.000000)"
              >
                <g
                  id="route-1"
                  transform="translate(31.000000, 32.000000)"
                >
                  <path
                    d="M61,17.0472273 L54.5047388,22.7006685 C53.6736945,23.4240051 53,23.1180465 53,22.0150837 L53,12.079371 C53,10.9773964 53.6683155,10.6657678 54.5047388,11.3937862 L61,17.0472273 Z"
                    id="Triangle-2"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgTriangle;

import * as React from 'react';
import classNames from 'classnames';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import SvgIconPencil from '../../../static/icons/svgIconPencil';

const styles = require('./styles.pcss');

type Props = {
  rowColumns: Array<any>;
  showEdit?: boolean;
  clickEdit?: any;
};

const FeaturesTableRow = (props: Props) => {
  const tableColumn = (name: string, i: number) => (
    <TableCell
      key={i}
      className={classNames(styles.tableCell, styles.tableCellFeature)}
    >
      {name}
    </TableCell>
  );

  const displayEdit = (props: Props) => {
    if (props.showEdit) {
      return (
        <TableCell
          key={'rem'}
          className={classNames(styles.tableCell, styles.removeCell)}
        >
          <div
            className={styles.editFeatureButton}
            onClick={props.clickEdit}
          >
            <SvgIconPencil
              width={16}
              height={16}
            />
          </div>
        </TableCell>
      );
    }
    return (
      <TableCell
        key={'rem'}
        className={classNames(styles.tableCell)}
      ></TableCell>
    );
  };

  return (
    <TableRow className={classNames(styles.tableRow)}>
      <TableCell
        key={'emp1'}
        className={classNames(styles.empty)}
      ></TableCell>
      {props.rowColumns.map(tableColumn)}
      {displayEdit(props)}
      <TableCell
        key={'emp2'}
        className={classNames(styles.empty)}
      ></TableCell>
    </TableRow>
  );
};

export default FeaturesTableRow;

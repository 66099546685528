import {
  ACTION_GET_APPROVERS,
  ACTION_GET_APPROVERS_SUCCESS,
  ACTION_GET_APPROVERS_FAILURE,
  ACTION_EMPTY_APPROVERS,
} from '../configs/ActionTypes';

import { QUERY_LIMIT } from '../configs/General';
import { User } from './types';

import _ from 'lodash-es';

const initialState = {
  users: [],
  isLoading: false,
  loadedAll: false,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_GET_APPROVERS: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ACTION_EMPTY_APPROVERS: {
      return initialState;
    }

    case ACTION_GET_APPROVERS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case ACTION_GET_APPROVERS_SUCCESS: {
      let users: Array<User> = [];

      const payload = action.payload.payload;

      _.each(payload, (u) => {
        let user: User = {
          id: u.id,
          firstName: _.get(u, 'firstName', ''),
          lastName: _.get(u, 'lastName', ''),
          email: _.get(u, 'email', ''),
          status: u.status,
          phoneNumber: u.phoneNumber,
          created: u.created,
          updated: u.updated,
          avatar: _.get(u, 'avatar[0].thumbnails.thumbnail', ''),
        };

        users.push(user);
      });

      return {
        ...state,
        users: users,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
}

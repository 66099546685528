import {
  ACTION_GET_COMPANY_EMPLOYEES_SIMPLE,
  ACTION_GET_COMPANY_EMPLOYEES_SIMPLE_SUCCESS,
  ACTION_GET_COMPANY_EMPLOYEES_SIMPLE_FAILURE,
  ACTION_EMPTY_COMPANY_EMPLOYEES_SIMPLE,
  API_CALL,
} from '../../configs/ActionTypes';

import { Dispatch } from 'redux';
import { enqueueSnackbarHandler } from '../Status';

import _ from 'lodash-es';

import { navigate } from '@reach/router';

export function getOrganizationEmployeesSimple(
  organizationId: string,
  params?: any,
) {
  return (dispatch: Dispatch) => {
    // Dispatch the api call request.
    dispatch(requestStarted());

    dispatch({
      type: API_CALL,
      payload: {
        call: `organizations/${organizationId}/employees-simple`,
        method: 'get',
        data: params || {},
        success: (response: any) => {
          if (response.type === 'error') {
            if (response.payload.unknownOrganization === true) {
              navigate(`/lookup`);
              return;
            }

            enqueueSnackbarHandler(dispatch, response.payload.payload, 'error');
            dispatch(requestFailed(response));
          } else {
            dispatch(requestSuccess(response));
          }
        },
        fail: (error: any) => {
          if (_.get(error.payload, 'unknownOrganization', false) === true) {
            navigate(`/lookup`);
            return;
          }

          enqueueSnackbarHandler(dispatch, error.payload, 'error');
          dispatch(requestFailed(error));
        },
      },
    });

    return null;
  };
}

export function emptyResults() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ACTION_EMPTY_COMPANY_EMPLOYEES_SIMPLE,
      payload: null,
    });
  };
}

function requestStarted() {
  return {
    type: ACTION_GET_COMPANY_EMPLOYEES_SIMPLE,
    payload: null,
  };
}

function requestSuccess(responseJSON: object) {
  return {
    type: ACTION_GET_COMPANY_EMPLOYEES_SIMPLE_SUCCESS,
    payload: responseJSON,
  };
}

function requestFailed(responseJSON: object) {
  return {
    type: ACTION_GET_COMPANY_EMPLOYEES_SIMPLE_FAILURE,
    payload: responseJSON,
  };
}

exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".components-Lookup-Body-styles___bodyPaper--3BKp- {\n  margin: 40px;\n}\n\n.components-Lookup-Body-styles___notFound--3tdum {\n  text-align: center;\n  margin: 20px;\n  font-size: 18px;\n  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;\n}\n\n.components-Lookup-Body-styles___tabs--3P9-l,\n.components-Lookup-Body-styles___tab--1qYM5 {\n  text-transform: initial !important;\n}\n\n.components-Lookup-Body-styles___tabs--3P9-l {\n  border-bottom: 1px solid #c6c8cc;\n  margin-bottom: 40px;\n}\n", "", {"version":3,"sources":["/tmp/build_c950a000/src/components/Lookup/Body/styles.pcss"],"names":[],"mappings":"AAAA;EACE,aAAa;CACd;;AAED;EACE,mBAAmB;EACnB,aAAa;EACb,gBAAgB;EAChB,wDAAwD;CACzD;;AAED;;EAEE,mCAAmC;CACpC;;AAED;EACE,iCAAiC;EACjC,oBAAoB;CACrB","file":"styles.pcss","sourcesContent":[".bodyPaper {\n  margin: 40px;\n}\n\n.notFound {\n  text-align: center;\n  margin: 20px;\n  font-size: 18px;\n  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;\n}\n\n.tabs,\n.tab {\n  text-transform: initial !important;\n}\n\n.tabs {\n  border-bottom: 1px solid #c6c8cc;\n  margin-bottom: 40px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"bodyPaper": "components-Lookup-Body-styles___bodyPaper--3BKp-",
	"notFound": "components-Lookup-Body-styles___notFound--3tdum",
	"tabs": "components-Lookup-Body-styles___tabs--3P9-l",
	"tab": "components-Lookup-Body-styles___tab--1qYM5"
};
import * as React from 'react';
import cx from 'classnames';
import Sidebar from '../../components/Sidebar/Sidebar';
const styles = require('./styles.pcss');

type Props = {
  children?: React.ReactNode;
  full?: boolean;
};

const LayoutDashboard = ({ children, ...props }: Props) => {
  let className = styles.content;
  if (props.full && props.full === true) {
    className = cx(styles.content, styles.full);
  }

  return (
    <div className={styles.layout}>
      <Sidebar />
      <div className={className}>{children}</div>
    </div>
  );
};

export default LayoutDashboard;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { removeSnackbar } from '../../actions/Status';
import { bindActionCreators, Dispatch } from 'redux';
import { ReduxState } from 'reducers/types';

class Notifier extends Component {
  displayed: any = [];
  props: any;

  storeDisplayed = (id: any) => {
    this.displayed = [...this.displayed, id];
  };

  shouldComponentUpdate({ notifications: newSnacks = [] }: any) {
    const { notifications: currentSnacks } = this.props;
    let notExists = false;
    for (let i = 0; i < newSnacks.length; i++) {
      if (notExists) continue;
      notExists =
        notExists ||
        !currentSnacks.filter(({ id }: any) => newSnacks[i].id === id).length;
    }
    return notExists;
  }

  componentDidUpdate() {
    const { notifications = [] } = this.props;

    notifications.forEach((notification: any) => {
      // Do nothing if snackbar is already displayed
      if (this.displayed.includes(notification.id)) return;
      // Display snackbar using notistack
      this.props.enqueueSnackbar(notification.message, notification.options);
      // Keep track of snackbars that we've displayed
      this.storeDisplayed(notification.id);
      // Dispatch action to remove snackbar from redux store
      this.props.removeSnackbar(notification.id);
    });
  }

  render() {
    return null;
  }
}

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {
    notifications: state.notifications.notifications,
  };
}

// Wire up redux dispatch functions
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      removeSnackbar,
    },
    dispatch,
  );
}

// Connect it to Redux
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withSnackbar(Notifier));

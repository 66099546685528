import * as React from 'react';
import _ from 'lodash-es';
import moment from 'moment';
import cx from 'classnames';
import { navigate } from '@reach/router';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Button from '@material-ui/core/Button';
import { KeyboardArrowDown } from '@material-ui/icons';
import EditUserPopup from 'components/Popup/EditUserPopup';
import UserProfileTravelerInformation from './UserProfileTravelerInformation';

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ReduxState } from 'reducers/types';
import { getSingle as getTraveler } from 'actions/Travelers/GetSingle';
import { resetSaveSuccess } from 'actions/Travelers/ResetSaveSuccess';
import {
  get as getTravelers,
  Params,
  emptyResults,
} from 'actions/Travelers/Get';

const styles = require('./styles.pcss');

import { Traveler } from 'reducers/types';

type Props = {
  traveler?: Traveler;
  userId?: string;
  newTraveler?: boolean;
  resetSaveSuccess: any;
  saveSucceeded: any;
  onTravelerUpdate?: any;
  readOnly?: boolean;
  travelers: any;
  labelWidth?: any;
  getTraveler: Function;
  getTravelers: Function;
  emptyResults: Function;
};

type State = {
  traveler: any;
  open: any;
  openEditPopup: any;
  deleted: boolean;
};

const _getNullSafe = (val: any, props: any, defaultVal: any) => {
  const v = _.get(val, props, defaultVal);
  return _.isNull(v) ? defaultVal : v;
};

class PersonalDetails extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      open: false,
      traveler: {},
      openEditPopup: false,
      deleted: false,
    };
  }

  componentWillReceiveProps = (nextProps: Props) => {
    if (nextProps.saveSucceeded === true) {
      this.setState({
        openEditPopup: false,
      });

      if (typeof this.props.onTravelerUpdate === 'function') {
        this.props.onTravelerUpdate();
      }
    }

    this.setState({
      traveler: _getNullSafe(nextProps, 'traveler.id', ''),
    });

    if (this.state.deleted) {
      if (_.get(nextProps.travelers, 'travelers', []).length > 0) {
        let traveler = _.find(
          _.get(nextProps.travelers, 'travelers', []),
          (t) => t.isPrimary === true,
        );

        if (traveler !== undefined && traveler.hasOwnProperty('id')) {
          this.props.getTraveler(traveler.id);
        } else {
          this.props.getTraveler(
            _.get(nextProps.travelers, 'travelers[0].id', ''),
          );
        }
      }

      this.setState({
        deleted: false,
      });
    }
  };

  onChangeTraveler = (event: any, id: any) => {
    this.props.getTraveler(event.target.value);

    this.setState({
      traveler: event.target.value,
    });
  };

  onOpen = (e: any) => {
    this.setState({
      open: true,
    });
  };

  onClose = (e: any) => {
    this.setState({
      open: false,
    });
  };

  onCloseEditPopup = (e: any) => {
    this.setState({
      openEditPopup: false,
    });
  };

  onOpenEditPopup = (e: any) => {
    this.setState({
      openEditPopup: true,
    });
  };

  renderTravalersDropdown = () => {
    let travelers = _.get(this.props.travelers, 'travelers', []);

    if (travelers.length > 1) {
      let labelWidth =
        this.state.traveler !== '' ? this.props.labelWidth || 55 : 0;
      let shrink = this.state.traveler !== '' ? true : false;

      return (
        <div
          className={cx(
            styles.inputContainer,
            styles.inputContainerTravelerSelection,
          )}
        >
          <div className={cx(styles.line)}>
            <div className={styles.selectTraveler}>Select Traveler: </div>
            <FormControl
              margin="normal"
              className={cx(styles.selectWrapper, styles.noBorder)}
            >
              <Select
                classes={{ select: styles.secondarySelectInput }}
                className={
                  this.state.open
                    ? cx(styles.secondarySelect, styles.selected)
                    : cx(styles.secondarySelect, styles.select)
                }
                id="input-traveler"
                value={this.state.traveler}
                onChange={this.onChangeTraveler}
                onOpen={this.onOpen}
                onClose={this.onClose}
                open={this.state.open}
                IconComponent={() => (
                  <KeyboardArrowDown className={styles.iconStyleProfile} />
                )}
                input={
                  <OutlinedInput
                    className={styles.secondarySelectInput}
                    fullWidth
                    labelWidth={labelWidth}
                    name="traveler"
                  />
                }
                MenuProps={{
                  style: { marginTop: 4, boxShadow: 'none' },
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
              >
                {travelers.map(this.renderTravelerOption)}
              </Select>
            </FormControl>
          </div>
        </div>
      );
    }

    return null;
  };

  renderTravelerOption = (traveler: any, i: number) => {
    return (
      <MenuItem
        key={i}
        value={traveler.id}
        className={styles.travelersOption}
      >
        {traveler.firstName} {traveler.lastName}
      </MenuItem>
    );
  };

  onClickAddUserPopupOpen = () => {
    this.setState({
      openEditPopup: true,
    });
  };

  onClickAddUserPopupClose = () => {
    this.setState({
      openEditPopup: false,
    });
  };

  onDeleteTravelerProfile = () => {
    const params: Params = {
      userId: this.props.userId,
    };
    this.props.emptyResults();
    this.props.getTravelers(params);

    this.setState({
      traveler: '',
      deleted: true,
    });
  };

  renderDetails = () => {
    if (
      this.props.travelers.travelers.length > 0 &&
      this.state.traveler === ''
    ) {
      let traveler = _.find(
        this.props.travelers.travelers,
        (t) => t.isPrimary === true,
      );

      if (traveler !== undefined && traveler.hasOwnProperty('id')) {
        this.setState({
          traveler: traveler.id,
        });
      }

      if (traveler == undefined && this.props.travelers.travelers.length > 0) {
        this.setState({
          traveler: _.get(this.props.travelers, 'travelers[0].id', ''),
        });

        this.props.getTraveler(
          _.get(this.props.travelers, 'travelers[0].id', ''),
        );
      }
    }
    let addtraveler = this.props.travelers.travelers.length < 1 ? true : false;

    return (
      <>
        <EditUserPopup
          open={this.state.openEditPopup}
          onClose={this.onCloseEditPopup}
          onClickAddUserPopupClose={this.onClickAddUserPopupClose}
          traveler={_.get(this.props, 'traveler', undefined)}
          addNewTraveler={addtraveler}
          userId={this.props.userId}
          onTravelerProfileDelete={this.onDeleteTravelerProfile}
        />

        <UserProfileTravelerInformation
          traveler={_.get(this.props, 'traveler', {})}
          onClickAddUserPopupOpen={this.onClickAddUserPopupOpen}
          addTraveler={addtraveler}
        />
      </>
    );
  };

  render() {
    let traveler = _.find(
      this.props.travelers.travelers,
      (t) => t.isPrimary === true,
    );
    let isPrimary = false;
    if (this.props.travelers.travelers.length === 1 && traveler === undefined) {
      isPrimary = true;
    }

    return (
      <>
        <div>
          {this.props.travelers.travelers.length > 0 &&
            !isPrimary &&
            this.renderTravalersDropdown()}
        </div>
        <Paper className={styles.paper}>{this.renderDetails()}</Paper>
      </>
    );
  }
}

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {
    travelerErrors: state.traveler.errors,
    saveSucceeded: state.traveler.saveSucceeded,
    travelers: state.travelers,
  };
}

// Wire up redux dispatch functions
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      resetSaveSuccess,
      getTraveler,
      getTravelers,
      emptyResults,
    },
    dispatch,
  );
}

// Connect it to Redux
export default connect(mapStateToProps, mapDispatchToProps)(PersonalDetails);

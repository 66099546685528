import * as React from 'react';
import classNames from 'classnames';

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const styles = require('./styles.pcss');

const TransactionTableHead = () => (
  <TableHead className={styles.tableHead}>
    <TableRow>
      <TableCell className={styles.tableHeadCell}>Date</TableCell>
      <TableCell
        className={classNames(styles.tableHeadCell, styles.description)}
      >
        Description
      </TableCell>
      <TableCell className={styles.tableHeadCell}>Merchant</TableCell>
      <TableCell className={styles.tableHeadCell}>FOP</TableCell>
      <TableCell className={styles.tableHeadCell}>Amount</TableCell>
      <TableCell className={styles.tableHeadCell}>Status</TableCell>
      <TableCell className={styles.tableHeadCell}></TableCell>
      <TableCell className={styles.tableHeadCell}>Username</TableCell>
    </TableRow>
  </TableHead>
);

export default TransactionTableHead;

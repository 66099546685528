/**
 * GET Travelers Action
 */

import {
  ACTION_GET_TRAVELERS,
  ACTION_GET_TRAVELERS_SUCCESS,
  ACTION_GET_TRAVELERS_FAILURE,
  ACTION_EMPTY_TRAVELERS,
  API_CALL,
} from '../../configs/ActionTypes';

import { Dispatch } from 'redux';

import { enqueueSnackbarHandler } from '../Status';

import _ from 'lodash-es';

export type Params = {
  search?: string;
  userId?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
};

/**
 * POST Travelers Request Action
 */
export function get(params?: any) {
  return (dispatch: Dispatch, getState: Function) => {
    //Dispatch the api call request.

    if (params && typeof params == 'object' && params.hasOwnProperty('email')) {
      _.set(
        params,
        'email',
        _.get(params, 'email', '').replace(/[.*+?^${}()|[\]\\]/g, '\\$&'),
      );
    }

    dispatch({
      type: API_CALL,
      payload: {
        call: 'travelers',
        method: 'get',
        data: params || {},
        success: (response: any) => {
          if (response.type === 'error') {
            enqueueSnackbarHandler(dispatch, response.payload.payload, 'error');
            dispatch(requestFailed(response));
          } else {
            dispatch(requestSuccess(response));
          }
        },
        fail: (error: any) => {
          enqueueSnackbarHandler(dispatch, error.payload, 'error');
          dispatch(requestFailed(error));
        },
      },
    });

    dispatch(requestStarted());

    return null;
  };
}

export function emptyResults() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ACTION_EMPTY_TRAVELERS,
      payload: null,
    });
  };
}

export function requestStarted() {
  return {
    type: ACTION_GET_TRAVELERS,
    payload: null,
  };
}

export function requestSuccess(responseJSON: object) {
  return {
    type: ACTION_GET_TRAVELERS_SUCCESS,
    payload: responseJSON,
  };
}

export function requestFailed(responseJSON: object) {
  return {
    type: ACTION_GET_TRAVELERS_FAILURE,
    payload: responseJSON,
  };
}

import { enqueueSnackbarHandler } from 'actions/Status';
import {
  API_CALL,
  ACTION_GET_ORG_SETTINGS,
  ACTION_GET_ORG_SETTINGS_SUCCESS,
  ACTION_GET_ORG_SETTINGS_FAILURE,
} from '../../../configs/ActionTypes';

import { Dispatch } from 'redux';

export function getOrganizationSettings(organizationId: string) {
  return (dispatch: Dispatch) => {
    dispatch(requestStarted());

    dispatch({
      type: API_CALL,
      payload: {
        call: `organizations/${organizationId}/settings`,
        method: 'get',
        data: {},
        success: (response: any) => {
          if (response.type === 'error') {
            enqueueSnackbarHandler(dispatch, response.payload.payload, 'error');
            dispatch(requestFailed(response));
          } else {
            dispatch(requestSuccess(response));
          }
        },
        fail: (error: any) => {
          enqueueSnackbarHandler(dispatch, error.payload, 'error');
          dispatch(requestFailed(error));
        },
      },
    });

    return null;
  };
}

function requestStarted() {
  return {
    type: ACTION_GET_ORG_SETTINGS,
    payload: null,
  };
}

function requestSuccess(responseJSON: object) {
  return {
    type: ACTION_GET_ORG_SETTINGS_SUCCESS,
    payload: responseJSON,
  };
}

function requestFailed(responseJSON: object) {
  return {
    type: ACTION_GET_ORG_SETTINGS_FAILURE,
    payload: responseJSON,
  };
}

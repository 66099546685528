import {
  ACTION_PUT_USER_FRAUD_RULE,
  ACTION_PUT_USER_FRAUD_RULE_SUCCESS,
  ACTION_PUT_USER_FRAUD_RULE_FAILURE,
  API_CALL,
} from '../../configs/ActionTypes';

import { Dispatch } from 'redux';

import { enqueueSnackbarHandler } from '../Status';

export function updateUserFraudRule(data: any) {
  return (dispatch: Dispatch, getState: Function) => {
    dispatch(requestStarted());
    //Dispatch the api call request.
    dispatch({
      type: API_CALL,
      payload: {
        call: `user/setting/fraud-rule`,
        method: 'put',
        data: JSON.stringify(data),
        success: (response: object) => {
          enqueueSnackbarHandler(
            dispatch,
            { updateUserSettingSuccess: true },
            'success',
          );
          dispatch(requestSuccess(response));
        },
        fail: (error: any) => {
          enqueueSnackbarHandler(dispatch, error.payload, 'error');
          dispatch(requestFailed(error));
        },
      },
    });
    return null;
  };
}

export function requestStarted() {
  return {
    type: ACTION_PUT_USER_FRAUD_RULE,
    payload: null,
  };
}

export function requestSuccess(responseJSON: object) {
  return {
    type: ACTION_PUT_USER_FRAUD_RULE_SUCCESS,
    payload: responseJSON,
  };
}

export function requestFailed(responseJSON: object) {
  return {
    type: ACTION_PUT_USER_FRAUD_RULE_FAILURE,
    payload: responseJSON,
  };
}

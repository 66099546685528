import _ from 'lodash-es';

import {
  ACTION_GET_CREDITCARDS,
  ACTION_GET_CREDITCARDS_SUCCESS,
  ACTION_GET_CREDITCARDS_FAILURE,
  ACTION_EMPTY_CREDITCARDS,
  ACTION_RESET_GET_CREDIT_CARDS,
} from '../configs/ActionTypes';

import { QUERY_LIMIT } from '../configs/General';

const initialState = {
  creditCardList: [],
  isLoading: false,
  success: null,
  loadedAll: false,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_GET_CREDITCARDS_SUCCESS: {
      let creditCards: any = _.clone(state.creditCardList);

      let loadedAll = state.loadedAll;
      const payload = action.payload.payload;

      if (payload.length < QUERY_LIMIT) {
        loadedAll = true;
      }

      _.each(payload, (u) => {
        creditCards.push(u);
      });

      return {
        ...state,
        creditCardList: creditCards,
        isLoading: false,
        loadedAll: loadedAll,
        success: true,
      };

      break;
    }

    case ACTION_GET_CREDITCARDS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        success: false,
      };

      break;
    }
    case ACTION_GET_CREDITCARDS: {
      return {
        ...state,
        isLoading: true,
      };

      break;
    }

    case ACTION_RESET_GET_CREDIT_CARDS: {
      return initialState;

      break;
    }

    default: {
      return state;
    }
  }
}

import React from 'react';

type Props = {
  width: number;
  height: number;
};

const SvgLockIcon = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 15.9514C0 7.14226 7.162 0 16 0C24.836 0 32 7.14027 32 15.9514C32 24.7606 24.838 31.9029 16 31.9029C7.164 31.9029 0 24.7626 0 15.9514Z"
        fill="#1B2432"
      />
      <g clipPath="url(#clip0_1016_5442)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.0002 8.7688C13.4228 8.7688 11.3335 10.8581 11.3335 13.4355V15.7688H12.5002V13.4355C12.5002 11.5025 14.0672 9.93547 16.0002 9.93547C17.9332 9.93547 19.5002 11.5025 19.5002 13.4355V15.7688H20.6668V13.4355C20.6668 10.8581 18.5775 8.7688 16.0002 8.7688Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.8332 22.4772H10.1665V15.4772H21.8332V22.4772Z"
          stroke="white"
          stroke-width="1.5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.1665 22.4772H21.8332V15.4772H10.1665V22.4772ZM15.2063 19.7232C15.2063 20.1604 15.5636 20.5182 16.0001 20.5182C16.4367 20.5182 16.794 20.1604 16.794 19.7232V17.4972C16.794 17.0599 16.4367 16.7022 16.0001 16.7022C15.5636 16.7022 15.2063 17.0599 15.2063 17.4972V19.7232Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1016_5442">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(9 8.7688)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgLockIcon;

/**
 * GET BOOKINGS Action
 */

import {
  ACTION_BOOKINGS_SEND_CONFIRMATION,
  ACTION_BOOKINGS_SEND_CONFIRMATION_SUCCESS,
  ACTION_BOOKINGS_SEND_CONFIRMATION_FAILURE,
  API_CALL,
} from '../../configs/ActionTypes';

import { track } from '../../analytics/analytics';
import { enqueueSnackbarHandler } from '../Status';
import { Dispatch } from 'redux';
import _ from 'lodash-es';

/**
 * POST Users Request Action
 */
export function sendEmail(bookingId: any, type: any, additionalEmails: string) {
  return (dispatch: Dispatch, getState: Function) => {
    dispatch(requestStarted());
    //Dispatch the api call request.
    dispatch({
      type: API_CALL,
      payload: {
        call: `bookings/sendemail/${bookingId}`,
        method: 'get',
        data: {
          type,
          additionalEmails,
        },
        success: (response: any) => {
          if (response.type === 'error') {
            dispatch(requestFailed(response));
            _.get(response.payload.payload, 'userPermissionError', false)
              ? enqueueSnackbarHandler(
                  dispatch,
                  response.payload.payload,
                  'error',
                )
              : enqueueSnackbarHandler(
                  dispatch,
                  { bookingConfirmationSendFailed: true },
                  'error',
                );
          } else {
            dispatch(requestSuccess(response));
            handleTrack(type, additionalEmails != '');
            enqueueSnackbarHandler(
              dispatch,
              { bookingConfirmationSent: true },
              'success',
            );
          }
        },
        fail: (error: any) => {
          _.get(error.payload, 'userPermissionError', false)
            ? enqueueSnackbarHandler(dispatch, error.payload, 'error')
            : enqueueSnackbarHandler(
                dispatch,
                { bookingConfirmationSendFailed: true },
                'error',
              );
          dispatch(requestFailed(error));
        },
      },
    });

    return null;
  };
}

export function requestStarted() {
  return {
    type: ACTION_BOOKINGS_SEND_CONFIRMATION,
    payload: null,
  };
}

export function requestSuccess(responseJSON: any) {
  return {
    type: ACTION_BOOKINGS_SEND_CONFIRMATION_SUCCESS,
    payload: responseJSON,
  };
}

export function requestFailed(responseJSON: any) {
  return {
    type: ACTION_BOOKINGS_SEND_CONFIRMATION_FAILURE,
    payload: responseJSON,
  };
}

function handleTrack(type: any, additionalEmailsAdded: any) {
  if (type.trim !== '' && typeof type == 'string') {
    let properties = {
      Product: type.concat('s'),
      'Additional Emails': additionalEmailsAdded,
    };

    track('BB_BookingEmailed', properties);
  }
}

import {
  ACTION_PUT_AGENCY,
  ACTION_PUT_AGENCY_SUCCESS,
  ACTION_PUT_AGENCY_FAILURE,
  ACTION_PUT_AGENCY_COLOR,
  API_CALL,
} from '../../configs/ActionTypes';
import { enqueueSnackbarHandler } from '../Status';

import { Dispatch } from 'redux';

import { enqueueSnackbar } from '../Status';

export function putSingle(agencyId: string, data: any, type?: any) {
  return (dispatch: Dispatch, getState: Function) => {
    // Dispatch the api call request.
    if (type == 'color') {
      dispatch(colorRequestStarted());
    } else {
      dispatch(requestStarted());
    }

    dispatch({
      type: API_CALL,
      payload: {
        call: `agencies/${agencyId}`,
        method: 'put',
        data: JSON.stringify(data),
        success: (response: any) => {
          if (response.type === 'error') {
            let errors = response.payload.payload;
            Object.keys(errors).forEach(function (item) {
              if (errors[item] == true) {
                dispatch(enqueueSnackbar(item, 'error'));
              }
            });
            dispatch(requestFailed(response));
          } else {
            enqueueSnackbarHandler(
              dispatch,
              { updateAgencySuccess: true },
              'success',
            );
            dispatch(requestSuccess(response));
          }
        },
        fail: (error: any) => {
          let errors = error.payload;
          Object.keys(errors).forEach(function (item) {
            if (errors[item] == true) {
              dispatch(enqueueSnackbar(item, 'error'));
            }
          });
          dispatch(requestFailed(error));
        },
      },
    });

    return null;
  };
}

export function requestStarted() {
  return {
    type: ACTION_PUT_AGENCY,
    payload: null,
  };
}

export function colorRequestStarted() {
  return {
    type: ACTION_PUT_AGENCY_COLOR,
    payload: null,
  };
}

export function requestSuccess(responeJSON: object) {
  return {
    type: ACTION_PUT_AGENCY_SUCCESS,
    payload: responeJSON,
  };
}

export function requestFailed(responeJSON: object) {
  return {
    type: ACTION_PUT_AGENCY_FAILURE,
    payload: responeJSON,
  };
}

export const requiredPostalCountries = [
  'Algeria',
  'Argentina',
  'Armenia',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Azores',
  'Bangladesh',
  'Belarus',
  'Belgium',
  'Bosnia',
  'Brazil',
  'Brunei',
  'Bulgaria',
  'Canada',
  'Canary Islands',
  'China',
  'Croatia',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'England',
  'Estonia',
  'Faroe Islands',
  'Finland',
  'France',
  'Georgia',
  'Germany',
  'Greece',
  'Greenland',
  'Guam',
  'Guernsey',
  'Holland',
  'Hungary',
  'India',
  'Indonesia',
  'Israel',
  'Italy',
  'Japan',
  'Jersey',
  'Kazakhstan',
  'South Korea',
  'Kosrae',
  'Kyrgyzstan',
  'Latvia',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macedonia',
  'Madagascar',
  'Madeira',
  'Malaysia',
  'Marshall Islands',
  'Martinique',
  'Mayotte',
  'Mexico',
  'Mongolia',
  'Montenegro',
  'Netherlands',
  'New Zealand',
  'Northern Ireland',
  'Norway',
  'Pakistan',
  'Philippines',
  'Poland',
  'Ponape',
  'Portugal',
  'Puerto Rico',
  'Reunion',
  'Russia',
  'Saudi Arabia',
  'Scotland',
  'Serbia',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'South Africa',
  'Spain',
  'Sri Lanka',
  'St. Barthelemy',
  'St. Croix',
  'St. John',
  'St. Thomas',
  'Sweden',
  'Switzerland',
  'Taiwan',
  'Tajikistan',
  'Thailand',
  'Truk',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'U.S. Virgin Islands',
  'Ukraine',
  'United Kingdom',
  'United States',
  'Uruguay',
  'Uzbekistan',
  'Vatican City State',
  'Vietnam',
  'Wales',
  'Yap',
];

export const requiredPostalCountriesCode = [
  'DZ',
  'AR',
  'AM',
  'AU',
  'AT',
  'AZ',
  'A2',
  'BD',
  'BY',
  'BE',
  'BA',
  'BR',
  'BN',
  'BG',
  'CA',
  'IC',
  'CN',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EN',
  'EE',
  'FO',
  'FI',
  'FR',
  'GE',
  'DE',
  'GR',
  'GL',
  'GU',
  'GG',
  'HO',
  'HU',
  'IN',
  'ID',
  'IL',
  'IT',
  'JP',
  'JE',
  'KZ',
  'KR',
  'KO',
  'KG',
  'LV',
  'LI',
  'LT',
  'LU',
  'MK',
  'MG',
  'M3',
  'MY',
  'MH',
  'MQ',
  'YT',
  'MX',
  'MN',
  'ME',
  'NL',
  'NZ',
  'NB',
  'NO',
  'PK',
  'PH',
  'PL',
  'PO',
  'PT',
  'PR',
  'RE',
  'RU',
  'SA',
  'SF',
  'CS',
  'SG',
  'SK',
  'SI',
  'ZA',
  'ES',
  'LK',
  'NT',
  'SX',
  'UV',
  'VL',
  'SE',
  'CH',
  'TW',
  'TJ',
  'TH',
  'TU',
  'TN',
  'TR',
  'TM',
  'VI',
  'UA',
  'GB',
  'US',
  'UY',
  'UZ',
  'VA',
  'VN',
  'WL',
  'YA',
];

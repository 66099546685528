import * as React from 'react';
import LayoutDashboard from 'components/Layout/Dashboard';
import Header from 'components/Lookup/Header/Header';

import UsersBody from 'components/Lookup/Body/Users';
import CompaniesBody from 'components/Lookup/Body/Companies';
import CreditCardBody from 'components/Lookup/Body/CreditCard';
import BookingsBody from 'components/Lookup/Body/Bookings';
import TravelersBody from 'components/Lookup/Body/Travelers';
import AgentiesBody from 'components/Lookup/Body/Agencies';

import { track } from '../../analytics/analytics';
import _ from 'lodash-es';

import { connect } from 'react-redux';

import { get as getUsers, emptyResults as emptyUsers } from 'actions/Users/Get';

import {
  get as getCompanies,
  emptyResults as emptyCompanies,
} from 'actions/Companies/Get';

import {
  get as getCreditCards,
  emptyResults as emptyCreditCards,
} from 'actions/CreditCards/GetCreditCards';

import {
  emptyResults as emptyBookings,
  get as getBookings,
} from 'actions/Bookings/Get';

import {
  emptyResults as emptyTravelers,
  get as getTravelers,
} from 'actions/Travelers/Get';

import {
  get as getAgencies,
  emptyResults as emptyAgencies,
} from 'actions/Agencies/Get';

import { bindActionCreators, Dispatch } from 'redux';
import { ReduxState, User } from '../../reducers/types';

import { QUERY_LIMIT, QUERY_LIMIT_25 } from '../../configs/General';

class Lookup extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      typeOfRecord: '',
      searchParams: {},
    };
  }

  handleTrack = (type: any, data: any) => {
    let properties = {
      Record: type,
      ...data,
    };

    track('BB_RecordSearched', properties);
  };

  searchUsers = (searchParams: any) => {
    const queryParams: any = {
      limit: QUERY_LIMIT,
      skip: 0,
      ...searchParams,
    };
    let properties = {
      'Name Searched': true,
      'Email Searched': true,
    };
    if (
      _.get(searchParams, 'firstName', '') === '' &&
      _.get(searchParams, 'lastName', '') === ''
    ) {
      properties['Name Searched'] = false;
    }

    if (_.get(searchParams, 'email', '') === '') {
      properties['Email Searched'] = false;
    }

    this.handleTrack('user', properties);

    this.props.getUsers(queryParams);
  };

  searchTravelers = (searchParams: any) => {
    const queryParams: any = {
      limit: QUERY_LIMIT,
      skip: 0,
      ...searchParams,
    };
    let properties = {
      'Name Searched': true,
      'Email Searched': true,
    };
    if (
      _.get(searchParams, 'firstName', '') === '' &&
      _.get(searchParams, 'lastName', '') === ''
    ) {
      properties['Name Searched'] = false;
    }

    if (_.get(searchParams, 'email', '') === '') {
      properties['Email Searched'] = false;
    }

    this.handleTrack('traveler', properties);

    this.props.getTravelers(queryParams);
  };

  searchCompanies = (searchParams: any) => {
    const queryParams: any = {
      limit: QUERY_LIMIT,
      skip: 0,
      ...searchParams,
    };

    let properties = {
      'Name Searched': true,
      'Domain Searched': true,
    };

    if (_.get(searchParams, 'name', '') === '') {
      properties['Name Searched'] = false;
    }

    if (_.get(searchParams, 'domain', '') === '') {
      properties['Domain Searched'] = false;
    }

    this.handleTrack('company', properties);

    this.props.getCompanies(queryParams);
  };

  searchAgencies = (searchParams: any) => {
    const queryParams: any = {
      limit: QUERY_LIMIT,
      skip: 0,
      ...searchParams,
    };

    this.props.getAgencies(queryParams);
  };

  getCreditCards = (searchParams: any) => {
    const queryParams: any = {
      limit: QUERY_LIMIT,
      skip: 0,
      ...searchParams,
    };

    let properties = {
      'Name Searched': true,
      'Last 4 Searched': true,
    };

    if (_.get(searchParams, 'name', '') === '') {
      properties['Name Searched'] = false;
    }

    if (_.get(searchParams, 'last4', '') === '') {
      properties['Last 4 Searched'] = false;
    }

    this.handleTrack('credit card', properties);

    this.props.getCreditCards(queryParams);
  };

  searchBooking = (searchParams: any, type: any, order?: string) => {
    let params: any = {
      type: type === 'booking' ? 'flight' : type,
      limit: QUERY_LIMIT_25,
      search: '',
      skip: 0,
      sort: '',
      order: order,
      ...searchParams,
    };

    let properties: any = {
      'Type Searched': type === 'booking' ? '' : type,
      'TB Confirmation Searched': true,
      'Confirmation Searched': true,
    };

    switch (type) {
      case 'flight':
      case 'car':
      case 'booking': {
        if (_.get(searchParams, 'pnr', '') === '') {
          properties['TB Confirmation Searched'] = false;
        }
        break;
      }

      case 'hotel': {
        if (_.get(searchParams, 'itineraryId', '') === '') {
          properties['TB Confirmation Searched'] = false;
        }

        properties['Provider Searched'] = true;

        if (_.get(searchParams, 'provider', '') === '') {
          properties['Provider Searched'] = false;
        }
        break;
      }
    }

    if (_.get(searchParams, 'confirmationNumber', '') === '') {
      properties['Confirmation Searched'] = false;
    }

    this.handleTrack('booking', properties);

    this.props.getBookings(params);
  };

  searchRecord = (data: any) => {
    this._emptyRecords();

    this.setState({
      typeOfRecord: data.type,
      searchParams: data.searchParams,
    });

    switch (data.type) {
      case 'user': {
        this.searchUsers(data.searchParams);
        break;
      }
      case 'traveler': {
        this.searchTravelers(data.searchParams);
        break;
      }
      case 'company': {
        this.searchCompanies(data.searchParams);
        break;
      }
      case 'creditcard': {
        this.getCreditCards(data.searchParams);
        break;
      }
      case 'flight': {
        this.searchBooking(data.searchParams, data.type, 'departureDate ASC');
        break;
      }
      case 'car': {
        this.searchBooking(data.searchParams, data.type, 'pickupDateTime ASC');
        break;
      }
      case 'hotel': {
        this.searchBooking(data.searchParams, data.type, 'arrivalDate ASC');
        break;
      }
      case 'booking': {
        this.searchBooking(data.searchParams, data.type, 'created DESC');
        break;
      }
      case 'agency': {
        this.searchAgencies(data.searchParams);
        break;
      }
    }
  };

  renderRecordBody = () => {
    switch (this.state.typeOfRecord) {
      case 'user': {
        return <UsersBody searchParams={this.state.searchParams} />;
        break;
      }
      case 'traveler': {
        return <TravelersBody searchParams={this.state.searchParams} />;
        break;
      }
      case 'company': {
        return <CompaniesBody searchParams={this.state.searchParams} />;
        break;
      }
      case 'creditcard': {
        return <CreditCardBody searchParams={this.state.searchParams} />;
        break;
      }
      case 'car': {
        return (
          <BookingsBody
            type={'car'}
            searchParams={this.state.searchParams}
          />
        );
        break;
      }
      case 'flight': {
        return (
          <BookingsBody
            type={'flight'}
            searchParams={this.state.searchParams}
          />
        );

        break;
      }
      case 'hotel': {
        return (
          <BookingsBody
            type={'hotel'}
            searchParams={this.state.searchParams}
          />
        );
        break;
      }
      case 'booking': {
        return (
          <BookingsBody
            type={'booking'}
            searchParams={this.state.searchParams}
            tabs={true}
          />
        );
        break;
      }
      case 'agency': {
        return (
          <AgentiesBody
            type={'agency'}
            searchParams={this.state.searchParams}
          />
        );
        break;
      }

      default: {
        return null;
      }
    }
  };

  onChangeRecordType = () => {
    this._emptyRecords();

    this.setState({
      typeOfRecord: '',
    });
  };

  _emptyRecords = () => {
    this.props.emptyUsers();
    this.props.emptyCompanies();
    this.props.emptyCreditCards();
    this.props.emptyBookings();
    this.props.emptyTravelers();
    this.props.emptyAgencies();
  };

  render() {
    return (
      <LayoutDashboard full>
        <Header
          searchRecord={this.searchRecord}
          onChangeRecordType={this.onChangeRecordType}
        />
        {this.renderRecordBody()}
      </LayoutDashboard>
    );
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getUsers,
      emptyUsers,
      getCompanies,
      emptyCompanies,
      getCreditCards,
      emptyCreditCards,
      emptyBookings,
      getBookings,
      emptyTravelers,
      getTravelers,
      getAgencies,
      emptyAgencies,
    },
    dispatch,
  );
}

function mapStateToProps(state: ReduxState) {
  return {
    users: state.users.users,
    // isLoadingUsers: state.users.isLoading,
    // loadedAllUsers: state.users.loadedAll,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Lookup);

import {
  ACTION_SEARCH_HOTELS,
  ACTION_SEARCH_HOTELS_SUCCESS,
  ACTION_SEARCH_HOTELS_FAILURE,
  ACTION_EMPTY_HOTELS,
  ACTION_FILTER_HOTELS_SUCCESS,
} from '../configs/ActionTypes';

import { Hotel } from './types';

import _ from 'lodash-es';

const initialState = {
  filteredHotels: [],
  hotels: [],
  searchData: [],
  isLoading: false,
  wasSubmitted: false,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_SEARCH_HOTELS: {
      return {
        ...state,
        isLoading: true,
        wasSubmitted: true,
      };
    }

    case ACTION_EMPTY_HOTELS: {
      return initialState;
    }

    case ACTION_SEARCH_HOTELS_SUCCESS: {
      let hotels: Array<Hotel> = action.payload.payload.hotels;

      return {
        ...state,
        hotels,
        searchData: action.payload.payload.searchData,
        filteredHotels: hotels,
        isLoading: false,
      };
    }

    case ACTION_SEARCH_HOTELS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case ACTION_FILTER_HOTELS_SUCCESS: {
      let review = action.payload.review;
      let rating = action.payload.rating;
      let min_price = action.payload.price.min;
      let max_price = action.payload.price.max;

      let filteredHotels = _.clone(state.hotels);

      _.each(filteredHotels, (hotel) => {
        if (review !== null) {
          _.remove(
            filteredHotels,
            (fh: any) => fh.userRating < parseInt(review),
          );
        }

        if (min_price !== null && max_price !== null) {
          _.remove(
            filteredHotels,
            (fh: any) => fh.price < min_price || fh.price > max_price,
          );
        }

        if (rating.length > 0) {
          _.remove(
            filteredHotels,
            (fh: any) => !_.includes(rating, Math.floor(fh.starRating)),
          );
        }
      });

      return {
        ...state,
        filteredHotels,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
}

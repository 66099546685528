import React from 'react';

type Props = {
  width: number;
  height: number;
};

const SvgAddIcon = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs></defs>
      <g
        id="Master"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Organization-List"
          transform="translate(-1269.000000, -167.000000)"
          fill="#318AFB"
        >
          <g
            id="list"
            transform="translate(280.000000, 143.000000)"
          >
            <g
              id="new"
              transform="translate(985.000000, 20.000000)"
            >
              <path
                d="M14.5741758,10.4573333 L13.0310943,12 L14.5741758,13.5426667 C14.8582521,13.8266667 14.8582521,14.2873333 14.5741758,14.5713333 C14.2900994,14.8553333 13.8293089,14.8553333 13.5452325,14.5713333 L12.002151,13.0286667 L10.4590696,14.5713333 C10.1749932,14.8553333 9.71420269,14.8553333 9.43012633,14.5713333 C9.14604997,14.2873333 9.14604997,13.8266667 9.43012633,13.5426667 L10.9732078,12 L9.43012633,10.4573333 C9.14604997,10.1733333 9.14604997,9.71266667 9.43012633,9.42866667 C9.71420269,9.14466667 10.1743264,9.14466667 10.4590696,9.42866667 L12.002151,10.9713333 L13.5452325,9.42866667 C13.8293089,9.14466667 14.2900994,9.14466667 14.5741758,9.42866667 C14.8582521,9.71266667 14.8582521,10.1733333 14.5741758,10.4573333 M12.002151,4 C7.58296313,4 4,7.582 4,12 C4,16.418 7.58296313,20 12.002151,20 C16.421339,20 20.0043021,16.418 20.0043021,12 C20.0043021,7.582 16.421339,4 12.002151,4"
                id="Plus-Circle"
                transform="translate(12.002151, 12.000000) rotate(-135.000000) translate(-12.002151, -12.000000) "
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgAddIcon;

import * as React from 'react';
import classNames from 'classnames';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import SvgIconPencil from '../../../../static/icons/svgIconPencil';
import _ from 'lodash-es';

const styles = require('./styles.pcss');

type Props = {
  user: any;
  clickEdit?: any;
  showEdit?: any;
};

const displayEdit = (clickEdit: any) => {
  return (
    <TableCell
      onClick={() => {
        clickEdit;
      }}
      key={'edit'}
      className={classNames(styles.tableRowCell, styles.editCell)}
    >
      <div onClick={clickEdit}>
        <SvgIconPencil
          width={16}
          height={16}
        />
      </div>
    </TableCell>
  );
};

const renderRole = (user: any) => {
  if (_.get(user, 'roleFriendlyName', '')) {
    return _.get(user, 'roleFriendlyName', '');
  }

  return _.get(user, 'roleName', '');
};

const TravelerTableRow = ({ user, clickEdit, showEdit }: Props) => (
  <TableRow
    className={classNames(
      styles.clickable,
      styles.tableRow,
      styles.bbusersTable,
    )}
    key={user.id}
  >
    <TableCell className={classNames(styles.cellHide)}></TableCell>
    <TableCell
      className={classNames(styles.tableRowCell, styles.tableCellName)}
      scope="row"
    >
      {user.name}
    </TableCell>
    <TableCell
      className={classNames(styles.tableRowCell, styles.tableRowCellEmail)}
    >
      {user.email}
    </TableCell>
    <TableCell
      className={classNames(styles.tableRowCell, styles.tableRowCellRole)}
    >
      {renderRole(user)}
    </TableCell>
    {renderStatus(user)}
    {showEdit && displayEdit(clickEdit)}
    <TableCell className={classNames(styles.cellHide)}></TableCell>
  </TableRow>
);

const renderStatus = (user: any) => {
  if (user.active) {
    return (
      <TableCell className={styles.tableRowCell}>
        <div className={styles.tableRowCellStatusActive}>active</div>
      </TableCell>
    );
  }

  return (
    <TableCell className={styles.tableRowCell}>
      <div className={styles.tableRowCellStatusDisabled}>disabled</div>
    </TableCell>
  );
};

export default TravelerTableRow;

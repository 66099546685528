/**
 * POST EMPLOYEE
 */
import _ from 'lodash-es';
import { Dispatch } from 'redux';

import {
  ACTION_POST_EMPLOYEE,
  ACTION_POST_EMPLOYEE_SUCCESS,
  ACTION_POST_EMPLOYEE_FAILURE,
  ACTION_RESET_EMPLOYEE_SUCCESS,
  API_CALL,
} from '../../configs/ActionTypes';
import { track } from '../../analytics/analytics';
import { enqueueSnackbarHandler } from '../Status';

export function add(params: any, organizationId: string) {
  return (dispatch: Dispatch, getState: Function) => {
    dispatch(requestStarted());

    dispatch({
      type: API_CALL,
      payload: {
        call: `organizations/${organizationId}/users`,
        method: 'post',
        data: JSON.stringify(params),
        success: (response: object) => {
          dispatch(requestSuccess(response));
          handleTrack(params);

          if (_.get(params, 'invite', false)) {
            enqueueSnackbarHandler(
              dispatch,
              { addEmployeeSuccess: true },
              'success',
            );
          } else {
            enqueueSnackbarHandler(
              dispatch,
              { inviteEmployeeSuccess: true },
              'success',
            );
          }
        },
        fail: (error: any) => {
          _.get(error.payload, 'userPermissionError', false)
            ? enqueueSnackbarHandler(dispatch, error.payload, 'error')
            : enqueueSnackbarHandler(
                dispatch,
                { addEmployeeFailed: true },
                'error',
              );
          dispatch(requestFailed());
        },
      },
    });

    return null;
  };
}

export function requestStarted() {
  return {
    type: ACTION_POST_EMPLOYEE,
    payload: null,
  };
}

export function resetSaveEmployee() {
  return (dispatch: Dispatch, getState: Function) => {
    // Dispatch the api call request.
    dispatch({
      type: ACTION_RESET_EMPLOYEE_SUCCESS,
      payload: null,
    });
  };
}

function requestSuccess(responeJSON: object) {
  return {
    type: ACTION_POST_EMPLOYEE_SUCCESS,
    payload: responeJSON,
  };
}

function requestFailed() {
  return {
    type: ACTION_POST_EMPLOYEE_FAILURE,
    payload: null,
  };
}

function handleTrack(params: any) {
  const admin = _.includes(_.get(params, 'permissions', []), 'orgAdmin');
  const finance =
    _.includes(_.get(params, 'permissions', []), 'finance') ||
    _.includes(_.get(params, 'permissions', []), 'orgFinance');

  let properties = {
    'Is Admin': admin,
    'Is Finance': finance,
    Invited: _.get(params, 'invite', false),
  };

  track('BB_UserCreated', properties);
}

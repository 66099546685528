import _ from 'lodash-es';

import {
  ACTION_PUT_USER_ORG_ANALYTICS,
  ACTION_PUT_USER_ORG_ANALYTICS_SUCCESS,
  ACTION_PUT_USER_ORG_ANALYTICS_FAILURE,
  ACTION_PUT_USER_ORG_ANALYTICS_RESET,
  API_CALL,
} from '../../configs/ActionTypes';
import { enqueueSnackbarHandler } from '../Status';

import { Dispatch } from 'redux';

import { enqueueSnackbar } from '../Status';

/**
 * PUT User Request Action
 */
export function putUserOrgAnalytics(userId: string, data: any) {
  return (dispatch: Dispatch, getState: Function) => {
    // Dispatch the api call request.
    dispatch(requestStarted());

    dispatch({
      type: API_CALL,
      payload: {
        call: `user/${userId}/organization-analytics`,
        method: 'put',
        data: JSON.stringify(data),
        success: (response: any) => {
          if (response.type === 'error') {
            let errors = response.payload.payload;
            Object.keys(errors).forEach(function (item) {
              if (errors[item] == true) {
                dispatch(enqueueSnackbar(item, 'error'));
              }
            });
            dispatch(requestFailed(response));
          } else {
            enqueueSnackbarHandler(
              dispatch,
              { updateUserOrgAnalytics: true },
              'success',
            );
            dispatch(requestSuccess(response));
          }
        },
        fail: (error: any) => {
          let errors = error.payload;
          Object.keys(errors).forEach(function (item) {
            if (errors[item] == true) {
              dispatch(enqueueSnackbar(item, 'error'));
            }
          });
          dispatch(requestFailed(error));
        },
      },
    });

    return null;
  };
}

export function requestStarted() {
  return {
    type: ACTION_PUT_USER_ORG_ANALYTICS,
    payload: null,
  };
}

export function requestSuccess(responeJSON: object) {
  return {
    type: ACTION_PUT_USER_ORG_ANALYTICS_SUCCESS,
    payload: responeJSON,
  };
}

export function requestFailed(responeJSON: object) {
  return {
    type: ACTION_PUT_USER_ORG_ANALYTICS_FAILURE,
    payload: responeJSON,
  };
}

export function resetResult() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ACTION_PUT_USER_ORG_ANALYTICS_RESET,
      payload: null,
    });
  };
}

import React from 'react';

type Props = {
  width: number;
  height: number;
};

const SvgIconVisa = (props: Props) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox="0 0 32 32"
    >
      <title>visa</title>
      <path
        fill="#1a1f71"
        stroke="#dddee0"
        strokeLinejoin="miter"
        strokeLinecap="butt"
        strokeMiterlimit="4"
        strokeWidth="0.9412"
        d="M0.471 16c0-8.577 6.952-15.529 15.529-15.529s15.529 6.952 15.529 15.529c0 8.577-6.952 15.529-15.529 15.529s-15.529-6.952-15.529-15.529z"
      ></path>
      <path
        fill="#fff"
        d="M13.972 19.765h-1.737l1.086-6.588h1.737z"
      ></path>
      <path
        fill="#fff"
        d="M20.428 13.453c-0.346-0.128-0.894-0.27-1.571-0.27-1.73 0-2.948 0.863-2.956 2.097-0.014 0.911 0.872 1.416 1.535 1.72 0.678 0.31 0.908 0.513 0.908 0.789-0.007 0.425-0.548 0.62-1.052 0.62-0.699 0-1.074-0.101-1.644-0.337l-0.231-0.101-0.245 1.423c0.411 0.175 1.168 0.331 1.954 0.337 1.838 0 3.035-0.85 3.049-2.165 0.007-0.722-0.461-1.275-1.471-1.726-0.613-0.29-0.988-0.486-0.988-0.782 0.007-0.27 0.317-0.546 1.009-0.546 0.57-0.014 0.988 0.115 1.305 0.243l0.158 0.067 0.238-1.369z"
      ></path>
      <path
        fill="#fff"
        d="M24.919 13.298h-1.355c-0.418 0-0.735 0.115-0.916 0.526l-2.602 5.813h1.838c0 0 0.303-0.782 0.368-0.951h2.249c0.050 0.223 0.209 0.951 0.209 0.951h1.622l-1.413-6.339zM22.756 17.392c0.144-0.364 0.699-1.774 0.699-1.774-0.007 0.014 0.144-0.371 0.23-0.607l0.122 0.546c0 0 0.332 1.517 0.404 1.834h-1.456z"
      ></path>
      <path
        fill="#fff"
        d="M10.862 13.298l-1.716 4.323-0.187-0.877c-0.317-1.012-1.312-2.111-2.422-2.657l1.572 5.544h1.853l2.754-6.333h-1.852z"
      ></path>
      <path
        fill="#fff"
        d="M7.553 13.298h-2.819l-0.029 0.128c2.199 0.526 3.655 1.794 4.253 3.318l-0.613-2.914c-0.101-0.405-0.411-0.52-0.793-0.533z"
      ></path>
    </svg>
  );
};

export default SvgIconVisa;

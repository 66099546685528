import * as React from 'react';
import { navigate } from '@reach/router';
import _ from 'lodash-es';

import Drawer from '@material-ui/core/Drawer';
import { hasPermission } from '../../helpers/Permission';

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ReduxState } from '../../reducers/types';
import { logout } from '../../actions/User/Login';
import { reset } from '../../analytics/analytics';

import SidebarMenu from '../SidebarMenu/SidebarMenu';

type Props = {
  logout: Function;
};

const styles = require('./style.pcss');

class Sidebar extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);
  }

  shouldComponentUpdate(nextProps: any) {
    if (_.get(nextProps.userLogin, 'isLoggedIn', false) === false) {
      reset();
      navigate(`/login`);
      return false;
    }

    return true;
  }

  logout = () => {
    this.props.logout();
  };

  renderAdminSetting() {
    if (hasPermission('blackBox', 'read')) {
      return (
        <div
          className={
            window.location.pathname.includes('/manage-bb')
              ? styles.active
              : styles.inactive
          }
          onClick={() => {
            navigate(`/manage-bb`);
          }}
        >
          Admin Settings
        </div>
      );
    }

    return null;
  }

  render() {
    return (
      <Drawer
        className={styles.drawer}
        variant="permanent"
        anchor="left"
      >
        <div className={styles.logo}>BlackBox</div>
        <SidebarMenu />
        <div className={styles.bottomNav}>
          <div
            className={
              window.location.pathname.includes('/account')
                ? styles.active
                : styles.inactive
            }
            onClick={() => {
              navigate(`/account`);
            }}
          >
            My Account
          </div>
          {this.renderAdminSetting()}
          <div onClick={this.logout}>Log Out</div>
        </div>
      </Drawer>
    );
  }
}

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {
    userLogin: state.userLogin,
  };
}

// Wire up redux dispatch functions
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch,
  );
}

// Connect it to Redux
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);

import {
  ACTION_GET_BOOKING_CANCEL_INFO,
  ACTION_GET_BOOKING_CANCEL_INFO_SUCCESS,
  ACTION_GET_BOOKING_CANCEL_INFO_FAILURE,
  ACTION_EMPTY_BOOKING_CANCEL_INFO,
} from '../configs/ActionTypes';

import _ from 'lodash-es';
const initialState = {
  cancelInfo: null,
  isLoading: false,
  success: null,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_GET_BOOKING_CANCEL_INFO: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ACTION_GET_BOOKING_CANCEL_INFO_SUCCESS: {
      const refundAmount = _.get(action.payload.payload, 'refundAmount', '');

      return {
        ...state,
        isLoading: false,
        cancelInfo: refundAmount,
        success: true,
      };
    }

    case ACTION_GET_BOOKING_CANCEL_INFO_FAILURE: {
      return {
        ...state,
        success: false,
        isLoading: false,
      };
    }

    case ACTION_EMPTY_BOOKING_CANCEL_INFO: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

import {
  ACTION_PUT_PAID_PLAN,
  ACTION_PUT_PAID_PLAN_SUCCESS,
  ACTION_PUT_PAID_PLAN_FAILURE,
  ACTION_GET_PAID_PLAN,
  ACTION_GET_PAID_PLAN_SUCCESS,
  ACTION_GET_PAID_PLAN_FAILURE,
} from '../configs/ActionTypes';

const initialState = {
  setting: {},
  isLoading: false,
  success: null,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_GET_PAID_PLAN:
    case ACTION_PUT_PAID_PLAN: {
      return {
        ...state,
        isLoading: true,
        success: null,
      };
    }

    case ACTION_GET_PAID_PLAN_SUCCESS:
    case ACTION_PUT_PAID_PLAN_SUCCESS: {
      return {
        ...state,
        setting: action.payload.payload,
        isLoading: false,
        success: null,
      };
    }

    case ACTION_PUT_PAID_PLAN_FAILURE:
    case ACTION_GET_PAID_PLAN_FAILURE: {
      return {
        ...state,
        isLoading: false,
        success: false,
      };
    }

    default: {
      return state;
    }
  }
}

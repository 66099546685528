import {
  ACTION_GET_FLIGT_TOUR_CODES,
  ACTION_GET_FLIGT_TOUR_CODES_SUCCESS,
  ACTION_GET_FLIGT_TOUR_CODES_FAILURE,
  ACTION_PUT_FLIGT_TOUR_CODES,
  ACTION_PUT_FLIGT_TOUR_CODES_SUCCESS,
  ACTION_PUT_FLIGT_TOUR_CODES_FAILURE,
} from '../configs/ActionTypes';

const initialState = {
  flightTourCodes: [],
  isLoading: false,
  success: null,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_GET_FLIGT_TOUR_CODES:
    case ACTION_PUT_FLIGT_TOUR_CODES: {
      return {
        ...state,
        isLoading: true,
        success: null,
      };
    }

    case ACTION_GET_FLIGT_TOUR_CODES_SUCCESS:
    case ACTION_PUT_FLIGT_TOUR_CODES_SUCCESS: {
      return {
        ...state,
        flightTourCodes: action.payload.payload,
        isLoading: false,
        success: null,
      };
    }

    case ACTION_GET_FLIGT_TOUR_CODES_FAILURE:
    case ACTION_PUT_FLIGT_TOUR_CODES_FAILURE: {
      return {
        ...state,
        isLoading: false,
        success: false,
      };
    }

    default: {
      return state;
    }
  }
}

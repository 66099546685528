import React from 'react';

type Props = {
  width: number;
  height: number;
  color: string;
};

const SvgArrowDown = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 9 6"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs></defs>
      <g
        id="(WIP)-Refund-Card"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Booking-Macro---Flight"
          transform="translate(-975.000000, -345.000000)"
          fill={props.color}
        >
          <g
            id="reservation"
            transform="translate(279.000000, 214.000000)"
          >
            <g
              id="flight-1"
              transform="translate(33.000000, 32.000000)"
            >
              <g
                id="Flight-Details"
                transform="translate(565.000000, 92.000000)"
              >
                <g id="Group-15">
                  <path
                    d="M104.720566,10.2808548 L101.230075,13.7886431 C100.949628,14.0704523 100.490781,14.0704523 100.210335,13.7886431 C99.9298884,13.5068339 99.9298884,13.0457576 100.210335,12.7639484 L102.960918,10 L100.210335,7.23605158 C99.9298884,6.95424237 99.9298884,6.49316613 100.210335,6.21135691 C100.490781,5.9295477 100.949628,5.9295477 101.230075,6.21135691 L104.720566,9.71914519 L105,10.0000364 L104.720566,10.2808548 L103.980622,11.0246583 L102.960918,10 Z"
                    id="ArrowDown"
                    transform="translate(102.500000, 10.000000) rotate(-270.000000) translate(-102.500000, -10.000000) "
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

SvgArrowDown.defaultProps = {
  width: 16,
  height: 16,
  color: '#318AFB',
};

export default SvgArrowDown;

import { enqueueSnackbarHandler } from 'actions/Status';
import {
  ACTION_POST_BOOKING,
  ACTION_POST_BOOKING_SUCCESS,
  ACTION_POST_BOOKING_FAILURE,
  ACTION_RESET_POST_BOOKING,
  ACTION_RESET_POST_BOOKING_ERRORS,
  API_CALL,
} from '../../configs/ActionTypes';

import { Dispatch } from 'redux';

export function postTrainBooking(params: any) {
  return (dispatch: Dispatch, getState: Function) => {
    //Dispatch the api call request.
    dispatch({
      type: API_CALL,
      payload: {
        call: 'bookings/train',
        method: 'post',
        data: JSON.stringify(params),
        success: (response: any) => {
          if (response.type === 'error') {
            enqueueSnackbarHandler(
              dispatch,
              { amtrakBookingFailed: true },
              'error',
            );
            dispatch(requestFailed(response));
          } else {
            enqueueSnackbarHandler(
              dispatch,
              { amtrakBookingSuccess: true },
              'success',
            );
            dispatch(requestSuccess(response));
          }
        },
        fail: (error: any) => {
          enqueueSnackbarHandler(
            dispatch,
            { amtrakBookingFailed: true },
            'error',
          );
          dispatch(requestFailed(error));
        },
      },
    });

    return null;
  };
}

export function requestStarted() {
  return {
    type: ACTION_POST_BOOKING,
    payload: null,
  };
}

export function requestSuccess(responseJSON: any) {
  return {
    type: ACTION_POST_BOOKING_SUCCESS,
    payload: responseJSON,
  };
}

export function requestFailed(responseJSON: any) {
  return {
    type: ACTION_POST_BOOKING_FAILURE,
    payload: responseJSON,
  };
}

export function resetPostBooking() {
  return (dispatch: Dispatch, getState: Function) => {
    dispatch({
      type: ACTION_RESET_POST_BOOKING,
      payload: null,
    });
  };
}

export function resetPostBookingErrors() {
  return (dispatch: Dispatch, getState: Function) => {
    dispatch({
      type: ACTION_RESET_POST_BOOKING_ERRORS,
      payload: null,
    });
  };
}

import {
  ACTION_PUT_USER_STATUS,
  ACTION_PUT_USER_STATUS_SUCCESS,
  ACTION_PUT_USER_STATUS_FAILURE,
  ACTION_PUT_USER_STATUS_RESET,
} from '../configs/ActionTypes';

import _ from 'lodash-es';

const initialState = {
  userId: null,
  isLoading: false,
  status: '',
  success: null,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_PUT_USER_STATUS: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ACTION_PUT_USER_STATUS_SUCCESS: {
      return {
        ...state,
        userId: action.payload.payload.id,
        status: action.payload.payload.status,
        isLoading: false,
        success: true,
      };
    }

    case ACTION_PUT_USER_STATUS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        success: false,
        status: '',
      };
    }
    case ACTION_PUT_USER_STATUS_RESET: {
      return {
        ...state,
        isLoading: false,
        success: null,
        status: false,
      };
    }

    default: {
      return state;
    }
  }
}

import React from 'react';
import _ from 'lodash-es';
import { debounce } from 'lodash-es';
import Autosuggest from 'react-autosuggest';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from 'react-select';
import MenuItem from '@material-ui/core/MenuItem';
import { FormHelperText } from '@material-ui/core';
import { Async } from 'react-select';
import {
  NoOptionsMessage,
  Option,
  Placeholder,
  ValueContainerMulti as ValueContainer,
  Menu,
  DropdownIndicatorMulti as DropdownIndicator,
  IndicatorSeparator,
  inputComponent,
  MultiValue,
} from '../AutoSuggest/Components';
import cx from 'classnames';

const styles = require('./styles.pcss');

type Props = {
  onChange: Function;
  label: string;
  className: any;
  error?: string;
  disabled?: any;
  dataSet?: any;
  returnAll?: boolean;
  isLoading: boolean;
  changeSelectedValue: Function;
  defValue?: any;
  multiValue: any;
  labelWidth?: any;
  maxMenuHeight?: any;
  id?: any;
  type?: any;
};

type State = {
  options: any;
  inputValue: any;
};

class MultiSelect extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      options: props.dataSet.map((data: any) => ({
        value: data.id,
        label:
          props?.type === 'user'
            ? `${data.firstName} ${data.lastName} - ${data.email}`
            : data.name,
      })),
      inputValue: '',
    };

    this.onChange = debounce(this.onChange, 250);
  }

  componentWillReceiveProps = (nextProps: Props) => {
    this.setState({
      options: nextProps.dataSet.map((data: any) => ({
        value: data.id,
        label:
          nextProps?.type === 'user'
            ? `${data.firstName} ${data.lastName} - ${data.email}`
            : data.name,
      })),
    });
  };

  handleChange = (val: any) => {
    this.props.changeSelectedValue(val);
  };

  handleChangeText = (value: any) => {
    if (value.length > 2) {
      this.onChange(value);
    }

    this.setState({
      inputValue: value,
    });
  };

  onChange = (value: any) => {
    this.props.onChange(value);
  };

  render() {
    const components = {
      Menu,
      Option,
      MultiValue,
      NoOptionsMessage,
      DropdownIndicator,
      IndicatorSeparator,
      ValueContainer,
    };

    let labelWidth =
      this.props.multiValue.length > 0 ? this.props.labelWidth || 100 : 0;
    let shrink =
      this.props.multiValue.length > 0 || this.state.inputValue.length > 0
        ? true
        : false;
    let classN = shrink
      ? cx(styles.formControl)
      : cx(styles.formControl, styles.inputLabel);
    let disableStyle = this.props.disabled ? styles.disable : styles.enable;
    return (
      <div className={cx(this.props.className, styles.multiDynamicSelect)}>
        <FormControl
          error={Boolean(this.props.error)}
          className={cx(classN, disableStyle)}
          margin="normal"
          variant="outlined"
        >
          <InputLabel
            htmlFor="select-multiple"
            shrink={shrink}
          >
            {this.props.label}
          </InputLabel>

          <Select
            isLoading={this.props.isLoading}
            name="filters"
            placeholder={''}
            components={components}
            options={this.state.options}
            onChange={this.handleChange}
            isMulti
            value={this.props.multiValue}
            onInputChange={this.handleChangeText}
            aria-label={this.props.label}
            isDisabled={this.props.disabled}
            className={this.props.disabled ? styles.disable : styles.enable}
            maxMenuHeight={this.props.maxMenuHeight}
            id={this.props.id ? this.props.id : ''}
            styles={{
              ...styles,
              control: (base, state) => ({
                ...base,
                '&:hover': { border: '1px solid #318AFB' },
                '&:active': { border: '1px solid #318AFB !important' },
                border: '1px solid lightgray',
                boxShadow: 'none',
              }),
            }}
          />
        </FormControl>
      </div>
    );
  }
}

export default MultiSelect;

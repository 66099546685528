import {
  ACTION_GET_EXCLUSIVE_HOTELS,
  ACTION_GET_EXCLUSIVE_HOTELS_SUCCESS,
  ACTION_GET_EXCLUSIVE_HOTELS_FAILURE,
  ACTION_RESET_EXCLUSIVE_HOTELS,
  ACTION_PUT_EXCLUSIVE_HOTEL,
  ACTION_PUT_EXCLUSIVE_HOTEL_SUCCESS,
  ACTION_PUT_EXCLUSIVE_HOTEL_FAILURE,
  ACTION_RESET_EXCLUSIVE_HOTELS_SUCCESS,
  ACTION_DELETE_EXCLUSIVE_HOTEL,
  ACTION_DELETE_EXCLUSIVE_HOTEL_SUCCESS,
  ACTION_DELETE_EXCLUSIVE_HOTEL_FAILURE,
} from '../configs/ActionTypes';

import { User } from './types';
import _ from 'lodash-es';

const initialState = {
  exclusiveHotels: [],
  isLoading: false,
  success: null,
  nameError: '',
  identifierError: '',
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_GET_EXCLUSIVE_HOTELS:
    case ACTION_PUT_EXCLUSIVE_HOTEL:
    case ACTION_DELETE_EXCLUSIVE_HOTEL: {
      return {
        ...state,
        isLoading: true,
        success: null,
      };
    }

    case ACTION_GET_EXCLUSIVE_HOTELS_SUCCESS:
    case ACTION_DELETE_EXCLUSIVE_HOTEL_SUCCESS: {
      let hotels = action.payload.payload;
      hotels = Array.isArray(hotels) ? hotels : [];

      return {
        ...state,
        exclusiveHotels: hotels,
        isLoading: false,
      };
    }

    case ACTION_PUT_EXCLUSIVE_HOTEL_SUCCESS: {
      let exclusiveHotels: Array<any> = _.clone(state.exclusiveHotels);
      exclusiveHotels.push(action.payload.payload);

      return {
        ...state,
        exclusiveHotels: exclusiveHotels,
        isLoading: false,
        success: true,
      };
    }

    case ACTION_GET_EXCLUSIVE_HOTELS_FAILURE:
    case ACTION_PUT_EXCLUSIVE_HOTEL_FAILURE:
    case ACTION_DELETE_EXCLUSIVE_HOTEL_FAILURE: {
      const p = action.payload.payload;

      let nameError: string = '';
      let identifierError: string = '';

      if (p.hasOwnProperty('identifierEmpty') && p.identifierEmpty === true) {
        identifierError = 'Required';
      }

      if (p.hasOwnProperty('nameEmpty') && p.nameEmpty === true) {
        nameError = 'Required';
      }

      return {
        ...state,
        isLoading: false,
        success: false,
        identifierError: identifierError,
        nameError: nameError,
      };
    }

    case ACTION_RESET_EXCLUSIVE_HOTELS: {
      return initialState;
    }

    case ACTION_RESET_EXCLUSIVE_HOTELS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        success: null,
      };
    }

    default: {
      return state;
    }
  }
}

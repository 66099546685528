/**
 * GET CREDITCARDS Action
 */

import {
  ACTION_GET_CREDITCARDS,
  ACTION_GET_CREDITCARDS_SUCCESS,
  ACTION_GET_CREDITCARDS_FAILURE,
  ACTION_EMPTY_CREDITCARDS,
  ACTION_RESET_GET_CREDIT_CARDS,
  API_CALL,
} from '../../configs/ActionTypes';

import { Dispatch } from 'redux';

import { enqueueSnackbar } from '../Status';

import { enqueueSnackbarHandler } from '../Status';

/**
 * GET CREDITCARDS Request Action
 */
export type Params = {
  search?: any;
  limit?: number;
  skip?: number;
};

export function get(params?: any) {
  return (dispatch: Dispatch, getState: Function) => {
    dispatch(requestStarted());

    //Dispatch the api call request.
    dispatch({
      type: API_CALL,
      payload: {
        call: 'creditcards',
        method: 'get',
        data: params || {},
        success: (response: any) => {
          dispatch(requestSuccess(response));
        },
        fail: (error: any) => {
          dispatch(requestFailed(error));
          enqueueSnackbarHandler(dispatch, error.payload, 'error');
        },
      },
    });

    return null;
  };
}

export function emptyResults() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ACTION_RESET_GET_CREDIT_CARDS,
      payload: null,
    });
  };
}

export function requestStarted() {
  return {
    type: ACTION_GET_CREDITCARDS,
    payload: null,
  };
}

export function requestSuccess(responseJSON: object) {
  return {
    type: ACTION_GET_CREDITCARDS_SUCCESS,
    payload: responseJSON,
  };
}

export function requestFailed(responseJSON: object) {
  return {
    type: ACTION_GET_CREDITCARDS_FAILURE,
    payload: responseJSON,
  };
}

import React from 'react';

type Props = {
  width: number;
  height: number;
};

const SvgFunctionalIcon = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 20 21" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg">
      <path d="M9.25 14.9242H10.75V9.92416H9.25V14.9242ZM9.9956 8.42416C10.2069 8.42416 10.3854 8.35271 10.5312 8.20981C10.6771 8.06689 10.75 7.88981 10.75 7.67856C10.75 7.4673 10.6785 7.28875 10.5356 7.14291C10.3927 6.99708 10.2156 6.92416 10.0044 6.92416C9.79313 6.92416 9.61458 6.99562 9.46875 7.13852C9.32292 7.28144 9.25 7.45852 9.25 7.66977C9.25 7.88103 9.32145 8.05958 9.46435 8.20541C9.60727 8.35125 9.78435 8.42416 9.9956 8.42416ZM10.0058 18.9242C8.90469 18.9242 7.86806 18.7158 6.89583 18.2992C5.92361 17.8825 5.07292 17.3096 4.34375 16.5804C3.61458 15.8512 3.04167 15.0008 2.625 14.0292C2.20833 13.0576 2 12.0194 2 10.9146C2 9.80987 2.20833 8.77486 2.625 7.80958C3.04167 6.8443 3.61458 5.99708 4.34375 5.26791C5.07292 4.53875 5.92332 3.96583 6.89496 3.54916C7.86661 3.1325 8.90481 2.92416 10.0095 2.92416C11.1143 2.92416 12.1493 3.1325 13.1146 3.54916C14.0799 3.96583 14.9271 4.53875 15.6562 5.26791C16.3854 5.99708 16.9583 6.84586 17.375 7.81425C17.7917 8.78265 18 9.81737 18 10.9184C18 12.0195 17.7917 13.0561 17.375 14.0283C16.9583 15.0006 16.3854 15.8512 15.6562 16.5804C14.9271 17.3096 14.0783 17.8825 13.1099 18.2992C12.1415 18.7158 11.1068 18.9242 10.0058 18.9242ZM10 17.4242C11.8056 17.4242 13.3403 16.7922 14.6042 15.5283C15.8681 14.2644 16.5 12.7297 16.5 10.9242C16.5 9.11861 15.8681 7.58389 14.6042 6.32C13.3403 5.05611 11.8056 4.42416 10 4.42416C8.19444 4.42416 6.65972 5.05611 5.39583 6.32C4.13194 7.58389 3.5 9.11861 3.5 10.9242C3.5 12.7297 4.13194 14.2644 5.39583 15.5283C6.65972 16.7922 8.19444 17.4242 10 17.4242Z" fill="#0058FF"/>
    </svg>
  );
};

export default SvgFunctionalIcon;

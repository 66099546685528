import React, { Component } from 'react';
import cx from 'classnames';
import _ from 'lodash-es';

import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Loader from 'components/Loader/Loader';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import TravelerTableHead from '../TravelerDetails/TravelerTableHead';
import TravelerTableRow from '../TravelerDetails/TravelerTableRow';
import TextFieldWithClear from 'components/TextFieldWithIcon/TextFieldWithIcon';
import CustomSelect from 'components/Inputs/Select/Select';
import AutoSuggest from 'components/Inputs/AutoSuggest/AutoSuggest';

const styles = require('./styles.pcss');

type Props = {
  open: boolean;
  onSubmit?: any;
  title?: any;
  subtitle?: string;
  submitButtonText?: string;
  closeButtonText?: string;
  children?: any;
  in_process?: any;
  onCancel: any;
  type: string;
  listOfLoyaltyProgram: any;
  submit: any;
  label: any;
  numberLabel: any;
};

type State = {
  programNumberError: boolean;
  programError: boolean;
  programNumber: string;
  program: string;
};

class AddMileageProgramPopup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      programNumberError: false,
      programError: false,
      programNumber: '',
      program: '',
    };
  }

  onChangeProgramNumber = (event: any) => {
    this.setState({
      programNumber: event.target.value,
    });
  };

  onChangeProgram = (event: any) => {
    this.setState({
      program: event.target.value,
    });
  };

  onDeleteMileageProgramNumber = () => {
    this.setState({
      programNumber: '',
    });
  };

  handleSubmit = () => {
    let isError = false;
    let newStates = {
      programNumberError: false,
      programError: false,
    };

    if (this.state.programNumber.trim() === '') {
      newStates.programNumberError = true;
      isError = true;
    }

    if (this.state.program.trim() === '') {
      newStates.programError = true;
      isError = true;
    }

    if (isError) {
      this.setState(newStates);
    } else {
      this.props.submit(
        _.toString(this.state.programNumber),
        this.state.program,
      );
    }
  };

  renderLoader = () => {
    if (!this.props.in_process) {
      return null;
    }
    return null;
    return (
      <div className={styles.loaderContainer}>
        <Loader visible={true} />
      </div>
    );
  };

  renderFrequentFlyer = (flyer: any) => {
    return (
      <MenuItem
        key={flyer.airlineCode}
        value={flyer.airlineCode}
      >
        {flyer.airlineName} {flyer.programName}
      </MenuItem>
    );
  };

  renderHotel = (hotel: any) => {
    return (
      <MenuItem
        key={hotel}
        value={hotel}
      >
        {' '}
        {hotel}{' '}
      </MenuItem>
    );
  };

  onChangeVendors = (vendor: any) => {
    this.setState({
      program: _.get(vendor, 'value', ''),
      programError: false,
    });
  };

  renderSelectField = (loyaltyProgram: any) => {
    if (this.props.type == 'car') {
      return (
        <AutoSuggest
          error={this.state.programError}
          className={styles.selectWrapperAutoSuggest}
          onChange={(value: any) => {
            this.onChangeVendors(value);
          }}
          label={this.props.label}
          value={this.state.program}
          dataSet={loyaltyProgram}
          maxMenuHeight={130}
          returnAll={true}
        />
      );
    }

    return (
      <CustomSelect
        margin={'normal'}
        error={this.state.programError}
        variant={'outlined'}
        className={styles.selectWrapper}
        htmlFor={'nput-select'}
        label={this.props.label}
        id={'input-select'}
        value={this.state.program}
        onChange={this.onChangeProgram}
        labelWidth={this.props.type == 'flight' ? 118 : 145}
        inputName={'program'}
        MenuProps={{
          style: { marginTop: 4, boxShadow: 'none' },
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        menuItems={loyaltyProgram}
      />
    );
  };

  render() {
    let loyaltyProgram: any = [];
    let numberFieldStyle = cx(styles.paddingRightNull, styles.inputTableCell);
    let selectStyle = cx(
      styles.tableCell,
      styles.paddingRightNull,
      styles.addNewTableRowProgram,
      styles.inputTableCell,
    );
    if (this.props.type == 'flight') {
      loyaltyProgram = _.map(
        this.props.listOfLoyaltyProgram,
        (loyaltyProgram) => {
          return {
            key: loyaltyProgram.airlineCode,
            value: loyaltyProgram.airlineCode,
            label:
              loyaltyProgram.airlineName + ' ' + loyaltyProgram.programName,
          };
        },
      );
    } else if (this.props.type == 'hotel') {
      loyaltyProgram = _.map(
        this.props.listOfLoyaltyProgram,
        (loyaltyProgram) => {
          return {
            key: loyaltyProgram,
            value: loyaltyProgram,
            label: loyaltyProgram,
          };
        },
      );
    } else {
      loyaltyProgram = this.props.listOfLoyaltyProgram;
      numberFieldStyle = cx(styles.paddingRightNull, styles.carLoyaltyNumber);
      selectStyle = cx(
        styles.tableCell,
        styles.paddingRightNull,
        styles.addNewTableRowProgram,
        styles.carLoyaltySelect,
      );
    }

    return (
      <Dialog
        maxWidth={'md'}
        fullWidth={true}
        className={cx(styles.dialogBox, styles.ccPopup)}
        open={this.props.open}
        onClose={this.props.onCancel}
      >
        <div className={styles.title}>
          <div className={styles.titleStyle}>{this.props.title}</div>
          <IconButton
            color="inherit"
            onClick={this.props.onCancel}
            className={cx(styles.closeIconCreditCard)}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        </div>
        {this.renderLoader()}
        <p className={styles.subtitleStyle}>{this.props.subtitle}</p>
        <div className={cx(styles.body, styles.addProgram)}>
          <Table>
            <TableBody>
              <TableRow
                className={cx(styles.tableRow, styles.addNewTableRowProgram)}
              >
                <TableCell className={selectStyle}>
                  {this.renderSelectField(loyaltyProgram)}
                </TableCell>

                <TableCell
                  className={cx(
                    styles.tableCell,
                    styles.addNewTableRowProgram,
                    numberFieldStyle,
                  )}
                >
                  <TextFieldWithClear
                    error={this.state.programNumberError}
                    onChange={this.onChangeProgramNumber}
                    fullWidth
                    label={this.props.numberLabel}
                    variant="outlined"
                    margin="normal"
                    value={this.state.programNumber}
                    onClear={this.onDeleteMileageProgramNumber}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <div
            className={cx(
              styles.popupButtonsContainer,
              styles.addNewTableRowProgram,
            )}
          >
            <Button
              size="small"
              className={styles.cancelButton}
              color="primary"
              onClick={this.props.onCancel}
            >
              Cancel
            </Button>

            <Button
              size="small"
              className={styles.button}
              variant="contained"
              color="primary"
              onClick={this.handleSubmit}
            >
              Add
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default AddMileageProgramPopup;

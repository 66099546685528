import {
  ACTION_GET_LICENSE_PRICING,
  ACTION_GET_LICENSE_PRICING_SUCCESS,
  ACTION_GET_LICENSE_PRICING_FAILURE,
  ACTION_UPDATE_LICENSE_PRICING,
  ACTION_UPDATE_LICENSE_PRICING_SUCCESS,
  ACTION_UPDATE_LICENSE_PRICING_FAILURE,
  ACTION_RESET_LICENSE_PRICING,
  ACTION_RESET_LICENSE_PRICING_SUCCESS,
  ACTION_RESET_LICENSE_PRICING_FAILURE,
} from '../configs/ActionTypes';

import _ from 'lodash-es';

const initialState = {
  licensePricing: null,
  loading: false,
  success: null,
  reloadPricing: false,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_RESET_LICENSE_PRICING:
    case ACTION_GET_LICENSE_PRICING:
    case ACTION_UPDATE_LICENSE_PRICING: {
      return {
        ...state,
        loading: true,
        success: null,
        reloadPricing: false,
      };
    }

    case ACTION_GET_LICENSE_PRICING_SUCCESS: {
      return {
        ...state,
        licensePricing: action.payload.payload,
        loading: false,
        reloadPricing: false,
        success: true,
      };
    }

    case ACTION_UPDATE_LICENSE_PRICING_SUCCESS: {
      return {
        ...state,
        loading: true,
        reloadPricing: true,
        success: true,
      };
    }

    case ACTION_RESET_LICENSE_PRICING_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true,
        reloadPricing: true,
      };
    }

    case ACTION_GET_LICENSE_PRICING_FAILURE:
    case ACTION_UPDATE_LICENSE_PRICING_FAILURE:
    case ACTION_RESET_LICENSE_PRICING_FAILURE: {
      return {
        ...state,
        loading: false,
        success: false,
        reloadPricing: false,
      };
    }

    default: {
      return state;
    }
  }
}

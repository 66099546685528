import * as React from 'react';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import DownIcon from '@material-ui/icons/ExpandMore';
import UpIcon from '@material-ui/icons/ExpandLess';

import Flights from 'components/BookingsList/Flights';
import Hotels from 'components/BookingsList/Hotels';
import Cars from 'components/BookingsList/Cars';

import { connect } from 'react-redux';
import { get, emptyResults } from 'actions/Bookings/Get';
import { bindActionCreators } from 'redux';

const styles = require('./styles.pcss');

class BookingsTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTabIndex: 0,
      open: false,
      sortBy: 'upcoming',
    };

    this.onTabChange = this.onTabChange.bind(this);
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleArrow = () => {
    if (this.state.open) {
      return <UpIcon className={styles.icon} />;
    }
    return <DownIcon className={styles.icon} />;
  };

  handleChangeSortBy = (value) => {
    this.props.emptyResults();
    this.setState({
      sortBy: value,
    });
  };

  componentWillUnmount() {
    this.props.emptyResults();
  }

  onTabChange(e, idx) {
    this.props.emptyResults();
    this.setState({
      activeTabIndex: idx,
    });
  }

  renderTable() {
    switch (this.state.activeTabIndex) {
      case 0: {
        return (
          <Flights
            noBorder={true}
            flights={this.props.bookings}
            get={this.props.get}
            emptyResults={this.props.emptyResults}
            isLoading={this.props.isLoading}
            loadedAllFlight={this.props.loadedAll}
            travelerId={this.props.travelerId}
            userId={this.props.userId}
            sort={this.state.sortBy}
            order={'departureDate ASC'}
          />
        );
      }

      case 1: {
        return (
          <Hotels
            noBorder={true}
            hotels={this.props.bookings}
            get={this.props.get}
            emptyResults={this.props.emptyResults}
            isLoading={this.props.isLoading}
            loadedAllHotel={this.props.loadedAll}
            travelerId={this.props.travelerId}
            userId={this.props.userId}
            sort={this.state.sortBy}
            order={'arrivalDate ASC'}
          />
        );
      }

      case 2: {
        return (
          <Cars
            noBorder={true}
            cars={this.props.bookings}
            get={this.props.get}
            emptyResults={this.props.emptyResults}
            isLoading={this.props.isLoading}
            loadedAllCars={this.props.loadedAll}
            travelerId={this.props.travelerId}
            userId={this.props.userId}
            sort={this.state.sortBy}
            order={'pickupDateTime ASC'}
          />
        );
      }
    }
  }

  render() {
    return (
      <div className="pageWrapper">
        <Tabs
          className={styles.tabbs}
          value={this.state.activeTabIndex}
          indicatorColor="primary"
          textColor="primary"
          onChange={this.onTabChange}
        >
          <Tab
            className={styles.tabb}
            label="Flights"
          />
          <Tab
            className={styles.tabb}
            label="Hotels"
          />
          <Tab
            className={styles.tabb}
            label="Cars"
          />

          <div className={styles.sortBy}>
            Show:
            <Select
              open={this.state.open}
              onClose={this.handleClose}
              onOpen={this.handleOpen}
              autoWidth={false}
              className={styles.select}
              value={this.state.sortBy}
              onChange={(e) => {
                this.handleChangeSortBy(e.target.value);
              }}
              IconComponent={this.handleArrow}
              inputProps={{ className: styles.input }}
              MenuProps={{
                style: { left: '-65px' },
                getContentAnchorEl: null,
                anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
              }}
            >
              <MenuItem
                value={'upcoming'}
                style={{ minWidth: '190px' }}
              >
                Upcoming
              </MenuItem>
              <MenuItem value={'past'}>Past</MenuItem>
              <MenuItem value={'canceled'}>Canceled</MenuItem>
            </Select>
          </div>
        </Tabs>

        {this.renderTable()}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      get,
      emptyResults,
    },
    dispatch,
  );
}

function mapStateToProps(state) {
  return {
    sort: state.bookings.sort,
    isLoading: state.bookings.isLoading,
    bookings: state.bookings.bookings,
    loadedAll: state.bookings.loadedAll,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingsTable);

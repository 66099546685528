import React from 'react';

type Props = {
  width: number;
  height: number;
};

const SvgMoreVertical = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="-10 0 24 24"
    >
      <path
        d="M0 0h24v24H0z"
        fill="none"
      />
      <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
    </svg>
  );
};

export default SvgMoreVertical;

import React from 'react';

type Props = {
  width: number;
  height: number;
};

const SvgIconBrex = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>BrexLogo Avatar</title>
      <defs>
        <polygon
          id="oath1"
          points="0 0.11912 3.95378947 0.11912 3.95378947 4.81978 0 4.81978"
        ></polygon>
      </defs>
      <g
        id="Master"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Payment-Method---Brex"
          transform="translate(-64.000000, -64.000000)"
        >
          <g
            id="payment-method"
            transform="translate(32.000000, 32.000000)"
          >
            <g
              id="BrexLogo-Avatar"
              transform="translate(32.000000, 32.000000)"
            >
              <path
                d="M0,16 C0,7.164 7.162,0 16,0 C24.836,0 32,7.162 32,16 C32,24.836 24.838,32 16,32 C7.164,32 0,24.838 0,16"
                id="Fill-1"
                fill="#1B2432"
              ></path>
              <g
                id="Brex_logo"
                transform="translate(4.000000, 12.000000)"
              >
                <polygon
                  id="Path"
                  fill="#D26E45"
                  fillRule="nonzero"
                  points="24 0 23.4697374 0 21.1522575 3.8129175 18 9 18.5302626 9 21.7427194 3.7142325"
                ></polygon>
                <g
                  id="Clipped"
                  transform="translate(0.000000, 2.000000)"
                >
                  <g id="Shape-Clipped">
                    <mask
                      id="mask2"
                      fill="white"
                    >
                      <use xlinkHref="#oath1"></use>
                    </mask>
                    <g id="oath1"></g>
                    <path
                      d="M2.62025263,2.245 L0.465073684,2.245 L0.465073684,0.5681 L2.62025263,0.5681 C3.09915789,0.5681 3.48873684,0.94422 3.48873684,1.4066 C3.48873684,1.86888 3.09915789,2.245 2.62025263,2.245 Z M2.62025263,4.37082 L0.465073684,4.37082 L0.465073684,2.694 L2.62025263,2.694 C3.09915789,2.694 3.48873684,3.07012 3.48873684,3.5324 C3.48873684,3.9947 3.09915789,4.37082 2.62025263,4.37082 Z M3.36953684,2.46948 C3.73631579,2.22836 3.95376842,1.83464 3.95376842,1.40658 C3.95376842,0.6967 3.35553684,0.11912 2.62025263,0.11912 L-2.10526316e-05,0.12234 L-2.10526316e-05,4.78758 L-2.10526316e-05,4.81978 L2.62025263,4.81978 C3.35553684,4.81978 3.95376842,4.24226 3.95376842,3.53238 C3.95376842,3.10436 3.73631579,2.71066 3.36953684,2.46948 Z"
                      id="Shape"
                      fill="#FFFFFF"
                      fillRule="nonzero"
                      mask="url(#mask2)"
                    ></path>
                  </g>
                </g>
                <polygon
                  id="Path"
                  fill="#FFFFFF"
                  fillRule="nonzero"
                  points="17 2.47786699 17 2 13 2.00019157 13 6.99965943 17 7 17 6.52213301 13.4696003 6.52213301 13.4696003 4.73752863 16.0029762 4.73752863 16.0029762 4.25970421 13.4696003 4.25970421 13.4696003 2.47786699"
                ></polygon>
                <path
                  d="M20.5690819,4.91599443 L20.2855349,5.32880683 L21.4232801,6.98556913 L21.4333246,7 L22,7 L20.5690819,4.91599443 Z M18.5666754,2 L18,2 L19.4330107,4.08717695 L19.7165577,3.67442841 L18.5767199,2.01443087 L18.5666754,2 Z"
                  id="Shape"
                  fill="#FFFFFF"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M8.42951664,4.73788456 L10,6.99946825 L9.42709959,6.99946825 L9.41694478,6.98504713 L7.85648929,4.73788456 L6.46731157,4.73788456 L6.46731157,7 L6,7 L6,4.26037129 L8.63024335,4.26037129 C9.11134853,4.26037129 9.50283753,3.86051508 9.50304909,3.36896355 C9.50283753,2.87736949 9.11134853,2.47751327 8.63024335,2.47751327 L6,2.47751327 L6,2 L8.63024335,2 C9.368921,2 9.96974713,2.6137694 9.97017025,3.36834672 C9.97017025,3.3684318 9.97038181,3.36853815 9.97038181,3.36868704 C9.97038181,3.36877212 9.97017025,3.36887847 9.97017025,3.36896355 C9.97017025,3.36902736 9.97038181,3.36911244 9.97038181,3.36921879 C9.97038181,3.36936768 9.97017025,3.36945276 9.97017025,3.36960165 C9.96974713,4.12411516 9.368921,4.73788456 8.63024335,4.73788456 L8.46199087,4.73788456 L8.42951664,4.73788456 Z"
                  id="Path"
                  fill="#FFFFFF"
                  fillRule="nonzero"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgIconBrex;

import React from 'react';

type Props = {
  width: number;
  height: number;
};

const SvgCheckboxCheckedCircle = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Check-Circle</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Master"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Travel---Hotel-Checkout---Select-Credit-Card"
          transform="translate(-312.000000, -827.000000)"
          fill="#318AFB"
        >
          <g
            id="Payment-Method"
            transform="translate(280.000000, 676.000000)"
          >
            <g
              id="Card"
              transform="translate(32.000000, 147.000000)"
            >
              <path
                d="M12.3665455,10.4109091 L6.70618182,16.0712727 L6.69672727,16.0610909 L6.67854545,16.0792727 L3.63854545,13.0392727 C3.35490909,12.7556364 3.35490909,12.2945455 3.63854545,12.0109091 L3.63854545,12.0109091 C3.92290909,11.7265455 4.38327273,11.7265455 4.66690909,12.0109091 L6.68872727,14.032 L11.3381818,9.38254545 C11.6218182,9.09818182 12.0821818,9.09818182 12.3665455,9.38254545 C12.6509091,9.66618182 12.6509091,10.1265455 12.3665455,10.4109091 M8,4 C3.58181818,4 0,7.58181818 0,12 C0,16.4181818 3.58181818,20 8,20 C12.4181818,20 16,16.4181818 16,12 C16,7.58181818 12.4181818,4 8,4"
                id="Check-Circle"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
SvgCheckboxCheckedCircle.defaultProps = {
  width: 16,
  height: 16,
};

export default SvgCheckboxCheckedCircle;

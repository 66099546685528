import * as React from 'react';
import classNames from 'classnames';
import _ from 'lodash-es';
import { navigate } from '@reach/router';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';

import AgencyTableHead from 'components/AgenciesList/AgencyTableHead';
import AgencyTableRow from 'components/AgenciesList/AgencyTableRow';
import Loader from 'components/Loader/Loader';
import AgencyInfoPopup from 'components/AgencyDetails/AgencyInfo/AgencyInfoPopup';

import { connect } from 'react-redux';
import { get, emptyResults } from 'actions/Agencies/Get';
import { bindActionCreators, Dispatch } from 'redux';
import { QUERY_LIMIT } from '../../../configs/General';
import { ReduxState } from '../../../reducers/types';
import { postSingle } from 'actions/Agencies/PostSingle';

const styles = require('./styles.pcss');

type Props = any;

type State = {
  searchKeyword: string;
  showAddAgency: boolean;
};

const appWindow: Window = window;

class AgenciesBody extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      searchKeyword: '',
      showAddAgency: false,
    };

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentWillReceiveProps = (nextProps: any) => {
    if (_.get(nextProps, 'agencies', []).length === 1) {
      const urlParams = new URLSearchParams(window.location.search);
      const appHost = (urlParams.get('appHost') ?? '').toLowerCase();
      const name = (urlParams.get('name') ?? '').toLowerCase();

      if (
        nextProps.agencies[0].name.toLowerCase() === name ||
        nextProps.agencies[0].friendlyName.toLowerCase() === name ||
        nextProps.agencies[0].appHost.toLowerCase() === appHost
      ) {
        navigate(`/agency/${nextProps.agencies[0].id}`);
      }
    }

    if (this.props.isLoadingAdd && _.get(nextProps, 'success', false) == true) {
      this.onCloseAddAgency();
    }
  };

  handleScroll() {
    const body = document.body;
    const html = document.documentElement;

    if (!body || !html) {
      return;
    }

    const windowHeight =
      'innerHeight' in appWindow ? appWindow.innerHeight : html.offsetHeight;

    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight,
    );
    const windowBottom = windowHeight + window.pageYOffset;

    if (
      windowBottom >= docHeight &&
      this.props.isLoading === false &&
      this.props.loadedAll === false
    ) {
      const params: any = {
        limit: QUERY_LIMIT,
        skip: this.props.agencies.length,
        ...this.props.searchParams,
      };

      this.props.get(params);
    }
  }

  componentDidMount() {
    appWindow.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    appWindow.removeEventListener('scroll', this.handleScroll);
    this.props.emptyResults();
  }

  renderTableRow = (agency: any, i: number) => {
    return (
      <AgencyTableRow
        key={i}
        agency={agency}
      />
    );
  };

  onClickAddAgency = () => {
    this.setState({
      showAddAgency: true,
    });
  };

  onCloseAddAgency = () => {
    this.setState({
      showAddAgency: false,
    });
  };

  onSubmit = (data: any) => {
    this.props.postSingle({
      address: data.address,
      appHost: data.appHost,
      name: data.name,
      friendlyName: data.friendlyName,
      marketingUrl: data.marketingUrl,
    });
  };

  render() {
    if (this.props.agencies.length == 0 && this.props.loadedAll) {
      return (
        <Paper className={styles.bodyPaper}>
          <div className={styles.notFound}>No records found</div>
        </Paper>
      );
    }
    if (this.props.agencies.length == 0) {
      return <Loader visible={this.props.isLoading} />;
    }
    return (
      <Paper className={styles.bodyPaper}>
        <Loader visible={this.props.isLoading} />
        <Table>
          <AgencyTableHead onClickAddAgency={this.onClickAddAgency} />
          <TableBody>{this.props.agencies.map(this.renderTableRow)}</TableBody>
        </Table>
        <AgencyInfoPopup
          open={this.state.showAddAgency}
          onClose={this.onCloseAddAgency}
          onSubmit={this.onSubmit}
          isLoading={this.props.isLoadingAdd}
        />
      </Paper>
    );
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      get,
      emptyResults,
      postSingle,
    },
    dispatch,
  );
}

function mapStateToProps(state: ReduxState) {
  return {
    agencies: state.agencies.agencies,
    isLoading: state.agencies.isLoading,
    loadedAll: state.agencies.loadedAll,
    isLoadingAdd: state.agency.isLoadingAdd,
    success: state.agency.success,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AgenciesBody);

import React from 'react';
import classNames from 'classnames';

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { API_URL } from '../../configs/Api';
import { uploadCSV } from 'actions/Companies/ImportEmployees';
import { Checkbox } from '@material-ui/core';
import _ from 'lodash-es';

import Button from '@material-ui/core/Button';
import SvgCloudIcon from '../../static/icons/svgCloudIcon';
import SvgGreenCheck from '../../static/icons/svgGreenCheck';

const styles = require('./styles.pcss');

import { ReduxState, Company as CompanyType } from 'reducers/types';

type Props = {
  uploadCSV: Function;
  companyDetails: CompanyType;
};

type State = {
  selectedFile: object | null;
  errorMessage: string;
  deactivateUsers: boolean;
};

class ImportEmployeesPopup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selectedFile: null,
      errorMessage: '',
      deactivateUsers: false,
    };
  }

  handleFileSelect(files: FileList | null) {
    if (files !== null && files.length > 0) {
      this.setState({
        selectedFile: files[0],
      });
    }

    return;
  }

  handleFileUpload() {
    if (this.state.selectedFile === null) {
      this.setState({
        errorMessage: 'You need to select a CSV file to import.',
      });

      return false;
    }

    this.props.uploadCSV(
      this.props.companyDetails.id,
      this.state.selectedFile,
      this.state.deactivateUsers,
    );

    return;
  }

  onClickDeactivateUsers = () => {
    this.setState({
      deactivateUsers: !this.state.deactivateUsers,
    });
  };

  renderContent = () => {
    if (_.get(this.state, 'selectedFile.name', null) !== null) {
      return this.renderFileNameLabel();
    }

    return (
      <>
        <SvgCloudIcon
          width={32}
          height={26}
        />
        <span>Upload CSV file</span>
      </>
    );
  };

  render() {
    return (
      <div className={classNames(styles.uploadContent)}>
        <label htmlFor="upload-csv-input">
          <div className={styles.importEmployees}>{this.renderContent()}</div>
        </label>

        <input
          accept="text/csv"
          id="upload-csv-input"
          type="file"
          onChange={(e) => {
            this.handleFileSelect(e.target.files);
          }}
          style={{ display: 'none' }}
        />

        <div className={styles.bottom}>
          <div className={styles.checkboxContainer}>
            <Checkbox
              checked={this.state.deactivateUsers}
              onChange={this.onClickDeactivateUsers}
            />
            <p
              onClick={this.onClickDeactivateUsers}
              className={styles.checkBoxText}
            >
              Deactivate Excluded Users
            </p>
          </div>
          <div className={styles.actions}>
            <div className={styles.button}>
              <Button
                className={classNames(styles.button)}
                variant="contained"
                color="primary"
                onClick={() => {
                  this.handleFileUpload();
                }}
              >
                Import
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderFileNameLabel() {
    let errorDiv = null;
    let errorClassName = '';
    if (this.state.errorMessage.length > 0) {
      errorDiv = (
        <div className={styles.errorMessage}>{this.state.errorMessage}</div>
      );
      errorClassName = styles.errorFileName;
    }

    return (
      <div className={styles.fileContainer}>
        <div className={styles.fileNameContainer}>
          <div className={styles.fileCheckIcon}>
            <SvgGreenCheck
              width={24}
              height={24}
            />{' '}
          </div>
          <div className={classNames(styles.fileName, errorClassName)}>
            {_.get(this.state, 'selectedFile.name', ' ')}
          </div>
          {errorDiv}
        </div>
        <div className={styles.changeFile}>Change File </div>
      </div>
    );
  }
}

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {
    companyDetails: state.company.company,
  };
}

// Wire up redux dispatch functions
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      uploadCSV,
    },
    dispatch,
  );
}

// Connect it to Redux
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ImportEmployeesPopup);

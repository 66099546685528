import {
  ACTION_GET_BB_USERS,
  ACTION_GET_BB_USERS_SUCCESS,
  ACTION_GET_BB_USERS_FAILURE,
  ACTION_RESET_BB_USERS,
  ACTION_UPDATE_BB_USER_SUCCESS,
} from '../configs/ActionTypes';

import { QUERY_LIMIT_25 } from '../configs/General';

import _ from 'lodash-es';

const initialState = {
  users: [],
  loadedAll: false,
  isLoading: false,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_GET_BB_USERS: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ACTION_RESET_BB_USERS: {
      return initialState;
    }

    case ACTION_GET_BB_USERS_SUCCESS: {
      const payload = action.payload.payload;
      let users, loadedAll;

      users = _.clone(state.users);
      loadedAll = state.loadedAll;

      if (payload.length < QUERY_LIMIT_25) {
        loadedAll = true;
      }

      if (payload.length > 0) {
        users = _.union(users, payload);
      }

      return {
        ...state,
        users: users,
        loadedAll: loadedAll,
        isLoading: false,
      };
    }

    case ACTION_UPDATE_BB_USER_SUCCESS: {
      let users = _.clone(state.users);
      const payload = action.payload.payload;

      _.find(state.users, function (user: any) {
        if (user.id == payload.id) {
          _.set(user, 'email', _.get(payload, 'email', ''));
          _.set(user, 'active', _.get(payload, 'active', ''));
          _.set(user, 'name', _.get(payload, 'name', ''));
          _.set(user, 'roleName', _.get(payload, 'roleName', ''));
          _.set(
            user,
            'roleFriendlyName',
            _.get(payload, 'roleFriendlyName', ''),
          );
          _.set(user, 'agency_id', _.get(payload, 'agencyId', ''));
        }
      });

      return {
        ...state,
        users: users,
      };
    }

    case ACTION_GET_BB_USERS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
}

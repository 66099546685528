import React from 'react';

type Props = {
  width: number;
  height: number;
};

const SvgIconOtherCardType = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>UknownCC</title>
      <desc>Created with Sketch.</desc>
      <defs>
        <rect
          id="path-1"
          x="0"
          y="0"
          width="32"
          height="32"
          rx="16"
        ></rect>
      </defs>
      <g
        id="Assets"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="UknownCC">
          <mask
            id="mask-2"
            fill="white"
          >
            <use xlinkHref="#path-1"></use>
          </mask>
          <use
            id="Mask"
            fill="#318AFB"
            xlinkHref="#path-1"
          ></use>
          <rect
            id="Rectangle"
            fill="#FFFFFF"
            transform="translate(16.000000, 16.000000) rotate(-360.000000) translate(-16.000000, -16.000000) "
            x="7"
            y="10"
            width="18"
            height="12"
            rx="2"
          ></rect>
          <rect
            id="Rectangle-2"
            fill="#1261C6"
            transform="translate(16.000000, 14.000000) rotate(-360.000000) translate(-16.000000, -14.000000) "
            x="7"
            y="13"
            width="18"
            height="2"
          ></rect>
          <rect
            id="Rectangle-3"
            fill="#1261C6"
            transform="translate(21.000000, 20.000000) rotate(-360.000000) translate(-21.000000, -20.000000) "
            x="18"
            y="19"
            width="6"
            height="2"
            rx="1"
          ></rect>
        </g>
      </g>
    </svg>
  );
};

export default SvgIconOtherCardType;

import * as React from 'react';
import _ from 'lodash-es';
import { Router, navigate } from '@reach/router';
import { hasPermission } from './helpers/Permission';
import Login from 'pages/Login/Login';
import ResetPassword from 'pages/ResetPassword/ResetPassword';
import SignUp from 'pages/SignUp/SignUp';
import UserDetails from 'pages/Users/UserDetails';
import CompanyDetails from 'pages/Companies/CompanyDetails';
import HotelsList from 'pages/Hotels/HotelsList/HotelsList';
import HotelDetails from 'pages/Hotels/HotelDetails/HotelDetails';
import BookHotel from 'pages/Hotels/BookHotel/BookHotel';
import MyAccount from 'pages/MyAccount/MyAccount';
import HotelBookingConfirmation from 'pages/Hotels/HotelBookingConfirmation/HotelBookingConfirmation';
import Lookup from 'pages/Lookup/Lookup';
import RefundChargeGoogleForm from 'pages/RefundChargeGoogleForm/RefundChargeGoogleForm';
import BookingDetails from 'pages/Bookings/BookingDetails';
import ManageTB from 'pages/ManageTB/ManageTB';
import ManageTBHotels from 'pages/ManageTBHotels/ManageTBHotels';
import ManageTBFeatures from 'pages/ManageTBFeatures/ManageTBFeatures';
import AgencySingle from 'pages/Agencies/AgencySingle';
import ManageBlackBox from 'pages/ManageBlackBox/ManageBlackBox';
import ManageBlackBoxUsers from 'pages/ManageBlackBoxUsers/ManageBlackBoxUsers';
import ManageBlackBoxPermissions from 'pages/ManageBlackBoxPermissions/ManageBlackBoxPermissions';
import CSVImport from 'pages/Companies/CSVImport';

import { ReduxState } from './reducers/types';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

const publicRoutes = ['/login', '/'];

const manageBBRoutes = [
  '/manage-bb',
  '/manage-bb/users',
  '/manage-bb/roles-permissions',
];
const withoutAgencyIdRoutes = [
  '/manage-tb',
  '/manage-tb/hotels',
  '/manage-tb/feature-flags',
];

class AppRouter extends React.Component<any, any> {
  componentWillMount() {
    if (!_.includes(publicRoutes, window.location.pathname)) {
      if (_.get(this.props.userLogin, 'isLoggedIn', false) === false) {
        navigate(`/login/`);
      } else if (
        _.get(this.props.userLogin, 'forceResetPassword', false) === true
      ) {
        navigate(`/change-password/`);
      }
    }

    if (_.includes(manageBBRoutes, window.location.pathname)) {
      if (!hasPermission('blackBox', 'read')) {
        navigate(`/lookup/`);
      }
    }

    if (_.includes(withoutAgencyIdRoutes, window.location.pathname)) {
      const loginData = localStorage.getItem('login_data') as string;
      const parsedLoginData = JSON.parse(loginData);

      if (_.get(parsedLoginData, 'agencyId', '')) {
        navigate(`/lookup/`);
      }
    }
  }

  render() {
    return (
      <Router>
        <Login path="/" />
        <Login path="login" />
        <ResetPassword path="change-password" />
        <UserDetails path="users/:userId" />
        <CompanyDetails path="company/:companyId" />
        <CSVImport path="company/:companyId/import-employees" />
        <MyAccount path="account" />
        <Lookup path="lookup" />
        <RefundChargeGoogleForm path="refund-charge" />
        <BookingDetails path="booking/:bookingId" />
        <ManageTB path="manage-tb" />
        <ManageTBHotels path="manage-tb/hotels" />
        <ManageTBFeatures path="manage-tb/feature-flags" />
        <AgencySingle path="agency/:agencyId" />
        <ManageBlackBox path="manage-bb" />
        <ManageBlackBoxUsers path="manage-bb/users" />
        <ManageBlackBoxPermissions path="manage-bb/roles-permissions" />
      </Router>
    );
  }
}

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {
    userLogin: state.userLogin,
  };
}

// Connect it to Redux
export default connect(mapStateToProps, null)(AppRouter);

// ---- Hotel bookng related routes ----
// <HotelsList path="hotels/:userId" />
// <HotelDetails path="hotels/rooms/:userId" />
// <BookHotel path="hotels/checkout/:userId" />
// <HotelBookingConfirmation path="hotels/bookingconfirmation" />

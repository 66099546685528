import {
  ACTION_GET_FEATURES,
  ACTION_GET_FEATURES_SUCCESS,
  ACTION_GET_FEATURES_FAILURE,
  ACTION_EMPTY_FEATURES,
  ACTION_RESET_FEATURES,
  ACTION_PUT_FEATURE,
  ACTION_PUT_FEATURES_SUCCESS,
  ACTION_PUT_FEATURES_FAILURE,
} from '../configs/ActionTypes';
import { Features } from './types';
import _ from 'lodash-es';

const initialState = {
  features: [],
  isLoading: false,
  success: null,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_PUT_FEATURE:
    case ACTION_GET_FEATURES: {
      return {
        ...state,
        isLoading: true,
        success: null,
      };
    }

    case ACTION_GET_FEATURES_SUCCESS: {
      let features: Array<Features> = [];
      let payload = action.payload.payload;
      payload = _.uniqBy(payload, 'name');

      _.each(payload, (t: any) => {
        let feature: Features = {
          id: t.id,
          confidence: t.confidence,
          created: t.created,
          updated: t.updated,
          platform: t.platform,
          scope: _.get(t, 'scope', []),
          name: t.name,
          friendlyName: _.get(t, 'friendlyName', t.name),
        };

        features.push(feature);
      });

      features = _.orderBy(features, [
        (feature) => _.lowerCase(_.get(feature, 'friendlyName', '')),
      ]);

      return {
        ...state,
        features: features,
        isLoading: false,
        success: null,
      };
    }

    case ACTION_PUT_FEATURES_SUCCESS: {
      let features_refresh: Array<Features> = _.clone(state.features);
      let payload = _.get(action, 'payload.payload', []);
      payload = _.uniqBy(payload, 'name');

      _.each(payload, (t: any) => {
        let feature: Features = {
          id: t.id,
          confidence: t.confidence,
          created: t.created,
          updated: t.updated,
          platform: t.platform,
          scope: _.get(t, 'scope', []),
          name: t.name,
          friendlyName:
            _.get(t, 'friendlyName', '') != ''
              ? _.get(t, 'friendlyName', '')
              : t.name,
        };

        let index = _.findIndex(features_refresh, { name: feature.name });
        features_refresh.splice(index, 1, feature);
      });

      features_refresh = _.orderBy(features_refresh, [
        (feature) => _.lowerCase(_.get(feature, 'friendlyName', '')),
      ]);

      return {
        ...state,
        features: features_refresh,
        isLoading: false,
        success: true,
      };
    }

    case ACTION_EMPTY_FEATURES: {
      return {
        ...state,
        features: [],
        isLoading: false,
        success: null,
      };
    }

    case ACTION_PUT_FEATURES_FAILURE:
    case ACTION_GET_FEATURES_FAILURE: {
      return {
        ...state,
        features: [],
        isLoading: false,
        success: false,
      };
    }

    case ACTION_RESET_FEATURES: {
      return {
        ...state,
        features: [],
        isLoading: false,
        success: null,
      };
    }

    default: {
      return state;
    }
  }
}

import React from 'react';

type Props = {
  width: number;
  height: number;
};

const SvgCheck = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      version="1.1"
      viewBox="0 0 16 12"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Master"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <g
          id="User-Macro---Profile---Multiple-Travelers"
          transform="translate(-573.000000, -463.000000)"
          stroke="#12DA9F"
          strokeWidth="1.5"
        >
          <g
            id="personal-details"
            transform="translate(280.000000, 301.000000)"
          >
            <g
              id="details"
              transform="translate(32.000000, 100.000000)"
            >
              <polyline
                id="Stroke-3"
                points="262 67.6245856 266.687707 72.3122928 276 63"
              ></polyline>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgCheck;

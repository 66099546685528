import {
  ACTION_POST_EMPLOYEE,
  ACTION_POST_EMPLOYEE_SUCCESS,
  ACTION_POST_EMPLOYEE_FAILURE,
  ACTION_RESET_EMPLOYEE_SUCCESS,
} from '../configs/ActionTypes';

import { Company } from './types';

import _ from 'lodash-es';

const initialState = {
  success: null,
  isLoading: false,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_POST_EMPLOYEE: {
      return {
        ...state,
        isLoading: true,
        success: null,
      };
    }

    case ACTION_POST_EMPLOYEE_SUCCESS: {
      return {
        ...state,
        success: true,
        isLoading: false,
      };
    }

    case ACTION_POST_EMPLOYEE_FAILURE: {
      return {
        ...state,
        success: false,
        isLoading: false,
      };
    }

    case ACTION_RESET_EMPLOYEE_SUCCESS: {
      return {
        ...state,
        success: null,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
}

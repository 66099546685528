import * as React from 'react';
import classNames from 'classnames';

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const styles = require('./styles.pcss');

type Props = {
  columns: any;
};

class SimpleTableHead extends React.Component<Props> {
  render() {
    return (
      <TableHead>
        <TableRow>
          {this.props.columns.map((v: any, i: any) => {
            return (
              <TableCell
                key={`column-${i}`}
                className={classNames(styles.tableHead)}
              >
                {v}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  }
}

export default SimpleTableHead;

import {
  ACTION_POST_REFUND_BOOKING,
  ACTION_POST_REFUND_BOOKING_SUCCESS,
  ACTION_POST_REFUND_BOOKING_FAILURE,
  ACTION_RESET_POST_REFUND_BOOKING,
  API_CALL,
} from '../../configs/ActionTypes';

import _ from 'lodash-es';
import { Dispatch } from 'redux';
import { enqueueSnackbarHandler } from '../Status';
import { track } from '../../analytics/analytics';

export function refund(
  id: string,
  type: any,
  form_of_payment: any,
  amount: number,
) {
  return (dispatch: Dispatch, getState: Function) => {
    dispatch(requestStarted());

    //Dispatch the api call request.
    dispatch({
      type: API_CALL,
      payload: {
        call: `bookings/transactions/refund/${id}`,
        method: 'post',
        data: JSON.stringify({
          amount,
        }),
        success: (response: any) => {
          if (response.type === 'error') {
            dispatch(requestFailed());
            _.get(response.payload, 'payload.userPermissionError', false)
              ? enqueueSnackbarHandler(
                  dispatch,
                  response.payload.payload,
                  'error',
                )
              : enqueueSnackbarHandler(
                  dispatch,
                  { bookingRefundFailed: true },
                  'error',
                );
          } else {
            handleTrack(type, form_of_payment);
            enqueueSnackbarHandler(
              dispatch,
              { bookingRefundSuccess: true },
              'success',
            );
            dispatch(requestSuccess(response));
          }
        },
        fail: (error: any) => {
          dispatch(requestFailed());
          _.get(error.payload, 'userPermissionError', false)
            ? enqueueSnackbarHandler(dispatch, error.payload, 'error')
            : enqueueSnackbarHandler(
                dispatch,
                { bookingRefundFailed: true },
                'error',
              );
        },
      },
    });

    return null;
  };
}

export function requestStarted() {
  return {
    type: ACTION_POST_REFUND_BOOKING,
    payload: null,
  };
}

export function requestSuccess(responseJSON: any) {
  return {
    type: ACTION_POST_REFUND_BOOKING_SUCCESS,
    payload: responseJSON,
  };
}

export function requestFailed() {
  return {
    type: ACTION_POST_REFUND_BOOKING_FAILURE,
    payload: null,
  };
}

export function resetBookingRefund() {
  return (dispatch: Dispatch, getState: Function) => {
    dispatch({
      type: ACTION_RESET_POST_REFUND_BOOKING,
      payload: null,
    });
  };
}

function handleTrack(type: any, form_of_payment: any) {
  let properties = {
    Product: type.concat('s'),
    'Refund Amount': 'full',
    'Form of Payment': form_of_payment,
  };

  track('BB_CreditCardRefunded', properties);
}

import {
  ACTION_POST_BOOKING,
  ACTION_POST_BOOKING_SUCCESS,
  ACTION_POST_BOOKING_FAILURE,
  ACTION_RESET_POST_BOOKING,
  ACTION_RESET_POST_BOOKING_ERRORS,
} from '../configs/ActionTypes';

import _ from 'lodash-es';

const initialState = {
  isLoading: false,
  booking: null,
  error: null,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_POST_BOOKING: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ACTION_POST_BOOKING_SUCCESS: {
      return {
        ...state,
        booking: action.payload.payload,
        isLoading: false,
        error: null,
      };
    }

    case ACTION_POST_BOOKING_FAILURE: {
      return {
        ...state,
        booking: null,
        isLoading: false,
        error: action.payload.payload,
      };
    }

    case ACTION_RESET_POST_BOOKING: {
      return {
        ...state,
        booking: null,
        isLoading: false,
        error: null,
      };
    }

    case ACTION_RESET_POST_BOOKING_ERRORS: {
      return {
        ...state,
        error: null,
      };
    }

    default: {
      return state;
    }
  }
}

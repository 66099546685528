import * as React from 'react';
import { navigate } from '@reach/router';

const styles = require('./styles.pcss');

type Props = {
  link: string;
  icon: any;
  text: string;
  description?: string;
};

const Box = (props: Props) => {
  return (
    <div
      className={styles.box}
      onClick={() => {
        navigate(props.link);
      }}
    >
      <div className={styles.boxTextContainer}>
        <div className={styles.iconStyle}>{props.icon}</div>
        <div className={styles.textStyle}>
          <div>{props.text}</div>
          {props.description && (
            <div className={styles.description}>{props.description}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Box;

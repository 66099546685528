import {
  ACTION_PUT_USER_ORG_ANALYTICS,
  ACTION_PUT_USER_ORG_ANALYTICS_SUCCESS,
  ACTION_PUT_USER_ORG_ANALYTICS_FAILURE,
  ACTION_PUT_USER_ORG_ANALYTICS_RESET,
} from '../configs/ActionTypes';

import { User } from './types';
import _ from 'lodash-es';

const initialState = {
  isLoading: false,
  success: null,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_PUT_USER_ORG_ANALYTICS: {
      return {
        ...state,
        isLoading: true,
        success: null,
      };
    }

    case ACTION_PUT_USER_ORG_ANALYTICS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        success: true,
      };
    }

    case ACTION_PUT_USER_ORG_ANALYTICS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        success: false,
      };
    }

    case ACTION_PUT_USER_ORG_ANALYTICS_RESET: {
      return {
        ...state,
        isLoading: false,
        success: null,
      };
    }

    default: {
      return state;
    }
  }
}

import * as React from 'react';
import cx from 'classnames';
import TextFieldWithClear from 'components/TextFieldWithIcon/TextFieldWithIcon';
import DropdownAutoSuggestDynamic from 'components/Inputs/Dropdown/DropdownAutoSuggestDynamic';
import _ from 'lodash-es';

import Button from '@material-ui/core/Button';
import OutlinedInputWithDelete from 'components/TextFieldWithIcon/OutlinedInputWithDelete';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import { TextMaskCVC } from 'components/TravelerDetails/MaskedFields/MaskedFields';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ReduxState } from '../../../reducers/types';
import Loader from 'components/Loader/Loader';

import {
  get as getCompanies,
  emptyResults as emptyCompanies,
} from 'actions/Companies/Get';
import { getSingle as getSingleOrganization } from 'actions/Companies/GetSingle';

const styles = require('./styles.pcss');

type Props = {
  onSubmit: any;
  getCompanies: Function;
  emptyCompanies: Function;
  companies?: any;
  isLoadingCompanies?: any;
  getSingleOrganization: Function;
  company: any;
};

type State = {
  name: string;
  last4: any;
  company: any;
  companyId: any;
  companyError: any;
  companies: any;
  companyDefaultValue: string;
};

class CreditCardRecordForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      name: '',
      last4: '',
      company: '',
      companyError: '',
      companyId: '',
      companies: '',
      companyDefaultValue: '',
    };

    // this.props.getCompanies({});
  }

  componentWillReceiveProps = (nextProps: any) => {
    if (nextProps.companies.length !== 0) {
      nextProps.companies.sort((a: any, b: any) =>
        a.name.localeCompare(b.name),
      );
      this.setState({
        companies: nextProps.companies,
      });
    }

    if (
      nextProps.company.isLoading == false &&
      nextProps.company.success == true &&
      Object.keys(nextProps.company.company).length != 0
    ) {
      this.setState({
        companyDefaultValue: _.get(nextProps.company.company, 'name', ''),
      });
    }
  };

  componentDidMount = () => {
    this.onSubmitForm('initial');
  };

  onChangeName = (event: any) => {
    this.setState({
      name: event.target.value,
    });
  };

  onChangeLast4 = (event: any) => {
    this.setState({
      last4: event.target.value,
    });
  };

  clearName = () => {
    this.setState({
      name: '',
    });
  };

  clearLast4 = () => {
    this.setState({
      last4: '',
    });
  };

  onSubmitForm = (initial?: any) => {
    let req: any = {
      name: this.state.name,
      last4: this.state.last4,
      organizationId: this.state.companyId,
      orderBy: 'name',
      order: 'asc',
    };

    if (initial == 'initial') {
      req.orderBy = 'created';
      req.order = 'desc';

      const urlParams = new URLSearchParams(window.location.search);

      if (urlParams.get('name')) {
        req.name = urlParams.get('name');
      }

      if (urlParams.get('last4')) {
        req.last4 = urlParams.get('last4');
      }

      if (urlParams.get('organizationId')) {
        req.organizationId = urlParams.get('organizationId');

        this.setState({
          companyId: req.organizationId,
        });

        this.props.getSingleOrganization(req.organizationId);
      }

      this.setState({
        name: req.name,
        last4: req.last4,
      });

      if (urlParams.get('sort')) {
        req.orderBy = urlParams.get('sort');
        req.order = 'asc';
      }
    } else {
      let state = { type: 'creditCard' };
      let url = 'lookup?type=creditcard';

      if (this.state.name) {
        url += `&name=${encodeURIComponent(this.state.name)}`;
      }

      if (this.state.last4) {
        url += `&last4=${encodeURIComponent(this.state.last4)}`;
      }

      if (this.state.companyId) {
        url += `&organizationId=${encodeURIComponent(this.state.companyId)}`;
      }

      url += `&sort=${encodeURIComponent('name')}`;

      window.history.pushState(state, '', url);
    }

    this.props.onSubmit(req);
  };

  _getSearchButtonText = () => {
    return 'Search';
  };

  onKeyPressed = (e: any) => {
    if (e.key === 'Enter') {
      this.onSubmitForm();
    }
  };

  onChangecompanyAuto = (companyId: any) => {
    this.setState({
      companyId,
    });
  };

  getCompanies = (value: string) => {
    this.props.getCompanies({
      name: value,
    });
  };

  emptyCompanies = () => {
    this.props.emptyCompanies();
  };

  render() {
    return (
      <div
        className={styles.form}
        onKeyDown={(e) => this.onKeyPressed(e)}
      >
        <div className={styles.inputWrapper}>
          <TextFieldWithClear
            onChange={this.onChangeName}
            label="Name"
            variant="outlined"
            margin="normal"
            value={this.state.name}
            onClear={this.clearName}
            fullWidth={true}
          />
        </div>

        <div className={styles.inputWrapper}>
          <FormControl
            margin="normal"
            variant="outlined"
            className={styles.selectWrapper}
          >
            <InputLabel htmlFor="input-Last-4">Last 4</InputLabel>
            <OutlinedInputWithDelete
              fullWidth
              id="Last-4"
              name="last4"
              labelWidth={41}
              value={this.state.last4}
              onChange={this.onChangeLast4}
              inputComponent={TextMaskCVC}
              onClear={this.clearLast4}
            />
          </FormControl>
        </div>

        <div className={styles.inputWrapper}>
          <DropdownAutoSuggestDynamic
            className={styles.dropdownContainer}
            onChange={this.onChangecompanyAuto}
            margin="normal"
            label="Company"
            list={this.state.companies || []}
            getList={this.getCompanies}
            emptyResults={this.emptyCompanies}
            isLoading={
              this.props.isLoadingCompanies || this.props.company.isLoading
            }
            value={this.state.companyDefaultValue}
          />
        </div>

        <div className={styles.inputWrapper}>
          <Button
            fullWidth
            className={styles.button}
            variant="contained"
            color="primary"
            onClick={this.onSubmitForm}
          >
            {this._getSearchButtonText()}
          </Button>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getCompanies,
      emptyCompanies,
      getSingleOrganization,
    },
    dispatch,
  );
}

function mapStateToProps(state: ReduxState) {
  return {
    company: state.company,
    companies: state.companies.companies,
    isLoadingCompanies: state.companies.isLoading,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreditCardRecordForm);

import * as React from 'react';
import classNames from 'classnames';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const styles = require('./styles.pcss');

type Props = {
  description?: any;
  text: Array<any>;
  flight: any;
  hotel: any;
};

const TravelPolicyTableRows = (props: Props) => {
  const tableColumn = (row: any) => (
    <TableCell
      key={row.id}
      className={classNames(styles.tableCell)}
    >
      {row.name}
      {props.description && (
        <div className={styles.description}>{row.description}</div>
      )}
    </TableCell>
  );

  const renderTableCell = (row: any) => {
    return (
      <TableCell
        className={classNames(styles.tableCell, styles.tableCellStyle)}
      >
        {row}
      </TableCell>
    );
  };

  return (
    <TableRow className={classNames(styles.tableRow)}>
      {tableColumn(props.text)}
      {renderTableCell(props.flight)}
      {renderTableCell(props.hotel)}
    </TableRow>
  );
};
export default TravelPolicyTableRows;

import * as React from 'react';
import _ from 'lodash-es';

import Loader from 'components/Loader/Loader';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import FeaturesTableRow from './FeaturesTableRow';
import FeaturesTableHead from './FeaturesTableHead';
import { hasPermission } from '../../../helpers/Permission';
import { bindActionCreators, Dispatch } from 'redux';
import FeatureFlagPopup from 'components/Popup/FeatureFlagPopup/FeatureFlagPopup';
import { connect } from 'react-redux';

const styles = require('./styles.pcss');

import { ReduxState } from 'reducers/types';

type Props = {
  features: any;
  onCloseAddFlagPopup: Function;
  addPopupState: any;
  isLoading: Boolean;
};

type State = {
  addNewOpen: boolean;
  openConfirmation: boolean;
  flagToEdit: Array<any>;
};

class Features extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      addNewOpen: false,
      openConfirmation: false,
      flagToEdit: [],
    };
  }

  onClickEdit = (flags: any) => {
    this.setState({
      flagToEdit: flags,
      openConfirmation: true,
    });
  };

  closeConfirmation = () => {
    this.setState({
      openConfirmation: false,
    });
  };

  renderLoader = () => {
    if (!_.get(this.props, 'isLoading', false)) {
      return null;
    }

    return (
      <div className={styles.loaderContainer}>
        <Loader visible={true} />
      </div>
    );
  };

  returnConfidenceType = (conf: any) => {
    switch (true) {
      case conf.indexOf('production') > -1:
        return <p className={styles.production}>production</p>;
        break;
      case conf.indexOf('staging') > -1:
        return <p className={styles.staging}>staging</p>;
        break;
      case conf.indexOf('preview') > -1:
        return <p className={styles.preview}>preview</p>;
        break;
      case conf.indexOf('development') > -1:
        return <p className={styles.development}>development</p>;
        break;
      default:
        return <p className={styles.test}>test</p>;
        break;
    }
  };

  processFeatureFlag = (flag: any) => {
    let processedFlag: any = {
      flag: flag,
      platform: [],
      scopeExist: false,
      confidence: [],
      friendlyName: '',
      id: [],
    };

    _.map(flag, (item: any) => {
      const flagPlatform = _.get(item, 'platform', '');

      processedFlag.platform.push(flagPlatform);
      processedFlag.scopeExist = processedFlag.scopeExist
        ? processedFlag.scopeExist
        : !_.isEmpty(_.get(item, 'scope', {}));
      processedFlag.confidence.push(_.get(item, 'confidence', ''));
      processedFlag.id.push(_.get(item, 'id', ''));
      processedFlag.friendlyName = _.get(item, 'friendlyName', '');
    });

    processedFlag.confidence = _.uniq(processedFlag.confidence);
    processedFlag.platform = _.uniq(processedFlag.platform);
    processedFlag.platform = processedFlag.platform.sort();
    processedFlag.friendlyName =
      processedFlag.friendlyName !== ''
        ? processedFlag.friendlyName
        : _.get(processedFlag.flag, '[0].name', '');

    return processedFlag;
  };

  renderFeatureTableRow = (flag: any) => {
    return (
      <FeaturesTableRow
        clickEdit={this.onClickEdit.bind(this, flag.flag)}
        showEdit={hasPermission('features', 'update')}
        key={flag.id[0]}
        rowColumns={[
          flag.friendlyName,
          this.returnConfidenceType(flag.confidence),
          flag.platform.join(', '),
          flag.scopeExist ? 'Beta' : 'All',
        ]}
      />
    );
  };

  renderTabContent = () => {
    let columns = ['Name', 'Confidence', 'Platform', 'Scope'];

    const groupedFeatures = _.groupBy(
      _.get(this.props, 'features', []),
      'name',
    );
    const processedFlags = _.map(groupedFeatures, this.processFeatureFlag);

    const orderedFeatures = _.orderBy(processedFlags, [
      (processedFlag) => processedFlag.friendlyName.toLowerCase(),
    ]);

    return (
      <>
        <Table className={styles.hotelTable}>
          <FeaturesTableHead tableColumns={columns} />
          <TableBody>
            {_.map(orderedFeatures, this.renderFeatureTableRow)}
          </TableBody>
        </Table>
      </>
    );
  };

  renderNoFeatures = () => {
    if (_.get(this.props, 'features', []).length < 1) {
      return <div className={styles.emptyData}>No Features</div>;
    }

    return null;
  };

  onSubmitEdit = () => {
    // this.props.deleteHotel(this.state.flagToEdit);

    this.setState({
      openConfirmation: false,
    });
  };

  handleClosePopup = () => {
    this.setState({
      openConfirmation: false,
      flagToEdit: [],
    });

    this.props.onCloseAddFlagPopup();
  };

  render() {
    return (
      <div className={styles.pageWrapper}>
        {this.renderLoader()}
        {this.renderTabContent()}
        {this.renderNoFeatures()}
        <FeatureFlagPopup
          openPopup={this.props.addPopupState || this.state.openConfirmation}
          addNewFeatureFlag={this.props.addPopupState}
          closePopup={this.handleClosePopup}
          flag={this.state.flagToEdit}
        />
      </div>
    );
  }
}

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {
    features: state.features.features,
    isLoading: state.features.isLoading,
  };
}

// Wire up redux dispatch functions
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({}, dispatch);
}

// Connect it to Redux
export default connect(mapStateToProps, mapDispatchToProps)(Features);

import {
  ACTION_GET_REIMBURSEMENT_SETTINGS_FEATURE,
  ACTION_GET_REIMBURSEMENT_SETTINGS_FEATURE_SUCCESS,
  ACTION_GET_REIMBURSEMENT_SETTINGS_FEATURE_FAILURE,
  ACTION_PUT_REIMBURSEMENT_SETTINGS_FEATURE,
  ACTION_PUT_REIMBURSEMENT_SETTINGS_FEATURE_SUCCESS,
  ACTION_PUT_REIMBURSEMENT_SETTINGS_FEATURE_FAILURE,
} from '../configs/ActionTypes';

const initialState = {
  feature: [],
  isLoading: false,
  success: null,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_GET_REIMBURSEMENT_SETTINGS_FEATURE:
    case ACTION_PUT_REIMBURSEMENT_SETTINGS_FEATURE: {
      return {
        ...state,
        isLoading: true,
        success: null,
      };
    }

    case ACTION_GET_REIMBURSEMENT_SETTINGS_FEATURE_SUCCESS:
    case ACTION_PUT_REIMBURSEMENT_SETTINGS_FEATURE_SUCCESS: {
      return {
        ...state,
        feature: action.payload.payload,
        isLoading: false,
        success: null,
      };
    }

    case ACTION_GET_REIMBURSEMENT_SETTINGS_FEATURE_FAILURE:
    case ACTION_PUT_REIMBURSEMENT_SETTINGS_FEATURE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        success: false,
      };
    }

    default: {
      return state;
    }
  }
}

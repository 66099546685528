import * as React from 'react';
const ColorPicker = require('rc-color-picker');
import 'rc-color-picker/assets/index.css';
const styles = require('./styles.pcss');
import cx from 'classnames';

type Props = {
  color: any;
  onChange: any;
  onChangeText: any;
  onClose: any;
  text: any;
  description: any;
  validationError: any;
};

class ChangeColorRow extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const rowstyle = this.props.validationError
      ? cx(styles.colorpickerErrorRow, styles.colorpickerRow)
      : styles.colorpickerRow;
    return (
      <div className={rowstyle}>
        <div className={styles.colorpickerRowCont}>
          <div className={styles.colorpickerRowTextContainer}>
            <div className={styles.colorpickerRowText}>{this.props.text}</div>
            <div className={styles.colorpickerRowDescription}>
              {this.props.description}
            </div>
          </div>
          <div className={styles.colorpickerContainer}>
            <ColorPicker
              color={this.props.color}
              defaultColor={'#fff'}
              placement="topLeft"
              onChange={this.props.onChange}
              onClose={this.props.onClose}
              className={styles.colorPickerStyle}
            />
            <div className={styles.colorPickerInput}>
              <input
                type="text"
                onChange={this.props.onChangeText}
                value={this.props.color}
              />
            </div>
          </div>
        </div>
        {this.props.validationError && (
          <div className={styles.colorpickerError}>
            {this.props.validationError}
          </div>
        )}
      </div>
    );
  }
}

export default ChangeColorRow;

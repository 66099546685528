import * as React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { navigate } from '@reach/router';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const styles = require('./styles.pcss');

type Props = {
  id: any;
  cells: any;
};

const BookingsTableRow = ({ id, cells }: Props) => (
  <TableRow
    className={classNames(styles.clickable, styles.tableRow)}
    key={id}
    onClick={() => navigate(`/booking/${id}`)}
  >
    {cells.map((v: any, i: any) => {
      return (
        <TableCell
          className={classNames(styles.tableRowCell)}
          scope="row"
          key={`table-cell-${i}`}
        >
          {v}
        </TableCell>
      );
    })}
  </TableRow>
);

export default BookingsTableRow;

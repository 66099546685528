/**
 * Post BOOKING NEW PAYMENT Action
 */

import {
  ACTION_POST_BOOKING_NEW_PAYMENT,
  ACTION_POST_BOOKING_NEW_PAYMENT_SUCCESS,
  ACTION_POST_BOOKING_NEW_PAYMENT_FAILURE,
  ACTION_RESET_POST_BOOKING_NEW_PAYMENT,
  ACTION_RESET_POST_BOOKING_NEW_PAYMENT_ERRORS,
  API_CALL,
} from '../../configs/ActionTypes';

import { Dispatch } from 'redux';
import { enqueueSnackbarHandler } from '../Status';
import { track } from '../../analytics/analytics';

export function postNewPayment(
  params: any,
  bookingId: string,
  product: string,
  cCType: string,
  merchant: string,
) {
  return (dispatch: Dispatch, getState: Function) => {
    dispatch(requestStarted());

    //Dispatch the api call request.
    dispatch({
      type: API_CALL,
      payload: {
        call: `booking/charge/${bookingId}`,
        method: 'post',
        data: JSON.stringify(params),
        success: (response: any) => {
          if (response.type === 'error') {
            dispatch(requestFailed(response));
            enqueueSnackbarHandler(
              dispatch,
              { bookingNewPaymentPostFailed: true },
              'error',
            );
          } else {
            enqueueSnackbarHandler(
              dispatch,
              { bookingNewPaymentPostSuccess: true },
              'success',
            );
            handleTrack(product, cCType, merchant);
            dispatch(requestSuccess(response));
          }
        },
        fail: (error: any) => {
          dispatch(requestFailed(error));
          enqueueSnackbarHandler(
            dispatch,
            { bookingNewPaymentPostFailed: true },
            'error',
          );
        },
      },
    });

    return null;
  };
}

export function requestStarted() {
  return {
    type: ACTION_POST_BOOKING_NEW_PAYMENT,
    payload: null,
  };
}

export function requestSuccess(responseJSON: any) {
  return {
    type: ACTION_POST_BOOKING_NEW_PAYMENT_SUCCESS,
    payload: responseJSON,
  };
}

export function requestFailed(responseJSON: any) {
  return {
    type: ACTION_POST_BOOKING_NEW_PAYMENT_FAILURE,
    payload: responseJSON,
  };
}

export function resetBookingNewPayment() {
  return (dispatch: Dispatch, getState: Function) => {
    dispatch({
      type: ACTION_RESET_POST_BOOKING_NEW_PAYMENT,
      payload: null,
    });
  };
}

export function resetPostBookingErrorsNewPayment() {
  return (dispatch: Dispatch, getState: Function) => {
    dispatch({
      type: ACTION_RESET_POST_BOOKING_NEW_PAYMENT_ERRORS,
      payload: null,
    });
  };
}

function handleTrack(product: string, cCType: string, merchant: string) {
  let properties = {
    Product: product.concat('s'),
    'Credit Card Type': cCType,
    Merchant: merchant,
  };

  track('BB_CreditCardCharged', properties);
}

import React, { Component } from 'react';
import cx from 'classnames';
import _ from 'lodash-es';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Loader from 'components/Loader/Loader';
import FormHelperText from '@material-ui/core/FormHelperText';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import MenuItem from '@material-ui/core/MenuItem';
import TextFieldWithClear from 'components/TextFieldWithIcon/TextFieldWithIcon';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { KeyboardArrowDown } from '@material-ui/icons';
const styles = require('./styles.pcss');
import {
  postNewPayment,
  resetBookingNewPayment,
} from '../../actions/Bookings/PostNewPayment';
import { ReduxState } from '../../reducers/types';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import RenderIcon from 'components/CreditCard/RenderIcon';
import CustomSelect from 'components/Inputs/Select/Select';
import { getOrganizationEmployeesSimple } from '../../actions/Companies/GetOrganizationEmployeesSimple';

type Props = {
  open: boolean;
  in_process?: any;
  onCancel: any;
  listOfCreditCards: any;
  bookingId: string;
  postNewPayment: Function;
  resetBookingNewPayment: Function;
  newPayment: any;
  pnr: string;
  isFlightCreditApplied: boolean;
  type: string;
  rateType: string;
  employeesSimple: any;
  userId: string;
  organizationId: string;
  getOrganizationEmployeesSimple: Function;
  getCreditCards: Function;
  user: any;
};

type State = {
  amountError: string;
  creditCardError: string;
  amount: string;
  creditCard: string;
  description: string;
  flightCreditAmount: string;
  flightCreditAmountError: string;
  open: any;
  displayMoneyIcon: boolean;
  displayMoneyIconFC: boolean;
  mor: string;
  morError: string;
  userId: string;
  userError: string;
};

class NewPaymentPopup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { rateType, userId, organizationId, getOrganizationEmployeesSimple } =
      props;

    this.state = {
      amountError: '',
      creditCardError: '',
      amount: '',
      creditCard: '',
      description: '',
      flightCreditAmount: '',
      flightCreditAmountError: '',
      open: false,
      displayMoneyIcon: false,
      displayMoneyIconFC: false,
      mor: rateType === 'prepay' ? 'travelbank' : '',
      morError: '',
      userId,
      userError: '',
    };

    if (organizationId) {
      getOrganizationEmployeesSimple(organizationId);
    }
  }

  onChangeamount = (event: any) => {
    this.setState({
      amount: event.target.value,
      amountError: '',
    });
  };

  onChangeFlightCreditAmount = (event: any) => {
    this.setState({
      flightCreditAmount: event.target.value,
      flightCreditAmountError: '',
    });
  };

  onChangeCreditCard = (event: any) => {
    this.setState({
      creditCard: event.target.value,
      creditCardError: '',
    });
  };

  onChangeDescription = (event: any) => {
    this.setState({
      description: event.target.value,
    });
  };

  onChangeMor = (event: any) => {
    this.setState({
      mor: event.target.value,
      morError: '',
    });
  };

  onDeleteDescription = () => {
    this.setState({
      description: '',
    });
  };

  onDeleteamount = () => {
    this.setState({
      amount: '',
      amountError: '',
      displayMoneyIcon: false,
    });
  };

  onDeleteFlightCreditAmount = () => {
    this.setState({
      flightCreditAmount: '',
      flightCreditAmountError: '',
      displayMoneyIconFC: false,
    });
  };

  onOpen = (e: any) => {
    this.setState({
      open: true,
    });
  };

  onClose = (e: any) => {
    this.setState({
      open: false,
    });
  };

  componentWillReceiveProps = (nextProps: Props) => {
    const amountError = _.get(nextProps, 'newPayment.amountError', '');
    const creditCardError = _.get(nextProps, 'newPayment.creditCardError', '');

    let newStates: any = {};

    if (amountError != '' || creditCardError != '') {
      newStates.amountError = amountError;
      newStates.creditCardError = creditCardError;
    }

    if (nextProps.rateType === 'prepay') {
      newStates.mor = 'travelbank';
    }

    const isListOfCreditCardsUpdated =
      !_.isEqual(this.props.listOfCreditCards, nextProps.listOfCreditCards) ||
      this.props.open !== nextProps.open;
    if (isListOfCreditCardsUpdated) {
      const creditCard =
        nextProps.listOfCreditCards.length === 1
          ? _.get(nextProps.listOfCreditCards[0], 'id')
          : '';

      newStates.creditCard = creditCard;
    }

    if (
      amountError != '' ||
      creditCardError != '' ||
      nextProps.rateType === 'prepay' ||
      isListOfCreditCardsUpdated
    ) {
      this.setState(newStates);
    }

    if (nextProps.newPayment.success === true) {
      this.props.resetBookingNewPayment();
      this.onCancel();
    }
  };

  renderLoader = () => {
    if (this.props.newPayment.isLoading || this.props.in_process) {
      return (
        <div className={styles.loaderContainer}>
          <Loader visible={true} />
        </div>
      );
    }

    return null;
  };

  validateFields = () => {
    const regex = RegExp('^[0-9]+([,.][0-9]*)?$');
    let isError = false;
    let newStates = {
      amountError: '',
      creditCardError: '',
      flightCreditAmountError: '',
      morError: '',
      userError: '',
    };

    if (this.state.amount.trim().length < 1) {
      newStates.amountError = 'Required';
      isError = true;
    } else if (!regex.test(this.state.amount)) {
      newStates.amountError = 'Please enter numbers only';
      isError = true;
    }

    if (this.state.creditCard.trim() === '') {
      newStates.creditCardError = 'Required';
      isError = true;
    }

    if (this.state.mor.trim() === '') {
      newStates.morError = 'Required';
      isError = true;
    }

    if (this.state.userId === '') {
      newStates.userError = 'Required';
      isError = true;
    }

    return { isError, newStates };
  };

  handleSubmit = () => {
    const { isError, newStates } = this.validateFields();
    if (isError) {
      this.setState(newStates);
    } else {
      const creditCard = _.find(this.props.listOfCreditCards, [
        'id',
        this.state.creditCard,
      ]);

      let cardType = 'personal';

      if (
        (_.get(creditCard, 'organizationId', null) !== null &&
          _.get(creditCard, 'organizationId', '') !== '') ||
        (_.isArray(_.get(creditCard, 'departmentIds', false)) &&
          _.get(creditCard, 'departmentIds', []).length > 0)
      ) {
        cardType = 'corporate';
      }

      const req = {
        creditCardId: this.state.creditCard,
        amount: this.state.amount,
        description: this.state.description,
        merchant: this.state.mor,
        flightCreditAmount: this.state.flightCreditAmount,
      };

      let merchant = this.state.mor;

      this.props.postNewPayment(
        req,
        this.props.bookingId,
        this.props.type,
        cardType,
        merchant,
      );
    }
  };

  renderCreditCardError = () => {
    if (Boolean(this.state.creditCardError)) {
      return (
        <FormHelperText error>{this.state.creditCardError}</FormHelperText>
      );
    }
    return null;
  };

  renderMorError = () => {
    if (Boolean(this.state.morError)) {
      return <FormHelperText error>{this.state.morError}</FormHelperText>;
    }
    return null;
  };

  onCancel = () => {
    this.props.resetBookingNewPayment();
    if (this.props.userId !== this.state.userId) {
      this.props.getCreditCards(this.props.userId);
    }
    this.setState({
      amountError: '',
      creditCardError: '',
      amount: '',
      creditCard: '',
      description: '',
      open: false,
      displayMoneyIcon: false,
      mor: '',
      morError: '',
      userId: this.props.userId,
    });
    this.props.onCancel();
  };

  onFocusAmount = (event: any) => {
    if (!this.state.displayMoneyIcon) {
      this.setState({
        displayMoneyIcon: true,
      });
    }
  };

  onFocusFlightCreditAmount = () => {
    if (!this.state.displayMoneyIconFC) {
      this.setState({
        displayMoneyIconFC: true,
      });
    }
  };

  onBlurAmount = (event: any) => {
    if (this.state.amount.trim().length === 0) {
      this.setState({
        displayMoneyIcon: false,
      });
    }
  };

  onBlurFlightCreditAmount = () => {
    if (this.state.flightCreditAmount.trim().length === 0) {
      this.setState({
        displayMoneyIconFC: false,
      });
    }
  };

  getCreditCardOwners = (employees: any) => {
    if (!employees) {
      return [];
    }

    let menuItems = _.filter(employees, ['status', 'active']).map(
      (user: any) => {
        let username = `${_.get(user, 'firstName', '')} ${_.get(
          user,
          'lastName',
          '',
        )}`;

        if (username.trim() == '') {
          username = user.email;
        }
        return { key: user.id, value: user.id, label: username };
      },
    );

    return _.sortBy(menuItems, ['label']);
  };

  onChangeUser = (event: any) => {
    const userId = event.target.value;
    this.setState({
      userId,
      userError: '',
    });
    this.props.getCreditCards(userId);
  };

  renderUserError = () => {
    if (Boolean(this.state.userError)) {
      return <FormHelperText error>{this.state.userError}</FormHelperText>;
    }

    return null;
  };

  render() {
    let employees = [];
    if (this.props.organizationId) {
      employees = this.props.employeesSimple.employees;
    } else if (this.props.user) {
      employees = [this.props.user];
    }
    const creditCardOwners = this.getCreditCardOwners(employees);

    return (
      <Dialog
        maxWidth={'md'}
        fullWidth={true}
        className={cx(styles.dialogBox, styles.ccPopup, styles.newPaymentPopup)}
        open={this.props.open}
        onClose={this.onCancel}
      >
        <div className={styles.title}>
          <div className={styles.titleStyle}>New Payment</div>
          <IconButton
            color="inherit"
            onClick={this.onCancel}
            className={cx(styles.closeIconCreditCard)}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        </div>

        <p className={styles.subtitleStyle}>
          Create a new credit card charge for booking{' '}
          <span className={styles.boldText}>{this.props.pnr}</span>.
        </p>
        <div className={cx(styles.body, styles.newPayment)}>
          <div className={cx(styles.newPaymentContainer)}>
            <div className={styles.inputWrapper}>
              <CustomSelect
                margin={'normal'}
                variant={'outlined'}
                className={styles.selectWrapper}
                htmlFor={'input-type'}
                label={'Credit Card Owner'}
                id={'input-type'}
                value={this.state.userId}
                onChange={this.onChangeUser}
                fullWidth={true}
                inputError={Boolean(this.state.userError)}
                labelWidth={123}
                inputName={'user'}
                renderError={this.renderUserError()}
                MenuProps={{
                  style: { marginTop: 4, boxShadow: 'none' },
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
                menuItems={creditCardOwners}
              />
            </div>

            <div className={styles.inputWrapper}>
              <FormControl
                error={Boolean(this.state.creditCardError)}
                variant={'outlined'}
                className={styles.selectWrapper}
                disabled={this.props.in_process}
              >
                <InputLabel htmlFor={'input-select'}>
                  {'Credit Card'}
                </InputLabel>
                <Select
                  className={this.state.open ? styles.selected : styles.select}
                  id={'cred-card-id'}
                  value={this.state.creditCard}
                  onChange={this.onChangeCreditCard}
                  input={
                    <OutlinedInput
                      fullWidth={true}
                      error={Boolean(this.state.creditCardError)}
                      labelWidth={78}
                      name={'creditCard'}
                    />
                  }
                  MenuProps={{
                    style: { marginTop: 4, boxShadow: 'none' },
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                  }}
                  onOpen={this.onOpen}
                  onClose={this.onClose}
                  open={this.state.open}
                  IconComponent={() => <KeyboardArrowDown />}
                >
                  {this.props.listOfCreditCards.map((creditCard: any) => {
                    let corporate = <></>;

                    if (
                      (_.get(creditCard, 'organizationId', null) !== null &&
                        _.get(creditCard, 'organizationId', '') !== '') ||
                      (_.isArray(_.get(creditCard, 'departmentIds', false)) &&
                        _.get(creditCard, 'departmentIds', []).length > 0)
                    ) {
                      corporate = (
                        <span className={styles.corporateMark}>
                          {' '}
                          • Corporate
                        </span>
                      );
                    }

                    return (
                      <MenuItem
                        key={_.get(creditCard, 'id', '')}
                        value={_.get(creditCard, 'id', '')}
                      >
                        {
                          <div
                            className={cx(
                              styles.menuItem,
                              styles.cardItem,
                              'menuItem',
                            )}
                          >
                            {
                              <RenderIcon
                                type={_.get(creditCard, 'typeFull', '')}
                                width={18}
                                height={18}
                              />
                            }
                            {`   ${creditCard.typeFull}... ${creditCard.last4}`}
                            {corporate}
                          </div>
                        }
                      </MenuItem>
                    );
                  })}
                </Select>
                {this.renderCreditCardError()}
              </FormControl>
            </div>
          </div>

          <div className={cx(styles.newPaymentContainer)}>
            <div className={styles.inputWrapper}>
              <FormControl
                error={Boolean(this.state.morError)}
                variant={'outlined'}
                className={styles.selectWrapper}
                disabled={this.props.rateType === 'prepay'}
              >
                <InputLabel htmlFor={'input-select'}>Merchant</InputLabel>
                <Select
                  className={this.state.open ? styles.selected : styles.select}
                  id={'mor'}
                  value={this.state.mor}
                  onChange={this.onChangeMor}
                  input={
                    <OutlinedInput
                      fullWidth={true}
                      error={Boolean(this.state.morError)}
                      labelWidth={78}
                      name={'mor'}
                    />
                  }
                  MenuProps={{
                    style: { marginTop: 4, boxShadow: 'none' },
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                  }}
                  IconComponent={() => <KeyboardArrowDown />}
                >
                  <MenuItem
                    key="TravelBank"
                    value="travelbank"
                  >
                    <div className={cx(styles.menuItem, 'menuItem')}>
                      TravelBank
                    </div>
                  </MenuItem>

                  <MenuItem
                    key="Other"
                    value="other"
                  >
                    <div className={cx(styles.menuItem, 'menuItem')}>Other</div>
                  </MenuItem>
                </Select>
                {this.renderMorError()}
              </FormControl>
            </div>

            <div className={styles.inputWrapper}>
              <TextFieldWithClear
                helperText={this.state.amountError}
                onChange={this.onChangeamount}
                fullWidth
                label={'Amount'}
                variant="outlined"
                margin="normal"
                error={Boolean(this.state.amountError)}
                value={this.state.amount}
                onClear={this.onDeleteamount}
                onFocus={this.onFocusAmount}
                onBlur={this.onBlurAmount}
                disabled={this.props.in_process}
                startAdornment={this.state.displayMoneyIcon}
                icon={<AttachMoneyIcon fontSize={'small'} />}
              />
            </div>
            {this.props.isFlightCreditApplied && (
              <div className={styles.inputWrapper}>
                <TextFieldWithClear
                  helperText={this.state.flightCreditAmountError}
                  onChange={this.onChangeFlightCreditAmount}
                  fullWidth
                  label={'Flight Credit Amount'}
                  variant="outlined"
                  margin="normal"
                  error={Boolean(this.state.flightCreditAmountError)}
                  value={this.state.flightCreditAmount}
                  onClear={this.onDeleteFlightCreditAmount}
                  onFocus={this.onFocusFlightCreditAmount}
                  onBlur={this.onBlurFlightCreditAmount}
                  disabled={this.props.in_process}
                  startAdornment={this.state.displayMoneyIconFC}
                  icon={<AttachMoneyIcon fontSize={'small'} />}
                />
              </div>
            )}
          </div>

          <div>
            <div className={styles.inputWrapper}>
              <TextFieldWithClear
                onChange={this.onChangeDescription}
                fullWidth
                label={'Description'}
                variant="outlined"
                margin="normal"
                value={this.state.description}
                onClear={this.onDeleteDescription}
                disabled={this.props.in_process}
              />
            </div>
          </div>

          <div
            className={cx(
              styles.popupButtonsContainer,
              styles.addNewTableRowProgram,
            )}
          >
            <Button
              size="small"
              className={styles.cancelButton}
              color="primary"
              onClick={this.onCancel}
            >
              Cancel
            </Button>

            <Button
              size="small"
              className={styles.button}
              variant="contained"
              color="primary"
              onClick={this.handleSubmit}
              disabled={this.props.in_process}
            >
              Charge
            </Button>
          </div>
        </div>
        {this.renderLoader()}
      </Dialog>
    );
  }
}

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {
    newPayment: state.bookingNewPayment,
    employeesSimple: state.employeesSimple,
  };
}

// Wire up redux dispatch functions
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      postNewPayment,
      resetBookingNewPayment,
      getOrganizationEmployeesSimple,
    },
    dispatch,
  );
}

// Connect it to Redux
export default connect(mapStateToProps, mapDispatchToProps)(NewPaymentPopup);

import React from 'react';

type Props = {
  width: number;
  height: number;
};

const SvgIconTower = (props: Props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 10 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <polygon
          id="path-1"
          points="0 0.0307692308 9.72307692 0.0307692308 9.72307692 15.3846154 0 15.3846154"
        ></polygon>
      </defs>
      <g
        id="Master"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="User-Macro---Account"
          transform="translate(-685.000000, -724.000000)"
        >
          <g
            id="reporting"
            transform="translate(280.000000, 626.000000)"
          >
            <g
              id="setting-1"
              transform="translate(31.000000, 70.000000)"
            >
              <g
                id="Group-6"
                transform="translate(345.000000, 0.000000)"
              >
                <g
                  id="Bank-Account"
                  transform="translate(18.000000, 20.000000)"
                >
                  <g id="Group-5">
                    <g id="Avatar">
                      <g id="Group-6">
                        <g
                          id="Company"
                          transform="translate(11.000000, 8.000000)"
                        >
                          <polygon
                            id="Fill-1"
                            fill="#6D717A"
                            points="2.83076923 9.27692308 4.49230769 9.27692308 4.49230769 7.61538462 2.83076923 7.61538462"
                          ></polygon>
                          <polygon
                            id="Fill-2"
                            fill="#6D717A"
                            points="5.23076923 9.27692308 6.89230769 9.27692308 6.89230769 7.61538462 5.23076923 7.61538462"
                          ></polygon>
                          <polygon
                            id="Fill-3"
                            fill="#6D717A"
                            points="2.89230769 11.7378462 4.55384615 11.7378462 4.55384615 10.0769231 2.89230769 10.0769231"
                          ></polygon>
                          <polygon
                            id="Fill-4"
                            fill="#6D717A"
                            points="5.23076923 11.7378462 6.89230769 11.7378462 6.89230769 10.0769231 5.23076923 10.0769231"
                          ></polygon>
                          <polygon
                            id="Fill-5"
                            fill="#6D717A"
                            points="2.89230769 14.1993846 4.55384615 14.1993846 4.55384615 12.5384615 2.89230769 12.5384615"
                          ></polygon>
                          <polygon
                            id="Fill-6"
                            fill="#6D717A"
                            points="5.23076923 14.1993846 6.89230769 14.1993846 6.89230769 12.5384615 5.23076923 12.5384615"
                          ></polygon>
                          <g
                            id="Group-9"
                            transform="translate(0.000000, 0.584615)"
                          >
                            <mask
                              id="mask-2"
                              fill="white"
                            >
                              <polygon
                                id="path-1"
                                points="0 0.0307692308 9.72307692 0.0307692308 9.72307692 15.3846154 0 15.3846154"
                              ></polygon>{' '}
                            </mask>
                            <g id="Clip-8"></g>
                            <path
                              d="M3.44615385,4.2 L6.4,4.2 L6.4,2.78461538 L3.44615385,2.78461538 L3.44615385,4.2 Z M9.10769231,4.2 L7.56923077,4.2 L7.56923077,2.16923077 C7.56923077,1.86153846 7.26153846,1.55384615 6.95384615,1.55384615 L5.47692308,1.55384615 L5.47692308,0.630769231 C5.47692308,-0.169230769 4.24615385,-0.169230769 4.24615385,0.630769231 L4.24615385,1.61538462 L2.76923077,1.61538462 C2.46153846,1.61538462 2.15384615,1.92307692 2.15384615,2.23076923 L2.15384615,4.26153846 L0.615384615,4.26153846 C0.307692308,4.26153846 0,4.56923077 0,4.87692308 L0,14.7846154 C0,15.5846154 1.23076923,15.5846154 1.23076923,14.7846154 L1.23076923,5.49230769 L8.49230769,5.49230769 L8.49230769,14.7846154 C8.49230769,15.5846154 9.72307692,15.5846154 9.72307692,14.7846154 L9.72307692,4.87692308 C9.72307692,4.50769231 9.41538462,4.2 9.10769231,4.2 L9.10769231,4.2 Z"
                              id="Fill-7"
                              fill="#6D717A"
                              mask="url(#mask-2)"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgIconTower;

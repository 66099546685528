import * as React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { navigate } from '@reach/router';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { User } from '../../reducers/types';

const styles = require('./styles.pcss');

type Props = {
  user: User;
};

const TravelerTableRow = ({ user }: Props) => (
  <TableRow
    className={classNames(styles.clickable, styles.tableRow)}
    key={user.id}
    onClick={() => navigate(`/users/${user.id}`)}
  >
    <TableCell
      className={classNames(styles.tableRowCell)}
      scope="row"
    >
      {user.firstName}
    </TableCell>
    <TableCell className={classNames(styles.tableRowCell)}>
      {user.lastName}
    </TableCell>
    <TableCell
      className={classNames(styles.tableRowCell, styles.tableRowCellEmail)}
    >
      {user.email}
    </TableCell>
    <TableCell
      className={classNames(styles.tableRowCell, styles.tableRowCellEmail)}
    >
      {user.status}
    </TableCell>
  </TableRow>
);

export default TravelerTableRow;
